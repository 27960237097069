import { Box, Grid, Typography } from '@mui/material';
import { useCreateArtService } from 'modules/art/services';
import { EmailFormData } from 'modules/art/types';
import { FC } from 'react';
import { FormView } from 'shared/components';
import { PublicPageLayout } from 'shared/layout';
import { EmailForm } from './EmailForm';
import { useLangContext } from 'shared/features/Language';

type Props = {
  goBack: () => void;
  enterEmail: (data: EmailFormData) => void;
};

export const EnteringEmailView: FC<Props> = ({ enterEmail, goBack }) => {
  const { initFormData } = useCreateArtService();
  const { textData } = useLangContext();

  return (
    <PublicPageLayout
      title={
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { md: '35px', xs: '26px' },
            lineHeight: { md: '42px', xs: '31px' },
            maxWidth: { md: '394px', xs: '100%' },
          }}
        >
          {textData.enterEmailPageTitle}
        </Typography>
      }
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '18px',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: '490px',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Grid item>
            <Typography
              variant="overline"
              sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              {textData.attentionText}
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              marginTop: {
                md: 0,
                xs: '5%',
              },
            }}
          >
            <FormView
              formData={initFormData}
              component={EmailForm}
              onSubmit={enterEmail}
              componentProps={{ goBack }}
            />
          </Grid>

          <Grid item>
            <Typography
              variant="overline"
              sx={{
                fontSize: '12px',
                fontWeight: 275,
                lineHeight: '18px',
                textAlign: 'left',
              }}
            >
              {textData.additionalText}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </PublicPageLayout>
  );
};
