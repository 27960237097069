import { Divider, Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import { TableTabsProps } from './types';

interface Props {
  tabs?: TableTabsProps;
}

export const TableTabs: FC<Props> = props => {
  const { tabs } = props;

  if (!tabs?.tabItems?.length) {
    return null;
  }

  return (
    <>
      <Tabs
        value={tabs.selectedTab}
        onChange={tabs.onSelectTab}
        variant="scrollable"
        textColor="secondary"
        scrollButtons="auto"
      >
        {tabs.tabItems.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.title} />
        ))}
      </Tabs>

      <Divider />
    </>
  );
};
