import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FC, useState } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { Logo } from './NavigationLogo';
import { useLangContext } from 'shared/features/Language';
import { ModalCountryConfirmDialog } from 'shared/components';

type Props = {
  mode: 'dark' | 'light';
};

export const PublicHeader: FC<Props> = ({ mode }) => {
  const theme = useTheme();
  const { lang, changeLang, textData } = useLangContext();
  const [openModal, setOpenModal] = useState(false);
  const primaryColor = mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main;
  const secondaryColor =
    mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main;

  const handleChange = (event: SelectChangeEvent) => {
    changeLang(event.target.value as 'en' | 'it' | 'es' | 'pl');
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const langs = ['en', 'es', 'it', 'pl'];

  return (
    <Container maxWidth={mode === 'dark' ? false : 'lg'} sx={{ backgroundColor: primaryColor }}>
      <ModalCountryConfirmDialog open={openModal} handleClose={handleClose} />

      <Grid
        container
        direction="column"
        width="100%"
        sx={{
          paddingTop: '10px',
          paddingLeft: '30px',
          paddingRight: mode === 'dark' ? { md: '15%', xs: 0 } : { md: '30px', xs: 0 },
        }}
      >
        <Grid
          item
          container
          maxWidth="100%"
          direction="row"
          width="100%"
          sx={{ justifyContent: 'space-between', paddingLeft: { md: '30px', xs: 0 } }}
        >
          <Grid item>
            <Logo
              color={mode === 'dark' ? 'white' : 'black'}
              sx={{
                width: { xl: '160px', lg: '160px', md: '160px', sm: '150px', xs: '150px' },
                height: { xl: '69px', lg: '69px', md: '69px', sm: '65px', xs: '65px' },
              }}
            />
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            spacing={1}
            sx={{
              maxWidth: { md: '340px', xs: '140px' },
              justifyContent: 'space-between',
            }}
          >
            <Grid
              item
              sx={{
                flex: '3 1',
                display: { xl: 'block', lg: 'block', md: 'block', xs: 'none' },
                '& > .MuiButton-endIcon': {
                  height: '35px',
                },
              }}
            >
              <Box onClick={handleClickOpen}>
                <ButtonWithIcon
                  icon={
                    <CustomIcon
                      name={mode === 'dark' ? 'arrowTopWhite' : 'arrowTopBlack'}
                      styles={{ '& > svg': { width: '28px', height: '28px' } }}
                    />
                  }
                  color={mode === 'dark' ? 'dark' : 'light'}
                  position="end"
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: '18px', lineHeight: '27px', paddingLeft: '15px' }}
                  >
                    {textData.goToStore}
                  </Typography>
                </ButtonWithIcon>
              </Box>
            </Grid>

            <Grid item sx={{ flex: '1 1' }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={lang}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: secondaryColor,
                    svg: {
                      color: theme =>
                        `${
                          mode === 'dark'
                            ? theme.palette.primary.main
                            : theme.palette.secondary.main
                        }`,
                    },
                    '&:hover': {
                      backgroundColor: primaryColor,
                      border: '1px solid',
                      borderColor: theme =>
                        `${
                          mode === 'dark'
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main
                        }`,

                      '.MuiTypography-root': {
                        color: theme =>
                          `${
                            mode === 'dark'
                              ? theme.palette.secondary.main
                              : theme.palette.primary.main
                          }`,
                      },

                      svg: {
                        color: theme =>
                          `${
                            mode === 'dark'
                              ? theme.palette.secondary.main
                              : theme.palette.primary.main
                          }`,
                      },
                    },
                  }}
                >
                  {langs.map((lang, index) => (
                    <MenuItem value={lang} key={index}>
                      <Typography
                        variant="overline"
                        sx={{
                          fontSize: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: theme =>
                            `${
                              mode === 'dark'
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main
                            }`,
                        }}
                      >
                        {lang.toLocaleUpperCase()}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              sx={{ flex: '1 1', display: { xl: 'none', lg: 'none', md: 'none', xs: 'block' } }}
            >
              <Box
                sx={{
                  border: `1px solid`,
                  borderColor: theme =>
                    `${
                      mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main
                    }`,
                  borderRadius: '100%',
                  padding: '8px',
                  width: '44px',
                  height: '44px',
                }}
              >
                <Box onClick={handleClickOpen}>
                  <CustomIcon
                    name={mode === 'dark' ? 'store' : 'storeBlack'}
                    styles={{ '& > svg': { width: '24px', height: '24px' } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box
            sx={{
              height: '1px',
              width: '100%',
              backgroundColor: secondaryColor,
              padding: 0,
            }}
          />
        </Grid>

        <Grid item sx={{ marginTop: '10px', paddingLeft: { md: '30px', xs: 0 } }}>
          <Typography
            sx={{
              color: secondaryColor,
              fontSize: '14px',
              lineHeight: '21px',
            }}
            variant="overline"
          >
            {textData.logoText}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
