import { Box, useMediaQuery } from '@mui/material';
import { FC, useEffect } from 'react';
import { theme } from 'themes';

type Props = {
  step: string;
  prev:
    | {
        x: number;
        y: number;
        angle: number;
        name: string;
      }
    | undefined
    | null;
  current:
    | {
        x: number;
        y: number;
        angle: number;
        name: string;
      }
    | undefined
    | null;
};

function drawLine(
  context: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) {
  context.beginPath();
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

function drawHead(
  context: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) {
  var headlen = 10;
  var dx = x2 - x1;
  var dy = y2 - y1;
  var angle = Math.atan2(dy, dx);

  context.beginPath();

  context.moveTo(x2, y2);
  context.lineTo(
    x2 - headlen * Math.cos(angle - Math.PI / 6),
    y2 - headlen * Math.sin(angle - Math.PI / 6),
  );
  context.lineTo(
    x2 - headlen * Math.cos(angle + Math.PI / 6),
    y2 - headlen * Math.sin(angle + Math.PI / 6),
  );

  context.closePath();
  context.fillStyle = colorWhite;
  context.fill();
}

function drawFilledArrow(
  current: { x: number; y: number },
  next: { x: number; y: number },
  color: string,
  width: number,
  circleWidth: number,
) {
  const canvas = document.getElementById('CentralCircleView') as HTMLCanvasElement;
  const context = canvas.getContext('2d');

  if (!current || !next) {
    context.clearRect(0, 0, circleWidth, circleWidth);
    return;
  }

  context.clearRect(0, 0, circleWidth, circleWidth);
  context.lineWidth = width;
  context.fillStyle = color;
  context.strokeStyle = color;
  drawLine(context, current.x, current.y, next.x, next.y);
  drawHead(context, current.x, current.y, next.x, next.y);
}

function getTransform(step: string) {
  if (step.includes('A')) {
    return '-136deg';
  } else if (step.includes('B')) {
    return '-45deg';
  } else if (step.includes('C')) {
    return '136deg';
  } else if (step.includes('D')) {
    return '45deg';
  }
}

const colorWhite = theme.palette.secondary.main;

export const CentralCircleView: FC<Props> = ({ step, prev, current }) => {
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const circleWidth = matches ? 170 : 315;

  useEffect(() => {
    drawFilledArrow(prev, current, colorWhite, 0.8, circleWidth);
  }, [prev, current, step, circleWidth]);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          zIndex: '1',
          width: { sm: '315px', xs: '170px' },
          height: { sm: '315px', xs: '170px' },
          background: 'rgba(45, 45, 45, 1)',
          borderRadius: '50%',
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          zIndex: '2',
          transform: `rotate(${getTransform(step)})`,
          transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          width: '0',
          height: '0',
          borderRight: {
            sm: '158px solid transparent',
            xs: '85px solid transparent',
          },
          borderTop: {
            sm: '158px solid rgba(98, 98, 98, 1)',
            xs: '85px solid rgba(98, 98, 98, 1)',
          },
          borderLeft: {
            sm: '158px solid rgba(98, 98, 98, 1)',
            xs: '85px solid rgba(98, 98, 98, 1)',
          },
          borderBottom: {
            sm: '158px solid rgba(98, 98, 98, 1)',
            xs: '85px solid rgba(98, 98, 98, 1)',
          },
          borderTopLeftRadius: { sm: '158px', xs: '85px' },
          borderTopRightRadius: { sm: '158px', xs: '85px' },
          borderBottomLeftRadius: { sm: '158px', xs: '85px' },
          borderBottomRightRadius: { sm: '158px', xs: '85px' },
        }}
      />

      <Box sx={{ position: 'absolute', zIndex: '3' }}>
        <canvas id="CentralCircleView" width={circleWidth} height={circleWidth}></canvas>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          zIndex: '4',
          width: { sm: '130px', xs: '70px' },
          height: { sm: '130px', xs: '70px' },
          background: 'rgba(255, 255, 255, 1)',
          borderRadius: '50%',
        }}
      />
    </>
  );
};
