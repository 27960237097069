import { Box, Grid, Typography } from '@mui/material';
import { useVerifyCouponController } from 'modules/auth';
import { FormView } from 'shared/components';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';
import { VerifyForm } from './VerifyForm';
import { FC } from 'react';

type Props = {
  imageId: string;
};
export const PublicAuthView: FC<Props> = ({ imageId }) => {
  const { initFormData, enter, loading, error } = useVerifyCouponController(imageId);
  const { textData } = useLangContext();

  return (
    <PublicPageLayout
      title={
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { md: '50px', xs: '28px' },
            lineHeight: { md: '60px', xs: '36px' },
            maxWidth: '394px',
            paddingRight: { sm: '20px ', xs: '20px ' },
          }}
        >
          {textData.verifyCouponPageTitle}
        </Typography>
      }
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '18px',
        }}
      >
        <Grid container spacing={2} sx={{ maxWidth: '490px' }}>
          {!!error && (
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography
                variant="overline"
                sx={{
                  fontSize: '14px',
                  fontWeight: 300,
                  lineHeight: '120%',
                  color: '#F00',
                }}
              >
                {textData.verifyError}
              </Typography>
            </Grid>
          )}

          <Grid item sx={{ marginTop: { md: 0, xs: '5%' } }}>
            <FormView
              formData={initFormData}
              component={VerifyForm}
              componentProps={{ isLoading: loading.verify }}
              onSubmit={enter}
              errorList={!!error ? [{ field: 'code', message: '' }] : undefined}
            />
          </Grid>
        </Grid>
      </Box>
    </PublicPageLayout>
  );
};
