import { AxiosResponse } from 'axios';

type ResponseType = {
  message: string;
};

export class CustomAxiosError extends Error {
  public response: Partial<AxiosResponse<ResponseType>>;
  public isAxiosError = false;

  constructor({ status, message }: { status: number; message: string }) {
    super();
    this.response = { status, data: { message } };
  }
}
