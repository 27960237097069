import { AlertColor } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { NotificationState } from '../types';

export function useNotificationsControllerService() {
  const [state, setState] = useState<NotificationState>({ isOpened: false });

  const onOpen = useCallback((message: ReactNode, type: AlertColor) => {
    setState({
      isOpened: true,
      notification: {
        message,
        type,
      },
    });
  }, []);

  const onClose = useCallback(() => {
    setState({
      ...state,
      isOpened: false,
    });
  }, [state]);

  return { ...state, onOpen, onClose };
}
