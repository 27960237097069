import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Loading } from '../Loading';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TablePaginationView } from './TablePaginationView';
import { TableTabs } from './TableTabs';
import { TableObjectField, TablePageProps, TableTabsProps } from './types';

interface Props<T extends object> {
  isLoading?: boolean;
  heading?: ReactNode;
  notFoundText?: ReactNode;
  columns: TableObjectField<T>[];
  records: T[] | undefined | null;
  page?: TablePageProps;
  tabs?: TableTabsProps;
  sort?: string;
  onChangeSort?: (value: string) => void;
}

export const TableView = <T extends object>(
  props: PropsWithChildren<Props<T>>,
): ReactElement | null => {
  const { isLoading, heading, notFoundText, columns, records, page, tabs, sort, onChangeSort } =
    props;

  return (
    <Card>
      {!!heading && (
        <>
          <CardHeader title={heading} />
          <Divider />
        </>
      )}

      <TableTabs tabs={tabs} />

      {isLoading ? (
        <>
          <Loading />
          {page && <Divider />}
        </>
      ) : (
        <Box overflow="auto">
          {!!records && records.length > 0 ? (
            <Table>
              <TableHead columns={columns} sort={sort} onChangeSort={onChangeSort} />
              <TableBody columns={columns} records={records} />
            </Table>
          ) : (
            <Box p={2}>
              <Typography variant="body1" color="textPrimary">
                {notFoundText}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <TablePaginationView page={page} />
    </Card>
  );
};
