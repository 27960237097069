import React, { FC, memo, useCallback, useState } from 'react';
import { useHarmonicIntervalFn } from 'react-use';
import { PlotterCanvas2D } from './parts/plotter/plotter-canvas-2d';
import { ThreadComputer } from './parts/threading/thread-computer';
import { ThreadPlotter } from './parts/threading/thread-plotter';
import { EMode } from './parts/types/EMode';
import { EShape } from './parts/types/EShape';

type Props = {
  imageSrc: string | undefined;
  canvasSize: {
    width: number;
    height: number;
  };
  parameters: {
    pegsCount: number;
    nbLines: number;
    mode: EMode.MONOCHROME;
    shape: EShape.ELLIPSIS;
    quality: number;
    blur: number;
    lineOpacity: number;
    lineThickness: number;
    displayPegs: boolean;
    invertColors: boolean;
    intervalTimeout: number;
  };
  onFinish?: (data: {
    size: { x: number; y: number };
    pegs: { x: number; y: number; angle: number; name: string }[];
    steps: string[];
    image: string;
  }) => void;
};

export const StringArtGenerator: FC<Props> = memo(props => {
  const { canvasSize, imageSrc, parameters, onFinish } = props;

  const [tools, setTools] = useState<{
    canvasEl: HTMLCanvasElement;
    imageEl: HTMLImageElement;
    threadComputer: ThreadComputer;
    canvasPlotter: PlotterCanvas2D;
    threadPlotter: ThreadPlotter;
  } | null>(null);

  const [isReady, setIsReady] = useState(false);

  const initCanvasEl = useCallback(
    (canvasEl: HTMLCanvasElement) => {
      if (canvasEl && !tools?.canvasEl && imageSrc) {
        const imageEl = new Image();
        imageEl.addEventListener('load', () => buildConfig(canvasEl, imageEl));
        imageEl.src = imageSrc;
      }
    },
    [tools],
  );

  const buildConfig = useCallback((canvasEl: HTMLCanvasElement, imageEl: HTMLImageElement) => {
    const threadComputer = new ThreadComputer(imageEl, parameters);
    const canvasPlotter = new PlotterCanvas2D(canvasEl);
    const threadPlotter = new ThreadPlotter(canvasPlotter, threadComputer, parameters);

    setTools({ canvasEl, imageEl, threadComputer, canvasPlotter, threadPlotter });
  }, []);

  const finish = useCallback(() => {
    setIsReady(true);
    onFinish?.({ ...tools.threadComputer.getScheme(), image: tools.canvasEl.toDataURL() });
  }, [tools]);

  const mainLoop = () => {
    if (!!tools) {
      const computedSomething = tools.threadComputer.computeNextSegments(1);
      if (!computedSomething && !isReady) {
        tools.threadComputer.dispose();
        finish();
      } else {
        tools.threadPlotter.plot();
      }
    }
  };

  useHarmonicIntervalFn(mainLoop, isReady ? null : parameters.intervalTimeout);

  return (
    <canvas
      ref={el => initCanvasEl(el)}
      height={canvasSize.height}
      width={canvasSize.width}
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#ffffff',
      }}
    />
  );
});
