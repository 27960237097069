import Axios, { AxiosError, Method, ResponseType } from 'axios';
import qs from 'query-string';
import { Token } from '../../../modules/auth';
import { IStorage } from '../storage';
import { ApiError } from './ApiError';
import { CustomAxiosError } from './CustomAxiosError';
import { ApiClient, ApiErrorHandler, ApiRequestHeader } from './types';

export class DefaultApiClient implements ApiClient {
  private readonly tokenStorage: IStorage;
  private readonly headers: Record<string, string>;
  private apiErrorHandler?: ApiErrorHandler;

  constructor(tokenStorage: IStorage) {
    this.tokenStorage = tokenStorage;
    this.headers = {};
  }

  public setHeader(name: string, value: string) {
    this.headers[name] = value;
  }

  private generateHeaders(customHeaders?: Record<ApiRequestHeader, string>) {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...this.headers,
    };

    const token = this.tokenStorage.get();
    if (token) {
      const authToken: Token = JSON.parse(token);
      headers['Authorization'] = `Bearer ${authToken.accessToken}`;
    }

    if (customHeaders) {
      (Object.keys(customHeaders) as ApiRequestHeader[]).forEach(key => {
        if (customHeaders[key]) {
          headers[key] = customHeaders[key];
        }
      });
    }

    return headers;
  }

  public setErrorHandler(apiErrorHandler: ApiErrorHandler) {
    this.apiErrorHandler = apiErrorHandler;
  }

  private handleError(error: AxiosError) {
    const apiError = new ApiError();

    if (error.response) {
      const { status, data } = error.response;

      apiError.httpCode = status;
      apiError.errorCode = data.errorCode;
      apiError.data = data;

      if (typeof data?.title === 'string') {
        apiError.message = data.title;
      }

      // switch (status) {
      //   case 401:
      //   case 400:
      //   case 403:
      //   case 404:
      //   case 500:
      //   default:
      //     apiError.message = 'Error';
      // }
    }

    if (this.apiErrorHandler) {
      this.apiErrorHandler(apiError);
    }

    throw apiError;
  }

  public async request<T, TResult = T>(p: {
    method: Method;
    path: string;
    data?: Partial<T>;
    params?: Record<string, any>;
    customHeaders?: Record<ApiRequestHeader, string>;
    responseType?: ResponseType;
    isFullResponse?: Boolean;
  }): Promise<TResult> {
    const { method, path, data, params, customHeaders, responseType, isFullResponse } = p;

    return Axios({
      method,
      url: path,
      data,
      headers: this.generateHeaders(customHeaders),
      responseType,
      params,
      paramsSerializer: params => qs.stringify(params, { skipNull: true }),
    })
      .then(response => {
        if (response.data.error_code) {
          throw new CustomAxiosError(response.data);
        }

        return isFullResponse ? response : response.data;
      })
      .catch(error => {
        this.handleError(error as AxiosError);
      });
  }

  public async get<T, TResult = T>(p: {
    path: string;
    params?: Partial<T>;
    customHeaders?: Record<ApiRequestHeader, string>;
    responseType?: ResponseType;
    isFullResponse?: Boolean;
  }) {
    const { path, params, customHeaders, responseType, isFullResponse } = p;

    return this.request<T, TResult>({
      method: 'get',
      path,
      params,
      customHeaders,
      responseType,
      isFullResponse,
    });
  }

  public async post<T, TResult = T>(p: {
    path: string;
    data?: Partial<T>;
    customHeaders?: Record<ApiRequestHeader, string>;
  }) {
    const { path, data, customHeaders } = p;

    return this.request<T, TResult>({ method: 'post', path, data, customHeaders });
  }

  public async put<T, TResult = T>(p: {
    path: string;
    data?: Partial<T>;
    customHeaders?: Record<ApiRequestHeader, string>;
  }) {
    const { path, data, customHeaders } = p;

    return this.request<T, TResult>({
      method: 'put',
      path,
      data,
      customHeaders,
    });
  }

  public async patch<T, TResult = T>(p: {
    path: string;
    data?: Partial<T>;
    customHeaders?: Record<ApiRequestHeader, string>;
  }) {
    const { path, data, customHeaders } = p;

    return this.request<T, TResult>({
      method: 'patch',
      path,
      data,
      customHeaders,
    });
  }

  public async delete<T, TResult = T>(p: {
    path: string;
    data?: Partial<T>;
    params?: Partial<T>;
    customHeaders?: Record<ApiRequestHeader, string>;
  }) {
    const { path, data, params, customHeaders } = p;

    return this.request<T, TResult>({
      method: 'delete',
      path,
      data,
      params,
      customHeaders,
    });
  }
}
