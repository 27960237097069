import { Button, styled } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link as LinkSource, BrowserRouter } from 'react-router-dom';

type Props = {
  to: string;
  variant?: 'text' | 'contained' | 'outlined';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  forceRefresh?: boolean;
};

export const LinkButton: FC<PropsWithChildren<Props>> = ({
  to,
  variant = 'contained',
  startIcon,
  endIcon,
  size,
  children,
  forceRefresh,
}) => {
  if (forceRefresh) {
    return (
      <BrowserRouter forceRefresh>
        <Link to={to}>
          <Button variant={variant} startIcon={startIcon} endIcon={endIcon} size={size}>
            {children}
          </Button>
        </Link>
      </BrowserRouter>
    );
  }

  return (
    <Link to={to}>
      <Button variant={variant} startIcon={startIcon} endIcon={endIcon} size={size}>
        {children}
      </Button>
    </Link>
  );
};

const Link = styled(LinkSource)(({ theme }) => ({
  textDecoration: 'none',
}));
