import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { Logo } from './NavigationLogo';
import { useLangContext } from 'shared/features/Language';
import { ModalCountryConfirmDialog } from 'shared/components';

type Props = {
  title: ReactNode;
};

export const MainHeader: FC<Props> = ({ title }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { lang, changeLang, textData } = useLangContext();
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    changeLang(event.target.value as 'en' | 'it' | 'es' | 'pl');
  };

  const langs = ['en', 'es', 'it', 'pl'];

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        height: { xl: '100vh', lg: '100vh', md: '100vh', sm: '100%', xs: '100%' },
        backgroundColor: theme => theme.palette.primary.main,
        color: theme => theme.palette.secondary.main,
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: {
          xl: '10px 40px 0px 90px',
          lg: '10px 40px 0px 90px',
          md: '10px 40px 0px 90px',
          sm: '10px 20px 40px 25px',
          xs: '10px 20px 20px 25px',
        },
        margin: 0,
      }}
    >
      <ModalCountryConfirmDialog open={openModal} handleClose={handleClose} />

      {matches ? (
        <Grid
          container
          direction="column"
          width="100%"
          sx={{ padding: { sm: '10px 14px 40px 14px', xs: '10px 14px 20px 14px' } }}
        >
          <Grid
            item
            container
            maxWidth="100%"
            direction="row"
            width="100%"
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Logo
                color="white"
                sx={{
                  width: '150px',
                  height: '65px',
                }}
              />
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              spacing={1}
              sx={{
                maxWidth: '140px',
                justifyContent: 'space-between',
              }}
            >
              <Grid item sx={{ flex: '2 1' }}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lang}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: theme => theme.palette.secondary.main,
                      svg: {
                        color: theme => theme.palette.primary.main,
                      },
                      '&:hover': {
                        backgroundColor: theme => theme.palette.primary.main,
                        border: theme => `1px solid ${theme.palette.secondary.main} `,
                        svg: {
                          color: theme => theme.palette.secondary.main,
                        },

                        '.MuiTypography-root': {
                          color: theme => theme.palette.secondary.main,
                        },
                      },
                    }}
                  >
                    {langs.map((lang, index) => (
                      <MenuItem value={lang} key={index}>
                        <Typography
                          variant="overline"
                          sx={{
                            fontSize: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: theme => theme.palette.primary.main,
                          }}
                        >
                          {lang.toLocaleUpperCase()}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                sx={{ flex: '1 1', display: { xl: 'none', lg: 'none', md: 'none', xs: 'block' } }}
              >
                <Box
                  sx={{
                    border: theme => `1px solid ${theme.palette.secondary.main}`,
                    borderRadius: '100%',
                    padding: '8px',
                    width: '44px',
                    height: '44px',
                  }}
                >
                  <Box onClick={handleClickOpen}>
                    <CustomIcon
                      name="store"
                      styles={{ '& > svg': { width: '24px', height: '24px' } }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                backgroundColor: theme => theme.palette.secondary.main,
                padding: 0,
              }}
            />
          </Grid>

          <Grid item sx={{ marginTop: '10px' }}>
            <Typography
              sx={{
                color: theme => theme.palette.secondary.main,
                fontSize: '14px',
                lineHeight: '21px',
              }}
              variant="overline"
            >
              {textData.logoText}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid item container alignItems="flex-start" flex="2 1">
          <Grid item flex="2 1">
            <Logo
              color="white"
              sx={{
                width: { xl: '160px', lg: '160px', md: '160px', sm: '150px', xs: '150px' },
                height: { xl: '69px', lg: '69px', md: '69px', sm: '65px', xs: '65px' },
              }}
            />
          </Grid>
          <Grid item sx={{ minWidth: '75px' }}>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                onChange={handleChange}
                sx={{
                  backgroundColor: theme => theme.palette.secondary.main,
                  svg: {
                    color: theme => theme.palette.primary.main,
                  },
                  '&:hover': {
                    backgroundColor: theme => theme.palette.primary.main,
                    border: '1px solid',
                    borderColor: theme => theme.palette.secondary.main,

                    '.MuiTypography-root': {
                      color: theme => theme.palette.secondary.main,
                    },

                    svg: {
                      color: theme => theme.palette.secondary.main,
                    },
                  },
                }}
              >
                {langs.map((lang, index) => (
                  <MenuItem value={lang} key={index}>
                    <Typography
                      variant="overline"
                      sx={{
                        fontSize: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: theme => theme.palette.primary.main,
                      }}
                    >
                      {lang.toLocaleUpperCase()}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid item flex="3 1" sx={{ paddingTop: '0px !important' }}>
        {title}
      </Grid>

      {!matches && (
        <Grid item flex="1 1">
          <Typography
            variant="overline"
            sx={{
              fontSize: '18px',
              lineHeight: '27px',
              color: theme => theme.palette.secondary.main,
            }}
          >
            {textData.contactUs}
            <br />
            <a
              href="mailto:support@letsstring.store"
              style={{ textDecoration: 'none', color: 'rgba(255, 255, 255, 1)' }}
            >
              {textData.contactEmail}
            </a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
