import { Box } from '@mui/material';
import { FC } from 'react';
import 'react-before-after-slider-component/dist/build.css';
import ReactCompareImage from 'react-compare-image';
import monaLizaArt from '../../../shared/assets/img/art_cropped.png';
import monaLiza from '../../../shared/assets/img/liza-crepped.png';

export const HomeCompareImage: FC = () => {
  return (
    <div className="compare-image" style={{ touchAction: 'none' }}>
      <ReactCompareImage
        aspectRatio="wider"
        leftImage={monaLiza}
        rightImage={monaLizaArt}
        handle={
          <Box
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              backgroundColor: 'transparent',
              border: '1px solid white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Box
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: 'white',
              }}
            />
          </Box>
        }
      />
    </div>
  );
};
