import { CssBaseline, ThemeProvider } from '@mui/material';
import 'normalize.css';
import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import 'shared/assets/css/common.css';
import 'shared/assets/css/fonts.css';
import { theme } from 'themes';
import { configureModules } from '../shared/modules';
import { Router } from '../shared/router';
import { createContextItems, createServices, MultiContextProvider } from '../shared/tools';
import { buildConfig } from './config';
import { modules } from './modules';
import { LangProvider } from 'shared/features/Language';
import { useEffectOnce } from 'react-use';
import { useVerifyService } from 'modules/auth';

const App: FC = () => {
  const services = createServices(buildConfig);
  const contextItems = createContextItems(services, buildConfig);
  const { routes, store } = configureModules({ modules });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <LangProvider>
          <MultiContextProvider items={contextItems}>
            <TokenProvider>
              <Helmet defaultTitle="LetsString" titleTemplate="%s -  LetsString" />
              <Router routes={routes} show404 />
            </TokenProvider>
          </MultiContextProvider>
        </LangProvider>
      </Provider>
    </ThemeProvider>
  );
};

export { App };

const TokenProvider: FC<PropsWithChildren<{}>> = props => {
  const { children } = props;
  const { loading, initVerify } = useVerifyService();

  useEffectOnce(() => {
    initVerify();
  });

  if (loading.initVerify) {
    return <>'loading'</>;
  }

  return <>{children}</>;
};
