import { useMemo } from 'react';
import { useApiConsumerClient, useApiUrlBuilder } from 'shared/tools';
import {
  CreateArtRequest,
  CreateArtResponse,
  GenerateArtRequestType,
  GenerateArtResponse,
  ResumeArtResponse,
  SaveStepRequest,
  SaveStepResponse,
  StartRequest,
  StartResponse,
} from '../types';

export function useConsumerArtApi() {
  const api = useApiConsumerClient();
  const apiUrlBuilder = useApiUrlBuilder();

  return useMemo(
    () => ({
      startArt: (data: StartRequest) =>
        api.post<StartRequest, StartResponse>({
          path: apiUrlBuilder.consumerStartArt(),
          data,
        }),

      sendPDF: (data: FormData) =>
        api.post<FormData, void>({
          path: apiUrlBuilder.consumerSendPDF(),
          data,
        }),

      saveArtStep: (data: SaveStepRequest) =>
        api.post<SaveStepRequest, SaveStepResponse>({
          path: apiUrlBuilder.consumerSaveArtStep(),
          data,
        }),

      resumeArt: (sessionId: string) =>
        api.post<string, ResumeArtResponse>({
          path: apiUrlBuilder.consumerResumeArt(sessionId),
        }),

      generateArt: (data: GenerateArtRequestType) =>
        api.post<GenerateArtRequestType, GenerateArtResponse>({
          path: apiUrlBuilder.consumerGenerateArt(),
          data,
        }),

      createArt: (data: CreateArtRequest) =>
        api.post<CreateArtRequest, CreateArtResponse>({
          path: apiUrlBuilder.consumerCreateArt(),
          data,
        }),

      downloadPDF: () =>
        api.get<Blob>({
          path: apiUrlBuilder.consumerDownloadPDF(),
          responseType: 'blob',
        }),
    }),
    [api, apiUrlBuilder],
  );
}
