import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          maxWidth: '72px',
          maxHeight: '29px',
          border: 'rgba(255, 255, 255, 1)',
          borderRadius: '50px',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '15px !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'grey',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'darkgrey',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: state => ({
          borderRadius: '45px',
          border: state.ownerState.selected ? '1px solid rgba(39, 39, 39, 1)' : 'none',
          backgroundColor: 'transparent !important',
          maxHeight: '40px',
          minWidth: '200px',
          textAlign: 'center',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '27px',
          borderRadius: '50px',
          boxSizing: 'border-box',
          color: 'rgba(255, 255, 255, 0.4)',
          padding: 0,
        },
        track: {
          backgroundColor: 'rgba(39, 39, 39, 1)',
        },
        rail: {
          border: '1px solid rgba(255, 255, 255, 1)',
        },
        thumb: {
          width: '40px',
          height: '40px',
          backgroundColor: 'rgba(255, 255, 255, 1)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: state => ({
          borderRadius: '45px',
          border: !!state.ownerState?.error
            ? '1px solid rgba(255, 0, 0, 1)'
            : '2px solid rgba(39, 39, 39, 1)',
          width: '100%',
        }),
        input: {
          textAlign: 'center',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          fontSize: '20px',
          fontFamily: 'Poppins',
        },
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(39, 39, 39, 1)',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 1)',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      // fontFamily: 'Mak, sans-serif',
      fontFamily: 'Rosarivo, cursive',
    },
    overline: {
      fontFamily: 'Poppins, sans-serif',
      textTransform: 'initial',
      fontWeight: 300,
      letterSpacing: '0.2px',
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
      textTransform: 'initial',
      fontWeight: 275,
      letterSpacing: '0.2px',
    },
  },
});
