import { FormControl } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormTextBox } from 'shared/components';
import { SearchData } from './types';

type Props = {
  search: (data: string) => Promise<void>;
};

export const CouponSearch: FC<Props> = ({ search }) => {
  const form = useForm<SearchData>({
    defaultValues: {
      text: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<SearchData> = form => search(form.text);

  return (
    <FormControl onChange={form.handleSubmit(onSubmit)} sx={{ margin: '20px 20px 0 0' }}>
      <Box sx={{ marginBottom: '20px', '& > div': { margin: 0 } }}>
        <FormTextBox form={form} fieldName="text" placeholder="Search..." />
      </Box>
    </FormControl>
  );
};
