import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { Breadcrumbs as BreadcrumbsSource, Link } from '@mui/material';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BreadcrumbItem } from '../../types';


interface Props {
  breadcrumbs?: BreadcrumbItem[];
}

export const Breadcrumbs: FC<Props> = props => {
  return (
    <BreadcrumbsSource separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {props.breadcrumbs?.map((breadcrumb, index) => {
        return (
          <Link component={RouterLink} key={index} color="inherit" to={breadcrumb.path}>
            {breadcrumb.title}
          </Link>
        );
      })}
    </BreadcrumbsSource>
  );
};
