import { Container, Grid, Pagination, Typography } from '@mui/material';
import { PortraitDataType } from 'modules/art/types';
import { Link } from 'react-router-dom';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components';
import { useLangContext } from 'shared/features/Language';
import { useAppUrlBuilderContext } from 'shared/tools';
import { useArtGeneratorViewController } from '../../controllers';
import { ReadyDesignCard } from './ReadyDesignCard';

export const ReadyDesignsView = () => {
  const appUrlBuilder = useAppUrlBuilderContext();
  const { data, chooseArt, changePage } = useArtGeneratorViewController('choice-variant');
  const { textData } = useLangContext();

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={2}
        sx={{
          margin: '18px 0',
          padding: '0 15px',
          flexDirection: { md: 'row', xs: 'column' },
        }}
      >
        <Grid item sx={{ maxWidth: '187px' }}>
          <Link to={appUrlBuilder.home()}>
            <ButtonWithIcon
              type="button"
              icon={<CustomIcon name="arrowBottomLeftBlack" />}
              position="start"
              color="light"
            >
              <Typography variant="overline" sx={{ fontSize: '21px', lineHeight: '30px' }}>
                {textData.buttonBack}
              </Typography>
            </ButtonWithIcon>
          </Link>
        </Grid>

        <Grid
          item
          sm={9}
          md={9}
          sx={{
            flex: '1 1',
            textAlign: {
              md: 'center',
              sm: 'start',
            },
          }}
        >
          <Typography
            variant="overline"
            sx={{
              fontWeight: 275,
              fontSize: '22px',
              lineHeight: '26px',
              textAlign: 'center',
            }}
          >
            {textData.readyDesignsText}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        {data.currentData().map((item: PortraitDataType, index: number) => (
          <Grid item lg={3} md={4} sm={6} xs={9} key={index}>
            <ReadyDesignCard item={item} chooseArt={chooseArt} />
          </Grid>
        ))}

        {data.maxPage > 1 && (
          <Grid
            item
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Pagination
              count={data.maxPage}
              color="primary"
              page={data.currentPage}
              onChange={changePage}
              hidePrevButton
              hideNextButton
              sx={{ padding: '20px 0 50px 0' }}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
