import { TablePagination as TablePaginationSource } from '@mui/material';
import React, { ChangeEvent, FC, MouseEvent, useCallback } from 'react';
import { TablePageProps } from './types';

interface Props {
  page?: TablePageProps;
}

export const TablePaginationView: FC<Props> = props => {
  const { page } = props;

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, pageNumber: number) => {
      page?.onChangePage(pageNumber);
    },
    [page],
  );

  const handleChangePageSize = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      page?.onChangePageSize(Number(event.target.value));
    },
    [page],
  );

  if (!page) {
    return null;
  }


  return (
    <TablePaginationSource
      component="div"
      count={page.pagination.total || 0}
      page={page.pagination.page}
      rowsPerPage={page.pagination.size}
      rowsPerPageOptions={page.pageSizes}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangePageSize}
    />
  );
};
