import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { Link as LinkSource } from 'react-router-dom';
import { LayoutLinkGroup } from '../../layout';


type Props = {
  group: LayoutLinkGroup;
  onLinkClick?(): void;
};

export const CollapseItemLinkList: FC<Props> = props => {
  const { group, onLinkClick } = props;
  const [isOpen, setIsOpen] = useState(true);

  const changeIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Box>
      <ListItemButton alignItems="flex-start" onClick={changeIsOpen}>
        <ListItemText primary={group.subheader} />

        <KeyboardArrowDown />
      </ListItemButton>

      {isOpen &&
        group.items.map((item, i) => {
          const Icon = item.icon;

          return (
            <Link to={item.link} key={i}>
              <ListItemButton onClick={onLinkClick}>
                {Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}

                <ListItemText primary={item.title} />
              </ListItemButton>
            </Link>
          );
        })}
    </Box>
  );
};

const Link = styled(LinkSource)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));
