import { Container } from '@mui/material';
import { FormView, HeaderView, Loading, LoadingContainer } from 'shared/components';
import { useCouponsController } from '../controllers';
import { CouponSearch } from './CouponSearch';
import { CouponsForm } from './CouponsForm';
import { CouponsTableView } from './CouponsTableView';
import { CouponFilterByStatus } from './CouponFilterByStatus';

export const CouponsView = () => {
  const {
    initFormData,
    loading,
    filter,
    couponList,
    generate,
    search,
    changeSort,
    changePage,
    changePageSize,
    changeStatus,
  } = useCouponsController();

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <HeaderView title="Coupons" />

      <FormView formData={initFormData} component={CouponsForm} onSubmit={generate} />

      <CouponSearch search={search} />

      <CouponFilterByStatus filter={filter} changeStatus={changeStatus} />

      {loading.getCoupons ? (
        <LoadingContainer flex="1 1">
          <Loading />
        </LoadingContainer>
      ) : (
        <CouponsTableView
          couponList={couponList}
          loading={loading}
          filter={filter}
          changeSort={changeSort}
          changePage={changePage}
          changePageSize={changePageSize}
        />
      )}
    </Container>
  );
};
