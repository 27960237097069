import { combineReducers, configureStore } from '@reduxjs/toolkit';
import artReducer from '../../modules/art/slices/ArtSlice';
import authReducer from '../../modules/auth/slices/AuthSlice';
import verifyReducer from '../../modules/auth/slices/VerifySlice';
import couponsReducer from '../../modules/coupons/slices/CouponsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  verify: verifyReducer,
  coupons: couponsReducer,
  art: artReducer,
});

export function setupStore() {
  return configureStore({
    reducer: rootReducer,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
