import { Grid, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router';
import { LinkButton } from 'shared/components';

export const AdminAuthPageLayout: FC = ({ children }) => {
  const location = useLocation();

  const context = useMemo(() => {
    if (location.pathname === '/login') {
      return {
        title: `Don't have an account?`,
        note: 'Create an account!',
        button: {
          title: 'Register',
          link: '/register',
        },
      };
    }

    if (location.pathname.includes('/reset-password')) {
      return {
        title: `Already have an account?`,
        note: 'Sign in with an existing account.',
        button: {
          title: 'Login',
          link: '/login',
        },
      };
    }

    return {
      title: '',
    };
  }, [location]);

  return (
    <Grid
      container
      sx={theme => ({
        minHeight: '100%',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('md')]: { flexDirection: 'column' },
      })}
    >
      <Grid item container sx={{ minWidth: '320px', flex: '1 1 33%', flexDirection: 'column' }}>
        <Grid
          container
          sx={{
            flex: '1 1 auto',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '100px 0',
            textAlign: 'center',
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="overline" sx={{ fontSize: '40px' }}>
                Let's String
              </Typography>
            </Grid>

            {context.title && (
              <Grid item>
                <Typography variant="h4">{context.title}</Typography>
              </Grid>
            )}

            {context.note && (
              <Grid item>
                <Typography variant="h5">{context.note}</Typography>
              </Grid>
            )}

            {context.button && (
              <Grid item>
                <LinkButton to={context.button.link}>{context.button.title}</LinkButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        sx={{ flex: '1 1 66%', flexDirection: 'column', backgroundColor: '#272727' }}
      >
        {children}
      </Grid>
    </Grid>
  );
};
