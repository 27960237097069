import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { PublicHeader } from './PublicHeader';
import { useLangContext } from 'shared/features/Language';

export const PublicWhitePageLayout: FC = ({ children }) => {
  const { textData } = useLangContext();

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Grid
        container
        direction="column"
        minHeight="100%"
        width="100%"
        sx={{ zIndex: 1, position: 'relative' }}
      >
        <PublicHeader mode="light" />

        <Grid item container direction="column" flex="1 0 auto">
          {children}
        </Grid>

        <Grid
          item
          sx={{
            height: '80px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme => theme.palette.primary.main,
          }}
        >
          <Typography
            variant="overline"
            sx={{
              fontSize: '18px',
              lineHeight: '27px',
              textAlign: 'center',
              color: theme => theme.palette.secondary.main,
            }}
          >
            {textData.contactUs}
            <Box component="span" sx={{ marginLeft: '10px' }}>
              <a
                href="mailto:support@letsstring.store"
                style={{ textDecoration: 'none', color: 'rgba(255, 255, 255, 1)' }}
              >
                {textData.contactEmail}
              </a>
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
