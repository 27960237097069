import { EColor } from "../types/EColor";
import { ECompositingOperation } from "../types/ECompositingOperation";
import { ILine } from "../types/ILine";
import { IPlotterInfo } from "../types/IPlotterInfo";
import { IPoint } from "../types/IPoint";
import { ISize } from "../types/ISize";

export abstract class PlotterBase {
  public abstract resize(): void;

  public abstract initialize(infos: IPlotterInfo): void;

  public abstract finalize(): void;

  public abstract drawLines(
    lines: ILine[],
    color: EColor,
    opacity: number,
    operation: ECompositingOperation,
    thickness: number
  ): void;

  public abstract drawPoints(points: IPoint[], color: string, diameter: number): void;

  public abstract get size(): ISize;

  public drawBrokenLine(
    points: IPoint[],
    color: EColor,
    opacity: number,
    operation: ECompositingOperation,
    thickness: number
  ): void {
    const lines: ILine[] = [];

    for (let i = 0; i < points.length - 1; i++) {
      lines.push({ from: points[i], to: points[i + 1] });
    }

    this.drawLines(lines, color, opacity, operation, thickness);
  }
}
