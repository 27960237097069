import { Grid, Typography } from '@mui/material';
import { EmailFormData } from 'modules/art/types';
import { FC } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';

type Props = {
  isStartLoading: boolean;
  emailData: EmailFormData;
  goBack: () => void;
  start: () => Promise<void>;
};

export const ConfirmEmailView: FC<Props> = ({ isStartLoading, emailData, goBack, start }) => {
  const { textData } = useLangContext();

  return (
    <PublicPageLayout
      title={
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { md: '50px', xs: '30px' },
            lineHeight: { md: '60px', xs: '36px' },
            fontWeight: 275,
            maxWidth: '330px',
            paddingRight: '20px',
          }}
        >
          {textData.confirmEmailPageTitle}
        </Typography>
      }
    >
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: '490px',
          padding: '18px',
          textAlign: 'center',
        }}
      >
        <Grid item>
          <Typography
            variant="overline"
            sx={{
              fontSize: '18px',
              lineHeight: '32px',
              wordBreak: 'break-all',
            }}
          >
            {emailData.email}
          </Typography>
        </Grid>

        <Grid item sx={{ marginBottom: '50px' }}>
          <Typography
            variant="overline"
            sx={{
              fontSize: '16px',
              lineHeight: '23px',
            }}
          >
            {textData.confirmEmailText}
          </Typography>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <ButtonWithIcon
              type="button"
              icon={<CustomIcon name="arrowBottomLeftBlack" />}
              position="start"
              color="light"
              onClick={goBack}
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '30px', sm: '22px', xs: '22px' },
                  lineHeight: { md: '30px', xs: '18px' },
                }}
              >
                {textData.noButton}
              </Typography>
            </ButtonWithIcon>
          </Grid>

          <Grid item xs={6}>
            <ButtonWithIcon
              type="button"
              icon={<CustomIcon name="arrowBottomRightBlack" />}
              color="light"
              position="end"
              loading={isStartLoading}
              onClick={start}
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '30px', sm: '22px', xs: '22px' },
                  lineHeight: { md: '30px', xs: '18px' },
                }}
              >
                {textData.yesButton}
              </Typography>
            </ButtonWithIcon>
          </Grid>
        </Grid>
      </Grid>
    </PublicPageLayout>
  );
};
