import { Theme } from '@emotion/react';
import { SxProps, TextField } from '@mui/material';
import { ChangeEvent, ReactElement, ReactNode } from 'react';
import { Controller, FieldValues, Path, RegisterOptions, UseFormReturn } from 'react-hook-form';

export interface FormTextBoxProps<T extends FieldValues> {
  fieldName: Path<T>;
  form: UseFormReturn<T>;
  validation?: RegisterOptions;
  label?: ReactNode;
  placeholder?: string;
  note?: ReactNode;
  type?: 'number' | 'password';
  isDisabled?: boolean;
  autoComplete?: 'new-password';
  margin?: 'dense' | 'normal' | 'none';
  isMultiline?: boolean;
  rows?: number;
  maxRows?: number;
  styles?: SxProps<Theme>;
  actionOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormTextBox = <T extends object>(props: FormTextBoxProps<T>): ReactElement | null => {
  const {
    fieldName,
    form,
    validation,
    label,
    placeholder,
    note,
    type,
    isDisabled,
    autoComplete,
    margin = 'normal',
    isMultiline,
    rows,
    styles,
    actionOnChange,
  } = props;

  return (
    <Controller
      name={fieldName}
      control={form.control}
      rules={validation}
      render={({ field, fieldState, formState }) => {
        const { error } = fieldState;

        const { onChange, ...rest } = field;

        const change = (e: ChangeEvent<HTMLInputElement>) => {
          actionOnChange?.(e);
          onChange(e);
        };

        return (
          <TextField
            sx={styles}
            {...rest}
            onChange={change}
            multiline={isMultiline}
            rows={rows}
            label={label}
            placeholder={placeholder}
            helperText={!!error ? error.message : note}
            type={type}
            required={!!validation?.required}
            disabled={isDisabled}
            autoComplete={autoComplete}
            fullWidth
            margin={margin}
            error={!!error}
          />
        );
      }}
    />
  );
};
