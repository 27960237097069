import 'context-filter-polyfill';

export function disposeCanvas(canvas) {
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx.filter = null;
  ctx && ctx.clearRect(0, 0, 1, 1);
  canvas.remove();
}

export function applyFiltersToImage(
  imageURL: string,
  filters: string[],
  callBack: (dataUrls: string[]) => void,
) {
  const image = new Image();
  image.src = imageURL;

  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    callBack(
      filters.map(f => {
        ctx.filter = f;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL('image/png');
      }),
    );
    disposeCanvas(canvas);
  };
}
