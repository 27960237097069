import { Box, Grid, Typography } from '@mui/material';
import { EmailFormData } from 'modules/art/types';
import { FC } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { FormComponentProps, FormTextBox } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { EMAIL_PATTERN } from 'shared/tools';
import './style.css';
import { useLangContext } from 'shared/features/Language';

type Props = {
  goBack: () => void;
};

export const EmailForm: FC<FormComponentProps<EmailFormData, Props>> = ({ form, goBack }) => {
  const { textData } = useLangContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextBox
            form={form}
            placeholder="Email"
            fieldName="email"
            validation={{
              required: textData.requiredEmail,
              pattern: {
                value: EMAIL_PATTERN,
                message: textData.enterEmailError,
              },
            }}
            styles={{
              flexDirection: 'column-reverse',
              '& > .Mui-error': {
                color: '#FF0000',
              },
              '& > p': {
                fontSize: { md: '16px', xs: '14px' },
                lineHeight: { md: '20px', xs: '18px' },
                color: '#FF0000',
                marginBottom: '10px',
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <ButtonWithIcon
            type="button"
            icon={<CustomIcon name="arrowBottomLeftBlack" />}
            position="start"
            color="light"
            onClick={goBack}
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: { xl: '24px', xs: '22px' },
              }}
            >
              {textData.buttonBack}
            </Typography>
          </ButtonWithIcon>
        </Grid>

        <Grid item xs={6}>
          <ButtonWithIcon
            type="submit"
            disabled={form.formState.isSubmitting}
            icon={<CustomIcon name="arrowBottomRightBlack" />}
            color="light"
            position="end"
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: { xl: '24px', xs: '22px' },
              }}
            >
              {textData.sendButton}
            </Typography>
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </Box>
  );
};
