import { Container, Grid } from '@mui/material';
import React, { FC } from 'react';
import { FormView } from 'shared/components';
import { useLoginService } from '../../services';
import { LoginForm } from './LoginForm';

export const LoginView: FC = () => {
  const { initFormData, login, error } = useLoginService();

  return (
    <Grid container flex="1 1 auto" alignItems="center" margin="100px 0">
      <Container maxWidth="sm">
        <FormView
          formData={initFormData}
          component={LoginForm}
          onSubmit={login}
          errorList={
            !!error
              ? [
                  { field: 'username', message: 'Invalid email or invalid password' },
                  { field: 'password', message: 'Invalid email or invalid password' },
                ]
              : undefined
          }
        />
      </Container>
    </Grid>
  );
};
