import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/tools';
import { useConsumerArtApi } from '../api';
import { setIsLoading } from '../slices';
import { SaveStepRequest } from '../types';

export const useAssemblyService = () => {
  const dispatch = useDispatch();
  const consumerApi = useConsumerArtApi();
  const { loading, isReadyArt } = useAppSelector(state => state.art);

  const saveStep = useCallback(
    async (data: SaveStepRequest) => {
      dispatch(setIsLoading({ saveStep: true }));

      try {
        return await consumerApi.saveArtStep(data);
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsLoading({ saveStep: false }));
      }
    },
    [consumerApi],
  );

  const resumeArt = useCallback(
    async sessionId => {
      dispatch(setIsLoading({ resumeArt: true }));

      try {
        return await consumerApi.resumeArt(sessionId);
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsLoading({ resumeArt: false }));
      }
    },
    [consumerApi],
  );

  return {
    saveStep,
    resumeArt,
    loading,
    isReadyArt,
  };
};
