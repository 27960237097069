import { createConsumerCodeStorage } from 'modules/auth';
import { Config } from '../../../app/config';
import { DefaultApiClient } from '../api';
import { createAdminTokenStorage, createConsumerTokenStorage } from '../token';
import { ApiUrlBuilder, AppUrlBuilder } from '../url';
import { Services } from './types';

export function createServices(appConfig: Config): Services {
  const apiUrlBuilder = new ApiUrlBuilder(appConfig.apiBaseUrl);
  const appUrlBuilder = new AppUrlBuilder();

  const adminTokenStorage = createAdminTokenStorage();
  const consumerTokenStorage = createConsumerTokenStorage();
  const consumerCodeStorage = createConsumerCodeStorage();

  const apiAdminClient = new DefaultApiClient(adminTokenStorage);
  const apiConsumerClient = new DefaultApiClient(consumerTokenStorage);

  return {
    apiUrlBuilder,
    appUrlBuilder,
    adminTokenStorage,
    consumerTokenStorage,
    consumerCodeStorage,
    apiAdminClient,
    apiConsumerClient,
  };
}
