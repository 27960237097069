import { Box, Button, Card, CardContent, Container } from '@mui/material';
import { FC } from 'react';
import { FormComponentProps, FormTextBox } from 'shared/components';
import { CouponCount } from '../types';

export const CouponsForm: FC<FormComponentProps<CouponCount>> = props => {
  const { form } = props;

  return (
    <Container maxWidth="xs">
      <Card>
        <CardContent
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box mt={1}>
            <FormTextBox
              form={form}
              type="number"
              label="Count"
              fieldName="count"
              validation={{
                required: 'Count is required',
                min: {
                  value: 1,
                  message: 'Min 1',
                },
              }}
            />
          </Box>

          <Box mt={3}>
            <Button
              variant="contained"
              type="submit"
              disabled={form.formState.isSubmitting}
              fullWidth
              size="large"
              sx={{
                backgroundColor: '#272727',

                '&:hover': {
                  backgroundColor: 'white',
                  color: '#272727',
                },
              }}
            >
              GENERATE
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};
