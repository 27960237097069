import { Route } from '../router';
import { setupStore } from '../store';
import { ModuleConfig } from './types';

export const configureModules = (config: ModuleConfig) => {
  const { modules } = config;

  const routes = modules.reduce<Route[]>((acc, module) => {
    const routes = module.getRoutes();

    if (routes) {
      return [...acc, ...routes];
    }

    return acc;
  }, []);

  const store = setupStore();

  return {
    routes,
    store,
  };
};
