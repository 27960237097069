import { Box, LinearProgress } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

export const Loading: FC = () => {
  return (
    <Box padding="50px 10%" width="100%">
      <LinearProgress />
    </Box>
  );
};

interface LoadingContainerProps {
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  flex?: string;
}

export const LoadingContainer: FC<PropsWithChildren<LoadingContainerProps>> = ({height = '100%', maxHeight, minHeight, flex, children}) => {
  
  return (
    <Box
      alignItems="center"
      sx={{
        height,
        maxHeight,
        minHeight,
        display: 'flex',
        alignItems: 'center',
        flex,
      }}
    >
      {children}
    </Box>
  );
};
