import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';
import { useAppUrlBuilderContext } from 'shared/tools';

type Props = {
  isStartLoading: boolean;
  toBuilder: () => void;
  download: () => Promise<void>;
};

export const FinishSelectView: FC<Props> = ({ toBuilder, download, isStartLoading }) => {
  const appUrlBuilder = useAppUrlBuilderContext();
  const { textData } = useLangContext();

  return (
    <PublicPageLayout
      title={
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { md: '40px', xs: '26px' },
            lineHeight: { md: '48px', xs: '30px' },
            fontWeight: 275,
            maxWidth: { md: '430px', xs: '100%' },
            paddingRight: '20px',
          }}
        >
          {textData.finalPageTitle}
        </Typography>
      }
    >
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: '625px',
          padding: '18px',
          gap: { md: '20px', xs: '10px' },
        }}
      >
        <Grid item sx={{ marginBottom: { md: '30px', xs: '10px' }, textAlign: 'center' }}>
          <Typography
            variant="overline"
            sx={{
              fontSize: { md: '20px', xs: '16px' },
              lineHeight: { md: '26px', xs: '19px' },
              fontWeight: 275,
            }}
          >
            {textData.finalPageText}
          </Typography>
        </Grid>

        <Grid item>
          <ButtonWithIcon
            type="button"
            icon={
              <CustomIcon
                name="arrowTopBlack"
                styles={{
                  '& > svg': {
                    width: { md: '43px', xs: '26px' },
                    height: { md: '43px', xs: '26px' },
                  },
                }}
              />
            }
            onClick={toBuilder}
            position="end"
            color="light"
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: { md: '24px', sm: '22px', xs: '16px' },
                lineHeight: { md: '34px', xs: '19px' },
              }}
            >
              {textData.startAssemblyButton}
            </Typography>
          </ButtonWithIcon>
        </Grid>

        <Grid item>
          <ButtonWithIcon
            icon={
              <CustomIcon
                name="arrowTopBlack"
                styles={{
                  '& > svg': {
                    width: { md: '43px', xs: '26px' },
                    height: { md: '43px', xs: '26px' },
                  },
                }}
              />
            }
            color="light"
            position="end"
            onClick={download}
            loading={isStartLoading}
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: { md: '24px', sm: '22px', xs: '16px' },
                lineHeight: { md: '29px', sm: '20px', xs: '12px' },
              }}
            >
              {textData.downloadPDFButton}
            </Typography>
          </ButtonWithIcon>
        </Grid>

        <Grid item>
          <Link to={appUrlBuilder.home()}>
            <ButtonWithIcon
              icon={
                <CustomIcon
                  name="arrowTopBlack"
                  styles={{
                    '& > svg': {
                      width: { md: '43px', xs: '26px' },
                      height: { md: '43px', xs: '26px' },
                    },
                  }}
                />
              }
              color="light"
              position="end"
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '24px', sm: '22px', xs: '16px' },
                  lineHeight: { md: '36px', xs: '19px' },
                }}
              >
                {textData.goToMainPageButton}
              </Typography>
            </ButtonWithIcon>
          </Link>
        </Grid>
      </Grid>
    </PublicPageLayout>
  );
};
