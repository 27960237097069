import { Box, Grid, Typography } from '@mui/material';
import { CreateArtRequest, UploadPhotoFormData } from 'modules/art';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon, FormTextBox, FormUploadButton } from 'shared/components';
import { useLangContext } from 'shared/features/Language';
import { useAppUrlBuilderContext } from 'shared/tools';

export const UploadPhotoForm2: FC<{
  initForm: Omit<CreateArtRequest, 'image'>;
  submit: (file: UploadPhotoFormData & Omit<CreateArtRequest, 'image'>) => void;
}> = props => {
  const { initForm, submit } = props;
  const appUrlBuilder = useAppUrlBuilderContext();
  const { textData } = useLangContext();

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: { ...initForm, newImage: null },
  });

  const formData = form.watch();

  useEffect(() => {
    if (formData.newImage) {
      form.handleSubmit(submit)();
    }
  }, [!!formData.newImage]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '18px',
      }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ flexDirection: 'column' }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{}}>
          <Grid item>
            <FormTextBox
              form={form}
              label="Query"
              fieldName="query"
              validation={{
                required: 'Query is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Points"
              fieldName="points"
              type="number"
              validation={{
                required: 'Points is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Steps"
              fieldName="steps"
              type="number"
              validation={{
                required: 'Steps is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Seed"
              fieldName="seed"
              type="number"
              validation={{
                required: 'Seed is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Index"
              fieldName="index"
              type="number"
              validation={{
                required: 'Index is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Width"
              fieldName="width"
              type="number"
              validation={{
                required: 'Width is required',
              }}
            />
          </Grid>

          <Grid item>
            <FormTextBox
              form={form}
              label="Weight"
              fieldName="weight"
              type="number"
              validation={{
                required: 'Weight is required',
              }}
            />
          </Grid>
        </Grid>

        <Grid item>
          <FormUploadButton
            errorText="Select images"
            form={form}
            label={textData.uploadButton}
            fieldName="newImage"
          />
        </Grid>

        <Grid item>
          <Link to={appUrlBuilder.consumerVerify()}>
            <ButtonWithIcon
              type="button"
              icon={<CustomIcon name="arrowBottomLeftBlack" />}
              position="start"
              color="light"
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '30px', xs: '18px' },
                  lineHeight: { md: '30px', xs: '18px' },
                  margin: '0 20px',
                }}
              >
                {textData.buttonBack}
              </Typography>
            </ButtonWithIcon>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
