import { Box, Typography } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';

type InfoBlockProps = {
  color: 'white' | 'black';
  hover?: boolean;
  onClick?: () => void;
};

export const InfoBlock: FC<PropsWithChildren<InfoBlockProps>> = props => {
  const { color, hover, onClick, children } = props;

  const { button, text } = useMemo(() => {
    const button = {
      minWidth: '145px',
      minHeight: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '30px',
    };

    const text = {
      fontSize: { sm: '20px', xs: '18px' },
      lineHeight: '36px',
      textTransform: 'uppercase',
      padding: '0 10px',
    };

    if (color === 'white') {
      Object.assign(button, {
        backgroundColor: theme => theme.palette.secondary.main,
      });

      Object.assign(text, {
        color: theme => theme.palette.primary.main,
      });

      if (hover) {
        Object.assign(button, {
          '&:hover': {
            border: theme => `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme => theme.palette.primary.main,

            '.MuiTypography-root': {
              color: theme => theme.palette.secondary.main,
            },
          },
        });
      }
    }

    if (color === 'black') {
      Object.assign(button, {
        border: theme => `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme => theme.palette.primary.main,
      });

      Object.assign(text, {
        color: theme => theme.palette.secondary.main,
      });

      if (hover) {
        Object.assign(button, {
          '&:hover': {
            backgroundColor: theme => theme.palette.secondary.main,

            '.MuiTypography-root': {
              color: theme => theme.palette.primary.main,
            },
          },
        });
      }
    }

    if (!!onClick) {
      Object.assign(button, {
        cursor: 'pointer',
      });
    }

    return { button, text };
  }, [color, hover, onClick]);

  return (
    <Box sx={button} onClick={onClick}>
      <Typography variant="overline" sx={text}>
        {children}
      </Typography>
    </Box>
  );
};
