import { Box, Grid, Typography } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { useLangContext } from 'shared/features/Language';

export const HomeFooter: FC = () => {
  const { textData } = useLangContext();

  const getText = useCallback(
    (content: ReactNode) => (
      <Typography
        variant="overline"
        sx={{
          fontSize: '18px',
          lineHeight: '27px',
          color: theme => theme.palette.secondary.main,
        }}
      >
        {content}
      </Typography>
    ),
    [],
  );

  return (
    <Grid
      container
      spacing={1}
      sx={{
        paddingBottom: {
          md: '70px',
          xs: 0,
        },
        paddingLeft: {
          xl: '6%',
          lg: '8%',
          md: '11%',
          xs: 0,
        },
        flexDirection: 'column',
        justifyContent: {
          md: 'flex-start',
          xs: 'center',
        },
        alignItems: {
          md: 'initial',
          xs: 'center',
        },
      }}
    >
      {getText(textData.contactUs)}
      <Box sx={{ marginLeft: '4px' }} />
      {getText(
        <a
          href="mailto:support@letsstring.store"
          style={{
            textDecoration: 'none',
            color: 'rgba(255, 255, 255, 1)',
          }}
        >
          {textData.contactEmail}
        </a>,
      )}
    </Grid>
  );
};
