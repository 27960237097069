import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { useLangContext } from 'shared/features/Language';

export const PublicLayoutAccordion: FC = () => {
  const { textData } = useLangContext();

  const accordionData = [
    { question: textData.FAQTitle1, answer: textData.FAQAnswer1 },
    { question: textData.FAQTitle2, answer: textData.FAQAnswer2 },
    { question: textData.FAQTitle3, answer: textData.FAQAnswer3 },
    { question: textData.FAQTitle4, answer: textData.FAQAnswer4 },
    { question: textData.FAQTitle5, answer: textData.FAQAnswer5 },
    { question: textData.FAQTitle6, answer: textData.FAQAnswer6 },
    { question: textData.FAQTitle7, answer: textData.FAQAnswer7 },
    { question: textData.FAQTitle8, answer: textData.FAQAnswer8 },
  ];

  return (
    <Box>
      {accordionData.map((item, index) => (
        <Accordion key={index} sx={{ boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={
              <CustomIcon name="arrowTopBlack" styles={{ transform: 'rotate(180deg)' }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: '20px',
                lineHeight: '20px',
                color: theme => theme.palette.primary.main,
              }}
            >
              {item.question}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography
              variant="overline"
              sx={{
                fontSize: '14px',
                lineHeight: '19.6px',
                color: theme => theme.palette.primary.main,
              }}
            >
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
