import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'shared/tools';
import { useConsumerArtApi } from '../api';
import { setIsLoading, setReadyArt } from '../slices';
import {
  CreateArtRequest,
  EmailFormData,
  GenerateArtRequestType,
  SendDataRequest,
  StartRequest,
} from '../types';

export const useCreateArtService = () => {
  const dispatch = useDispatch();
  const consumerApi = useConsumerArtApi();
  const { loading, isReadyArt } = useAppSelector(state => state.art);

  const initFormData: EmailFormData = useMemo(() => ({ email: '' }), []);

  const chooseReadyArt = useCallback(async () => {
    try {
      dispatch(setReadyArt());
    } catch (e) {
      throw e;
    }
  }, [dispatch]);

  const startArt = useCallback(
    async (data: StartRequest) => {
      dispatch(setIsLoading({ startArt: true }));

      try {
        return await consumerApi.startArt(data);
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsLoading({ startArt: false }));
      }
    },
    [consumerApi],
  );

  const sendPDF = useCallback(
    async (data: SendDataRequest) => {
      dispatch(setIsLoading({ sendPDF: true }));

      try {
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('file', data.file);
        formData.append('name', data.name);
        formData.append('languageId', data.languageId);
        await consumerApi.sendPDF(formData);
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsLoading({ sendPDF: false }));
      }
    },
    [consumerApi],
  );

  const generatePdf = useCallback(
    async (data: GenerateArtRequestType) => {
      dispatch(setIsLoading({ sendPDF: true }));

      try {
        return await consumerApi.generateArt(data);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        dispatch(setIsLoading({ sendPDF: false }));
      }
    },
    [consumerApi],
  );

  const createArt = useCallback(
    async (data: CreateArtRequest) => {
      dispatch(setIsLoading({ createArt: true }));

      try {
        return await consumerApi.createArt(data);
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsLoading({ createArt: false }));
      }
    },
    [consumerApi],
  );

  const downloadPDF = useCallback(async () => {
    try {
      return await consumerApi.downloadPDF();
    } catch (e) {
      throw e;
    }
  }, [consumerApi]);

  return {
    initFormData,
    chooseReadyArt,
    startArt,
    sendPDF,
    loading,
    isReadyArt,
    generatePdf,
    downloadPDF,
    createArt,
  };
};
