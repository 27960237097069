import { Button, Grid } from '@mui/material';
import { useLoginService } from 'modules/auth';
import React, { FC } from 'react';

type Props = {
  isAuth: boolean | null;
};

export const AuthButton: FC<Props> = props => {
  const { isAuth } = props;
  const { logout } = useLoginService();
  return <Grid item>{isAuth && <Button onClick={logout}>Logout</Button>}</Grid>;
};
