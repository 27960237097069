import { Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon, IconName } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { useAppUrlBuilderContext } from 'shared/tools';

export const HomeButtonBlock = () => {
  const appBuilder = useAppUrlBuilderContext();
  const { textData } = useLangContext();

  const getLink = useCallback(
    (title: string, link: string, iconName: IconName) => (
      <Link to={link}>
        <ButtonWithIcon
          icon={
            <CustomIcon
              name={iconName}
              styles={{
                maxWidth: { md: '38px', xs: '25px' },
                maxHeight: { md: '38px', xs: '25px' },
                '& > svg': { fill: 'white' },
              }}
            />
          }
          color="dark"
          position="end"
        >
          <Typography
            variant="overline"
            sx={{
              fontSize: {
                lg: '30px',
                xs: '22px',
              },
              lineHeight: '36px',
              padding: { md: '12px', xs: '0 12px !important' },
            }}
          >
            {title}
          </Typography>
        </ButtonWithIcon>
      </Link>
    ),
    [],
  );

  return (
    <Grid
      item
      container
      spacing={3}
      sm={7}
      md={10}
      sx={{
        justifyContent: { xs: 'center' },
        alignItems: 'center',
        gap: { md: '15px', xs: 0 },
      }}
    >
      <Grid
        item
        sx={{
          paddingLeft: 0,
          paddingTop: { md: '24px', xs: '10px !important' },
        }}
      >
        {getLink(textData.uploadPhotoButtonHomePage, appBuilder.consumerVerify(), 'upload')}
      </Grid>

      <Grid
        item
        sx={{
          paddingLeft: 0,
          paddingTop: { md: '24px', xs: '10px !important' },
        }}
      >
        {getLink(
          textData.chooseDesignsButtonHomePage,
          appBuilder.consumerDesigns(),
          'arrowTopWhite',
        )}
      </Grid>
    </Grid>
  );
};
