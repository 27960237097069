import { IStorage } from './types';

export class DefaultStorage implements IStorage {
  private storageKey: string;
  private storage: Storage;

  constructor(storage: Storage, storageKey: string) {
    this.storage = storage;
    this.storageKey = storageKey;
  }

  get() {
    return this.storage.getItem(this.storageKey);
  }

  set(value: string) {
    this.storage.setItem(this.storageKey, value);
  }

  remove() {
    this.storage.removeItem(this.storageKey);
  }
}
