import { Button, Typography } from '@mui/material';
import { ChangeEvent, ReactElement, useEffect } from 'react';
import { Path, PathValue, UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { CustomIcon } from 'shared/assets/icons';

interface Props<T extends object> {
  label: string;
  form: UseFormReturn<T>;
  fieldName: Path<T>;
  errorText: string;
}

export const FormUploadButton = <T extends object>(props: Props<T>): ReactElement | null => {
  const { form, fieldName, label } = props;

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files?.length) {
      form.setValue(fieldName, event.target.files[0] as UnpackNestedValue<PathValue<T, Path<T>>>);
      form.trigger(fieldName);
    }
  };

  useEffect(() => {
    form.register(fieldName);
  }, [fieldName, form]);

  return (
    <>
      <Button
        component="label"
        variant="outlined"
        endIcon={<CustomIcon name="uploadBlack" />}
        sx={{
          borderRadius: '45px',
          border: theme => `2px solid ${theme.palette.primary.main}`,
          padding: '0 50px',

          '&:hover': {
            color: theme => theme.palette.secondary.main,
            backgroundColor: theme => theme.palette.primary.main,
            borderColor: theme => theme.palette.primary.main,

            '& svg, & path': {
              fill: theme => theme.palette.secondary.main,
            },

            '& > .MuiButton-endIcon': {
              '& > .MuiBox-root': {
                '& > svg': {
                  fill: theme => theme.palette.secondary.main,
                },
              },
            },
          },
        }}
      >
        <Typography
          variant="overline"
          sx={{ fontSize: { md: '24px', xs: '22px' }, paddingLeft: { md: '20px', xs: 0 } }}
        >
          {label}
        </Typography>

        <input hidden accept="image/*" multiple type="file" onChange={handleSelectFile} />
      </Button>
    </>
  );
};
