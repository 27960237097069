import { Grid, Typography } from '@mui/material';
import { SoundPlayerController } from 'modules/art/controllers';
import { FC } from 'react';

type Props = {
  soundPlayerController: SoundPlayerController;
};

export const SlidesSpeedsView: FC<Props> = props => {
  const { soundPlayerController } = props;

  return (
    <Grid
      container
      sx={{
        width: 'fit-content',
        flexDirection: { sm: 'row', xs: 'column' },
        flexWrap: 'nowrap',
        justifyContent: 'center',
        gap: '4px',
      }}
    >
      {soundPlayerController.speeds.map((item, index) => {
        const isSelected = item.value === soundPlayerController.state.speed;

        return (
          <Grid
            key={index}
            item
            onClick={() => soundPlayerController.setSpeed(item.value)}
            sx={{
              width: { sm: '60px', xs: '50px' },
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: theme => (isSelected ? `1px solid ${theme.palette.secondary.main}` : 'none'),
              borderRadius: '10px',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: isSelected ? '18px' : '16px',
                lineHeight: '24px',
                color: theme => theme.palette.secondary.main,
              }}
            >
              {item.title}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
