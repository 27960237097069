import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

type Props = {
  type?: 'submit' | 'button';
  icon: ReactNode;
  position: 'start' | 'end';
  color: 'dark' | 'light';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
};

export const ButtonWithIcon: FC<PropsWithChildren<Props>> = ({
  type,
  icon,
  position,
  color,
  disabled,
  loading,
  onClick,
  children,
}) => {
  const { palette } = useTheme();
  const primaryColor = color === 'dark' ? palette.primary.main : palette.secondary.main;
  const secondaryColor = color === 'dark' ? palette.secondary.main : palette.primary.main;

  return (
    <Button
      fullWidth
      type={type}
      onClick={onClick}
      variant="outlined"
      disabled={disabled || loading}
      startIcon={
        position === 'start' && (
          <Box>
            {loading ? (
              <CircularProgress size="32px" sx={{ margin: '6px', color: secondaryColor }} />
            ) : (
              icon
            )}
          </Box>
        )
      }
      endIcon={
        position === 'end' && (
          <Box>
            {loading ? (
              <CircularProgress size="32px" sx={{ margin: '6px', color: secondaryColor }} />
            ) : (
              icon
            )}
          </Box>
        )
      }
      sx={{
        borderRadius: '45px',
        border: `2px solid ${secondaryColor}`,
        padding: '0',

        '& > .MuiButton-startIcon': {
          padding: '10px',
          margin: '0',
          borderRadius: '50%',
          backgroundColor: primaryColor,
          boxShadow: `0px 0px 1px 2px ${secondaryColor}`,

          '& > .MuiBox-root': {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          },
        },

        '& > .MuiButton-endIcon': {
          padding: '10px',
          margin: '0',
          borderRadius: '50%',
          backgroundColor: primaryColor,
          boxShadow: `0px 0px 1px 2px ${secondaryColor}`,

          '& > .MuiBox-root': {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center',
          },
        },

        '& > .MuiBox-root': {
          '& > .MuiTypography-root': {
            padding: '12px 12px ',
            color: secondaryColor,
            textAlign: 'center',
          },
        },

        '&:hover': {
          backgroundColor: secondaryColor,
          border: `2px solid ${primaryColor}`,

          '& > .MuiButton-startIcon': {
            backgroundColor: primaryColor,
            boxShadow: `2px solid ${secondaryColor}`,

            '& > svg': {
              fill: primaryColor,
            },
          },

          '& > .MuiButton-endIcon': {
            backgroundColor: primaryColor,
            boxShadow: `2px solid ${secondaryColor}`,

            '& > svg': {
              fill: primaryColor,
            },
          },

          '.MuiTypography-root': {
            color: primaryColor,
          },
        },
      }}
    >
      <Box sx={{ flex: '1 1' }}>{children}</Box>
    </Button>
  );
};
