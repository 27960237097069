import { Box, Grid, Typography } from '@mui/material';
import { CreateArtResponse } from 'modules/art/types';
import { FC } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { Loading, LoadingContainer } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';

type Props = {
  isGenerateLoading: boolean;
  beGeneration: CreateArtResponse;
  goBack?(): void;
  goFurther?(): void;
};

export const SelectVariantView2: FC<Props> = ({
  isGenerateLoading,
  beGeneration,
  goBack,
  goFurther,
}) => {
  const { textData } = useLangContext();

  return (
    <>
      <PublicPageLayout
        title={
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: { md: '50px', xs: '30px' },
              lineHeight: { md: '60px', xs: '36px' },
              maxWidth: '394px',
              paddingRight: {
                xs: '20px ',
              },
              paddingTop: { md: '40px', xs: 0 },
            }}
          >
            {textData.cropPageTitle}
          </Typography>
        }
      >
        {isGenerateLoading || !beGeneration ? (
          <LoadingContainer flex="1 1">
            <Loading />
          </LoadingContainer>
        ) : (
          <Grid
            container
            sx={{
              height: '100%',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
              padding: '18px',
              marginTop: { sm: 0, xs: '30px' },
              maxWidth: '570px',
            }}
          >
            <Grid
              item
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                height: { sm: '400px', xs: '535px' },
                maxWidth: { sm: 'inherit', xs: '400px' },
              }}
            >
              <Box
                component="img"
                src={beGeneration.imageUrl}
                alt=""
                sx={{
                  width: { sm: '400px', xs: '535px' },
                  height: { sm: '400px', xs: '535px' },
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
              />
            </Grid>

            <Grid
              item
              container
              spacing={2}
              sx={{ paddingLeft: { md: '50px', xs: 0 }, maxWidth: '490px' }}
            >
              <Grid item xs={6}>
                <ButtonWithIcon
                  type="button"
                  icon={<CustomIcon name="arrowBottomLeftBlack" />}
                  position="start"
                  color="light"
                  onClick={goBack}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: { md: '19px', sm: '22px', xs: '15px' },
                      lineHeight: { md: '20px', xs: '18px' },
                    }}
                  >
                    {textData.buttonBack}
                  </Typography>
                </ButtonWithIcon>
              </Grid>

              <Grid item xs={6}>
                <ButtonWithIcon
                  icon={<CustomIcon name="arrowBottomRightBlack" />}
                  color="light"
                  position="end"
                  loading={isGenerateLoading}
                  onClick={goFurther}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: { md: '19px', sm: '22px', xs: '15px' },
                      lineHeight: { md: '20px', xs: '18px' },
                    }}
                  >
                    {textData.buttonFurther}
                  </Typography>
                </ButtonWithIcon>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PublicPageLayout>
    </>
  );
};
