import React, { FC, PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

type Props = RouteProps & {
  isAuth?: boolean;
  isVerify?: boolean;
  availableFor: 'consumer' | 'admin';
  onlyNonAuth?: boolean;
};

export const ProtectedRoute: FC<PropsWithChildren<Props>> = ({ children, ...rest }) => {
  const { isAuth, isVerify, availableFor, onlyNonAuth, ...routeProps } = rest;
  const location = useLocation();

  if (!isAuth && availableFor === 'admin') {
    return (
      <Redirect
        to={{
          pathname: '/admin/login',
          state: { from: location },
        }}
      />
    );
  }

  if (!isVerify && availableFor === 'consumer') {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...routeProps}>{children}</Route>;
};
