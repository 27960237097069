import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, Drawer, Grid, IconButton } from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { CollapseItemLinkList, LinkButton, LinkMenu } from 'shared/components';
import { LayoutLinks } from 'shared/layout/types';

export const usePublicNavigation = (layoutLinks: LayoutLinks) => {
  return useMemo(
    () => ({
      menu: <PublicNavigationMenu layoutLinks={layoutLinks} />,
      drawer: <PublicNavigationDrawer layoutLinks={layoutLinks} />,
    }),
    [layoutLinks],
  );
};

type PublicNavigationDrawerProps = {
  layoutLinks: LayoutLinks;
};

const PublicNavigationDrawer: FC<PublicNavigationDrawerProps> = props => {
  const { layoutLinks } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenNavMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseNavMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Grid item>
      <IconButton size="large" color="inherit" onClick={handleOpenNavMenu}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="left" open={isOpen} onClose={handleCloseNavMenu}>
        <Box sx={{ width: 250 }} role="presentation" onKeyDown={handleCloseNavMenu}>
          {layoutLinks.adminPublic.map((group, i) => (
            <CollapseItemLinkList group={group} onLinkClick={handleCloseNavMenu} key={i} />
          ))}
        </Box>
      </Drawer>
    </Grid>
  );
};

type PublicNavigationMenuProps = {
  layoutLinks: LayoutLinks;
};

const PublicNavigationMenu: FC<PublicNavigationMenuProps> = props => {
  const { layoutLinks } = props;

  return (
    <>
      {layoutLinks.adminPublic.map((group, i) => {
        const { length } = group.items;

        if (length === 1) {
          return (
            <Grid item key={i}>
              <LinkButton to={group.items[0].link} variant="outlined">
                {group.items[0].title}
              </LinkButton>
            </Grid>
          );
        }

        return (
          <Grid item key={i}>
            <LinkMenu title={group.subheader} links={group.items} themeMode="light" />
          </Grid>
        );
      })}
    </>
  );
};
