import { Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  letters: [string, string];
  orientation: 'vertical' | 'horizontal';
};

export const LineWithLetterView: FC<Props> = props => {
  const { letters, orientation } = props;

  const isHorizontal = orientation === 'horizontal';

  return (
    <Grid
      container
      sx={{ width: isHorizontal ? '100%' : 'auto', flexDirection: isHorizontal ? 'row' : 'column' }}
    >
      <Grid item sx={{ flex: '1 1', margin: '8px', marginRight: isHorizontal ? '158px' : '8px' }}>
        <Divider
          orientation={orientation}
          sx={{
            '&:before, &:after': {
              borderTop: theme =>
                isHorizontal ? `thin solid ${theme.palette.secondary.main}` : undefined,
              borderLeft: theme =>
                !isHorizontal ? `thin solid ${theme.palette.secondary.main}` : undefined,
              opacity: '0.3',
            },
            '&:before': {
              width: isHorizontal ? '100%' : undefined,
              height: !isHorizontal ? '100%' : undefined,
            },
            '&:after': {
              width: isHorizontal ? '0%' : undefined,
              height: !isHorizontal ? '0%' : undefined,
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '30px',
              lineHeight: '36px',
              color: theme => theme.palette.secondary.main,
            }}
          >
            {letters[0]}
          </Typography>
        </Divider>
      </Grid>

      <Grid item sx={{ flex: '1 1', margin: '8px', marginLeft: isHorizontal ? '158px' : '8px' }}>
        <Divider
          orientation={orientation}
          sx={{
            '&:before, &:after': {
              borderTop: theme =>
                isHorizontal ? `thin solid ${theme.palette.secondary.main}` : undefined,
              borderLeft: theme =>
                !isHorizontal ? `thin solid ${theme.palette.secondary.main}` : undefined,
              opacity: '0.3',
            },
            '&:before': {
              width: isHorizontal ? '0%' : undefined,
              height: !isHorizontal ? '0%' : undefined,
            },
            '&:after': {
              width: isHorizontal ? '100%' : undefined,
              height: !isHorizontal ? '100%' : undefined,
            },
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: '30px',
              lineHeight: '36px',
              color: theme => theme.palette.secondary.main,
            }}
          >
            {letters[1]}
          </Typography>
        </Divider>
      </Grid>
    </Grid>
  );
};
