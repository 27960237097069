import { EColor } from "../types/EColor";
import { ECompositingOperation } from "../types/ECompositingOperation";
import { IColor } from "../types/IColor";

let supportsAdvancedCompositing = true;

function useAdvancedCompositing(): boolean {
  return supportsAdvancedCompositing;
}

function computeRawColor(color: EColor): IColor {
  if (color === EColor.MONOCHROME) {
    return {
      r: 1,
      g: 1,
      b: 1,
    };
  }

  const result: IColor = {
    r: color === EColor.RED ? 1 : 0,
    g: color === EColor.GREEN ? 1 : 0,
    b: color === EColor.BLUE ? 1 : 0,
  };

  return result;
}

function applyCanvasCompositing(
  context: CanvasRenderingContext2D,
  color: EColor,
  opacity: number,
  operation: ECompositingOperation
): void {
  const rawRGB = computeRawColor(color);

  if (supportsAdvancedCompositing) {
    const targetOperation = operation === ECompositingOperation.LIGHTEN ? "lighter" : "difference";

    context.globalCompositeOperation = targetOperation;

    if (context.globalCompositeOperation === targetOperation) {
      const value = Math.ceil(255 * opacity);
      context.strokeStyle = `rgb(${rawRGB.r * value}, ${rawRGB.g * value}, ${rawRGB.b * value})`;
      return;
    } else {
      supportsAdvancedCompositing = false;
      console.log(`Your browser does not support canvas2D compositing '${targetOperation}'.`);
    }
  }
}

function resetCanvasCompositing(context: CanvasRenderingContext2D): void {
  context.globalCompositeOperation = "source-over";
}

export {
  applyCanvasCompositing,
  computeRawColor,
  resetCanvasCompositing,
  useAdvancedCompositing,
};
