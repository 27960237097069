import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { PublicLayoutAccordion } from './PublicLayoutAccordion';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const drawerCloseStyles = {
  backgroundColor: 'rgba(255, 255, 255, 1)',
  height: { md: '100%', xs: '10%' },
  width: { md: '15%', xs: '100%' },
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  zIndex: { md: 3, xs: 1 },
  position: 'absolute',
  right: { md: 0, xs: 'inherit' },
  bottom: { md: 'inherit', xs: 0 },
  transition: '1s',
  '&:hover': {
    cursor: 'pointer',
  },
};

const drawerOpenStyles = {
  backgroundColor: 'rgba(255, 255, 255, 1)',
  height: { md: '100%', xs: '80%' },
  width: { md: '65%', xs: '100%' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexDirection: { md: 'row', xs: 'column' },
  zIndex: { md: 3, xs: 1 },
  position: 'absolute',
  right: { md: 0, xs: 'inherit' },
  paddingLeft: { md: '35px', xs: 0 },
  bottom: { md: 'inherit', xs: 0 },
  transition: '1s',
  '&:hover': {
    cursor: 'pointer',
  },
};

export const PublicLayoutDrawer: FC<Props> = ({ isOpen, setIsOpen }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={isOpen ? drawerOpenStyles : drawerCloseStyles}>
      <Typography
        variant="body1"
        sx={{
          flex: '1 1',
          transition: '1s',
          fontSize: '30px',
          fontWeight: 275,
          color: theme => theme.palette.primary.main,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <Box
            component="span"
            sx={{
              color: 'black',
              fontSize: '40px',
              position: 'absolute',
              display: { md: 'block', xs: 'none' },
            }}
          >
            X
          </Box>
        ) : matches ? (
          <Box
            component="span"
            sx={{
              color: 'black',
              fontSize: '25px',
            }}
          >
            FAQ
          </Box>
        ) : (
          <Box component="span" sx={{ color: 'black', fontSize: '40px' }}>
            |||
          </Box>
        )}
      </Typography>

      {isOpen && (
        <Box
          sx={{
            paddingRight: { md: '110px', xs: 0 },
            paddingLeft: '15px',
            maxWidth: '800px',
            maxHeight: '687px',
            flex: '4 1',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <PublicLayoutAccordion />
        </Box>
      )}

      {matches && isOpen && (
        <Grid
          item
          sx={{
            height: '80px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme => theme.palette.secondary.main,
            boxShadow: ' 1px -6px 30px 0px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="overline"
            sx={{
              transition: '1s',
              fontSize: '30px',
              fontWeight: 275,
              textAlign: 'center',
              color: theme => theme.palette.primary.main,
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            X
          </Typography>
        </Grid>
      )}
    </Box>
  );
};
