import { FormControl, Grid, MenuItem, Select, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { LangState } from 'shared/features/Language';
import { Logo } from 'shared/layout';

type Props = {
  lang: string;
  changeLang: (lang: LangState) => void;
  sx?: SxProps<Theme>;
};

const langs = ['en', 'es', 'it', 'pl'];

export const BuilderArtHeader: FC<Props> = props => {
  const { lang, changeLang, sx } = props;

  return (
    <Grid container sx={{ ...sx, alignItems: 'center', justifyContent: 'space-between' }}>
      <Grid item>
        <Logo color="white" sx={{ width: '109px', height: '47px' }} />
      </Grid>

      <Grid item sx={{ minWidth: '75px' }}>
        <FormControl fullWidth>
          <Select
            value={lang}
            onChange={e => changeLang(e.target.value as LangState)}
            sx={{
              backgroundColor: theme => theme.palette.secondary.main,

              svg: {
                color: theme => theme.palette.primary.main,
              },

              span: {
                color: theme => theme.palette.primary.main,
              },

              '&:hover': {
                border: theme => `1px solid ${theme.palette.secondary.main}`,
                backgroundColor: theme => theme.palette.primary.main,

                svg: {
                  color: theme => theme.palette.secondary.main,
                },

                span: {
                  color: theme => theme.palette.secondary.main,
                },
              },
            }}
          >
            {langs.map((lang, index) => (
              <MenuItem value={lang} key={index}>
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {lang.toLocaleUpperCase()}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
