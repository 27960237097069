import { Alert, Slide, Snackbar } from '@mui/material';
import React, { FC } from 'react';
import { useNotificationContext } from '../context';
import {
  ERROR_NOTIFICATION_DURATION,
  NOTIFICATION_DURATION,
} from './NotificationDuration';

export const Notifications: FC = () => {
  const { isOpened, notification, onClose } = useNotificationContext();

  return (
    <Snackbar
      open={isOpened}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={
        notification?.type === 'error' ? ERROR_NOTIFICATION_DURATION : NOTIFICATION_DURATION
      }
      TransitionComponent={Slide}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={notification?.type}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};
