import {
  Drawer as MuiDrawer,
  Grid,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SoundPlayerController } from 'modules/art';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components/Buttons';
import { useLangContext } from 'shared/features/Language';
import { theme } from 'themes';

type BuilderArtStepDrawerProps = {
  isOpen: boolean;
  close: () => void;
  changeStep: (newSlideNumber: number, closeDrawer: () => void) => Promise<void>;
  currentStep: number;
};

export const BuilderArtStepDrawer: FC<BuilderArtStepDrawerProps> = ({
  isOpen,
  close,
  changeStep,
  currentStep,
}) => {
  const { textData } = useLangContext();
  const [newSlideNumber, setNewSlideNumber] = useState<number>(null);
  const [drawerStep, setDrawerStep] = useState<'enter-step' | 'confirm-step'>('enter-step');

  const onClose = useCallback(() => {
    setDrawerStep('enter-step');
    setNewSlideNumber(null);
    close();
  }, []);

  return (
    <Drawer isOpen={isOpen} close={onClose}>
      <Grid
        container
        spacing={3}
        sx={{
          maxWidth: '435px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'nowrap',
        }}
      >
        {drawerStep === 'enter-step' && (
          <>
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '25px', xs: '20px' },
                  lineHeight: { md: '32px', xs: '26px' },
                }}
              >
                {textData.enterStepField}
              </Typography>
            </Grid>

            <Grid item sx={{ width: '100%' }}>
              <TextField
                value={newSlideNumber}
                onChange={e => setNewSlideNumber(Number(e.target.value))}
                type="number"
                fullWidth
                sx={{ borderRadius: '45px' }}
              />
            </Grid>

            <Grid item>
              <ButtonWithIcon
                type="button"
                icon={<CustomIcon name="arrowTopBlack" />}
                position="end"
                color="light"
                onClick={() => setDrawerStep('confirm-step')}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: '30px',
                    lineHeight: '30px',
                  }}
                >
                  Ok
                </Typography>
              </ButtonWithIcon>
            </Grid>
          </>
        )}

        {drawerStep === 'confirm-step' && (
          <>
            <Grid item>
              <Typography
                variant="overline"
                sx={{
                  fontSize: '35px',
                  lineHeight: '45px',
                }}
              >
                {textData.yourStep}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="overline"
                sx={{
                  fontSize: '35px',
                  lineHeight: '45px',
                }}
              >
                {newSlideNumber || currentStep}
              </Typography>
            </Grid>

            <Grid item>
              <ButtonWithIcon
                type="button"
                icon={<CustomIcon name="arrowTopBlack" />}
                position="end"
                color="light"
                onClick={() => changeStep(newSlideNumber || currentStep, onClose)}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: '30px',
                    lineHeight: '30px',
                  }}
                >
                  Ok
                </Typography>
              </ButtonWithIcon>
            </Grid>
          </>
        )}
      </Grid>
    </Drawer>
  );
};

type BuilderArtMelodyDrawerProps = {
  isOpen: boolean;
  close: () => void;
  soundPlayerController: SoundPlayerController;
};

export const BuilderArtMelodyDrawer: FC<BuilderArtMelodyDrawerProps> = ({
  isOpen,
  close,
  soundPlayerController,
}) => {
  const { state, melodies, setMelody } = soundPlayerController;

  return (
    <Drawer
      isOpen={isOpen}
      close={() => {
        close();
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          maxWidth: '435px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'nowrap',
        }}
      >
        <Grid item>
          <CustomIcon name="musicBlack" styles={{ '& > svg': { width: '40px', height: '40px' } }} />
        </Grid>

        {melodies.map((m, i) => (
          <Grid item key={i}>
            <ListItemButton
              selected={state.melodyName === m.value}
              onClick={() => setMelody(m.value)}
            >
              <ListItemText>
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: { md: '25px', xs: '20px' },
                    lineHeight: { md: '32px', xs: '26px' },
                  }}
                >
                  {m.title}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

type DrawerProps = {
  isOpen: boolean;
  close: () => void;
};

const Drawer: FC<PropsWithChildren<DrawerProps>> = ({ isOpen, close, children }) => {
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const width = matches ? '100%' : '50%';
  const height = matches ? '85%' : '100%';

  return (
    <MuiDrawer
      anchor={!!matches ? 'bottom' : 'right'}
      open={isOpen}
      onClose={close}
      sx={{
        width,
        height,

        '& .MuiDrawer-paper': {
          width,
          height,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px 20px',
        },
      }}
    >
      {children}
    </MuiDrawer>
  );
};
