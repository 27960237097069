import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import { ReadyArtDataType } from 'modules/art/types';
import { FC, useCallback, useState } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { Loading, LoadingContainer } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { StringArtGenerator } from 'shared/components/StringArtGenerator';
import { EMode } from 'shared/components/StringArtGenerator/parts/types/EMode';
import { EShape } from 'shared/components/StringArtGenerator/parts/types/EShape';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';

type Props = {
  image: {
    file: File | null;
    url: string | undefined;
    crop: string[] | undefined;
  };
  goBack?(): void;
  select: (data: ReadyArtDataType) => void;
};

export const SelectVariantView: FC<Props> = ({ image, goBack, select }) => {
  const [activeArtIndex, setActiveArtIndex] = useState<number>(0);
  const [artData, setArtData] = useState<ReadyArtDataType[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { textData } = useLangContext();
  const matches = useMediaQuery('(max-width:376px)');
  const quality = matches ? 2 : 3;

  const userAgent = window.navigator.userAgent;
  let lineOpacity = 0.4;
  let lineThickness = 0.035;
  const isAppleDevice = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
  if (isAppleDevice) {
    lineOpacity = 0.4;
    lineThickness = 0.025;
  } else {
    lineOpacity = 0.5;
    lineThickness = 0.035;
  }

  const handleClick = useCallback((data: ReadyArtDataType, index: number) => {
    setArtData(state => {
      const updatedState = state;
      updatedState[index] = data;
      return [...updatedState];
    });
    if (index === image.crop.length - 1) {
      setIsDisabled(false);
    }
  }, []);

  if (!image.crop) {
    return (
      <LoadingContainer flex="1 1">
        <Loading />
      </LoadingContainer>
    );
  }
  return (
    <>
      <PublicPageLayout
        title={
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: { md: '30px', xs: '26px' },
              lineHeight: { md: '40px', xs: '31px' },
              maxWidth: { md: '394px', xs: '100%' },
            }}
          >
            {textData.generateArtPageTitle}
          </Typography>
        }
      >
        <Grid
          container
          sx={{
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '15px',
            padding: '18px',
            marginTop: 0,
            maxWidth: '570px',
          }}
        >
          <Grid
            item
            container
            sx={{
              flexDirection: { sm: 'column', xs: 'row' },
              gap: '5px',
              marginBottom: 0,
              justifyContent: { sm: 'inherit', xs: 'center' },
              height: { sm: '400px', xs: '375px' },
              maxWidth: { sm: 'inherit', xs: '370px' },
              overflow: 'hidden',
            }}
          >
            {image?.crop?.map?.((c, i) => {
              return (
                <Grid
                  key={i}
                  item
                  onClick={() => setActiveArtIndex(i)}
                  sx={{
                    order: activeArtIndex === i ? { sm: 1, xs: 0 } : { sm: 0, xs: 1 },
                    height:
                      activeArtIndex === i
                        ? { sm: '372px', xs: '270px' }
                        : { sm: '96px', xs: '96px' },
                    width:
                      activeArtIndex === i
                        ? { sm: '372px', xs: '270px' }
                        : { sm: '96px', xs: '96px' },
                    '&:hover': { cursor: activeArtIndex === i ? 'auto' : 'pointer' },
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      justifyItems: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {i === 0 || !!artData[i - 1] || !isAppleDevice ? (
                      <StringArtGenerator
                        key={i}
                        imageSrc={c}
                        canvasSize={{
                          height: 500,
                          width: 500,
                        }}
                        parameters={{
                          pegsCount: 240,
                          nbLines: 3699,
                          mode: EMode.MONOCHROME,
                          shape: EShape.ELLIPSIS,
                          quality,
                          blur: 0,
                          lineOpacity,
                          lineThickness,
                          displayPegs: true,
                          invertColors: false,
                          intervalTimeout: matches ? 6 : 2,
                        }}
                        onFinish={data => handleClick(data, i)}
                      />
                    ) : (
                      <div>
                        <CircularProgress
                          size="32px"
                          sx={{ margin: '6px', color: theme => theme.palette.primary.main }}
                        />
                      </div>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            sx={{ paddingLeft: { md: '50px', xs: 0 }, maxWidth: '490px' }}
          >
            <Grid item xs={6}>
              <ButtonWithIcon
                type="button"
                icon={<CustomIcon name="arrowBottomLeftBlack" />}
                position="start"
                color="light"
                onClick={goBack}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: { md: '19px', sm: '22px', xs: '22px' },
                    lineHeight: { md: '20px', xs: '18px' },
                  }}
                >
                  {textData.buttonBack}
                </Typography>
              </ButtonWithIcon>
            </Grid>

            <Grid item xs={6}>
              <ButtonWithIcon
                icon={<CustomIcon name="arrowBottomRightBlack" />}
                color="light"
                position="end"
                loading={isDisabled}
                onClick={() => select(artData[activeArtIndex])}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: { md: '19px', sm: '22px', xs: '22px' },
                    lineHeight: { md: '20px', xs: '18px' },
                  }}
                >
                  {textData.buttonFurther}
                </Typography>
              </ButtonWithIcon>
            </Grid>
          </Grid>
        </Grid>
      </PublicPageLayout>
    </>
  );
};
