import { Box, Grid, Typography } from '@mui/material';
import { PortraitDataType, ReadyArtDataType } from 'modules/art/types';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { CustomIcon } from 'shared/assets/icons';
import { Loading, LoadingContainer } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';
import { useAppUrlBuilderContext } from 'shared/tools';

type Props = {
  select: (data: ReadyArtDataType) => void;
  currentReadyArt: PortraitDataType;
};

export const ShowReadyArtView: FC<Props> = ({ currentReadyArt, select }) => {
  const history = useHistory();
  const appBuilder = useAppUrlBuilderContext();
  const { textData } = useLangContext();

  if (!currentReadyArt.image) {
    return (
      <LoadingContainer flex="1 1">
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <>
      <PublicPageLayout
        title={
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: { md: '50px', xs: '30px' },
              lineHeight: { md: '60px', xs: '36px' },
              maxWidth: '394px',
              paddingRight: {
                xs: '20px ',
              },
              paddingTop: { md: '40px', xs: 0 },
            }}
          >
            {textData.showReadyArtTitle}
          </Typography>
        }
      >
        <Grid
          container
          sx={{
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            padding: '18px',
            marginTop: { sm: 0, xs: '30px' },
            maxWidth: '570px',
          }}
        >
          <Grid
            item
            container
            sx={{
              marginBottom: 0,
              width: '100%',
              height: '100%',
              maxWidth: '500px',
              maxHeight: '500px',
              overflow: 'hidden',
            }}
          >
            <Box
              component="img"
              alt={currentReadyArt.id}
              src={currentReadyArt.image}
              sx={{ height: '100%', width: '100%' }}
            />
          </Grid>

          <Grid item container spacing={2} sx={{ paddingLeft: 0, maxWidth: '490px' }}>
            <Grid item xs={6}>
              <ButtonWithIcon
                type="button"
                icon={<CustomIcon name="arrowBottomLeftBlack" />}
                position="start"
                color="light"
                onClick={() => history.push(appBuilder.home())}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: { md: '19px', sm: '22px', xs: '15px' },
                    lineHeight: { md: '20px', xs: '18px' },
                  }}
                >
                  {textData.buttonBack}
                </Typography>
              </ButtonWithIcon>
            </Grid>

            <Grid item xs={6}>
              <ButtonWithIcon
                icon={<CustomIcon name="arrowBottomRightBlack" />}
                color="light"
                position="end"
                onClick={() =>
                  select({
                    pegs: currentReadyArt.artData[0].pegs,
                    size: currentReadyArt.artData[0].size,
                    steps: currentReadyArt.artData[0].steps,
                    image: currentReadyArt.image,
                  })
                }
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: { md: '19px', sm: '22px', xs: '15px' },
                    lineHeight: { md: '20px', xs: '18px' },
                  }}
                >
                  {textData.buttonFurther}
                </Typography>
              </ButtonWithIcon>
            </Grid>
          </Grid>
        </Grid>
      </PublicPageLayout>
    </>
  );
};
