import { format } from 'date-fns';

export function formatDateByPattern(
  date: Date | number | string | null | undefined,
  pattern: string,
): string {
  if (!date) {
    return '';
  }

  const sourceValue = typeof date === 'string' ? Date.parse(date) : date;
  const formatted = format(sourceValue, pattern);

  return formatted;
}

export function formatDateTime(
  date: Date | number | string | null | undefined,
  options: { milliseconds: boolean } = { milliseconds: false },
): string {
  return formatDateByPattern(date, `MM/dd/yyyy hh:mm:ss${options.milliseconds ? ':SSS' : ''} a`);
}
