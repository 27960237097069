import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './types';

const initialState: AuthState = {
  loading: {
    login: false,
  },
  isAuth: null,
  error: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthLoading(
      state,
      action: PayloadAction<Partial<Record<keyof AuthState['loading'], boolean>>>,
    ) {
      state.loading = { ...state.loading, ...action.payload };
    },
    setAuth(state, action) {
      state.isAuth = action.payload.isAuth;
    },
    setAuthError(state, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = AuthSlice;
export default reducer;
export const { setIsAuthLoading, setAuth, setAuthError } = actions;
