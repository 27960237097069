import {  PlotterBase } from "../plotter/plotter-base";
import { IPlotterInfo } from "../types/IPlotterInfo";
import { ThreadComputer } from "./thread-computer";

export class ThreadPlotter {
  private nbSegmentsDrawn: number = 0;

  public constructor(
    private readonly plotter: PlotterBase,
    private readonly threadComputer: ThreadComputer,
    private readonly parameters: {
      invertColors: boolean;
      blur: number;
      displayPegs: boolean;
    }
  ) {}

  public reset(): void {
    this.nbSegmentsDrawn = 0;
  }

  public plot(): void {
    if (this.nbSegmentsDrawn === this.threadComputer.nbSegments) {
      // nothing more to do
      return;
    } else if (this.nbSegmentsDrawn > this.threadComputer.nbSegments) {
      // if the nb of segment went down, no other choice that redrawing all from scratch
      this.nbSegmentsDrawn = 0;
    }

    const drawFromScratch = this.nbSegmentsDrawn === 0;
    if (drawFromScratch) {
      const plotterInfos: IPlotterInfo = {
        backgroundColor: this.parameters.invertColors ? "black" : "white",
        blur: this.parameters.blur,
      };

      this.plotter.resize();
      this.plotter.initialize(plotterInfos);

      if (this.parameters.displayPegs) {
        this.threadComputer.drawPegs(this.plotter);
      }

      this.threadComputer.drawThread(this.plotter, 0);
      this.plotter.finalize();
    } else {
      this.threadComputer.drawThread(this.plotter, this.nbSegmentsDrawn);
    }

    this.nbSegmentsDrawn = this.threadComputer.nbSegments;
  }
}
