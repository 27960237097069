import { Grid, styled } from '@mui/material';
import React, { FC } from 'react';

export const PrivateFooter: FC = () => {
  return (
    <Footer>
      <Grid container justifyContent="center">
        <Grid item>{new Date().getFullYear()} Let's String &copy; All rights reserved</Grid>
      </Grid>
    </Footer>
  );
};

const Footer = styled('footer')(({ theme }) => ({
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.contrastText,
  backgroundColor: '#272727',
}));
