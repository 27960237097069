import { Route } from '../../../shared/router';
import { CouponsPage } from '../pages';

export const routes: Route[] = [
  {
    name: 'coupons',
    title: 'Coupons',
    path: '/admin/coupons',
    component: CouponsPage,
    layoutType: 'adminPrivate',
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'admin',
  },
];
