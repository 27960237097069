import { Grid, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Breadcrumbs } from '../Breadcrumbs';
import { BreadcrumbItem } from '../../types';

interface Props {
  title?: ReactNode;
  actions?: ReactNode;
  breadcrumbs?: BreadcrumbItem[];
}

export const HeaderView: FC<Props> = props => {
  const { title, actions, breadcrumbs } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={3} marginBottom={2}>
      {title && (
        <Grid item>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Typography variant="h5" color="textPrimary">
            {title}
          </Typography>
        </Grid>
      )}

      {actions && <Grid item>{actions}</Grid>}
    </Grid>
  );
};
