import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAdminTokenStorage, useAppSelector } from 'shared/tools';
import { useAuthAdminApi } from '../api';
import { setAuth, setAuthError, setIsAuthLoading } from '../slices';
import { LoginFormData } from '../types';

export const useLoginService = () => {
  const dispatch = useDispatch();
  const authAdminApi = useAuthAdminApi();
  const adminTokenStorage = useAdminTokenStorage();
  const { loading, isAuth, error } = useAppSelector(state => state.auth);

  const initFormData: LoginFormData = useMemo(() => ({ username: '', password: '' }), []);

  const login = useCallback(
    async (data: LoginFormData) => {
      dispatch(setIsAuthLoading({ login: true }));

      try {
        const response = await authAdminApi.login(data);

        adminTokenStorage.set(JSON.stringify(response));

        dispatch(setAuth({ isAuth: true }));
      } catch (e) {
        dispatch(setAuthError(e as Error));
      } finally {
        dispatch(setIsAuthLoading({ verify: false }));
      }
    },
    [dispatch, authAdminApi, adminTokenStorage],
  );

  const logout = useCallback(() => {
    adminTokenStorage.remove();

    dispatch(setAuth({ isAuth: false }));
  }, [dispatch, adminTokenStorage]);

  return {
    initFormData,
    login,
    logout,
    loading,
    isAuth,
    error,
  };
};
