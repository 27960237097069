import { useArtGeneratorViewController } from 'modules/art/controllers';
import { CropPhotoView } from './CropPhotoView';
import { SelectVariantView2 } from './SelectVariantView2';
import { UploadPhotoView2 } from './UploadPhotoView2';

export const CreateArtView2 = () => {
  const {
    step,
    image,
    isGenerateLoading,
    setStep,
    uploadImage,
    uploadImage2,
    saveCrop2,
    generationParams,
    beGeneration,
  } = useArtGeneratorViewController('upload');

  return (
    <>
      {step === 'upload' && (
        <UploadPhotoView2
          initForm={generationParams}
          uploadImage={data => uploadImage2(data, () => setStep('crop'))}
        />
      )}

      {step === 'crop' && (
        <CropPhotoView
          image={image}
          goBack={() => setStep('upload')}
          uploadImage={uploadImage}
          saveCrop={saveCrop2}
        />
      )}

      {step === 'choice-variant' && (
        <SelectVariantView2
          isGenerateLoading={isGenerateLoading}
          beGeneration={beGeneration}
          goBack={() => setStep('crop')}
          goFurther={() => {}}
        />
      )}

      {/* {step === 'enter-email' && (
        <EnteringEmailView goBack={() => setStep('choice-variant')} enterEmail={enterEmail} />
      )}

      {step === 'confirm-email' && (
        <ConfirmEmailView
          isStartLoading={isStartLoading}
          emailData={emailData}
          goBack={() => setStep('enter-email')}
          start={start}
        />
      )}

      {step === 'finish-select' && (
        <FinishSelectView
          download={download}
          toBuilder={toBuilder}
          isStartLoading={isStartLoading}
        />
      )} */}
    </>
  );
};
