import { useConsumerCodeStorage } from 'modules/auth';
import { useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useEffectOnce } from 'react-use';
import { LangState, useLangContext } from 'shared/features/Language';
import { useConsumerTokenStorage } from 'shared/tools';
import { useAssemblyService } from '../services';
import { useSoundPlayerController } from './SoundPlayerController';
import { getBlankVoiceUrl } from '../../../app/constants';

export type AssemblyDataState = {
  code: string;
  initLastStepNumber: number | null;
  stepsData: {
    size: {
      x: number;
      y: number;
    };
    pegs: {
      x: number;
      y: number;
      angle: number;
      name: string;
    }[];
    steps: string[];
  };
};

export const useAssemblyViewController = (sessionId: string) => {
  const { saveStep, resumeArt } = useAssemblyService();
  const langContext = useLangContext();
  const consumerCodeStorage = useConsumerCodeStorage();
  const consumerTokenStorage = useConsumerTokenStorage();
  const soundPlayerController = useSoundPlayerController();

  const [assemblyData, setAssemblyData] = useState<AssemblyDataState>(null);
  const [currentStep, setCurrentStep] = useState<{
    number: number;
    status: 'started' | 'finished';
  }>(null);

  const [isStepDrawerOpen, setIsStepDrawerOpen] = useState(false);
  const [isMelodyDrawerOpen, setIsMelodyDrawerOpen] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);

  const slider = useRef<Slider>();

  const changeAutoPlay = useCallback(
    (autoPlay: boolean) => {
      if (currentStep?.number === assemblyData?.stepsData.steps.length) {
        setAutoPlay(false);
      }
      if (!autoPlay) {
        soundPlayerController.voiceRef.current.src = getBlankVoiceUrl();
      }
      setAutoPlay(!autoPlay);
    },
    [currentStep, assemblyData],
  );

  const save = useCallback(
    async (slideNumber: number) => {
      try {
        const code = consumerCodeStorage.get();
        await saveStep({
          code,
          step: assemblyData.stepsData.steps[slideNumber - 1],
          stepNumber: slideNumber,
        });
      } catch (e) {}
    },
    [assemblyData],
  );

  const resume = useCallback(async () => {
    try {
      const response = await resumeArt(sessionId);
      const token = JSON.stringify(response.sessionToken);
      const stepsData = JSON.parse(response.stepsData);
      consumerTokenStorage.set(token);
      consumerCodeStorage.set(response.code);
      setCurrentStep({
        number: response.lastStepNumber || 1,
        status: 'started',
      });
      setAssemblyData({
        code: response.code,
        initLastStepNumber: response.lastStepNumber,
        stepsData,
      });
    } catch (e) {}
  }, []);

  const handleIntro = useCallback(
    async (slideNumber: number, firstAction?: () => void) => {
      soundPlayerController.setIntro(langContext.lang, () => changeStep(slideNumber, firstAction));
    },
    [slider.current, langContext.lang, soundPlayerController.setIntro],
  );

  const changeStep = useCallback(
    async (slideNumber: number, firstAction?: () => void) => {
      firstAction?.();

      slider.current.slickGoTo(slideNumber - 1);
      setCurrentStep({
        number: slideNumber,
        status: 'started',
      });
      save(slideNumber);

      soundPlayerController.setStep(
        assemblyData.stepsData.steps[slideNumber - 1],
        langContext.lang,
        () =>
          setCurrentStep(s => {
            if (s.number !== slideNumber) {
              return s;
            }

            return { number: slideNumber, status: 'finished' };
          }),
      );
    },
    [slider.current, assemblyData, langContext.lang, soundPlayerController.setStep],
  );

  useEffect(() => {
    if (currentStep?.number === 1) {
      if (autoPlay) {
        handleIntro(currentStep.number);
      } else {
        soundPlayerController.voiceRef.current.pause();
      }
    }
  }, [autoPlay]);

  useEffect(() => {
    if (autoPlay) {
      if (currentStep.number !== 1) {
        slider.current.slickGoTo(currentStep.number - 1);
      }
    }
  }, [autoPlay]);

  useEffect(() => {
    if (autoPlay) {
      if (currentStep.status === 'finished') {
        slider.current.slickNext();
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep?.number === assemblyData?.stepsData.steps.length) {
      setAutoPlay(false);
    }
  }, [currentStep]);

  useEffectOnce(() => {
    resume();
  });

  return {
    assemblyData,
    currentStep,
    isStepDrawerOpen,
    isMelodyDrawerOpen,
    autoPlay,
    slider,
    soundPlayerController,
    langContext,
    setIsStepDrawerOpen,
    setIsMelodyDrawerOpen,
    changeAutoPlay,
    changeStep,
  };
};
