export const commonTextData = {
  logoText: {
    en: 'Creative MindFulness',
    it: 'Mente creativa',
    es: 'Mindfulness Creativo',
    pl: 'Inteligencja twórcza ',
  },
  goToStore: {
    en: 'Go to store',
    it: 'Vai al negozio',
    es: 'Ir a la tienda',
    pl: 'Przejdź do sklepu',
  },
  chooseCountry: {
    en: 'CHOOSE YOUR COUNTRY',
    it: 'SCEGLI IL TUO PAESE',
    es: 'ELIGE TU PAÍS',
    pl: 'WYBIERZ SWÓJ KRAJ',
  },
  buttonPoland: {
    en: 'Poland',
    it: 'Polonia',
    es: 'Polonia',
    pl: 'Polska',
  },
  buttonEU: {
    en: 'Other EU countries',
    it: `Altri paesi dell'UE`,
    es: 'Otros países de la UE',
    pl: 'Inne Państwa UE',
  },
  uploadPhotoButtonHomePage: {
    en: 'Upload a photo',
    it: 'Carica una foto',
    es: 'Subir foto',
    pl: 'Prześlij zdjęcie',
  },
  chooseDesignsButtonHomePage: {
    en: 'Choose designs',
    it: 'Scegli i disegni',
    es: 'Elige diseños',
    pl: 'Wybierz projekty',
  },
  contactUs: {
    en: `Contact us: `,
    it: `Contattaci: `,
    es: `Ponte en contacto con nosotros: `,
    pl: `Skontaktuj się z nami: `,
  },
  contactEmail: {
    en: 'support@letsstring.store',
    it: 'support@letsstring.store',
    es: 'support@letsstring.store',
    pl: 'support@letsstring.store',
  },
  verifyCouponPageTitle: {
    en: "Enter the ‘Let's String’ code provided in your kit",
    it: `Inserisci codice "Let's String" fornito nel kit`,
    es: `Introduce el código "Let's String" que se incluye en tu kit`,
    pl: `Wprowadź kod "Let's String" dostarczony w zestawie.`,
  },
  buttonBack: {
    en: 'Back',
    it: `Indietro`,
    es: `Volver`,
    pl: `Powrót`,
  },
  buttonFurther: {
    en: 'Next',
    it: `Avanti `,
    es: `Más`,
    pl: `Dalej`,
  },
  verifyError: {
    en: "We couldn't find the code you entered. Please double-check and ensure that you haven't mistakenly used the letter 'i' instead of the number '1'. Please note that the code should only consist of Latin letters and numbers.",
    it: `Non siamo riusciti a trovare il codice inserito. Si prega di ricontrollare
    e di assicurarsi di non aver erroneamente utilizzato la lettera "i" al posto del numero "1". Il codice deve essere composto solo da lettere
    e numeri latini.`,
    es: `No hemos encontrado el código que has introducido. Por favor, compruebe que no ha utilizado por error la letra "i" en lugar del número "1". Tenga en cuenta que el código sólo debe constar de letras y números latinos.`,
    pl: `Nie mogliśmy znaleźć wprowadzonego kodu. Upewnij się, że przez pomyłkę nie użyłeś litery "i" zamiast cyfry "1". Należy pamiętać, że kod powinien składać się wyłącznie z liter alfabetu łacińskiego i cyfr.`,
  },
  requiredCode: {
    en: 'Code is required',
    it: `Il codice è obbligatorio`,
    es: `Se requiere código`,
    pl: `Kod jest wymagany`,
  },
  invalidCode: {
    en: 'Code has invalid format',
    it: `Il codice ha un formato non valido`,
    es: `El código tiene un formato no válido`,
    pl: `Kod ma nieprawidłowy format`,
  },
  showReadyArtTitle: {
    en: 'Click "further" to continue',
    it: `Fare clic su «Più avanti» per continuare`,
    es: `Haga clic en «Más información» para continuar`,
    pl: `Naciśnij «Dalej», aby kontynuować`,
  },
  uploadPageTitle: {
    en: 'The file size should not exceed 15 MB',
    it: `La dimensione del file non deve superare i 15 MB`,
    es: `El tamaño del archivo no debe superar los 15 MB`,
    pl: `Rozmiar pliku nie może przekraczać 15 MB`,
  },
  uploadButton: {
    en: 'Upload a photo',
    it: `Carica una foto`,
    es: `Suba una foto`,
    pl: `Prześlij zdjęcie`,
  },
  cropPageTitle: {
    en: 'Please adjust the image',
    it: `Si prega di regolare l'immagine`,
    es: `Ajusta la imagen`,
    pl: `Prosimy o przygotowanie zdjęcia`,
  },
  generateArtPageTitle: {
    en: 'Please stay on this page without closing your browser. The loading will take up to 5-10 minutes',
    it: `Rimani su questa pagina senza chiudere il browser. Il caricamento richiederà fino a 5-10 minuti`,
    es: `Permanezca en esta página sin cerrar su navegador. La carga tardará entre 5 y 10 minutos`,
    pl: `Proszę pozostać na tej stronie, nie zamykając przeglądarki. Ładowanie może potrwać od 5 do 10 minut`,
  },
  enterEmailPageTitle: {
    en: 'Congratulations! Your instructions are ready! Please, enter your email address and we will immediately send instructions for assembly',
    it: `Congratulazioni! Le istruzioni sono pronte! Inserite il vostro indirizzo e-mail e vi invieremo immediatamente le istruzioni per il montaggio.`,
    es: `¡Enhorabuena! ¡Sus instrucciones están listas! Por favor, introduzca su dirección de correo electrónico y le enviaremos inmediatamente las instrucciones de montaje`,
    pl: `Gratulacje! Twoja instrukcja jest gotowa! Wprowadź swoj adres e-mail a my natychmiast wyślemy instrukcje montażu`,
  },
  attentionText: {
    en: 'Pay attention! Assembly instructions can be sent to the mail no more than 3 times. Then the code will be inactive',
    it: `Attenzione! Le istruzioni di montaggio possono essere inviate per posta non più di 3 volte. In tal caso il codice non sarà più attivo`,
    es: `¡Atención! Las instrucciones de ensamblaje pueden enviarse al correo no más de 3 veces. Luego, el código estará inactivo`,
    pl: `Uwaga! Instrukcja montażu może zostać wysłana na adres e-mail nie więcej niż 3 razy. Następnie kod będzie nieaktywny`,
  },
  additionalText: {
    en: 'By clicking on the "Send" button, I give my permission to process my personal data for the purpose of informing me about special offers and prices. I can revoke this consent at any time by writing to support@letsstring.store.',
    it: `Facendo clic sul pulsante "Invia", do il mio consenso al trattamento dei miei dati personali allo scopo di informarmi su offerte e prezzi speciali. Posso revocare questo consenso in qualsiasi momento scrivendo a support@letsstring.store`,
    es: `Al hacer clic en el botón "Enviar", doy mi permiso para procesar mis datos personales con el fin de informarme sobre ofertas especiales y precios. Puedo revocar este consentimiento en cualquier momento escribiendo a support@letsstring.store.`,
    pl: `Klikając przycisk "Wyślij", wyrażam zgodę na przetwarzanie moich danych osobowych w celu informowania mnie o ofertach specjalnych i cenach. Mogę odwołać tę zgodę w dowolnym momencie, pisząc na adres support@letsstring.store.`,
  },
  sendButton: {
    en: 'Send',
    it: `Invia`,
    es: `Enviar`,
    pl: `Wysłać`,
  },
  enterEmailError: {
    en: 'Please enter a valid e-mail',
    it: 'Inserisci una email valida',
    es: 'Por favor introduzca una dirección de correo electrónico válida',
    pl: 'Proszę podać poprawny adres e-mail',
  },
  requiredEmail: {
    en: 'Email is required',
    it: "L'e-mail è obbligatoria",
    es: 'correo electronico es requerido',
    pl: 'Email jest wymagany',
  },
  confirmEmailPageTitle: {
    en: 'Please check your Email',
    it: "Controlla l'e-mail",
    es: 'Compruebe su dirección de correo electrónico',
    pl: 'Sprawdź swój adres e-mail',
  },
  confirmEmailText: {
    en: 'Is this email correct?',
    it: "L'e-mail è corretta?",
    es: '¿Es correcto este correo electrónico?',
    pl: 'Czy ten adres e-mail jest prawidłowy?',
  },
  yesButton: {
    en: 'Yes',
    it: 'Sì',
    es: 'Sí',
    pl: 'Tak',
  },
  noButton: {
    en: 'No',
    it: 'No',
    es: 'No',
    pl: 'Nie',
  },
  finalPageTitle: {
    en: 'Your instructions for printing and for online assembly are in your Email',
    it: 'Le istruzioni per la stampa e per il montaggio online sono nella vostra e-mail',
    es: 'Sus instrucciones para la impresión y el montaje en línea se encuentran en su correo electrónico',
    pl: 'Instrukcje dotyczące drukowania i montażu online znajdują się w wiadomości e-mail',
  },
  finalPageText: {
    en: 'You can now join the journey to Creative Mindfulness 🌱',
    it: 'Ora potete unirvi al viaggio verso la Mindfulness creativa 🌱',
    es: 'Ya puedes unirte al viaje hacia Atención Plena Creativa 🌱',
    pl: 'Teraz możesz dołączyć do podróży w kreatywność opartą na uważności 🌱',
  },
  startAssemblyButton: {
    en: 'Start assembly online',
    it: "Iniziare l'assemblaggio online",
    es: 'Iniciar el montaje en línea',
    pl: 'Rozpocznij montaż online',
  },
  downloadPDFButton: {
    en: 'Download PDF-instruction',
    it: 'Scarica le istruzioni in PDF',
    es: 'Descargar instrucciones en PDF',
    pl: 'Pobierz instrukcję w formacie PDF',
  },
  goToMainPageButton: {
    en: 'Main page',
    it: 'Pagina principale',
    es: 'Página principal',
    pl: 'Strona główna',
  },
  chooseButton: {
    en: 'Choose',
    it: 'Scegliere',
    es: 'Elegir',
    pl: 'Wybierz',
  },
  readyDesignsText: {
    en: " If you're not sure which photo to use, you can choose one from the images we've carefully prepared for you",
    it: 'Se non siete sicuri di quale foto usare, potete sceglierne una tra le immagini che abbiamo preparato con cura per voi.',
    es: 'Si no estás seguro(a) de qué foto escoger, puedes elegir una de las imágenes que hemos preparado cuidadosamente para ti.',
    pl: 'Jeśli nie masz pewności, które zdjęcie wybrać, możesz skorzystać z jednego z obrazów, które starannie dla Ciebie przygotowaliśmy.',
  },
  lostButton: {
    en: "I'm lost",
    it: 'Mi sono persə',
    es: 'Estoy perdido',
    pl: 'Zgubiłem się',
  },
  noMusic: {
    en: 'No music',
    it: 'Nessuna musica',
    es: 'Sin música',
    pl: 'Bez muzyki',
  },
  enterStepField: {
    en: 'Enter the step where you left off',
    it: 'Inserite il passo in cui vi siete fermati',
    es: 'Introduzca el paso donde lo dejó',
    pl: 'Wprowadź krok, na którym skończyłeś',
  },
  yourStep: {
    en: 'Your step:',
    it: 'Il tuo passo:',
    es: 'Tu paso:',
    pl: 'Twój krok:',
  },
  FAQTitle1: {
    en: `What is Let’s String?`,
    it: `Che cos'è Let's String?`,
    es: `¿Qué es Let's String?`,
    pl: `Czym jest Let's String?`,
  },
  FAQTitle2: {
    en: `I received the kit. What's next?`,
    it: `Ho ricevuto il kit. Cosa devo fare ora?`,
    es: `He recibido el kit. ¿Qué hago ahora?`,
    pl: `Otrzymałem zestaw. Co dalej?`,
  },
  FAQTitle3: {
    en: `How can I change a threading speed or a background sound?`,
    it: `Come posso cambiare la velocità di infilatura o il suono di sottofondo?`,
    es: `¿Cómo puedo cambiar la velocidad de enhebrado o el sonido de fondo?`,
    pl: `Jak mogę zmienić prędkość nawlekania lub dźwięk w tle?`,
  },
  FAQTitle4: {
    en: `What if I tangle?`,
    it: `E se mi aggroviglio?`,
    es: `¿Y si me enredo?`,
    pl: `Co zrobić, jeśli się pogubię?`,
  },
  FAQTitle5: {
    en: `How do I know how many steps I have left until Let'sString artwork is finished?`,
    it: `Come faccio a sapere quanti passi mi restano prima che l'opera d'arte Let'sString finisca?`,
    es: `¿Cómo sé cuántos pasos me faltan para terminar la obra de arte Let'sString?`,
    pl: `Jak się dowiedzieć ile kroków pozostało do zakończenia tworzenia mojego Let's String ?`,
  },
  FAQTitle6: {
    en: `Which image to choose?`,
    it: `Quale immagine scegliere?`,
    es: `¿Qué imagen elegir?`,
    pl: `Który obraz wybrać?`,
  },
  FAQTitle7: {
    en: `How do I upload an image?`,
    it: `Come faccio a caricare un'immagine?`,
    es: `¿Cómo subo una imagen?`,
    pl: `Jak przesłać zdjęcie?`,
  },
  FAQTitle8: {
    en: `What if the string breaks?`,
    it: `E se il filo si rompe?`,
    es: `¿Y si se rompe el cordel?`,
    pl: `Co jeśli sznurek pęknie?`,
  },
  FAQAnswer1: {
    en: `Let's String is a unique creativity kit that lets you turn any picture or photo into a stunning string painting. With easy-to-follow instructions and a helpful voice assistant, you can unleash your creativity and create a beautiful artwork. It's the perfect gift idea or a fun activity for yourself. Once finished, your Let's String masterpiece will become a captivating centerpiece in any room, drawing attention and adding elegance to your space.`,
    it: `Il kit di creatività Let's String è un modo unico per trasformare qualsiasi immagine o foto in un bellissimo quadro di spago. Con questo kit è possibile liberare la propria creatività e creare splendide opere d'arte. Le nostre istruzioni facili da seguire e l'assistente vocale vi guideranno attraverso il processo.
    Oltre a essere un'ottima idea regalo, questo kit è anche un'attività divertente per se stessi. Potete scegliere di regalarlo, ben incartato e pronto per essere assemblato, oppure potete assemblarlo voi stessi e presentarlo come un sentito regalo fatto a mano.
    Una volta terminato, il vostro quadro di corde aggiungerà un tocco di eleganza a qualsiasi interno. Preparatevi a stupire i vostri ospiti con questo capolavoro accattivante!`,
    es: `El kit de creatividad Let's String es una forma única de convertir cualquier imagen o foto en un bonito cuadro pintado con cuerdas. Con este kit, puedes dar rienda suelta a tu creatividad y crear obras de arte impresionantes. Nuestras instrucciones fáciles de seguir y el asistente de voz te guiarán durante todo el proceso.
    Además de ser una gran idea para regalar, este kit es también una divertida actividad para ti mismo. Puede optar por regalarlo, bellamente envuelto y listo para montar, o puede montarlo usted mismo y presentarlo como un sentido regalo hecho a mano.
    Una vez terminado, tu cuadro de cuerda añadirá un toque de elegancia a cualquier interior. Prepárate para impresionar a tus invitados con esta llamativa obra maestra.`,
    pl: `Zestaw kreatywny Let's String to wyjątkowy sposób na przekształcenie dowolnego obrazu lub zdjęcia w piękny obraz sznurkowy. Dzięki temu zestawowi możesz uwolnić swoją kreatywność i stworzyć wspaniałe dzieła sztuki. Nasze łatwe do wykonania instrukcje i asystent głosowy poprowadzą Cię przez cały proces.
    Oprócz tego, że jest to świetny pomysł na prezent, zestaw ten jest również świetną zabawą dla Ciebie. Możesz podarować go jako prezent, pięknie zapakowany i gotowy do złożenia, lub możesz złożyć go samodzielnie i podarować jako serdeczny, ręcznie robiony prezent.
    Po zakończeniu malowanie sznurkiem doda elegancji każdemu wnętrzu. Przygotuj się na zaimponowanie swoim gościom tym przyciągającym wzrok arcydziełem!
    `,
  },
  FAQAnswer2: {
    en: `To get started, open the packages and locate the detaied paper manual enclosed. Take a moment to thoroughly read through the instructions. Afterward, visit our website and upload your photo.
    From there, you can select the assembly option that suits you best: either follow the online guide or download the PDF guide.`,
    it: `Per iniziare, aprire la confezione e trovare il manuale cartaceo allegato. Prendetevi un momento per leggere attentamente le istruzioni. Successivamente, visitate il nostro sito web e caricate la vostra foto.
    Da qui è possibile selezionare l'opzione di montaggio più adatta: seguire la guida online o scaricare la guida in PDF.`,
    es: `Para empezar, abre los paquetes y localiza el manual detallado en papel que se incluye. Tómese un momento para leer detenidamente las instrucciones. Después, visita nuestro sitio web y sube tu foto.
    A partir de ahí, puede seleccionar la opción de montaje que más le convenga: seguir la guía en línea o descargar la guía en PDF.`,
    pl: `Aby rozpocząć, otwórz opakowanie i znajdź załączoną szczegółową instrukcję. Poświęć chwilę na dokładne przeczytanie instrukcji. Następnie odwiedź naszą stronę internetową i prześlij swoje zdjęcie.
    Stamtąd możesz wybrać opcję montażu, która najbardziej Ci odpowiada: postępuj zgodnie z instrukcją online lub pobierz instrukcję PDF.`,
  },
  FAQAnswer3: {
    en: `Our generator can be customised to suit your preferences. You have the freedom to select delightful background music or sound, a soothing voiceover, and adjust the assembling speed according to your liking. Faster speeds allow for quicker completion of your Let’sString artwork.`,
    it: `Il nostro generatore può essere personalizzato in base alle vostre preferenze. Avete la libertà di selezionare una musica o un suono di sottofondo piacevole, una voce fuori campo rilassante e di regolare la velocità di assemblaggio secondo i vostri gusti. Una velocità maggiore consente di completare più rapidamente la vostra opera d'arte Let'sString.`,
    es: `Nuestro generador puede personalizarse según sus preferencias. Tiene la libertad de seleccionar una música o un sonido de fondo encantadores, una voz en off relajante y ajustar la velocidad de montaje a su gusto. Las velocidades más rápidas permiten terminar antes tu obra de Let'sString.`,
    pl: `Nasz generator można dostosować do własnych preferencji. Masz swobodę wyboru zachwycającej muzyki lub dźwięku w tle, kojącego lektora i dostosowania prędkości montażu do swoich upodobań. Większa prędkość pozwala na szybsze ukończenie kompozycji Let'sString.`,
  },
  FAQAnswer4: {
    en: `Don't worry! We've made it easy to find your way back to the step where you left off. Just enter the step number in the left corner, and the generator will guide you right back to it. Alternatively, you can tap on the nail you need directly from the main screen of the generator.`,
    it: `Non preoccupatevi! Abbiamo semplificato il percorso per tornare al punto in cui ci si è fermati. Basta inserire il numero del passo nell'angolo in sinistra e il generatore vi guiderà direttamente al passo. In alternativa, potete toccare l'unghia di cui avete bisogno direttamente dalla schermata principale del generatore.`,
    es: `No te preocupes. Te lo hemos puesto fácil para que encuentres el camino de vuelta al paso donde lo dejaste. Sólo tienes que introducir el número del paso en la esquina izquierda y el generador te guiará hasta él. También puedes pulsar sobre el clavo que necesites directamente desde la pantalla principal del generador.`,
    pl: `Nie martw się! Ułatwiliśmy powrót do kroku, w którym przerwałeś. Wystarczy wpisać numer kroku w lewym rogu, a generator poprowadzi Cię z powrotem do niego. Alternatywnie, możesz dotknąć potrzebnego gwoździa bezpośrednio z głównego ekranu generatora.`,
  },
  FAQAnswer5: {
    en: `Below, you'll see a progress bar that indicates your current step and how much progress you've made so far.`,
    it: `Qui di seguito viene visualizzata una barra di avanzamento che indica il passo corrente e i progressi compiuti finora.`,
    es: `Abajo verás una barra de progreso que indica tu paso actual y cuánto has avanzado hasta ahora.`,
    pl: `W dolnej części generatora możesz zobaczyć, na jakim etapie obecnie się znajdujesz i ile kroków pozostało do ukończenia.`,
  },
  FAQAnswer6: {
    en: `The best photo for a Let’sString art is a close-up of a single person with a contrast lighting.`,
    it: `La foto migliore per un'opera d'arte Let'sString è un primo piano di una singola persona con un'illuminazione contrastante.`,
    es: `La mejor foto para una ilustración de Let'sString es un primer plano de una sola persona con una iluminación de contraste.`,
    pl: `Najlepszym zdjęciem dla Let'sString jest zbliżenie jednej osoby z kontrastowym oświetleniem.`,
  },
  FAQAnswer7: {
    en: `Use the instruction below
    1. Go to LetsString.art, or scan the QR code from the Unique Let's String code card
    2. Enter a unique 8-character Let'sString code from the card
    3. Upload your picture
    4. Select the design option
    5. Start assembling using the guides provided`,
    it: `Utilizzate le istruzioni riportate di seguito
    1. Andate su LetsString.art o scannerizzate il codice QR della cartolina con il codice unico Let's String.
    2. Inserite un codice Let'sString univoco di 8 caratteri dalla scheda.
    3. Caricate la vostra foto
    4. Selezionare l'opzione di design
    5. Iniziare l'assemblaggio utilizzando le guide fornite`,
    es: `Sigue estas instrucciones
    1. Vaya a LetsString.art, o escanee el código QR de la tarjeta de códigos Let's String única
    2. Introduce un código Let'sString único de 8 caracteres de la tarjeta
    3. Sube tu foto
    4. Selecciona la opción de diseño
    5. Empieza a montarlo siguiendo las guías`,
    pl: `Skorzystaj z poniższej instrukcji
    1. Wejdź na stronę LetsString.art lub zeskanuj kod QR z unikalnej karty kodów Let's String.
    2. Wprowadź unikalny 8-znakowy kod Let'sString z karty
    3. Prześlij swoje zdjęcie
    4. Wybierz opcję projektu
    5. Rozpocznij montaż, korzystając z dostarczonych instrukcji`,
  },
  FAQAnswer8: {
    en: `Don't worry - just tie a tight knot, cut off the excess and move on; it won't show on the finished artwork.`,
    it: `Non preoccupatevi: fate un nodo stretto, tagliate l'eccesso e andate avanti; non si vedrà sull'opera d'arte finita.`,
    es: `No te preocupes: haz un nudo apretado, corta el sobrante y sigue adelante; no se notará en la obra de arte terminada.`,
    pl: `Nie martw się-  Najpierw zawiąż węzeł na gwoździu, gdzie musiałeś się zatrzymać, i obetnij porwaną część. Następnie, używając nowej nici, zawiąż ją na tym samym gwoździu, aby kontynuować montaż swojego zestawu.  Nie będzie to widoczne na gotowej kompozycji.`,
  },
};
