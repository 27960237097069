import { useMemo } from 'react';
import { useApiConsumerClient, useApiUrlBuilder } from 'shared/tools';
import { SessionToken, VerifyDataRequest } from '../types';

export function useVerifyConsumerApi() {
  const api = useApiConsumerClient();
  const apiUrlBuilder = useApiUrlBuilder();

  return useMemo(
    () => ({
      verify: (data: VerifyDataRequest) =>
        api.post<VerifyDataRequest, SessionToken>({
          path: apiUrlBuilder.consumerVerify(),
          data,
        }),
    }),
    [api, apiUrlBuilder],
  );
}
