import { Box } from '@mui/material';
import React, { FC } from 'react';

export const Page404Layout: FC = ({ children }) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {children}
    </Box>
  );
};
