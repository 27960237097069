export const portraits = [
  {
    title: 'Albert Einstein',
    id: 'albert-einstein',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Albert%20Einstein.PNG',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D16',
          'C5',
          'D49',
          'D33',
          'D18',
          'C3',
          'D23',
          'D57',
          'D27',
          'D51',
          'D30',
          'C7',
          'D15',
          'C6',
          'C48',
          'A13',
          'A29',
          'A7',
          'C52',
          'C19',
          'C35',
          'A14',
          'C46',
          'C13',
          'A2',
          'C18',
          'C41',
          'A19',
          'C33',
          'A21',
          'C29',
          'C4',
          'D17',
          'D58',
          'D19',
          'D52',
          'D23',
          'D43',
          'D28',
          'D56',
          'D18',
          'C2',
          'D25',
          'C1',
          'D26',
          'D60',
          'D13',
          'C7',
          'C56',
          'A12',
          'C51',
          'C36',
          'A13',
          'C16',
          'C44',
          'A18',
          'C34',
          'A15',
          'C13',
          'C54',
          'C8',
          'C47',
          'A30',
          'A6',
          'C17',
          'C43',
          'C22',
          'A1',
          'A34',
          'A60',
          'A41',
          'B2',
          'A39',
          'A21',
          'C25',
          'C40',
          'A26',
          'C50',
          'C6',
          'D15',
          'D59',
          'D16',
          'C5',
          'D22',
          'C4',
          'C33',
          'A24',
          'C32',
          'C13',
          'A7',
          'C16',
          'C53',
          'C14',
          'A14',
          'A39',
          'B3',
          'A38',
          'A1',
          'C19',
          'A5',
          'A33',
          'A3',
          'C9',
          'D14',
          'C7',
          'D21',
          'C6',
          'D43',
          'D28',
          'D55',
          'D31',
          'D51',
          'D35',
          'D15',
          'D53',
          'D18',
          'D45',
          'D19',
          'D44',
          'D60',
          'D27',
          'D59',
          'D16',
          'C4',
          'D24',
          'C3',
          'C25',
          'C60',
          'C36',
          'A6',
          'A31',
          'B2',
          'A30',
          'C46',
          'C15',
          'A19',
          'C26',
          'C59',
          'A38',
          'B4',
          'A37',
          'A3',
          'C17',
          'A12',
          'C13',
          'A16',
          'C45',
          'C21',
          'C51',
          'C27',
          'A26',
          'B3',
          'A25',
          'A9',
          'C14',
          'A1',
          'A40',
          'A56',
          'A27',
          'B2',
          'A46',
          'A6',
          'C15',
          'A8',
          'C12',
          'A13',
          'A41',
          'C59',
          'C20',
          'C60',
          'C8',
          'D14',
          'C7',
          'D17',
          'C4',
          'D22',
          'D58',
          'D30',
          'D57',
          'D38',
          'D55',
          'D32',
          'D48',
          'C18',
          'A5',
          'A36',
          'C45',
          'C29',
          'C58',
          'C38',
          'A31',
          'C21',
          'A22',
          'C16',
          'A18',
          'C27',
          'C57',
          'C42',
          'C23',
          'A21',
          'C22',
          'A33',
          'B1',
          'A44',
          'A1',
          'C24',
          'A34',
          'C52',
          'C17',
          'A24',
          'B3',
          'A20',
          'C34',
          'A31',
          'C20',
          'A4',
          'C16',
          'D44',
          'D20',
          'C8',
          'D12',
          'B43',
          'B26',
          'B59',
          'B44',
          'B22',
          'B52',
          'D8',
          'B45',
          'B20',
          'B53',
          'B29',
          'B58',
          'B34',
          'B51',
          'B23',
          'D2',
          'B25',
          'B60',
          'B28',
          'D6',
          'B24',
          'B39',
          'B57',
          'B30',
          'B50',
          'B35',
          'D4',
          'B22',
          'D3',
          'B38',
          'B18',
          'B55',
          'B40',
          'B17',
          'B33',
          'D7',
          'B23',
          'D1',
          'B19',
          'B54',
          'B26',
          'B58',
          'B36',
          'D6',
          'B21',
          'D5',
          'B25',
          'B59',
          'B28',
          'B51',
          'B31',
          'D9',
          'B27',
          'D2',
          'B39',
          'B10',
          'B44',
          'B29',
          'D4',
          'B19',
          'B60',
          'B24',
          'D3',
          'B46',
          'B12',
          'A38',
          'C32',
          'A28',
          'A9',
          'C11',
          'A10',
          'A57',
          'A42',
          'C27',
          'C58',
          'C21',
          'A3',
          'A39',
          'A58',
          'A8',
          'C35',
          'A17',
          'C45',
          'C6',
          'D15',
          'C5',
          'D21',
          'C2',
          'D24',
          'D52',
          'D25',
          'D40',
          'D12',
          'C8',
          'D13',
          'D54',
          'D17',
          'C3',
          'D36',
          'C1',
          'D18',
          'D59',
          'D28',
          'D43',
          'D20',
          'C7',
          'D14',
          'D60',
          'C34',
          'A23',
          'C18',
          'A11',
          'C14',
          'A18',
          'C43',
          'A36',
          'B4',
          'A33',
          'C54',
          'C6',
          'D13',
          'C8',
          'A1',
          'C35',
          'A40',
          'A60',
          'A15',
          'B1',
          'A12',
          'A56',
          'A21',
          'C19',
          'A10',
          'C24',
          'A20',
          'A57',
          'A9',
          'C26',
          'A35',
          'C46',
          'C28',
          'C50',
          'C22',
          'A28',
          'C31',
          'A39',
          'C45',
          'C8',
          'D16',
          'C5',
          'D23',
          'D56',
          'D40',
          'D25',
          'C2',
          'D13',
          'D46',
          'D15',
          'C6',
          'C52',
          'A9',
          'A60',
          'A18',
          'A33',
          'C21',
          'D52',
          'D28',
          'D50',
          'D18',
          'D53',
          'D21',
          'D59',
          'D36',
          'D20',
          'C7',
          'D10',
          'B34',
          'D7',
          'B20',
          'D6',
          'B23',
          'B43',
          'D5',
          'B37',
          'B57',
          'B42',
          'B11',
          'B47',
          'B19',
          'D2',
          'B22',
          'D8',
          'B32',
          'B59',
          'B29',
          'D9',
          'C3',
          'D17',
          'D57',
          'C28',
          'A43',
          'A58',
          'A16',
          'A35',
          'C52',
          'C8',
          'A4',
          'A31',
          'B4',
          'A30',
          'C24',
          'C41',
          'A20',
          'C30',
          'A44',
          'C29',
          'A24',
          'C16',
          'A14',
          'C12',
          'A3',
          'C37',
          'C59',
          'A42',
          'C51',
          'C20',
          'A9',
          'B1',
          'A11',
          'A56',
          'A35',
          'C24',
          'A2',
          'A37',
          'B12',
          'B49',
          'B32',
          'B8',
          'B35',
          'D7',
          'B27',
          'B53',
          'B21',
          'D8',
          'C7',
          'D14',
          'D60',
          'D15',
          'C6',
          'D20',
          'D35',
          'D51',
          'D31',
          'C4',
          'D16',
          'D46',
          'D17',
          'D57',
          'D25',
          'D52',
          'D36',
          'D21',
          'D53',
          'D22',
          'D58',
          'C27',
          'A8',
          'C34',
          'A40',
          'C32',
          'A46',
          'C52',
          'A55',
          'A23',
          'C16',
          'A5',
          'C9',
          'D12',
          'C7',
          'D14',
          'D54',
          'D17',
          'C2',
          'D9',
          'B25',
          'D1',
          'B40',
          'B9',
          'A29',
          'C19',
          'A22',
          'A53',
          'A13',
          'A55',
          'A32',
          'C36',
          'A7',
          'A57',
          'A19',
          'C14',
          'A6',
          'C47',
          'A15',
          'C15',
          'C60',
          'A60',
          'A14',
          'B3',
          'A12',
          'C23',
          'C49',
          'A29',
          'C25',
          'A41',
          'C34',
          'A9',
          'C13',
          'A17',
          'C31',
          'A34',
          'C21',
          'A27',
          'C39',
          'C56',
          'C30',
          'A45',
          'B23',
          'D4',
          'B37',
          'B11',
          'A33',
          'C38',
          'C57',
          'C7',
          'D10',
          'C6',
          'D21',
          'D44',
          'D24',
          'C3',
          'D12',
          'C1',
          'D13',
          'C8',
          'D9',
          'B21',
          'D5',
          'B49',
          'B34',
          'D6',
          'B36',
          'B20',
          'A52',
          'A24',
          'C41',
          'A37',
          'C28',
          'A25',
          'B10',
          'A22',
          'A55',
          'A13',
          'C17',
          'C59',
          'A60',
          'A39',
          'C36',
          'A16',
          'A59',
          'B32',
          'D9',
          'C8',
          'C43',
          'A19',
          'C25',
          'A40',
          'B21',
          'D4',
          'B38',
          'B53',
          'B15',
          'B55',
          'B25',
          'D8',
          'B19',
          'D7',
          'B22',
          'A43',
          'C27',
          'A27',
          'C18',
          'A12',
          'C50',
          'C8',
          'C55',
          'A57',
          'A17',
          'C17',
          'A21',
          'A56',
          'C54',
          'A44',
          'C40',
          'A30',
          'C19',
          'A2',
          'C22',
          'C57',
          'A39',
          'C30',
          'A18',
          'B4',
          'A12',
          'C11',
          'A11',
          'B3',
          'A19',
          'C35',
          'A3',
          'C8',
          'D13',
          'D58',
          'D16',
          'C6',
          'D35',
          'D51',
          'D25',
          'D40',
          'C15',
          'A21',
          'A59',
          'A13',
          'A60',
          'A32',
          'C23',
          'A37',
          'A52',
          'A34',
          'A53',
          'C49',
          'C31',
          'A46',
          'B17',
          'B56',
          'B41',
          'D1',
          'B27',
          'B52',
          'B30',
          'B9',
          'B43',
          'B12',
          'B60',
          'C5',
          'D15',
          'D60',
          'D20',
          'D56',
          'D24',
          'D43',
          'D28',
          'D49',
          'D18',
          'D57',
          'D19',
          'D54',
          'D33',
          'C4',
          'D16',
          'D58',
          'D22',
          'D53',
          'D23',
          'C6',
          'D14',
          'C7',
          'D9',
          'C1',
          'C22',
          'A20',
          'C20',
          'A28',
          'A54',
          'A24',
          'B9',
          'A19',
          'C31',
          'A9',
          'C25',
          'C59',
          'C9',
          'D11',
          'B22',
          'B60',
          'B45',
          'B10',
          'A21',
          'C14',
          'A15',
          'C46',
          'A38',
          'C33',
          'A47',
          'C32',
          'A16',
          'A37',
          'B20',
          'B54',
          'B14',
          'D5',
          'B26',
          'D8',
          'B33',
          'B59',
          'C4',
          'D1',
          'B11',
          'A29',
          'C48',
          'A13',
          'C15',
          'C45',
          'A40',
          'C24',
          'A11',
          'C19',
          'A31',
          'C46',
          'C5',
          'D10',
          'B24',
          'D2',
          'C6',
          'D3',
          'B39',
          'B12',
          'A36',
          'C26',
          'A42',
          'B22',
          'D9',
          'D60',
          'D17',
          'D46',
          'D22',
          'D45',
          'C16',
          'A25',
          'C41',
          'C8',
          'D13',
          'C1',
          'D14',
          'C6',
          'D4',
          'B17',
          'D10',
          'C4',
          'D23',
          'D42',
          'D27',
          'D57',
          'D30',
          'D56',
          'D40',
          'D25',
          'D9',
          'C7',
          'C59',
          'A60',
          'C58',
          'C37',
          'A5',
          'C8',
          'A6',
          'C28',
          'A32',
          'B1',
          'A9',
          'B3',
          'A3',
          'C48',
          'A53',
          'A33',
          'C29',
          'A26',
          'C33',
          'A41',
          'C26',
          'C52',
          'A10',
          'A59',
          'A16',
          'B9',
          'B58',
          'B16',
          'B34',
          'B57',
          'B27',
          'B60',
          'C3',
          'D16',
          'D58',
          'D17',
          'D54',
          'C24',
          'A19',
          'B10',
          'B59',
          'B23',
          'A42',
          'A58',
          'B32',
          'B47',
          'D52',
          'D26',
          'C2',
          'B58',
          'B29',
          'B51',
          'B13',
          'D3',
          'B26',
          'B53',
          'D60',
          'D15',
          'C5',
          'C32',
          'A8',
          'B1',
          'A1',
          'C7',
          'D6',
          'B15',
          'D7',
          'C6',
          'C53',
          'A25',
          'C34',
          'A37',
          'C27',
          'A34',
          'A58',
          'A26',
          'C26',
          'C58',
          'C8',
          'C48',
          'C33',
          'A27',
          'B2',
          'A13',
          'C47',
          'A17',
          'C45',
          'A53',
          'C44',
          'A35',
          'C22',
          'A36',
          'C42',
          'A18',
          'C13',
          'C28',
          'A44',
          'B17',
          'D2',
          'C4',
          'D11',
          'D47',
          'D31',
          'D50',
          'B44',
          'D49',
          'D19',
          'D57',
          'B54',
          'B16',
          'D5',
          'B22',
          'A40',
          'C31',
          'A29',
          'B4',
          'A26',
          'B8',
          'A20',
          'C18',
          'A28',
          'C53',
          'C9',
          'D12',
          'C3',
          'B57',
          'D60',
          'D15',
          'D59',
          'D18',
          'D46',
          'B41',
          'B7',
          'B55',
          'B29',
          'A55',
          'A22',
          'C35',
          'A7',
          'C21',
          'A10',
          'C12',
          'A23',
          'B9',
          'A15',
          'C19',
          'A26',
          'C17',
          'D43',
          'D28',
          'D59',
          'B57',
          'B16',
          'D9',
          'C6',
          'D14',
          'C1',
          'C33',
          'A31',
          'A53',
          'C42',
          'A22',
          'C15',
          'C52',
          'A57',
          'C54',
          'C39',
          'A44',
          'B21',
          'D1',
          'C3',
          'D12',
          'C2',
          'D2',
          'B26',
          'B41',
          'B56',
          'B24',
          'D14',
          'D47',
          'D31',
          'D57',
          'D22',
          'C46',
          'A32',
          'B20',
          'D8',
          'C4',
          'D11',
          'B18',
          'A46',
          'C30',
          'A23',
          'C55',
          'C6',
          'C44',
          'A51',
          'B6',
          'A18',
          'C36',
          'A49',
          'C35',
          'A30',
          'B8',
          'A17',
          'C15',
          'C31',
          'A47',
          'B14',
          'D3',
          'B23',
          'B57',
          'B37',
          'D44',
          'B39',
          'D43',
          'B40',
          'A42',
          'C26',
          'A37',
          'C40',
          'C8',
          'D8',
          'B17',
          'A19',
          'A58',
          'C56',
          'A16',
          'C18',
          'A29',
          'C41',
          'C6',
          'D9',
          'C5',
          'B60',
          'B9',
          'A14',
          'C50',
          'A44',
          'C38',
          'C13',
          'A26',
          'C22',
          'A13',
          'A60',
          'B33',
          'D40',
          'D18',
          'D58',
          'D24',
          'D39',
          'B35',
          'D52',
          'D26',
          'C1',
          'D14',
          'B25',
          'D6',
          'C7',
          'D7',
          'B21',
          'A37',
          'C60',
          'B1',
          'C59',
          'C36',
          'D60',
          'D17',
          'C43',
          'D18',
          'D53',
          'D23',
          'C46',
          'A34',
          'C23',
          'A38',
          'B19',
          'A33',
          'C28',
          'C5',
          'D10',
          'B30',
          'B49',
          'D54',
          'D20',
          'C3',
          'D13',
          'C2',
          'B56',
          'B28',
          'D1',
          'B31',
          'B46',
          'D53',
          'D24',
          'D39',
          'B36',
          'B3',
          'A2',
          'C9',
          'D12',
          'D55',
          'D16',
          'C42',
          'A23',
          'C23',
          'C60',
          'A52',
          'C58',
          'C13',
          'A19',
          'A59',
          'A10',
          'A60',
          'B48',
          'D56',
          'B50',
          'A50',
          'C35',
          'A36',
          'C29',
          'A45',
          'B42',
          'A44',
          'C37',
          'A12',
          'C51',
          'C7',
          'A21',
          'A56',
          'A20',
          'C11',
          'A13',
          'C20',
          'A32',
          'C40',
          'A43',
          'B17',
          'B48',
          'B11',
          'D6',
          'B13',
          'D4',
          'C5',
          'D1',
          'B18',
          'D10',
          'B20',
          'A31',
          'C9',
          'C39',
          'A30',
          'A53',
          'C38',
          'C18',
          'A3',
          'A33',
          'B24',
          'B50',
          'D52',
          'D27',
          'B29',
          'B53',
          'D59',
          'D15',
          'B23',
          'A40',
          'C60',
          'C7',
          'A22',
          'B12',
          'B56',
          'C1',
          'D14',
          'B51',
          'B36',
          'D53',
          'D33',
          'D49',
          'D20',
          'D54',
          'D17',
          'C42',
          'A6',
          'C13',
          'A45',
          'B13',
          'D40',
          'B14',
          'A34',
          'C10',
          'A9',
          'C30',
          'A32',
          'C39',
          'A48',
          'B6',
          'D8',
          'C7',
          'D12',
          'C2',
          'D13',
          'D55',
          'D16',
          'C1',
          'B58',
          'B10',
          'A18',
          'B17',
          'D3',
          'B20',
          'D43',
          'D28',
          'D52',
          'B36',
          'D57',
          'C30',
          'A25',
          'B4',
          'D7',
          'C5',
          'D10',
          'C3',
          'C42',
          'A15',
          'C12',
          'A24',
          'C47',
          'A52',
          'C40',
          'A54',
          'B57',
          'B10',
          'A17',
          'C14',
          'C36',
          'A20',
          'B16',
          'A47',
          'B44',
          'D12',
          'B25',
          'B54',
          'D56',
          'B34',
          'D41',
          'B17',
          'A41',
          'C25',
          'A10',
          'C49',
          'A16',
          'C16',
          'C31',
          'A44',
          'C13',
          'A27',
          'A54',
          'C51',
          'A43',
          'C59',
          'B1',
          'C57',
          'C14',
          'A22',
          'B22',
          'B58',
          'D59',
          'D21',
          'C45',
          'D22',
          'D45',
          'B38',
          'A39',
          'C33',
          'A49',
          'B51',
          'B4',
          'A24',
          'C8',
          'C56',
          'C28',
          'A17',
          'C44',
          'D19',
          'D46',
          'D26',
          'C48',
          'C13',
          'A47',
          'C29',
          'A31',
          'B24',
          'D7',
          'B47',
          'B25',
          'D4',
          'C4',
          'D11',
          'C10',
          'A35',
          'C21',
          'A14',
          'C20',
          'A33',
          'C37',
          'A15',
          'B10',
          'D8',
          'B5',
          'B48',
          'D55',
          'D16',
          'D50',
          'D34',
          'D19',
          'C43',
          'A21',
          'C11',
          'A38',
          'B38',
          'D54',
          'D20',
          'B41',
          'D43',
          'D28',
          'C50',
          'A5',
          'B6',
          'A47',
          'B53',
          'D58',
          'B39',
          'A58',
          'D2',
          'C5',
          'D15',
          'D59',
          'D16',
          'D55',
          'B54',
          'D10',
          'B15',
          'A13',
          'C13',
          'C40',
          'A25',
          'B8',
          'B59',
          'A59',
          'C44',
          'A40',
          'C30',
          'A29',
          'C47',
          'C4',
          'D11',
          'C3',
          'C24',
          'D14',
          'C22',
          'A32',
          'C9',
          'A42',
          'C25',
          'A31',
          'C41',
          'D17',
          'D38',
          'D18',
          'D55',
          'B55',
          'A46',
          'B41',
          'B12',
          'A8',
          'C47',
          'A13',
          'B9',
          'B52',
          'B30',
          'D37',
          'D53',
          'D24',
          'D49',
          'B31',
          'B1',
          'D6',
          'A57',
          'D4',
          'B8',
          'B27',
          'D26',
          'B28',
          'D5',
          'C7',
          'A19',
          'C23',
          'A11',
          'C49',
          'A55',
          'C15',
          'A20',
          'B25',
          'A47',
          'B48',
          'B30',
          'A36',
          'C10',
          'A18',
          'C26',
          'A39',
          'C11',
          'D10',
          'C5',
          'D3',
          'A7',
          'B59',
          'A56',
          'B28',
          'B54',
          'A55',
          'C39',
          'D17',
          'D60',
          'D14',
          'C19',
          'B47',
          'D49',
          'B32',
          'A36',
          'C33',
          'A15',
          'C46',
          'B1',
          'C44',
          'D16',
          'C36',
          'C21',
          'B52',
          'B33',
          'D50',
          'B45',
          'A44',
          'B48',
          'D48',
          'D20',
          'D54',
          'D21',
          'C58',
          'B2',
          'D5',
          'B18',
          'D44',
          'B19',
          'D42',
          'B16',
          'A24',
          'B26',
          'D1',
          'A8',
          'C14',
          'C47',
          'D24',
          'D39',
          'B37',
          'D57',
          'D15',
          'D56',
          'B51',
          'A53',
          'C37',
          'A38',
          'C28',
          'A44',
          'C12',
          'A16',
          'A59',
          'D7',
          'B17',
          'A17',
          'A58',
          'C50',
          'A28',
          'C42',
          'C5',
          'A10',
          'C55',
          'B48',
          'C19',
          'B49',
          'D58',
          'C30',
          'A34',
          'B18',
          'B56',
          'A51',
          'B58',
          'C4',
          'D5',
          'A54',
          'A26',
          'C8',
          'A41',
          'C32',
          'A18',
          'C20',
          'D13',
          'C2',
          'C43',
          'A13',
          'C34',
          'A50',
          'B15',
          'D40',
          'B34',
          'C53',
          'A33',
          'B36',
          'A55',
          'D2',
          'A8',
          'D4',
          'C3',
          'D11',
          'C10',
          'A44',
          'B13',
          'D47',
          'B40',
          'D59',
          'D29',
          'D52',
          'B22',
          'D44',
          'D22',
          'C59',
          'B48',
          'A14',
          'C17',
          'C42',
          'D19',
          'C56',
          'A50',
          'C14',
          'A4',
          'A36',
          'B25',
          'A25',
          'C15',
          'B49',
          'B2',
          'C23',
          'B3',
          'B46',
          'B31',
          'D17',
          'D51',
          'B34',
          'D37',
          'D22',
          'D44',
          'B8',
          'D8',
          'C6',
          'C57',
          'B47',
          'C18',
          'D14',
          'C1',
          'A32',
          'C35',
          'C15',
          'A56',
          'C53',
          'B48',
          'C16',
          'A19',
          'B16',
          'D45',
          'B17',
          'D40',
          'B36',
          'D5',
          'B20',
          'A41',
          'C24',
          'B4',
          'B35',
          'D42',
          'B41',
          'A45',
          'C40',
          'D19',
          'D57',
          'B49',
          'A15',
          'B13',
          'A8',
          'B15',
          'A21',
          'B22',
          'A37',
          'C4',
          'B59',
          'A9',
          'B60',
          'A6',
          'C37',
          'A17',
          'C34',
          'A51',
          'C43',
          'B1',
          'D36',
          'D21',
          'D48',
          'B50',
          'C18',
          'C33',
          'D19',
          'C10',
          'A33',
          'D52',
          'B23',
          'D14',
          'D60',
          'D26',
          'C49',
          'A5',
          'D5',
          'B49',
          'A51',
          'A28',
          'C8',
          'A23',
          'B9',
          'B59',
          'B27',
          'A24',
          'A54',
          'B37',
          'A40',
          'C7',
          'D6',
          'A60',
          'A10',
          'C13',
          'A48',
          'D32',
          'C52',
          'D29',
          'C50',
          'C12',
          'A22',
          'C31',
          'A49',
          'D1',
          'C60',
          'D20',
          'C57',
          'B51',
          'D48',
          'B14',
          'A29',
          'C26',
          'A40',
          'D30',
          'A41',
          'B46',
          'B18',
          'C15',
          'C56',
          'A39',
          'C35',
          'D59',
          'B41',
          'D34',
          'A50',
          'B57',
          'B8',
          'D38',
          'B9',
          'D8',
          'C4',
          'D12',
          'C3',
          'D24',
          'A13',
          'B47',
          'B12',
          'A6',
          'D2',
          'C6',
          'A19',
          'C19',
          'A38',
          'B10',
          'C53',
          'B35',
          'B2',
          'B60',
          'A3',
          'C15',
          'B39',
          'A28',
          'B24',
          'C10',
          'A7',
          'D53',
          'C37',
          'D15',
          'D56',
          'B33',
          'B18',
          'B54',
          'C22',
          'B22',
          'C20',
          'A34',
          'C25',
          'B5',
          'D41',
          'A12',
          'C54',
          'B2',
          'A10',
          'B3',
          'A9',
          'C27',
          'D14',
          'B53',
          'C23',
          'D54',
          'B55',
          'A52',
          'D2',
          'A3',
          'D3',
          'C6',
          'D6',
          'B14',
          'D47',
          'D31',
          'A42',
          'C12',
          'A43',
          'C49',
          'B45',
          'C17',
          'B50',
          'A15',
          'A59',
          'B48',
          'C14',
          'A20',
          'A56',
          'C16',
          'C45',
          'A30',
          'C9',
          'A44',
          'B43',
          'D51',
          'D17',
          'D50',
          'D18',
          'D38',
          'B37',
          'B16',
          'A13',
          'C39',
          'C17',
          'A29',
          'D46',
          'D20',
          'B42',
          'C52',
          'D34',
          'B5',
          'B30',
          'A24',
          'C23',
          'B56',
          'A45',
          'B60',
          'A47',
          'C28',
          'A7',
          'B8',
          'A46',
          'B34',
          'C35',
          'D16',
          'D55',
          'B31',
          'C31',
          'C55',
          'C38',
          'A35',
          'C3',
          'D12',
          'C2',
          'D13',
          'B19',
          'C15',
          'B40',
          'D36',
          'D21',
          'C47',
          'A58',
          'C19',
          'B22',
          'D43',
          'D28',
          'A42',
          'B13',
          'C11',
          'B27',
          'C50',
          'B44',
          'C14',
          'B49',
          'C60',
          'D24',
          'C46',
          'D57',
          'A10',
          'D1',
          'C59',
          'C24',
          'A40',
          'B43',
          'B4',
          'A9',
          'D6',
          'C4',
          'D9',
          'C6',
          'A39',
          'A2',
          'B7',
          'C26',
          'D14',
          'D60',
          'A32',
          'D50',
          'B19',
          'B59',
          'A5',
          'C15',
          'A53',
          'B53',
          'B22',
          'A19',
          'B23',
          'D44',
          'C59',
          'D20',
          'D46',
          'B21',
          'D45',
          'D25',
          'A18',
          'C25',
          'B26',
          'D10',
          'A21',
          'C32',
          'B56',
          'A43',
          'C29',
          'A51',
          'B6',
          'D39',
          'A10',
          'B58',
          'A49',
          'C59',
          'C16',
          'B17',
          'C14',
          'B45',
          'D12',
          'C1',
          'B52',
          'D60',
          'D18',
          'D55',
          'D19',
          'C30',
          'B55',
          'A60',
          'C55',
          'B13',
          'A22',
          'A55',
          'C38',
          'A48',
          'A25',
          'C20',
          'B16',
          'D49',
          'B33',
          'C52',
          'B10',
          'C44',
          'A5',
          'D50',
          'C35',
          'D17',
          'B24',
          'D13',
          'C1',
          'B60',
          'C5',
          'D3',
          'A2',
          'D4',
          'A50',
          'D33',
          'A47',
          'B40',
          'C43',
          'D16',
          'C26',
          'A43',
          'A26',
          'D27',
          'B30',
          'A55',
          'B56',
          'C19',
          'A59',
          'C60',
          'B54',
          'B1',
          'B47',
          'A12',
          'C21',
          'A2',
          'D48',
          'D18',
          'C50',
          'B46',
          'B26',
          'C48',
          'B4',
          'A23',
          'C7',
          'A41',
          'C52',
          'B48',
          'A13',
          'D42',
          'A30',
          'B20',
          'C9',
          'B22',
          'B47',
          'C15',
          'B53',
          'A4',
          'B13',
          'C8',
          'A7',
          'B58',
          'C44',
          'D57',
          'D32',
          'B14',
          'A22',
          'B26',
          'A18',
          'A56',
          'A11',
          'D56',
          'D15',
          'C18',
          'A37',
          'D26',
          'D45',
          'A29',
          'B34',
          'A24',
          'A54',
          'C14',
          'B18',
          'C13',
          'A20',
          'B53',
          'A36',
          'D57',
          'A28',
          'D47',
          'B51',
          'A42',
          'D25',
          'A13',
          'C27',
          'C42',
          'A19',
          'B25',
          'D53',
          'D24',
          'D48',
          'B33',
          'C36',
          'A25',
          'A50',
          'D26',
          'B30',
          'B45',
          'C58',
          'D22',
          'D54',
          'C41',
          'D15',
          'B17',
          'C59',
          'C12',
          'A23',
          'B11',
          'D46',
          'B15',
          'C19',
          'B46',
          'C17',
          'A18',
          'D6',
          'C3',
          'A37',
          'C33',
          'A56',
          'B21',
          'D44',
          'B51',
          'A17',
          'C27',
          'B8',
          'A24',
          'D36',
          'B29',
          'C11',
          'B3',
          'B50',
          'C14',
          'A51',
          'B33',
          'D17',
          'D59',
          'D18',
          'C34',
          'B57',
          'D13',
          'B41',
          'C44',
          'A8',
          'C4',
          'D3',
          'B1',
          'C37',
          'A35',
          'B12',
          'A5',
          'D2',
          'C47',
          'C24',
          'B26',
          'D53',
          'A32',
          'D44',
          'B33',
          'A37',
          'C22',
          'B2',
          'D36',
          'D52',
          'B40',
          'A36',
          'B48',
          'C15',
          'A7',
          'D1',
          'C46',
          'A16',
          'C39',
          'A34',
          'C9',
          'D11',
          'C8',
          'B14',
          'A4',
          'B55',
          'C29',
          'D20',
          'D35',
          'A46',
          'C13',
          'B19',
          'D46',
          'C19',
          'B16',
          'C18',
          'C51',
          'A57',
          'C33',
          'A40',
          'D31',
          'C53',
          'B32',
          'D55',
          'B37',
          'D37',
          'B44',
          'A11',
          'A58',
          'C45',
          'A34',
          'D23',
          'C26',
          'B25',
          'C10',
          'D8',
          'B1',
          'A32',
          'C27',
          'A38',
          'B46',
          'C58',
          'B17',
          'C44',
          'B39',
          'B6',
          'C25',
          'B58',
          'D58',
          'D14',
          'D60',
          'C34',
          'B33',
          'D43',
          'D28',
          'A54',
          'C60',
          'D4',
          'C5',
          'A44',
          'B49',
          'D49',
          'C18',
          'B44',
          'A46',
          'B19',
          'C12',
          'A25',
          'D23',
          'A10',
          'D5',
          'A53',
          'C36',
          'A21',
          'D8',
          'C3',
          'A40',
          'C11',
          'B30',
          'D50',
          'D19',
          'D55',
          'B39',
          'A27',
          'C55',
          'C16',
          'A20',
          'C7',
          'B10',
          'C13',
          'B23',
          'A39',
          'B44',
          'D36',
          'A23',
          'B21',
          'A43',
          'B60',
          'A12',
          'C49',
          'B25',
          'D46',
          'A3',
          'C29',
          'B57',
          'C19',
          'C37',
          'A48',
          'B12',
          'D48',
          'D25',
          'D40',
          'C56',
          'B51',
          'A15',
          'C20',
          'A58',
          'C18',
          'B22',
          'D24',
          'C45',
          'A60',
          'B58',
          'B27',
          'B42',
          'D13',
          'C1',
          'D16',
          'C40',
          'A31',
          'D45',
          'D26',
          'A38',
          'B56',
          'C31',
          'A24',
          'C42',
          'B1',
          'D30',
          'A42',
          'B41',
          'C16',
          'C43',
          'A14',
          'B13',
          'C17',
          'B47',
          'D12',
          'B17',
          'D35',
          'C52',
          'B34',
          'C34',
          'A35',
          'D58',
          'D18',
          'C57',
          'B49',
          'A32',
          'C21',
          'B14',
          'C20',
          'B45',
          'C48',
          'B27',
          'A16',
          'B8',
          'A6',
          'D7',
          'C5',
          'D10',
          'B54',
          'C15',
          'B55',
          'C2',
          'B57',
          'A8',
          'D54',
          'B24',
          'A26',
          'A45',
          'D31',
          'B37',
          'C59',
          'B7',
          'A51',
          'A25',
          'C8',
          'A29',
          'C41',
          'B5',
          'C9',
          'B8',
          'C26',
          'B28',
          'C11',
          'D10',
          'C4',
          'A34',
          'D52',
          'B24',
          'A3',
          'D22',
          'D54',
          'A40',
          'C23',
          'C56',
          'A52',
          'B59',
          'C25',
          'B51',
          'C16',
          'A2',
          'D5',
          'C7',
          'D7',
          'A48',
          'B43',
          'B3',
          'D30',
          'C49',
          'A57',
          'A20',
          'D3',
          'A1',
          'A18',
          'B31',
          'D38',
          'B12',
          'D41',
          'C15',
          'A16',
          'B50',
          'A5',
          'B8',
          'B38',
          'A29',
          'D57',
          'D14',
          'B26',
          'D34',
          'A22',
          'A55',
          'C14',
          'B51',
          'D24',
          'D53',
          'A36',
          'C28',
          'A59',
          'D35',
          'A49',
          'C30',
          'C57',
          'D32',
          'A41',
          'B54',
          'C33',
          'B55',
          'B19',
          'C46',
          'D18',
          'D58',
          'C37',
          'B35',
          'C13',
          'B24',
          'A21',
          'C6',
          'D1',
          'A4',
          'D2',
          'A10',
          'B22',
          'A1',
          'D26',
          'A43',
          'B45',
          'A11',
          'D40',
          'A51',
          'B37',
          'D16',
          'C23',
          'B21',
          'A52',
          'D37',
          'C59',
          'C21',
          'A44',
          'C4',
          'D11',
          'C3',
          'D12',
          'C28',
          'B10',
          'C5',
          'C49',
          'A28',
          'C29',
          'A35',
          'B57',
          'A53',
          'D30',
          'B34',
          'B9',
          'C17',
          'B18',
          'D15',
          'D56',
          'D40',
          'B5',
          'C39',
          'A19',
          'D48',
          'A36',
          'C24',
          'B4',
          'B47',
          'A37',
          'B59',
          'C47',
          'D3',
          'A45',
          'B44',
          'C59',
          'C8',
          'B22',
          'C14',
          'D35',
          'A24',
          'A54',
          'B40',
          'D21',
          'A2',
          'C7',
          'D8',
          'C21',
          'A60',
          'C44',
          'A18',
          'D30',
          'B10',
          'C4',
          'A7',
          'A58',
          'C35',
          'B32',
          'C12',
          'B36',
          'D58',
          'A13',
          'B43',
          'A12',
          'D6',
          'A44',
          'B50',
          'A1',
          'C20',
          'B17',
          'C18',
          'A38',
          'D60',
          'C54',
          'C32',
          'D47',
          'A30',
          'C31',
          'D15',
          'D52',
          'D36',
          'A8',
          'B60',
          'C4',
          'D23',
          'C59',
          'D4',
          'A56',
          'B52',
          'A15',
          'D44',
          'A34',
          'D9',
          'C6',
          'D2',
          'A19',
          'C37',
          'A31',
          'C24',
          'B5',
          'D18',
          'C55',
          'C25',
          'D20',
          'D46',
          'B26',
          'C13',
          'B4',
          'A20',
          'B21',
          'A41',
          'D27',
          'C51',
          'B32',
          'A48',
          'C34',
          'B35',
          'D41',
          'C57',
          'B52',
          'B37',
          'B22',
          'C17',
          'B47',
          'B10',
          'D47',
          'C3',
          'D16',
          'D55',
          'D19',
          'C39',
          'A45',
          'D36',
          'D21',
          'A15',
          'D7',
          'B26',
          'A17',
          'B53',
          'C14',
          'B9',
          'C6',
          'A41',
          'B59',
          'C35',
          'A60',
          'B40',
          'A27',
          'B7',
          'C37',
          'A55',
          'D27',
          'D42',
          'B33',
          'D38',
          'B43',
          'C16',
          'B52',
          'A42',
          'C45',
          'A2',
          'A33',
          'C19',
          'D17',
          'B35',
          'D7',
          'C11',
          'A19',
          'C21',
          'B21',
          'C9',
          'B46',
          'C48',
          'D1',
          'A51',
          'A36',
          'D56',
          'C47',
          'A13',
          'B19',
          'A45',
          'B34',
          'A8',
          'D52',
          'A23',
          'B12',
          'A2',
          'D6',
          'A22',
          'B55',
          'C22',
          'A9',
          'B11',
          'D20',
          'C55',
          'C9',
          'C46',
          'C25',
          'B20',
          'D47',
          'B16',
          'A47',
          'D29',
          'B34',
          'D56',
          'A33',
          'D51',
          'A4',
          'B27',
          'D46',
          'B28',
          'D31',
          'A39',
          'C27',
          'A57',
          'C16',
          'B18',
          'C58',
          'B50',
          'C20',
          'D8',
          'A10',
          'B45',
          'D36',
          'C60',
          'D43',
          'D28',
          'A26',
          'B2',
          'D17',
          'C48',
          'D27',
          'A51',
          'D33',
          'B5',
          'A6',
          'A32',
          'D59',
          'B42',
          'C51',
          'C5',
          'D21',
          'C54',
          'D37',
          'C14',
          'C44',
          'C29',
          'A49',
          'C24',
          'B20',
          'C12',
          'B10',
          'B29',
          'D60',
          'C52',
          'B30',
          'C32',
          'A27',
          'C36',
          'A33',
          'B46',
          'D50',
          'D19',
          'A24',
          'D39',
          'B25',
          'C38',
          'A16',
          'C1',
          'D13',
          'B51',
          'D7',
          'B12',
          'C18',
          'B8',
          'C15',
          'B56',
          'D55',
          'A7',
          'D4',
          'C57',
          'B53',
          'D56',
          'A42',
          'B45',
          'A34',
          'D49',
          'D19',
          'A4',
          'B28',
          'D14',
          'A52',
          'A25',
          'B29',
          'C16',
          'B47',
          'A58',
          'A17',
          'B4',
          'A9',
          'A28',
          'C15',
          'D34',
          'B27',
          'C20',
          'A60',
          'B44',
          'B11',
          'C53',
          'D10',
          'A19',
          'D3',
          'C2',
          'D12',
          'B7',
          'C19',
          'A36',
          'B54',
          'C32',
          'B25',
          'C9',
          'D7',
          'A56',
          'D48',
          'A37',
          'C11',
          'B31',
          'C30',
          'A53',
          'D8',
          'D56',
          'C45',
          'B19',
          'C44',
          'C1',
          'D16',
          'D52',
          'B32',
          'D45',
          'D26',
          'A36',
          'B60',
          'A60',
          'D31',
          'A25',
          'D54',
          'A9',
          'B14',
          'C47',
          'B15',
          'C13',
          'B39',
          'C2',
          'C59',
          'B38',
          'D37',
          'A47',
          'A29',
          'B23',
          'C8',
          'C49',
          'C21',
          'A24',
          'C37',
          'B8',
          'A4',
          'C17',
          'A15',
          'C41',
          'A43',
          'D25',
          'D48',
          'B15',
          'D15',
          'B24',
          'D47',
          'A2',
          'B48',
          'D9',
          'C6',
          'A48',
          'A3',
          'B14',
          'C11',
          'B19',
          'C30',
          'B56',
          'D54',
          'B39',
          'A44',
          'C19',
          'D13',
          'C28',
          'B57',
          'C15',
          'A23',
          'B34',
          'D18',
          'A26',
          'D38',
          'B35',
          'B58',
          'A8',
          'D20',
          'D46',
          'A35',
          'C25',
          'C58',
          'C22',
          'B3',
          'C33',
          'B24',
          'A2',
          'C38',
          'B26',
          'C23',
          'B57',
          'A43',
          'D33',
          'B41',
          'C13',
          'A28',
          'A47',
          'B33',
          'A25',
          'D48',
          'A20',
          'C34',
          'A55',
          'A22',
          'C24',
          'B52',
          'D59',
          'D21',
          'D36',
          'A48',
          'C32',
          'B35',
          'A8',
          'D37',
          'B39',
          'C20',
          'B32',
          'B4',
          'A16',
          'B11',
          'C5',
          'D10',
          'A37',
          'B23',
          'A9',
          'D23',
          'B46',
          'C8',
          'A42',
          'C33',
          'A58',
          'C10',
          'B44',
          'C51',
          'A7',
          'D45',
          'B10',
          'A13',
          'D40',
          'B19',
          'A11',
          'B57',
          'D13',
          'A49',
          'D41',
          'A29',
          'A53',
          'B54',
          'C31',
          'B25',
          'D54',
          'B58',
          'C22',
          'A6',
          'C9',
          'B3',
          'D56',
          'B4',
          'C33',
          'D22',
          'D44',
          'B16',
          'B59',
          'C19',
          'D19',
          'D48',
          'A29',
          'C36',
          'A34',
          'D25',
          'B26',
          'C21',
          'B15',
          'C43',
          'B41',
          'D52',
          'D16',
          'A58',
          'C49',
          'A33',
          'C5',
          'B12',
          'D33',
          'C57',
          'C41',
          'D18',
          'A24',
          'A54',
          'C50',
          'B50',
          'C28',
          'B11',
          'C24',
          'B32',
          'D44',
          'C30',
          'B42',
          'B57',
          'C27',
          'A59',
          'A7',
          'D49',
          'B13',
          'A6',
          'C35',
          'B19',
          'C10',
          'D8',
          'C7',
          'A24',
          'B10',
          'C54',
          'A23',
          'D22',
          'B47',
          'A31',
          'B49',
          'C59',
          'D5',
          'A41',
          'D24',
          'D53',
          'A21',
          'B2',
          'C13',
          'A53',
          'D36',
          'C55',
          'B38',
          'C16',
          'B30',
          'D60',
          'D14',
          'B40',
          'A3',
          'D29',
          'A15',
          'B11',
          'A7',
          'C12',
          'A47',
          'B54',
          'A44',
          'D56',
          'B35',
          'C31',
          'A38',
          'C10',
          'B45',
          'B19',
          'D23',
          'C48',
          'C23',
          'B13',
          'C54',
          'D38',
          'B36',
          'D17',
          'D60',
          'A13',
          'B46',
          'C20',
          'A27',
          'D1',
          'C5',
          'B4',
          'C45',
          'A54',
          'B52',
          'D47',
          'C19',
          'B27',
          'D6',
          'C17',
          'A19',
          'B34',
          'D43',
          'D28',
          'A39',
          'C25',
          'C44',
          'B20',
          'B5',
          'D30',
          'C40',
          'A15',
          'D27',
          'A23',
          'C34',
          'A36',
          'A51',
          'D26',
          'D58',
          'B22',
          'C33',
          'B26',
          'C9',
          'B47',
          'D12',
          'C2',
          'D13',
          'C1',
          'A54',
          'B19',
          'D48',
          'A8',
          'A29',
          'B1',
          'B50',
          'C49',
          'B18',
          'D51',
          'A36',
          'C15',
          'B58',
          'A6',
          'D54',
          'A34',
          'B25',
          'C24',
          'D33',
          'A21',
          'B7',
          'A50',
          'C28',
          'D22',
          'B8',
          'D10',
          'C4',
          'D11',
          'B20',
          'D16',
          'B38',
          'C49',
          'B56',
          'C43',
          'D15',
          'C57',
          'A49',
          'D32',
          'A28',
          'D56',
          'B14',
          'A2',
          'B40',
          'D35',
          'B1',
          'C56',
          'B31',
          'A21',
          'C16',
          'A27',
          'A47',
          'C9',
          'B9',
          'C27',
          'B27',
          'C17',
          'B28',
          'D45',
          'D26',
          'A53',
          'D44',
          'B38',
          'C15',
          'B43',
          'B20',
          'C35',
          'A16',
          'C29',
          'A58',
          'C8',
          'B3',
          'D7',
          'A11',
          'C44',
          'D1',
          'C58',
          'C18',
          'A13',
          'B49',
          'D5',
          'C5',
          'B14',
          'C60',
          'A55',
          'C36',
          'A46',
          'A1',
          'B24',
          'D9',
          'A33',
          'C47',
          'B23',
          'D24',
          'C25',
          'A50',
          'D39',
          'A29',
          'B50',
          'C9',
          'D8',
          'C51',
          'B34',
          'C33',
          'B60',
          'C22',
          'B13',
          'A12',
          'A55',
          'D15',
          'D56',
          'B30',
          'D46',
          'D20',
          'D35',
          'B4',
          'C42',
          'B33',
          'C12',
          'D6',
          'B40',
          'C20',
          'B21',
          'A11',
          'C5',
          'D49',
          'A58',
          'B48',
          'C51',
          'A23',
          'A54',
          'C43',
          'D57',
          'D14',
          'C36',
          'B8',
          'A16',
          'D43',
          'D28',
          'B34',
          'D8',
          'A20',
          'A57',
          'A18',
          'B7',
          'A26',
          'D58',
          'B50',
          'C17',
          'B17',
          'A15',
          'B53',
          'C34',
          'A56',
          'C19',
          'B23',
          'D48',
          'D19',
          'D55',
          'C48',
          'B16',
          'C59',
          'D45',
          'A52',
          'A25',
          'D40',
          'B27',
          'C13',
          'A5',
          'B26',
          'C32',
          'B4',
          'C18',
          'B46',
          'C39',
          'D9',
          'C6',
          'D2',
          'C58',
          'D5',
          'C56',
          'D34',
          'B17',
          'D57',
          'B50',
          'A54',
          'C25',
          'C40',
          'A33',
          'C35',
          'B21',
          'C60',
          'B55',
          'C20',
          'A3',
          'B8',
          'D19',
          'C9',
          'B51',
          'A57',
          'C15',
          'D13',
          'A37',
          'B39',
          'B5',
          'B45',
          'C59',
          'B57',
          'B28',
          'D39',
          'B11',
          'C12',
          'B37',
          'D53',
          'D25',
          'A39',
          'C46',
          'A48',
          'A27',
          'A49',
          'C14',
          'A23',
          'C48',
          'D18',
          'C31',
          'D56',
          'D15',
          'C27',
          'A33',
          'D55',
          'B23',
          'D16',
          'C55',
          'D59',
          'A31',
          'B52',
          'A51',
          'A1',
          'B36',
          'A8',
          'B7',
          'C13',
          'B30',
          'D33',
          'A38',
          'D50',
          'A21',
          'B54',
          'A5',
          'B13',
          'C41',
          'B39',
          'C49',
          'D5',
          'C29',
          'B23',
          'C18',
          'B5',
          'D4',
          'A49',
          'D27',
          'C33',
          'D40',
          'C28',
          'C57',
          'B14',
          'D31',
          'B33',
          'A59',
          'B46',
          'C10',
          'B48',
          'A35',
          'C21',
          'A45',
          'C38',
          'D20',
          'D54',
          'D22',
          'D44',
          'B24',
          'C47',
          'D27',
          'A29',
          'B20',
          'C7',
          'D8',
          'A9',
          'D3',
          'A4',
          'A31',
          'C28',
          'B17',
          'D49',
          'A55',
          'A22',
          'C60',
          'A59',
          'C21',
          'A51',
          'D15',
          'B55',
          'A21',
          'D12',
          'C32',
          'D14',
          'D57',
          'B2',
          'C10',
          'B7',
          'D45',
          'A33',
          'D41',
          'B4',
          'C40',
          'B45',
          'B27',
          'D54',
          'A37',
          'B29',
          'C17',
          'D7',
          'C11',
          'A43',
          'C22',
          'A18',
          'C24',
          'B12',
          'A16',
          'D60',
          'A55',
          'A21',
          'D28',
          'D43',
          'B51',
          'A34',
          'B56',
          'B37',
          'D30',
          'A39',
          'B45',
          'C13',
          'B31',
          'D10',
          'C39',
          'A52',
          'D8',
          'A60',
          'D17',
          'D56',
          'B15',
          'C5',
          'D9',
          'C52',
          'B24',
          'C18',
          'B26',
          'B60',
          'A11',
          'C40',
          'C55',
          'A50',
          'C9',
          'B48',
          'C8',
          'D7',
          'C58',
          'D31',
          'C39',
          'A54',
          'C38',
          'C57',
          'B17',
          'B47',
          'C54',
          'B33',
          'C11',
          'A49',
          'A3',
          'D4',
          'A42',
          'C44',
          'A30',
          'D46',
          'D20',
          'D45',
          'C5',
          'D10',
          'A58',
          'A11',
          'D1',
          'B10',
          'C6',
          'D3',
          'B9',
          'C36',
          'B1',
          'C21',
          'B13',
          'D38',
          'A51',
          'C41',
          'B53',
          'C9',
          'A8',
          'A30',
          'C8',
          'D19',
          'D55',
          'B50',
          'D49',
          'B18',
          'C22',
          'A23',
          'B45',
          'B2',
          'C12',
          'A24',
          'D11',
          'C3',
          'B10',
          'B31',
          'C16',
          'B42',
          'C13',
          'D5',
          'C9',
          'B55',
          'C28',
          'A58',
          'A17',
          'C26',
          'A59',
          'D3',
          'A29',
          'C48',
          'B50',
          'B29',
          'C14',
          'B1',
          'C55',
          'A52',
          'A25',
          'D50',
          'B3',
          'B41',
          'C32',
          'A55',
          'C25',
          'A1',
          'D19',
          'D48',
          'D25',
          'D40',
          'A10',
          'B24',
          'D59',
          'D12',
          'A5',
          'C24',
          'B42',
          'C20',
          'A39',
          'B21',
          'C19',
          'B60',
          'C45',
          'B40',
          'A24',
          'D57',
          'D14',
          'C56',
          'B37',
          'A36',
          'B44',
          'C11',
          'B47',
          'A1',
          'A53',
          'D3',
          'B25',
          'D47',
          'B53',
          'D36',
          'B16',
          'A16',
          'B23',
          'C34',
          'D2',
          'B20',
          'C21',
          'B2',
          'D53',
          'B27',
          'C18',
          'D58',
          'D13',
          'C1',
          'A15',
          'C30',
          'B54',
          'C51',
          'B21',
          'A22',
          'C57',
          'C26',
          'D18',
          'D56',
          'A47',
          'A4',
          'B29',
          'A13',
          'B57',
          'C3',
          'D11',
          'B23',
          'C28',
          'D3',
          'A44',
          'A29',
          'C29',
          'C55',
          'B45',
          'C38',
          'B4',
          'D60',
          'B38',
          'C12',
          'A40',
          'D29',
          'A19',
          'B40',
          'A11',
          'C22',
          'A30',
          'D20',
          'D35',
          'C24',
          'D9',
          'C6',
          'B13',
          'D1',
          'C36',
          'B18',
          'A7',
          'B43',
          'C13',
          'A21',
          'B12',
          'C60',
          'B36',
          'C33',
          'C52',
          'D10',
          'C4',
          'A47',
          'D12',
          'C3',
          'D24',
          'A43',
          'B33',
          'C35',
          'B49',
          'C10',
          'D4',
          'A11',
          'B46',
          'C38',
          'A4',
          'C25',
          'B10',
          'A55',
          'D42',
          'A3',
          'A58',
          'C42',
          'B52',
          'A30',
          'D43',
          'D28',
          'A59',
          'C59',
          'B19',
          'A44',
          'C8',
          'B50',
          'D45',
          'D26',
          'D53',
          'D37',
          'A60',
          'C58',
          'D46',
          'B31',
          'D33',
          'A18',
          'A57',
          'C46',
          'B25',
          'A15',
          'B27',
          'D47',
          'C44',
          'D18',
          'A7',
          'D8',
          'C7',
          'A58',
          'C15',
          'A37',
          'C47',
          'D17',
          'D51',
          'B22',
          'A17',
          'B7',
          'A25',
          'B60',
          'B31',
          'D26',
          'A24',
          'D52',
          'A6',
          'C16',
          'B13',
          'B38',
          'C22',
          'D1',
          'A13',
          'B30',
          'D39',
          'B32',
          'C13',
          'B9',
          'C26',
          'B57',
          'C18',
          'B28',
          'C51',
          'B49',
          'D59',
          'A44',
          'B6',
          'C14',
          'B23',
          'C10',
          'D7',
          'C8',
          'B51',
          'C33',
          'A45',
          'C19',
          'A4',
          'A36',
          'A51',
          'A27',
          'C30',
          'B4',
          'D6',
          'C12',
          'B29',
          'D57',
          'D14',
          'B12',
          'D50',
          'D17',
          'A10',
          'C34',
          'A39',
          'C24',
          'A42',
          'D27',
          'A2',
          'B8',
          'C43',
          'B19',
          'C22',
          'B25',
          'C14',
          'A16',
          'B24',
          'C25',
          'D30',
          'B54',
          'D10',
          'C4',
          'B40',
          'C53',
          'B1',
          'C45',
          'B50',
          'C44',
          'D29',
          'C42',
          'B34',
          'B49',
          'C54',
          'B27',
          'C9',
          'A29',
          'C47',
          'B43',
          'D20',
          'D45',
          'D26',
          'A7',
        ],
      },
    ],
  },
  {
    title: 'Eye',
    id: 'eye',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/eye%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D10',
          'B53',
          'D9',
          'B46',
          'D5',
          'B49',
          'D14',
          'B44',
          'D11',
          'B45',
          'D19',
          'B42',
          'D15',
          'B43',
          'D23',
          'B40',
          'D20',
          'B41',
          'D17',
          'B51',
          'D13',
          'B57',
          'D18',
          'B48',
          'D4',
          'B36',
          'D24',
          'B39',
          'D21',
          'B34',
          'D25',
          'B35',
          'D16',
          'B37',
          'D22',
          'B44',
          'D7',
          'B47',
          'D19',
          'B50',
          'A11',
          'C56',
          'A13',
          'C53',
          'A15',
          'C50',
          'A17',
          'C48',
          'A18',
          'C46',
          'A19',
          'C44',
          'A7',
          'C52',
          'A16',
          'B55',
          'D14',
          'B58',
          'A14',
          'C55',
          'A10',
          'C41',
          'A20',
          'C43',
          'A8',
          'B48',
          'A6',
          'C47',
          'A15',
          'B54',
          'A13',
          'C39',
          'A21',
          'D5',
          'A17',
          'B57',
          'A16',
          'C45',
          'A18',
          'B56',
          'A11',
          'C40',
          'A19',
          'B53',
          'D16',
          'B41',
          'D29',
          'B33',
          'D26',
          'B38',
          'D25',
          'B42',
          'D12',
          'B43',
          'D20',
          'B44',
          'A3',
          'C48',
          'A12',
          'B52',
          'A10',
          'C51',
          'A13',
          'C36',
          'A15',
          'B57',
          'D13',
          'A24',
          'B12',
          'A30',
          'B17',
          'B1',
          'B21',
          'B6',
          'B23',
          'A60',
          'B24',
          'A57',
          'B18',
          'A29',
          'B13',
          'A32',
          'B16',
          'A27',
          'B19',
          'A39',
          'B20',
          'D27',
          'B32',
          'D30',
          'B37',
          'D28',
          'B30',
          'D31',
          'B36',
          'D23',
          'B41',
          'D22',
          'B38',
          'D21',
          'B42',
          'A2',
          'B40',
          'D26',
          'B33',
          'D24',
          'B35',
          'D32',
          'B29',
          'D35',
          'B34',
          'D33',
          'B27',
          'D27',
          'B37',
          'D15',
          'B47',
          'D3',
          'A20',
          'C40',
          'A12',
          'C37',
          'A21',
          'C35',
          'A16',
          'B51',
          'D11',
          'A23',
          'B17',
          'A40',
          'B18',
          'A28',
          'B15',
          'A34',
          'D10',
          'B44',
          'A5',
          'B46',
          'D20',
          'A32',
          'B20',
          'A27',
          'D9',
          'B53',
          'A10',
          'C43',
          'A19',
          'B54',
          'D19',
          'A44',
          'B16',
          'A31',
          'D17',
          'B34',
          'D29',
          'B36',
          'D35',
          'B26',
          'D34',
          'B32',
          'D33',
          'B28',
          'D37',
          'B25',
          'A56',
          'D16',
          'B42',
          'A1',
          'C29',
          'A8',
          'B49',
          'A6',
          'C46',
          'C30',
          'C59',
          'B56',
          'A17',
          'D6',
          'A14',
          'C38',
          'A20',
          'D4',
          'A13',
          'B48',
          'D15',
          'A25',
          'B20',
          'A38',
          'B21',
          'A27',
          'D10',
          'A9',
          'C28',
          'A2',
          'B60',
          'A15',
          'B51',
          'A11',
          'B57',
          'A6',
          'C30',
          'C52',
          'B1',
          'D21',
          'B40',
          'D24',
          'A30',
          'B17',
          'A22',
          'C34',
          'A17',
          'B53',
          'A9',
          'D11',
          'A33',
          'B15',
          'A46',
          'D23',
          'B37',
          'D27',
          'B30',
          'D26',
          'A34',
          'D2',
          'A23',
          'D3',
          'A2',
          'C25',
          'A6',
          'B59',
          'A10',
          'C27',
          'C53',
          'B4',
          'B19',
          'A44',
          'D10',
          'B45',
          'A3',
          'B41',
          'D20',
          'B47',
          'A7',
          'D7',
          'A14',
          'B53',
          'D9',
          'A35',
          'B14',
          'D28',
          'A36',
          'D3',
          'A34',
          'D27',
          'B15',
          'A24',
          'B16',
          'A42',
          'B10',
          'A25',
          'B21',
          'D36',
          'B25',
          'D39',
          'B31',
          'D31',
          'B32',
          'D25',
          'A29',
          'B20',
          'A23',
          'C34',
          'C51',
          'C32',
          'C58',
          'B57',
          'A14',
          'C28',
          'A4',
          'C49',
          'A15',
          'D6',
          'A25',
          'D16',
          'A39',
          'D17',
          'A53',
          'D29',
          'B12',
          'A41',
          'B16',
          'A31',
          'D19',
          'B52',
          'A20',
          'B17',
          'D28',
          'B26',
          'D36',
          'B31',
          'D35',
          'B22',
          'A27',
          'D22',
          'B33',
          'D18',
          'A49',
          'D23',
          'A33',
          'D12',
          'A9',
          'C42',
          'A19',
          'B55',
          'A15',
          'C27',
          'A5',
          'D4',
          'A2',
          'B43',
          'D15',
          'B50',
          'A7',
          'C24',
          'A10',
          'D2',
          'A37',
          'B21',
          'A23',
          'C33',
          'C56',
          'B9',
          'C55',
          'B7',
          'D22',
          'A47',
          'D12',
          'A28',
          'B18',
          'A40',
          'B19',
          'D37',
          'B20',
          'D40',
          'B24',
          'D38',
          'B27',
          'D32',
          'A37',
          'D30',
          'B39',
          'D27',
          'B28',
          'D36',
          'A39',
          'D5',
          'A33',
          'B15',
          'A58',
          'D16',
          'B60',
          'B45',
          'D21',
          'A27',
          'B58',
          'A11',
          'B47',
          'A5',
          'C32',
          'C60',
          'C30',
          'A13',
          'D1',
          'A3',
          'D2',
          'A1',
          'B38',
          'D13',
          'A32',
          'B15',
          'A45',
          'D24',
          'A28',
          'B19',
          'A26',
          'D18',
          'A52',
          'B11',
          'A42',
          'D22',
          'A60',
          'D16',
          'A30',
          'D29',
          'A38',
          'D17',
          'A55',
          'B14',
          'A48',
          'D27',
          'B32',
          'D23',
          'A29',
          'B17',
          'A19',
          'C36',
          'C55',
          'C21',
          'A2',
          'D5',
          'A42',
          'D11',
          'B51',
          'A7',
          'B55',
          'A5',
          'C26',
          'A1',
          'C44',
          'A17',
          'B50',
          'A4',
          'B42',
          'D21',
          'A43',
          'D26',
          'A29',
          'D14',
          'A50',
          'D8',
          'A8',
          'C23',
          'C54',
          'B5',
          'C53',
          'C38',
          'A22',
          'B21',
          'D40',
          'B30',
          'D32',
          'A41',
          'B16',
          'A30',
          'B60',
          'A18',
          'B17',
          'A17',
          'C27',
          'C60',
          'B57',
          'C57',
          'B58',
          'C56',
          'C31',
          'A23',
          'B22',
          'D33',
          'A40',
          'D38',
          'B18',
          'D41',
          'B23',
          'D29',
          'A35',
          'B14',
          'A34',
          'D11',
          'A19',
          'D23',
          'A59',
          'D9',
          'B46',
          'A12',
          'B48',
          'A9',
          'D2',
          'C60',
          'B39',
          'D22',
          'A32',
          'D25',
          'B30',
          'D37',
          'B24',
          'A37',
          'D18',
          'B40',
          'D13',
          'A11',
          'C23',
          'A1',
          'D4',
          'A45',
          'D29',
          'A51',
          'B12',
          'A36',
          'D31',
          'B35',
          'D28',
          'A52',
          'D22',
          'B4',
          'C43',
          'A26',
          'B20',
          'A24',
          'C33',
          'C48',
          'B43',
          'D12',
          'A41',
          'D27',
          'A29',
          'B17',
          'D42',
          'B22',
          'A22',
          'C36',
          'C59',
          'B36',
          'D17',
          'B45',
          'C42',
          'A27',
          'C32',
          'A36',
          'D21',
          'B8',
          'A43',
          'D10',
          'B54',
          'A21',
          'B59',
          'A19',
          'D24',
          'A48',
          'B15',
          'A32',
          'D33',
          'B29',
          'D30',
          'A31',
          'D20',
          'B49',
          'C50',
          'B51',
          'A4',
          'B48',
          'C51',
          'A58',
          'C42',
          'B38',
          'D23',
          'A57',
          'D17',
          'A13',
          'C29',
          'C60',
          'D5',
          'A19',
          'D27',
          'A35',
          'D1',
          'C47',
          'A17',
          'C26',
          'A27',
          'B18',
          'A16',
          'B52',
          'A11',
          'D19',
          'A33',
          'B12',
          'C57',
          'D23',
          'B33',
          'C38',
          'A48',
          'D41',
          'B19',
          'D28',
          'B31',
          'D24',
          'A50',
          'B13',
          'D29',
          'A43',
          'D34',
          'A39',
          'D13',
          'B41',
          'C41',
          'B42',
          'B57',
          'C46',
          'B11',
          'D30',
          'A34',
          'B54',
          'A10',
          'B46',
          'A20',
          'B22',
          'D39',
          'B26',
          'A55',
          'D32',
          'A31',
          'C42',
          'A60',
          'D13',
          'A9',
          'C25',
          'A19',
          'D12',
          'A51',
          'D7',
          'A13',
          'C22',
          'A1',
          'B59',
          'A46',
          'D25',
          'A44',
          'D18',
          'C54',
          'C34',
          'C59',
          'C31',
          'A12',
          'D15',
          'A49',
          'D26',
          'B37',
          'D14',
          'B50',
          'D6',
          'A52',
          'B26',
          'D34',
          'A32',
          'B24',
          'A27',
          'D23',
          'B35',
          'D21',
          'B39',
          'C42',
          'B5',
          'D41',
          'B29',
          'D29',
          'A37',
          'B6',
          'D30',
          'A39',
          'D37',
          'A40',
          'D15',
          'B8',
          'C45',
          'B9',
          'A34',
          'C31',
          'C16',
          'C55',
          'B58',
          'C46',
          'A25',
          'C33',
          'A26',
          'B59',
          'A15',
          'B49',
          'C49',
          'A9',
          'B43',
          'C41',
          'B35',
          'D48',
          'B28',
          'D46',
          'B29',
          'D44',
          'A49',
          'B27',
          'D49',
          'A51',
          'D23',
          'B3',
          'C54',
          'C17',
          'C40',
          'B39',
          'D16',
          'A14',
          'B55',
          'C58',
          'D22',
          'A7',
          'B46',
          'A3',
          'C20',
          'C38',
          'A50',
          'B26',
          'A37',
          'C31',
          'A27',
          'D15',
          'A46',
          'D31',
          'A31',
          'B16',
          'A21',
          'B21',
          'D17',
          'C55',
          'D20',
          'A17',
          'D24',
          'A12',
          'C23',
          'A23',
          'B48',
          'D11',
          'B51',
          'A28',
          'B20',
          'D18',
          'B42',
          'A16',
          'D3',
          'C48',
          'C17',
          'C51',
          'A59',
          'D34',
          'B27',
          'D31',
          'B10',
          'C45',
          'A25',
          'D20',
          'A54',
          'B9',
          'A43',
          'B15',
          'D39',
          'A42',
          'D35',
          'B25',
          'C26',
          'A28',
          'B57',
          'C41',
          'A10',
          'D1',
          'A7',
          'D8',
          'A5',
          'D17',
          'B46',
          'C29',
          'A16',
          'B54',
          'A8',
          'D6',
          'A44',
          'D34',
          'A60',
          'D9',
          'B53',
          'A20',
          'D29',
          'A47',
          'B14',
          'A34',
          'B19',
          'A30',
          'C43',
          'B37',
          'D24',
          'C58',
          'B34',
          'D26',
          'B31',
          'C34',
          'B32',
          'D30',
          'A44',
          'B59',
          'C46',
          'A9',
          'B44',
          'D12',
          'A32',
          'D35',
          'A37',
          'C30',
          'A7',
          'C52',
          'A57',
          'D10',
          'A48',
          'D21',
          'B40',
          'C39',
          'C18',
          'C45',
          'B11',
          'D22',
          'B5',
          'D43',
          'B16',
          'A24',
          'B21',
          'A18',
          'D8',
          'C21',
          'C36',
          'A20',
          'D16',
          'A1',
          'D17',
          'B48',
          'A22',
          'C35',
          'A42',
          'D42',
          'B6',
          'D20',
          'B44',
          'A2',
          'D1',
          'C8',
          'D3',
          'A53',
          'D14',
          'A25',
          'B11',
          'C13',
          'C49',
          'A16',
          'D21',
          'A11',
          'C39',
          'B41',
          'A6',
          'C19',
          'B20',
          'C24',
          'D7',
          'C22',
          'A27',
          'B25',
          'D50',
          'B26',
          'D47',
          'B27',
          'A44',
          'D26',
          'C59',
          'D2',
          'A21',
          'B22',
          'A18',
          'C44',
          'B3',
          'A13',
          'B56',
          'A7',
          'D23',
          'A47',
          'D42',
          'A50',
          'D19',
          'C56',
          'C15',
          'C41',
          'A32',
          'B15',
          'D18',
          'B35',
          'D44',
          'B8',
          'A37',
          'B10',
          'D49',
          'B34',
          'D32',
          'A42',
          'D27',
          'B29',
          'C37',
          'B52',
          'A22',
          'D31',
          'A33',
          'C32',
          'B31',
          'C31',
          'B49',
          'D17',
          'A21',
          'D26',
          'A13',
          'B4',
          'A11',
          'B60',
          'D16',
          'A45',
          'D37',
          'A54',
          'D13',
          'B9',
          'C12',
          'C50',
          'B44',
          'A18',
          'C26',
          'B41',
          'D22',
          'B34',
          'C38',
          'A51',
          'D31',
          'B28',
          'C27',
          'A26',
          'B25',
          'A59',
          'D58',
          'B3',
          'D26',
          'C58',
          'B13',
          'A36',
          'D33',
          'B9',
          'C44',
          'A25',
          'D30',
          'A56',
          'C53',
          'B50',
          'C51',
          'C11',
          'B60',
          'C9',
          'D2',
          'C6',
          'D3',
          'C56',
          'B10',
          'D12',
          'C46',
          'C14',
          'C42',
          'B1',
          'D8',
          'C19',
          'B21',
          'C17',
          'D9',
          'A6',
          'B58',
          'C41',
          'A54',
          'D48',
          'A49',
          'C39',
          'A19',
          'B18',
          'C23',
          'A29',
          'B17',
          'D39',
          'B23',
          'C25',
          'D6',
          'B44',
          'C26',
          'A37',
          'B56',
          'A22',
          'D10',
          'A58',
          'D36',
          'A33',
          'B14',
          'C14',
          'C57',
          'B13',
          'C47',
          'A17',
          'B51',
          'B36',
          'D28',
          'A54',
          'D18',
          'A3',
          'B48',
          'C30',
          'A38',
          'D31',
          'B22',
          'D30',
          'B35',
          'C37',
          'C19',
          'D9',
          'C44',
          'A29',
          'B24',
          'D51',
          'B33',
          'D36',
          'A41',
          'D41',
          'A51',
          'B7',
          'A39',
          'D24',
          'C57',
          'B8',
          'D20',
          'A43',
          'D39',
          'B32',
          'C29',
          'A25',
          'B14',
          'C47',
          'A24',
          'B19',
          'C16',
          'D9',
          'A33',
          'C40',
          'B57',
          'C12',
          'B59',
          'A12',
          'B49',
          'A22',
          'D19',
          'C54',
          'B7',
          'A26',
          'B26',
          'C29',
          'A34',
          'B10',
          'D47',
          'B34',
          'D46',
          'A50',
          'C49',
          'B51',
          'D11',
          'A55',
          'C38',
          'B53',
          'C44',
          'C13',
          'C47',
          'B44',
          'C25',
          'A28',
          'B17',
          'A14',
          'C51',
          'A7',
          'D2',
          'C53',
          'D17',
          'B36',
          'C58',
          'B37',
          'B57',
          'C10',
          'B59',
          'A31',
          'C22',
          'B15',
          'D52',
          'B32',
          'D19',
          'A26',
          'B50',
          'A18',
          'C41',
          'C16',
          'B20',
          'D43',
          'A42',
          'B58',
          'A22',
          'C34',
          'C19',
          'B26',
          'D33',
          'A50',
          'B13',
          'A36',
          'D7',
          'C20',
          'A5',
          'D21',
          'B43',
          'A4',
          'D3',
          'C5',
          'D4',
          'C26',
          'D5',
          'C23',
          'D6',
          'C60',
          'B35',
          'C35',
          'A43',
          'B28',
          'D45',
          'A57',
          'D39',
          'B34',
          'D19',
          'A14',
          'B40',
          'A7',
          'C18',
          'D8',
          'B3',
          'A45',
          'B6',
          'C43',
          'B38',
          'B53',
          'C37',
          'B30',
          'C28',
          'A16',
          'D28',
          'A1',
          'D30',
          'A52',
          'D54',
          'A53',
          'D21',
          'A18',
          'D23',
          'A60',
          'A31',
          'B16',
          'A30',
          'C25',
          'A38',
          'D37',
          'A33',
          'B56',
          'C58',
          'D14',
          'A28',
          'B21',
          'A16',
          'B22',
          'C3',
          'D4',
          'A3',
          'C31',
          'D13',
          'C29',
          'B48',
          'C52',
          'C10',
          'B8',
          'A39',
          'C30',
          'A24',
          'B26',
          'D22',
          'A11',
          'C32',
          'A22',
          'B60',
          'C7',
          'D1',
          'C46',
          'A15',
          'B52',
          'A9',
          'B4',
          'C11',
          'B6',
          'C8',
          'B27',
          'D28',
          'B2',
          'C52',
          'B53',
          'C34',
          'D12',
          'A17',
          'D27',
          'A13',
          'B45',
          'A18',
          'D2',
          'C49',
          'B17',
          'C22',
          'A32',
          'C33',
          'A35',
          'B8',
          'D34',
          'B20',
          'A19',
          'D17',
          'C36',
          'A21',
          'D32',
          'B9',
          'C10',
          'C25',
          'D13',
          'B42',
          'C27',
          'B43',
          'D18',
          'B17',
          'D54',
          'B31',
          'D53',
          'A52',
          'C37',
          'A14',
          'B2',
          'C41',
          'C58',
          'B14',
          'A33',
          'B19',
          'D36',
          'A49',
          'D1',
          'B37',
          'D22',
          'B30',
          'C36',
          'B33',
          'D50',
          'A56',
          'D24',
          'A7',
          'B4',
          'C1',
          'B21',
          'D43',
          'B36',
          'D16',
          'B22',
          'C20',
          'A32',
          'B15',
          'C47',
          'B56',
          'C13',
          'B58',
          'A26',
          'B27',
          'C29',
          'A42',
          'D34',
          'B33',
          'C59',
          'A14',
          'C21',
          'D6',
          'C37',
          'A18',
          'C24',
          'B44',
          'B59',
          'A8',
          'D20',
          'B12',
          'D53',
          'A60',
          'C3',
          'B25',
          'D48',
          'B8',
          'A9',
          'C45',
          'C10',
          'B60',
          'A6',
          'B42',
          'C22',
          'B18',
          'C49',
          'A51',
          'D26',
          'B39',
          'D14',
          'C29',
          'A27',
          'C27',
          'D3',
          'C4',
          'B1',
          'D36',
          'B24',
          'A28',
          'C24',
          'A31',
          'D25',
          'C60',
          'B40',
          'C38',
          'A11',
          'B35',
          'D29',
          'B25',
          'C30',
          'A15',
          'D5',
          'C3',
          'B43',
          'D12',
          'C32',
          'B34',
          'D45',
          'A43',
          'B30',
          'D55',
          'B14',
          'A45',
          'B7',
          'C43',
          'D9',
          'B53',
          'A32',
          'B56',
          'A24',
          'C34',
          'B33',
          'C29',
          'C6',
          'B23',
          'A30',
          'B16',
          'A20',
          'B58',
          'C40',
          'D7',
          'C20',
          'B13',
          'A35',
          'D43',
          'A46',
          'D37',
          'A27',
          'D25',
          'B39',
          'C19',
          'A19',
          'D1',
          'C10',
          'B30',
          'C55',
          'B52',
          'C53',
          'A48',
          'C50',
          'A49',
          'D34',
          'B21',
          'D59',
          'A55',
          'D51',
          'B15',
          'C14',
          'D9',
          'C13',
          'C58',
          'D27',
          'B36',
          'C31',
          'A36',
          'B55',
          'C45',
          'D2',
          'C7',
          'C27',
          'A39',
          'B9',
          'D19',
          'B14',
          'D40',
          'A34',
          'B11',
          'A37',
          'D39',
          'B35',
          'C16',
          'A19',
          'B23',
          'D38',
          'A44',
          'D44',
          'B18',
          'C17',
          'A28',
          'B60',
          'C57',
          'B33',
          'D33',
          'B36',
          'D15',
          'C29',
          'A43',
          'C59',
          'B4',
          'C44',
          'B5',
          'D21',
          'C56',
          'A47',
          'C54',
          'B28',
          'C20',
          'D8',
          'A14',
          'B18',
          'D42',
          'B7',
          'C11',
          'C42',
          'B56',
          'A29',
          'B19',
          'C49',
          'A53',
          'C48',
          'A7',
          'B8',
          'C9',
          'C46',
          'B13',
          'D58',
          'B21',
          'A2',
          'A41',
          'C60',
          'A42',
          'D23',
          'A24',
          'B27',
          'D17',
          'C34',
          'D6',
          'B35',
          'D36',
          'A55',
          'C41',
          'B36',
          'C16',
          'D10',
          'C43',
          'A59',
          'D24',
          'A11',
          'B45',
          'D12',
          'C30',
          'A25',
          'C28',
          'B24',
          'D49',
          'B33',
          'D48',
          'B12',
          'C21',
          'A33',
          'D22',
          'B38',
          'A13',
          'C48',
          'D4',
          'C35',
          'B30',
          'C27',
          'A16',
          'B58',
          'C52',
          'B3',
          'A38',
          'B9',
          'C6',
          'B27',
          'A23',
          'B16',
          'D45',
          'A58',
          'D56',
          'B29',
          'D28',
          'C60',
          'B17',
          'C48',
          'C7',
          'B2',
          'D20',
          'A13',
          'D9',
          'A5',
          'B49',
          'A2',
          'D15',
          'C28',
          'B25',
          'A25',
          'B15',
          'A31',
          'C36',
          'D11',
          'C35',
          'A21',
          'B47',
          'A10',
          'D25',
          'B36',
          'D3',
          'C53',
          'C9',
          'B31',
          'D52',
          'B23',
          'C21',
          'B30',
          'D44',
          'B4',
          'D59',
          'A40',
          'B5',
          'C12',
          'C27',
          'D15',
          'B38',
          'B60',
          'B40',
          'B55',
          'A34',
          'C23',
          'A30',
          'D7',
          'C37',
          'C52',
          'B24',
          'D30',
          'B33',
          'D27',
          'A10',
          'C55',
          'B10',
          'D29',
          'C60',
          'A46',
          'C59',
          'A60',
          'C40',
          'D10',
          'C13',
          'B44',
          'C41',
          'A58',
          'D35',
          'B19',
          'A22',
          'C48',
          'A54',
          'D58',
          'B28',
          'C36',
          'C4',
          'C30',
          'B35',
          'D18',
          'A16',
          'D4',
          'C1',
          'B5',
          'D55',
          'A47',
          'C51',
          'B22',
          'D40',
          'B31',
          'C38',
          'D5',
          'C49',
          'B52',
          'C46',
          'A28',
          'D8',
          'C17',
          'B16',
          'A15',
          'C37',
          'D10',
          'C12',
          'B58',
          'A32',
          'B21',
          'A14',
          'B60',
          'C47',
          'C8',
          'B17',
          'D53',
          'B14',
          'A33',
          'A59',
          'C41',
          'B5',
          'D40',
          'A41',
          'C1',
          'D5',
          'C22',
          'B19',
          'D19',
          'A2',
          'B22',
          'D9',
          'A23',
          'B57',
          'A9',
          'B49',
          'C54',
          'D1',
          'A25',
          'C25',
          'D15',
          'A22',
          'B27',
          'C26',
          'B45',
          'C24',
          'D4',
          'A43',
          'B32',
          'D51',
          'A48',
          'B3',
          'C6',
          'C49',
          'A52',
          'C35',
          'C14',
          'C43',
          'C10',
          'D10',
          'C9',
          'D11',
          'C33',
          'B28',
          'D34',
          'A22',
          'B20',
          'D41',
          'B30',
          'A7',
          'D3',
          'C47',
          'B45',
          'B60',
          'A8',
          'C17',
          'A29',
          'A60',
          'D50',
          'A50',
          'D32',
          'B31',
          'C56',
          'B4',
          'D42',
          'B28',
          'D57',
          'A57',
          'C43',
          'A32',
          'A1',
          'A36',
          'C28',
          'D2',
          'B36',
          'A11',
          'C50',
          'B50',
          'C31',
          'B24',
          'A27',
          'C21',
          'B31',
          'D20',
          'C40',
          'B2',
          'D56',
          'A39',
          'C25',
          'B38',
          'D31',
          'C60',
          'A59',
          'C5',
          'B27',
          'C19',
          'D7',
          'B33',
          'C55',
          'A43',
          'C4',
          'D3',
          'D19',
          'B6',
          'D35',
          'B7',
          'A44',
          'D40',
          'A48',
          'D47',
          'B17',
          'D45',
          'A35',
          'B18',
          'D37',
          'B32',
          'C14',
          'A19',
          'B43',
          'A17',
          'C42',
          'B59',
          'A41',
          'C36',
          'B54',
          'C49',
          'B20',
          'C18',
          'C40',
          'C3',
          'B1',
          'D57',
          'B13',
          'C19',
          'D12',
          'C8',
          'C54',
          'A55',
          'C48',
          'B12',
          'A37',
          'A1',
          'D1',
          'C29',
          'A45',
          'B25',
          'C32',
          'A24',
          'D32',
          'B36',
          'C15',
          'D8',
          'C45',
          'A16',
          'B44',
          'D18',
          'C33',
          'B30',
          'D54',
          'B13',
          'A34',
          'C21',
          'D6',
          'C41',
          'D9',
          'C3',
          'B3',
          'D46',
          'A54',
          'C55',
          'A46',
          'C58',
          'B15',
          'D38',
          'B17',
          'A12',
          'B55',
          'C44',
          'B56',
          'B38',
          'D27',
          'B41',
          'D26',
          'B29',
          'A32',
          'C42',
          'B6',
          'D40',
          'B25',
          'D34',
          'B35',
          'D5',
          'A1',
          'A39',
          'C24',
          'B21',
          'D32',
          'B37',
          'C31',
          'C1',
          'A42',
          'B33',
          'C33',
          'D59',
          'A41',
          'D43',
          'B17',
          'A11',
          'C54',
          'B8',
          'C13',
          'A25',
          'B51',
          'A2',
          'A30',
          'D45',
          'B7',
          'D56',
          'B16',
          'C15',
          'B57',
          'C14',
          'A21',
          'B27',
          'C35',
          'A23',
          'D38',
          'B31',
          'C53',
          'A60',
          'A27',
          'B26',
          'C28',
          'C9',
          'B24',
          'D35',
          'A46',
          'B4',
          'C58',
          'B38',
          'C41',
          'D19',
          'B41',
          'C23',
          'A25',
          'B18',
          'A31',
          'C34',
          'A52',
          'D40',
          'A56',
          'D60',
          'D5',
          'B44',
          'A4',
          'B59',
          'C51',
          'B23',
          'A29',
          'D46',
          'B15',
          'C21',
          'B51',
          'C54',
          'B55',
          'C48',
          'D24',
          'B42',
          'C49',
          'D13',
          'C8',
          'B58',
          'A9',
          'B32',
          'D53',
          'B22',
          'A31',
          'B54',
          'A20',
          'C37',
          'D58',
          'D5',
          'C35',
          'C5',
          'D1',
          'C44',
          'B10',
          'A39',
          'C23',
          'C7',
          'B26',
          'D29',
          'A2',
          'A57',
          'A1',
          'B19',
          'D44',
          'B34',
          'D37',
          'B23',
          'A18',
          'C17',
          'B21',
          'D41',
          'A34',
          'A3',
          'B50',
          'C16',
          'B56',
          'C41',
          'B6',
          'A40',
          'D58',
          'D6',
          'C31',
          'A24',
          'B48',
          'C22',
          'A59',
          'C55',
          'B27',
          'C34',
          'D14',
          'B39',
          'C25',
          'D3',
          'C2',
          'B20',
          'C27',
          'A29',
          'C16',
          'D12',
          'B46',
          'A13',
          'C47',
          'B12',
          'A49',
          'C50',
          'A50',
          'C12',
          'B32',
          'D50',
          'B9',
          'A26',
          'B29',
          'D55',
          'A59',
          'D48',
          'A44',
          'C4',
          'B16',
          'A19',
          'C38',
          'D21',
          'B30',
          'D33',
          'A23',
          'A3',
          'A35',
          'D30',
          'B40',
          'A5',
          'B26',
          'C17',
          'B10',
          'C11',
          'B58',
          'A45',
          'D52',
          'A33',
          'B14',
          'C46',
          'B60',
          'C6',
          'A19',
          'C24',
          'D15',
          'B44',
          'C49',
          'A57',
          'C38',
          'C58',
          'B54',
          'C52',
          'A48',
          'C51',
          'B28',
          'C26',
          'D2',
          'C5',
          'C30',
          'A40',
          'A1',
          'A45',
          'B18',
          'D53',
          'B40',
          'C37',
          'C3',
          'D13',
          'C40',
          'D57',
          'D6',
          'C59',
          'B35',
          'D28',
          'B36',
          'C44',
          'A18',
          'B24',
          'C21',
          'B41',
          'C6',
          'B10',
          'A53',
          'D47',
          'B2',
          'C45',
          'B52',
          'A2',
          'D32',
          'A27',
          'C18',
          'D7',
          'A6',
          'B43',
          'D24',
          'A46',
          'C57',
          'D2',
          'C44',
          'D8',
          'C14',
          'A52',
          'D50',
          'B13',
          'D41',
          'B24',
          'C11',
          'B28',
          'D30',
          'B9',
          'C59',
          'B47',
          'A13',
          'B39',
          'C40',
          'D22',
          'C37',
          'D60',
          'B3',
          'C42',
          'B4',
          'C30',
          'A26',
          'B30',
          'A23',
          'B18',
          'D36',
          'A45',
          'D45',
          'B10',
          'D23',
          'B29',
          'A44',
          'C35',
          'A48',
          'B27',
          'D59',
          'A56',
          'C20',
          'D6',
          'D22',
          'B39',
          'B54',
          'A18',
          'C41',
          'D56',
          'B28',
          'D29',
          'A13',
          'C45',
          'A17',
          'D2',
          'C51',
          'D3',
          'C36',
          'A20',
          'C5',
          'D12',
          'B49',
          'A21',
          'B51',
          'D11',
          'C43',
          'B55',
          'A1',
          'A58',
          'D58',
          'C34',
          'C15',
          'B13',
          'A26',
          'C23',
          'B17',
          'A9',
          'B33',
          'D40',
          'B16',
          'A30',
          'D44',
          'B15',
          'A32',
          'D49',
          'A43',
          'C2',
          'C41',
          'D12',
          'C12',
          'B19',
          'D42',
          'B29',
          'D34',
          'B34',
          'D31',
          'A25',
          'B26',
          'C53',
          'B5',
          'D54',
          'B7',
          'C42',
          'B37',
          'D17',
          'C24',
          'A22',
          'C11',
          'B44',
          'A1',
          'B21',
          'C27',
          'B24',
          'A51',
          'C50',
          'D4',
          'C25',
          'A17',
          'A2',
          'A35',
          'B9',
          'D48',
          'B13',
          'C13',
          'A26',
          'D38',
          'B29',
          'C51',
          'B47',
          'C35',
          'D13',
          'B41',
          'D26',
          'B35',
          'D20',
          'C39',
          'B32',
          'C17',
          'B48',
          'D4',
          'C4',
          'C33',
          'A52',
          'B11',
          'A34',
          'D24',
          'C43',
          'A21',
          'B28',
          'D47',
          'B33',
          'C12',
          'B6',
          'A36',
          'D54',
          'A46',
          'D30',
          'B34',
          'C23',
          'A58',
          'D31',
          'B23',
          'A28',
          'B25',
          'C52',
          'A16',
          'C9',
          'D53',
          'A51',
          'C36',
          'B55',
          'A10',
          'B3',
          'D57',
          'C38',
          'B32',
          'D38',
          'A5',
          'B27',
          'C53',
          'A47',
          'C39',
          'A50',
          'D30',
          'A30',
          'B18',
          'C48',
          'B59',
          'C8',
          'A18',
          'B58',
          'C54',
          'A43',
          'D41',
          'A25',
          'C19',
          'B28',
          'D53',
          'B50',
          'A9',
          'D51',
          'A49',
          'D20',
          'C33',
          'D60',
          'A39',
          'C56',
          'A42',
          'C27',
          'D1',
          'B37',
          'D25',
          'A58',
          'C4',
          'D12',
          'C55',
          'B4',
          'D41',
          'A47',
          'C8',
          'B40',
          'C28',
          'C5',
          'C21',
          'B59',
          'C41',
          'B7',
          'D51',
          'B23',
          'C32',
          'B35',
          'D17',
          'B43',
          'D21',
          'A30',
          'B20',
          'D35',
          'B32',
          'C36',
          'A55',
          'D49',
          'A37',
          'C43',
          'D55',
          'B11',
          'C56',
          'D7',
          'C16',
          'B21',
          'D57',
          'C35',
          'A25',
          'A3',
          'A38',
          'C42',
          'B18',
          'D45',
          'A60',
          'C23',
          'B25',
          'D35',
          'A48',
          'D22',
          'B41',
          'A14',
          'B22',
          'C22',
          'A39',
          'B56',
          'C15',
          'A20',
          'B19',
          'C48',
          'B51',
          'C16',
          'D15',
          'B45',
          'C41',
          'D16',
          'C3',
          'C31',
          'D14',
          'C49',
          'D6',
          'B4',
          'D23',
          'A15',
          'B1',
          'C59',
          'B52',
          'A21',
          'D36',
          'A57',
          'C1',
          'D13',
          'D57',
          'B6',
          'C53',
          'A11',
          'C26',
          'B29',
          'C11',
          'D9',
          'B53',
          'A13',
          'C10',
          'B45',
          'C6',
          'B28',
          'D43',
          'B23',
          'A14',
          'D55',
          'A38',
          'C28',
          'B4',
          'D58',
          'A12',
          'C38',
          'A60',
          'D59',
          'B1',
          'D29',
          'B34',
          'D27',
          'C48',
          'B13',
          'D47',
          'B14',
          'C22',
          'B25',
          'D30',
          'C47',
          'D10',
          'C44',
          'D54',
          'B21',
          'A19',
          'D37',
          'A32',
          'C30',
          'B5',
          'D31',
          'A13',
          'D57',
          'B27',
          'C52',
          'D1',
          'A51',
          'C12',
          'B8',
          'D33',
          'B33',
          'D46',
          'A10',
          'D60',
          'C32',
          'A49',
          'B13',
          'A34',
          'C19',
          'A56',
          'A41',
          'A1',
          'B32',
          'C59',
          'B59',
          'B44',
          'C48',
          'B20',
          'A29',
          'D7',
          'B34',
          'D24',
          'C40',
          'A18',
          'B27',
          'C27',
          'B43',
          'D16',
          'B7',
          'A36',
          'C57',
          'A41',
          'B2',
          'C56',
          'B12',
          'C20',
          'D11',
          'C42',
          'D55',
          'B16',
          'C50',
          'A56',
          'A3',
          'A28',
          'D28',
          'B28',
          'D39',
          'B19',
          'A33',
          'B14',
          'D60',
          'B20',
          'D33',
          'B35',
          'C23',
          'D4',
          'C21',
          'D48',
          'A41',
          'C30',
          'B22',
          'C46',
          'D3',
          'A21',
          'C34',
          'D7',
          'D53',
          'B30',
          'D8',
          'A26',
          'C12',
          'B42',
          'D22',
          'A28',
          'C45',
          'B54',
          'A2',
          'C13',
          'A49',
          'C1',
          'A55',
          'D44',
          'B22',
          'C29',
          'B23',
          'D42',
          'A24',
          'C14',
          'B6',
          'C30',
          'A33',
          'B57',
          'A27',
          'B50',
          'D20',
          'B13',
          'C10',
          'B26',
          'A23',
          'B31',
          'D51',
          'C20',
          'A18',
          'D26',
          'B36',
          'D23',
          'B37',
          'C18',
          'B12',
          'A39',
          'C42',
          'B19',
          'C60',
          'C24',
          'C40',
          'D56',
          'D13',
          'B47',
          'D21',
          'C55',
          'B34',
          'D42',
          'A46',
          'C54',
          'B4',
          'A38',
          'D36',
          'B5',
          'D59',
          'D4',
          'C39',
          'A54',
          'C37',
          'A30',
          'B22',
          'D52',
          'B6',
          'C7',
          'A22',
          'D11',
          'B51',
          'A24',
          'D35',
          'B27',
          'C50',
          'A11',
          'C17',
          'C1',
          'A9',
          'B59',
          'C40',
          'B29',
          'D25',
          'B40',
          'D39',
          'A58',
          'C57',
          'B1',
          'C25',
          'B24',
          'A26',
          'D43',
          'B3',
          'A27',
          'B60',
          'C58',
          'D1',
          'C6',
          'D11',
          'C56',
          'B13',
          'C9',
          'B26',
          'C16',
          'A53',
          'D50',
          'A31',
          'B15',
          'D57',
          'A15',
          'D27',
          'D12',
          'D58',
          'C39',
          'D24',
          'B2',
          'D45',
          'B14',
          'D59',
          'C38',
          'B38',
          'D17',
          'C23',
          'B44',
          'C60',
          'A45',
          'C28',
          'A46',
          'C6',
          'B46',
          'C47',
          'B16',
          'D54',
          'B27',
          'D50',
          'B52',
          'A30',
          'C44',
          'A36',
          'B12',
          'C12',
          'B25',
          'A4',
          'A31',
          'C21',
          'D5',
          'A14',
          'C31',
          'A5',
          'B41',
          'C28',
          'B31',
          'D28',
          'B32',
          'D49',
          'A56',
          'C36',
          'B26',
          'D40',
          'B34',
          'C42',
          'A53',
          'C14',
          'C60',
          'D32',
          'B22',
          'C45',
          'D53',
          'D13',
          'D52',
          'A51',
          'B11',
          'C59',
          'B12',
          'A35',
          'D55',
          'C41',
          'D21',
          'C49',
          'A16',
          'B24',
          'D31',
          'A26',
          'B16',
          'D44',
          'C23',
          'D60',
          'A59',
          'C50',
          'B18',
          'C13',
          'A21',
          'A5',
          'B58',
          'B37',
          'A14',
          'D54',
          'D6',
          'C57',
          'B54',
          'C28',
          'B33',
          'D32',
          'C51',
          'A48',
          'C5',
          'D2',
          'C27',
          'B2',
          'A40',
          'C2',
          'C49',
          'D23',
          'B40',
          'C7',
          'A45',
          'D26',
          'A27',
          'B28',
          'C50',
          'B29',
          'C8',
          'B30',
          'D39',
          'B36',
          'D19',
          'B42',
          'D8',
          'B52',
          'A19',
          'B19',
          'C30',
          'A44',
          'A2',
          'A38',
          'C31',
          'A31',
          'B15',
          'A22',
          'B32',
          'D48',
          'A60',
          'D37',
          'B40',
          'C48',
          'D52',
          'B30',
          'C40',
          'A48',
          'D59',
          'A11',
          'B18',
          'D43',
          'C25',
          'D5',
          'B45',
          'D14',
          'C42',
          'B57',
          'C9',
          'A59',
          'A44',
          'D47',
          'C23',
          'A40',
          'C21',
          'B19',
          'D38',
          'B36',
          'C30',
          'B60',
          'C28',
          'B42',
          'A10',
          'B56',
          'C53',
          'B21',
          'D53',
          'A32',
          'B14',
          'D58',
          'B26',
          'C56',
          'B54',
          'A49',
          'C60',
          'B3',
          'D56',
          'A13',
          'B16',
          'A29',
          'B59',
          'C8',
          'A21',
          'C25',
          'D16',
          'A12',
          'B53',
          'C59',
          'B17',
          'D44',
          'B32',
          'D41',
          'B7',
          'A40',
          'D60',
          'C21',
          'A58',
          'C1',
          'C53',
          'C24',
          'A24',
          'B29',
          'D54',
          'A49',
          'D47',
          'B9',
          'C54',
          'B25',
          'C18',
          'B38',
          'D28',
          'C48',
          'A23',
          'D39',
          'B4',
          'C9',
          'B39',
          'A12',
          'B24',
          'D42',
          'A56',
          'D46',
          'A32',
          'C13',
          'A54',
          'C60',
          'B51',
          'C47',
          'C3',
          'B9',
          'D26',
          'B34',
          'C36',
          'B58',
          'C50',
          'B33',
          'D38',
          'B39',
          'A7',
          'D48',
          'B1',
          'D35',
          'B40',
          'D16',
          'B60',
          'C45',
          'B13',
          'A34',
          'D50',
          'B31',
          'A8',
          'D43',
          'A49',
          'C31',
          'B7',
          'D34',
          'A25',
          'C32',
          'A37',
          'B11',
          'D21',
          'D5',
          'D59',
          'C25',
          'C10',
          'A46',
          'C52',
          'B55',
          'C28',
          'D14',
          'C26',
          'D3',
          'C45',
          'D50',
          'A6',
          'D55',
          'B19',
          'A17',
          'B20',
          'A28',
          'B23',
          'D36',
          'D21',
          'B40',
          'D32',
          'C47',
          'B21',
          'D45',
          'B31',
          'C40',
          'C12',
          'B27',
          'D40',
          'A22',
          'C33',
          'C59',
          'C15',
          'A31',
          'A3',
          'B8',
          'D56',
          'C39',
          'B33',
          'D20',
          'C57',
          'D10',
          'C7',
          'B7',
          'D29',
          'B30',
          'A18',
          'C1',
          'B10',
          'A38',
          'C26',
          'D42',
          'A35',
          'C29',
          'B24',
          'C20',
          'C4',
          'A59',
          'A42',
          'C5',
          'B28',
          'D35',
          'A41',
          'C21',
          'D17',
          'B49',
          'D13',
          'C30',
          'B20',
          'D55',
          'B12',
          'D59',
          'B2',
          'C25',
          'B16',
          'A21',
          'C51',
          'D7',
          'A8',
          'B32',
          'D24',
          'B35',
          'D29',
          'C48',
          'B29',
          'D53',
          'D36',
          'A23',
          'C46',
          'A48',
          'C33',
          'B53',
          'A6',
          'B39',
          'A1',
          'B8',
          'C60',
          'B58',
          'C9',
          'A14',
          'B24',
          'A10',
          'B30',
          'D34',
          'B31',
          'C19',
          'D17',
          'C16',
          'A30',
          'C43',
          'D54',
          'A38',
          'B5',
          'C44',
          'D53',
          'A53',
          'D26',
          'B42',
          'D18',
          'A51',
          'C4',
          'B2',
          'A14',
          'C54',
          'B43',
          'D10',
          'C36',
          'D12',
          'B56',
          'B41',
          'D20',
          'A37',
          'C29',
          'B39',
          'D46',
          'B16',
          'D39',
          'B21',
          'A17',
          'B23',
          'D50',
          'A42',
          'A60',
          'A19',
          'C37',
          'B46',
          'D22',
          'C3',
          'A44',
          'A1',
          'B49',
          'C17',
          'A52',
          'D51',
          'A5',
          'D49',
          'B11',
          'C44',
          'C9',
          'B28',
          'A14',
          'B29',
          'C30',
          'B26',
          'C51',
          'B56',
          'C11',
          'D17',
          'C3',
          'B16',
          'A18',
          'D3',
          'C22',
          'A28',
          'C18',
          'B39',
          'D27',
          'A43',
          'C26',
          'B33',
          'A8',
          'D47',
          'B15',
          'A25',
          'B46',
          'C53',
          'B23',
          'C46',
          'D52',
          'A54',
          'C12',
          'D8',
          'C42',
          'B22',
          'D38',
          'A59',
          'A32',
          'B14',
          'C20',
          'B19',
          'A31',
          'C14',
          'B7',
          'D50',
          'D13',
          'B42',
          'C55',
          'B54',
          'C47',
          'D49',
          'C21',
          'D7',
          'C50',
          'B59',
          'C7',
          'B8',
          'D28',
          'B33',
          'D43',
          'B39',
          'C59',
          'A45',
          'C56',
          'B32',
          'D47',
          'A55',
          'C28',
          'D41',
          'B25',
          'C13',
          'C36',
          'A24',
          'B20',
          'A11',
          'B29',
          'C29',
          'B49',
          'C52',
          'B4',
          'C8',
          'A56',
          'C18',
          'B3',
          'A41',
          'D58',
          'B17',
          'D46',
          'A45',
          'D31',
          'C55',
          'B2',
          'A2',
          'A35',
          'B12',
          'C37',
          'A23',
          'B47',
          'D19',
          'B16',
          'D29',
          'B37',
          'A4',
          'B45',
          'D26',
          'C49',
          'D51',
          'C11',
          'B33',
          'C17',
          'B55',
          'C27',
          'A7',
          'D49',
          'B7',
          'C46',
          'B40',
          'D28',
          'A31',
          'D48',
          'B27',
          'A16',
          'C26',
          'D57',
          'A48',
          'C9',
          'A23',
          'B12',
          'D51',
          'A30',
          'C32',
          'A1',
          'B22',
          'A29',
          'A6',
          'D20',
          'D35',
          'A17',
          'C49',
          'B37',
          'C36',
          'D56',
          'B10',
          'A36',
          'C24',
          'D2',
          'B46',
          'D25',
          'B41',
          'D37',
          'B21',
          'A11',
          'D48',
          'B14',
          'D41',
          'A56',
          'D38',
          'B34',
          'A2',
          'C12',
          'D15',
          'B50',
          'C22',
          'A60',
          'D30',
          'B27',
          'C37',
          'B29',
          'D40',
          'B18',
          'A21',
          'C49',
          'A51',
          'C47',
          'D11',
          'B51',
          'C38',
          'D9',
          'B53',
          'C29',
          'A54',
          'D60',
          'D3',
          'C50',
          'B48',
          'D21',
          'C11',
          'D24',
          'C36',
          'D59',
          'D43',
          'A39',
          'C13',
          'A50',
          'C56',
          'B24',
          'C42',
          'D15',
          'C20',
          'A42',
          'D28',
          'B23',
          'A27',
          'B6',
          'C4',
          'D12',
          'C50',
          'D50',
          'A54',
          'D45',
          'B3',
          'C10',
          'A47',
          'C52',
          'B5',
          'D53',
          'C47',
          'D33',
          'B39',
          'C58',
          'D5',
          'A9',
          'B30',
          'C60',
          'B37',
          'D38',
          'A25',
          'C24',
          'A38',
          'A2',
          'B46',
          'C26',
          'B55',
          'A30',
          'B43',
          'D16',
          'C27',
          'D41',
          'B15',
          'A31',
          'B42',
          'C43',
          'B56',
          'A16',
          'D14',
          'B40',
          'D27',
          'D42',
          'B12',
          'D49',
          'C54',
          'B45',
          'A29',
          'C45',
          'B3',
          'A12',
          'D30',
          'B30',
          'D57',
          'D17',
          'D50',
          'C41',
          'C26',
          'A8',
          'C2',
          'A50',
          'D54',
          'C19',
          'B35',
          'C55',
          'B36',
          'D47',
          'A36',
          'C33',
          'B32',
          'D34',
          'B38',
          'C31',
          'A52',
          'C7',
          'B44',
          'C43',
          'B11',
          'C4',
          'D15',
          'B17',
          'D41',
          'A7',
          'B31',
          'C37',
          'D9',
          'C21',
          'A55',
          'D43',
          'C24',
          'B16',
          'A25',
          'C34',
          'A33',
          'B12',
          'C33',
          'D12',
          'B47',
          'C32',
          'A50',
          'D45',
          'C22',
          'A40',
          'B8',
          'C46',
          'A24',
          'D1',
          'A22',
          'A5',
          'A29',
          'C8',
          'B39',
          'A39',
          'A54',
          'B52',
          'C35',
          'A30',
          'B19',
          'D34',
          'C29',
          'D40',
          'A57',
          'C6',
          'B47',
          'C53',
          'A14',
          'C51',
          'B57',
          'C13',
          'B26',
          'D55',
          'B4',
          'D37',
          'B45',
          'C40',
          'D8',
          'C5',
          'A43',
          'C18',
          'B31',
          'C45',
          'B40',
          'A32',
          'C7',
          'B20',
          'A3',
          'B24',
          'A24',
          'B25',
          'A10',
          'B29',
          'A21',
          'D40',
          'C41',
          'B17',
          'C15',
          'D7',
          'C43',
          'D12',
          'D27',
          'B35',
          'C4',
          'D4',
          'C38',
          'A17',
          'C21',
          'D50',
          'C6',
          'D1',
          'C28',
          'A26',
          'B60',
          'C8',
          'B31',
          'D49',
          'D17',
          'C1',
          'B11',
          'A56',
          'B18',
          'A34',
          'B13',
          'C44',
          'B52',
          'A18',
          'D11',
          'C5',
          'A60',
          'A1',
          'B34',
          'D19',
          'A21',
          'D31',
          'B21',
          'A15',
          'B27',
          'C49',
          'B50',
          'A23',
          'D10',
          'C34',
          'D24',
          'C3',
          'D28',
          'A14',
          'D12',
          'C26',
          'A55',
          'C20',
          'B30',
          'C57',
          'B40',
          'C44',
          'D3',
          'C24',
          'B24',
          'C14',
          'B10',
          'A35',
          'A58',
          'C56',
          'D22',
          'B46',
          'C36',
          'D4',
          'C22',
          'D47',
          'B38',
          'D23',
          'C41',
          'B18',
          'A18',
          'C15',
          'A28',
          'B48',
          'C59',
          'A49',
          'C51',
          'B30',
          'D51',
          'A33',
          'A4',
          'B27',
          'C24',
          'D16',
          'C12',
          'D20',
          'C50',
          'B39',
          'D42',
          'D27',
          'A32',
          'B14',
          'A26',
          'C19',
          'D60',
          'A60',
          'D58',
          'B20',
          'D46',
          'A9',
          'D1',
          'C45',
          'B4',
          'D43',
          'B8',
          'C31',
          'D11',
          'B51',
          'A23',
          'B16',
          'A12',
          'B28',
          'D26',
          'B24',
          'D36',
          'C34',
          'A53',
          'C47',
          'A17',
          'B54',
          'D10',
          'A20',
          'C35',
          'B56',
          'A1',
          'C14',
          'A35',
          'B3',
          'A41',
          'D42',
          'B13',
          'A46',
          'D3',
          'C59',
          'D29',
          'C3',
          'D32',
          'C54',
          'D2',
          'C48',
          'D31',
          'B26',
          'A22',
          'D39',
          'C30',
          'D14',
          'A13',
          'B23',
          'A25',
          'B19',
          'C11',
          'C41',
          'B24',
          'C34',
          'B34',
          'A8',
          'B43',
          'D13',
          'C32',
          'B40',
          'D23',
          'C24',
          'D46',
          'A36',
          'B7',
          'D30',
          'B33',
          'D41',
          'C60',
          'B60',
          'C55',
          'B44',
          'A30',
          'B58',
          'C35',
          'A32',
          'B17',
          'D37',
          'C33',
          'B34',
          'C17',
          'C1',
          'A22',
          'C47',
          'B30',
          'C46',
          'D34',
          'C24',
          'A49',
          'C58',
          'B29',
          'D58',
          'B2',
          'A1',
          'A41',
          'C13',
          'D11',
          'A8',
          'B18',
          'D35',
          'C35',
          'B32',
          'A21',
          'C5',
          'D2',
          'C47',
          'B39',
          'D29',
          'A23',
          'B25',
          'C52',
          'B21',
          'A31',
          'D6',
          'C17',
          'A56',
          'C16',
          'B41',
          'D19',
          'B48',
          'C32',
          'D4',
          'A24',
          'B49',
          'C23',
          'B18',
          'A49',
          'C37',
          'C58',
          'B16',
          'D51',
          'C19',
          'B22',
          'A11',
          'B23',
          'D32',
          'A57',
          'D60',
          'B26',
          'A19',
          'D55',
          'D5',
          'C22',
          'A9',
          'D26',
          'C11',
          'B37',
          'D57',
          'B4',
          'C38',
          'B54',
          'A35',
          'B12',
          'C46',
          'D36',
          'D52',
          'B11',
          'C25',
          'D14',
          'C18',
          'A20',
          'B20',
          'A33',
          'C27',
          'A34',
          'B13',
          'D49',
          'A30',
          'B21',
          'D33',
          'C36',
          'B49',
          'D13',
          'C43',
          'A51',
          'A36',
          'B8',
          'C16',
          'B55',
        ],
      },
    ],
  },
  {
    title: 'Audrey Hepburn',
    id: 'audrey-hepburn',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Audrey%20Hepburn.png',
    artData: [
      {
        size: {
          x: 301,
          y: 301,
        },
        pegs: [
          {
            x: 301,
            y: 150.5,
            angle: 0,
            name: 'D1',
          },
          {
            x: 300.9484274088214,
            y: 154.4396307203349,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 300.79374498056336,
            y: 158.376561414563,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 300.5360587268358,
            y: 162.3080939070407,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 300.17554525292513,
            y: 166.23153372178183,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 299.7124516367585,
            y: 170.14419192911777,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 299.14709525956823,
            y: 174.04338698855474,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 298.47986358837517,
            y: 177.92644658656818,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 297.7112139104377,
            y: 181.79070946807278,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 296.8416730198503,
            y: 185.63352726031374,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 295.8718368565048,
            y: 189.45226628792935,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 294.8023700976631,
            y: 193.24430937794037,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 293.6340057024206,
            y: 197.00705765342957,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 292.36754440937284,
            y: 200.7379323146825,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 291.00385418782884,
            y: 204.43437640656765,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 289.54386964294866,
            y: 208.09385657094597,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.98859137521146,
            y: 211.71386478290793,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 286.339085294654,
            y: 215.29192006964837,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 284.59648189034937,
            y: 218.82557021080177,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 282.7619754556258,
            y: 222.31239341907104,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 280.836823269558,
            y: 225.74999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 278.82234473529087,
            y: 229.13603398975027,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 276.7199204757863,
            y: 232.46817476976156,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 274.53099138761337,
            y: 235.74413865718734,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 272.25705765342957,
            y: 238.9616804700172,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.8996777138309,
            y: 242.11859506581243,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 267.46046719927415,
            y: 245.21271885300055,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.9410978228047,
            y: 248.24193127369267,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 262.3432962343478,
            y: 251.20415625700815,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 259.6688428373493,
            y: 254.09736364191,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.9195705685754,
            y: 256.9195705685754,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 254.09736364190996,
            y: 259.6688428373493,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 251.2041562570081,
            y: 262.34329623434786,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 248.2419312736926,
            y: 264.9410978228047,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 245.2127188530005,
            y: 267.46046719927415,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 242.1185950658124,
            y: 269.8996777138309,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.96168047001717,
            y: 272.2570576534296,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 235.74413865718728,
            y: 274.53099138761337,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 232.4681747697615,
            y: 276.71992047578635,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 229.13603398975022,
            y: 278.82234473529087,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 225.74999999999997,
            y: 280.836823269558,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 222.312393419071,
            y: 282.7619754556258,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.82557021080177,
            y: 284.59648189034937,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 215.29192006964837,
            y: 286.339085294654,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.71386478290793,
            y: 287.9885913752114,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 208.093856570946,
            y: 289.54386964294866,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 204.4343764065677,
            y: 291.00385418782884,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.73793231468255,
            y: 292.36754440937284,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 197.00705765342963,
            y: 293.6340057024206,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 193.2443093779404,
            y: 294.8023700976631,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 189.45226628792943,
            y: 295.8718368565048,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.63352726031383,
            y: 296.8416730198503,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.79070946807283,
            y: 297.7112139104377,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.9264465865683,
            y: 298.4798635883751,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 174.04338698855483,
            y: 299.14709525956823,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 170.1441919291179,
            y: 299.71245163675843,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 166.23153372178197,
            y: 300.17554525292513,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 162.3080939070408,
            y: 300.53605872683573,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 158.37656141456318,
            y: 300.79374498056336,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 154.43963072033506,
            y: 300.9484274088214,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.50000000000017,
            y: 301,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.56036927966528,
            y: 300.9484274088214,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.62343858543716,
            y: 300.79374498056336,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.69190609295953,
            y: 300.5360587268358,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.76846627821837,
            y: 300.1755452529252,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.85580807088246,
            y: 299.7124516367585,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.95661301144548,
            y: 299.1470952595683,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 123.07355341343207,
            y: 298.47986358837517,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 119.20929053192748,
            y: 297.7112139104378,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 115.36647273968649,
            y: 296.8416730198504,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.5477337120709,
            y: 295.87183685650484,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.75569062205994,
            y: 294.80237009766313,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.99294234657071,
            y: 293.63400570242067,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 100.26206768531779,
            y: 292.3675444093729,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.5656235934326,
            y: 291.00385418782895,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.90614342905431,
            y: 289.5438696429488,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 89.2861352170924,
            y: 287.98859137521157,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.70807993035191,
            y: 286.3390852946542,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 82.17442978919851,
            y: 284.59648189034954,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.68760658092921,
            y: 282.76197545562593,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.25000000000026,
            y: 280.8368232695582,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.86396601024994,
            y: 278.822344735291,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.53182523023864,
            y: 276.7199204757864,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.25586134281286,
            y: 274.5309913876135,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 62.03831952998296,
            y: 272.25705765342974,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.88140493418769,
            y: 269.89967771383095,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.78728114699959,
            y: 267.4604671992742,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.75806872630748,
            y: 264.94109782280475,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.795843742991934,
            y: 262.3432962343479,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.9026363580901,
            y: 259.66884283734936,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 44.080429431424655,
            y: 256.91957056857547,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.33115716265075,
            y: 254.09736364191002,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.656703765652196,
            y: 251.20415625700815,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 36.05890217719534,
            y: 248.24193127369267,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.53953280072588,
            y: 245.2127188530005,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 31.100322286169092,
            y: 242.1185950658124,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.742942346570374,
            y: 238.96168047001717,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.469008612386602,
            y: 235.74413865718728,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.28007952421363,
            y: 232.4681747697615,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.17765526470906,
            y: 229.13603398975022,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.163176730441904,
            y: 225.74999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.238024544374127,
            y: 222.31239341907093,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.403518109650545,
            y: 218.8255702108016,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.660914705345892,
            y: 215.2919200696482,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 13.01140862478847,
            y: 211.7138647829077,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.456130357051245,
            y: 208.09385657094577,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.996145812171036,
            y: 204.43437640656745,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.632455590627046,
            y: 200.73793231468224,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.365994297579293,
            y: 197.00705765342929,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.197629902336845,
            y: 193.24430937794,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.128163143495136,
            y: 189.452266287929,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.158326980149578,
            y: 185.63352726031337,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2887860895621603,
            y: 181.7907094680724,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.520136411624763,
            y: 177.92644658656778,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8529047404316987,
            y: 174.0433869885543,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2875483632414704,
            y: 170.14419192911728,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.824454747074803,
            y: 166.23153372178137,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.4639412731641913,
            y: 162.30809390704013,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.20625501943660468,
            y: 158.3765614145625,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.05157259117861568,
            y: 154.43963072033432,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 150.4999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.051572591178649096,
            y: 146.56036927966449,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20625501943667152,
            y: 142.62343858543633,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.46394127316429157,
            y: 138.69190609295867,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8244547470749367,
            y: 134.7684662782175,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.287548363241621,
            y: 130.85580807088155,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.8529047404318826,
            y: 126.95661301144453,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.520136411624964,
            y: 123.07355341343107,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2887860895624104,
            y: 119.20929053192646,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.1583269801498615,
            y: 115.36647273968548,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.128163143495436,
            y: 111.54773371206986,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.197629902337179,
            y: 107.75569062205885,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.365994297579644,
            y: 103.9929423465696,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.632455590627448,
            y: 100.26206768531664,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.996145812171454,
            y: 96.56562359343147,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.456130357051697,
            y: 92.90614342905313,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 13.011408624788954,
            y: 89.28613521709121,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.660914705346391,
            y: 85.70807993035072,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.40351810965108,
            y: 82.17442978919733,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.238024544374692,
            y: 78.68760658092803,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.16317673044249,
            y: 75.2499999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.17765526470966,
            y: 71.8639660102488,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.280079524214262,
            y: 68.53182523023752,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.469008612387253,
            y: 65.25586134281176,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.74294234657106,
            y: 62.03831952998189,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 31.100322286169792,
            y: 58.88140493418664,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.53953280072661,
            y: 55.787281146998566,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 36.05890217719611,
            y: 52.758068726306455,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.65670376565297,
            y: 49.795843742990954,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.33115716265154,
            y: 46.90263635808913,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 44.08042943142547,
            y: 44.08042943142372,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.90263635809094,
            y: 41.33115716264984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.7958437429928,
            y: 38.6567037656513,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.75806872630831,
            y: 36.058902177194526,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.78728114700044,
            y: 33.53953280072508,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.88140493418856,
            y: 31.10032228616832,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 62.03831952998386,
            y: 28.742942346569638,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.25586134281376,
            y: 26.469008612385885,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.53182523023956,
            y: 24.280079524212944,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.86396601025088,
            y: 22.17765526470839,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.25000000000121,
            y: 20.16317673044129,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.68760658093017,
            y: 18.238024544373523,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 82.17442978919948,
            y: 16.40351810964998,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.70807993035291,
            y: 14.66091470534534,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 89.28613521709343,
            y: 13.01140862478795,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.90614342905538,
            y: 11.456130357050762,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.56562359343374,
            y: 9.996145812170585,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 100.26206768531894,
            y: 8.632455590626627,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.99294234657191,
            y: 7.365994297578892,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.75569062206118,
            y: 6.1976299023364945,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.54773371207219,
            y: 5.128163143494801,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 115.36647273968782,
            y: 4.158326980149294,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 119.20929053192884,
            y: 3.2887860895619094,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 123.07355341343347,
            y: 2.5201364116245295,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.95661301144695,
            y: 1.8529047404314982,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.85580807088397,
            y: 1.2875483632413036,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.7684662782199,
            y: 0.824454747074686,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.6919060929611,
            y: 0.46394127316410777,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.62343858543875,
            y: 0.20625501943653785,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.5603692796669,
            y: 0.05157259117858226,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.50000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 154.43963072033677,
            y: 0.051572591178682514,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 158.37656141456495,
            y: 0.20625501943673835,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 162.30809390704258,
            y: 0.4639412731643918,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 166.23153372178376,
            y: 0.8244547470750704,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 170.1441919291197,
            y: 1.2875483632417881,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 174.04338698855668,
            y: 1.852904740432083,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.92644658657017,
            y: 2.520136411625198,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.79070946807477,
            y: 3.2887860895626777,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.63352726031576,
            y: 4.158326980150147,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 189.45226628793137,
            y: 5.128163143495754,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 193.2443093779424,
            y: 6.19762990233753,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 197.00705765343162,
            y: 7.365994297580045,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.7379323146845,
            y: 8.632455590627865,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 204.4343764065697,
            y: 9.996145812171905,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 208.09385657094805,
            y: 11.456130357052182,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.7138647829099,
            y: 13.011408624789455,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 215.29192006965044,
            y: 14.660914705346926,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.8255702108038,
            y: 16.40351810965165,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 222.31239341907306,
            y: 18.238024544375296,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.750000000002,
            y: 20.16317673044313,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 229.13603398975226,
            y: 22.177655264710328,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 232.46817476976352,
            y: 24.28007952421495,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 235.74413865718927,
            y: 26.469008612387974,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.96168047001913,
            y: 28.742942346571812,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 242.11859506581436,
            y: 31.100322286170563,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 245.2127188530024,
            y: 33.5395328007274,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 248.24193127369446,
            y: 36.058902177196934,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 251.20415625700994,
            y: 38.656703765653816,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 254.09736364191176,
            y: 41.33115716265242,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.91957056857717,
            y: 44.08042943142638,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 259.668842837351,
            y: 46.902636358091854,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 262.34329623434957,
            y: 49.79584374299374,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.94109782280634,
            y: 52.75806872630932,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 267.46046719927574,
            y: 55.78728114700147,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.89967771383243,
            y: 58.88140493418961,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 272.2570576534311,
            y: 62.038319529984925,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 274.53099138761485,
            y: 65.25586134281484,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 276.7199204757878,
            y: 68.53182523024067,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 278.82234473529223,
            y: 71.86396601025201,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 280.8368232695594,
            y: 75.25000000000237,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 282.76197545562707,
            y: 78.68760658093136,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 284.5964818903506,
            y: 82.17442978920069,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 286.33908529465526,
            y: 85.7080799303541,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.98859137521254,
            y: 89.28613521709465,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 289.54386964294974,
            y: 92.90614342905661,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 291.00385418782986,
            y: 96.56562359343498,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 292.3675444093738,
            y: 100.26206768532019,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 293.63400570242146,
            y: 103.99294234657317,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 294.80237009766387,
            y: 107.75569062206245,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 295.8718368565055,
            y: 111.54773371207347,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 296.841673019851,
            y: 115.36647273968913,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 297.71121391043835,
            y: 119.20929053193014,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 298.47986358837574,
            y: 123.07355341343477,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 299.1470952595687,
            y: 126.95661301144824,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 299.7124516367589,
            y: 130.85580807088527,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 300.1755452529254,
            y: 134.7684662782212,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 300.536058726836,
            y: 138.69190609296243,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 300.79374498056353,
            y: 142.62343858544008,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 300.94842740882143,
            y: 146.56036927966824,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D25',
          'D47',
          'D28',
          'D43',
          'D19',
          'D49',
          'D23',
          'D48',
          'C4',
          'C29',
          'D58',
          'C15',
          'D54',
          'D37',
          'D20',
          'D35',
          'D51',
          'D18',
          'D50',
          'D21',
          'D36',
          'D13',
          'D45',
          'D25',
          'D42',
          'D19',
          'D41',
          'D26',
          'D52',
          'C8',
          'C28',
          'D59',
          'C26',
          'C1',
          'C24',
          'C5',
          'C21',
          'D56',
          'D40',
          'D12',
          'D48',
          'D14',
          'D44',
          'D16',
          'D43',
          'D28',
          'D13',
          'D49',
          'D21',
          'D36',
          'D15',
          'D47',
          'D18',
          'D46',
          'D11',
          'D45',
          'C1',
          'C31',
          'D57',
          'C30',
          'C2',
          'C18',
          'D56',
          'C12',
          'C28',
          'C4',
          'C26',
          'C9',
          'D50',
          'D14',
          'D48',
          'D23',
          'D44',
          'D18',
          'D39',
          'D19',
          'D34',
          'D55',
          'C23',
          'C7',
          'C30',
          'D58',
          'C32',
          'D60',
          'C29',
          'C2',
          'C28',
          'C5',
          'D49',
          'D11',
          'D45',
          'D15',
          'D50',
          'D16',
          'D42',
          'D27',
          'D52',
          'D17',
          'D48',
          'D10',
          'D47',
          'D31',
          'D14',
          'D44',
          'D23',
          'D38',
          'D12',
          'D46',
          'D21',
          'D36',
          'D57',
          'C33',
          'D59',
          'C30',
          'C3',
          'C23',
          'C7',
          'D51',
          'D13',
          'D49',
          'D11',
          'D43',
          'D28',
          'D52',
          'D20',
          'D35',
          'D15',
          'D45',
          'D18',
          'D44',
          'D24',
          'D39',
          'D19',
          'D34',
          'D50',
          'D10',
          'D47',
          'D14',
          'D40',
          'D16',
          'D49',
          'D13',
          'D46',
          'C2',
          'C32',
          'D57',
          'C34',
          'D58',
          'C31',
          'D60',
          'C27',
          'C12',
          'D52',
          'D22',
          'D51',
          'D12',
          'D50',
          'D10',
          'D47',
          'D15',
          'D30',
          'D55',
          'C20',
          'C4',
          'C25',
          'C10',
          'C29',
          'C3',
          'C32',
          'D59',
          'C34',
          'D56',
          'C33',
          'D58',
          'D42',
          'D11',
          'D41',
          'D26',
          'D45',
          'D13',
          'D38',
          'D18',
          'B4',
          'A45',
          'A30',
          'B11',
          'A28',
          'B12',
          'A52',
          'B16',
          'A44',
          'B6',
          'A31',
          'A56',
          'A29',
          'B7',
          'A27',
          'B13',
          'A46',
          'B18',
          'A43',
          'B19',
          'A42',
          'B20',
          'A41',
          'B21',
          'A39',
          'A55',
          'B10',
          'A54',
          'B15',
          'A49',
          'B17',
          'A44',
          'A28',
          'B1',
          'A34',
          'B4',
          'A30',
          'B16',
          'A27',
          'B18',
          'A32',
          'B9',
          'A29',
          'B11',
          'A50',
          'A35',
          'B21',
          'A31',
          'B10',
          'A26',
          'B13',
          'A45',
          'B15',
          'A28',
          'B22',
          'A34',
          'B5',
          'A33',
          'B8',
          'A47',
          'B2',
          'A36',
          'B20',
          'A27',
          'B23',
          'A30',
          'B6',
          'A51',
          'A35',
          'B3',
          'B18',
          'A44',
          'B27',
          'A29',
          'B29',
          'A28',
          'B12',
          'A50',
          'A32',
          'B19',
          'A31',
          'B22',
          'A26',
          'B24',
          'A27',
          'B30',
          'A30',
          'B26',
          'A34',
          'B14',
          'A33',
          'B17',
          'A48',
          'B11',
          'A37',
          'A57',
          'A35',
          'B13',
          'A28',
          'B31',
          'A29',
          'B21',
          'A32',
          'B5',
          'A50',
          'B28',
          'A31',
          'B29',
          'A27',
          'B32',
          'A26',
          'B27',
          'A42',
          'B19',
          'A30',
          'B42',
          'A33',
          'B15',
          'A45',
          'B18',
          'A37',
          'B12',
          'A47',
          'B17',
          'A28',
          'B23',
          'A41',
          'B28',
          'A29',
          'B43',
          'A32',
          'B26',
          'A27',
          'B31',
          'A26',
          'B29',
          'A40',
          'B20',
          'A33',
          'B24',
          'A31',
          'A57',
          'B14',
          'A51',
          'B16',
          'A34',
          'B23',
          'A39',
          'A60',
          'A38',
          'B8',
          'A53',
          'B10',
          'A29',
          'B42',
          'A28',
          'B28',
          'A27',
          'B33',
          'A26',
          'B30',
          'A31',
          'B25',
          'A30',
          'B27',
          'A32',
          'B15',
          'A43',
          'B18',
          'A33',
          'B23',
          'A37',
          'B19',
          'A42',
          'B22',
          'A51',
          'B9',
          'A46',
          'B11',
          'A34',
          'B43',
          'A28',
          'B30',
          'B45',
          'A29',
          'B41',
          'A31',
          'B44',
          'A30',
          'B28',
          'A32',
          'B20',
          'A36',
          'B8',
          'A35',
          'B25',
          'A33',
          'B54',
          'A37',
          'B24',
          'A51',
          'B17',
          'A46',
          'B26',
          'A28',
          'B32',
          'A26',
          'B34',
          'B49',
          'A30',
          'B31',
          'A27',
          'B42',
          'A31',
          'B52',
          'A34',
          'B27',
          'A33',
          'B55',
          'A36',
          'B24',
          'A47',
          'B14',
          'A37',
          'A60',
          'B17',
          'A44',
          'B18',
          'A43',
          'B46',
          'A29',
          'B30',
          'A39',
          'B22',
          'A38',
          'B44',
          'A28',
          'B36',
          'A30',
          'B20',
          'A40',
          'A55',
          'D19',
          'D34',
          'D13',
          'D49',
          'D9',
          'D48',
          'D17',
          'D42',
          'D27',
          'D50',
          'D12',
          'D41',
          'D26',
          'D54',
          'C11',
          'C26',
          'C2',
          'C31',
          'C6',
          'C27',
          'C8',
          'C30',
          'D60',
          'C33',
          'C1',
          'C29',
          'C14',
          'D51',
          'D9',
          'D48',
          'D13',
          'D45',
          'D15',
          'D44',
          'D18',
          'D38',
          'D19',
          'C32',
          'D56',
          'C35',
          'D58',
          'C37',
          'D59',
          'C31',
          'C4',
          'C28',
          'D16',
          'D40',
          'D17',
          'C30',
          'C9',
          'C24',
          'C5',
          'C21',
          'D60',
          'D44',
          'D15',
          'C29',
          'D18',
          'D33',
          'D57',
          'C35',
          'D58',
          'C32',
          'D20',
          'D35',
          'D55',
          'D39',
          'D24',
          'D48',
          'D9',
          'D46',
          'D10',
          'D47',
          'D7',
          'D45',
          'D13',
          'C25',
          'C2',
          'C18',
          'D53',
          'D11',
          'D52',
          'D8',
          'D49',
          'D14',
          'D43',
          'D15',
          'C27',
          'D58',
          'C38',
          'D59',
          'C34',
          'D22',
          'B9',
          'A50',
          'B13',
          'A45',
          'B45',
          'A28',
          'B27',
          'B42',
          'A35',
          'B56',
          'A33',
          'B43',
          'A27',
          'B36',
          'A32',
          'B23',
          'A38',
          'B58',
          'A36',
          'B29',
          'A30',
          'B52',
          'A29',
          'B25',
          'A43',
          'B21',
          'A40',
          'B6',
          'D19',
          'D34',
          'B38',
          'A31',
          'B43',
          'A39',
          'B27',
          'A37',
          'B31',
          'A24',
          'B32',
          'B47',
          'A9',
          'B48',
          'A32',
          'B29',
          'A25',
          'B30',
          'A28',
          'B46',
          'A27',
          'B44',
          'A35',
          'B3',
          'A47',
          'B5',
          'D18',
          'C31',
          'D19',
          'D34',
          'D58',
          'C29',
          'D60',
          'C26',
          'D12',
          'D50',
          'D8',
          'D49',
          'D16',
          'D40',
          'D17',
          'A56',
          'B21',
          'A35',
          'B9',
          'D20',
          'B11',
          'D22',
          'B3',
          'D19',
          'C30',
          'C15',
          'D59',
          'C32',
          'C1',
          'C31',
          'C7',
          'C26',
          'D15',
          'D43',
          'D28',
          'B40',
          'A36',
          'B41',
          'A34',
          'B53',
          'A31',
          'B54',
          'A30',
          'B43',
          'B28',
          'A43',
          'B18',
          'A41',
          'B19',
          'A60',
          'D20',
          'C31',
          'D16',
          'A52',
          'B10',
          'D19',
          'B12',
          'A48',
          'B16',
          'A45',
          'B17',
          'A34',
          'B24',
          'A32',
          'B41',
          'A42',
          'B39',
          'A39',
          'B57',
          'A33',
          'B28',
          'A37',
          'B59',
          'A38',
          'B26',
          'A31',
          'B45',
          'A30',
          'B33',
          'A24',
          'B34',
          'A29',
          'B49',
          'A17',
          'B50',
          'A18',
          'B51',
          'A32',
          'B44',
          'A34',
          'B12',
          'D21',
          'D45',
          'D10',
          'D46',
          'D12',
          'C24',
          'C9',
          'D48',
          'D9',
          'D51',
          'D11',
          'C22',
          'D13',
          'D41',
          'D26',
          'C25',
          'D14',
          'C28',
          'C3',
          'C31',
          'D17',
          'D40',
          'D25',
          'A60',
          'D23',
          'D44',
          'D24',
          'B4',
          'B41',
          'A54',
          'D20',
          'C33',
          'D55',
          'C36',
          'D57',
          'D37',
          'D22',
          'B1',
          'D19',
          'B13',
          'A44',
          'B20',
          'A39',
          'A55',
          'D18',
          'B7',
          'D21',
          'C32',
          'D17',
          'C28',
          'C5',
          'C27',
          'D28',
          'D43',
          'D15',
          'A49',
          'B40',
          'A35',
          'B22',
          'A40',
          'B25',
          'A33',
          'B35',
          'D29',
          'B36',
          'A26',
          'B28',
          'A13',
          'B30',
          'A23',
          'B31',
          'A36',
          'B43',
          'A7',
          'B45',
          'A27',
          'B47',
          'A8',
          'B48',
          'A10',
          'B46',
          'A9',
          'B49',
          'A18',
          'B52',
          'A17',
          'B51',
          'A30',
          'B41',
          'A28',
          'B25',
          'A50',
          'B10',
          'A35',
          'B32',
          'A33',
          'B40',
          'A44',
          'B24',
          'A56',
          'D20',
          'C20',
          'D21',
          'D54',
          'C34',
          'D60',
          'C42',
          'C1',
          'C29',
          'D30',
          'B37',
          'A5',
          'B38',
          'D32',
          'C32',
          'D18',
          'C30',
          'D29',
          'B41',
          'B1',
          'D16',
          'A56',
          'D22',
          'D50',
          'D12',
          'D46',
          'D5',
          'D45',
          'D13',
          'C24',
          'C9',
          'D53',
          'D7',
          'D51',
          'C13',
          'D11',
          'C21',
          'D19',
          'A58',
          'B20',
          'A11',
          'B49',
          'A8',
          'B39',
          'A37',
          'B29',
          'A43',
          'B38',
          'A4',
          'B27',
          'A27',
          'B41',
          'A41',
          'B19',
          'D22',
          'D46',
          'D14',
          'C24',
          'D24',
          'D44',
          'D10',
          'C23',
          'D12',
          'C13',
          'D13',
          'D45',
          'D21',
          'C33',
          'D57',
          'C39',
          'D58',
          'D42',
          'D15',
          'A47',
          'B15',
          'A57',
          'D17',
          'B4',
          'D19',
          'A52',
          'B7',
          'A40',
          'B56',
          'A32',
          'B57',
          'A37',
          'B19',
          'C58',
          'B20',
          'B40',
          'A15',
          'B41',
          'A33',
          'B44',
          'A29',
          'B35',
          'A28',
          'B47',
          'A7',
          'B46',
          'A16',
          'B48',
          'A19',
          'B55',
          'A30',
          'B24',
          'A41',
          'B29',
          'A34',
          'B34',
          'A39',
          'B25',
          'A47',
          'B17',
          'A45',
          'B26',
          'A35',
          'B58',
          'A20',
          'B56',
          'A38',
          'B9',
          'D18',
          'C28',
          'C13',
          'D52',
          'D8',
          'D49',
          'D12',
          'C21',
          'D22',
          'C33',
          'D60',
          'C32',
          'C5',
          'C25',
          'D11',
          'D51',
          'D20',
          'C34',
          'D55',
          'D24',
          'D39',
          'B39',
          'A50',
          'C22',
          'C6',
          'D46',
          'D6',
          'D52',
          'B8',
          'D23',
          'D38',
          'B40',
          'B5',
          'D17',
          'A51',
          'D18',
          'B13',
          'A49',
          'C21',
          'A52',
          'B7',
          'D50',
          'C16',
          'D16',
          'C29',
          'C3',
          'C26',
          'D25',
          'C24',
          'D26',
          'A59',
          'D24',
          'D44',
          'D2',
          'A43',
          'B40',
          'D27',
          'D42',
          'D15',
          'A53',
          'D21',
          'C31',
          'D22',
          'C20',
          'D11',
          'C27',
          'D23',
          'B2',
          'D20',
          'B12',
          'B39',
          'A7',
          'B49',
          'A10',
          'B50',
          'A9',
          'B44',
          'A26',
          'B42',
          'A32',
          'B32',
          'A14',
          'B24',
          'A1',
          'B25',
          'C60',
          'B26',
          'A43',
          'B18',
          'A11',
          'B21',
          'A53',
          'C21',
          'A50',
          'D18',
          'C33',
          'D58',
          'C36',
          'D59',
          'C41',
          'C1',
          'C20',
          'D23',
          'C35',
          'D53',
          'B19',
          'A34',
          'B54',
          'A18',
          'B47',
          'A33',
          'B45',
          'A26',
          'B37',
          'A32',
          'B58',
          'A31',
          'B48',
          'A12',
          'B30',
          'A37',
          'B35',
          'A27',
          'B21',
          'D52',
          'D8',
          'D47',
          'D11',
          'C26',
          'D23',
          'B10',
          'D21',
          'A57',
          'D46',
          'D22',
          'C30',
          'D31',
          'B42',
          'A41',
          'A58',
          'B23',
          'A2',
          'B44',
          'A8',
          'B50',
          'A7',
          'B48',
          'A27',
          'D27',
          'D42',
          'B39',
          'A34',
          'B31',
          'A21',
          'B32',
          'A31',
          'B46',
          'A5',
          'B38',
          'A56',
          'D24',
          'D44',
          'D25',
          'C23',
          'A51',
          'C22',
          'D10',
          'C29',
          'D31',
          'D59',
          'C30',
          'C1',
          'C17',
          'D15',
          'C15',
          'D16',
          'B2',
          'B17',
          'A44',
          'B37',
          'A6',
          'B45',
          'A32',
          'B53',
          'A19',
          'B56',
          'A30',
          'B46',
          'A41',
          'B26',
          'B41',
          'D46',
          'A59',
          'D48',
          'A60',
          'D21',
          'C34',
          'D23',
          'C25',
          'D12',
          'C20',
          'A52',
          'B23',
          'A48',
          'C23',
          'D26',
          'B1',
          'D24',
          'D39',
          'D55',
          'C37',
          'D56',
          'B13',
          'D17',
          'B14',
          'A49',
          'D16',
          'C27',
          'D10',
          'C26',
          'C8',
          'D52',
          'B9',
          'D50',
          'B20',
          'C57',
          'B19',
          'A28',
          'D28',
          'D43',
          'D2',
          'A21',
          'B57',
          'A19',
          'B49',
          'A12',
          'B54',
          'A11',
          'B22',
          'A34',
          'B42',
          'A15',
          'B43',
          'D33',
          'C32',
          'D21',
          'B8',
          'B40',
          'A42',
          'B4',
          'D22',
          'D56',
          'C31',
          'D28',
          'D43',
          'B38',
          'A3',
          'B45',
          'A37',
          'B20',
          'D53',
          'B5',
          'D51',
          'C19',
          'C3',
          'B25',
          'A14',
          'B26',
          'A22',
          'B28',
          'A39',
          'B7',
          'D17',
          'D48',
          'D20',
          'A24',
          'D18',
          'B11',
          'A53',
          'C20',
          'D13',
          'C16',
          'D7',
          'D53',
          'B6',
          'D31',
          'B36',
          'A36',
          'B30',
          'A22',
          'D14',
          'C30',
          'D30',
          'A29',
          'B53',
          'A18',
          'B55',
          'A34',
          'B13',
          'D20',
          'C19',
          'A48',
          'C22',
          'A46',
          'B14',
          'D19',
          'C33',
          'D31',
          'D58',
          'B15',
          'D18',
          'A53',
          'C25',
          'C10',
          'D7',
          'D50',
          'B21',
          'C57',
          'B22',
          'D23',
          'A55',
          'D45',
          'A60',
          'B42',
          'A25',
          'D22',
          'D46',
          'D12',
          'C12',
          'D52',
          'B11',
          'D21',
          'B1',
          'A38',
          'B18',
          'A10',
          'B51',
          'A19',
          'B58',
          'A13',
          'B29',
          'A33',
          'B50',
          'B31',
          'D27',
          'A58',
          'D49',
          'B9',
          'D24',
          'C36',
          'D60',
          'C43',
          'D59',
          'B21',
          'A36',
          'B42',
          'D46',
          'D22',
          'C28',
          'C1',
          'C27',
          'A55',
          'C25',
          'D11',
          'D51',
          'C16',
          'A49',
          'C19',
          'A52',
          'C26',
          'D28',
          'D43',
          'B13',
          'B38',
          'D40',
          'B39',
          'D37',
          'B40',
          'A38',
          'B10',
          'D56',
          'D25',
          'A26',
          'B46',
          'A4',
          'B37',
          'A28',
          'B50',
          'A31',
          'B34',
          'A30',
          'B47',
          'A17',
          'B53',
          'A11',
          'B48',
          'D36',
          'A32',
          'B30',
          'A43',
          'C24',
          'A47',
          'B28',
          'A38',
          'B16',
          'D49',
          'A59',
          'D23',
          'C33',
          'C2',
          'C40',
          'D56',
          'D32',
          'A24',
          'D21',
          'C19',
          'D8',
          'C18',
          'A50',
          'B10',
          'B39',
          'A6',
          'B47',
          'A35',
          'B43',
          'C58',
          'B18',
          'C56',
          'B19',
          'D51',
          'B8',
          'D19',
          'A25',
          'D23',
          'B3',
          'D20',
          'C35',
          'D59',
          'B22',
          'D22',
          'B6',
          'C50',
          'B5',
          'D32',
          'C31',
          'D29',
          'A28',
          'B38',
          'D26',
          'C38',
          'D54',
          'B4',
          'D52',
          'B18',
          'A42',
          'C23',
          'A55',
          'D44',
          'A56',
          'D47',
          'B40',
          'B2',
          'D25',
          'A58',
          'A40',
          'B26',
          'C24',
          'C2',
          'C46',
          'D12',
          'D50',
          'C21',
          'A46',
          'C20',
          'C4',
          'B24',
          'A2',
          'B45',
          'A8',
          'B38',
          'B22',
          'A33',
          'D37',
          'A25',
          'D34',
          'B48',
          'A28',
          'D27',
          'C32',
          'D16',
          'A48',
          'C17',
          'D6',
          'D54',
          'B11',
          'D17',
          'B16',
          'D48',
          'D9',
          'C28',
          'A54',
          'C21',
          'D10',
          'C14',
          'D11',
          'D47',
          'A60',
          'C48',
          'B14',
          'D59',
          'B25',
          'B44',
          'C59',
          'B24',
          'C16',
          'A50',
          'D15',
          'A21',
          'B55',
          'A31',
          'B40',
          'A16',
          'B47',
          'A29',
          'B54',
          'D39',
          'D24',
          'C27',
          'D29',
          'B1',
          'D46',
          'B43',
          'A41',
          'C21',
          'B26',
          'C3',
          'C45',
          'D11',
          'C12',
          'D13',
          'D45',
          'A28',
          'D34',
          'A24',
          'B42',
          'A56',
          'C25',
          'A42',
          'D54',
          'C35',
          'D21',
          'B23',
          'A13',
          'B31',
          'A12',
          'B57',
          'A11',
          'B45',
          'A36',
          'B33',
          'A32',
          'B46',
          'A8',
          'B51',
          'A7',
          'B44',
          'D34',
          'A26',
          'D40',
          'A51',
          'B38',
          'D25',
          'B6',
          'C49',
          'B1',
          'B22',
          'D52',
          'C16',
          'D8',
          'C29',
          'B58',
          'D41',
          'B37',
          'C60',
          'B27',
          'A14',
          'B33',
          'A43',
          'C23',
          'D9',
          'C18',
          'A47',
          'C20',
          'B38',
          'A27',
          'D26',
          'A57',
          'B17',
          'A44',
          'B21',
          'D24',
          'C25',
          'B40',
          'D36',
          'A24',
          'D13',
          'D45',
          'B43',
          'C57',
          'B35',
          'C11',
          'D55',
          'C32',
          'D23',
          'D46',
          'B7',
          'D53',
          'A40',
          'B18',
          'A9',
          'B28',
          'A35',
          'D30',
          'B8',
          'C50',
          'B9',
          'D21',
          'A26',
          'B41',
          'A37',
          'D32',
          'C29',
          'B59',
          'C31',
          'D57',
          'B21',
          'D49',
          'B40',
          'A14',
          'B60',
          'D41',
          'A36',
          'B4',
          'C51',
          'B5',
          'D54',
          'C33',
          'D20',
          'D48',
          'B41',
          'D47',
          'D4',
          'C14',
          'B37',
          'A57',
          'D44',
          'D25',
          'A28',
          'B51',
          'C2',
          'C47',
          'B14',
          'A45',
          'C23',
          'C3',
          'B54',
          'A14',
          'B42',
          'C55',
          'B25',
          'C7',
          'B26',
          'A38',
          'B29',
          'C26',
          'B27',
          'A51',
          'C18',
          'A52',
          'B12',
          'D40',
          'D25',
          'C37',
          'D53',
          'D7',
          'C22',
          'B39',
          'A5',
          'B48',
          'A4',
          'B28',
          'A2',
          'B46',
          'A36',
          'B59',
          'C30',
          'D60',
          'C44',
          'D11',
          'C43',
          'D12',
          'C18',
          'A54',
          'D46',
          'A30',
          'D32',
          'B43',
          'A38',
          'B18',
          'C50',
          'B15',
          'D51',
          'B20',
          'D37',
          'A19',
          'B52',
          'A12',
          'B17',
          'C47',
          'D13',
          'C12',
          'B36',
          'A34',
          'D2',
          'A36',
          'D43',
          'D28',
          'C40',
          'D60',
          'C25',
          'A49',
          'B16',
          'D19',
          'C34',
          'D51',
          'B6',
          'B39',
          'A46',
          'B11',
          'D41',
          'A28',
          'D38',
          'B38',
          'A40',
          'B27',
          'C23',
          'A47',
          'C14',
          'D12',
          'A15',
          'B59',
          'A35',
          'B51',
          'A20',
          'D13',
          'D44',
          'B1',
          'D18',
          'C17',
          'A54',
          'D22',
          'A59',
          'C44',
          'D10',
          'C28',
          'C6',
          'C22',
          'A49',
          'D38',
          'A24',
          'D26',
          'B31',
          'A22',
          'D30',
          'B16',
          'D60',
          'B49',
          'D36',
          'A33',
          'D50',
          'B8',
          'D35',
          'A19',
          'C14',
          'B23',
          'A42',
          'C26',
          'A44',
          'C19',
          'A53',
          'C15',
          'B24',
          'A21',
          'D3',
          'A38',
          'C20',
          'D7',
          'A55',
          'D21',
          'C29',
          'C4',
          'D43',
          'D28',
          'B30',
          'D56',
          'B14',
          'C46',
          'B17',
          'D18',
          'B3',
          'D53',
          'A42',
          'D5',
          'C14',
          'A20',
          'B32',
          'A37',
          'B44',
          'C58',
          'B34',
          'C9',
          'B35',
          'C13',
          'B37',
          'D44',
          'B43',
          'A26',
          'D33',
          'C31',
          'B60',
          'C30',
          'D20',
          'D57',
          'D23',
          'C19',
          'A50',
          'D19',
          'A23',
          'D27',
          'C41',
          'D8',
          'D52',
          'D9',
          'D48',
          'A31',
          'B15',
          'A45',
          'B2',
          'D21',
          'B14',
          'B37',
          'C59',
          'B18',
          'D17',
          'A24',
          'B29',
          'A29',
          'B55',
          'A12',
          'B19',
          'A10',
          'B56',
          'A18',
          'D19',
          'C51',
          'B7',
          'C49',
          'C2',
          'B26',
          'C20',
          'A49',
          'B41',
          'D28',
          'C32',
          'D9',
          'C29',
          'B30',
          'A38',
          'D39',
          'A20',
          'B52',
          'A33',
          'D29',
          'A17',
          'D20',
          'B15',
          'C48',
          'D14',
          'C18',
          'B32',
          'A13',
          'B59',
          'A11',
          'B47',
          'A34',
          'B45',
          'A44',
          'B24',
          'C53',
          'B6',
          'A47',
          'D37',
          'D57',
          'C13',
          'A20',
          'D40',
          'B22',
          'A31',
          'D34',
          'A23',
          'D13',
          'C11',
          'B36',
          'A29',
          'D27',
          'C39',
          'C3',
          'B55',
          'A39',
          'C20',
          'A42',
          'B15',
          'D50',
          'B39',
          'D48',
          'D21',
          'C22',
          'B25',
          'A25',
          'B44',
          'C57',
          'B45',
          'A17',
          'D16',
          'C48',
          'C1',
          'B51',
          'A31',
          'B18',
          'A45',
          'C25',
          'D54',
          'D24',
          'A58',
          'D47',
          'D11',
          'C19',
          'B38',
          'A38',
          'D56',
          'C36',
          'D52',
          'B26',
          'D49',
          'A30',
          'B40',
          'C24',
          'D27',
          'C60',
          'B46',
          'D59',
          'D32',
          'A36',
          'D5',
          'C21',
          'B33',
          'C18',
          'A55',
          'B10',
          'A35',
          'B11',
          'A29',
          'B22',
          'A1',
          'B28',
          'A44',
          'D55',
          'B30',
          'C28',
          'D27',
          'A21',
          'D41',
          'B41',
          'C29',
          'B60',
          'A12',
          'B32',
          'C17',
          'A55',
          'C16',
          'D53',
          'D37',
          'A29',
          'B56',
          'A15',
          'D14',
          'C46',
          'C1',
          'B26',
          'C19',
          'A46',
          'B12',
          'C42',
          'D12',
          'A25',
          'B27',
          'A19',
          'C11',
          'B57',
          'A10',
          'B52',
          'A28',
          'B40',
          'D26',
          'A22',
          'D2',
          'A32',
          'B39',
          'A55',
          'B26',
          'C6',
          'B33',
          'A35',
          'B14',
          'A59',
          'C43',
          'D30',
          'C28',
          'A44',
          'D1',
          'C31',
          'D12',
          'D47',
          'C17',
          'D19',
          'A60',
          'C47',
          'B18',
          'D54',
          'D6',
          'C21',
          'A51',
          'C27',
          'B25',
          'C25',
          'B27',
          'A3',
          'B43',
          'A1',
          'B36',
          'A6',
          'B49',
          'D35',
          'B37',
          'D29',
          'C42',
          'D9',
          'C16',
          'D55',
          'B10',
          'D18',
          'A23',
          'D14',
          'C11',
          'A20',
          'B24',
          'C52',
          'B6',
          'C48',
          'B8',
          'D57',
          'B12',
          'D52',
          'D10',
          'C20',
          'A43',
          'B22',
          'C56',
          'D23',
          'D46',
          'B15',
          'D41',
          'A50',
          'C15',
          'A55',
          'D42',
          'B40',
          'A27',
          'D43',
          'D28',
          'B3',
          'D15',
          'C49',
          'B13',
          'C44',
          'C4',
          'B53',
          'A30',
          'B21',
          'B39',
          'A16',
          'B49',
          'A34',
          'D55',
          'D5',
          'A24',
          'D35',
          'B19',
          'D16',
          'C14',
          'A55',
          'B43',
          'A14',
          'B58',
          'C32',
          'D49',
          'C17',
          'B38',
          'A35',
          'B18',
          'A26',
          'D13',
          'C27',
          'A41',
          'C24',
          'B53',
          'C23',
          'D27',
          'B30',
          'C10',
          'D54',
          'A43',
          'B37',
          'C12',
          'A18',
          'D32',
          'A22',
          'D43',
          'D28',
          'B8',
          'D53',
          'D7',
          'C39',
          'D25',
          'A24',
          'D16',
          'D45',
          'A54',
          'C26',
          'B34',
          'B49',
          'A27',
          'B23',
          'C54',
          'B42',
          'D47',
          'B26',
          'C15',
          'B36',
          'C60',
          'B47',
          'D35',
          'C36',
          'B3',
          'A39',
          'B7',
          'A47',
          'C22',
          'B27',
          'D51',
          'D11',
          'C41',
          'D57',
          'C30',
          'D23',
          'D46',
          'D12',
          'C13',
          'A17',
          'B14',
          'D52',
          'A40',
          'D6',
          'A56',
          'C24',
          'A44',
          'D3',
          'C15',
          'D10',
          'C43',
          'B6',
          'D54',
          'B49',
          'D34',
          'A19',
          'B29',
          'C25',
          'D15',
          'A25',
          'B46',
          'A35',
          'B21',
          'A2',
          'D28',
          'A21',
          'D22',
          'C35',
          'D50',
          'B48',
          'A30',
          'B26',
          'C27',
          'A43',
          'D4',
          'C8',
          'D5',
          'A23',
          'D42',
          'B1',
          'D41',
          'A29',
          'D47',
          'D17',
          'D40',
          'B41',
          'C56',
          'B45',
          'A42',
          'B25',
          'C59',
          'D26',
          'B13',
          'A5',
          'B49',
          'D49',
          'D8',
          'C24',
          'A45',
          'B6',
          'A36',
          'D42',
          'B59',
          'C32',
          'D51',
          'B22',
          'C50',
          'B23',
          'A1',
          'B44',
          'A46',
          'C15',
          'D59',
          'B47',
          'A32',
          'D48',
          'B40',
          'A13',
          'B51',
          'B30',
          'C19',
          'B31',
          'A15',
          'D2',
          'C31',
          'D52',
          'C21',
          'B34',
          'A22',
          'B59',
          'C28',
          'B41',
          'D39',
          'D24',
          'A26',
          'D14',
          'C45',
          'A58',
          'B36',
          'C9',
          'A19',
          'D36',
          'B6',
          'A30',
          'B58',
          'A44',
          'C17',
          'B33',
          'A31',
          'C18',
          'A53',
          'C13',
          'B18',
          'A33',
          'D56',
          'A42',
          'D7',
          'C31',
          'D13',
          'A16',
          'D17',
          'C50',
          'C3',
          'C48',
          'B15',
          'C44',
          'D29',
          'A3',
          'B38',
          'D49',
          'B27',
          'C27',
          'D30',
          'C42',
          'A59',
          'D27',
          'B32',
          'C10',
          'B57',
          'A20',
          'B26',
          'A29',
          'B16',
          'A45',
          'B23',
          'C60',
          'B28',
          'A25',
          'D31',
          'A37',
          'C21',
          'D8',
          'D49',
          'D14',
          'C16',
          'B38',
          'D24',
          'A19',
          'C15',
          'B22',
          'A57',
          'D43',
          'A21',
          'D16',
          'D42',
          'A58',
          'C49',
          'D17',
          'D40',
          'B15',
          'A35',
          'B23',
          'A26',
          'D22',
          'B7',
          'D32',
          'C33',
          'D48',
          'A56',
          'D45',
          'B36',
          'C57',
          'D24',
          'B26',
          'C5',
          'B33',
          'A23',
          'D36',
          'B12',
          'C45',
          'B16',
          'D28',
          'A17',
          'C10',
          'A21',
          'D23',
          'B38',
          'C15',
          'D53',
          'B10',
          'C41',
          'D12',
          'C48',
          'A58',
          'C44',
          'C1',
          'B46',
          'C59',
          'D19',
          'B9',
          'C40',
          'D57',
          'C13',
          'B23',
          'C23',
          'D49',
          'A34',
          'D4',
          'A50',
          'C28',
          'B24',
          'C26',
          'B35',
          'A4',
          'B36',
          'D42',
          'B10',
          'A48',
          'C14',
          'B38',
          'C12',
          'B56',
          'A12',
          'B21',
          'A10',
          'B53',
          'A28',
          'B19',
          'D15',
          'A22',
          'B23',
          'C49',
          'B39',
          'C48',
          'D17',
          'C33',
          'D22',
          'A15',
          'B34',
          'C54',
          'D21',
          'C53',
          'B10',
          'A38',
          'D54',
          'D6',
          'A43',
          'D53',
          'B11',
          'A8',
          'B14',
          'A46',
          'D14',
          'C10',
          'D8',
          'A60',
          'D43',
          'D28',
          'C23',
          'B24',
          'C20',
          'A51',
          'C39',
          'B9',
          'D57',
          'B29',
          'C11',
          'A16',
          'B50',
          'B32',
          'C19',
          'D52',
          'B19',
          'A33',
          'B31',
          'C30',
          'C2',
          'B53',
          'A9',
          'B56',
          'D39',
          'D24',
          'C29',
          'D25',
          'B20',
          'A28',
          'B54',
          'C23',
          'B25',
          'D50',
          'A24',
          'B30',
          'A19',
          'D18',
          'B12',
          'A36',
          'D57',
          'A47',
          'B2',
          'D41',
          'B38',
          'A2',
          'B22',
          'C55',
          'C5',
          'C43',
          'D31',
          'B43',
          'C54',
          'B44',
          'D46',
          'B49',
          'C22',
          'D49',
          'B30',
          'C27',
          'D6',
          'C26',
          'A43',
          'C10',
          'B33',
          'A21',
          'D42',
          'A26',
          'B24',
          'D21',
          'A28',
          'B22',
          'C48',
          'D33',
          'B4',
          'A49',
          'C24',
          'A54',
          'D27',
          'C38',
          'D5',
          'C18',
          'B31',
          'D56',
          'C42',
          'D10',
          'D52',
          'C8',
          'B25',
          'C22',
          'A45',
          'D55',
          'B3',
          'C49',
          'B5',
          'D26',
          'B32',
          'C27',
          'D31',
          'D47',
          'A59',
          'A3',
          'D46',
          'D14',
          'A25',
          'B41',
          'D37',
          'A20',
          'B55',
          'C13',
          'A15',
          'D3',
          'A13',
          'D18',
          'C57',
          'D19',
          'C52',
          'B16',
          'C44',
          'B5',
          'A41',
          'C28',
          'A42',
          'C16',
          'A56',
          'C14',
          'D53',
          'D7',
          'A54',
          'C20',
          'D50',
          'B10',
          'D22',
          'A24',
          'D29',
          'A4',
          'D49',
          'B39',
          'C47',
          'C4',
          'B33',
          'B54',
          'A21',
          'D1',
          'C32',
          'D34',
          'B19',
          'A40',
          'B41',
          'D26',
          'C40',
          'B5',
          'D55',
          'D5',
          'A38',
          'D45',
          'D14',
          'A20',
          'B28',
          'C7',
          'B51',
          'A29',
          'B12',
          'C41',
          'D30',
          'B29',
          'C30',
          'B57',
          'C9',
          'B15',
          'D17',
          'A48',
          'B13',
          'D42',
          'A2',
          'D29',
          'A30',
          'B8',
          'C47',
          'B3',
          'C34',
          'D47',
          'C24',
          'B40',
          'C23',
          'A56',
          'C13',
          'A21',
          'B25',
          'D58',
          'A34',
          'B14',
          'A7',
          'B52',
          'A32',
          'B20',
          'A24',
          'C16',
          'B32',
          'D55',
          'A35',
          'C18',
          'D23',
          'D46',
          'B26',
          'A26',
          'D12',
          'D50',
          'B37',
          'C58',
          'B23',
          'D17',
          'A22',
          'D13',
          'A14',
          'B30',
          'A11',
          'B60',
          'D40',
          'B55',
          'C2',
          'B15',
          'D31',
          'C26',
          'B36',
          'C54',
          'B45',
          'D60',
          'C45',
          'D28',
          'A20',
          'B59',
          'A43',
          'C25',
          'B39',
          'D44',
          'A59',
          'C49',
          'D13',
          'A27',
          'B22',
          'C14',
          'B26',
          'D45',
          'A24',
          'B43',
          'A48',
          'D56',
          'D4',
          'C13',
          'D57',
          'C38',
          'D51',
          'B47',
          'D31',
          'D16',
          'B16',
          'D53',
          'B12',
          'D47',
          'B48',
          'A18',
          'C10',
          'A19',
          'C8',
          'A42',
          'D55',
          'A26',
          'D28',
          'B18',
          'A45',
          'C21',
          'B27',
          'A10',
          'B30',
          'A35',
          'D3',
          'C9',
          'B37',
          'C30',
          'D1',
          'A41',
          'C12',
          'A15',
          'D23',
          'A20',
          'D38',
          'B39',
          'D34',
          'C47',
          'B40',
          'D17',
          'D37',
          'A50',
          'B11',
          'C55',
          'B33',
          'C27',
          'B41',
          'D32',
          'C50',
          'D14',
          'A12',
          'D15',
          'C46',
          'D35',
          'C35',
          'B32',
          'A45',
          'D21',
          'A18',
          'B28',
          'D52',
          'B5',
          'D43',
          'A2',
          'B35',
          'C55',
          'D22',
          'B36',
          'C59',
          'B19',
          'C51',
          'C3',
          'C19',
          'A54',
          'D15',
          'A51',
          'C38',
          'B9',
          'D60',
          'B46',
          'D58',
          'A46',
          'B14',
          'A29',
          'B18',
          'C5',
          'B51',
          'A11',
          'A26',
          'B38',
          'C11',
          'B52',
          'C7',
          'A20',
          'C22',
          'B50',
          'A30',
          'B35',
          'C52',
          'B44',
          'A24',
          'D12',
          'C58',
          'D25',
          'A16',
          'B42',
          'A4',
          'B4',
          'A35',
          'D40',
          'A19',
          'C23',
          'D6',
          'D54',
          'B7',
          'A36',
          'C16',
          'A45',
          'D35',
          'D59',
          'C31',
          'B56',
          'C5',
          'C46',
          'B3',
          'A38',
          'B27',
          'A23',
          'D1',
          'A20',
          'D19',
          'C50',
          'D38',
          'B15',
          'A7',
          'D31',
          'A6',
          'D48',
          'D9',
          'C44',
          'B37',
          'A29',
          'D50',
          'D7',
          'A40',
          'C29',
          'B31',
          'C12',
          'B18',
          'D26',
          'C39',
          'D53',
          'B21',
          'A1',
          'B45',
          'D52',
          'B29',
          'A27',
          'B42',
          'A13',
          'B50',
          'D49',
          'D16',
          'A23',
          'D32',
          'C49',
          'B23',
          'A31',
          'B2',
          'D54',
          'A26',
          'B19',
          'A44',
          'C23',
          'B6',
          'D33',
          'B49',
          'C21',
          'D9',
          'D48',
          'D21',
          'B41',
          'C55',
          'D18',
          'A21',
          'B34',
          'C10',
          'A50',
          'D57',
          'C20',
          'B30',
          'A9',
          'B12',
          'D46',
          'A55',
          'A39',
          'A58',
          'D50',
          'A4',
          'B55',
          'A28',
          'D39',
          'B37',
          'C29',
          'D1',
          'A14',
          'C14',
          'B17',
          'A47',
          'C11',
          'B56',
          'A22',
          'D4',
          'C7',
          'D5',
          'D55',
          'C31',
          'B27',
          'C30',
          'B29',
          'A23',
          'D46',
          'D23',
          'B6',
          'C42',
          'C6',
          'C54',
          'D37',
          'A38',
          'B19',
          'B41',
          'C25',
          'A40',
          'B45',
          'A9',
          'B31',
          'D49',
          'A5',
          'B3',
          'C52',
          'D15',
          'B38',
          'C9',
          'A21',
          'D30',
          'C34',
          'D53',
          'D7',
          'D50',
          'C39',
          'B5',
          'C54',
          'D22',
          'A47',
          'B60',
          'A34',
          'B21',
          'C51',
          'B44',
          'A43',
          'B34',
          'C7',
          'C28',
          'B37',
          'C18',
          'B49',
          'A32',
          'D4',
          'A12',
          'C6',
          'B52',
          'C5',
          'C56',
          'D12',
          'C40',
          'B35',
          'A35',
          'B48',
          'A1',
          'A57',
          'D48',
          'D32',
          'B9',
          'A30',
          'C19',
          'B50',
          'C9',
          'C24',
          'B4',
          'A49',
          'B42',
          'C58',
          'D13',
          'A25',
          'B24',
          'C51',
          'D37',
          'A32',
          'C18',
          'D17',
          'C29',
          'D26',
          'A16',
          'C12',
          'A19',
          'D38',
          'B56',
          'C4',
          'B54',
          'C32',
          'D54',
          'D6',
          'C38',
          'B14',
          'D18',
          'A28',
          'D42',
          'B37',
          'B22',
          'D50',
          'C17',
          'B24',
          'A1',
          'B38',
          'A17',
          'D30',
          'A5',
          'B30',
          'C13',
          'A45',
          'D59',
          'A37',
          'B20',
          'A36',
          'B27',
          'D43',
          'B35',
          'C39',
          'B10',
          'C52',
          'B49',
          'C51',
          'D15',
          'D42',
          'B14',
          'A6',
          'B1',
          'C48',
          'D37',
          'D17',
          'C16',
          'A59',
          'C41',
          'D55',
          'A47',
          'D4',
          'D56',
          'C27',
          'B56',
          'C3',
          'B33',
          'C37',
          'D23',
          'D38',
          'B57',
          'A18',
          'B29',
          'A17',
          'B48',
          'C60',
          'D11',
          'C59',
          'B26',
          'A25',
          'B13',
          'A48',
          'D39',
          'B1',
          'A37',
          'D57',
          'D3',
          'B15',
          'C8',
          'B50',
          'A27',
          'B12',
          'D27',
          'B37',
          'A4',
          'B44',
          'C39',
          'C6',
          'B17',
          'A39',
          'D54',
          'B9',
          'D48',
          'A2',
          'D40',
          'C53',
          'B42',
          'C47',
          'C1',
          'A38',
          'C18',
          'D2',
          'A14',
          'A29',
          'B21',
          'D15',
          'C30',
          'B40',
          'D41',
          'A19',
          'C20',
          'B49',
          'C10',
          'B35',
          'D46',
          'D12',
          'C55',
          'D13',
          'B38',
          'D22',
          'A18',
          'C24',
          'D48',
          'B31',
          'C11',
          'B20',
          'A20',
          'D20',
          'B7',
          'A52',
          'C22',
          'A35',
          'B41',
          'A21',
          'B27',
          'C59',
          'D47',
          'A7',
          'B36',
          'C31',
          'B58',
          'D38',
          'D23',
          'A27',
          'B25',
          'D53',
          'A44',
          'B17',
          'C45',
          'B3',
          'A31',
          'B5',
          'C43',
          'C2',
          'C51',
          'D31',
          'D16',
          'D42',
          'A60',
          'D27',
          'A25',
          'B31',
          'C28',
          'D19',
          'A13',
          'B33',
          'C41',
          'B12',
          'A8',
          'B40',
          'D57',
          'A27',
          'D44',
          'B26',
          'C13',
          'D32',
          'C27',
          'D46',
          'B46',
          'C58',
          'D17',
          'B17',
          'C18',
          'A57',
          'C60',
          'B21',
          'A32',
          'B54',
          'C31',
          'D3',
          'A23',
          'B13',
          'D22',
          'B40',
          'C22',
          'B58',
          'C21',
          'A20',
          'D15',
          'C45',
          'B14',
          'A5',
          'B36',
          'D43',
          'C57',
          'B46',
          'C44',
          'B21',
          'A28',
          'D56',
          'B18',
          'C4',
          'A10',
          'D33',
          'A36',
          'B8',
          'C42',
          'B42',
          'D41',
          'A34',
          'D13',
          'A21',
          'B35',
          'C51',
          'A57',
          'C46',
          'A49',
          'D58',
          'B7',
          'C53',
          'B34',
          'D55',
          'D5',
          'A22',
          'B32',
          'C34',
          'D18',
          'C16',
          'D2',
          'A31',
          'D45',
          'D14',
          'B22',
          'C24',
          'A16',
          'D6',
          'D51',
          'B38',
          'C8',
          'C27',
          'B37',
          'A52',
          'C28',
          'D28',
          'C44',
          'D32',
          'C18',
          'B23',
          'A28',
          'B6',
          'D47',
          'D9',
          'C41',
          'C7',
          'A21',
          'B58',
          'A39',
          'A58',
          'D45',
          'B12',
          'A50',
          'D55',
          'C15',
          'A59',
          'C3',
          'C42',
          'B36',
          'C30',
          'D3',
          'C8',
          'A16',
          'D18',
          'D37',
          'B48',
          'A34',
          'C23',
          'B55',
          'B31',
          'A14',
          'D38',
          'D23',
          'C55',
          'B50',
          'C51',
          'B16',
          'D27',
          'B3',
          'A3',
          'B53',
          'C5',
          'D49',
          'D8',
          'A53',
          'D28',
          'A16',
          'B44',
          'C38',
          'D52',
          'B46',
          'A40',
          'D59',
          'D4',
          'D56',
          'B24',
          'A24',
          'B26',
          'C32',
          'B55',
          'D40',
          'B16',
          'A48',
          'C35',
          'D24',
          'A18',
          'D15',
          'B40',
          'C59',
          'D20',
          'C52',
          'D36',
          'C18',
          'A58',
          'C43',
          'B21',
          'D23',
          'C16',
          'A25',
          'D51',
          'B30',
          'A17',
          'B39',
          'A4',
          'D30',
          'C32',
          'B57',
          'A22',
          'D12',
          'C19',
          'B58',
          'C9',
          'A50',
          'C46',
          'B4',
          'A3',
          'B20',
          'D60',
          'A53',
          'C22',
          'B31',
          'C36',
          'B14',
          'C10',
          'B36',
          'D40',
          'D17',
          'A20',
          'B29',
          'C29',
          'D45',
          'D14',
          'C53',
          'C4',
          'C30',
          'D7',
          'D50',
          'A60',
          'A36',
          'B12',
          'D44',
          'C56',
          'B34',
          'C20',
          'A45',
          'B60',
          'A16',
          'C5',
          'C50',
          'B40',
          'D48',
          'B5',
          'C24',
          'B41',
          'C14',
          'A59',
          'D51',
          'A7',
          'D2',
          'C10',
          'A20',
          'B50',
          'D60',
          'A34',
          'B17',
          'D15',
          'D41',
          'A3',
          'B14',
          'C43',
          'B40',
          'A51',
          'D20',
          'A19',
          'C26',
          'B37',
          'D26',
          'B19',
          'A25',
          'B32',
          'C33',
          'B3',
          'D54',
          'D6',
          'A34',
          'B28',
          'C59',
          'D12',
          'D46',
          'A8',
          'B13',
          'A36',
          'C22',
          'B53',
          'D57',
          'D3',
          'A11',
          'D13',
          'C54',
          'D34',
          'C37',
          'A46',
          'C12',
          'B27',
          'C5',
          'B34',
          'B49',
          'A4',
          'B56',
          'A31',
          'D49',
          'D8',
          'A24',
          'B46',
          'A34',
          'A14',
          'C13',
          'B17',
          'C2',
          'B33',
          'D30',
          'C47',
          'D14',
          'A21',
          'C25',
          'D46',
          'B31',
          'D44',
          'B34',
          'D2',
          'D58',
          'B51',
          'C57',
          'D20',
          'D35',
          'B12',
          'A26',
          'B23',
          'C56',
          'A53',
          'C44',
          'D15',
          'C41',
          'B42',
          'C30',
          'D33',
          'A18',
          'B13',
          'A4',
          'B30',
          'A27',
          'B9',
          'A52',
          'C16',
          'B25',
          'A44',
          'C27',
          'B7',
          'A39',
          'C4',
          'B45',
          'D26',
          'C58',
          'B21',
          'C21',
          'D54',
          'C10',
          'B37',
          'A25',
          'D7',
          'A38',
          'B11',
          'D50',
          'C22',
          'D1',
          'C17',
          'A35',
          'B16',
          'C48',
          'B23',
          'C8',
          'B51',
          'A27',
          'D12',
          'D46',
          'B19',
          'A33',
          'C24',
          'B52',
          'A13',
          'D6',
          'D51',
          'D11',
          'C57',
          'B33',
          'C12',
          'D33',
          'B37',
          'C17',
          'D7',
          'C42',
          'B21',
          'A3',
          'D47',
          'D9',
          'A59',
          'C13',
          'B42',
          'D40',
          'D17',
          'D32',
          'A38',
          'A55',
          'D49',
          'A24',
          'D43',
          'B39',
          'A15',
          'B20',
          'C52',
          'B34',
          'C45',
          'C6',
          'B25',
          'C54',
          'B15',
          'C53',
          'D35',
          'C34',
          'A49',
          'C23',
          'B7',
          'D51',
          'A45',
          'D23',
          'A14',
          'B9',
          'D2',
          'B12',
          'D32',
          'B42',
          'C7',
          'C38',
          'D22',
          'A3',
          'B44',
          'C37',
          'D26',
          'B46',
          'C56',
          'B52',
          'D58',
          'C44',
          'B39',
          'A29',
          'B48',
          'C21',
          'B23',
          'D50',
          'B29',
          'A8',
          'B18',
          'A34',
          'C19',
          'A51',
          'C8',
          'D6',
          'A57',
          'C59',
          'D42',
          'C30',
          'B50',
          'D59',
          'A53',
          'C37',
          'B2',
          'A37',
          'D55',
          'D5',
          'A41',
          'B37',
          'A3',
          'D30',
          'C45',
          'B4',
          'D53',
          'A14',
          'C5',
          'B28',
          'C31',
          'A22',
          'B24',
          'C60',
          'D10',
          'A25',
          'A9',
          'B10',
          'A30',
          'B20',
          'A5',
          'B1',
          'D8',
          'D49',
          'C40',
          'B40',
          'C39',
          'D12',
          'C51',
          'D20',
          'B42',
          'C6',
          'C49',
          'B32',
          'C15',
          'D50',
          'D7',
          'A17',
          'C27',
          'B57',
          'C4',
          'A58',
          'A39',
          'B8',
          'D47',
          'D11',
          'C56',
          'B24',
          'C28',
          'B40',
          'D43',
          'D28',
          'C39',
          'D51',
          'B21',
          'C11',
          'B48',
          'C47',
          'B60',
          'A15',
          'D16',
          'D45',
          'D26',
          'A26',
          'A8',
          'B19',
          'C19',
          'D13',
          'C48',
          'B32',
          'A44',
          'D60',
          'D1',
          'D59',
          'B30',
          'D50',
          'B38',
          'A33',
          'B46',
          'C54',
          'D44',
          'D14',
          'A37',
          'C15',
          'A54',
          'C56',
          'B9',
          'D51',
          'C22',
          'A19',
          'B59',
          'A30',
          'D2',
          'D58',
          'A26',
          'C16',
          'B40',
          'C26',
          'D32',
          'B44',
          'A36',
          'B37',
          'C57',
          'C6',
          'A21',
          'D44',
          'B32',
          'C32',
          'B3',
          'C13',
          'B5',
          'A49',
          'C33',
          'D50',
          'A30',
          'D38',
          'D23',
          'C14',
          'B24',
          'A18',
          'C27',
          'B33',
          'D56',
          'D4',
          'C22',
          'B56',
          'A28',
          'B28',
          'A14',
          'D20',
          'A46',
          'B16',
          'C44',
          'B60',
          'C37',
          'D36',
          'B36',
          'C45',
          'B38',
          'C60',
          'B22',
          'C51',
          'B51',
          'D49',
          'D8',
          'A13',
          'D38',
          'B11',
          'A38',
          'B34',
          'C12',
          'B41',
          'C43',
          'B46',
          'C6',
          'B50',
          'C3',
          'A60',
          'C35',
          'B31',
          'A8',
          'D30',
          'A36',
          'C18',
          'B58',
          'A37',
          'D46',
          'D12',
          'A21',
          'A43',
          'B18',
          'A29',
          'C20',
          'A20',
          'C8',
          'C37',
          'D54',
          'D6',
          'D51',
          'A15',
          'B8',
          'D58',
          'B54',
          'D57',
          'D3',
          'A7',
          'D24',
          'C41',
          'D25',
          'D40',
          'D18',
          'A27',
          'D53',
          'B18',
          'C46',
          'B32',
          'C14',
        ],
      },
    ],
  },
  {
    title: 'Mandala 1',
    id: 'mandala-1',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Mandala%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C40',
          'B2',
          'D40',
          'A9',
          'D18',
          'A24',
          'B42',
          'C3',
          'A59',
          'D48',
          'B21',
          'D60',
          'B39',
          'A20',
          'B44',
          'A21',
          'D41',
          'B14',
          'C4',
          'B34',
          'C25',
          'B57',
          'A2',
          'B40',
          'C5',
          'B2',
          'C40',
          'B36',
          'C27',
          'B12',
          'D59',
          'A23',
          'D1',
          'C41',
          'B8',
          'C18',
          'B18',
          'C8',
          'B38',
          'C39',
          'A58',
          'D24',
          'A35',
          'D3',
          'C60',
          'B22',
          'C1',
          'A41',
          'D4',
          'A15',
          'B43',
          'C40',
          'B2',
          'C37',
          'B27',
          'C59',
          'D38',
          'A43',
          'C23',
          'B59',
          'A40',
          'C19',
          'A42',
          'C17',
          'B20',
          'D42',
          'A46',
          'D55',
          'A47',
          'C15',
          'B6',
          'D51',
          'A37',
          'D26',
          'A13',
          'D5',
          'A16',
          'D29',
          'A43',
          'D2',
          'A38',
          'D1',
          'C41',
          'B40',
          'C20',
          'A45',
          'D44',
          'A4',
          'D13',
          'C42',
          'B20',
          'D42',
          'A23',
          'D59',
          'B39',
          'C37',
          'B50',
          'C36',
          'B26',
          'C12',
          'A59',
          'D12',
          'A28',
          'D36',
          'A2',
          'D21',
          'A1',
          'B60',
          'A34',
          'D48',
          'A54',
          'D20',
          'A42',
          'D8',
          'C54',
          'B20',
          'C42',
          'B21',
          'C25',
          'B57',
          'C58',
          'B24',
          'D43',
          'A31',
          'D15',
          'A7',
          'B51',
          'C39',
          'D18',
          'C37',
          'B27',
          'C13',
          'A41',
          'D40',
          'A53',
          'C8',
          'B38',
          'C43',
          'D19',
          'A17',
          'D6',
          'A36',
          'D25',
          'A23',
          'B42',
          'C22',
          'B1',
          'C34',
          'B48',
          'A22',
          'D56',
          'B11',
          'C26',
          'B35',
          'C7',
          'B16',
          'C31',
          'B45',
          'C56',
          'D9',
          'C53',
          'D6',
          'C24',
          'B23',
          'A10',
          'B54',
          'C46',
          'D19',
          'C43',
          'D16',
          'A8',
          'B52',
          'A39',
          'D7',
          'A18',
          'B41',
          'C9',
          'A54',
          'D43',
          'A4',
          'D2',
          'A44',
          'C14',
          'B4',
          'C4',
          'B49',
          'A3',
          'D39',
          'A37',
          'D60',
          'B30',
          'C16',
          'B58',
          'A40',
          'D36',
          'B19',
          'C60',
          'B22',
          'A2',
          'D33',
          'A49',
          'C21',
          'A60',
          'D22',
          'B2',
          'C40',
          'B5',
          'C1',
          'B33',
          'C49',
          'D20',
          'A25',
          'D57',
          'A48',
          'C18',
          'A33',
          'C2',
          'B37',
          'C28',
          'D14',
          'A6',
          'D40',
          'A21',
          'B59',
          'C23',
          'B60',
          'C58',
          'B24',
          'C21',
          'B41',
          'C42',
          'B29',
          'C15',
          'B58',
          'C56',
          'B46',
          'A16',
          'D58',
          'A28',
          'D37',
          'A38',
          'D27',
          'A59',
          'D14',
          'A30',
          'D41',
          'B2',
          'C2',
          'B37',
          'C23',
          'B59',
          'A13',
          'D29',
          'A37',
          'D50',
          'A36',
          'D38',
          'C58',
          'B24',
          'D58',
          'A17',
          'D30',
          'A46',
          'D21',
          'A43',
          'C22',
          'A52',
          'C6',
          'A24',
          'D23',
          'A55',
          'D45',
          'B20',
          'C41',
          'B42',
          'A18',
          'D17',
          'C45',
          'B3',
          'C44',
          'D24',
          'A58',
          'D58',
          'B39',
          'A1',
          'D20',
          'A60',
          'D22',
          'A38',
          'D41',
          'B17',
          'C21',
          'B53',
          'A39',
          'C19',
          'B23',
          'A8',
          'D54',
          'A45',
          'C20',
          'B32',
          'A19',
          'B47',
          'C58',
          'B42',
          'C3',
          'B43',
          'A30',
          'C1',
          'B13',
          'C29',
          'A44',
          'D46',
          'A32',
          'D16',
          'A21',
          'D32',
          'A50',
          'D3',
          'C51',
          'B35',
          'C7',
          'A24',
          'D33',
          'B3',
          'C6',
          'B16',
          'C19',
          'A45',
          'D59',
          'A56',
          'C10',
          'B23',
          'C34',
          'B48',
          'C4',
          'B49',
          'C57',
          'D38',
          'B19',
          'D35',
          'A51',
          'D6',
          'A4',
          'D45',
          'B15',
          'C30',
          'B36',
          'C51',
          'D37',
          'A5',
          'B60',
          'C29',
          'D17',
          'C48',
          'B56',
          'A21',
          'B42',
          'C46',
          'B31',
          'C47',
          'B3',
          'D58',
          'A18',
          'D20',
          'A7',
          'B23',
          'C55',
          'B7',
          'D52',
          'B38',
          'A20',
          'D4',
          'A40',
          'D60',
          'A26',
          'D34',
          'C48',
          'B57',
          'C25',
          'B39',
          'A10',
          'D21',
          'A57',
          'D47',
          'B18',
          'D28',
          'A14',
          'B40',
          'C5',
          'A42',
          'C2',
          'A50',
          'D19',
          'A60',
          'D39',
          'A3',
          'B32',
          'D44',
          'B15',
          'C59',
          'B13',
          'C43',
          'B45',
          'C22',
          'B25',
          'C35',
          'B4',
          'D60',
          'A57',
          'C39',
          'B42',
          'C13',
          'B28',
          'A2',
          'D4',
          'C24',
          'A58',
          'D50',
          'B16',
          'D58',
          'A59',
          'D13',
          'A9',
          'D57',
          'A25',
          'B57',
          'C25',
          'D11',
          'A27',
          'C14',
          'B58',
          'A19',
          'D18',
          'C38',
          'B7',
          'D23',
          'C44',
          'D25',
          'B1',
          'C13',
          'B6',
          'C3',
          'A26',
          'D10',
          'C57',
          'B21',
          'D45',
          'A43',
          'C17',
          'B40',
          'C45',
          'D14',
          'A19',
          'B33',
          'C49',
          'B53',
          'C43',
          'B22',
          'C60',
          'B39',
          'C53',
          'B45',
          'C32',
          'B46',
          'A16',
          'D6',
          'A55',
          'C9',
          'A40',
          'D1',
          'C50',
          'B19',
          'C16',
          'A50',
          'C38',
          'B37',
          'C2',
          'B17',
          'D55',
          'B10',
          'C23',
          'B59',
          'C21',
          'B1',
          'D57',
          'A43',
          'D12',
          'A1',
          'B36',
          'D48',
          'A20',
          'D23',
          'B8',
          'C41',
          'B52',
          'C5',
          'A24',
          'D26',
          'A56',
          'D46',
          'A4',
          'D22',
          'C55',
          'B44',
          'C45',
          'D31',
          'A47',
          'D43',
          'B25',
          'C35',
          'B5',
          'C20',
          'B49',
          'C33',
          'B20',
          'D56',
          'A50',
          'D35',
          'B26',
          'C56',
          'D38',
          'A20',
          'B40',
          'C5',
          'B53',
          'D60',
          'A49',
          'D3',
          'A52',
          'C38',
          'B41',
          'A6',
          'D34',
          'A5',
          'D51',
          'B38',
          'D50',
          'B4',
          'C54',
          'B7',
          'C16',
          'B30',
          'A32',
          'C15',
          'A28',
          'B43',
          'A15',
          'B26',
          'D43',
          'B3',
          'C40',
          'D8',
          'A21',
          'D52',
          'B22',
          'C26',
          'A36',
          'C18',
          'B10',
          'D23',
          'A11',
          'B55',
          'C27',
          'A44',
          'C31',
          'D14',
          'C55',
          'B45',
          'D55',
          'A59',
          'D44',
          'B34',
          'A19',
          'B47',
          'A38',
          'D4',
          'A5',
          'D53',
          'A22',
          'B60',
          'C26',
          'B32',
          'C6',
          'B15',
          'D27',
          'A37',
          'D21',
          'A1',
          'D30',
          'C48',
          'D7',
          'C24',
          'B27',
          'C36',
          'D18',
          'A16',
          'B31',
          'A31',
          'C30',
          'A60',
          'D45',
          'A32',
          'C29',
          'B14',
          'C59',
          'B16',
          'D32',
          'C45',
          'B54',
          'C8',
          'B55',
          'C38',
          'D3',
          'A8',
          'D37',
          'A42',
          'C17',
          'A25',
          'D24',
          'A40',
          'D19',
          'A41',
          'D16',
          'C42',
          'B29',
          'C12',
          'A46',
          'D52',
          'B10',
          'A11',
          'B26',
          'C11',
          'A56',
          'D11',
          'B11',
          'D40',
          'B2',
          'C26',
          'B35',
          'A27',
          'D35',
          'A39',
          'B54',
          'C59',
          'B27',
          'D30',
          'C32',
          'A35',
          'C18',
          'B22',
          'C60',
          'B44',
          'C41',
          'D1',
          'A17',
          'D9',
          'C12',
          'D10',
          'A59',
          'D15',
          'C50',
          'B32',
          'C47',
          'A56',
          'C20',
          'B40',
          'C17',
          'C1',
          'D45',
          'C56',
          'B27',
          'D36',
          'A8',
          'D27',
          'C60',
          'B24',
          'A18',
          'D2',
          'A42',
          'D56',
          'A19',
          'D44',
          'A41',
          'C13',
          'B58',
          'C51',
          'A51',
          'B50',
          'D51',
          'C55',
          'B55',
          'A47',
          'C35',
          'D24',
          'B18',
          'C23',
          'D1',
          'A26',
          'D22',
          'A43',
          'C2',
          'B11',
          'A11',
          'D57',
          'A13',
          'D26',
          'C37',
          'B7',
          'C15',
          'D4',
          'A58',
          'C39',
          'B19',
          'D37',
          'C49',
          'D20',
          'C46',
          'B3',
          'C23',
          'B23',
          'C27',
          'B12',
          'C30',
          'B15',
          'A7',
          'D7',
          'A39',
          'D30',
          'B29',
          'C14',
          'D58',
          'A15',
          'D8',
          'C13',
          'B30',
          'D47',
          'B8',
          'D53',
          'B9',
          'D39',
          'B17',
          'D29',
          'C33',
          'A40',
          'D59',
          'B19',
          'C49',
          'A51',
          'C5',
          'B57',
          'A53',
          'C7',
          'B35',
          'D52',
          'B49',
          'C20',
          'B24',
          'C39',
          'B2',
          'C17',
          'A29',
          'C1',
          'B33',
          'C22',
          'A43',
          'D53',
          'A48',
          'D3',
          'A24',
          'C9',
          'B41',
          'A3',
          'D16',
          'A20',
          'D39',
          'A6',
          'B50',
          'C7',
          'A11',
          'B12',
          'A9',
          'D6',
          'C52',
          'B4',
          'D25',
          'A33',
          'D19',
          'C43',
          'D2',
          'C34',
          'B28',
          'A23',
          'B51',
          'D51',
          'B35',
          'C39',
          'B30',
          'D30',
          'A16',
          'C5',
          'B20',
          'C41',
          'D32',
          'B3',
          'C44',
          'B46',
          'A35',
          'C25',
          'D13',
          'A30',
          'B59',
          'C57',
          'B18',
          'C18',
          'D7',
          'A56',
          'C46',
          'D22',
          'A19',
          'B35',
          'C6',
          'B44',
          'A18',
          'D40',
          'A45',
          'D5',
          'C24',
          'B54',
          'C5',
          'D49',
          'A33',
          'D46',
          'B13',
          'D18',
          'C60',
          'D36',
          'A51',
          'D34',
          'A15',
          'C11',
          'D10',
          'C56',
          'B37',
          'D45',
          'B42',
          'A3',
          'D5',
          'A21',
          'D59',
          'B39',
          'C29',
          'A33',
          'B34',
          'C25',
          'A46',
          'B51',
          'A36',
          'C3',
          'B7',
          'C13',
          'B31',
          'A30',
          'D36',
          'C41',
          'D10',
          'B12',
          'A8',
          'B17',
          'C59',
          'B55',
          'C47',
          'B43',
          'A32',
          'D23',
          'B9',
          'C21',
          'B41',
          'A20',
          'B59',
          'C40',
          'D16',
          'C48',
          'A51',
          'C35',
          'B6',
          'C32',
          'B47',
          'D57',
          'B56',
          'A12',
          'D25',
          'C38',
          'B4',
          'D37',
          'C53',
          'D48',
          'B21',
          'C54',
          'B42',
          'C13',
          'A14',
          'D42',
          'A6',
          'D1',
          'C58',
          'D46',
          'C51',
          'A53',
          'D20',
          'A21',
          'D41',
          'A42',
          'D29',
          'B2',
          'D49',
          'C52',
          'B7',
          'D15',
          'A44',
          'D43',
          'A52',
          'B51',
          'C44',
          'B17',
          'C20',
          'A60',
          'C4',
          'A24',
          'D31',
          'C32',
          'A40',
          'C19',
          'B56',
          'A37',
          'C27',
          'B28',
          'A2',
          'B29',
          'C11',
          'B19',
          'D34',
          'B32',
          'D33',
          'A29',
          'B37',
          'A17',
          'C6',
          'B45',
          'D36',
          'A22',
          'D17',
          'A57',
          'D41',
          'A11',
          'C9',
          'A52',
          'B53',
          'A45',
          'D26',
          'C44',
          'B57',
          'C5',
          'A7',
          'D32',
          'A47',
          'D56',
          'D4',
          'B2',
          'D28',
          'C34',
          'B1',
          'D36',
          'A3',
          'B5',
          'D23',
          'A28',
          'D55',
          'A54',
          'C50',
          'A50',
          'B50',
          'C4',
          'D48',
          'B38',
          'A7',
          'B23',
          'C43',
          'B57',
          'C36',
          'D25',
          'A52',
          'C22',
          'B36',
          'C10',
          'A27',
          'B25',
          'A5',
          'D11',
          'B9',
          'C16',
          'A58',
          'C48',
          'B27',
          'C29',
          'D34',
          'A55',
          'D53',
          'B48',
          'C60',
          'B50',
          'C59',
          'D24',
          'B14',
          'A11',
          'D17',
          'C31',
          'A47',
          'D18',
          'A57',
          'A7',
          'D49',
          'A35',
          'D3',
          'C26',
          'B40',
          'A14',
          'D14',
          'B5',
          'D9',
          'A23',
          'B52',
          'D58',
          'D42',
          'A39',
          'C36',
          'B38',
          'C2',
          'B26',
          'C35',
          'D27',
          'C31',
          'D30',
          'A44',
          'C14',
          'B15',
          'A6',
          'D19',
          'C57',
          'D46',
          'A25',
          'B34',
          'C50',
          'D49',
          'A5',
          'B46',
          'A31',
          'B59',
          'A10',
          'B26',
          'C33',
          'D2',
          'A40',
          'D52',
          'C59',
          'B58',
          'C13',
          'D5',
          'D56',
          'A32',
          'C35',
          'B14',
          'A25',
          'C16',
          'B31',
          'D47',
          'B55',
          'B7',
          'C51',
          'D4',
          'D20',
          'D35',
          'A10',
          'B11',
          'D13',
          'A16',
          'C24',
          'B6',
          'B56',
          'A48',
          'B50',
          'A39',
          'D38',
          'A31',
          'D12',
          'C47',
          'D17',
          'B27',
          'D59',
          'C20',
          'D15',
          'C49',
          'B19',
          'C8',
          'B38',
          'C35',
          'A34',
          'C28',
          'A59',
          'C39',
          'D46',
          'B46',
          'D58',
          'B55',
          'A57',
          'C57',
          'B41',
          'C9',
          'B15',
          'A5',
          'D56',
          'B56',
          'C22',
          'B45',
          'A25',
          'B25',
          'A4',
          'B35',
          'A47',
          'C15',
          'B18',
          'C14',
          'B5',
          'D25',
          'A3',
          'D39',
          'A29',
          'B32',
          'C26',
          'A37',
          'C16',
          'B14',
          'A10',
          'C3',
          'B43',
          'C52',
          'D21',
          'A34',
          'B20',
          'C33',
          'B42',
          'C30',
          'D33',
          'A23',
          'C1',
          'A52',
          'D38',
          'D60',
          'B21',
          'C46',
          'D41',
          'D26',
          'C36',
          'B9',
          'A12',
          'B10',
          'D10',
          'C10',
          'A16',
          'B6',
          'D23',
          'C16',
          'B43',
          'C25',
          'A37',
          'B57',
          'C54',
          'D22',
          'B3',
          'C11',
          'D3',
          'D57',
          'A28',
          'C41',
          'B25',
          'D31',
          'A19',
          'D10',
          'D51',
          'A44',
          'C3',
          'B23',
          'A6',
          'B41',
          'A2',
          'B51',
          'C37',
          'A45',
          'D17',
          'B17',
          'D42',
          'A54',
          'C49',
          'B58',
          'B43',
          'D50',
          'C52',
          'A31',
          'B9',
          'A24',
          'B25',
          'C40',
          'B28',
          'A22',
          'D24',
          'C47',
          'B60',
          'C5',
          'C58',
          'B35',
          'D32',
          'C31',
          'B38',
          'A17',
          'B49',
          'A52',
          'C35',
          'B54',
          'C57',
          'A59',
          'C18',
          'B56',
          'C8',
          'B7',
          'D16',
          'C25',
          'B46',
          'D36',
          'C2',
          'D24',
          'A47',
          'C55',
          'B26',
          'C23',
          'D33',
          'C45',
          'A36',
          'D6',
          'C17',
          'D7',
          'C7',
          'B17',
          'A28',
          'D47',
          'C54',
          'B47',
          'C52',
          'B60',
          'C46',
          'A57',
          'D30',
          'B49',
          'B11',
          'C20',
          'C4',
          'B34',
          'C24',
          'A43',
          'B44',
          'C27',
          'A20',
          'D8',
          'D52',
          'A5',
          'A55',
          'C23',
          'B4',
          'D59',
          'D39',
          'A44',
          'C28',
          'D34',
          'B24',
          'C6',
          'A39',
          'A24',
          'D54',
          'B23',
          'D43',
          'A18',
          'B28',
          'C39',
          'D4',
          'C42',
          'B37',
          'D40',
          'A26',
          'A58',
          'D35',
          'C54',
          'D11',
          'D51',
          'B19',
          'A28',
          'B58',
          'A4',
          'A19',
          'C31',
          'D1',
          'D17',
          'A36',
          'D26',
          'B27',
          'D16',
          'C17',
          'B3',
          'C2',
          'D7',
          'D60',
          'A46',
          'B36',
          'D57',
          'D41',
          'A22',
          'B48',
          'C45',
          'B21',
          'C9',
          'B33',
          'A53',
          'A12',
          'B57',
          'A47',
          'B18',
          'A8',
          'D15',
          'C33',
          'D26',
          'C2',
          'A11',
          'B52',
          'C58',
          'B24',
          'C44',
          'A36',
          'C42',
          'A45',
          'B37',
          'A33',
          'C52',
          'A39',
          'B55',
          'C30',
          'D1',
          'C5',
          'B15',
          'C59',
          'D11',
          'C41',
          'C26',
          'D21',
          'C11',
          'A44',
          'D24',
          'A30',
          'C27',
          'A59',
          'D39',
          'C29',
          'A27',
          'B43',
          'D37',
          'A56',
          'D49',
          'B16',
          'D6',
          'A25',
          'C13',
          'A49',
          'C42',
          'B39',
          'A38',
          'C16',
          'A20',
          'A1',
          'B45',
          'D50',
          'B50',
          'D30',
          'B51',
          'C26',
          'B2',
          'C37',
          'B29',
          'A53',
          'D57',
          'A39',
          'D18',
          'A11',
          'C30',
          'B15',
          'D13',
          'C42',
          'C57',
          'B30',
          'D60',
          'C16',
          'B6',
          'D42',
          'A24',
          'B26',
          'D31',
          'B47',
          'C50',
          'B35',
          'C4',
          'B46',
          'C19',
          'B38',
          'D54',
          'C48',
          'A55',
          'A4',
          'D3',
          'A47',
          'C30',
          'A13',
          'B40',
          'C53',
          'D53',
          'D7',
          'D23',
          'C37',
          'B22',
          'B7',
          'B37',
          'A28',
          'B21',
          'C21',
          'A43',
          'D40',
          'D25',
          'C16',
          'B58',
          'C12',
          'B42',
          'D38',
          'A29',
          'B16',
          'C9',
          'A27',
          'D44',
          'A22',
          'C46',
          'A35',
          'D28',
          'B1',
          'C3',
          'A38',
          'B44',
          'D37',
          'C2',
          'B39',
          'D58',
          'D2',
          'A23',
          'C20',
          'B32',
          'A3',
          'B12',
          'C26',
          'B23',
          'A5',
          'B50',
          'C35',
          'B33',
          'C28',
          'B55',
          'C13',
          'D9',
          'D52',
          'B7',
          'D5',
          'C51',
          'A34',
          'C18',
          'B35',
          'A52',
          'A13',
          'D55',
          'B46',
          'D35',
          'D20',
          'A6',
          'D43',
          'B24',
          'D32',
          'A43',
          'A58',
          'A12',
          'B43',
          'C44',
          'B30',
          'C21',
          'C36',
          'A57',
          'D59',
          'A51',
          'C27',
          'D9',
          'A37',
          'C41',
          'B17',
          'A11',
          'B60',
          'C42',
          'B11',
          'A30',
          'B13',
          'C6',
          'C57',
          'A60',
          'C12',
          'D11',
          'D50',
          'A21',
          'C60',
          'B20',
          'C52',
          'C10',
          'A53',
          'B38',
          'D45',
          'B22',
          'D51',
          'C35',
          'D31',
          'B48',
          'A43',
          'C17',
          'B12',
          'D40',
          'A38',
          'C37',
          'B60',
          'D25',
          'D3',
          'A51',
          'C19',
          'D6',
          'D56',
          'B44',
          'A7',
          'D33',
          'B46',
          'D32',
          'C16',
          'B51',
          'D29',
          'B52',
          'C50',
          'A34',
          'C49',
          'B59',
          'A57',
          'D46',
          'C56',
          'B12',
          'C22',
          'A16',
          'B32',
          'A54',
          'C45',
          'D6',
          'C24',
          'D8',
          'C17',
          'B40',
          'D42',
          'D58',
          'C23',
          'B31',
          'D38',
          'B5',
          'C44',
          'D2',
          'C59',
          'B28',
          'C34',
          'B2',
          'B17',
          'C35',
          'A39',
          'C33',
          'D17',
          'A14',
          'B6',
          'A13',
          'B48',
          'B3',
          'C31',
          'B43',
          'C3',
          'B18',
          'A7',
          'C9',
          'D21',
          'C17',
          'A55',
          'C45',
          'B26',
          'C28',
          'B7',
          'C1',
          'B34',
          'B19',
          'D33',
          'A2',
          'C47',
          'A34',
          'D22',
          'A58',
          'A40',
          'D43',
          'B27',
          'A24',
          'B54',
          'C54',
          'A21',
          'B44',
          'C40',
          'B49',
          'C20',
          'B41',
          'C45',
          'D29',
          'D11',
          'D26',
          'C1',
          'A42',
          'D8',
          'C15',
          'D36',
          'B12',
          'D19',
          'A9',
          'B14',
          'C43',
          'A35',
          'C24',
          'A46',
          'D5',
          'A44',
          'B38',
          'A28',
          'C28',
          'B29',
          'C25',
          'B57',
          'A16',
          'C4',
          'A26',
          'B24',
          'C32',
          'A41',
          'D25',
          'C11',
          'D29',
          'B59',
          'C20',
          'D16',
          'C39',
          'A56',
          'D54',
          'A27',
          'C60',
          'B56',
          'C28',
          'B37',
          'C23',
          'D34',
          'C3',
          'B60',
          'A40',
          'D28',
          'C47',
          'B24',
          'C29',
          'A14',
          'B19',
          'A46',
          'C11',
          'B26',
          'D18',
          'C38',
          'B15',
          'C21',
          'A49',
          'C51',
          'A31',
          'B10',
          'A12',
          'B11',
          'D53',
          'A58',
          'D7',
          'D55',
          'A38',
          'C25',
          'A15',
          'D15',
          'B32',
          'D10',
          'A2',
          'D16',
          'A59',
          'D50',
          'C41',
          'B21',
          'C33',
          'D28',
          'C60',
          'A33',
          'D5',
          'C39',
          'C2',
          'D40',
          'D60',
          'B10',
          'D26',
          'B30',
          'C27',
          'A34',
          'D37',
          'B45',
          'A12',
          'B13',
          'B59',
          'B27',
          'C45',
          'A57',
          'D42',
          'B3',
          'B54',
          'A45',
          'D49',
          'B35',
          'D47',
          'B16',
          'A9',
          'B49',
          'C32',
          'D29',
          'A59',
          'A39',
          'D51',
          'A8',
          'D24',
          'C17',
          'B31',
          'C41',
          'A3',
          'B22',
          'C59',
          'C4',
          'A47',
          'D48',
          'B50',
          'D6',
          'C16',
          'D35',
          'C58',
          'D20',
          'C12',
          'A41',
          'D42',
          'D7',
          'C35',
          'A27',
          'D27',
          'A12',
          'D2',
          'C26',
          'D4',
          'A10',
          'C31',
          'B22',
          'A28',
          'A13',
          'B7',
          'D14',
          'B33',
          'C9',
          'B52',
          'A50',
          'D39',
          'B37',
          'A5',
          'D8',
          'C14',
          'B34',
          'A24',
          'B42',
          'A23',
          'C45',
          'B3',
          'B35',
          'C34',
          'B9',
          'C15',
          'D38',
          'B17',
          'C17',
          'D31',
          'A48',
          'D46',
          'B1',
          'A26',
          'C2',
          'A7',
          'C46',
          'A46',
          'D54',
          'A49',
          'D44',
          'C57',
          'B46',
          'C55',
          'D10',
          'A37',
          'C40',
          'B5',
          'C38',
          'B37',
          'D38',
          'B6',
          'D17',
          'C25',
          'B24',
          'D58',
          'D42',
          'A15',
          'B1',
          'C10',
          'A19',
          'B30',
          'D16',
          'B43',
          'D45',
          'B23',
          'C54',
          'D52',
          'A27',
          'D32',
          'C24',
          'C3',
          'B31',
          'C57',
          'B4',
          'C19',
          'A41',
          'B27',
          'C5',
          'B5',
          'D41',
          'A12',
          'D3',
          'A19',
          'C29',
          'D1',
          'A14',
          'B1',
          'A39',
          'C25',
          'B51',
          'D15',
          'C45',
          'D36',
          'D10',
          'D60',
          'A29',
          'D26',
          'B35',
          'C39',
          'A59',
          'B18',
          'D59',
          'B53',
          'C56',
          'D49',
          'A40',
          'C24',
          'B24',
          'D29',
          'B26',
          'D27',
          'C55',
          'B34',
          'A9',
          'B20',
          'A45',
          'C6',
          'C52',
          'C2',
          'A34',
          'B60',
          'B8',
          'A13',
          'A32',
          'B5',
          'C10',
          'B14',
          'C3',
          'D6',
          'C50',
          'B55',
          'B38',
          'C58',
          'A33',
          'B39',
          'D57',
          'A36',
          'B33',
          'A26',
          'C30',
          'B22',
          'C1',
          'B52',
          'D14',
          'C46',
          'A10',
          'D15',
          'A52',
          'B58',
          'C40',
          'D45',
          'A11',
          'B13',
          'C56',
          'C32',
          'B18',
          'D46',
          'C23',
          'C5',
          'C57',
          'A33',
          'C28',
          'B47',
          'C47',
          'A51',
          'C4',
          'A6',
          'B26',
          'D37',
          'D16',
          'B31',
          'A37',
          'B2',
          'C22',
          'D19',
          'C60',
          'B51',
          'C21',
          'B53',
          'A11',
          'A32',
          'B8',
          'D12',
          'A18',
          'D34',
          'B45',
          'A51',
          'C18',
          'D20',
          'A31',
          'D45',
          'B1',
          'C7',
          'C51',
          'B25',
          'A1',
          'D56',
          'D10',
          'B54',
          'C52',
          'B24',
          'D19',
          'C29',
          'C45',
          'D17',
          'B29',
          'A22',
          'B42',
          'C23',
          'A43',
          'D11',
          'A4',
          'B44',
          'C3',
          'D23',
          'C36',
          'B41',
          'D39',
          'C11',
          'A58',
          'D30',
          'B6',
          'C2',
          'D46',
          'B25',
          'D38',
          'B43',
          'A14',
          'B2',
          'A34',
          'C26',
          'B40',
          'C4',
          'A12',
          'C31',
          'B45',
          'D16',
          'A31',
          'C27',
          'B36',
          'A1',
          'B48',
          'B12',
          'D53',
          'C43',
          'B3',
          'C6',
          'B8',
          'D34',
          'C52',
          'A48',
          'B16',
          'D44',
          'C55',
          'B37',
          'C33',
          'B9',
          'A4',
          'A32',
          'D5',
          'C20',
          'D19',
          'C19',
          'B32',
          'A37',
          'B58',
          'C18',
          'B44',
          'D59',
          'D1',
          'C3',
          'C38',
          'B23',
          'A9',
          'D9',
          'C56',
          'B16',
          'A25',
          'D39',
          'A42',
          'B26',
          'B41',
          'A18',
          'A3',
          'B14',
          'A30',
          'C53',
          'D16',
          'D31',
          'B49',
          'D23',
          'C58',
          'D14',
          'A12',
          'D54',
          'B10',
          'B50',
          'A17',
          'D51',
          'A55',
          'C47',
          'B56',
          'C42',
          'D44',
          'B30',
          'C59',
          'B43',
          'D35',
          'B27',
          'A40',
          'C48',
          'B19',
          'D32',
          'B47',
          'C46',
          'D23',
          'A52',
          'D36',
          'C40',
          'D59',
          'D26',
          'A5',
          'B26',
          'C21',
          'B42',
          'A20',
          'D49',
          'B13',
          'A26',
          'C22',
          'C6',
          'C35',
          'D18',
          'C37',
          'B7',
          'C36',
          'B29',
          'C33',
          'B50',
          'D22',
          'C17',
          'D34',
          'A34',
          'A50',
          'A13',
          'C14',
          'A57',
          'C44',
          'A24',
          'C8',
          'A35',
          'B33',
          'D41',
          'B36',
          'C40',
          'D51',
          'D35',
          'A26',
          'B37',
          'C22',
          'B45',
          'D33',
          'A36',
          'C27',
          'A22',
          'D11',
          'B52',
          'C55',
          'D60',
          'A38',
          'D15',
          'B46',
          'A49',
          'C41',
          'B26',
          'B7',
          'D51',
          'B42',
          'A2',
          'D6',
          'A11',
          'B19',
          'C4',
          'C37',
          'D3',
          'D57',
          'A10',
          'D7',
          'A29',
          'B18',
          'A25',
          'B39',
          'C50',
          'D13',
          'C47',
          'A21',
          'C13',
          'D38',
          'C30',
          'D17',
          'A8',
          'B20',
          'C42',
          'D16',
          'C41',
          'B34',
          'A53',
          'D54',
          'B28',
          'D27',
          'C28',
          'B57',
          'D14',
          'C3',
          'D25',
          'C34',
          'B17',
          'D47',
          'A56',
          'A41',
          'C48',
          'B53',
          'A49',
          'D59',
          'A52',
          'B39',
          'C37',
          'A59',
          'B31',
          'C46',
          'A51',
          'C16',
          'B37',
          'A4',
          'B11',
          'D8',
          'A8',
          'B33',
          'A3',
          'D13',
          'C23',
          'D18',
          'A45',
          'C19',
          'D30',
          'A22',
          'D45',
          'B44',
          'D35',
          'C42',
          'B22',
          'A44',
          'B55',
          'A24',
          'D10',
          'A47',
          'C18',
          'D5',
          'A41',
          'D20',
          'B18',
          'C45',
          'B59',
          'C22',
          'B3',
          'A33',
          'C51',
          'A39',
          'C50',
          'B56',
          'C32',
          'A4',
          'D44',
          'A28',
          'B32',
          'C43',
          'B5',
          'C4',
          'D22',
          'A3',
          'A24',
          'A1',
          'D31',
          'D59',
          'B36',
          'A34',
          'D27',
          'C20',
          'A43',
          'B23',
          'A12',
          'A54',
          'B13',
          'C15',
          'D39',
          'D55',
          'A52',
          'B41',
          'C32',
          'D26',
          'B48',
          'C34',
          'A45',
          'C3',
          'C60',
          'D38',
          'B19',
          'A25',
          'D28',
          'C27',
          'B5',
          'D50',
          'B9',
          'B51',
          'C4',
          'B23',
          'D33',
          'B34',
          'C29',
          'A21',
          'B56',
          'C31',
          'D5',
          'A55',
          'C15',
          'B20',
          'C14',
          'B47',
          'C35',
          'B14',
          'D41',
          'C11',
          'D6',
          'C8',
          'B57',
          'C24',
          'A58',
          'C45',
          'A34',
          'C34',
          'B39',
          'C25',
          'A41',
          'C47',
          'A42',
          'C40',
          'C1',
          'A47',
          'D40',
          'B17',
          'C59',
          'A28',
          'D25',
          'A35',
          'D13',
          'B47',
          'A37',
          'D1',
          'A22',
          'B49',
          'C53',
          'A32',
          'D47',
          'C31',
          'C4',
          'B8',
          'C25',
          'B32',
          'C6',
          'A31',
          'B12',
          'D46',
          'B41',
          'C42',
          'A24',
          'B45',
          'D59',
          'D34',
          'B22',
          'C12',
          'B4',
          'C7',
          'B21',
          'A45',
          'C32',
          'B29',
          'A40',
          'D41',
          'B40',
          'A1',
          'D52',
          'B2',
          'C39',
          'B28',
          'D18',
          'C50',
          'A50',
          'B14',
          'D57',
          'A31',
          'B36',
          'D26',
          'D11',
          'C37',
          'D24',
          'C44',
          'A58',
          'D21',
          'B51',
          'C59',
          'A30',
          'B15',
          'C1',
          'D29',
          'C20',
          'A44',
          'D23',
          'B11',
          'C19',
          'B5',
          'B57',
          'D13',
          'C21',
          'A19',
          'A3',
          'A55',
          'A1',
          'A25',
          'C12',
          'B42',
          'D39',
          'B8',
          'C36',
          'B10',
          'C23',
          'D2',
          'A14',
          'C28',
          'B58',
          'C25',
          'D28',
          'A28',
          'B39',
          'D60',
          'D44',
          'B7',
          'C27',
          'A39',
          'C49',
          'B46',
          'D24',
          'B48',
          'C30',
          'B20',
          'D56',
          'A20',
          'D10',
          'A8',
          'C32',
          'B19',
          'D36',
          'C16',
          'D37',
          'A16',
          'B54',
          'C41',
          'A36',
          'B35',
          'C4',
          'B57',
          'C1',
          'D6',
          'A50',
          'C49',
          'B6',
          'C38',
          'A51',
          'C45',
          'A56',
          'C10',
          'D42',
          'A33',
          'C56',
          'B29',
          'D27',
          'A49',
          'D14',
          'A17',
          'D31',
          'C57',
          'D55',
          'D10',
          'A39',
          'B30',
          'C14',
          'D19',
          'B25',
          'C35',
          'B31',
          'D11',
          'A59',
          'C9',
          'B42',
          'C38',
          'A53',
          'D33',
          'C19',
          'B15',
          'D30',
          'C18',
          'A48',
          'C8',
          'A27',
          'D34',
          'B44',
          'C50',
          'A33',
          'C48',
          'D4',
          'B47',
          'C4',
          'B12',
          'D58',
          'A19',
          'C56',
          'B17',
          'B36',
          'C52',
          'B38',
          'C28',
          'A54',
          'A6',
          'D2',
          'C21',
          'D18',
          'C34',
          'B52',
          'D8',
          'D59',
          'B9',
          'A13',
          'B19',
          'C24',
          'B43',
          'C6',
          'B45',
          'D56',
          'D40',
          'C50',
          'B4',
          'C37',
          'A3',
          'B15',
          'D54',
          'A25',
          'B40',
          'C51',
          'B41',
          'D17',
          'A4',
          'B50',
          'C26',
          'B54',
          'B8',
          'A52',
          'D44',
          'B18',
          'D39',
          'C13',
          'B34',
          'C36',
          'A58',
          'D47',
          'C48',
          'A50',
          'D52',
          'A34',
          'B38',
          'A26',
          'C54',
          'B26',
          'C53',
          'A38',
          'C4',
          'A27',
          'B58',
          'D33',
          'D10',
          'B47',
          'D32',
          'B25',
          'D43',
          'B11',
          'A10',
          'C21',
          'D4',
          'C47',
          'D49',
          'B23',
          'A28',
          'C54',
          'B29',
          'C28',
          'C5',
          'C36',
          'B49',
          'D16',
          'D31',
          'B28',
          'A41',
          'D46',
          'B4',
          'A33',
          'C15',
          'B11',
          'C7',
          'B38',
          'D53',
          'B17',
          'A48',
          'D56',
          'A60',
          'C31',
          'B57',
          'A40',
          'C27',
          'B44',
          'D38',
          'D8',
          'C23',
          'A46',
          'C43',
          'B14',
          'A8',
          'A23',
          'C19',
          'B8',
          'A32',
          'C52',
          'D37',
          'B31',
          'D26',
          'C21',
          'A22',
          'D35',
          'A42',
          'C46',
          'B55',
          'A11',
          'B39',
          'A20',
          'C56',
          'D17',
          'C24',
          'D5',
          'C42',
          'A57',
          'A35',
          'B35',
          'A12',
          'C29',
          'B27',
          'D28',
          'B20',
          'C40',
          'B41',
          'A17',
          'C4',
          'A39',
          'D23',
          'A55',
          'D58',
          'C41',
          'D44',
          'D6',
          'D56',
          'B47',
          'C42',
          'B5',
          'C9',
          'A20',
          'D47',
          'A49',
          'B49',
          'A14',
          'B3',
          'C44',
          'D12',
          'A29',
          'C28',
          'B31',
          'A55',
          'D26',
          'B45',
          'A1',
          'D14',
          'C27',
          'B52',
          'D20',
          'B20',
          'D44',
          'C37',
          'B21',
          'C36',
          'A7',
          'D48',
          'B40',
          'D40',
          'A27',
          'B36',
          'A30',
          'C17',
          'B8',
          'C1',
          'D30',
          'A35',
          'A59',
          'C19',
          'B37',
          'C15',
          'D2',
          'D58',
          'A44',
          'C26',
          'B3',
          'D4',
          'D57',
          'C13',
          'D19',
          'A22',
          'C53',
          'A47',
          'B18',
          'C10',
          'D20',
          'B23',
          'C60',
          'A25',
          'D59',
          'B54',
          'C51',
          'D21',
          'A60',
          'D48',
          'B13',
          'A10',
          'B39',
          'C59',
          'D15',
          'C38',
          'A37',
          'B34',
          'C24',
          'D28',
          'A11',
          'B56',
          'C18',
          'D33',
          'C43',
          'B40',
          'C47',
          'A56',
          'D12',
          'A7',
          'B30',
          'B4',
          'D36',
          'B42',
          'C33',
          'D24',
          'A2',
          'B22',
          'C29',
          'A28',
          'B53',
          'C44',
          'A34',
          'D16',
          'C38',
          'A54',
          'C27',
          'B33',
          'D9',
          'C46',
          'A52',
          'B13',
          'C11',
          'B38',
          'A5',
          'D35',
          'C17',
          'B58',
          'A25',
          'B24',
          'D57',
          'D34',
          'B46',
          'C19',
          'B16',
          'C52',
          'B28',
          'D42',
          'C50',
          'D60',
          'A41',
          'B32',
          'A39',
          'C16',
          'D7',
          'C40',
          'B59',
          'C4',
          'D22',
          'C38',
          'B44',
          'C35',
          'B26',
          'C2',
          'D1',
          'C57',
          'B1',
          'D55',
          'D39',
          'B30',
          'C44',
          'B17',
          'C43',
          'A35',
          'C28',
          'B48',
          'D9',
          'B2',
          'A26',
          'B15',
          'D46',
          'C15',
          'A58',
          'D57',
          'B43',
          'C58',
          'A31',
          'C1',
          'B16',
          'D36',
          'C14',
          'B24',
          'A43',
          'B25',
          'A23',
          'B60',
          'A7',
          'B14',
          'A49',
          'C51',
          'D25',
          'B45',
          'A21',
          'C28',
          'D10',
          'D32',
          'C20',
          'A57',
          'C32',
          'A9',
          'B26',
          'D43',
          'A25',
          'C11',
          'D47',
          'A35',
          'A60',
          'D18',
          'B40',
          'A33',
          'D29',
          'C27',
          'B53',
          'D9',
          'A42',
          'D19',
          'B27',
          'C53',
          'A30',
          'D11',
          'D26',
          'C29',
          'B45',
          'C48',
          'B34',
          'A23',
          'D12',
          'A15',
          'D47',
          'B5',
          'B26',
          'C30',
          'A18',
          'A2',
          'B59',
          'C25',
          'D31',
          'A46',
          'B19',
          'A10',
          'C10',
          'A40',
          'C8',
          'B25',
          'D36',
          'D59',
          'A3',
          'C33',
          'C50',
          'D37',
          'C14',
          'B12',
          'A31',
          'B43',
          'C39',
          'B30',
          'D12',
          'C25',
          'B10',
          'C46',
          'D21',
          'A20',
          'C30',
          'B42',
          'D17',
          'C27',
          'B55',
          'C26',
          'D24',
          'A21',
          'D51',
          'A57',
          'D31',
          'C21',
          'A29',
          'B20',
          'D42',
          'B29',
          'A18',
          'C22',
          'A35',
          'D36',
          'A41',
          'D50',
          'B26',
          'A42',
          'B53',
          'C53',
          'B33',
          'C52',
          'B38',
          'D26',
          'B40',
          'C41',
          'A51',
          'B10',
          'C8',
          'A49',
          'C5',
          'D21',
          'B14',
          'A44',
          'B22',
          'C58',
          'B24',
          'C55',
          'A11',
          'C32',
          'B35',
          'A32',
          'B9',
          'C12',
          'B59',
          'C53',
          'D2',
          'C43',
          'D39',
          'C8',
          'B29',
          'C49',
          'B54',
          'C34',
          'B40',
          'C3',
          'D25',
          'C44',
          'B39',
          'A9',
          'D35',
          'B31',
          'D30',
          'B47',
          'A19',
          'B50',
          'D16',
          'C19',
          'D20',
          'A12',
          'B52',
          'C29',
          'A47',
          'C12',
          'A37',
          'C54',
          'B1',
          'D40',
          'C13',
          'A22',
          'B37',
          'D37',
          'A7',
          'C33',
          'B6',
          'C21',
          'D14',
          'B53',
          'A9',
          'D32',
          'C1',
          'B23',
          'A27',
          'B50',
          'C42',
          'A35',
          'D54',
          'B47',
          'C38',
          'B60',
          'C26',
          'D3',
          'C25',
          'D4',
          'C14',
          'B37',
          'B2',
          'C34',
          'B14',
          'C8',
          'D20',
          'D35',
          'C57',
          'B19',
          'D59',
          'D43',
          'A59',
          'C27',
          'B36',
          'D58',
          'A51',
          'D25',
          'A11',
          'B10',
          'D45',
          'C25',
          'A22',
          'D15',
          'A6',
          'B14',
          'C31',
          'B17',
          'A30',
          'B46',
          'D33',
          'A28',
          'D30',
          'B7',
          'A33',
          'C46',
          'A53',
          'C35',
          'B36',
          'A24',
          'C55',
          'B48',
          'D23',
          'B49',
          'A42',
          'C37',
          'D25',
          'B47',
          'A16',
          'B60',
          'C4',
          'D5',
          'D57',
          'D36',
          'A1',
          'C42',
          'B23',
          'C10',
          'B24',
          'D39',
          'C35',
          'D15',
          'C58',
          'A18',
          'B58',
          'D14',
          'A7',
          'B20',
          'A3',
          'B16',
          'C6',
          'B46',
          'A47',
          'C16',
          'D33',
          'C1',
          'B34',
          'D27',
          'C2',
          'A58',
          'B57',
          'C39',
          'A36',
          'C18',
          'B3',
          'D8',
          'A26',
          'D50',
          'B22',
          'C25',
          'D57',
          'A6',
          'D23',
          'C57',
          'B47',
          'A9',
          'A50',
          'B45',
          'B26',
          'A23',
          'B27',
          'D46',
          'A53',
          'C22',
          'D12',
          'C39',
          'A2',
          'B29',
          'D41',
          'A36',
          'B52',
          'A3',
          'C13',
          'B8',
          'A26',
          'D21',
          'B17',
          'C12',
          'B55',
          'A32',
          'C51',
          'D8',
          'C11',
          'B20',
          'A25',
          'D51',
          'C37',
          'B30',
          'C54',
          'A29',
          'C20',
          'D18',
          'C15',
          'B21',
          'C31',
          'A17',
          'B51',
          'C56',
          'D43',
          'B39',
          'C22',
          'B56',
          'C41',
          'A57',
          'B12',
          'D28',
          'C21',
          'C5',
          'A42',
          'C32',
          'B6',
          'D6',
          'A22',
          'B38',
          'D49',
          'B24',
          'D33',
          'A41',
          'A56',
          'A1',
          'B37',
          'A52',
          'A13',
          'D13',
          'A29',
          'B12',
          'C37',
          'A60',
          'A34',
          'B33',
          'C44',
          'C59',
          'D38',
          'B41',
          'A5',
          'D50',
          'A42',
          'B30',
          'C29',
          'B43',
          'D36',
          'D52',
          'C34',
          'D27',
          'C26',
          'A21',
          'B28',
          'C9',
          'B59',
          'C6',
          'B21',
          'A25',
          'D34',
          'A48',
          'D49',
          'A52',
          'D24',
          'C4',
          'C54',
          'C33',
          'B46',
          'D17',
          'B26',
          'C41',
          'A39',
          'B51',
          'D16',
          'B55',
          'C49',
          'A40',
          'C36',
          'D3',
          'A32',
          'B11',
          'C18',
          'D6',
          'D54',
          'D10',
          'A25',
          'C55',
          'A20',
          'B44',
          'B28',
          'C30',
          'B54',
          'C24',
          'D7',
          'A14',
          'D55',
          'B23',
          'A6',
          'D29',
          'A49',
          'B15',
          'D41',
          'A51',
          'C3',
          'A24',
          'D37',
          'B1',
          'C53',
          'C5',
          'A11',
          'D2',
          'A41',
          'D59',
          'C56',
          'D19',
          'C11',
          'C26',
          'B4',
          'B34',
          'D53',
          'A21',
          'C16',
          'B19',
          'C45',
          'B21',
          'D58',
          'A29',
          'B36',
          'A17',
          'D40',
          'B33',
          'C38',
          'A55',
          'D44',
          'A7',
          'B51',
          'C54',
          'C1',
          'D1',
          'D59',
          'B28',
          'D46',
          'C49',
          'B41',
          'D37',
          'D8',
          'D34',
          'A39',
          'C24',
          'D9',
          'C40',
          'B3',
          'C29',
          'B55',
          'C48',
          'B1',
          'C46',
          'A43',
          'B16',
          'A17',
          'B18',
          'C38',
          'D47',
          'C9',
          'C34',
          'D23',
          'C30',
          'B36',
          'C7',
          'B38',
          'D44',
          'A42',
          'B45',
          'D35',
          'B21',
          'C27',
          'B56',
          'C20',
          'A46',
          'C42',
          'B35',
          'C26',
          'B41',
          'A50',
          'D31',
          'A14',
          'C2',
          'D28',
          'B47',
          'C32',
          'B13',
          'A9',
          'B19',
          'A35',
          'C50',
          'C8',
          'D21',
          'A42',
          'D53',
          'B3',
          'D25',
          'B40',
          'D39',
          'B33',
          'C17',
          'B7',
          'D42',
          'B39',
          'D27',
          'C54',
          'B23',
          'C31',
          'B25',
          'A3',
          'C5',
          'B38',
          'C35',
          'B5',
          'D46',
          'B24',
          'C43',
          'A18',
          'D21',
          'B49',
          'D5',
          'A31',
          'B13',
          'A51',
          'C40',
          'B16',
          'C59',
          'D45',
          'C54',
          'D36',
          'B44',
          'C45',
          'B1',
          'D54',
          'A30',
          'C52',
          'A48',
          'D32',
          'B48',
          'C28',
          'A38',
          'C53',
          'A26',
          'B17',
          'D52',
          'D10',
          'B54',
          'C38',
          'B19',
          'A8',
          'A23',
          'D60',
          'A27',
          'D4',
          'C19',
          'A60',
          'A29',
          'B46',
          'D33',
          'A10',
          'D43',
          'B28',
          'C27',
          'B30',
          'C21',
          'B39',
          'C5',
          'B21',
          'D28',
          'B33',
          'A25',
          'D31',
          'A41',
          'D55',
          'A17',
          'C23',
          'B29',
          'A43',
          'C8',
          'B6',
          'C44',
          'B58',
          'C15',
          'B14',
          'C48',
          'A50',
          'C42',
          'B10',
          'C29',
          'A13',
          'B45',
          'C43',
          'A38',
          'B30',
          'D41',
          'D7',
          'D53',
          'C33',
          'C10',
          'B26',
          'C7',
          'D9',
          'C4',
          'D48',
          'A30',
          'D19',
          'B39',
          'C20',
          'D3',
          'A7',
          'A24',
          'B37',
          'C41',
          'D21',
          'A22',
          'D40',
          'A32',
          'D18',
          'A23',
          'D37',
          'B42',
          'A1',
          'B53',
          'C31',
          'B27',
          'C45',
          'B28',
          'D17',
          'C22',
          'D47',
          'A46',
          'B52',
          'C40',
          'B5',
          'A47',
          'D50',
          'B19',
          'C19',
          'D28',
          'B59',
          'A49',
          'C50',
          'B25',
          'C25',
          'A26',
          'C21',
          'B9',
          'D34',
          'C18',
          'B56',
          'C17',
          'B10',
          'A55',
          'C46',
          'B18',
          'A43',
          'D13',
          'B29',
          'C5',
          'D21',
          'C35',
          'B33',
          'A39',
          'C50',
          'D43',
          'A17',
          'B59',
          'C21',
          'A35',
          'C49',
          'A54',
          'D20',
          'A27',
          'B32',
          'D42',
          'A30',
          'C13',
          'B14',
          'A29',
          'A7',
          'B21',
          'D22',
          'A13',
          'C3',
          'D25',
          'C30',
          'A60',
          'A16',
          'C32',
          'B50',
          'C57',
          'D3',
          'A34',
          'B49',
          'B2',
          'D30',
          'A4',
          'C37',
          'B22',
          'A25',
          'B35',
          'C21',
          'B52',
          'D19',
          'A49',
          'C12',
          'D5',
          'C30',
          'A27',
          'D55',
          'B31',
          'C23',
          'B33',
          'D38',
          'A58',
          'C39',
          'B11',
          'C25',
          'A50',
          'C47',
          'D14',
          'B3',
          'C43',
          'D6',
          'B41',
          'C3',
          'D9',
          'C11',
          'B58',
          'C31',
          'A24',
          'B57',
          'A44',
          'B15',
          'C7',
          'B27',
          'C58',
          'A25',
          'C42',
          'D49',
          'B43',
          'C16',
          'D16',
          'D31',
          'D58',
          'B42',
          'A18',
          'D30',
          'C11',
          'A36',
          'B32',
          'C40',
          'B8',
          'D19',
          'C9',
          'B60',
          'A33',
          'C52',
          'D5',
          'A23',
          'B37',
          'A16',
          'D11',
          'C28',
          'A19',
          'A2',
          'A48',
          'D45',
          'A7',
          'D34',
          'B45',
          'A11',
          'C44',
          'D47',
          'A51',
          'B39',
          'D41',
          'D57',
          'B12',
          'A45',
          'B21',
          'A29',
          'D52',
          'B14',
          'A4',
          'D42',
          'A50',
          'B11',
          'C34',
          'B23',
          'D44',
          'C26',
          'B42',
          'C51',
          'B20',
          'C60',
          'B58',
          'D14',
          'C42',
          'C4',
          'A8',
          'B47',
          'D24',
          'A12',
          'B2',
          'C13',
          'D46',
          'C47',
          'A45',
          'D52',
          'B22',
          'C46',
          'A56',
          'D41',
          'D26',
          'C28',
          'C9',
          'B20',
          'C39',
          'D17',
          'A44',
          'B23',
          'B56',
          'C48',
          'D1',
          'C29',
          'B24',
          'A25',
          'C55',
          'A48',
          'B51',
          'A6',
          'C6',
          'D4',
          'A24',
          'D59',
          'C37',
          'B40',
          'C5',
          'D39',
          'A33',
          'D8',
        ],
      },
    ],
  },
  {
    title: 'Salvador Dalí',
    id: 'salvador-dalí',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Salvador%20Dal%C3%AD.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'B10',
          'B26',
          'B8',
          'A49',
          'B4',
          'A43',
          'B6',
          'B27',
          'B3',
          'A47',
          'B22',
          'A45',
          'A1',
          'A50',
          'A5',
          'A58',
          'A4',
          'A59',
          'B21',
          'A48',
          'A6',
          'A57',
          'A8',
          'A54',
          'A7',
          'A55',
          'A3',
          'A56',
          'B13',
          'A43',
          'B23',
          'B8',
          'A40',
          'B16',
          'A36',
          'B18',
          'B3',
          'A5',
          'B4',
          'B29',
          'B1',
          'A6',
          'B2',
          'B28',
          'A54',
          'A4',
          'A60',
          'A7',
          'A47',
          'A1',
          'A51',
          'A9',
          'A53',
          'A8',
          'A59',
          'B30',
          'A52',
          'A10',
          'C7',
          'C23',
          'C6',
          'C22',
          'A45',
          'B24',
          'B4',
          'A5',
          'B5',
          'B20',
          'A40',
          'C16',
          'A41',
          'B15',
          'A37',
          'B17',
          'A34',
          'B18',
          'A49',
          'A6',
          'B3',
          'A46',
          'A8',
          'C4',
          'A9',
          'A56',
          'A7',
          'A57',
          'A3',
          'A58',
          'B27',
          'A55',
          'A10',
          'C6',
          'C22',
          'A35',
          'B16',
          'A51',
          'C49',
          'C34',
          'C54',
          'C31',
          'C50',
          'C29',
          'C55',
          'C30',
          'C45',
          'A43',
          'C19',
          'A44',
          'B11',
          'A38',
          'C14',
          'A39',
          'C60',
          'A48',
          'B28',
          'B13',
          'A42',
          'C20',
          'A46',
          'B9',
          'A13',
          'C31',
          'C52',
          'C37',
          'C53',
          'C29',
          'C56',
          'A40',
          'C47',
          'C32',
          'C49',
          'A49',
          'B27',
          'B5',
          'A5',
          'B6',
          'A8',
          'C6',
          'A11',
          'C9',
          'A10',
          'C8',
          'A9',
          'B3',
          'A6',
          'A57',
          'B29',
          'A53',
          'C50',
          'C30',
          'C54',
          'C36',
          'A19',
          'C34',
          'A20',
          'C38',
          'A23',
          'C37',
          'A18',
          'C35',
          'A21',
          'B11',
          'A27',
          'B13',
          'A38',
          'C17',
          'A44',
          'C16',
          'A45',
          'C21',
          'A34',
          'B19',
          'A36',
          'C46',
          'A41',
          'B6',
          'A5',
          'B7',
          'B26',
          'A60',
          'A7',
          'C2',
          'A8',
          'A46',
          'C19',
          'A47',
          'B29',
          'A54',
          'C50',
          'A55',
          'C49',
          'A57',
          'C43',
          'A58',
          'A3',
          'B5',
          'A2',
          'A38',
          'C13',
          'A13',
          'C5',
          'C54',
          'C7',
          'C23',
          'A44',
          'A9',
          'A52',
          'A1',
          'A53',
          'A8',
          'C3',
          'C55',
          'C6',
          'A7',
          'B1',
          'A4',
          'A50',
          'A11',
          'A51',
          'B31',
          'A49',
          'C22',
          'A43',
          'C20',
          'A33',
          'C19',
          'A48',
          'C18',
          'A42',
          'B24',
          'B9',
          'A39',
          'C58',
          'C29',
          'C54',
          'C39',
          'A22',
          'C34',
          'C53',
          'C8',
          'A11',
          'C5',
          'C25',
          'D47',
          'C23',
          'A36',
          'C11',
          'A12',
          'B4',
          'A6',
          'D60',
          'C55',
          'C4',
          'A13',
          'C6',
          'C54',
          'D56',
          'C56',
          'C29',
          'C59',
          'B3',
          'B25',
          'A59',
          'B28',
          'A58',
          'A9',
          'A45',
          'C15',
          'A37',
          'C46',
          'A42',
          'C14',
          'A47',
          'A7',
          'B2',
          'B20',
          'A34',
          'C20',
          'A41',
          'C19',
          'A49',
          'C18',
          'A43',
          'C44',
          'A60',
          'C43',
          'D51',
          'C41',
          'A55',
          'B28',
          'A46',
          'B29',
          'A52',
          'A9',
          'C3',
          'A6',
          'B4',
          'A1',
          'B5',
          'C60',
          'A40',
          'C58',
          'A41',
          'C56',
          'C5',
          'A8',
          'A59',
          'B26',
          'A56',
          'C50',
          'C8',
          'C54',
          'C33',
          'A16',
          'B8',
          'B24',
          'B1',
          'C49',
          'C9',
          'C24',
          'A47',
          'C13',
          'A39',
          'C17',
          'A31',
          'B16',
          'A30',
          'C15',
          'A46',
          'B30',
          'A50',
          'A10',
          'A56',
          'C46',
          'C31',
          'C53',
          'D57',
          'C55',
          'D55',
          'C54',
          'D58',
          'A6',
          'A53',
          'B34',
          'A51',
          'A11',
          'C7',
          'C51',
          'A52',
          'C41',
          'A58',
          'C49',
          'C34',
          'D49',
          'C32',
          'A15',
          'B8',
          'A8',
          'C1',
          'A7',
          'B2',
          'A2',
          'A38',
          'B14',
          'A54',
          'B30',
          'A45',
          'C20',
          'A47',
          'C12',
          'A39',
          'C11',
          'C53',
          'C6',
          'A41',
          'C45',
          'A44',
          'C9',
          'C46',
          'A57',
          'C42',
          'A26',
          'B13',
          'A25',
          'C41',
          'C7',
          'A36',
          'C22',
          'A8',
          'A59',
          'A3',
          'B6',
          'B28',
          'A55',
          'B33',
          'A54',
          'A11',
          'B9',
          'A7',
          'C5',
          'A41',
          'C21',
          'A32',
          'C18',
          'A50',
          'C17',
          'A43',
          'C58',
          'A44',
          'C8',
          'C47',
          'A35',
          'C51',
          'C6',
          'C57',
          'C4',
          'A12',
          'A49',
          'C15',
          'A38',
          'A1',
          'B6',
          'B23',
          'A47',
          'B27',
          'A56',
          'A9',
          'C10',
          'A40',
          'C7',
          'C48',
          'C30',
          'C55',
          'C5',
          'A42',
          'C21',
          'D45',
          'C24',
          'D46',
          'C20',
          'A39',
          'C46',
          'B1',
          'B25',
          'A41',
          'C43',
          'C9',
          'C40',
          'A58',
          'B37',
          'A55',
          'C47',
          'A38',
          'C59',
          'B5',
          'B23',
          'B2',
          'C60',
          'C29',
          'C57',
          'C2',
          'A12',
          'C7',
          'A44',
          'C44',
          'A56',
          'A9',
          'C22',
          'A34',
          'C6',
          'A36',
          'C16',
          'A51',
          'B29',
          'A60',
          'C42',
          'D50',
          'C38',
          'D51',
          'C37',
          'A24',
          'B13',
          'A23',
          'C35',
          'D48',
          'C33',
          'C7',
          'A45',
          'B31',
          'A58',
          'A9',
          'C2',
          'A6',
          'A40',
          'C46',
          'A46',
          'C23',
          'A47',
          'C11',
          'A49',
          'B32',
          'A57',
          'C41',
          'A60',
          'B27',
          'B8',
          'A14',
          'C7',
          'C53',
          'D59',
          'A7',
          'B3',
          'A2',
          'B6',
          'C60',
          'A50',
          'C14',
          'A46',
          'C17',
          'A32',
          'C48',
          'A34',
          'C19',
          'A37',
          'C18',
          'A45',
          'C44',
          'A59',
          'B40',
          'D56',
          'C55',
          'D54',
          'C51',
          'A54',
          'B35',
          'A53',
          'B36',
          'A52',
          'C49',
          'C6',
          'A40',
          'C15',
          'A51',
          'A11',
          'A55',
          'C45',
          'C8',
          'A35',
          'C21',
          'A44',
          'C5',
          'A14',
          'C31',
          'D49',
          'C39',
          'C10',
          'A47',
          'C7',
          'A6',
          'B5',
          'B28',
          'A53',
          'C42',
          'A38',
          'C59',
          'B6',
          'B21',
          'A34',
          'D47',
          'A35',
          'C50',
          'A37',
          'A3',
          'A60',
          'A8',
          'D60',
          'C54',
          'C4',
          'A40',
          'C12',
          'A46',
          'C16',
          'A48',
          'B33',
          'A47',
          'C6',
          'C42',
          'A54',
          'C48',
          'A39',
          'C57',
          'A43',
          'C46',
          'C7',
          'C23',
          'A37',
          'C17',
          'A45',
          'C43',
          'A59',
          'B30',
          'B3',
          'B42',
          'B2',
          'C49',
          'D52',
          'B33',
          'A57',
          'C45',
          'A58',
          'C39',
          'A35',
          'C21',
          'A33',
          'D46',
          'C27',
          'D44',
          'C25',
          'C5',
          'A37',
          'A3',
          'B1',
          'B25',
          'B10',
          'A7',
          'C22',
          'D47',
          'C36',
          'A20',
          'D39',
          'A19',
          'C33',
          'C51',
          'A34',
          'C9',
          'A48',
          'C12',
          'A29',
          'D44',
          'A30',
          'B17',
          'A35',
          'C7',
          'B41',
          'C6',
          'A42',
          'C19',
          'A39',
          'C8',
          'C41',
          'A60',
          'B39',
          'D55',
          'B38',
          'D54',
          'B35',
          'A56',
          'C50',
          'A38',
          'C49',
          'A53',
          'B37',
          'D56',
          'A6',
          'C1',
          'A12',
          'A49',
          'C10',
          'A10',
          'A51',
          'C14',
          'A43',
          'C13',
          'A52',
          'A9',
          'A58',
          'B31',
          'A50',
          'C50',
          'C7',
          'C56',
          'D29',
          'C57',
          'C28',
          'C55',
          'D58',
          'B40',
          'D55',
          'B37',
          'D53',
          'B38',
          'A57',
          'C40',
          'A60',
          'B15',
          'A37',
          'C16',
          'D16',
          'C15',
          'A39',
          'C3',
          'C58',
          'A45',
          'B32',
          'B2',
          'B21',
          'A33',
          'C52',
          'C30',
          'D49',
          'A38',
          'C21',
          'D18',
          'C20',
          'A32',
          'D45',
          'A31',
          'C16',
          'A50',
          'C9',
          'B42',
          'C6',
          'A48',
          'C23',
          'A10',
          'C5',
          'C53',
          'D53',
          'C56',
          'C3',
          'A40',
          'C44',
          'B1',
          'B26',
          'B11',
          'A13',
          'C12',
          'A43',
          'C10',
          'C37',
          'A46',
          'C43',
          'A27',
          'D43',
          'A28',
          'D2',
          'A18',
          'D1',
          'A17',
          'C38',
          'C9',
          'C52',
          'C6',
          'A49',
          'B34',
          'A57',
          'B40',
          'D54',
          'B33',
          'B1',
          'B43',
          'D55',
          'C57',
          'B3',
          'B28',
          'B8',
          'A12',
          'C3',
          'A7',
          'D58',
          'B45',
          'B6',
          'C59',
          'A39',
          'C47',
          'A56',
          'C42',
          'A59',
          'B27',
          'A43',
          'D53',
          'B40',
          'C8',
          'C44',
          'D50',
          'B29',
          'B5',
          'C57',
          'D59',
          'A6',
          'A54',
          'C58',
          'D58',
          'B41',
          'C5',
          'A49',
          'A12',
          'C10',
          'C35',
          'A24',
          'D41',
          'A45',
          'D40',
          'A20',
          'D43',
          'A53',
          'C2',
          'C54',
          'C32',
          'A16',
          'B60',
          'A23',
          'D6',
          'A33',
          'C22',
          'A50',
          'B35',
          'D52',
          'C46',
          'A54',
          'B34',
          'D56',
          'B36',
          'D54',
          'A44',
          'C20',
          'A48',
          'A14',
          'C6',
          'C47',
          'A41',
          'C15',
          'A42',
          'C4',
          'A45',
          'C11',
          'B42',
          'C5',
          'A50',
          'D42',
          'A25',
          'D2',
          'A27',
          'C42',
          'C10',
          'A28',
          'C9',
          'C24',
          'A36',
          'C51',
          'C12',
          'A52',
          'B30',
          'A51',
          'D60',
          'B46',
          'D59',
          'B39',
          'A58',
          'B32',
          'A50',
          'A12',
          'B58',
          'A21',
          'D44',
          'C22',
          'A40',
          'C17',
          'A35',
          'C38',
          'A43',
          'C8',
          'A46',
          'D39',
          'A47',
          'B34',
          'D55',
          'C56',
          'D28',
          'C55',
          'C1',
          'A52',
          'C10',
          'B41',
          'D53',
          'B42',
          'C14',
          'A48',
          'D38',
          'A42',
          'C45',
          'C30',
          'C54',
          'C3',
          'A54',
          'B26',
          'B7',
          'A2',
          'C29',
          'A3',
          'A37',
          'C60',
          'B4',
          'B24',
          'B9',
          'A8',
          'C21',
          'A36',
          'D48',
          'C40',
          'A60',
          'B29',
          'A55',
          'B39',
          'D54',
          'B43',
          'C9',
          'A38',
          'C18',
          'D17',
          'C17',
          'A41',
          'D51',
          'B28',
          'C6',
          'A43',
          'B31',
          'B3',
          'A2',
          'A40',
          'C21',
          'A30',
          'D3',
          'A27',
          'C41',
          'A59',
          'B36',
          'D60',
          'A9',
          'C7',
          'A48',
          'D57',
          'B33',
          'D51',
          'B35',
          'A49',
          'D41',
          'A25',
          'D7',
          'A35',
          'C23',
          'D19',
          'C54',
          'C10',
          'A37',
          'C48',
          'C12',
          'A11',
          'D59',
          'A31',
          'D4',
          'A21',
          'D40',
          'A44',
          'B30',
          'A40',
          'C8',
          'C40',
          'B1',
          'A8',
          'C9',
          'B39',
          'D57',
          'A5',
          'C21',
          'A46',
          'C7',
          'A57',
          'C44',
          'A51',
          'D42',
          'A26',
          'D46',
          'A32',
          'C49',
          'C11',
          'C34',
          'A23',
          'B14',
          'B29',
          'C6',
          'B48',
          'B9',
          'A2',
          'B10',
          'B47',
          'A1',
          'C16',
          'A38',
          'D10',
          'A36',
          'C19',
          'A30',
          'C18',
          'A52',
          'C9',
          'B45',
          'D57',
          'B24',
          'A10',
          'C22',
          'A6',
          'D36',
          'A5',
          'D60',
          'B38',
          'A53',
          'C13',
          'D15',
          'C12',
          'B42',
          'C10',
          'B26',
          'B4',
          'B41',
          'A58',
          'C8',
          'B43',
          'C6',
          'B44',
          'C54',
          'D28',
          'C57',
          'A40',
          'D50',
          'B17',
          'A29',
          'D1',
          'A16',
          'B53',
          'A17',
          'D2',
          'A26',
          'D45',
          'A21',
          'D5',
          'A30',
          'C14',
          'A41',
          'C42',
          'A46',
          'D42',
          'A52',
          'C7',
          'C52',
          'C36',
          'A22',
          'D44',
          'A48',
          'C11',
          'A42',
          'C13',
          'A45',
          'D52',
          'B40',
          'C12',
          'C37',
          'A47',
          'D37',
          'A43',
          'B32',
          'D58',
          'A49',
          'D38',
          'A18',
          'C24',
          'A37',
          'C1',
          'C58',
          'C28',
          'C60',
          'C6',
          'A56',
          'B21',
          'B6',
          'C57',
          'D30',
          'C56',
          'A41',
          'C12',
          'B1',
          'B28',
          'C10',
          'D14',
          'A32',
          'D59',
          'C55',
          'C7',
          'B40',
          'C46',
          'C29',
          'A4',
          'B4',
          'B45',
          'C6',
          'B48',
          'C5',
          'A56',
          'B32',
          'D55',
          'A6',
          'D57',
          'B29',
          'A59',
          'B31',
          'A48',
          'A13',
          'C3',
          'B25',
          'A10',
          'A55',
          'C16',
          'A39',
          'C18',
          'A33',
          'C49',
          'C8',
          'B24',
          'C7',
          'B42',
          'D52',
          'A42',
          'C9',
          'A12',
          'B29',
          'D49',
          'B15',
          'D48',
          'A37',
          'C22',
          'A17',
          'C36',
          'A47',
          'B35',
          'C45',
          'B36',
          'D51',
          'A43',
          'C4',
          'B48',
          'A11',
          'B21',
          'D58',
          'B28',
          'C7',
          'B37',
          'A52',
          'C17',
          'A26',
          'D3',
          'A19',
          'D40',
          'A46',
          'C13',
          'C46',
          'A44',
          'C14',
          'A12',
          'D60',
          'B41',
          'D57',
          'B45',
          'C48',
          'A40',
          'D11',
          'A39',
          'C9',
          'A53',
          'B29',
          'D56',
          'A47',
          'C17',
          'B27',
          'B4',
          'B19',
          'A35',
          'D9',
          'A34',
          'C52',
          'C11',
          'A51',
          'D43',
          'A54',
          'D44',
          'A20',
          'C33',
          'C48',
          'B46',
          'C16',
          'B29',
          'D54',
          'B34',
          'D53',
          'B43',
          'B2',
          'B24',
          'D56',
          'C53',
          'C4',
          'A46',
          'C3',
          'B26',
          'A10',
          'C1',
          'C52',
          'C32',
          'A21',
          'B14',
          'C11',
          'B40',
          'D51',
          'A44',
          'C18',
          'A36',
          'A4',
          'C20',
          'A29',
          'B18',
          'D50',
          'C39',
          'A60',
          'C50',
          'A39',
          'C45',
          'C14',
          'A37',
          'C60',
          'B9',
          'D47',
          'B10',
          'C7',
          'B25',
          'C13',
          'A51',
          'C27',
          'A52',
          'B33',
          'D56',
          'B39',
          'D54',
          'A5',
          'D35',
          'A44',
          'C38',
          'A45',
          'C9',
          'A59',
          'D58',
          'B22',
          'A33',
          'C37',
          'B35',
          'A46',
          'D38',
          'A10',
          'C4',
          'A55',
          'C13',
          'B27',
          'C11',
          'B1',
          'D55',
          'B33',
          'A50',
          'D59',
          'A5',
          'A57',
          'B25',
          'C21',
          'A31',
          'C19',
          'A40',
          'C59',
          'B9',
          'C8',
          'D13',
          'A30',
          'D12',
          'C30',
          'C56',
          'D57',
          'B32',
          'A55',
          'D43',
          'A48',
          'D37',
          'A42',
          'C17',
          'B43',
          'C7',
          'B47',
          'C2',
          'B26',
          'A51',
          'C19',
          'A3',
          'A37',
          'C12',
          'C47',
          'B46',
          'A2',
          'B48',
          'A10',
          'C30',
          'C57',
          'D54',
          'A29',
          'D2',
          'A24',
          'B59',
          'A13',
          'D3',
          'A20',
          'D41',
          'A52',
          'C6',
          'B30',
          'C44',
          'B31',
          'A12',
          'C8',
          'B27',
          'D59',
          'B20',
          'B1',
          'C43',
          'A43',
          'C48',
          'A35',
          'C20',
          'A49',
          'C14',
          'B3',
          'C12',
          'B13',
          'B51',
          'A15',
          'C9',
          'B23',
          'A11',
          'C22',
          'D19',
          'C55',
          'D29',
          'C58',
          'C11',
          'B15',
          'A36',
          'C17',
          'B45',
          'C5',
          'B28',
          'D57',
          'B35',
          'D50',
          'B33',
          'A42',
          'C7',
          'B27',
          'A10',
          'A57',
          'D56',
          'B44',
          'D55',
          'A52',
          'C16',
          'A14',
          'D4',
          'A17',
          'D5',
          'A33',
          'C2',
          'A47',
          'D44',
          'A26',
          'C10',
          'A51',
          'C43',
          'B28',
          'D50',
          'B36',
          'A56',
          'B27',
          'D58',
          'B30',
          'D56',
          'B14',
          'A20',
          'B60',
          'A27',
          'C40',
          'C12',
          'C33',
          'A43',
          'C21',
          'A9',
          'C23',
          'A18',
          'D31',
          'C51',
          'C8',
          'B23',
          'B5',
          'C16',
          'B18',
          'C14',
          'A31',
          'C50',
          'C35',
          'A45',
          'B29',
          'C5',
          'C52',
          'A51',
          'B37',
          'C6',
          'B49',
          'A1',
          'A38',
          'C23',
          'D6',
          'A22',
          'B57',
          'A11',
          'D58',
          'B23',
          'A60',
          'B24',
          'A13',
          'B28',
          'C19',
          'B7',
          'C1',
          'B47',
          'C14',
          'D16',
          'C33',
          'A58',
          'D49',
          'C45',
          'A44',
          'C6',
          'B11',
          'D47',
          'A27',
          'D1',
          'A22',
          'D45',
          'A20',
          'B15',
          'C15',
          'A14',
          'C4',
          'A49',
          'C17',
          'A54',
          'C9',
          'B26',
          'A50',
          'C20',
          'A38',
          'C46',
          'B37',
          'D54',
          'B41',
          'D52',
          'B34',
          'C1',
          'C54',
          'C31',
          'B29',
          'A11',
          'C11',
          'B22',
          'C10',
          'D14',
          'C32',
          'C51',
          'A36',
          'C39',
          'A41',
          'C8',
          'B3',
          'B18',
          'C13',
          'A56',
          'B40',
          'C45',
          'B37',
          'D51',
          'B30',
          'D55',
          'B35',
          'C40',
          'A16',
          'D29',
          'C54',
          'B45',
          'D56',
          'B32',
          'D53',
          'B39',
          'C46',
          'A40',
          'C18',
          'A46',
          'D18',
          'C55',
          'D20',
          'C26',
          'A48',
          'D39',
          'A21',
          'B59',
          'A11',
          'B24',
          'C22',
          'A7',
          'B11',
          'C59',
          'C28',
          'D9',
          'A37',
          'D10',
          'A27',
          'C20',
          'B17',
          'C17',
          'D41',
          'A44',
          'C3',
          'B28',
          'A47',
          'C34',
          'B31',
          'A40',
          'C14',
          'A54',
          'D52',
          'B42',
          'C10',
          'B29',
          'C7',
          'D13',
          'A31',
          'D58',
          'A60',
          'B26',
          'C8',
          'A55',
          'D47',
          'B12',
          'A28',
          'C21',
          'A47',
          'D36',
          'A40',
          'C44',
          'A25',
          'C18',
          'A53',
          'D54',
          'B1',
          'C42',
          'D25',
          'C41',
          'C11',
          'B25',
          'C20',
          'D18',
          'C54',
          'D52',
          'B43',
          'A60',
          'D56',
          'B28',
          'C17',
          'A29',
          'C47',
          'A36',
          'A4',
          'D35',
          'A40',
          'B33',
          'C3',
          'B42',
          'B1',
          'A3',
          'B4',
          'C12',
          'B18',
          'A2',
          'C28',
          'D21',
          'C26',
          'A51',
          'C37',
          'A19',
          'B60',
          'A12',
          'A50',
          'C15',
          'B17',
          'D56',
          'B38',
          'C7',
          'B44',
          'B7',
          'C18',
          'B26',
          'D60',
          'B19',
          'C6',
          'A6',
          'C29',
          'A1',
          'B18',
          'C11',
          'A46',
          'C35',
          'B24',
          'A7',
          'D57',
          'B36',
          'D55',
          'A57',
          'C42',
          'A47',
          'C16',
          'B7',
          'C49',
          'B54',
          'A18',
          'C22',
          'A34',
          'C52',
          'A35',
          'C19',
          'A45',
          'C45',
          'B29',
          'D59',
          'A51',
          'B21',
          'C6',
          'B27',
          'C4',
          'A50',
          'C11',
          'A53',
          'C2',
          'B33',
          'D58',
          'B31',
          'A11',
          'C30',
          'C58',
          'D30',
          'A17',
          'C25',
          'A46',
          'B23',
          'A33',
          'D9',
          'C27',
          'B24',
          'C12',
          'A38',
          'D8',
          'A19',
          'B15',
          'B53',
          'A15',
          'B22',
          'C23',
          'A16',
          'C39',
          'A42',
          'D12',
          'A41',
          'B29',
          'C4',
          'B48',
          'C3',
          'A50',
          'D38',
          'A9',
          'D37',
          'C48',
          'B54',
          'C47',
          'A37',
          'D18',
          'C19',
          'A38',
          'C10',
          'B25',
          'C9',
          'B8',
          'C1',
          'B35',
          'D53',
          'B39',
          'D51',
          'B41',
          'C15',
          'A36',
          'C49',
          'B6',
          'C6',
          'A57',
          'B26',
          'C7',
          'B3',
          'D45',
          'B29',
          'C3',
          'B30',
          'D54',
          'B44',
          'C53',
          'D32',
          'A22',
          'B13',
          'C16',
          'A49',
          'C12',
          'A10',
          'C21',
          'A29',
          'D5',
          'A20',
          'D7',
          'A24',
          'C7',
          'D13',
          'C30',
          'C52',
          'C14',
          'B17',
          'B1',
          'C39',
          'A37',
          'C13',
          'B2',
          'D54',
          'B31',
          'C41',
          'A41',
          'C10',
          'A53',
          'C8',
          'B14',
          'C16',
          'A29',
          'B21',
          'D59',
          'B30',
          'A13',
          'A48',
          'C21',
          'A34',
          'D15',
          'C12',
          'A44',
          'C36',
          'C13',
          'A40',
          'C43',
          'B29',
          'C2',
          'B3',
          'D44',
          'A28',
          'D1',
          'B15',
          'A53',
          'D41',
          'A19',
          'C25',
          'A48',
          'B26',
          'A14',
          'B23',
          'C23',
          'A8',
          'C22',
          'A32',
          'D4',
          'C21',
          'B9',
          'B49',
          'C6',
          'B22',
          'D54',
          'B32',
          'D52',
          'B38',
          'C59',
          'A38',
          'D38',
          'A44',
          'D17',
          'A45',
          'C57',
          'D27',
          'C55',
          'C31',
          'B31',
          'C2',
          'A58',
          'C38',
          'B28',
          'A42',
          'C22',
          'D6',
          'A17',
          'D28',
          'C53',
          'B45',
          'C7',
          'A54',
          'B27',
          'A14',
          'C8',
          'A49',
          'B36',
          'C37',
          'A41',
          'D22',
          'A2',
          'A37',
          'C21',
          'B24',
          'C14',
          'A52',
          'B20',
          'A19',
          'D43',
          'C26',
          'D7',
          'B23',
          'C11',
          'B28',
          'A32',
          'C16',
          'D42',
          'A27',
          'C18',
          'B16',
          'A3',
          'B50',
          'A2',
          'D23',
          'C48',
          'C32',
          'A58',
          'B20',
          'C7',
          'B46',
          'D59',
          'B5',
          'D46',
          'B7',
          'A17',
          'C21',
          'B11',
          'C12',
          'B19',
          'D56',
          'B42',
          'C54',
          'D20',
          'A48',
          'C34',
          'A24',
          'C19',
          'A57',
          'C3',
          'B38',
          'D55',
          'A46',
          'C45',
          'A56',
          'C9',
          'B29',
          'C1',
          'B40',
          'D51',
          'B2',
          'C42',
          'B37',
          'C40',
          'D33',
          'C39',
          'A43',
          'C15',
          'A47',
          'D19',
          'A39',
          'C49',
          'D30',
          'A16',
          'B21',
          'D4',
          'A28',
          'D11',
          'A38',
          'C58',
          'B4',
          'C7',
          'B14',
          'D55',
          'B28',
          'C8',
          'A59',
          'C4',
          'B25',
          'C42',
          'A40',
          'C57',
          'C29',
          'A5',
          'B57',
          'B18',
          'C5',
          'B46',
          'B5',
          'C11',
          'B40',
          'A55',
          'C14',
          'A36',
          'A4',
          'A51',
          'C18',
          'A41',
          'C55',
          'D17',
          'C35',
          'A47',
          'C9',
          'D14',
          'A25',
          'B58',
          'A5',
          'B20',
          'A49',
          'D43',
          'B29',
          'D42',
          'C15',
          'B21',
          'A48',
          'C13',
          'A57',
          'D48',
          'A50',
          'C28',
          'A1',
          'C17',
          'A30',
          'C51',
          'A37',
          'C60',
          'B40',
          'D59',
          'B31',
          'D49',
          'B33',
          'C7',
          'A49',
          'B37',
          'C39',
          'A46',
          'C38',
          'B29',
          'D60',
          'A11',
          'C10',
          'C48',
          'A55',
          'B12',
          'C21',
          'B13',
          'C9',
          'B42',
          'D52',
          'B43',
          'C54',
          'D21',
          'C45',
          'D27',
          'A12',
          'C22',
          'A44',
          'B26',
          'C5',
          'B47',
          'C1',
          'B9',
          'C57',
          'B11',
          'D53',
          'A47',
          'C33',
          'B24',
          'C11',
          'B41',
          'C8',
          'B42',
          'C35',
          'A19',
          'B16',
          'C16',
          'A42',
          'C47',
          'A43',
          'C9',
          'B2',
          'C17',
          'A48',
          'D36',
          'A44',
          'D24',
          'C50',
          'A41',
          'D14',
          'A33',
          'B27',
          'C44',
          'C29',
          'A7',
          'B25',
          'C6',
          'B8',
          'C22',
          'A9',
          'B59',
          'B12',
          'D46',
          'B28',
          'C4',
          'B4',
          'C3',
          'B48',
          'B10',
          'C18',
          'A28',
          'C40',
          'D25',
          'A6',
          'B45',
          'C42',
          'D52',
          'B39',
          'C13',
          'B3',
          'B19',
          'D53',
          'B29',
          'A14',
          'B49',
          'C5',
          'B27',
          'C20',
          'A25',
          'D3',
          'A18',
          'D5',
          'C21',
          'A49',
          'C35',
          'B26',
          'C14',
          'A21',
          'B13',
          'D57',
          'B31',
          'A9',
          'A58',
          'C45',
          'A59',
          'C18',
          'D40',
          'A24',
          'C46',
          'A43',
          'C37',
          'B37',
          'D58',
          'B46',
          'C15',
          'A52',
          'C26',
          'A53',
          'C20',
          'A56',
          'C12',
          'B21',
          'A28',
          'B27',
          'C2',
          'B34',
          'D51',
          'B41',
          'C45',
          'D36',
          'A45',
          'C47',
          'A39',
          'D50',
          'B30',
          'C43',
          'D49',
          'B17',
          'C13',
          'B7',
          'C46',
          'B54',
          'B14',
          'C6',
          'A59',
          'D57',
          'B37',
          'D54',
          'B38',
          'C29',
          'D11',
          'A41',
          'C40',
          'A34',
          'C20',
          'D5',
          'B22',
          'A44',
          'D16',
          'C15',
          'A53',
          'C25',
          'B20',
          'C16',
          'A2',
          'D34',
          'C57',
          'B13',
          'B59',
          'A20',
          'C27',
          'A47',
          'D18',
          'A27',
          'B29',
          'A10',
          'B58',
          'A23',
          'D33',
          'C38',
          'B2',
          'C8',
          'D12',
          'B25',
          'C12',
          'B26',
          'C6',
          'A15',
          'B22',
          'A12',
          'D3',
          'A32',
          'C53',
          'D18',
          'C35',
          'B30',
          'C5',
          'B32',
          'C41',
          'B45',
          'C40',
          'C15',
          'B16',
          'A50',
          'C45',
          'D22',
          'C32',
          'A43',
          'D39',
          'A18',
          'B20',
          'A36',
          'A4',
          'D51',
          'A54',
          'C10',
          'B1',
          'C18',
          'B12',
          'C17',
          'D2',
          'A21',
          'D43',
          'A26',
          'C44',
          'B28',
          'D27',
          'A11',
          'C23',
          'A41',
          'C48',
          'B30',
          'D60',
          'B4',
          'B47',
          'C7',
          'D13',
          'A23',
          'C36',
          'A46',
          'C22',
          'B10',
          'A29',
          'D12',
          'A40',
          'C59',
          'B40',
          'D53',
          'B33',
          'C52',
          'A35',
          'B20',
          'C14',
          'B21',
          'C5',
          'A9',
          'C11',
          'B50',
          'C18',
          'A47',
          'C8',
          'B25',
          'A15',
          'B28',
          'C2',
          'B35',
          'D59',
          'B36',
          'C47',
          'D15',
          'C36',
          'A58',
          'C19',
          'A26',
          'D9',
          'B24',
          'A33',
          'C53',
          'B22',
          'A55',
          'C22',
          'A13',
          'D4',
          'A34',
          'B20',
          'A17',
          'D29',
          'C48',
          'A38',
          'C51',
          'D38',
          'C20',
          'B32',
          'C4',
          'B8',
          'D48',
          'B33',
          'A12',
          'B28',
          'C32',
          'B30',
          'C42',
          'D22',
          'C31',
          'A58',
          'C13',
          'B12',
          'A48',
          'C46',
          'A55',
          'C7',
          'B5',
          'D58',
          'B42',
          'D51',
          'C36',
          'B23',
          'D38',
          'C19',
          'B9',
          'B58',
          'B17',
          'A19',
          'C24',
          'A39',
          'D20',
          'A31',
          'B28',
          'D26',
          'C52',
          'B35',
          'C44',
          'C9',
          'B22',
          'D53',
          'B44',
          'C49',
          'B55',
          'C6',
          'B3',
          'C5',
          'A38',
          'B14',
          'D54',
          'B15',
          'D47',
          'B37',
          'C1',
          'B10',
          'A39',
          'D38',
          'A11',
          'C4',
          'B18',
          'A19',
          'D9',
          'C26',
          'A45',
          'C24',
          'A48',
          'C8',
          'B29',
          'A8',
          'C29',
          'C58',
          'A57',
          'C22',
          'B2',
          'C16',
          'A35',
          'D17',
          'C54',
          'B47',
          'D54',
          'B10',
          'C5',
          'B22',
          'D40',
          'A59',
          'C13',
          'B41',
          'D53',
          'B30',
          'A12',
          'D59',
          'B32',
          'C3',
          'B11',
          'A26',
          'D1',
          'A13',
          'B26',
          'C33',
          'A29',
          'B50',
          'D57',
          'B5',
          'D44',
          'A19',
          'B55',
          'B18',
          'C53',
          'D19',
          'A49',
          'C46',
          'B27',
          'C12',
          'A27',
          'B22',
          'D39',
          'C19',
          'A50',
          'C21',
          'A51',
          'C17',
          'D17',
          'A23',
          'C47',
          'D37',
          'B23',
          'A17',
          'C38',
          'D13',
          'C37',
          'B43',
          'D58',
          'B46',
          'C46',
          'A56',
          'C41',
          'B1',
          'C9',
          'C50',
          'B53',
          'C45',
          'A60',
          'B19',
          'C17',
          'D44',
          'B28',
          'A28',
          'B50',
          'C10',
          'B27',
          'D57',
          'B16',
          'A38',
          'C58',
          'A58',
          'C7',
          'B7',
          'C39',
          'A44',
          'C32',
          'D15',
          'B26',
          'C4',
          'A52',
          'C11',
          'A37',
          'C60',
          'C30',
          'D22',
          'A4',
          'D31',
          'C36',
          'D16',
          'A45',
          'D25',
          'A10',
          'C13',
          'A60',
          'C20',
          'A9',
          'B60',
          'B15',
          'C21',
          'D6',
          'A32',
          'D8',
          'A18',
          'C38',
          'B21',
          'C11',
          'B44',
          'C8',
          'D12',
          'C41',
          'A40',
          'C51',
          'A48',
          'C22',
          'A16',
          'B24',
          'C19',
          'B43',
          'D52',
          'B19',
          'C15',
          'A54',
          'D42',
          'B31',
          'C30',
          'A41',
          'B13',
          'D56',
          'B27',
          'C3',
          'A59',
          'C2',
          'B39',
          'D55',
          'B29',
          'D35',
          'C43',
          'A52',
          'B7',
          'C5',
          'A39',
          'A7',
          'D24',
          'A2',
          'A36',
          'A1',
          'B19',
          'C53',
          'B20',
          'A30',
          'C12',
          'A16',
          'C19',
          'B15',
          'D33',
          'A42',
          'C59',
          'C7',
          'D13',
          'A45',
          'C32',
          'A56',
          'C14',
          'C43',
          'B40',
          'D55',
          'B21',
          'D41',
          'B29',
          'D29',
          'B30',
          'A26',
          'D15',
          'C46',
          'B28',
          'C1',
          'D11',
          'A24',
          'D6',
          'A36',
          'C23',
          'A33',
          'D58',
          'B7',
          'D45',
          'A25',
          'C16',
          'A53',
          'C59',
          'B12',
          'A44',
          'C10',
          'A60',
          'D49',
          'B32',
          'A11',
          'B49',
          'D56',
          'B5',
          'C2',
          'B36',
          'C58',
          'B37',
          'A48',
          'B27',
          'C9',
          'D11',
          'B24',
          'C13',
          'B20',
          'C52',
          'C32',
          'B38',
          'C28',
          'A3',
          'B8',
          'C3',
          'B18',
          'A37',
          'C60',
          'A51',
          'C8',
          'A27',
          'D34',
          'B29',
          'C37',
          'A52',
          'C21',
          'A57',
          'C14',
          'A22',
          'B58',
          'C6',
          'B1',
          'C35',
          'D23',
          'C44',
          'D26',
          'C41',
          'A43',
          'C56',
          'C31',
          'B39',
          'C49',
          'D24',
          'A5',
          'D58',
          'B34',
          'D48',
          'B11',
          'C22',
          'A6',
          'B56',
          'B17',
          'C18',
          'D40',
          'A14',
          'B51',
          'A2',
          'B16',
          'C21',
          'D7',
          'A19',
          'B22',
          'C1',
          'B1',
          'D43',
          'C28',
          'A51',
          'C50',
          'A42',
          'D23',
          'A43',
          'C23',
          'A45',
          'C56',
          'D18',
          'A25',
          'B48',
          'C2',
          'B42',
          'D55',
          'A53',
          'D59',
          'B41',
          'B3',
          'C9',
          'B24',
          'D8',
          'A35',
          'D10',
          'A28',
          'C43',
          'A55',
          'C15',
          'A20',
          'B16',
          'B59',
          'C6',
          'B31',
          'D31',
          'C35',
          'C50',
          'B57',
          'B6',
          'C20',
          'D7',
          'A17',
          'D38',
          'B18',
          'C18',
          'B51',
          'C42',
          'B22',
          'C38',
          'A48',
          'C5',
          'B24',
          'C32',
          'B16',
          'C59',
          'D60',
          'D11',
          'C39',
          'A57',
          'C37',
          'D14',
          'A20',
          'D4',
          'C24',
          'A15',
          'C41',
          'B36',
          'A54',
          'C56',
          'A38',
          'C25',
          'D20',
          'C46',
          'A45',
          'C10',
          'B21',
          'C49',
          'B45',
          'C16',
          'D40',
          'C13',
          'A23',
          'B17',
          'C12',
          'B20',
          'D60',
          'B28',
          'D21',
          'A50',
          'B10',
          'A46',
          'D35',
          'B18',
          'C1',
          'B38',
          'D57',
          'B51',
          'A38',
          'C55',
          'B54',
          'C54',
          'D39',
          'A41',
          'C34',
          'D47',
          'C41',
          'B2',
          'C35',
          'C15',
          'B4',
          'C22',
          'A47',
          'C47',
          'A39',
          'C45',
          'C7',
          'B12',
          'A26',
          'B50',
          'B13',
          'D46',
          'B37',
          'C36',
          'A60',
          'C19',
          'B16',
          'C14',
          'D16',
          'A42',
          'C40',
          'A43',
          'C16',
          'D2',
          'B14',
          'A21',
          'B56',
          'C58',
          'B38',
          'C27',
          'B12',
          'A25',
          'C9',
          'B30',
          'D32',
          'A4',
          'A36',
          'A1',
          'D21',
          'A32',
          'B15',
          'C7',
          'B57',
          'A12',
          'B35',
          'D48',
          'B17',
          'C59',
          'D29',
          'A18',
          'B22',
          'D36',
          'B28',
          'C42',
          'D35',
          'C60',
          'A37',
          'B33',
          'D60',
          'B47',
          'C53',
          'B23',
          'C34',
          'D23',
          'C54',
          'D33',
          'B29',
          'A7',
          'D26',
          'A12',
          'A53',
          'D45',
          'B27',
          'A16',
          'D41',
          'A21',
          'D10',
          'A40',
          'C11',
          'A58',
          'C21',
          'D37',
          'A41',
          'B26',
          'D14',
          'C48',
          'A38',
          'C1',
          'B25',
          'D58',
          'B35',
          'C51',
          'C15',
          'B23',
          'C49',
          'B56',
          'A4',
          'C19',
          'B6',
          'C10',
          'D33',
          'A25',
          'B15',
          'C60',
          'C14',
          'A14',
          'B28',
          'D43',
          'A29',
          'B11',
          'C20',
          'B13',
          'C17',
          'C39',
          'D12',
          'A18',
          'C11',
          'B26',
          'A17',
          'D31',
          'C12',
          'B39',
          'D49',
          'C40',
          'C25',
          'A54',
          'B53',
          'A47',
          'D20',
          'B28',
          'D25',
          'C8',
          'B30',
          'C7',
          'B17',
          'C6',
          'D13',
          'A46',
          'C10',
          'A55',
          'C42',
          'D11',
          'D60',
          'B8',
          'A52',
          'B25',
          'C19',
          'D7',
          'A31',
          'B9',
          'C23',
          'A56',
          'C30',
          'A1',
          'D33',
          'A44',
          'C37',
          'B22',
          'D6',
          'A21',
          'C38',
          'A50',
          'C35',
          'B3',
          'C4',
          'B30',
          'D28',
          'A11',
          'C15',
          'B13',
          'D32',
          'C52',
          'A36',
          'C49',
          'A37',
          'D26',
          'A47',
          'D17',
          'C34',
          'B37',
          'C33',
          'A30',
          'B9',
          'D50',
          'B14',
          'C57',
          'A40',
          'C24',
          'D20',
          'A51',
          'C22',
          'B5',
          'D55',
          'B48',
          'C7',
          'B2',
          'D46',
          'A21',
          'B19',
          'A19',
          'C26',
          'C54',
          'D10',
          'B25',
          'D38',
          'B20',
          'C6',
          'B45',
          'C1',
          'B31',
          'D56',
          'A10',
          'C24',
          'B1',
          'B44',
          'C19',
          'B11',
          'B60',
          'A28',
          'C8',
          'B13',
          'C13',
          'A26',
          'D4',
          'A25',
          'D19',
          'C56',
          'B6',
          'C2',
          'A13',
          'C17',
          'A24',
          'C45',
          'B46',
          'C11',
          'A60',
          'C32',
          'D29',
          'B22',
          'D59',
          'B33',
          'A11',
          'B28',
          'D35',
          'C41',
          'B20',
          'D50',
          'B34',
          'C53',
          'C13',
          'B16',
          'B53',
          'A33',
          'D15',
          'C45',
          'A47',
          'D52',
          'B44',
          'C18',
          'D12',
          'C11',
          'D31',
          'A22',
          'D40',
          'A40',
          'C38',
          'B33',
          'D56',
          'B50',
          'A41',
          'C47',
          'A53',
          'C37',
          'A58',
          'C30',
          'B37',
          'D50',
          'A59',
          'C21',
          'B2',
          'C10',
          'B28',
          'C56',
          'A50',
          'C44',
          'D27',
          'B30',
          'C2',
          'B8',
          'A48',
          'A13',
          'B27',
          'C33',
          'A55',
          'C5',
          'B56',
          'B7',
          'C9',
          'B46',
          'D58',
          'B49',
          'A32',
          'C3',
          'B19',
          'D40',
          'B24',
          'A43',
          'C11',
          'A27',
          'D21',
          'C46',
          'B29',
          'C19',
          'A23',
          'D11',
          'C31',
          'A44',
          'C27',
          'A50',
          'B34',
          'C38',
          'B35',
          'D56',
          'B37',
          'A47',
          'C55',
          'A31',
          'D2',
          'B20',
          'C3',
          'B21',
          'C2',
          'B32',
          'C1',
          'B53',
          'C44',
          'A39',
          'B12',
          'C26',
          'A60',
          'C16',
          'B9',
          'D46',
          'C37',
          'A59',
          'D25',
          'A5',
          'B44',
          'C47',
          'A42',
          'C34',
          'A25',
          'C14',
          'B19',
          'D18',
          'A28',
          'D49',
          'A39',
          'C43',
          'A56',
          'C22',
          'D4',
          'A30',
          'D19',
          'C37',
          'B7',
          'C4',
          'B17',
          'D47',
          'B38',
          'D54',
          'B42',
          'D53',
          'A5',
          'B22',
          'D22',
          'A49',
          'C23',
          'A12',
          'D39',
          'C19',
          'D8',
          'C53',
          'A33',
          'C50',
          'B35',
          'C35',
          'D15',
          'C11',
          'B16',
          'A25',
          'B18',
          'A23',
          'D14',
          'A40',
          'D31',
          'B11',
          'C9',
          'A46',
          'C24',
          'A54',
          'C20',
          'B4',
          'C13',
          'A36',
          'D7',
          'B24',
          'C2',
          'A5',
          'C29',
          'A9',
          'B33',
          'D55',
          'B13',
          'C7',
          'D20',
          'C23',
          'B1',
          'C8',
          'B16',
          'D54',
          'B47',
          'C19',
          'D9',
          'A18',
          'C22',
          'B12',
          'C16',
          'A15',
          'C40',
          'A45',
          'C14',
          'A58',
          'C42',
          'A39',
          'C58',
          'B57',
          'A3',
          'B58',
          'B20',
          'D35',
          'C11',
          'A16',
          'C39',
          'A49',
          'B52',
          'A40',
          'C49',
          'D25',
          'A46',
          'B10',
          'A28',
          'D5',
          'C25',
          'A60',
          'B44',
          'C2',
          'B9',
          'C13',
          'D29',
          'A15',
          'D37',
          'A8',
          'B46',
          'C8',
          'B4',
          'C6',
          'B23',
          'D21',
          'A53',
          'C31',
          'B40',
          'C10',
          'A58',
          'C29',
          'A49',
          'C45',
          'B10',
          'C23',
          'B3',
          'C21',
          'B34',
          'C52',
          'D9',
          'C16',
          'D12',
          'C8',
          'D26',
          'B30',
          'A7',
          'B53',
          'A32',
          'C15',
          'C42',
          'A42',
          'C33',
          'A48',
          'D35',
          'A54',
          'D27',
          'A17',
          'C32',
          'C53',
          'D39',
          'B24',
          'A18',
          'B25',
          'D57',
          'B18',
          'C7',
          'B54',
          'C1',
          'B26',
          'A15',
          'B52',
          'B12',
          'C9',
          'D24',
          'C46',
          'A38',
          'C60',
          'B10',
          'D3',
          'A11',
          'C24',
          'D5',
          'A19',
          'B21',
          'D54',
          'B40',
          'C33',
          'B41',
          'C51',
          'B55',
          'A36',
          'A1',
          'D41',
          'A58',
          'C27',
          'B50',
          'C9',
          'A60',
          'C18',
          'B11',
          'B58',
          'C5',
          'D12',
          'C38',
          'B36',
          'D55',
          'B49',
          'B7',
          'A41',
          'C36',
          'B29',
          'A13',
          'B32',
          'C40',
          'D10',
          'C19',
          'D18',
          'B28',
          'D30',
          'B21',
          'A23',
          'C24',
          'A42',
          'C12',
          'A50',
          'D37',
          'B17',
          'D19',
          'A28',
          'C7',
          'B19',
          'D3',
          'A31',
          'C54',
          'A38',
          'C51',
          'C34',
          'B38',
          'C14',
          'B6',
          'C17',
          'B15',
          'C16',
          'D44',
          'B27',
          'C1',
          'B39',
          'A1',
          'C13',
          'B45',
          'C47',
          'A54',
          'C31',
          'A55',
          'C39',
          'B11',
          'A12',
          'B36',
          'C35',
          'D32',
          'C11',
          'B17',
          'D36',
          'A42',
          'D47',
          'B6',
          'C8',
          'D27',
          'A16',
          'D28',
          'B22',
          'D2',
          'A19',
          'B23',
          'C3',
          'B34',
          'C20',
          'B54',
          'A53',
          'C49',
          'A22',
          'C48',
          'A44',
          'C15',
          'A56',
          'C4',
          'B19',
          'C36',
          'D13',
          'C54',
          'C12',
          'A57',
          'C46',
          'B8',
          'D51',
          'B42',
          'A3',
          'D22',
          'B25',
          'C39',
          'B12',
          'A24',
          'D8',
          'C28',
          'A53',
          'C21',
          'B5',
          'C20',
          'B14',
          'D46',
          'A34',
          'C50',
          'A52',
          'C60',
          'B46',
          'C22',
          'A14',
          'D9',
          'A39',
          'B13',
          'C12',
          'B29',
          'D30',
          'C59',
          'A37',
          'D37',
          'B24',
          'C43',
          'D27',
          'C56',
          'D20',
          'C53',
          'C30',
          'A43',
          'D12',
          'A31',
          'C22',
          'A58',
          'D24',
          'C37',
          'A45',
          'D18',
          'B20',
          'D42',
          'C29',
          'D22',
          'A28',
          'D53',
          'B45',
          'B60',
          'C18',
          'D43',
          'B2',
          'D57',
          'B3',
          'D41',
          'A15',
          'D30',
          'B9',
          'D45',
          'B35',
          'A9',
          'B36',
          'D58',
          'B1',
          'C45',
          'B11',
          'B46',
          'C44',
          'A54',
          'C23',
          'A8',
          'B59',
          'A25',
          'C48',
          'A38',
          'B17',
          'C10',
          'D23',
          'B21',
          'C7',
          'A37',
          'B52',
          'A48',
          'C36',
          'B25',
          'D11',
          'D60',
          'B34',
          'A42',
          'C44',
          'D34',
          'B18',
          'A22',
          'D17',
          'B28',
          'C39',
          'A39',
          'D21',
          'C43',
          'D36',
          'C22',
          'B23',
          'C20',
          'B43',
          'C12',
          'B14',
          'A26',
          'C48',
          'A52',
          'C35',
          'D46',
          'B15',
          'D25',
          'B30',
          'C31',
          'B41',
          'D57',
          'A11',
          'D39',
          'A44',
          'B12',
          'C45',
          'B13',
          'C39',
          'B20',
          'D56',
          'B45',
          'C12',
          'A59',
          'C42',
          'B6',
          'C5',
          'B31',
          'D35',
          'C10',
          'A18',
          'D27',
          'A36',
          'A4',
          'D34',
          'B28',
          'A10',
          'A51',
          'C24',
          'A27',
          'D50',
          'C33',
          'B51',
          'C32',
          'B2',
          'C30',
          'B16',
          'A24',
          'D33',
          'C58',
          'C27',
          'D8',
          'A20',
          'B19',
          'C11',
          'B47',
          'C59',
          'D57',
          'B7',
          'D12',
          'C44',
          'D48',
          'A39',
          'C22',
          'B57',
          'C9',
          'D11',
          'D59',
          'A58',
          'C20',
          'B8',
          'A48',
          'D14',
          'A19',
          'D38',
          'B21',
          'D3',
          'A29',
          'D17',
          'C16',
          'A54',
          'A4',
          'D1',
          'C18',
          'D13',
          'B24',
          'C3',
          'A10',
          'D27',
          'A46',
          'A14',
          'B31',
          'C22',
          'A35',
          'C50',
          'B55',
          'C1',
          'B48',
          'D4',
          'B6',
          'A51',
          'C55',
          'C25',
          'D3',
          'C18',
          'B14',
          'A43',
          'B51',
          'A4',
          'C28',
          'A56',
          'D1',
          'A21',
          'C31',
          'D29',
          'C12',
          'B16',
          'D51',
          'A55',
          'C47',
          'D14',
          'C9',
          'B40',
          'A54',
          'C55',
          'B44',
          'C17',
          'A34',
          'D5',
          'A13',
          'A48',
          'C50',
          'B38',
          'C26',
          'B2',
          'C15',
          'D9',
          'B26',
          'D46',
          'A20',
          'B39',
          'C34',
          'A52',
          'C24',
          'A17',
          'B27',
          'D31',
          'A2',
          'A49',
          'B59',
          'C22',
          'B1',
          'D49',
          'B19',
          'D39',
          'C50',
          'B20',
          'C11',
          'B51',
          'C31',
          'B54',
          'C6',
          'B2',
          'C40',
          'A47',
          'A20',
          'D12',
          'C4',
          'B13',
          'D35',
          'B21',
          'A21',
          'C15',
          'B25',
          'D32',
          'C59',
          'A42',
          'C57',
          'A46',
          'B11',
          'C14',
          'A35',
          'B55',
          'C48',
          'A53',
          'C17',
          'D7',
          'A49',
          'C33',
          'A57',
          'C23',
          'B4',
          'B56',
          'B13',
          'D48',
          'B9',
          'C20',
          'D6',
          'A10',
          'C19',
          'D11',
          'C54',
          'C29',
          'A50',
          'A12',
          'B37',
          'C15',
          'D43',
          'B23',
        ],
      },
    ],
  },
  {
    title: 'Home',
    id: 'home',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/HOME%202.jpeg',
    artData: [
      {
        size: {
          x: 301,
          y: 301,
        },
        pegs: [
          {
            x: 301,
            y: 150.5,
            angle: 0,
            name: 'D1',
          },
          {
            x: 300.9484274088214,
            y: 154.4396307203349,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 300.79374498056336,
            y: 158.376561414563,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 300.5360587268358,
            y: 162.3080939070407,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 300.17554525292513,
            y: 166.23153372178183,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 299.7124516367585,
            y: 170.14419192911777,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 299.14709525956823,
            y: 174.04338698855474,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 298.47986358837517,
            y: 177.92644658656818,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 297.7112139104377,
            y: 181.79070946807278,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 296.8416730198503,
            y: 185.63352726031374,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 295.8718368565048,
            y: 189.45226628792935,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 294.8023700976631,
            y: 193.24430937794037,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 293.6340057024206,
            y: 197.00705765342957,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 292.36754440937284,
            y: 200.7379323146825,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 291.00385418782884,
            y: 204.43437640656765,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 289.54386964294866,
            y: 208.09385657094597,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.98859137521146,
            y: 211.71386478290793,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 286.339085294654,
            y: 215.29192006964837,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 284.59648189034937,
            y: 218.82557021080177,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 282.7619754556258,
            y: 222.31239341907104,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 280.836823269558,
            y: 225.74999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 278.82234473529087,
            y: 229.13603398975027,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 276.7199204757863,
            y: 232.46817476976156,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 274.53099138761337,
            y: 235.74413865718734,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 272.25705765342957,
            y: 238.9616804700172,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.8996777138309,
            y: 242.11859506581243,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 267.46046719927415,
            y: 245.21271885300055,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.9410978228047,
            y: 248.24193127369267,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 262.3432962343478,
            y: 251.20415625700815,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 259.6688428373493,
            y: 254.09736364191,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.9195705685754,
            y: 256.9195705685754,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 254.09736364190996,
            y: 259.6688428373493,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 251.2041562570081,
            y: 262.34329623434786,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 248.2419312736926,
            y: 264.9410978228047,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 245.2127188530005,
            y: 267.46046719927415,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 242.1185950658124,
            y: 269.8996777138309,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.96168047001717,
            y: 272.2570576534296,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 235.74413865718728,
            y: 274.53099138761337,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 232.4681747697615,
            y: 276.71992047578635,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 229.13603398975022,
            y: 278.82234473529087,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 225.74999999999997,
            y: 280.836823269558,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 222.312393419071,
            y: 282.7619754556258,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.82557021080177,
            y: 284.59648189034937,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 215.29192006964837,
            y: 286.339085294654,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.71386478290793,
            y: 287.9885913752114,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 208.093856570946,
            y: 289.54386964294866,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 204.4343764065677,
            y: 291.00385418782884,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.73793231468255,
            y: 292.36754440937284,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 197.00705765342963,
            y: 293.6340057024206,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 193.2443093779404,
            y: 294.8023700976631,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 189.45226628792943,
            y: 295.8718368565048,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.63352726031383,
            y: 296.8416730198503,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.79070946807283,
            y: 297.7112139104377,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.9264465865683,
            y: 298.4798635883751,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 174.04338698855483,
            y: 299.14709525956823,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 170.1441919291179,
            y: 299.71245163675843,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 166.23153372178197,
            y: 300.17554525292513,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 162.3080939070408,
            y: 300.53605872683573,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 158.37656141456318,
            y: 300.79374498056336,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 154.43963072033506,
            y: 300.9484274088214,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.50000000000017,
            y: 301,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.56036927966528,
            y: 300.9484274088214,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.62343858543716,
            y: 300.79374498056336,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.69190609295953,
            y: 300.5360587268358,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.76846627821837,
            y: 300.1755452529252,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.85580807088246,
            y: 299.7124516367585,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.95661301144548,
            y: 299.1470952595683,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 123.07355341343207,
            y: 298.47986358837517,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 119.20929053192748,
            y: 297.7112139104378,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 115.36647273968649,
            y: 296.8416730198504,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.5477337120709,
            y: 295.87183685650484,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.75569062205994,
            y: 294.80237009766313,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.99294234657071,
            y: 293.63400570242067,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 100.26206768531779,
            y: 292.3675444093729,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.5656235934326,
            y: 291.00385418782895,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.90614342905431,
            y: 289.5438696429488,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 89.2861352170924,
            y: 287.98859137521157,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.70807993035191,
            y: 286.3390852946542,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 82.17442978919851,
            y: 284.59648189034954,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.68760658092921,
            y: 282.76197545562593,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.25000000000026,
            y: 280.8368232695582,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.86396601024994,
            y: 278.822344735291,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.53182523023864,
            y: 276.7199204757864,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.25586134281286,
            y: 274.5309913876135,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 62.03831952998296,
            y: 272.25705765342974,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.88140493418769,
            y: 269.89967771383095,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.78728114699959,
            y: 267.4604671992742,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.75806872630748,
            y: 264.94109782280475,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.795843742991934,
            y: 262.3432962343479,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.9026363580901,
            y: 259.66884283734936,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 44.080429431424655,
            y: 256.91957056857547,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.33115716265075,
            y: 254.09736364191002,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.656703765652196,
            y: 251.20415625700815,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 36.05890217719534,
            y: 248.24193127369267,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.53953280072588,
            y: 245.2127188530005,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 31.100322286169092,
            y: 242.1185950658124,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.742942346570374,
            y: 238.96168047001717,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.469008612386602,
            y: 235.74413865718728,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.28007952421363,
            y: 232.4681747697615,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.17765526470906,
            y: 229.13603398975022,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.163176730441904,
            y: 225.74999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.238024544374127,
            y: 222.31239341907093,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.403518109650545,
            y: 218.8255702108016,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.660914705345892,
            y: 215.2919200696482,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 13.01140862478847,
            y: 211.7138647829077,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.456130357051245,
            y: 208.09385657094577,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.996145812171036,
            y: 204.43437640656745,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.632455590627046,
            y: 200.73793231468224,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.365994297579293,
            y: 197.00705765342929,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.197629902336845,
            y: 193.24430937794,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.128163143495136,
            y: 189.452266287929,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.158326980149578,
            y: 185.63352726031337,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2887860895621603,
            y: 181.7907094680724,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.520136411624763,
            y: 177.92644658656778,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8529047404316987,
            y: 174.0433869885543,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2875483632414704,
            y: 170.14419192911728,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.824454747074803,
            y: 166.23153372178137,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.4639412731641913,
            y: 162.30809390704013,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.20625501943660468,
            y: 158.3765614145625,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.05157259117861568,
            y: 154.43963072033432,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 150.4999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.051572591178649096,
            y: 146.56036927966449,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20625501943667152,
            y: 142.62343858543633,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.46394127316429157,
            y: 138.69190609295867,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8244547470749367,
            y: 134.7684662782175,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.287548363241621,
            y: 130.85580807088155,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.8529047404318826,
            y: 126.95661301144453,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.520136411624964,
            y: 123.07355341343107,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2887860895624104,
            y: 119.20929053192646,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.1583269801498615,
            y: 115.36647273968548,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.128163143495436,
            y: 111.54773371206986,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.197629902337179,
            y: 107.75569062205885,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.365994297579644,
            y: 103.9929423465696,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.632455590627448,
            y: 100.26206768531664,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.996145812171454,
            y: 96.56562359343147,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.456130357051697,
            y: 92.90614342905313,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 13.011408624788954,
            y: 89.28613521709121,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.660914705346391,
            y: 85.70807993035072,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.40351810965108,
            y: 82.17442978919733,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.238024544374692,
            y: 78.68760658092803,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.16317673044249,
            y: 75.2499999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.17765526470966,
            y: 71.8639660102488,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.280079524214262,
            y: 68.53182523023752,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.469008612387253,
            y: 65.25586134281176,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.74294234657106,
            y: 62.03831952998189,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 31.100322286169792,
            y: 58.88140493418664,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.53953280072661,
            y: 55.787281146998566,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 36.05890217719611,
            y: 52.758068726306455,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.65670376565297,
            y: 49.795843742990954,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.33115716265154,
            y: 46.90263635808913,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 44.08042943142547,
            y: 44.08042943142372,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.90263635809094,
            y: 41.33115716264984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.7958437429928,
            y: 38.6567037656513,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.75806872630831,
            y: 36.058902177194526,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.78728114700044,
            y: 33.53953280072508,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.88140493418856,
            y: 31.10032228616832,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 62.03831952998386,
            y: 28.742942346569638,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.25586134281376,
            y: 26.469008612385885,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.53182523023956,
            y: 24.280079524212944,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.86396601025088,
            y: 22.17765526470839,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.25000000000121,
            y: 20.16317673044129,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.68760658093017,
            y: 18.238024544373523,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 82.17442978919948,
            y: 16.40351810964998,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.70807993035291,
            y: 14.66091470534534,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 89.28613521709343,
            y: 13.01140862478795,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.90614342905538,
            y: 11.456130357050762,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.56562359343374,
            y: 9.996145812170585,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 100.26206768531894,
            y: 8.632455590626627,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.99294234657191,
            y: 7.365994297578892,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.75569062206118,
            y: 6.1976299023364945,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.54773371207219,
            y: 5.128163143494801,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 115.36647273968782,
            y: 4.158326980149294,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 119.20929053192884,
            y: 3.2887860895619094,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 123.07355341343347,
            y: 2.5201364116245295,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.95661301144695,
            y: 1.8529047404314982,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.85580807088397,
            y: 1.2875483632413036,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.7684662782199,
            y: 0.824454747074686,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.6919060929611,
            y: 0.46394127316410777,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.62343858543875,
            y: 0.20625501943653785,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.5603692796669,
            y: 0.05157259117858226,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.50000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 154.43963072033677,
            y: 0.051572591178682514,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 158.37656141456495,
            y: 0.20625501943673835,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 162.30809390704258,
            y: 0.4639412731643918,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 166.23153372178376,
            y: 0.8244547470750704,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 170.1441919291197,
            y: 1.2875483632417881,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 174.04338698855668,
            y: 1.852904740432083,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.92644658657017,
            y: 2.520136411625198,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.79070946807477,
            y: 3.2887860895626777,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.63352726031576,
            y: 4.158326980150147,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 189.45226628793137,
            y: 5.128163143495754,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 193.2443093779424,
            y: 6.19762990233753,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 197.00705765343162,
            y: 7.365994297580045,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.7379323146845,
            y: 8.632455590627865,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 204.4343764065697,
            y: 9.996145812171905,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 208.09385657094805,
            y: 11.456130357052182,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.7138647829099,
            y: 13.011408624789455,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 215.29192006965044,
            y: 14.660914705346926,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.8255702108038,
            y: 16.40351810965165,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 222.31239341907306,
            y: 18.238024544375296,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.750000000002,
            y: 20.16317673044313,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 229.13603398975226,
            y: 22.177655264710328,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 232.46817476976352,
            y: 24.28007952421495,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 235.74413865718927,
            y: 26.469008612387974,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.96168047001913,
            y: 28.742942346571812,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 242.11859506581436,
            y: 31.100322286170563,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 245.2127188530024,
            y: 33.5395328007274,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 248.24193127369446,
            y: 36.058902177196934,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 251.20415625700994,
            y: 38.656703765653816,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 254.09736364191176,
            y: 41.33115716265242,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.91957056857717,
            y: 44.08042943142638,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 259.668842837351,
            y: 46.902636358091854,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 262.34329623434957,
            y: 49.79584374299374,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.94109782280634,
            y: 52.75806872630932,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 267.46046719927574,
            y: 55.78728114700147,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.89967771383243,
            y: 58.88140493418961,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 272.2570576534311,
            y: 62.038319529984925,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 274.53099138761485,
            y: 65.25586134281484,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 276.7199204757878,
            y: 68.53182523024067,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 278.82234473529223,
            y: 71.86396601025201,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 280.8368232695594,
            y: 75.25000000000237,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 282.76197545562707,
            y: 78.68760658093136,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 284.5964818903506,
            y: 82.17442978920069,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 286.33908529465526,
            y: 85.7080799303541,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.98859137521254,
            y: 89.28613521709465,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 289.54386964294974,
            y: 92.90614342905661,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 291.00385418782986,
            y: 96.56562359343498,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 292.3675444093738,
            y: 100.26206768532019,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 293.63400570242146,
            y: 103.99294234657317,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 294.80237009766387,
            y: 107.75569062206245,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 295.8718368565055,
            y: 111.54773371207347,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 296.841673019851,
            y: 115.36647273968913,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 297.71121391043835,
            y: 119.20929053193014,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 298.47986358837574,
            y: 123.07355341343477,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 299.1470952595687,
            y: 126.95661301144824,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 299.7124516367589,
            y: 130.85580807088527,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 300.1755452529254,
            y: 134.7684662782212,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 300.536058726836,
            y: 138.69190609296243,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 300.79374498056353,
            y: 142.62343858544008,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 300.94842740882143,
            y: 146.56036927966824,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C43',
          'B50',
          'C44',
          'D7',
          'C45',
          'D8',
          'C46',
          'D9',
          'C52',
          'B16',
          'C51',
          'D2',
          'A48',
          'C17',
          'A49',
          'D3',
          'C34',
          'B48',
          'C33',
          'B47',
          'C31',
          'B46',
          'C30',
          'B44',
          'C29',
          'B45',
          'C18',
          'A50',
          'D48',
          'A51',
          'C20',
          'A52',
          'C1',
          'A53',
          'C22',
          'A55',
          'C3',
          'B5',
          'D57',
          'A58',
          'C25',
          'A57',
          'C24',
          'B49',
          'C43',
          'B51',
          'C60',
          'D14',
          'A39',
          'D15',
          'C45',
          'D5',
          'C44',
          'B56',
          'C42',
          'B50',
          'C41',
          'B53',
          'C32',
          'B48',
          'C31',
          'B47',
          'C35',
          'B44',
          'C30',
          'B46',
          'C43',
          'B55',
          'A8',
          'B52',
          'A5',
          'B51',
          'C59',
          'D7',
          'C44',
          'D8',
          'C45',
          'B59',
          'A45',
          'D44',
          'A44',
          'D42',
          'B31',
          'D41',
          'A43',
          'B58',
          'C33',
          'B47',
          'C21',
          'A53',
          'C2',
          'A52',
          'C20',
          'B46',
          'C19',
          'A50',
          'C36',
          'B50',
          'C43',
          'B51',
          'C58',
          'D13',
          'A38',
          'D12',
          'C55',
          'B18',
          'C54',
          'B17',
          'C52',
          'B16',
          'C51',
          'D3',
          'C44',
          'B49',
          'C31',
          'B48',
          'C30',
          'B40',
          'C10',
          'B35',
          'C9',
          'B1',
          'C43',
          'B52',
          'A10',
          'D51',
          'A53',
          'C23',
          'A56',
          'C16',
          'A46',
          'B60',
          'C45',
          'D6',
          'C44',
          'B57',
          'A42',
          'D22',
          'A57',
          'C24',
          'B49',
          'C39',
          'B50',
          'C31',
          'B51',
          'C57',
          'D13',
          'A5',
          'B53',
          'C43',
          'B56',
          'A10',
          'D52',
          'A54',
          'C3',
          'B5',
          'C10',
          'B36',
          'C11',
          'B41',
          'C30',
          'B49',
          'C44',
          'B60',
          'A46',
          'D45',
          'B33',
          'D44',
          'B30',
          'D43',
          'A44',
          'D41',
          'B29',
          'D40',
          'A43',
          'C14',
          'B50',
          'C38',
          'B51',
          'A1',
          'D8',
          'C34',
          'A50',
          'D5',
          'C43',
          'B57',
          'C32',
          'B46',
          'C44',
          'D4',
          'A49',
          'D46',
          'B32',
          'C6',
          'A55',
          'C40',
          'B31',
          'C5',
          'B10',
          'D59',
          'B11',
          'C37',
          'B51',
          'C56',
          'D5',
          'C42',
          'A60',
          'C8',
          'B39',
          'C29',
          'B45',
          'C18',
          'A48',
          'C16',
          'B52',
          'A12',
          'D53',
          'B8',
          'C22',
          'B7',
          'D60',
          'A17',
          'D38',
          'B27',
          'D58',
          'B9',
          'C48',
          'B48',
          'C23',
          'B49',
          'C43',
          'B1',
          'C8',
          'B12',
          'C41',
          'A55',
          'C7',
          'A56',
          'C17',
          'B53',
          'C16',
          'A47',
          'D1',
          'C44',
          'D8',
          'A31',
          'D9',
          'C35',
          'A48',
          'D47',
          'A50',
          'D49',
          'B6',
          'C4',
          'A58',
          'C27',
          'B27',
          'C26',
          'B24',
          'D55',
          'B4',
          'C20',
          'B47',
          'C34',
          'A47',
          'D23',
          'A51',
          'C19',
          'B16',
          'C51',
          'B17',
          'C52',
          'B18',
          'C54',
          'B50',
          'C25',
          'B21',
          'D35',
          'B22',
          'C24',
          'A57',
          'D57',
          'A14',
          'D56',
          'B25',
          'D37',
          'A16',
          'D59',
          'B28',
          'D39',
          'A19',
          'D38',
          'A18',
          'B52',
          'A3',
          'B40',
          'A2',
          'B24',
          'C25',
          'A58',
          'C4',
          'B6',
          'D57',
          'B14',
          'C50',
          'B15',
          'C3',
          'B16',
          'C51',
          'D10',
          'A33',
          'D9',
          'C45',
          'D2',
          'A48',
          'D3',
          'C43',
          'B54',
          'A7',
          'D15',
          'A38',
          'D14',
          'C59',
          'B51',
          'C44',
          'B47',
          'C20',
          'B55',
          'C53',
          'B18',
          'C54',
          'D12',
          'A37',
          'D21',
          'A46',
          'C15',
          'A56',
          'C40',
          'B49',
          'C12',
          'B42',
          'C2',
          'A53',
          'D10',
          'C35',
          'A49',
          'C34',
          'A50',
          'D6',
          'C43',
          'B58',
          'A44',
          'B59',
          'C33',
          'A45',
          'D43',
          'B32',
          'D42',
          'A24',
          'D40',
          'B30',
          'C39',
          'A54',
          'D12',
          'A4',
          'B41',
          'C11',
          'B6',
          'D50',
          'A58',
          'D27',
          'A59',
          'D58',
          'A15',
          'D37',
          'A52',
          'D8',
          'C59',
          'B21',
          'D54',
          'A13',
          'B52',
          'A11',
          'D51',
          'B10',
          'C48',
          'B59',
          'C43',
          'B1',
          'C29',
          'B37',
          'D55',
          'B13',
          'C42',
          'B32',
          'C5',
          'A42',
          'C13',
          'B50',
          'C53',
          'B18',
          'C52',
          'D1',
          'C33',
          'D2',
          'C44',
          'D22',
          'A46',
          'D57',
          'B5',
          'C21',
          'A53',
          'D51',
          'B11',
          'C36',
          'D17',
          'A41',
          'D18',
          'A40',
          'C12',
          'B37',
          'D54',
          'B22',
          'C26',
          'A57',
          'D21',
          'C45',
          'D24',
          'C37',
          'D25',
          'A51',
          'D59',
          'B12',
          'C38',
          'B30',
          'C27',
          'A60',
          'D58',
          'B39',
          'D57',
          'A15',
          'B52',
          'A2',
          'D7',
          'C43',
          'B60',
          'C53',
          'D12',
          'C35',
          'B50',
          'C58',
          'B51',
          'C15',
          'B25',
          'C26',
          'B42',
          'A5',
          'B43',
          'C3',
          'B28',
          'D42',
          'A44',
          'C7',
          'B20',
          'C25',
          'B23',
          'D36',
          'A13',
          'D56',
          'B5',
          'C10',
          'A47',
          'C9',
          'B4',
          'C1',
          'B8',
          'C47',
          'B47',
          'C43',
          'D21',
          'A33',
          'D22',
          'A51',
          'C37',
          'B12',
          'D54',
          'B23',
          'C24',
          'B50',
          'C57',
          'B51',
          'C36',
          'A59',
          'C26',
          'B29',
          'C4',
          'B17',
          'C20',
          'A2',
          'D13',
          'A39',
          'D16',
          'A21',
          'D40',
          'B26',
          'D39',
          'A18',
          'C1',
          'B41',
          'A3',
          'B25',
          'C25',
          'B42',
          'D46',
          'B34',
          'D45',
          'A27',
          'D6',
          'A51',
          'C34',
          'A44',
          'C15',
          'A45',
          'B60',
          'C48',
          'C3',
          'B5',
          'C20',
          'A53',
          'D49',
          'B35',
          'D48',
          'A9',
          'B51',
          'A4',
          'D34',
          'A50',
          'C38',
          'D25',
          'A58',
          'C4',
          'B30',
          'C18',
          'B54',
          'C17',
          'B44',
          'C37',
          'B48',
          'C22',
          'A52',
          'D31',
          'A41',
          'C32',
          'A42',
          'D23',
          'A38',
          'D11',
          'C56',
          'B51',
          'C15',
          'B26',
          'D57',
          'B7',
          'D50',
          'A51',
          'C38',
          'D15',
          'A55',
          'C39',
          'B31',
          'D44',
          'A46',
          'C14',
          'A56',
          'D18',
          'A30',
          'D19',
          'A41',
          'D30',
          'A35',
          'D10',
          'A3',
          'C20',
          'B3',
          'C2',
          'A54',
          'C35',
          'B51',
          'C47',
          'B8',
          'D46',
          'A13',
          'D55',
          'A12',
          'B57',
          'C24',
          'A9',
          'B52',
          'C44',
          'D28',
          'A58',
          'C60',
          'B22',
          'C10',
          'C52',
          'B55',
          'A38',
          'C11',
          'B16',
          'C50',
          'C7',
          'A45',
          'D45',
          'B42',
          'A6',
          'D43',
          'B2',
          'D51',
          'B34',
          'C9',
          'B19',
          'C54',
          'C13',
          'A55',
          'D14',
          'C58',
          'C17',
          'A50',
          'D4',
          'C57',
          'A57',
          'C26',
          'B51',
          'C14',
          'B24',
          'D36',
          'A14',
          'D37',
          'B26',
          'D56',
          'A33',
          'D20',
          'A37',
          'C27',
          'B28',
          'A5',
          'B29',
          'D39',
          'A22',
          'D41',
          'A23',
          'D18',
          'C36',
          'D16',
          'A6',
          'B44',
          'D49',
          'A10',
          'D50',
          'B17',
          'C53',
          'D4',
          'C41',
          'B13',
          'C43',
          'B1',
          'D55',
          'B21',
          'D52',
          'A12',
          'D35',
          'B23',
          'A1',
          'B39',
          'C60',
          'C18',
          'A49',
          'D5',
          'C55',
          'D13',
          'A40',
          'B56',
          'C52',
          'D11',
          'A36',
          'B54',
          'C44',
          'D14',
          'A20',
          'D40',
          'B1',
          'C42',
          'B33',
          'D47',
          'B35',
          'C9',
          'B40',
          'D43',
          'B6',
          'D42',
          'A5',
          'C22',
          'B8',
          'A29',
          'D1',
          'A47',
          'D57',
          'A58',
          'C59',
          'D2',
          'C40',
          'D26',
          'C38',
          'B19',
          'D54',
          'B9',
          'D58',
          'A49',
          'D25',
          'C47',
          'D23',
          'A46',
          'C33',
          'D3',
          'C58',
          'B20',
          'C13',
          'A43',
          'B58',
          'C56',
          'C15',
          'B8',
          'D30',
          'A5',
          'D11',
          'A34',
          'D49',
          'A19',
          'C4',
          'A41',
          'C30',
          'B1',
          'C18',
          'B2',
          'C9',
          'B47',
          'D53',
          'B22',
          'C42',
          'D20',
          'A31',
          'B52',
          'A7',
          'B45',
          'C17',
          'B28',
          'D41',
          'A29',
          'D47',
          'A15',
          'B46',
          'C29',
          'B38',
          'C13',
          'B51',
          'C60',
          'D6',
          'C56',
          'A57',
          'D56',
          'C45',
          'D19',
          'A46',
          'D16',
          'A19',
          'B31',
          'C4',
          'B7',
          'C46',
          'D59',
          'A50',
          'D7',
          'C34',
          'A54',
          'D49',
          'B36',
          'C3',
          'A41',
          'C5',
          'A59',
          'D26',
          'C47',
          'B14',
          'D56',
          'B38',
          'D40',
          'A22',
          'B3',
          'D52',
          'B4',
          'A24',
          'D24',
          'A60',
          'C44',
          'D23',
          'A21',
          'B35',
          'C51',
          'C9',
          'A36',
          'D58',
          'B25',
          'C24',
          'A11',
          'D53',
          'B16',
          'C50',
          'D11',
          'C35',
          'B11',
          'C2',
          'B12',
          'C23',
          'A54',
          'D13',
          'C54',
          'B58',
          'A14',
          'B10',
          'D51',
          'B19',
          'D34',
          'B26',
          'C25',
          'A8',
          'B4',
          'A22',
          'C9',
          'B13',
          'D60',
          'B29',
          'C17',
          'A57',
          'D39',
          'A43',
          'C32',
          'A44',
          'C6',
          'A55',
          'C5',
          'B2',
          'C30',
          'A49',
          'D3',
          'A30',
          'C19',
          'A52',
          'D9',
          'A53',
          'C12',
          'B25',
          'A3',
          'D12',
          'A34',
          'C24',
          'B20',
          'C38',
          'B45',
          'D50',
          'B34',
          'A1',
          'D33',
          'B19',
          'C5',
          'C49',
          'B58',
          'A13',
          'C25',
          'B27',
          'A4',
          'B52',
          'C15',
          'A48',
          'C36',
          'A50',
          'C33',
          'A40',
          'A24',
          'D32',
          'A45',
          'D48',
          'B44',
          'C4',
          'B32',
          'D49',
          'B17',
          'C1',
          'A17',
          'A33',
          'A15',
          'B12',
          'C49',
          'D2',
          'C25',
          'B19',
          'D31',
          'B10',
          'A31',
          'D55',
          'A14',
          'B52',
          'A30',
          'D52',
          'B37',
          'C55',
          'D6',
          'C51',
          'C11',
          'A39',
          'D17',
          'C40',
          'B12',
          'D46',
          'B43',
          'C14',
          'B7',
          'C21',
          'A1',
          'D13',
          'C35',
          'D16',
          'A11',
          'B51',
          'A16',
          'D47',
          'B5',
          'C2',
          'A20',
          'D38',
          'D23',
          'A58',
          'C28',
          'A60',
          'A3',
          'D15',
          'C44',
          'D53',
          'B36',
          'A2',
          'D31',
          'A45',
          'C8',
          'B33',
          'D43',
          'B41',
          'C20',
          'B16',
          'C3',
          'A56',
          'D37',
          'B22',
          'C26',
          'A10',
          'B6',
          'A27',
          'C16',
          'C58',
          'B28',
          'C36',
          'B44',
          'C19',
          'B31',
          'D47',
          'A49',
          'D24',
          'A51',
          'C21',
          'A32',
          'D8',
          'A37',
          'A15',
          'A40',
          'C29',
          'B2',
          'D56',
          'B23',
          'C11',
          'B49',
          'C37',
          'D21',
          'D36',
          'A52',
          'C18',
          'B13',
          'C40',
          'B32',
          'D48',
          'A17',
          'C27',
          'A19',
          'D12',
          'A8',
          'D47',
          'B35',
          'C50',
          'B53',
          'A34',
          'C8',
          'A47',
          'D2',
          'A29',
          'D46',
          'A27',
          'D20',
          'C41',
          'A60',
          'A2',
          'B43',
          'A1',
          'D34',
          'B23',
          'C26',
          'A58',
          'C59',
          'B39',
          'D41',
          'D26',
          'C39',
          'B60',
          'C52',
          'D12',
          'A13',
          'B10',
          'C7',
          'B33',
          'A20',
          'C6',
          'B11',
          'D51',
          'B20',
          'D30',
          'D14',
          'C57',
          'B19',
          'C54',
          'C12',
          'A24',
          'D50',
          'A9',
          'B56',
          'C22',
          'B6',
          'A26',
          'D6',
          'C42',
          'B22',
          'C41',
          'D27',
          'A36',
          'D18',
          'D40',
          'B28',
          'C16',
          'B53',
          'A4',
          'D9',
          'C34',
          'B44',
          'C32',
          'A39',
          'A7',
          'B46',
          'D52',
          'A11',
          'A38',
          'D29',
          'B7',
          'D43',
          'A25',
          'B32',
          'D46',
          'B13',
          'A15',
          'B1',
          'C2',
          'B27',
          'D58',
          'A37',
          'C10',
          'B15',
          'D60',
          'B41',
          'A23',
          'C4',
          'C47',
          'B9',
          'C5',
          'A51',
          'C36',
          'B12',
          'A31',
          'D42',
          'A48',
          'D1',
          'C50',
          'D7',
          'A28',
          'D54',
          'B3',
          'C19',
          'B46',
          'A14',
          'C28',
          'A16',
          'D59',
          'B11',
          'D31',
          'B24',
          'C11',
          'C53',
          'B50',
          'D6',
          'A36',
          'A20',
          'D49',
          'B45',
          'C6',
          'B33',
          'A17',
          'B15',
          'D57',
          'C45',
          'D3',
          'C26',
          'A37',
          'D19',
          'D39',
          'B37',
          'A22',
          'D50',
          'B19',
          'C37',
          'D22',
          'A36',
          'C1',
          'A51',
          'D21',
          'D36',
          'D12',
          'C59',
          'B30',
          'C14',
          'A44',
          'B59',
          'C56',
          'C41',
          'A57',
          'C55',
          'B56',
          'C50',
          'C8',
          'A60',
          'C7',
          'B39',
          'A31',
          'D48',
          'B2',
          'C46',
          'D16',
          'D42',
          'B32',
          'C20',
          'B17',
          'D53',
          'B18',
          'C52',
          'B50',
          'C25',
          'B9',
          'C16',
          'B44',
          'C59',
          'C19',
          'B55',
          'A11',
          'D54',
          'B24',
          'A2',
          'D29',
          'C47',
          'B59',
          'C54',
          'B37',
          'B52',
          'A20',
          'B4',
          'A7',
          'A41',
          'B57',
          'C55',
          'D26',
          'A50',
          'C32',
          'A54',
          'C12',
          'B50',
          'D57',
          'B25',
          'C56',
          'C17',
          'A28',
          'D35',
          'D13',
          'D43',
          'A26',
          'C29',
          'B39',
          'A4',
          'A41',
          'D33',
          'B17',
          'A34',
          'D24',
          'A23',
          'D39',
          'D9',
          'C44',
          'D19',
          'A51',
          'D7',
          'C49',
          'B16',
          'D58',
          'B10',
          'A12',
          'B44',
          'D47',
          'A14',
          'A37',
          'A9',
          'B24',
          'C24',
          'B9',
          'D48',
          'B34',
          'D45',
          'A44',
          'A4',
          'C23',
          'B21',
          'C14',
          'A41',
          'C2',
          'B13',
          'D34',
          'D13',
          'C41',
          'D24',
          'A42',
          'D21',
          'A32',
          'D59',
          'B40',
          'D42',
          'B27',
          'A12',
          'C26',
          'A59',
          'D51',
          'B37',
          'C43',
          'B29',
          'C58',
          'A58',
          'C4',
          'B6',
          'C21',
          'B8',
          'A28',
          'C7',
          'A56',
          'D17',
          'C45',
          'D4',
          'C52',
          'C37',
          'D25',
          'A25',
          'C13',
          'B30',
          'A6',
          'C22',
          'A55',
          'C42',
          'D21',
          'A16',
          'D38',
          'D10',
          'C49',
          'B35',
          'D37',
          'A40',
          'A9',
          'D51',
          'B16',
          'A17',
          'A35',
          'D15',
          'D30',
          'B24',
          'A37',
          'D7',
          'D40',
          'B30',
          'C1',
          'B2',
          'A6',
          'B54',
          'B34',
          'C57',
          'D2',
          'A20',
          'C27',
          'A57',
          'C16',
          'B10',
          'C5',
          'A19',
          'D37',
          'D10',
          'A36',
          'C28',
          'B31',
          'C39',
          'D16',
          'A27',
          'D34',
          'A10',
          'B7',
          'D60',
          'C45',
          'D18',
          'C38',
          'A52',
          'D7',
          'B47',
          'C46',
          'B50',
          'D6',
          'D42',
          'C41',
          'D43',
          'A5',
          'A39',
          'B55',
          'C18',
          'B14',
          'D28',
          'B17',
          'D32',
          'B23',
          'D53',
          'A33',
          'D44',
          'B3',
          'C6',
          'A26',
          'D54',
          'C44',
          'D4',
          'C43',
          'B21',
          'C59',
          'B41',
          'C20',
          'A31',
          'B40',
          'A1',
          'B45',
          'C5',
          'B33',
          'A25',
          'B59',
          'A43',
          'B52',
          'A6',
          'B31',
          'C15',
          'C57',
          'D3',
          'C54',
          'A56',
          'C53',
          'D8',
          'D40',
          'B3',
          'A21',
          'C8',
          'B21',
          'C22',
          'B57',
          'B32',
          'C60',
          'A41',
          'C12',
          'B42',
          'D44',
          'A49',
          'C9',
          'B20',
          'A20',
          'B5',
          'D41',
          'A44',
          'A3',
          'B26',
          'C48',
          'B10',
          'D45',
          'A12',
          'A36',
          'C11',
          'B56',
          'B33',
          'D50',
          'A11',
          'A60',
          'D54',
          'B48',
          'A17',
          'D14',
          'D29',
          'C45',
          'C29',
          'D5',
          'D44',
          'A25',
          'C30',
          'B3',
          'C21',
          'A5',
          'A40',
          'C32',
          'B52',
          'B35',
          'D53',
          'A54',
          'D11',
          'A53',
          'C23',
          'A34',
          'B15',
          'C53',
          'C14',
          'B39',
          'C13',
          'B21',
          'D33',
          'D13',
          'A37',
          'A8',
          'B23',
          'A7',
          'D12',
          'C51',
          'B17',
          'D58',
          'B28',
          'A17',
          'A35',
          'A16',
          'B53',
          'A43',
          'C31',
          'B1',
          'D51',
          'B46',
          'C8',
          'A44',
          'D17',
          'D32',
          'A41',
          'D29',
          'B50',
          'C55',
          'D11',
          'D37',
          'C40',
          'A60',
          'C27',
          'B14',
          'D34',
          'A49',
          'C29',
          'B60',
          'C47',
          'C1',
          'B42',
          'A4',
          'A42',
          'D35',
          'A17',
          'D46',
          'B33',
          'C60',
          'B43',
          'A24',
          'B59',
          'C53',
          'B54',
          'C4',
          'B37',
          'A19',
          'B14',
          'C46',
          'B7',
          'D50',
          'C43',
          'D14',
          'A45',
          'A3',
          'B38',
          'D39',
          'B50',
          'D37',
          'A17',
          'D59',
          'B3',
          'C12',
          'C50',
          'B57',
          'A11',
          'D42',
          'D7',
          'C48',
          'D1',
          'C58',
          'B43',
          'C27',
          'D4',
          'D46',
          'A47',
          'C14',
          'B31',
          'A39',
          'D55',
          'B13',
          'B29',
          'A14',
          'B1',
          'D60',
          'A40',
          'C13',
          'A52',
          'D32',
          'D17',
          'C39',
          'B18',
          'D49',
          'A21',
          'C3',
          'B6',
          'C20',
          'D1',
          'A46',
          'A2',
          'C19',
          'B4',
          'C9',
          'C55',
          'B51',
          'D34',
          'D14',
          'C35',
          'A48',
          'C10',
          'A38',
          'A8',
          'D48',
          'B16',
          'D36',
          'B50',
          'D56',
          'A54',
          'A3',
          'B42',
          'C11',
          'A24',
          'C31',
          'B53',
          'C49',
          'C34',
          'D4',
          'D45',
          'B30',
          'B58',
          'C32',
          'B4',
          'D47',
          'B11',
          'C1',
          'B25',
          'D55',
          'B22',
          'D33',
          'A26',
          'B8',
          'A10',
          'A35',
          'B54',
          'D34',
          'B20',
          'C37',
          'D23',
          'A50',
          'D58',
          'B40',
          'C60',
          'C22',
          'B60',
          'C51',
          'B28',
          'C35',
          'A59',
          'A1',
          'A46',
          'C17',
          'B29',
          'A5',
          'A40',
          'C4',
          'C52',
          'A56',
          'D60',
          'B16',
          'C19',
          'B41',
          'D45',
          'D9',
          'D39',
          'A20',
          'B21',
          'A36',
          'C27',
          'A38',
          'C1',
          'B50',
          'D21',
          'B10',
          'B30',
          'C26',
          'A15',
          'D28',
          'B3',
          'D49',
          'B26',
          'D33',
          'D18',
          'A19',
          'D11',
          'D37',
          'A51',
          'C40',
          'C58',
          'C37',
          'B29',
          'C3',
          'B43',
          'C57',
          'B50',
          'D12',
          'A53',
          'C6',
          'C51',
          'B55',
          'C43',
          'D7',
          'A29',
          'C30',
          'A30',
          'B36',
          'D40',
          'B49',
          'D58',
          'B8',
          'D43',
          'D5',
          'A1',
          'B23',
          'C10',
          'A50',
          'D20',
          'C38',
          'B10',
          'A10',
          'A36',
          'B22',
          'C27',
          'A9',
          'D47',
          'D3',
          'C50',
          'D6',
          'D41',
          'B27',
          'A23',
          'B2',
          'A5',
          'A43',
          'C6',
          'C48',
          'D10',
          'A1',
          'A47',
          'D45',
          'B12',
          'D59',
          'C51',
          'B29',
          'B60',
          'A18',
          'C3',
          'A55',
          'D54',
          'B30',
          'C17',
          'B45',
          'C60',
          'D33',
          'B55',
          'B33',
          'D44',
          'B42',
          'A28',
          'D47',
          'C42',
          'A58',
          'C25',
          'B22',
          'C23',
          'B12',
          'D52',
          'B47',
          'C8',
          'B34',
          'A17',
          'B52',
          'B37',
          'D50',
          'A57',
          'C54',
          'C10',
          'B6',
          'A20',
          'D51',
          'A26',
          'C14',
          'A48',
          'C60',
          'C36',
          'B29',
          'A18',
          'C28',
          'B1',
          'D42',
          'D8',
          'D40',
          'A27',
          'C15',
          'B32',
          'D45',
          'D4',
          'A30',
          'C6',
          'A59',
          'D29',
          'D14',
          'C56',
          'B34',
          'C7',
          'B46',
          'A25',
          'D53',
          'B36',
          'C2',
          'B43',
          'C35',
          'D17',
          'A29',
          'C8',
          'A35',
          'D50',
          'B16',
          'A21',
          'B17',
          'D27',
          'B4',
          'C3',
          'B54',
          'C52',
          'B15',
          'A33',
          'A13',
          'B45',
          'C34',
          'A52',
          'C11',
          'C26',
          'C45',
          'D22',
          'D37',
          'D12',
          'D36',
          'A40',
          'D14',
          'D29',
          'C44',
          'D30',
          'A20',
          'D17',
          'A12',
          'D54',
          'B20',
          'C58',
          'B32',
          'B8',
          'D55',
          'B9',
          'C49',
          'C9',
          'B22',
          'C14',
          'C59',
          'A48',
          'B60',
          'D48',
          'D2',
          'A5',
          'B7',
          'B40',
          'A30',
          'C21',
          'B18',
          'C16',
          'B26',
          'C57',
          'D27',
          'B50',
          'D35',
          'A52',
          'C1',
          'A60',
          'C44',
          'B14',
          'C26',
          'A16',
          'D60',
          'B30',
          'C43',
          'D1',
          'B28',
          'A38',
          'A6',
          'B24',
          'D29',
          'B8',
          'A5',
          'D16',
          'A55',
          'A7',
          'C22',
          'A54',
          'C4',
          'B18',
          'C20',
          'B2',
          'D59',
          'D43',
          'D5',
          'C53',
          'B37',
          'D40',
          'B47',
          'D51',
          'C43',
          'D19',
          'B50',
          'D55',
          'B4',
          'D46',
          'B24',
          'C46',
          'D28',
          'A33',
          'A12',
          'B28',
          'D38',
          'B49',
          'C51',
          'C29',
          'A41',
          'C55',
          'C18',
          'B57',
          'A22',
          'D15',
          'A45',
          'A3',
          'C35',
          'A46',
          'D52',
          'B14',
          'C43',
          'B33',
          'C21',
          'A54',
          'A2',
          'B25',
          'D2',
          'C24',
          'A35',
          'D59',
          'B24',
          'C45',
          'D1',
          'C39',
          'D19',
          'A34',
          'A12',
          'D32',
          'B56',
          'D12',
          'B36',
          'C58',
          'B41',
          'C10',
          'A23',
          'D42',
          'A30',
          'B10',
          'B25',
          'D48',
          'B45',
          'C32',
          'A51',
          'C17',
          'B12',
          'C49',
          'D34',
          'C39',
          'B22',
          'A20',
          'B38',
          'A2',
          'B52',
          'B8',
          'A3',
          'D40',
          'D8',
          'D38',
          'B34',
          'D45',
          'B7',
          'C23',
          'B49',
          'A42',
          'A7',
          'B6',
          'D49',
          'A8',
          'A38',
          'D9',
          'D39',
          'B47',
          'A16',
          'D58',
          'C14',
          'B59',
          'C49',
          'D60',
          'A34',
          'D47',
          'D3',
          'D46',
          'B31',
          'D41',
          'A9',
          'C23',
          'B56',
          'C51',
          'A56',
          'C56',
          'B51',
          'A10',
          'A36',
          'D49',
          'C45',
          'B2',
          'C5',
          'C21',
          'D2',
          'D48',
          'B26',
          'C43',
          'B34',
          'B49',
          'C34',
          'D8',
          'D31',
          'B23',
          'A41',
          'B56',
          'C49',
          'B15',
          'C18',
          'A29',
          'A14',
          'D46',
          'A45',
          'B38',
          'C57',
          'B27',
          'C16',
          'B29',
          'D57',
          'B39',
          'B8',
          'C20',
          'D2',
          'A3',
          'C24',
          'A58',
          'C58',
          'A48',
          'C4',
          'C51',
          'D56',
          'B6',
          'B44',
          'A24',
          'C5',
          'B30',
          'B59',
          'D49',
          'D1',
          'C36',
          'B19',
          'A23',
          'C28',
          'A49',
          'D4',
          'D44',
          'D6',
          'D41',
          'B46',
          'C6',
          'A60',
          'A16',
          'B26',
          'C27',
          'B29',
          'D53',
          'A13',
          'B1',
          'C8',
          'B35',
          'D52',
          'B18',
          'D51',
          'A54',
          'A1',
          'A47',
          'C19',
          'A48',
          'D43',
          'D5',
          'C41',
          'A58',
          'B14',
          'C2',
          'C47',
          'C25',
          'B42',
          'A2',
          'A45',
          'A24',
          'D54',
          'A29',
          'D52',
          'B5',
          'A8',
          'A40',
          'C11',
          'B57',
          'B31',
          'A6',
          'A42',
          'A4',
          'A43',
          'D28',
          'D13',
          'D33',
          'D18',
          'C46',
          'B8',
          'D22',
          'B9',
          'D54',
          'B50',
          'D17',
          'A17',
          'D44',
          'B7',
          'D31',
          'A9',
          'B55',
          'C54',
          'B21',
          'D51',
          'B36',
          'D37',
          'B53',
          'A15',
          'C29',
          'C44',
          'D57',
          'B49',
          'C50',
          'A56',
          'C14',
          'B24',
          'A8',
          'B47',
          'D30',
          'C43',
          'B57',
          'C10',
          'C34',
          'B5',
          'C9',
          'D53',
          'A60',
          'D58',
          'C57',
          'A48',
          'C55',
          'B58',
          'D50',
          'B27',
          'C37',
          'B11',
          'C22',
          'B22',
          'C13',
          'B43',
          'D45',
          'A30',
          'B41',
          'C16',
          'A55',
          'C2',
          'C53',
          'D13',
          'C60',
          'A47',
          'C33',
          'A54',
          'C31',
          'A28',
          'A11',
          'B29',
          'D42',
          'B30',
          'A43',
          'A4',
          'A44',
          'C5',
          'A1',
          'C36',
          'D22',
          'D37',
          'B3',
          'C18',
          'C34',
          'B11',
          'D20',
          'C46',
          'B51',
          'D26',
          'B14',
          'D55',
          'A56',
          'D46',
          'D3',
          'B24',
          'A26',
          'B36',
          'D48',
          'D2',
          'B27',
          'A4',
          'B37',
          'C12',
          'A42',
          'D54',
          'B25',
          'C47',
          'D16',
          'B51',
          'A3',
          'D32',
          'B10',
          'C50',
          'C6',
          'A32',
          'D9',
          'D38',
          'B47',
          'D50',
          'B22',
          'A58',
          'C57',
          'C17',
          'B33',
          'D59',
          'A59',
          'C60',
          'B6',
          'C59',
          'B42',
          'A25',
          'D10',
          'D35',
          'D11',
          'A9',
          'A36',
          'D57',
          'C50',
          'B58',
          'C40',
          'D38',
          'D9',
          'A2',
          'A46',
          'D56',
          'B4',
          'A21',
          'C7',
          'B8',
          'D42',
          'B40',
          'C29',
          'B15',
          'D52',
          'C54',
          'B42',
          'B57',
          'C57',
          'D10',
          'C53',
          'C28',
          'B2',
          'D53',
          'B6',
          'B21',
          'D43',
          'D6',
          'D41',
          'D7',
          'A36',
          'B24',
          'A5',
          'A40',
          'A8',
          'C35',
          'A50',
          'C21',
          'B20',
          'A39',
          'A11',
          'B44',
          'C58',
          'B33',
          'C16',
          'D52',
          'C45',
          'D25',
          'A48',
          'C54',
          'D6',
          'B23',
          'C5',
          'C29',
          'C10',
          'A51',
          'D8',
          'B37',
          'A3',
          'D3',
          'C56',
          'C8',
          'B40',
          'C3',
          'A57',
          'C53',
          'B17',
          'A18',
          'D60',
          'A52',
          'C40',
          'D14',
          'D29',
          'B12',
          'D49',
          'B33',
          'A7',
          'B55',
          'A43',
          'C15',
          'A55',
          'C25',
          'A33',
          'A11',
          'B5',
          'B53',
          'C44',
          'B1',
          'D56',
          'B49',
          'D30',
          'B11',
          'C48',
          'C7',
          'A42',
          'D34',
          'A2',
          'C6',
          'B20',
          'D26',
          'A31',
          'C9',
          'B14',
          'D47',
          'D2',
          'C58',
          'C15',
          'B53',
          'A33',
          'C22',
          'C47',
          'B28',
          'D43',
          'B33',
          'D58',
          'A38',
          'A6',
          'B6',
          'B52',
          'B13',
          'C24',
          'D3',
          'C49',
          'D11',
          'D31',
          'B46',
          'C41',
          'D44',
          'D4',
          'C56',
          'D27',
          'C47',
          'B29',
          'C35',
          'C8',
          'B57',
          'D51',
          'B60',
          'D50',
          'A18',
          'D12',
          'A40',
          'B35',
          'D38',
          'D9',
          'D25',
          'B1',
          'C17',
          'B13',
          'C14',
          'B31',
          'C3',
          'B44',
          'C57',
          'D21',
          'A15',
          'A30',
          'A14',
          'B30',
          'C26',
          'A17',
          'B17',
          'C15',
          'B9',
          'D41',
          'A48',
          'D33',
          'A9',
          'B22',
          'A8',
          'B26',
          'A7',
          'D47',
          'B25',
          'A26',
          'C17',
          'B55',
          'B36',
          'C56',
          'D56',
          'B8',
          'A2',
          'B35',
          'D13',
          'C30',
          'C50',
          'B27',
          'A57',
          'C60',
          'A54',
          'C24',
          'D4',
          'C22',
          'B48',
          'A7',
          'D44',
          'B18',
          'C1',
          'C54',
          'C27',
          'A49',
          'C39',
          'A60',
          'C19',
          'B2',
          'A23',
          'B28',
          'C18',
          'B40',
          'D41',
          'D7',
          'B41',
          'A5',
          'A41',
          'C33',
          'B43',
          'D48',
          'A32',
          'D43',
          'A14',
          'D58',
          'A30',
          'B55',
          'C4',
          'B7',
          'D60',
          'B31',
          'C59',
          'A47',
          'A24',
          'B60',
          'A22',
          'D31',
          'C45',
          'D28',
          'B12',
          'C37',
          'B56',
          'B4',
          'A18',
          'B11',
          'D52',
          'C14',
          'A9',
          'A36',
          'C25',
          'B27',
          'A42',
          'B58',
          'C57',
          'B51',
          'D56',
          'B39',
          'D43',
          'A41',
          'C6',
          'B18',
          'D48',
          'A20',
          'C9',
          'A4',
          'A44',
          'A3',
          'A45',
          'B40',
          'C15',
          'C52',
          'D55',
          'B23',
          'D49',
          'D1',
          'A19',
          'B38',
          'A1',
          'B52',
          'D38',
          'D10',
          'C56',
          'C18',
          'A49',
          'C11',
          'A58',
          'D57',
          'B22',
          'C60',
          'C17',
          'D50',
          'B60',
          'C14',
          'B7',
          'A4',
          'D1',
          'D49',
          'B37',
          'B9',
          'C23',
          'C45',
          'C27',
          'A11',
          'A34',
          'C9',
          'B15',
          'D25',
          'C53',
          'C3',
          'A42',
          'D20',
          'D40',
          'D8',
          'C50',
          'C10',
          'B48',
          'D41',
          'B1',
          'B21',
          'C28',
          'B32',
          'C8',
          'B53',
          'D36',
          'D12',
          'D35',
          'D15',
          'C40',
          'D39',
          'B44',
          'C16',
          'A49',
          'C54',
          'B38',
          'A32',
          'D53',
          'B8',
          'B43',
          'A41',
          'A8',
          'B5',
          'A7',
          'B51',
          'C25',
          'A56',
          'C23',
          'A57',
          'D26',
          'D11',
          'A35',
          'B14',
          'D49',
          'B27',
          'C44',
          'B45',
          'D42',
          'D5',
          'D46',
          'D3',
          'D45',
          'A21',
          'D41',
          'A4',
          'B28',
          'C42',
          'B29',
          'D52',
          'B33',
          'D17',
          'D32',
          'C39',
          'B32',
          'C20',
          'C48',
          'D23',
          'B18',
          'D59',
          'A46',
          'A2',
          'B44',
          'D47',
          'D2',
          'A15',
          'D60',
          'C58',
          'B6',
          'C57',
          'B19',
          'C53',
          'D49',
          'B34',
          'C42',
          'D3',
          'C60',
          'C13',
          'A56',
          'C7',
          'B57',
          'B2',
          'C11',
          'B16',
          'A33',
          'B46',
          'C29',
          'C44',
          'D58',
          'A47',
          'C56',
          'A58',
          'D38',
          'D10',
          'A52',
          'D55',
          'B5',
          'B45',
          'C57',
          'D5',
          'D42',
          'A32',
          'C26',
          'A45',
          'A3',
          'B26',
          'C2',
          'B4',
          'C1',
          'B53',
          'A20',
          'D36',
          'B35',
          'D46',
          'A8',
          'B27',
          'C49',
          'D51',
          'B57',
          'C6',
          'B3',
          'A23',
          'B40',
          'D53',
          'A10',
          'A39',
          'C13',
          'B29',
          'D44',
          'D4',
          'A48',
          'C25',
          'B58',
          'A12',
          'A30',
          'C16',
          'A50',
          'D18',
          'D33',
          'B45',
          'C37',
          'B50',
          'C58',
          'A53',
          'B22',
          'C5',
          'C51',
          'B17',
          'C38',
          'B31',
          'D54',
          'B59',
          'C32',
          'C49',
          'C19',
          'C55',
          'A55',
          'C39',
          'B13',
          'D27',
          'A14',
          'C27',
          'A21',
          'B19',
          'D52',
          'B56',
          'C21',
          'A37',
          'A9',
          'B11',
          'C50',
          'D9',
          'C58',
          'B42',
          'A22',
          'C3',
          'A44',
          'A59',
          'D57',
          'B24',
          'D50',
          'B10',
          'D56',
          'B27',
          'C43',
          'A39',
          'D53',
          'B3',
          'B54',
          'C41',
          'B30',
          'B45',
          'D39',
          'D6',
          'B50',
          'C34',
          'C4',
          'A1',
          'A46',
          'C60',
          'C7',
          'A57',
          'C52',
          'C29',
          'A42',
          'A6',
          'B22',
          'D8',
          'D40',
          'B9',
          'D53',
          'B59',
          'D52',
          'B20',
          'A37',
          'C12',
          'B29',
          'C46',
          'B15',
          'C2',
          'B49',
          'D38',
          'D10',
          'D37',
          'D22',
          'B47',
          'C32',
          'B19',
          'C23',
          'A36',
          'D31',
          'A43',
          'C8',
          'C51',
          'B48',
          'D39',
          'A17',
          'D58',
          'A51',
          'C5',
          'A59',
          'A9',
          'B3',
          'B46',
          'D52',
          'A52',
          'A24',
          'A53',
          'D48',
          'D1',
          'A49',
          'C59',
          'B40',
          'C2',
          'B28',
          'C25',
          'C10',
          'B36',
          'B11',
          'A7',
          'C12',
          'B50',
          'C50',
          'B17',
          'D24',
          'C36',
          'B3',
          'D36',
          'A55',
          'C54',
          'B4',
          'C53',
          'B42',
          'C43',
          'B18',
          'C38',
          'D21',
          'B32',
          'D47',
          'D2',
          'A2',
          'D15',
          'D35',
          'B53',
          'D60',
          'B34',
          'D54',
          'C55',
          'B23',
          'C15',
          'B28',
          'C50',
          'D12',
          'C40',
          'B30',
          'D41',
          'B37',
          'D48',
          'A60',
          'A9',
          'A30',
          'D56',
          'B2',
          'C33',
          'C12',
          'A23',
          'A53',
          'C57',
          'C16',
          'B11',
          'C48',
          'B12',
          'B28',
          'A15',
          'D45',
          'D3',
          'A22',
          'B15',
          'C27',
          'B21',
          'C7',
          'A31',
          'C30',
          'B50',
          'C2',
          'B51',
          'D55',
          'C45',
          'B7',
          'B43',
          'D40',
          'B39',
          'A32',
          'B56',
          'C17',
          'A29',
          'C42',
          'B36',
          'D50',
          'B60',
          'C50',
          'D58',
          'C35',
          'A51',
          'D30',
          'D15',
          'D34',
          'C60',
          'A47',
          'D39',
          'D7',
          'C54',
          'A58',
          'B52',
          'A42',
          'A5',
          'B20',
          'D51',
          'B59',
          'A23',
          'D52',
          'A45',
          'A3',
          'C17',
          'C32',
          'B10',
          'B42',
          'C8',
          'B19',
          'D22',
          'C52',
          'C3',
          'B6',
          'C47',
          'B48',
          'C38',
          'C7',
          'B38',
          'C60',
          'A54',
          'C30',
          'B27',
          'D48',
          'D1',
          'A23',
          'D25',
          'A34',
          'A11',
          'A56',
          'A10',
          'A39',
          'C5',
          'B45',
          'C40',
          'D18',
          'A16',
          'C2',
          'B52',
          'A35',
          'D14',
          'B33',
          'C20',
          'B54',
          'D60',
          'B8',
          'C49',
          'C11',
          'A42',
          'A26',
          'B26',
          'D59',
          'B13',
          'D56',
          'B58',
          'D55',
          'A40',
          'C42',
          'B1',
          'B48',
          'C36',
          'B6',
          'D30',
          'B57',
          'A43',
          'C5',
          'B3',
          'C21',
          'B11',
          'B41',
          'D43',
          'D6',
          'D22',
          'A58',
          'C7',
          'C57',
          'A54',
          'C15',
          'B18',
          'C12',
          'B39',
          'C54',
          'C25',
          'A59',
          'B49',
          'D37',
          'D13',
          'A21',
          'B36',
          'D56',
          'B28',
          'C59',
          'A50',
          'C11',
          'B3',
          'C2',
          'B20',
          'C32',
          'A27',
          'C7',
          'A34',
          'A11',
          'D32',
          'B45',
          'B4',
          'D41',
          'D7',
          'C47',
          'C21',
          'B48',
          'C9',
          'A37',
          'D5',
          'D45',
          'B23',
          'D51',
          'B59',
          'A15',
          'D42',
          'A4',
          'A44',
          'B34',
          'B12',
          'C3',
          'C60',
          'A60',
          'C59',
          'B33',
          'C5',
          'A20',
          'D15',
          'C37',
          'C9',
          'C50',
          'D50',
          'B60',
          'C28',
          'A19',
          'D29',
          'D14',
          'C42',
          'B14',
          'D46',
          'A12',
          'D56',
          'A43',
          'A26',
          'B49',
          'D22',
          'D37',
          'A30',
          'C15',
          'C34',
          'A9',
          'A39',
          'A6',
          'B11',
          'C7',
          'B51',
          'C55',
          'B3',
          'B47',
          'A5',
          'D8',
          'C43',
          'A57',
          'C10',
          'A33',
          'B17',
          'C5',
          'B53',
          'A56',
          'C49',
          'B9',
          'C22',
          'A29',
          'B46',
          'A42',
          'D33',
          'A49',
          'D48',
          'B5',
          'C19',
          'B35',
          'D52',
          'B22',
          'B40',
          'B12',
          'C18',
          'A14',
          'A31',
          'B56',
          'A16',
          'D15',
          'B53',
          'C46',
          'B25',
          'C32',
          'A52',
          'D50',
          'C6',
          'B31',
          'C27',
          'A42',
          'C2',
          'B55',
          'D53',
          'B41',
          'C24',
          'A48',
          'C51',
          'D5',
          'D42',
          'B38',
          'C5',
          'B46',
          'D51',
          'A30',
          'C12',
          'B26',
          'A52',
          'D24',
          'B15',
          'D56',
          'B31',
          'A1',
          'B24',
          'D38',
          'D11',
          'C54',
          'D59',
          'B28',
          'C32',
          'B2',
          'A44',
          'D5',
          'A22',
          'C31',
          'A40',
          'A8',
          'A41',
          'C13',
          'B12',
          'D60',
          'A33',
          'D50',
          'B60',
          'D49',
          'B46',
          'D42',
          'A43',
          'A5',
          'C35',
          'C9',
          'B26',
          'C1',
          'B52',
          'A57',
          'C18',
          'A27',
          'C29',
          'A13',
          'A31',
          'D10',
          'D34',
          'D15',
          'C55',
          'B59',
          'A1',
          'B5',
          'B44',
          'C15',
          'B58',
          'A24',
          'B30',
          'D57',
          'A34',
          'D44',
          'D4',
          'D47',
          'D2',
          'D46',
          'C52',
          'B4',
          'C21',
          'A51',
          'B27',
          'A26',
          'B10',
          'C49',
          'C6',
          'B11',
          'D33',
          'D18',
          'B32',
          'C27',
          'B1',
          'B58',
          'D54',
          'B39',
          'C53',
          'C37',
          'A59',
          'C34',
          'A11',
          'A35',
          'C44',
          'B13',
          'D41',
          'D7',
          'D39',
          'D8',
          'B33',
          'D51',
          'B59',
          'C52',
          'B41',
          'D52',
          'C48',
          'D3',
          'D45',
          'A53',
          'A4',
          'B22',
          'C4',
          'A58',
          'B25',
          'C14',
          'C29',
          'B20',
          'D56',
          'A15',
          'D38',
          'A41',
          'A7',
          'D48',
          'D1',
          'C25',
          'A45',
          'A2',
          'B37',
          'B52',
          'C26',
          'B23',
          'C23',
          'B38',
          'C56',
          'B40',
          'C14',
          'A57',
          'C59',
          'C37',
          'A49',
          'C58',
          'A50',
          'C27',
          'B10',
          'C11',
          'B6',
          'D57',
          'B57',
          'D56',
          'B32',
          'D44',
          'D4',
          'D43',
          'D6',
          'B1',
          'D58',
          'A48',
          'C8',
          'A22',
          'C13',
          'B31',
          'D48',
          'D1',
          'C55',
          'A56',
          'B55',
          'C41',
          'A40',
          'B36',
          'A17',
          'B38',
          'D55',
          'B15',
          'C26',
          'B43',
          'D45',
          'D3',
          'C40',
          'D27',
          'B14',
          'C16',
          'A11',
          'B30',
          'C46',
          'D21',
          'A34',
          'B24',
          'C6',
          'C39',
          'A50',
          'D46',
          'B20',
          'C57',
          'B35',
          'A8',
          'B10',
          'C47',
          'C7',
          'B39',
          'C43',
          'B19',
          'B3',
          'A43',
          'A5',
          'C23',
          'B50',
          'C56',
          'B44',
          'D34',
          'D19',
          'C36',
          'A2',
          'A46',
          'A1',
          'B8',
          'C24',
          'A55',
          'C5',
          'B51',
          'C32',
          'B54',
          'D59',
          'B29',
          'A44',
          'A4',
          'B23',
          'C13',
          'B59',
          'D51',
          'A42',
          'B50',
          'C48',
          'D17',
          'D42',
          'D5',
          'A9',
          'B4',
          'C31',
          'C9',
          'B36',
          'C28',
          'B37',
          'D47',
          'B42',
          'C7',
          'A32',
          'C15',
          'A4',
          'C8',
          'B12',
          'D54',
          'B13',
          'D50',
          'A19',
          'B19',
          'C36',
          'B30',
          'D53',
          'B58',
          'D52',
          'A12',
          'A37',
          'C2',
          'A60',
          'B48',
          'D39',
          'D8',
          'D36',
          'D13',
          'A55',
          'C38',
          'D30',
          'D15',
          'A54',
          'C51',
          'A57',
          'C9',
          'B56',
          'A39',
          'C28',
          'A41',
          'C41',
          'B5',
          'C2',
          'B43',
          'B6',
          'C19',
          'B44',
          'D46',
          'D2',
          'C52',
          'C5',
          'A52',
          'C17',
          'A47',
          'C60',
          'A48',
          'D24',
          'B46',
          'D40',
          'B14',
          'D25',
          'B21',
          'A13',
          'A31',
          'A12',
          'C33',
          'A49',
          'C53',
          'D54',
          'B26',
          'C52',
          'D57',
          'B7',
          'D56',
          'B11',
          'C47',
          'B52',
          'B17',
          'C3',
          'B27',
          'C48',
          'B16',
          'C10',
          'C60',
          'B31',
          'D22',
          'D37',
          'D10',
          'D31',
          'B5',
          'C55',
          'A47',
          'C59',
          'B57',
          'D55',
          'B40',
          'D54',
          'B54',
          'A18',
          'D34',
          'A46',
          'B45',
          'C39',
          'D35',
          'A5',
          'B11',
          'C33',
          'D59',
          'A60',
          'C17',
          'C45',
          'A59',
          'C4',
          'A28',
          'D57',
          'C36',
          'C7',
          'A19',
          'B53',
          'B33',
          'B18',
          'D54',
          'B1',
          'D47',
          'B19',
          'C31',
          'A29',
          'C20',
          'B56',
          'D58',
          'A58',
          'B49',
          'A8',
          'A43',
          'C27',
          'A51',
          'C19',
          'B46',
          'B4',
          'C13',
          'A55',
          'D59',
          'B48',
          'D52',
          'B58',
          'B24',
          'C26',
          'A18',
          'D10',
          'C50',
          'A48',
          'B56',
          'C54',
          'D51',
          'B59',
          'C46',
          'B33',
          'D43',
          'D6',
          'D42',
          'B2',
          'B47',
          'A25',
          'C43',
          'D23',
          'C42',
          'B37',
          'C3',
          'A57',
          'B54',
          'C19',
          'B58',
          'C53',
          'A55',
          'C15',
          'B14',
          'B39',
          'B24',
          'D49',
          'B60',
          'C13',
          'D57',
          'B8',
          'B42',
          'D53',
          'B19',
          'C1',
          'A39',
          'A9',
          'A37',
          'C44',
          'A38',
          'C20',
          'A50',
          'C57',
          'B31',
          'C21',
          'A19',
          'D22',
          'C46',
          'D17',
          'A43',
          'A5',
          'B21',
          'C10',
          'B6',
          'D28',
          'B8',
          'D47',
          'D1',
          'C41',
          'D25',
          'B24',
          'A4',
          'A44',
          'C27',
          'A58',
          'C51',
          'B40',
          'D12',
          'D32',
          'A8',
          'B55',
          'C1',
          'B28',
          'D51',
          'B2',
          'A22',
          'C2',
          'B1',
          'B52',
          'C37',
          'A47',
          'D33',
          'D18',
          'A51',
          'C18',
          'B58',
          'B11',
          'C17',
          'D2',
          'D46',
          'B25',
          'C56',
          'A53',
          'C11',
          'C34',
          'B6',
          'D41',
          'D7',
          'C51',
          'B3',
          'A19',
          'B20',
          'D44',
          'D4',
          'B23',
          'C44',
          'D55',
          'A28',
          'D45',
          'D3',
          'A31',
          'D49',
          'B60',
          'A6',
          'A39',
          'D52',
          'B55',
          'C56',
          'A54',
          'C4',
          'C40',
          'D40',
          'D9',
          'D39',
          'A37',
          'A12',
          'B45',
          'C22',
          'A10',
          'A34',
          'B52',
          'A21',
          'B58',
          'A44',
          'D28',
          'B1',
          'D34',
          'D10',
          'B8',
          'B37',
          'D59',
          'C49',
          'B47',
          'A9',
          'D18',
          'B7',
          'B44',
          'D43',
          'B29',
          'C25',
          'A14',
          'D57',
          'A57',
          'D46',
          'A32',
          'B43',
          'C59',
          'C2',
          'C45',
          'D9',
          'A37',
          'A7',
          'C36',
          'B45',
          'C33',
          'A42',
          'C6',
          'B4',
          'D49',
          'A45',
          'A3',
          'B53',
          'B12',
          'B35',
          'A44',
          'C21',
          'B10',
          'D44',
          'D4',
          'D43',
          'B22',
          'C59',
          'C29',
        ],
      },
    ],
  },
  {
    title: 'Frida Kahlo',
    id: 'frida-kahlo',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Frida%20Kahlo.PNG',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'A25',
          'A41',
          'A24',
          'A42',
          'A23',
          'B41',
          'A29',
          'B44',
          'A28',
          'C15',
          'A27',
          'B43',
          'B20',
          'B46',
          'B21',
          'B52',
          'B22',
          'B58',
          'B26',
          'D8',
          'B23',
          'D5',
          'B27',
          'D37',
          'B28',
          'D15',
          'B24',
          'D28',
          'B25',
          'D45',
          'B37',
          'D52',
          'B38',
          'D53',
          'B36',
          'D51',
          'B34',
          'D50',
          'B32',
          'D49',
          'B33',
          'D35',
          'B29',
          'D13',
          'B21',
          'D9',
          'B22',
          'D29',
          'B24',
          'B60',
          'B25',
          'D27',
          'B27',
          'B57',
          'B30',
          'D12',
          'B20',
          'D10',
          'B26',
          'D45',
          'B28',
          'D36',
          'B32',
          'D34',
          'B34',
          'A22',
          'A42',
          'A21',
          'C12',
          'A24',
          'C13',
          'A25',
          'C14',
          'A28',
          'B45',
          'A31',
          'C16',
          'A30',
          'B46',
          'A29',
          'C15',
          'A32',
          'C42',
          'A41',
          'C40',
          'A39',
          'C23',
          'A38',
          'C50',
          'A40',
          'C46',
          'A36',
          'C51',
          'A37',
          'C41',
          'A33',
          'C17',
          'A26',
          'B43',
          'A30',
          'B40',
          'D52',
          'B38',
          'A27',
          'B39',
          'A24',
          'B42',
          'A29',
          'C43',
          'A41',
          'C39',
          'A40',
          'C44',
          'A36',
          'C22',
          'A38',
          'C41',
          'A39',
          'C48',
          'A34',
          'C17',
          'A31',
          'B48',
          'D4',
          'B28',
          'D14',
          'D29',
          'D5',
          'B24',
          'D33',
          'D12',
          'D27',
          'B29',
          'B55',
          'A42',
          'C39',
          'A41',
          'C29',
          'A40',
          'C25',
          'A37',
          'C44',
          'A31',
          'B50',
          'A36',
          'B51',
          'D25',
          'B25',
          'D32',
          'B21',
          'D6',
          'B46',
          'A28',
          'C18',
          'A32',
          'B45',
          'B28',
          'D47',
          'B30',
          'D8',
          'D34',
          'B22',
          'D37',
          'B31',
          'D51',
          'B37',
          'D54',
          'B42',
          'D53',
          'B39',
          'D46',
          'B26',
          'D19',
          'B21',
          'D35',
          'D2',
          'A3',
          'B9',
          'A2',
          'B11',
          'A1',
          'B60',
          'D36',
          'D6',
          'A50',
          'D5',
          'B31',
          'D50',
          'B35',
          'A19',
          'A42',
          'A17',
          'A36',
          'C42',
          'A29',
          'C14',
          'A26',
          'C12',
          'D43',
          'D59',
          'D17',
          'D33',
          'B34',
          'A20',
          'C9',
          'A21',
          'B36',
          'D55',
          'B41',
          'B56',
          'C53',
          'A35',
          'C47',
          'A39',
          'C40',
          'A40',
          'C23',
          'A25',
          'C20',
          'A26',
          'B44',
          'D8',
          'D29',
          'D9',
          'D25',
          'B33',
          'D17',
          'B27',
          'D46',
          'B38',
          'D51',
          'D4',
          'D34',
          'B23',
          'D30',
          'B50',
          'D27',
          'B32',
          'D3',
          'A49',
          'C40',
          'A51',
          'D8',
          'B39',
          'A26',
          'C11',
          'D43',
          'C7',
          'D44',
          'B25',
          'D31',
          'B28',
          'D9',
          'A6',
          'B17',
          'A4',
          'D5',
          'B40',
          'A29',
          'C13',
          'D41',
          'C15',
          'C35',
          'A41',
          'C38',
          'A42',
          'B57',
          'C58',
          'B7',
          'C57',
          'B8',
          'C60',
          'B5',
          'C59',
          'B6',
          'C54',
          'B55',
          'C53',
          'A36',
          'B52',
          'D24',
          'B26',
          'D38',
          'B20',
          'D37',
          'B58',
          'A42',
          'C29',
          'A43',
          'B56',
          'D23',
          'B1',
          'D22',
          'C4',
          'D44',
          'D60',
          'D19',
          'A60',
          'D18',
          'B21',
          'D11',
          'A7',
          'D10',
          'B27',
          'D16',
          'A59',
          'D15',
          'B20',
          'D36',
          'B22',
          'D31',
          'B31',
          'A23',
          'B42',
          'D57',
          'C35',
          'C18',
          'A27',
          'C21',
          'A24',
          'C16',
          'A34',
          'C54',
          'B7',
          'C53',
          'A38',
          'C49',
          'A33',
          'C20',
          'D43',
          'C10',
          'A20',
          'C8',
          'A21',
          'B37',
          'A28',
          'B47',
          'D3',
          'A3',
          'B17',
          'A2',
          'D1',
          'A48',
          'C41',
          'A40',
          'C24',
          'A41',
          'C37',
          'C17',
          'A35',
          'C45',
          'A39',
          'C44',
          'A53',
          'C42',
          'A37',
          'C51',
          'B53',
          'A36',
          'B49',
          'D31',
          'D4',
          'B31',
          'D47',
          'B41',
          'A25',
          'C12',
          'A29',
          'B43',
          'D57',
          'D15',
          'B27',
          'D49',
          'B25',
          'D35',
          'B30',
          'D7',
          'D36',
          'B17',
          'A1',
          'B4',
          'C55',
          'B5',
          'C51',
          'B54',
          'B31',
          'D30',
          'B55',
          'C52',
          'A35',
          'C16',
          'D42',
          'C13',
          'A20',
          'B37',
          'D53',
          'C32',
          'C17',
          'D40',
          'B25',
          'D11',
          'D26',
          'B24',
          'D46',
          'B40',
          'A26',
          'B46',
          'D33',
          'D18',
          'B26',
          'D34',
          'D9',
          'B29',
          'A17',
          'B32',
          'B52',
          'A41',
          'C23',
          'C39',
          'C16',
          'A28',
          'C11',
          'A29',
          'C21',
          'A30',
          'C45',
          'C60',
          'B59',
          'C59',
          'B11',
          'C58',
          'D5',
          'D51',
          'B33',
          'D54',
          'C31',
          'D55',
          'B43',
          'D53',
          'D3',
          'B41',
          'D58',
          'C40',
          'A40',
          'C36',
          'D56',
          'B35',
          'A22',
          'B42',
          'A31',
          'C43',
          'A39',
          'C25',
          'A41',
          'B53',
          'C50',
          'B5',
          'A3',
          'B10',
          'C60',
          'B60',
          'A44',
          'B59',
          'D37',
          'D3',
          'D52',
          'B39',
          'D48',
          'B27',
          'D33',
          'D18',
          'C1',
          'C34',
          'D20',
          'B25',
          'D51',
          'D6',
          'A5',
          'B16',
          'A4',
          'C44',
          'A8',
          'D12',
          'B43',
          'B58',
          'C58',
          'B59',
          'B29',
          'A16',
          'B23',
          'D36',
          'B26',
          'D28',
          'C8',
          'D29',
          'B46',
          'D26',
          'B2',
          'D25',
          'B47',
          'A26',
          'C37',
          'A43',
          'B54',
          'A36',
          'C16',
          'C49',
          'B51',
          'D49',
          'B52',
          'A31',
          'C14',
          'A24',
          'B30',
          'D32',
          'B34',
          'D54',
          'B35',
          'B50',
          'A40',
          'C17',
          'A21',
          'C7',
          'D45',
          'C3',
          'D22',
          'D6',
          'A4',
          'B6',
          'C55',
          'A33',
          'C18',
          'C33',
          'D20',
          'C5',
          'D21',
          'B1',
          'D16',
          'A12',
          'B21',
          'D38',
          'B19',
          'D37',
          'D4',
          'C57',
          'B11',
          'C60',
          'B58',
          'A41',
          'C20',
          'D44',
          'B26',
          'D50',
          'D6',
          'B30',
          'A16',
          'A36',
          'C45',
          'A52',
          'C40',
          'C25',
          'A26',
          'B48',
          'D32',
          'B36',
          'D54',
          'C34',
          'D21',
          'C33',
          'D58',
          'C30',
          'A39',
          'C28',
          'A41',
          'C17',
          'C51',
          'B55',
          'D25',
          'B34',
          'A27',
          'C12',
          'A20',
          'D46',
          'B41',
          'D15',
          'A10',
          'B19',
          'A9',
          'C54',
          'B57',
          'A43',
          'B60',
          'C58',
          'B5',
          'C49',
          'A38',
          'A16',
          'B32',
          'D55',
          'C37',
          'A39',
          'C18',
          'A21',
          'B31',
          'D35',
          'B22',
          'D43',
          'C9',
          'A29',
          'B45',
          'D34',
          'D10',
          'A52',
          'C43',
          'A9',
          'D13',
          'A8',
          'C34',
          'D52',
          'B41',
          'A30',
          'C11',
          'D55',
          'B33',
          'B51',
          'C48',
          'B5',
          'A2',
          'C35',
          'C50',
          'A37',
          'C40',
          'A53',
          'C41',
          'C14',
          'D40',
          'B27',
          'D8',
          'A50',
          'D7',
          'D35',
          'B43',
          'D27',
          'C9',
          'B28',
          'D28',
          'B22',
          'A17',
          'B24',
          'A16',
          'B34',
          'D49',
          'B53',
          'C52',
          'A36',
          'A21',
          'C11',
          'A31',
          'B54',
          'D24',
          'B60',
          'D58',
          'D2',
          'A2',
          'B6',
          'A1',
          'B58',
          'C55',
          'D3',
          'A48',
          'C37',
          'A27',
          'C17',
          'B40',
          'D48',
          'B25',
          'D53',
          'B37',
          'D47',
          'A19',
          'D45',
          'C19',
          'A24',
          'B43',
          'A33',
          'B49',
          'C47',
          'B51',
          'D27',
          'D12',
          'C27',
          'D17',
          'C32',
          'D18',
          'B32',
          'D26',
          'B9',
          'A1',
          'B17',
          'D35',
          'B27',
          'D32',
          'B14',
          'C59',
          'D6',
          'A55',
          'C42',
          'A38',
          'C48',
          'C17',
          'B41',
          'C16',
          'A41',
          'C15',
          'D42',
          'C21',
          'C40',
          'A56',
          'D8',
          'C20',
          'D9',
          'D51',
          'C7',
          'D29',
          'B23',
          'D49',
          'A25',
          'C26',
          'A40',
          'C22',
          'D15',
          'B55',
          'D38',
          'B57',
          'B32',
          'A23',
          'D50',
          'B22',
          'A14',
          'B16',
          'A3',
          'D4',
          'C56',
          'A32',
          'B51',
          'A29',
          'C27',
          'D13',
          'B28',
          'C12',
          'D42',
          'B21',
          'D37',
          'B25',
          'D47',
          'B11',
          'C57',
          'C31',
          'A36',
          'C43',
          'D60',
          'B45',
          'A27',
          'B31',
          'D28',
          'B42',
          'A20',
          'D43',
          'C9',
          'B29',
          'D36',
          'B41',
          'A21',
          'B9',
          'D12',
          'B56',
          'C51',
          'A7',
          'C35',
          'A44',
          'C29',
          'D59',
          'C45',
          'B3',
          'C52',
          'B7',
          'C50',
          'B50',
          'A35',
          'C54',
          'D44',
          'D60',
          'C28',
          'D11',
          'A9',
          'C33',
          'D52',
          'A29',
          'C46',
          'B51',
          'A41',
          'C14',
          'A27',
          'C10',
          'B27',
          'D45',
          'A18',
          'B19',
          'D39',
          'C18',
          'A40',
          'C30',
          'D18',
          'D33',
          'B29',
          'C12',
          'A19',
          'A34',
          'B47',
          'D21',
          'C5',
          'D24',
          'B34',
          'D3',
          'A5',
          'D4',
          'A49',
          'D5',
          'C57',
          'B15',
          'A11',
          'B22',
          'D6',
          'C58',
          'B6',
          'C53',
          'C32',
          'D20',
          'A60',
          'D17',
          'A13',
          'D19',
          'C27',
          'C1',
          'D24',
          'B43',
          'C19',
          'A38',
          'C38',
          'A40',
          'B53',
          'C49',
          'B7',
          'C48',
          'B50',
          'A26',
          'C23',
          'D13',
          'C20',
          'A34',
          'C55',
          'D43',
          'C6',
          'A21',
          'B33',
          'D34',
          'D10',
          'A6',
          'D11',
          'A51',
          'B60',
          'B28',
          'D39',
          'C16',
          'A40',
          'B56',
          'B34',
          'D46',
          'C3',
          'D23',
          'C36',
          'A41',
          'C12',
          'B30',
          'C11',
          'B26',
          'D53',
          'A28',
          'B38',
          'D17',
          'C23',
          'C43',
          'A54',
          'D4',
          'C60',
          'B17',
          'A14',
          'B24',
          'D49',
          'B54',
          'C55',
          'D46',
          'B25',
          'D56',
          'B40',
          'A27',
          'C20',
          'B45',
          'D31',
          'B37',
          'A52',
          'D9',
          'A50',
          'C37',
          'A6',
          'D52',
          'B30',
          'D39',
          'B20',
          'A18',
          'D43',
          'A1',
          'B59',
          'B31',
          'A26',
          'C22',
          'A41',
          'C11',
          'A33',
          'B50',
          'B35',
          'A16',
          'A42',
          'B52',
          'D17',
          'A12',
          'A38',
          'C46',
          'C18',
          'B39',
          'D45',
          'A24',
          'C24',
          'D12',
          'A1',
          'D1',
          'A44',
          'C28',
          'D9',
          'C19',
          'B45',
          'D54',
          'C32',
          'A9',
          'B20',
          'D14',
          'C27',
          'D21',
          'C4',
          'B29',
          'C3',
          'D25',
          'C1',
          'C42',
          'A39',
          'C17',
          'D41',
          'B21',
          'D36',
          'B42',
          'A19',
          'C11',
          'B32',
          'A25',
          'B51',
          'D51',
          'A27',
          'B46',
          'D28',
          'C40',
          'A5',
          'B5',
          'D22',
          'B34',
          'D57',
          'A40',
          'C13',
          'B27',
          'C8',
          'B28',
          'C5',
          'A21',
          'B9',
          'D32',
          'B44',
          'D35',
          'B60',
          'D16',
          'C33',
          'C51',
          'B6',
          'D7',
          'C60',
          'D8',
          'A7',
          'B16',
          'A2',
          'C46',
          'B7',
          'D47',
          'B12',
          'A31',
          'C21',
          'C53',
          'D44',
          'B27',
          'C15',
          'C50',
          'D22',
          'B46',
          'D54',
          'A38',
          'C31',
          'D56',
          'B42',
          'C17',
          'A24',
          'D49',
          'B55',
          'A58',
          'D13',
          'A7',
          'D51',
          'C35',
          'A26',
          'D43',
          'C58',
          'B17',
          'A11',
          'B24',
          'D38',
          'B30',
          'D31',
          'B53',
          'D27',
          'B2',
          'C42',
          'B3',
          'C41',
          'D57',
          'B23',
          'A18',
          'D46',
          'A23',
          'B48',
          'D20',
          'B58',
          'C54',
          'D2',
          'A51',
          'D7',
          'C21',
          'D39',
          'B18',
          'A5',
          'D5',
          'A2',
          'B10',
          'D48',
          'B11',
          'D45',
          'B40',
          'A24',
          'C27',
          'C3',
          'C20',
          'A36',
          'C52',
          'B4',
          'A3',
          'A35',
          'B46',
          'C38',
          'D26',
          'C10',
          'A41',
          'C30',
          'C60',
          'D9',
          'C18',
          'A34',
          'A18',
          'D25',
          'C53',
          'B59',
          'A47',
          'D4',
          'D24',
          'C50',
          'C22',
          'C45',
          'A38',
          'D58',
          'B39',
          'D57',
          'C31',
          'B47',
          'A24',
          'B52',
          'C52',
          'C18',
          'A51',
          'B37',
          'A25',
          'B8',
          'A22',
          'C20',
          'D14',
          'A3',
          'D51',
          'A30',
          'B44',
          'D55',
          'A34',
          'C11',
          'B51',
          'A55',
          'D20',
          'B1',
          'B58',
          'D49',
          'A21',
          'D43',
          'C54',
          'A35',
          'C32',
          'D28',
          'B34',
          'C8',
          'B35',
          'A15',
          'B15',
          'D31',
          'B12',
          'A2',
          'B16',
          'D37',
          'B40',
          'C18',
          'A38',
          'D56',
          'A40',
          'C15',
          'B26',
          'C14',
          'B27',
          'C6',
          'D19',
          'C47',
          'B50',
          'D52',
          'B35',
          'D16',
          'C43',
          'B7',
          'C42',
          'C24',
          'A25',
          'B46',
          'D5',
          'A46',
          'D6',
          'D22',
          'A17',
          'B33',
          'C14',
          'D42',
          'B11',
          'C56',
          'B57',
          'C13',
          'A26',
          'C19',
          'A32',
          'B53',
          'D26',
          'C1',
          'C47',
          'B52',
          'D50',
          'C37',
          'A53',
          'B42',
          'D14',
          'A9',
          'C30',
          'D20',
          'B20',
          'D30',
          'B45',
          'A54',
          'D15',
          'A3',
          'B5',
          'C54',
          'D45',
          'B7',
          'D44',
          'B35',
          'D33',
          'C11',
          'D9',
          'C1',
          'A40',
          'D55',
          'B34',
          'C14',
          'B28',
          'D34',
          'B24',
          'A10',
          'D12',
          'C40',
          'A9',
          'C50',
          'A37',
          'C24',
          'B6',
          'C47',
          'B58',
          'A45',
          'D3',
          'A4',
          'B8',
          'A3',
          'B60',
          'A42',
          'C15',
          'A36',
          'A15',
          'B39',
          'D16',
          'C28',
          'D18',
          'A5',
          'D50',
          'D28',
          'B49',
          'A56',
          'C42',
          'D14',
          'C31',
          'D1',
          'A52',
          'C18',
          'A30',
          'B12',
          'D45',
          'C6',
          'D20',
          'B28',
          'C13',
          'B34',
          'A17',
          'D25',
          'C11',
          'D42',
          'C56',
          'A32',
          'B57',
          'A46',
          'C35',
          'D53',
          'B29',
          'C2',
          'D46',
          'B1',
          'B53',
          'C38',
          'A26',
          'D48',
          'C60',
          'C41',
          'D30',
          'B12',
          'A25',
          'D21',
          'C26',
          'A53',
          'D19',
          'A12',
          'B13',
          'A10',
          'C32',
          'D29',
          'D49',
          'B35',
          'C7',
          'A20',
          'D31',
          'A36',
          'C10',
          'B26',
          'C9',
          'A41',
          'B55',
          'C55',
          'B15',
          'C54',
          'C20',
          'A37',
          'D57',
          'A27',
          'B33',
          'A23',
          'B52',
          'D8',
          'A2',
          'B18',
          'A11',
          'A38',
          'B49',
          'A22',
          'D48',
          'B29',
          'C8',
          'B44',
          'C1',
          'B47',
          'C40',
          'D13',
          'B35',
          'D42',
          'A3',
          'C34',
          'A8',
          'D54',
          'B23',
          'D39',
          'B54',
          'C50',
          'D25',
          'C37',
          'B30',
          'D29',
          'D46',
          'B2',
          'B57',
          'C48',
          'A52',
          'B27',
          'A50',
          'B59',
          'D37',
          'D5',
          'C56',
          'A33',
          'B11',
          'C55',
          'B56',
          'A36',
          'C27',
          'A44',
          'D2',
          'A1',
          'C38',
          'D10',
          'C29',
          'B36',
          'A29',
          'D44',
          'C9',
          'A27',
          'D43',
          'A15',
          'B40',
          'C30',
          'D57',
          'C44',
          'A55',
          'B39',
          'D51',
          'C36',
          'A43',
          'C14',
          'A46',
          'D7',
          'C8',
          'B45',
          'D12',
          'A5',
          'B19',
          'A2',
          'D50',
          'A8',
          'B15',
          'D29',
          'A36',
          'A14',
          'D18',
          'C49',
          'C23',
          'C1',
          'B26',
          'C19',
          'B38',
          'A16',
          'B2',
          'D44',
          'A1',
          'D3',
          'C52',
          'D23',
          'C51',
          'C21',
          'D40',
          'A60',
          'D8',
          'C24',
          'A29',
          'B53',
          'D54',
          'A40',
          'D52',
          'C8',
          'D10',
          'A57',
          'B48',
          'C57',
          'A21',
          'C10',
          'B28',
          'B52',
          'C11',
          'B24',
          'D21',
          'D36',
          'A23',
          'C28',
          'A28',
          'D45',
          'C53',
          'B15',
          'A47',
          'C42',
          'D29',
          'D10',
          'D51',
          'B21',
          'A18',
          'B31',
          'D27',
          'A36',
          'C51',
          'B52',
          'C46',
          'A34',
          'B48',
          'A54',
          'D18',
          'B36',
          'B51',
          'C23',
          'A58',
          'C22',
          'D38',
          'B27',
          'C19',
          'A39',
          'C16',
          'B40',
          'D16',
          'A17',
          'D27',
          'C33',
          'D17',
          'B53',
          'A28',
          'C47',
          'D20',
          'B34',
          'D53',
          'A9',
          'B21',
          'D55',
          'C34',
          'D6',
          'B6',
          'C22',
          'A59',
          'B44',
          'A35',
          'C23',
          'D18',
          'A3',
          'D1',
          'A33',
          'D57',
          'D21',
          'A56',
          'C37',
          'B31',
          'D19',
          'C35',
          'A48',
          'D2',
          'C31',
          'A11',
          'D43',
          'A23',
          'B10',
          'D41',
          'B7',
          'D30',
          'C8',
          'A41',
          'C5',
          'B30',
          'D36',
          'B16',
          'C59',
          'D3',
          'D52',
          'A26',
          'D24',
          'C12',
          'A30',
          'B50',
          'C56',
          'B4',
          'D60',
          'B47',
          'C30',
          'D59',
          'C44',
          'A38',
          'C41',
          'A55',
          'C38',
          'B54',
          'A24',
          'B27',
          'C18',
          'D5',
          'C54',
          'A21',
          'C3',
          'B30',
          'A22',
          'D21',
          'A27',
          'B9',
          'B60',
          'D49',
          'B8',
          'D25',
          'B40',
          'A54',
          'B49',
          'C57',
          'D29',
          'D45',
          'B29',
          'C6',
          'A9',
          'D15',
          'C44',
          'A40',
          'C12',
          'C27',
          'D20',
          'A7',
          'B6',
          'C21',
          'B44',
          'C37',
          'B46',
          'A21',
          'D59',
          'B4',
          'C51',
          'C14',
          'B58',
          'C13',
          'B29',
          'D32',
          'C60',
          'B12',
          'A29',
          'C26',
          'A54',
          'D23',
          'B13',
          'D47',
          'B8',
          'D16',
          'B60',
          'C55',
          'A33',
          'B44',
          'B19',
          'A1',
          'C28',
          'A25',
          'B38',
          'C45',
          'D56',
          'B32',
          'D35',
          'D3',
          'D60',
          'D26',
          'B33',
          'C9',
          'D27',
          'C40',
          'B34',
          'D19',
          'B50',
          'A16',
          'B42',
          'A15',
          'B23',
          'A49',
          'C35',
          'B24',
          'C10',
          'D9',
          'A8',
          'D40',
          'C20',
          'A59',
          'D17',
          'A6',
          'D22',
          'C2',
          'A32',
          'B11',
          'D32',
          'C8',
          'B46',
          'D2',
          'A33',
          'C47',
          'A52',
          'C42',
          'C13',
          'A28',
          'D43',
          'A2',
          'B59',
          'A41',
          'C1',
          'D10',
          'A51',
          'C17',
          'B27',
          'D36',
          'A25',
          'C22',
          'B51',
          'C10',
          'B30',
          'C36',
          'A6',
          'B6',
          'C50',
          'B13',
          'A31',
          'C58',
          'D4',
          'C55',
          'D44',
          'A20',
          'D33',
          'B10',
          'A34',
          'D51',
          'B50',
          'D50',
          'B53',
          'A16',
          'D7',
          'C34',
          'D19',
          'C49',
          'B32',
          'D30',
          'C32',
          'D55',
          'B47',
          'A58',
          'C18',
          'B43',
          'B28',
          'C17',
          'D5',
          'B11',
          'C42',
          'D16',
          'A13',
          'B17',
          'C57',
          'A31',
          'C9',
          'B20',
          'D41',
          'B24',
          'A9',
          'C24',
          'A30',
          'B45',
          'C39',
          'A39',
          'C14',
          'A47',
          'B56',
          'D12',
          'C60',
          'D44',
          'B8',
          'D1',
          'A37',
          'C23',
          'D35',
          'A59',
          'A13',
          'D21',
          'C4',
          'B28',
          'C7',
          'B20',
          'B53',
          'D16',
          'A53',
          'D14',
          'A11',
          'B38',
          'D59',
          'A41',
          'D54',
          'C46',
          'A7',
          'C35',
          'D22',
          'C26',
          'B48',
          'A25',
          'B13',
          'D10',
          'B35',
          'C40',
          'C15',
          'A26',
          'B30',
          'D51',
          'D26',
          'C56',
          'D43',
          'C5',
          'B15',
          'C52',
          'D17',
          'A23',
          'C20',
          'B6',
          'C45',
          'B7',
          'C41',
          'B55',
          'C38',
          'D49',
          'B3',
          'D48',
          'D27',
          'A32',
          'B41',
          'B21',
          'D30',
          'D46',
          'C53',
          'A35',
          'C46',
          'A54',
          'C22',
          'C37',
          'A40',
          'C11',
          'A20',
          'C59',
          'B56',
          'D39',
          'B25',
          'D54',
          'B38',
          'C29',
          'B47',
          'A17',
          'B49',
          'C12',
          'D31',
          'C35',
          'A56',
          'D7',
          'C33',
          'A8',
          'D10',
          'C28',
          'B6',
          'A19',
          'B18',
          'A10',
          'D43',
          'C32',
          'B23',
          'A12',
          'B53',
          'D47',
          'B32',
          'D23',
          'C2',
          'C30',
          'A45',
          'B15',
          'A4',
          'D52',
          'B26',
          'C20',
          'C4',
          'B30',
          'C39',
          'D13',
          'B42',
          'C23',
          'B5',
          'C46',
          'B58',
          'D38',
          'A52',
          'D42',
          'B12',
          'D28',
          'A36',
          'C7',
          'B24',
          'C34',
          'D22',
          'A24',
          'B41',
          'D32',
          'A21',
          'C52',
          'C2',
          'B46',
          'A59',
          'B56',
          'C49',
          'A51',
          'C26',
          'A55',
          'D18',
          'B37',
          'A17',
          'D29',
          'B33',
          'D58',
          'B4',
          'D57',
          'A38',
          'D55',
          'B22',
          'C11',
          'B27',
          'D21',
          'A7',
          'B53',
          'D40',
          'C16',
          'A25',
          'C27',
          'A39',
          'A10',
          'C29',
          'D8',
          'B45',
          'A26',
          'D58',
          'A36',
          'D2',
          'A49',
          'D20',
          'A53',
          'B36',
          'A27',
          'B12',
          'C42',
          'B46',
          'C56',
          'B51',
          'D19',
          'A23',
          'C57',
          'D40',
          'B18',
          'A4',
          'D43',
          'A16',
          'B54',
          'A42',
          'D52',
          'C32',
          'D31',
          'B19',
          'A19',
          'C43',
          'A40',
          'D50',
          'B9',
          'D49',
          'D1',
          'C53',
          'D22',
          'D37',
          'B26',
          'C18',
          'D14',
          'B53',
          'A57',
          'C23',
          'D36',
          'B31',
          'C3',
          'C26',
          'B49',
          'A21',
          'B51',
          'D51',
          'A23',
          'C29',
          'B16',
          'A15',
          'D7',
          'D35',
          'C52',
          'C37',
          'B7',
          'D40',
          'B10',
          'D15',
          'C54',
          'A33',
          'D56',
          'B43',
          'D10',
          'C7',
          'D18',
          'A35',
          'D30',
          'B28',
          'C16',
          'A58',
          'C21',
          'A32',
          'C8',
          'B59',
          'A16',
          'B55',
          'C37',
          'A1',
          'B4',
          'C60',
          'B19',
          'B48',
          'D55',
          'C11',
          'B58',
          'C45',
          'A54',
          'C42',
          'C3',
          'A9',
          'C17',
          'A38',
          'D53',
          'A41',
          'D1',
          'C30',
          'D2',
          'A4',
          'D22',
          'A39',
          'D29',
          'B37',
          'C14',
          'B21',
          'A17',
          'B52',
          'C50',
          'A37',
          'C46',
          'D31',
          'C41',
          'B4',
          'D56',
          'B9',
          'D47',
          'B38',
          'A52',
          'B40',
          'C45',
          'C2',
          'D46',
          'B10',
          'A22',
          'B36',
          'A19',
          'B43',
          'C9',
          'D9',
          'A46',
          'C1',
          'A32',
          'C17',
          'D14',
          'B27',
          'A49',
          'C18',
          'B6',
          'D41',
          'A8',
          'C31',
          'A48',
          'D18',
          'C48',
          'B32',
          'D21',
          'A5',
          'D56',
          'A19',
          'D40',
          'B29',
          'C38',
          'D53',
          'A33',
          'B3',
          'A28',
          'B33',
          'C44',
          'B6',
          'C23',
          'D8',
          'C21',
          'A38',
          'B47',
          'C39',
          'B51',
          'C53',
          'D26',
          'D11',
          'B10',
          'D6',
          'A12',
          'D20',
          'C6',
          'B11',
          'D44',
          'C52',
          'B16',
          'C28',
          'D58',
          'C46',
          'B54',
          'D10',
          'C18',
          'A59',
          'D33',
          'B13',
          'A9',
          'D49',
          'B26',
          'A54',
          'C58',
          'D8',
          'B35',
          'C25',
          'D7',
          'C59',
          'B57',
          'C15',
          'B28',
          'A25',
          'C36',
          'A45',
          'D37',
          'A52',
          'D15',
          'C8',
          'D16',
          'A7',
          'B49',
          'A37',
          'C49',
          'A21',
          'B38',
          'C14',
          'D14',
          'A48',
          'B27',
          'C9',
          'B44',
          'C55',
          'C19',
          'A41',
          'C6',
          'D5',
          'A47',
          'C20',
          'B42',
          'C10',
          'A30',
          'D24',
          'C4',
          'A8',
          'D11',
          'C41',
          'B33',
          'D34',
          'A3',
          'B10',
          'C58',
          'B14',
          'D25',
          'C49',
          'B15',
          'A44',
          'B43',
          'A18',
          'A34',
          'C54',
          'D18',
          'B38',
          'C28',
          'B23',
          'C36',
          'B31',
          'C10',
          'A40',
          'D49',
          'D32',
          'A23',
          'B50',
          'A6',
          'B54',
          'C26',
          'A36',
          'A13',
          'B2',
          'D50',
          'D26',
          'D60',
          'A43',
          'B53',
          'C37',
          'B56',
          'C36',
          'A55',
          'B47',
          'D48',
          'B52',
          'D41',
          'B28',
          'D49',
          'A6',
          'B45',
          'A60',
          'C33',
          'D28',
          'A50',
          'B5',
          'C29',
          'A35',
          'C52',
          'D38',
          'D1',
          'A51',
          'B26',
          'C16',
          'B42',
          'B21',
          'C12',
          'B57',
          'D39',
          'C22',
          'B40',
          'A17',
          'D23',
          'C13',
          'A39',
          'D20',
          'C5',
          'D49',
          'B43',
          'A15',
          'B29',
          'A25',
          'B34',
          'C26',
          'B59',
          'C7',
          'B45',
          'D27',
          'A10',
          'D13',
          'C55',
          'C32',
          'B24',
          'C44',
          'A51',
          'D39',
          'C43',
          'A46',
          'C29',
          'A24',
          'B48',
          'C48',
          'A22',
          'B41',
          'D57',
          'D22',
          'C33',
          'A34',
          'D50',
          'A38',
          'D9',
          'A56',
          'C8',
          'D40',
          'B55',
          'A12',
          'D7',
          'D31',
          'C31',
          'B17',
          'A28',
          'C27',
          'B4',
          'B30',
          'C1',
          'B37',
          'A37',
          'C39',
          'B52',
          'C10',
          'B58',
          'C9',
          'B22',
          'A9',
          'D54',
          'B9',
          'A35',
          'D34',
          'A40',
          'B39',
          'A25',
          'D45',
          'C32',
          'B60',
          'A16',
          'D1',
          'D36',
          'C40',
          'B36',
          'C28',
          'C2',
          'A39',
          'C38',
          'A5',
          'C8',
          'C26',
          'B16',
          'C24',
          'A31',
          'C57',
          'B50',
          'C12',
          'B25',
          'A55',
          'C22',
          'D17',
          'B34',
          'A23',
          'B54',
          'D47',
          'D10',
          'D34',
          'B9',
          'C42',
          'A4',
          'A19',
          'C38',
          'D15',
          'C29',
          'D55',
          'B23',
          'A41',
          'B57',
          'C51',
          'A36',
          'D26',
          'B11',
          'A32',
          'B44',
          'D53',
          'A3',
          'C15',
          'A38',
          'D30',
          'B29',
          'C58',
          'C43',
          'B19',
          'C35',
          'A27',
          'C34',
          'D51',
          'B8',
          'D29',
          'D14',
          'A8',
          'D60',
          'A46',
          'C10',
          'B5',
          'A48',
          'C19',
          'B6',
          'C17',
          'B43',
          'C40',
          'A57',
          'C26',
          'D16',
          'C24',
          'B4',
          'D46',
          'D30',
          'B13',
          'D42',
          'B44',
          'A14',
          'D43',
          'C35',
          'B23',
          'D50',
          'B33',
          'D21',
          'C3',
          'B12',
          'A26',
          'B58',
          'D14',
          'C39',
          'B11',
          'C5',
          'D5',
          'A1',
          'A19',
          'C58',
          'D13',
          'C51',
          'D24',
          'B31',
          'A24',
          'D11',
          'A4',
          'B1',
          'C33',
          'D53',
          'A12',
          'B57',
          'C47',
          'A53',
          'D12',
          'C28',
          'B40',
          'C27',
          'D15',
          'B41',
          'A17',
          'B50',
          'A54',
          'C39',
          'B34',
          'B53',
          'C60',
          'B54',
          'C24',
          'A32',
          'D54',
          'D16',
          'C7',
          'D19',
          'C46',
          'A22',
          'B48',
          'C13',
          'B20',
          'A19',
          'D46',
          'A53',
          'D7',
          'A14',
          'B51',
          'A6',
          'C1',
          'B12',
          'A24',
          'B32',
          'A49',
          'D8',
          'C54',
          'B41',
          'D45',
          'C59',
          'B4',
          'C50',
          'B55',
          'A21',
          'B34',
          'C9',
          'D14',
          'C55',
          'A19',
          'C14',
          'A51',
          'C43',
          'B24',
          'C6',
          'B35',
          'A2',
          'B38',
          'D49',
          'D22',
          'D47',
          'D23',
          'C26',
          'C11',
          'A43',
          'B15',
          'A29',
          'B18',
          'D38',
          'B58',
          'D59',
          'B31',
          'C25',
          'B36',
          'A18',
          'B39',
          'D17',
          'C51',
          'D1',
          'B21',
          'D22',
          'D37',
          'A39',
          'D24',
          'A7',
          'B44',
          'D49',
          'C35',
          'D20',
          'C46',
          'D55',
          'A31',
          'B11',
          'C37',
          'A19',
          'D19',
          'B35',
          'A23',
          'D40',
          'A29',
          'A60',
          'C18',
          'A35',
          'C43',
          'B20',
          'A13',
          'D32',
          'C49',
          'B4',
          'C40',
          'B7',
          'A26',
          'B33',
          'D56',
          'C32',
          'B28',
          'D37',
          'C23',
          'C3',
          'B24',
          'C33',
          'D8',
          'C12',
          'B38',
          'C6',
          'A57',
          'C19',
          'B31',
          'C15',
          'B36',
          'D34',
          'A2',
          'B60',
          'C59',
          'B19',
          'A29',
          'A1',
          'B39',
          'D7',
          'B45',
          'D59',
          'B59',
          'B10',
          'A34',
          'B52',
          'A13',
          'D43',
          'C42',
          'D51',
          'A5',
          'B55',
          'D48',
          'C58',
          'D14',
          'C50',
          'A50',
          'B22',
          'A17',
          'B58',
          'C44',
          'A28',
          'B18',
          'A3',
          'B30',
          'A25',
          'B47',
          'C28',
          'D59',
          'D1',
          'C56',
          'D53',
          'D2',
          'D38',
          'A60',
          'D47',
          'A23',
          'B24',
          'C2',
          'A21',
          'D18',
          'C17',
          'B21',
          'C16',
          'B6',
          'A47',
          'B27',
          'D54',
          'B54',
          'C53',
          'B2',
          'C33',
          'A56',
          'C41',
          'B46',
          'A18',
          'B48',
          'D48',
          'D27',
          'C39',
          'D36',
          'C49',
          'D2',
          'D49',
          'D3',
          'D35',
          'B18',
          'C31',
          'D58',
          'B24',
          'C13',
          'B37',
          'A6',
          'B53',
          'C33',
          'D29',
          'C56',
          'B14',
          'A31',
          'D16',
          'C54',
          'B23',
          'A11',
          'D7',
          'A60',
          'D22',
          'D37',
          'A37',
          'C22',
          'B5',
          'C8',
          'B58',
          'A12',
          'C30',
          'A27',
          'C28',
          'C6',
          'B40',
          'D53',
          'C47',
          'D18',
          'A13',
          'B48',
          'A39',
          'B15',
          'D25',
          'A4',
          'B3',
          'C33',
          'D54',
          'B36',
          'C51',
          'B37',
          'D60',
          'B30',
          'D28',
          'A31',
          'C3',
          'C60',
          'D34',
          'A44',
          'D4',
          'C34',
          'A41',
          'D8',
          'D52',
          'A33',
          'B10',
          'C21',
          'A40',
          'B23',
          'C27',
          'A30',
          'C26',
          'D35',
          'A50',
          'C46',
          'B37',
          'C31',
          'B60',
          'A12',
          'D13',
          'A1',
          'D10',
          'B14',
          'D59',
          'D20',
          'B33',
          'A24',
          'C9',
          'A58',
          'D12',
          'A38',
          'D51',
          'B16',
          'C10',
          'B32',
          'A10',
          'D29',
          'D45',
          'C51',
          'A21',
          'B57',
          'C36',
          'A20',
          'D36',
          'B5',
          'B46',
          'D59',
          'A32',
          'D3',
          'D58',
          'A9',
          'D9',
          'B38',
          'C11',
          'B28',
          'A47',
          'C10',
          'D12',
          'A23',
          'B43',
          'C3',
          'B15',
          'C48',
          'D4',
          'B48',
          'D41',
          'C20',
          'A38',
          'D20',
          'B4',
          'D55',
          'B35',
          'A36',
          'C41',
          'D26',
          'B30',
          'C21',
          'C46',
          'C12',
          'A39',
          'B50',
          'A15',
          'D25',
          'C57',
          'B56',
          'A42',
          'B41',
          'D29',
          'C30',
          'D32',
          'C7',
          'A58',
          'A30',
          'B11',
          'C4',
          'A41',
          'D56',
          'B26',
          'D42',
          'A2',
          'C17',
          'A52',
          'D21',
          'C54',
          'B28',
          'A18',
          'C39',
          'D52',
          'B42',
          'C22',
          'D18',
          'C36',
          'B17',
          'C56',
          'D2',
          'A16',
          'B49',
          'A59',
          'A12',
          'D43',
          'B23',
          'C52',
          'B54',
          'A22',
          'B45',
          'D49',
          'D33',
          'A51',
          'C25',
          'D60',
          'B3',
          'A27',
          'B18',
          'C29',
          'D1',
          'C50',
          'D17',
          'C8',
          'A30',
          'B52',
          'B7',
          'A46',
          'B13',
          'C60',
          'C41',
          'D12',
          'C56',
          'B53',
          'A25',
          'B14',
          'D55',
          'B26',
          'A23',
          'C10',
          'A18',
          'D47',
          'C36',
          'D6',
          'A13',
          'B54',
          'D41',
          'A7',
          'C32',
          'B29',
          'A45',
          'B44',
          'C40',
          'B24',
          'D57',
          'C29',
          'D11',
          'D34',
          'C51',
          'C11',
          'B57',
          'A18',
          'B33',
          'C48',
          'D25',
          'B58',
          'C57',
          'B34',
          'C36',
          'D30',
          'A21',
          'C19',
          'C3',
          'A45',
          'C28',
          'D13',
          'B9',
          'A54',
          'C37',
          'B47',
          'B20',
          'A28',
          'C12',
          'B51',
          'A37',
          'C7',
          'B5',
          'C33',
          'D9',
          'A5',
          'D17',
          'D54',
          'B39',
          'C29',
          'D43',
          'A29',
          'B1',
          'C18',
          'B2',
          'D28',
          'C9',
          'D1',
          'A21',
          'B21',
          'C13',
          'A10',
          'C39',
          'D3',
          'D19',
          'D49',
          'A11',
          'B25',
          'D50',
          'B36',
          'A7',
          'D6',
          'A41',
          'C49',
          'D17',
          'A48',
          'D37',
          'B29',
          'C16',
          'B56',
          'A22',
          'B26',
          'A50',
          'C19',
          'A14',
          'D31',
          'C45',
          'B41',
          'A46',
          'D38',
          'C48',
          'B34',
          'A11',
          'B7',
          'D57',
          'A47',
          'C38',
          'D16',
          'B40',
          'A25',
          'C32',
          'D22',
          'B31',
          'C12',
          'B23',
          'D59',
          'C27',
          'B5',
          'D8',
          'C19',
          'A59',
          'C40',
          'B55',
          'C60',
          'A30',
          'B13',
          'D28',
          'A39',
          'C51',
          'A45',
          'B54',
          'D50',
          'C44',
          'B2',
          'D16',
          'C53',
          'A19',
          'D43',
          'D59',
          'A46',
          'C41',
          'B12',
          'D44',
          'B4',
          'D1',
          'A12',
          'D21',
          'A21',
          'D58',
          'D4',
          'C47',
          'B27',
          'D31',
          'A58',
          'D56',
          'A27',
          'B37',
          'B52',
          'A33',
          'D16',
          'A22',
          'C43',
          'A55',
          'C34',
          'D27',
          'D47',
          'B26',
          'D41',
          'A31',
          'C26',
          'D24',
          'C10',
          'D26',
          'B31',
          'A28',
          'B54',
          'B19',
          'C58',
          'D15',
          'B44',
          'C36',
          'D21',
          'C47',
          'A21',
          'C13',
          'B49',
          'C37',
          'B24',
          'C32',
          'B53',
          'A14',
          'A57',
          'C22',
          'C1',
          'D32',
          'C28',
          'D34',
          'C7',
          'B10',
          'A1',
          'B40',
          'C25',
          'D35',
          'B13',
          'A8',
          'D4',
          'C24',
          'D7',
          'A5',
          'C33',
          'B8',
          'B51',
          'A35',
          'B9',
          'A4',
          'B37',
          'C30',
          'D54',
          'B14',
          'A30',
          'B17',
          'B39',
          'D43',
          'C5',
          'A58',
          'B50',
          'A2',
          'D11',
          'C48',
          'B36',
          'D19',
          'C33',
          'B56',
          'D40',
          'A20',
          'C53',
          'A34',
          'D45',
          'A51',
          'D13',
          'B57',
          'D39',
          'A9',
          'C23',
          'D38',
          'B6',
          'A37',
          'C43',
          'A42',
          'D51',
          'D10',
          'A5',
          'B29',
          'C40',
          'B37',
          'B21',
          'A8',
          'C25',
          'B41',
          'C11',
          'A45',
          'C2',
          'B11',
          'D46',
          'C20',
          'B47',
          'D42',
          'B51',
          'C9',
          'A40',
          'D60',
          'B12',
          'D57',
          'B31',
          'C49',
          'B13',
          'D11',
          'C59',
          'D12',
          'C43',
          'B21',
          'A19',
          'B30',
          'A2',
          'B45',
          'A1',
          'D54',
          'B47',
          'C14',
          'A38',
          'C35',
          'A50',
          'D27',
          'B57',
          'C10',
          'A56',
          'A31',
          'C2',
          'B54',
          'C59',
          'C15',
          'B6',
          'D5',
          'D37',
          'B30',
          'A15',
          'B33',
          'D23',
          'C46',
          'D60',
          'B19',
          'A27',
          'B20',
          'A51',
          'C6',
          'B59',
          'A17',
          'B48',
          'C40',
          'D26',
          'D11',
          'A47',
          'B29',
          'D29',
          'A35',
          'C52',
          'B56',
          'C14',
          'D18',
          'D33',
          'B26',
          'A21',
          'C16',
          'A37',
          'D35',
          'D5',
          'C34',
          'A56',
          'D23',
          'A5',
          'B36',
          'C47',
          'B39',
          'C46',
          'C15',
          'B40',
          'C21',
          'B22',
          'A13',
          'C20',
          'B10',
          'A33',
          'C19',
          'D13',
          'C10',
          'D31',
          'A1',
          'B46',
          'C55',
          'D15',
          'C43',
          'D45',
          'A40',
          'B57',
          'C46',
          'D30',
          'C33',
          'B15',
          'A3',
          'B37',
          'D55',
          'B52',
          'D53',
          'A10',
          'B34',
          'D56',
          'B21',
          'A15',
          'C60',
          'C14',
          'A52',
          'B8',
          'A46',
          'D42',
          'C8',
          'B22',
          'A8',
          'B24',
          'C31',
          'B39',
          'C28',
          'D20',
          'B12',
          'A23',
          'B44',
          'A3',
          'D9',
          'B14',
          'A16',
          'D8',
          'A12',
          'B2',
          'A36',
          'B7',
          'A10',
          'B38',
          'D46',
          'C57',
          'A32',
          'C12',
          'B56',
          'C48',
          'D19',
          'C7',
          'D51',
          'B22',
          'C16',
          'B30',
          'C38',
          'D17',
          'C55',
          'B14',
          'C50',
          'B10',
          'C18',
          'D39',
          'B48',
          'C25',
          'B50',
          'A1',
          'C36',
          'A26',
          'B28',
          'C53',
          'D27',
          'B7',
          'A24',
          'B25',
          'D43',
          'B43',
          'D5',
          'D53',
          'C31',
          'A39',
          'C24',
          'B17',
          'C33',
          'B46',
          'C51',
          'A49',
          'D37',
          'B59',
          'D36',
          'C25',
          'B53',
          'A23',
          'D15',
          'C39',
          'D46',
          'C18',
          'A50',
          'D34',
          'C27',
          'B47',
          'A35',
          'C8',
          'B1',
          'A17',
          'D30',
          'A54',
          'D55',
          'B24',
          'C58',
          'C43',
          'B25',
          'D38',
          'C19',
          'A15',
          'B36',
          'C57',
          'B38',
          'D17',
          'B12',
          'A11',
          'B31',
          'D52',
          'B14',
          'C34',
          'A29',
          'B16',
          'A43',
          'C30',
          'D31',
          'C29',
          'B37',
          'D50',
          'B57',
          'A6',
          'D6',
          'A1',
          'D45',
          'B42',
          'C45',
          'B50',
          'D28',
          'C31',
          'B46',
          'C40',
          'C60',
          'D55',
          'A46',
          'C21',
          'D12',
          'C53',
          'B33',
          'A13',
          'B56',
          'A20',
          'B9',
          'A35',
          'B32',
          'A19',
          'D24',
          'C36',
          'B7',
          'D48',
          'A7',
          'B22',
          'C7',
          'B58',
          'C43',
          'B2',
          'D59',
          'B47',
          'D15',
          'C34',
          'D26',
          'A39',
          'A60',
          'D11',
          'C40',
          'A38',
          'B23',
          'D27',
          'B30',
          'C32',
          'B36',
          'C2',
          'B5',
          'C25',
          'B54',
          'C23',
          'A42',
          'A58',
          'A14',
          'B1',
          'C38',
          'B43',
          'C10',
          'D40',
          'B44',
          'A27',
          'A3',
          'B38',
          'C51',
          'A59',
          'D21',
          'D51',
          'B52',
          'C37',
          'D3',
          'A9',
          'B34',
          'C41',
          'B42',
          'C11',
          'A52',
          'C1',
          'B10',
          'D47',
          'B55',
          'D41',
          'B11',
          'A28',
          'C35',
          'B44',
          'D60',
          'C24',
          'C9',
          'D15',
          'D35',
          'D7',
          'B20',
          'A10',
          'B40',
          'C14',
          'B9',
          'C59',
          'A30',
          'C15',
          'B33',
          'C58',
          'D57',
          'A39',
          'C11',
          'B50',
          'C55',
          'A32',
          'B10',
          'D60',
          'A9',
          'D30',
          'C31',
          'D17',
          'A22',
          'D29',
          'A55',
          'B52',
          'C9',
          'D31',
          'A35',
          'C45',
          'A37',
          'C32',
          'B3',
          'D50',
          'B24',
          'C57',
          'D11',
          'B34',
          'D21',
          'C52',
          'B35',
          'A28',
          'A3',
          'D44',
          'B22',
          'A43',
          'B29',
          'A4',
          'B4',
          'C11',
          'D10',
          'B54',
          'C37',
          'B1',
          'C22',
          'D36',
          'C7',
          'A40',
          'D48',
          'B16',
          'D28',
          'A13',
          'D52',
          'B15',
          'C30',
          'D9',
          'C56',
          'D13',
          'A42',
          'D5',
          'A9',
          'B42',
          'A25',
          'C39',
          'A45',
          'C27',
          'D22',
          'B26',
          'C18',
          'B3',
          'D58',
          'B10',
          'D56',
          'B12',
          'D12',
          'B47',
          'A7',
          'C41',
          'A50',
          'C31',
          'B49',
          'D52',
          'C36',
          'B11',
          'C35',
          'A53',
          'C23',
          'B60',
          'C10',
          'B21',
          'B50',
          'C24',
          'D35',
          'D20',
          'C5',
          'D45',
          'D3',
          'A47',
          'D38',
          'B58',
          'A52',
          'C38',
          'B34',
          'C33',
          'B59',
          'C57',
          'D7',
          'C36',
          'B1',
          'A28',
          'B32',
          'A22',
          'C11',
          'B9',
          'C4',
          'C53',
          'A34',
          'C47',
          'D22',
          'A43',
          'C7',
          'D40',
          'B56',
          'B4',
          'D30',
          'B14',
          'B33',
          'C42',
          'A42',
          'B30',
          'D25',
          'C54',
          'D47',
          'A27',
          'C48',
          'B38',
          'D55',
          'B12',
          'D41',
          'A40',
          'C31',
          'D19',
          'B37',
          'A19',
          'B45',
          'D43',
          'A5',
          'A24',
          'D14',
          'C40',
          'B50',
          'C15',
          'C30',
          'D33',
          'A22',
          'D20',
          'B35',
          'A21',
          'C44',
          'A41',
          'D9',
          'A7',
          'B48',
          'A3',
          'D58',
          'A43',
          'D4',
          'C22',
          'B7',
          'C34',
          'D16',
          'C52',
          'A36',
          'D24',
          'A53',
          'B43',
          'A8',
          'D31',
          'C26',
          'D34',
          'B12',
          'A10',
          'B15',
          'D23',
          'C11',
          'A35',
          'C28',
          'D55',
          'B28',
          'A6',
          'D40',
          'B24',
          'C5',
          'D49',
          'A3',
          'B49',
          'C58',
          'A20',
          'B19',
          'C31',
          'B53',
          'D42',
          'A51',
          'C4',
          'A55',
          'B36',
          'D6',
          'B50',
          'C54',
          'B60',
          'A50',
          'C9',
          'A28',
          'D5',
          'A13',
          'B59',
          'D27',
          'C32',
          'A29',
          'C19',
          'B39',
          'C26',
          'A48',
          'B3',
          'D47',
          'C17',
          'C53',
          'D13',
          'A38',
          'B33',
          'B18',
          'A43',
          'D28',
          'D43',
          'A30',
          'A59',
          'C39',
          'A46',
          'B14',
          'D45',
          'C50',
          'B22',
          'C6',
          'B42',
          'D44',
          'A40',
          'B49',
          'A24',
          'D6',
          'C22',
          'D38',
          'B28',
          'C3',
          'B32',
          'A6',
          'D20',
          'C55',
          'A33',
          'D46',
          'B39',
          'D13',
          'C48',
          'D57',
          'A57',
          'C14',
          'D19',
          'C29',
          'A29',
          'B23',
          'A17',
          'B53',
          'C54',
          'D9',
          'C42',
          'A22',
          'B50',
          'C35',
          'B13',
          'C8',
          'D18',
          'C51',
          'A36',
          'C47',
          'D25',
          'C11',
          'B20',
          'A46',
          'B43',
          'A34',
          'D8',
          'D35',
          'C50',
          'A20',
          'B35',
          'C33',
          'D26',
          'A18',
          'D48',
          'A21',
          'B58',
          'C36',
          'A47',
          'C7',
          'B1',
          'B16',
          'C25',
        ],
      },
    ],
  },
  {
    title: 'Lion',
    id: 'lion',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Lion%202.PNG',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'A1',
          'A17',
          'C60',
          'A20',
          'C59',
          'A23',
          'A8',
          'A26',
          'B7',
          'A51',
          'B14',
          'A41',
          'B18',
          'A40',
          'B20',
          'A38',
          'B30',
          'A48',
          'B8',
          'A25',
          'B9',
          'A42',
          'B12',
          'A24',
          'C60',
          'A27',
          'C59',
          'A28',
          'B6',
          'A44',
          'B36',
          'A47',
          'B2',
          'A29',
          'B21',
          'A26',
          'A5',
          'A32',
          'B20',
          'A30',
          'B14',
          'A36',
          'B30',
          'A40',
          'B10',
          'A25',
          'B22',
          'A24',
          'A7',
          'A43',
          'B7',
          'D17',
          'B8',
          'A16',
          'B15',
          'A41',
          'B29',
          'A51',
          'C41',
          'A52',
          'C39',
          'A53',
          'C38',
          'C2',
          'D45',
          'C16',
          'D49',
          'C11',
          'D54',
          'D38',
          'C12',
          'D42',
          'C18',
          'D44',
          'C7',
          'D51',
          'C15',
          'D37',
          'C13',
          'D46',
          'C26',
          'D48',
          'C22',
          'D41',
          'C20',
          'D43',
          'C9',
          'D49',
          'C5',
          'D32',
          'C14',
          'D35',
          'C17',
          'D36',
          'C16',
          'C34',
          'A40',
          'B31',
          'A35',
          'C60',
          'A37',
          'A5',
          'A44',
          'B1',
          'C54',
          'B2',
          'A43',
          'B28',
          'A52',
          'B38',
          'A47',
          'B43',
          'A51',
          'C40',
          'A55',
          'C48',
          'A56',
          'C47',
          'A57',
          'B13',
          'A42',
          'A12',
          'A48',
          'B35',
          'A35',
          'A7',
          'A29',
          'C59',
          'A30',
          'B30',
          'A26',
          'A3',
          'A18',
          'B14',
          'D21',
          'B10',
          'D19',
          'B7',
          'D16',
          'B8',
          'A10',
          'B9',
          'A2',
          'A22',
          'B13',
          'A19',
          'B18',
          'A39',
          'C38',
          'A43',
          'C31',
          'D50',
          'C25',
          'C4',
          'D38',
          'C10',
          'D29',
          'C7',
          'D30',
          'C6',
          'C34',
          'D54',
          'D32',
          'D60',
          'D44',
          'C27',
          'A37',
          'C59',
          'A38',
          'C35',
          'A48',
          'B37',
          'A40',
          'C32',
          'D55',
          'D9',
          'C2',
          'C31',
          'A44',
          'B11',
          'D16',
          'B14',
          'A15',
          'B15',
          'D18',
          'B6',
          'A27',
          'B20',
          'A55',
          'C39',
          'C1',
          'D30',
          'D56',
          'C30',
          'A45',
          'C38',
          'A47',
          'B41',
          'A52',
          'C37',
          'A53',
          'B27',
          'D27',
          'C8',
          'A7',
          'A36',
          'A2',
          'A26',
          'A1',
          'A33',
          'B19',
          'A57',
          'C50',
          'A58',
          'D10',
          'A60',
          'C23',
          'B1',
          'D13',
          'B6',
          'A11',
          'C7',
          'C24',
          'B2',
          'D11',
          'D58',
          'D12',
          'B4',
          'C27',
          'D45',
          'D29',
          'C15',
          'A12',
          'C6',
          'D23',
          'B16',
          'D20',
          'B25',
          'D22',
          'B12',
          'A3',
          'A44',
          'C60',
          'A45',
          'B43',
          'B3',
          'A29',
          'B31',
          'A50',
          'C42',
          'A51',
          'C38',
          'A36',
          'C32',
          'A41',
          'B36',
          'A30',
          'C58',
          'A38',
          'C26',
          'B4',
          'B32',
          'B16',
          'D18',
          'C3',
          'D39',
          'C23',
          'D40',
          'C21',
          'A43',
          'B38',
          'A40',
          'C40',
          'D60',
          'D10',
          'D59',
          'D33',
          'D53',
          'C35',
          'A39',
          'C58',
          'C4',
          'D23',
          'D58',
          'D24',
          'D57',
          'D26',
          'C42',
          'A54',
          'B21',
          'A15',
          'C8',
          'A10',
          'C11',
          'A24',
          'A6',
          'C9',
          'D22',
          'B26',
          'D21',
          'B9',
          'D20',
          'C7',
          'A16',
          'C20',
          'A23',
          'B12',
          'D16',
          'B3',
          'C24',
          'B5',
          'D17',
          'B22',
          'A53',
          'C36',
          'A55',
          'D3',
          'C1',
          'D9',
          'D48',
          'D32',
          'C53',
          'A59',
          'C50',
          'A60',
          'C48',
          'B17',
          'A42',
          'D59',
          'C41',
          'B27',
          'A27',
          'C10',
          'C30',
          'A46',
          'B42',
          'A35',
          'C39',
          'A38',
          'B38',
          'A55',
          'C4',
          'C35',
          'C9',
          'D26',
          'D55',
          'D30',
          'B28',
          'A42',
          'C32',
          'C17',
          'A10',
          'B47',
          'A9',
          'C22',
          'A59',
          'C49',
          'B2',
          'D13',
          'B8',
          'C30',
          'A32',
          'A13',
          'A34',
          'A1',
          'A36',
          'C28',
          'D43',
          'B40',
          'A48',
          'B33',
          'A2',
          'B10',
          'D18',
          'C8',
          'D28',
          'D57',
          'B53',
          'D56',
          'D40',
          'B25',
          'C38',
          'D23',
          'C2',
          'D5',
          'C1',
          'D7',
          'D60',
          'D13',
          'B12',
          'C42',
          'B13',
          'A3',
          'A32',
          'C15',
          'A45',
          'C31',
          'C7',
          'A23',
          'C14',
          'A14',
          'B18',
          'C51',
          'A58',
          'D3',
          'A57',
          'C5',
          'A13',
          'C24',
          'B6',
          'C27',
          'C11',
          'A26',
          'B36',
          'C42',
          'D58',
          'D13',
          'D56',
          'A39',
          'B29',
          'A49',
          'C38',
          'A40',
          'C58',
          'A41',
          'C34',
          'B24',
          'A53',
          'C19',
          'D34',
          'D52',
          'D35',
          'D51',
          'C36',
          'C3',
          'D8',
          'A59',
          'C39',
          'B35',
          'C51',
          'B14',
          'A3',
          'A19',
          'B20',
          'A18',
          'C12',
          'A23',
          'B28',
          'D27',
          'C44',
          'B15',
          'D15',
          'D55',
          'D30',
          'C50',
          'B18',
          'D23',
          'C40',
          'D24',
          'C11',
          'C56',
          'C5',
          'A58',
          'D11',
          'B5',
          'D19',
          'B9',
          'B48',
          'A9',
          'C18',
          'B4',
          'A46',
          'C58',
          'A47',
          'D28',
          'B25',
          'D18',
          'B7',
          'A26',
          'B37',
          'C42',
          'A57',
          'C37',
          'C2',
          'B60',
          'D60',
          'A43',
          'D59',
          'D10',
          'B10',
          'C27',
          'A52',
          'D29',
          'A48',
          'C28',
          'A33',
          'A3',
          'B35',
          'C50',
          'D6',
          'C49',
          'B19',
          'D22',
          'A21',
          'C8',
          'A24',
          'A4',
          'B36',
          'A53',
          'B46',
          'A51',
          'C34',
          'C10',
          'A29',
          'B4',
          'C17',
          'A11',
          'B7',
          'D39',
          'B25',
          'D17',
          'C9',
          'A20',
          'D20',
          'D59',
          'B60',
          'D53',
          'D26',
          'D48',
          'D13',
          'C55',
          'C15',
          'A56',
          'C45',
          'D57',
          'A40',
          'C16',
          'B20',
          'A14',
          'C10',
          'A5',
          'B32',
          'A29',
          'B5',
          'C18',
          'D34',
          'A11',
          'C25',
          'B1',
          'D14',
          'D56',
          'D24',
          'B15',
          'B33',
          'A38',
          'C24',
          'A8',
          'B45',
          'A7',
          'C26',
          'B6',
          'D19',
          'C8',
          'D22',
          'B8',
          'B44',
          'D45',
          'B39',
          'D44',
          'C17',
          'A47',
          'C1',
          'D9',
          'C53',
          'A26',
          'B40',
          'D42',
          'B41',
          'D41',
          'B24',
          'C22',
          'A44',
          'C40',
          'A34',
          'B38',
          'A25',
          'B11',
          'D19',
          'A20',
          'B53',
          'D53',
          'B54',
          'A19',
          'C10',
          'A32',
          'B29',
          'A31',
          'B1',
          'C19',
          'A8',
          'C6',
          'A24',
          'B30',
          'C38',
          'B32',
          'C23',
          'B34',
          'A27',
          'B35',
          'C49',
          'B23',
          'C33',
          'B22',
          'D15',
          'C55',
          'D33',
          'C21',
          'A15',
          'C13',
          'B1',
          'D35',
          'B7',
          'D14',
          'A60',
          'C22',
          'A14',
          'C4',
          'A56',
          'A34',
          'B18',
          'A28',
          'B32',
          'A17',
          'B5',
          'C17',
          'B22',
          'C51',
          'B36',
          'C41',
          'D25',
          'A25',
          'D26',
          'D47',
          'C32',
          'A59',
          'C30',
          'A47',
          'D27',
          'D42',
          'C29',
          'B8',
          'B49',
          'D56',
          'B50',
          'D54',
          'D15',
          'B16',
          'C44',
          'D58',
          'A42',
          'C39',
          'A44',
          'C21',
          'B3',
          'C49',
          'B40',
          'D52',
          'B55',
          'D51',
          'B60',
          'D50',
          'D24',
          'B25',
          'A51',
          'C2',
          'D6',
          'C5',
          'A16',
          'B30',
          'C18',
          'A23',
          'C54',
          'B21',
          'C16',
          'A13',
          'C32',
          'A43',
          'A6',
          'C27',
          'A53',
          'D31',
          'A55',
          'C60',
          'A36',
          'C36',
          'A37',
          'D56',
          'B54',
          'A20',
          'D38',
          'B6',
          'B28',
          'A50',
          'C49',
          'D30',
          'A47',
          'C16',
          'B4',
          'D10',
          'D59',
          'D11',
          'D57',
          'B45',
          'D55',
          'B49',
          'D42',
          'D27',
          'A48',
          'C1',
          'D23',
          'C37',
          'A58',
          'D2',
          'C45',
          'B14',
          'D19',
          'C34',
          'B33',
          'C24',
          'B36',
          'C40',
          'B26',
          'C39',
          'A46',
          'B44',
          'B2',
          'C23',
          'A8',
          'B47',
          'D57',
          'D12',
          'B13',
          'A21',
          'C5',
          'C35',
          'A50',
          'A12',
          'B5',
          'D20',
          'A22',
          'D39',
          'A19',
          'D45',
          'A54',
          'C46',
          'A7',
          'C25',
          'A36',
          'C59',
          'A37',
          'B29',
          'C17',
          'A60',
          'C53',
          'D13',
          'B19',
          'D35',
          'D51',
          'D15',
          'D48',
          'C18',
          'A44',
          'C11',
          'A13',
          'A35',
          'A11',
          'B53',
          'C57',
          'C1',
          'B58',
          'D59',
          'B52',
          'A19',
          'D17',
          'B9',
          'B43',
          'A30',
          'A15',
          'C10',
          'D21',
          'B28',
          'A33',
          'C41',
          'A39',
          'D8',
          'C2',
          'A56',
          'B14',
          'A2',
          'B34',
          'C52',
          'D9',
          'D60',
          'D23',
          'B11',
          'D14',
          'D56',
          'A40',
          'C35',
          'A55',
          'C13',
          'A9',
          'D27',
          'C45',
          'B18',
          'D18',
          'B3',
          'C19',
          'C34',
          'B7',
          'D38',
          'A49',
          'B32',
          'C33',
          'B24',
          'C21',
          'A58',
          'A33',
          'B39',
          'D53',
          'D30',
          'A51',
          'C28',
          'A5',
          'D20',
          'A19',
          'C6',
          'D31',
          'B21',
          'A14',
          'D36',
          'B4',
          'C15',
          'B19',
          'D12',
          'A37',
          'C40',
          'C55',
          'C2',
          'B57',
          'D52',
          'A30',
          'C53',
          'B18',
          'C44',
          'C1',
          'A49',
          'C36',
          'B23',
          'C32',
          'B28',
          'D26',
          'D46',
          'B60',
          'A32',
          'A16',
          'C4',
          'A57',
          'D11',
          'C31',
          'A46',
          'C18',
          'B31',
          'A31',
          'C9',
          'C50',
          'D9',
          'C49',
          'B36',
          'C27',
          'C3',
          'A53',
          'D43',
          'D15',
          'B10',
          'A10',
          'B11',
          'B27',
          'C56',
          'B54',
          'D53',
          'B42',
          'A6',
          'B24',
          'D29',
          'C48',
          'B29',
          'C42',
          'D24',
          'D55',
          'B52',
          'A18',
          'C11',
          'D25',
          'A24',
          'B38',
          'C45',
          'D3',
          'A56',
          'B17',
          'C54',
          'B27',
          'A44',
          'C59',
          'A45',
          'C20',
          'D33',
          'B19',
          'A39',
          'C25',
          'A49',
          'C58',
          'D16',
          'B26',
          'D28',
          'A59',
          'C18',
          'A51',
          'B44',
          'D58',
          'B48',
          'D57',
          'D13',
          'A7',
          'B41',
          'C51',
          'D7',
          'C5',
          'D22',
          'C39',
          'D24',
          'D54',
          'C38',
          'C1',
          'C56',
          'C14',
          'A52',
          'B24',
          'C57',
          'A38',
          'B22',
          'D30',
          'D46',
          'A22',
          'C21',
          'B33',
          'A28',
          'A2',
          'B35',
          'C23',
          'A13',
          'B53',
          'D55',
          'B59',
          'D52',
          'D13',
          'A42',
          'C20',
          'A54',
          'D44',
          'B60',
          'A1',
          'A34',
          'C37',
          'B30',
          'C32',
          'B32',
          'B2',
          'D34',
          'B6',
          'D16',
          'D48',
          'D19',
          'B1',
          'C50',
          'B14',
          'A21',
          'B52',
          'D57',
          'A39',
          'C33',
          'A41',
          'D56',
          'C15',
          'A59',
          'B45',
          'B7',
          'D27',
          'A45',
          'D11',
          'C30',
          'A49',
          'D37',
          'B8',
          'D9',
          'C48',
          'D60',
          'C59',
          'A48',
          'C43',
          'B27',
          'C40',
          'A41',
          'C25',
          'D38',
          'B3',
          'B57',
          'C41',
          'A54',
          'D3',
          'A59',
          'C9',
          'A23',
          'D39',
          'B4',
          'B47',
          'A7',
          'A31',
          'D43',
          'C19',
          'C55',
          'B54',
          'A18',
          'D15',
          'C30',
          'D16',
          'B21',
          'C53',
          'D8',
          'A32',
          'A2',
          'D18',
          'C35',
          'B49',
          'B6',
          'A27',
          'B36',
          'A24',
          'B40',
          'C36',
          'B14',
          'A1',
          'A43',
          'D20',
          'C12',
          'B1',
          'D12',
          'C5',
          'A55',
          'C3',
          'A57',
          'C52',
          'D32',
          'D48',
          'C32',
          'A44',
          'D28',
          'C16',
          'B22',
          'C50',
          'B30',
          'D26',
          'A46',
          'D35',
          'B16',
          'B57',
          'C26',
          'B35',
          'C43',
          'A36',
          'D2',
          'C44',
          'B38',
          'D49',
          'D24',
          'B9',
          'C29',
          'D6',
          'A29',
          'D41',
          'B42',
          'C53',
          'A32',
          'B30',
          'C54',
          'A24',
          'C7',
          'A7',
          'B34',
          'A49',
          'C21',
          'D42',
          'D27',
          'A27',
          'D50',
          'D1',
          'C46',
          'B33',
          'B10',
          'C60',
          'B4',
          'D18',
          'A19',
          'A3',
          'B58',
          'C26',
          'C11',
          'D20',
          'C34',
          'B37',
          'C25',
          'A12',
          'D29',
          'C8',
          'A27',
          'B29',
          'C51',
          'D8',
          'A57',
          'C29',
          'D9',
          'D60',
          'A46',
          'B45',
          'D53',
          'B27',
          'A51',
          'B53',
          'C56',
          'B20',
          'D34',
          'A13',
          'B3',
          'C18',
          'B32',
          'C54',
          'A54',
          'D32',
          'C22',
          'A17',
          'C13',
          'D19',
          'B17',
          'C10',
          'A39',
          'C57',
          'B51',
          'C36',
          'B41',
          'D49',
          'A23',
          'B19',
          'C34',
          'C12',
          'A50',
          'C30',
          'B21',
          'C52',
          'D13',
          'D50',
          'B55',
          'A40',
          'D58',
          'B46',
          'A10',
          'D14',
          'B26',
          'C58',
          'D35',
          'A47',
          'C37',
          'D24',
          'D48',
          'C17',
          'A12',
          'B20',
          'A5',
          'B18',
          'B3',
          'D22',
          'B15',
          'A14',
          'C15',
          'D59',
          'D23',
          'A22',
          'C13',
          'A38',
          'C36',
          'C2',
          'A57',
          'D2',
          'C3',
          'A17',
          'D38',
          'C24',
          'A59',
          'A32',
          'D31',
          'C51',
          'C29',
          'A4',
          'A35',
          'C37',
          'C56',
          'B28',
          'A41',
          'D21',
          'B29',
          'C36',
          'B43',
          'D40',
          'B8',
          'A26',
          'D3',
          'D49',
          'B55',
          'B12',
          'C28',
          'B41',
          'C50',
          'D58',
          'B51',
          'C53',
          'B1',
          'A30',
          'D41',
          'C30',
          'A54',
          'D30',
          'D15',
          'A43',
          'D8',
          'C1',
          'C47',
          'D28',
          'B14',
          'C35',
          'D46',
          'A55',
          'C54',
          'B34',
          'C46',
          'B13',
          'D17',
          'C60',
          'B11',
          'D34',
          'A10',
          'C26',
          'B59',
          'A2',
          'B36',
          'C39',
          'A21',
          'B53',
          'D52',
          'B54',
          'A13',
          'B4',
          'A28',
          'D51',
          'A29',
          'D2',
          'D50',
          'D15',
          'B23',
          'C42',
          'B35',
          'D44',
          'A18',
          'D36',
          'B9',
          'D22',
          'C38',
          'B48',
          'D57',
          'A45',
          'B26',
          'C25',
          'B19',
          'A28',
          'C11',
          'A50',
          'B44',
          'C31',
          'B46',
          'A40',
          'C57',
          'A41',
          'D24',
          'A22',
          'A1',
          'A36',
          'B33',
          'C23',
          'D31',
          'A60',
          'C7',
          'D22',
          'A24',
          'B45',
          'A32',
          'A15',
          'C6',
          'A25',
          'B35',
          'C38',
          'A56',
          'B19',
          'D9',
          'A39',
          'D15',
          'A48',
          'C31',
          'D14',
          'D52',
          'C36',
          'B53',
          'C53',
          'D11',
          'B11',
          'C49',
          'D59',
          'A38',
          'C59',
          'B56',
          'C41',
          'B13',
          'C3',
          'B59',
          'C1',
          'A42',
          'C33',
          'D54',
          'B52',
          'A37',
          'C42',
          'B37',
          'B1',
          'D29',
          'A14',
          'B49',
          'C38',
          'D50',
          'D18',
          'B21',
          'A3',
          'A35',
          'D55',
          'C31',
          'D5',
          'B2',
          'C53',
          'B28',
          'D53',
          'D11',
          'C51',
          'D32',
          'D48',
          'B3',
          'D37',
          'A22',
          'C10',
          'B18',
          'C14',
          'A60',
          'C6',
          'C22',
          'A54',
          'B23',
          'C48',
          'B32',
          'A39',
          'B46',
          'D40',
          'B55',
          'A24',
          'C46',
          'A12',
          'D13',
          'C32',
          'B37',
          'D43',
          'B39',
          'A27',
          'D2',
          'C26',
          'D37',
          'A13',
          'C14',
          'A54',
          'C27',
          'D56',
          'B51',
          'B2',
          'C48',
          'D4',
          'C3',
          'C30',
          'D17',
          'B42',
          'B10',
          'D20',
          'A60',
          'C55',
          'B20',
          'D32',
          'A49',
          'C50',
          'B34',
          'C40',
          'D49',
          'D5',
          'A7',
          'A51',
          'D27',
          'C43',
          'B11',
          'A4',
          'B12',
          'C60',
          'A36',
          'C22',
          'B25',
          'C41',
          'B58',
          'B15',
          'C35',
          'B38',
          'D54',
          'B41',
          'A2',
          'B42',
          'C21',
          'C4',
          'A20',
          'D45',
          'A55',
          'B20',
          'C5',
          'B21',
          'C34',
          'B39',
          'A25',
          'B11',
          'C11',
          'C48',
          'B36',
          'D44',
          'D24',
          'D39',
          'C22',
          'A38',
          'C42',
          'B24',
          'D52',
          'B59',
          'D51',
          'B45',
          'D41',
          'A21',
          'A6',
          'D13',
          'A57',
          'C16',
          'A44',
          'C57',
          'A46',
          'B37',
          'A35',
          'A10',
          'C18',
          'B6',
          'B58',
          'C25',
          'D57',
          'A42',
          'D21',
          'B60',
          'D42',
          'A32',
          'C42',
          'B2',
          'C13',
          'A45',
          'D16',
          'C51',
          'D53',
          'B52',
          'C57',
          'A47',
          'B54',
          'A17',
          'B20',
          'A4',
          'B29',
          'C41',
          'B37',
          'D55',
          'C50',
          'A51',
          'C49',
          'A53',
          'D58',
          'B44',
          'D56',
          'B48',
          'C54',
          'D10',
          'A59',
          'D38',
          'D15',
          'D30',
          'B3',
          'B28',
          'C52',
          'B30',
          'C19',
          'A17',
          'B7',
          'D12',
          'D53',
          'B56',
          'B1',
          'C22',
          'B45',
          'C31',
          'B47',
          'B12',
          'C1',
          'C40',
          'B13',
          'D29',
          'C47',
          'B32',
          'A3',
          'A35',
          'D21',
          'C12',
          'A55',
          'C20',
          'B33',
          'A17',
          'D30',
          'A11',
          'B8',
          'D25',
          'C59',
          'B59',
          'D47',
          'D19',
          'C55',
          'B26',
          'D46',
          'C33',
          'B36',
          'C29',
          'C5',
          'A10',
          'D15',
          'B27',
          'C11',
          'D28',
          'A28',
          'D5',
          'C30',
          'D18',
          'C14',
          'A41',
          'B17',
          'D25',
          'B5',
          'C36',
          'A39',
          'C59',
          'D17',
          'A21',
          'B19',
          'C57',
          'A48',
          'C24',
          'B36',
          'C46',
          'A57',
          'D48',
          'D32',
          'A56',
          'C54',
          'B20',
          'D11',
          'A36',
          'A54',
          'C6',
          'B14',
          'C22',
          'A41',
          'D31',
          'D55',
          'B51',
          'D60',
          'A49',
          'C57',
          'D34',
          'A59',
          'C8',
          'A14',
          'A29',
          'A4',
          'B24',
          'D23',
          'C12',
          'B2',
          'D19',
          'C35',
          'B50',
          'C54',
          'C22',
          'B23',
          'C4',
          'B58',
          'D53',
          'C40',
          'A52',
          'C38',
          'B26',
          'C57',
          'D57',
          'B43',
          'B28',
          'A40',
          'C27',
          'B40',
          'A17',
          'D43',
          'D2',
          'D40',
          'A22',
          'B31',
          'C36',
          'D17',
          'B45',
          'D12',
          'A11',
          'C3',
          'A58',
          'C33',
          'B38',
          'A18',
          'C2',
          'A44',
          'B16',
          'B55',
          'C42',
          'B57',
          'B10',
          'A46',
          'D11',
          'A16',
          'C24',
          'B37',
          'D52',
          'D14',
          'B5',
          'D39',
          'D24',
          'A10',
          'C33',
          'C50',
          'B31',
          'A30',
          'A4',
          'A43',
          'D30',
          'B4',
          'D49',
          'C32',
          'C1',
          'A57',
          'C36',
          'D45',
          'A45',
          'C59',
          'D54',
          'B44',
          'D22',
          'C3',
          'C54',
          'B54',
          'B18',
          'C31',
          'D8',
          'A54',
          'C37',
          'A36',
          'C60',
          'A37',
          'D53',
          'A31',
          'D9',
          'B5',
          'B43',
          'D59',
          'A52',
          'D42',
          'B53',
          'A10',
          'B12',
          'C49',
          'C10',
          'C29',
          'B21',
          'C51',
          'B30',
          'C17',
          'D26',
          'A26',
          'B44',
          'C36',
          'D23',
          'D52',
          'B25',
          'D2',
          'A55',
          'D33',
          'C54',
          'D14',
          'C28',
          'D1',
          'A2',
          'A35',
          'B17',
          'A29',
          'D57',
          'C51',
          'C7',
          'A18',
          'B31',
          'C32',
          'A45',
          'D35',
          'B4',
          'D15',
          'C53',
          'B20',
          'D25',
          'C40',
          'B29',
          'C55',
          'B14',
          'C60',
          'A37',
          'D8',
          'D49',
          'A24',
          'B59',
          'C25',
          'D2',
          'D18',
          'C58',
          'D55',
          'C17',
          'A49',
          'C43',
          'A53',
          'C21',
          'B43',
          'A55',
          'D21',
          'A20',
          'D37',
          'A57',
          'D27',
          'D42',
          'C20',
          'B24',
          'C49',
          'B41',
          'A17',
          'D39',
          'A29',
          'A2',
          'D26',
          'B31',
          'C39',
          'B42',
          'C28',
          'D6',
          'C47',
          'B33',
          'C7',
          'D8',
          'B16',
          'A59',
          'D7',
          'A55',
          'B26',
          'B8',
          'A3',
          'B9',
          'A1',
          'D1',
          'D52',
          'C52',
          'B40',
          'D18',
          'D49',
          'D11',
          'C44',
          'B22',
          'C29',
          'C14',
          'A16',
          'C2',
          'D6',
          'C25',
          'A56',
          'C34',
          'B41',
          'D2',
          'A23',
          'B30',
          'C49',
          'D3',
          'C4',
          'C50',
          'A47',
          'D1',
          'A24',
          'A6',
          'A27',
          'D16',
          'B15',
          'C6',
          'B1',
          'A30',
          'B37',
          'C29',
          'A58',
          'C16',
          'A51',
          'C30',
          'C8',
          'A28',
          'B23',
          'C3',
          'D29',
          'A46',
          'C59',
          'A53',
          'B60',
          'B25',
          'D23',
          'B5',
          'B57',
          'D56',
          'A44',
          'A2',
          'D27',
          'C17',
          'B31',
          'D41',
          'C34',
          'B14',
          'D26',
          'A23',
          'D38',
          'A50',
          'C41',
          'D22',
          'B27',
          'A25',
          'B29',
          'C33',
          'B33',
          'A3',
          'B45',
          'D50',
          'A22',
          'C38',
          'B50',
          'A14',
          'B23',
          'A5',
          'A33',
          'D45',
          'D19',
          'D43',
          'C19',
          'A41',
          'D44',
          'B13',
          'C39',
          'B47',
          'D59',
          'D11',
          'B46',
          'C49',
          'C8',
          'C57',
          'D60',
          'D9',
          'C28',
          'D36',
          'B15',
          'C30',
          'B3',
          'B48',
          'D54',
          'B51',
          'A38',
          'B28',
          'C43',
          'B33',
          'C38',
          'B52',
          'C15',
          'B53',
          'D41',
          'B54',
          'C53',
          'A37',
          'C46',
          'D4',
          'A60',
          'D27',
          'D42',
          'A16',
          'B9',
          'C48',
          'B30',
          'B45',
          'C58',
          'B27',
          'A43',
          'C44',
          'A47',
          'C32',
          'D54',
          'B53',
          'D51',
          'B54',
          'C37',
          'A37',
          'D60',
          'A58',
          'D32',
          'A7',
          'D7',
          'C47',
          'C12',
          'D56',
          'B40',
          'A15',
          'A33',
          'B60',
          'A38',
          'D53',
          'C34',
          'D21',
          'C57',
          'B27',
          'C55',
          'B47',
          'B7',
          'C3',
          'A14',
          'A54',
          'C4',
          'B20',
          'A16',
          'D40',
          'D9',
          'C18',
          'B33',
          'C41',
          'D48',
          'B22',
          'A29',
          'C7',
          'A47',
          'B44',
          'A6',
          'B19',
          'C14',
          'B1',
          'D59',
          'D12',
          'D53',
          'D31',
          'C50',
          'D5',
          'C29',
          'B38',
          'C13',
          'D16',
          'A51',
          'B30',
          'A4',
          'B13',
          'C36',
          'B34',
          'C26',
          'A52',
          'D28',
          'A49',
          'A30',
          'A53',
          'D9',
          'D54',
          'D10',
          'A16',
          'B52',
          'C54',
          'B37',
          'A23',
          'D22',
          'A20',
          'B19',
          'C10',
          'D6',
          'C1',
          'D8',
          'C29',
          'D55',
          'B40',
          'C41',
          'B59',
          'A21',
          'D45',
          'D13',
          'D40',
          'C36',
          'A44',
          'A1',
          'B24',
          'C56',
          'D34',
          'B16',
          'D14',
          'D49',
          'B56',
          'B11',
          'A24',
          'B18',
          'A59',
          'A31',
          'D30',
          'A10',
          'C4',
          'C53',
          'D51',
          'D35',
          'D19',
          'A32',
          'D6',
          'C9',
          'A9',
          'A42',
          'A8',
          'D4',
          'B37',
          'A58',
          'C54',
          'D34',
          'A46',
          'C51',
          'D54',
          'A2',
          'B23',
          'D43',
          'C34',
          'B27',
          'D28',
          'C45',
          'C13',
          'B30',
          'C44',
          'C14',
          'B24',
          'C53',
          'B56',
          'C25',
          'C5',
          'A33',
          'A12',
          'B6',
          'C9',
          'C24',
          'D2',
          'A35',
          'B52',
          'C36',
          'A58',
          'D16',
          'C50',
          'B54',
          'A59',
          'C19',
          'B14',
          'C15',
          'A44',
          'B45',
          'C57',
          'A50',
          'C43',
          'B12',
          'D49',
          'B27',
          'D33',
          'A33',
          'C38',
          'B42',
          'D52',
          'B5',
          'A28',
          'B26',
          'C6',
          'D2',
          'C59',
          'B25',
          'A48',
          'D36',
          'C27',
          'B41',
          'D21',
          'B30',
          'C31',
          'B47',
          'C23',
          'D4',
          'C3',
          'B8',
          'D27',
          'B29',
          'C53',
          'B6',
          'C50',
          'C7',
          'A51',
          'D33',
          'C48',
          'D5',
          'B35',
          'C47',
          'B9',
          'A26',
          'D50',
          'D6',
          'D22',
          'A49',
          'D58',
          'A15',
          'C3',
          'A21',
          'C55',
          'B49',
          'A12',
          'B21',
          'D60',
          'B46',
          'A24',
          'D38',
          'A19',
          'B19',
          'C2',
          'A55',
          'D30',
          'B23',
          'A7',
          'C20',
          'B39',
          'C46',
          'D18',
          'D49',
          'D24',
          'A9',
          'C6',
          'C52',
          'B51',
          'A22',
          'B18',
          'C56',
          'A44',
          'C35',
          'D23',
          'D38',
          'C23',
          'C50',
          'A52',
          'C11',
          'A4',
          'A43',
          'C19',
          'B20',
          'C2',
          'B57',
          'C9',
          'D11',
          'B22',
          'A56',
          'C43',
          'B36',
          'B51',
          'C48',
          'C30',
          'B5',
          'C29',
          'D12',
          'B9',
          'A11',
          'D13',
          'A5',
          'B25',
          'D29',
          'B2',
          'A30',
          'D58',
          'B47',
          'C22',
          'D41',
          'B60',
          'A28',
          'A6',
          'D20',
          'A21',
          'D2',
          'C43',
          'B38',
          'D48',
          'D24',
          'A42',
          'D54',
          'B26',
          'C44',
          'B32',
          'C51',
          'A48',
          'B43',
          'A33',
          'D58',
          'D23',
          'C41',
          'D14',
          'C26',
          'B8',
          'D21',
          'B44',
          'A45',
          'C56',
          'B19',
          'A18',
          'D39',
          'C53',
          'B37',
          'A22',
          'B12',
          'C47',
          'C9',
          'A13',
          'A40',
          'D13',
          'B27',
          'C54',
          'A29',
          'D42',
          'B23',
          'D53',
          'A5',
          'B28',
          'C49',
          'A54',
          'D43',
          'B48',
          'D52',
          'D17',
          'A57',
          'C41',
          'B41',
          'C40',
          'D27',
          'C24',
          'B15',
          'D46',
          'C19',
          'B21',
          'A8',
          'D26',
          'C46',
          'C11',
          'B17',
          'A25',
          'C12',
          'B38',
          'D3',
          'C48',
          'B4',
          'C32',
          'B46',
          'C30',
          'D35',
          'A35',
          'A2',
          'C3',
          'B14',
          'A46',
          'C26',
          'B55',
          'C15',
          'D45',
          'A44',
          'D20',
          'B30',
          'B54',
          'A12',
          'D59',
          'A39',
          'D24',
          'B17',
          'C20',
          'B59',
          'D51',
          'B32',
          'C42',
          'B38',
          'C25',
          'D37',
          'B7',
          'A27',
          'D25',
          'B31',
          'C55',
          'A53',
          'D21',
          'B53',
          'C18',
          'A18',
          'B39',
          'D57',
          'B49',
          'A36',
          'B16',
          'C24',
          'A60',
          'C16',
          'A11',
          'B10',
          'A26',
          'D27',
          'D42',
          'A15',
          'B45',
          'C36',
          'B54',
          'D17',
          'B2',
          'D48',
          'A23',
          'C37',
          'B42',
          'D13',
          'C29',
          'D35',
          'B23',
          'C58',
          'A37',
          'C28',
          'D55',
          'A36',
          'B34',
          'A28',
          'D60',
          'B11',
          'C27',
          'A9',
          'B46',
          'D45',
          'B59',
          'C45',
          'A13',
          'B19',
          'C24',
          'D30',
          'A15',
          'D17',
          'B10',
          'C40',
          'B2',
          'D38',
          'A48',
          'D31',
          'C15',
          'B56',
          'C52',
          'B31',
          'C12',
          'A45',
          'C53',
          'D18',
          'C36',
          'C4',
          'A52',
          'D1',
          'C27',
          'D9',
          'C47',
          'B20',
          'D51',
          'A51',
          'C33',
          'A59',
          'C54',
          'B3',
          'A30',
          'B16',
          'D32',
          'D48',
          'C13',
          'D19',
          'B15',
          'C60',
          'A43',
          'C58',
          'B50',
          'C37',
          'B9',
          'C22',
          'B32',
          'C6',
          'D5',
          'C45',
          'D34',
          'B26',
          'D52',
          'C8',
          'B57',
          'A4',
          'A31',
          'D16',
          'A18',
          'C1',
          'B20',
          'C14',
          'A21',
          'D11',
          'B54',
          'A41',
          'A56',
          'A34',
          'D12',
          'C56',
          'B25',
          'B40',
          'D22',
          'B28',
          'C54',
          'B5',
          'C28',
          'C53',
          'B49',
          'A6',
          'D32',
          'C50',
          'D59',
          'A47',
          'B8',
          'D35',
          'D2',
          'D50',
          'C37',
          'A43',
          'D10',
          'A37',
          'B30',
          'C30',
          'B47',
          'A44',
          'C55',
          'D23',
          'C20',
          'B42',
          'A52',
          'D58',
          'A54',
          'B26',
          'A7',
          'A42',
          'A2',
          'D37',
          'A21',
          'B38',
          'D46',
          'A20',
          'C15',
          'C43',
          'B14',
          'C29',
          'B60',
          'A36',
          'D13',
          'D53',
          'B58',
          'C8',
          'B25',
          'A46',
          'C46',
          'B37',
          'A4',
          'D43',
          'C18',
          'D14',
          'C25',
          'A37',
          'C9',
          'A26',
          'B45',
          'C34',
          'D24',
          'D44',
          'B47',
          'D21',
          'C10',
          'B9',
          'D57',
          'B52',
          'C14',
          'B5',
          'D38',
          'B25',
          'C51',
          'B33',
          'C5',
          'A19',
          'C40',
          'B43',
          'B8',
          'C50',
          'B56',
          'A58',
          'D49',
          'D6',
          'D41',
          'A38',
          'C34',
          'A57',
          'C57',
          'B17',
          'D14',
          'A11',
          'A40',
          'A10',
          'B13',
          'D35',
          'C44',
          'A35',
          'A1',
          'B42',
          'C43',
          'D10',
          'A24',
          'C13',
          'B24',
          'C1',
          'B45',
          'A41',
          'D58',
          'B8',
          'D30',
          'B36',
          'A49',
          'B4',
          'D48',
          'B34',
          'C49',
          'A55',
          'C59',
          'C18',
          'B7',
          'A12',
          'A34',
          'A5',
          'C6',
          'B19',
          'C31',
          'D4',
          'C58',
          'B10',
          'C50',
          'D20',
          'D35',
          'B32',
          'C32',
          'A60',
          'C3',
          'D5',
          'B3',
          'C16',
          'D14',
          'D45',
          'C34',
          'D33',
          'A43',
          'D57',
          'B13',
          'A3',
          'B22',
          'D46',
          'B27',
          'D52',
          'B43',
          'C37',
          'A48',
          'C20',
          'C54',
          'B41',
          'D18',
          'A47',
          'C26',
          'B18',
          'C35',
          'A53',
          'C11',
          'B28',
          'D6',
          'C45',
          'D17',
          'D51',
          'D1',
          'B45',
          'D48',
          'A39',
          'C3',
          'A31',
          'C48',
          'B31',
          'D55',
          'A57',
          'D22',
          'B38',
          'C32',
          'C4',
          'A22',
          'D15',
          'A59',
          'C51',
          'B49',
          'C39',
          'A32',
          'D59',
          'B21',
          'B60',
          'A4',
          'D20',
          'C36',
          'B16',
          'C8',
          'A17',
          'D2',
          'A3',
          'A20',
          'B48',
          'D16',
          'A1',
          'A52',
          'D6',
          'C24',
          'D39',
          'D24',
          'B23',
          'C57',
          'C4',
          'B50',
          'C49',
          'B15',
          'C46',
          'A55',
          'D58',
          'C23',
          'D40',
          'C35',
          'A37',
          'B32',
          'D55',
          'B14',
          'C39',
          'D19',
          'D43',
          'B52',
          'A14',
          'B2',
          'D47',
          'B16',
          'D26',
          'C41',
          'D3',
          'A24',
          'C3',
          'A54',
          'C55',
          'B35',
          'C26',
          'D36',
          'A23',
          'B11',
          'C10',
          'A41',
          'D9',
          'D60',
          'C45',
          'B28',
          'C57',
          'B29',
          'D30',
          'A49',
          'D26',
          'A57',
          'C25',
          'B55',
          'D11',
          'B12',
          'C58',
          'A43',
          'B16',
          'B43',
          'C32',
          'D34',
          'C31',
          'D7',
          'C3',
          'B18',
          'C33',
          'A44',
          'D32',
          'B27',
          'A7',
          'A52',
          'D27',
          'B36',
          'C47',
          'B60',
          'A54',
          'C26',
          'D56',
          'A45',
          'C29',
          'A35',
          'A1',
          'D59',
          'A11',
          'B11',
          'A9',
          'C19',
          'C3',
          'B56',
          'C24',
          'D37',
          'B26',
          'D23',
          'D57',
          'D28',
          'A4',
          'B16',
          'C58',
          'D32',
          'C45',
          'C30',
          'D7',
          'B17',
          'C8',
          'A48',
          'D49',
          'A21',
          'B40',
          'A13',
          'A28',
          'B5',
          'C27',
          'D13',
          'B43',
          'C29',
          'B34',
          'A26',
          'B56',
          'C49',
          'D4',
          'A57',
          'B12',
          'C26',
          'B36',
          'D19',
          'B39',
          'C38',
          'B35',
          'D51',
          'B33',
          'C56',
          'A46',
          'B15',
          'C45',
          'C12',
          'A38',
          'D58',
          'D10',
          'A6',
          'A29',
          'D26',
          'C25',
          'D3',
          'B32',
          'C39',
          'B2',
          'B52',
          'A12',
          'D39',
          'C44',
          'A37',
          'D23',
          'B7',
          'C2',
          'D7',
          'A19',
          'B22',
          'A8',
          'B48',
          'C32',
          'B9',
          'D45',
          'B38',
          'A28',
          'B8',
          'A27',
          'D52',
          'C35',
          'D32',
          'C49',
          'D15',
          'B41',
          'C21',
          'D41',
          'B13',
          'C16',
          'D43',
          'A39',
          'C59',
          'C3',
          'B29',
          'D22',
          'A40',
          'C41',
          'A43',
          'C57',
          'B30',
          'C40',
          'D13',
          'C51',
          'A50',
          'C5',
          'B19',
          'A41',
          'A10',
          'D54',
          'B57',
          'A44',
          'D9',
          'C17',
          'B23',
          'C44',
          'B59',
          'B41',
          'D53',
          'B25',
          'C58',
          'C19',
          'B2',
          'C37',
          'B51',
          'B5',
          'D36',
          'D52',
          'B44',
          'C21',
          'A37',
          'C14',
          'D15',
          'D37',
          'A56',
          'C7',
          'B24',
          'C17',
          'A23',
          'B17',
          'C46',
          'A31',
          'A59',
          'C45',
          'A18',
          'D31',
          'C53',
          'B7',
          'D56',
          'B30',
          'C56',
          'B55',
          'C37',
          'D4',
          'C11',
          'B2',
          'C7',
          'B25',
          'D36',
          'B38',
          'D30',
          'B14',
          'A10',
          'A25',
          'D27',
          'C39',
          'B24',
          'A12',
          'B8',
          'D18',
          'A56',
          'C20',
          'D42',
          'C40',
          'A20',
          'C22',
          'B18',
          'D12',
          'C55',
          'B36',
          'B21',
          'D47',
          'B35',
          'D20',
          'D35',
          'A15',
          'B22',
          'A1',
          'B52',
          'C51',
          'A10',
          'C47',
          'C4',
          'B6',
          'D25',
          'A11',
          'B54',
          'C18',
          'B9',
          'A24',
          'D27',
          'A40',
          'C59',
          'D3',
          'C57',
          'A58',
          'D12',
          'B48',
          'A44',
          'B4',
          'A13',
          'D57',
          'C13',
          'B39',
          'C45',
          'B31',
          'D54',
          'D10',
          'A3',
          'A33',
          'A56',
          'C37',
          'D16',
          'C11',
          'A12',
          'D2',
          'A34',
          'D20',
          'C32',
          'B33',
          'D50',
          'C16',
          'B28',
          'C34',
          'B15',
          'C15',
          'D53',
          'A39',
          'B21',
          'C49',
          'D23',
          'B27',
          'C6',
          'A45',
          'C52',
          'B37',
          'D47',
          'B45',
          'A25',
          'D9',
          'D46',
          'A56',
          'C59',
          'A42',
          'D44',
          'C39',
          'A50',
          'C37',
          'D39',
          'D13',
          'B44',
          'B59',
          'B8',
          'C53',
          'B12',
          'C40',
          'B36',
          'C23',
          'B16',
          'A3',
          'D11',
          'D46',
          'C18',
          'A48',
          'D28',
          'C49',
          'C26',
          'A55',
          'D55',
          'B11',
          'C48',
          'D35',
          'B40',
          'C35',
          'B23',
          'C2',
          'B25',
          'C27',
          'B7',
          'A6',
          'B50',
          'C3',
          'D22',
          'C36',
          'C8',
          'B18',
          'A22',
          'B34',
          'A16',
          'B46',
          'D30',
          'A8',
          'D6',
          'C52',
          'B42',
          'D15',
          'C56',
          'A47',
          'C25',
          'D29',
          'A19',
          'B30',
          'C53',
          'D12',
          'A17',
          'B43',
          'D59',
          'A28',
          'D1',
          'A50',
          'D13',
          'C46',
          'D31',
          'C36',
          'B17',
          'C15',
          'D14',
          'D45',
          'B51',
          'C17',
          'D6',
          'C48',
          'B34',
          'C28',
          'D35',
          'C43',
          'D40',
          'B24',
          'A38',
          'C60',
          'D1',
          'A20',
          'B1',
          'C55',
          'B19',
          'A2',
          'A33',
          'A15',
          'C25',
          'B39',
          'D42',
          'B18',
          'C11',
          'A55',
          'B41',
          'C33',
          'D33',
          'A19',
          'D60',
          'B6',
          'A49',
          'D23',
          'C53',
          'D1',
          'B20',
          'C31',
          'A53',
          'D47',
          'C27',
          'A42',
          'B7',
          'D13',
          'D57',
          'B56',
          'B22',
          'D25',
          'C57',
          'B48',
          'D9',
          'B9',
          'C46',
          'A49',
          'C13',
          'B40',
          'A11',
          'C4',
          'B21',
          'C45',
          'A32',
          'D15',
          'D30',
          'C52',
          'D18',
          'C14',
          'B56',
          'A21',
          'B18',
          'C58',
          'A45',
          'B15',
          'C39',
          'D49',
          'A49',
          'B54',
          'C17',
          'D10',
          'A20',
          'B12',
          'C37',
          'D17',
          'A14',
          'C30',
          'D54',
          'B32',
          'D21',
          'C9',
          'A38',
          'B29',
          'A1',
          'A36',
          'B51',
          'B30',
          'C3',
          'B31',
          'D22',
          'C2',
          'A48',
          'C56',
          'A52',
          'C28',
          'A39',
          'C21',
          'B45',
          'C40',
          'B53',
          'D9',
          'A33',
          'D31',
          'A56',
          'B37',
          'D58',
          'B10',
          'C18',
          'B21',
          'D33',
          'C46',
          'B42',
          'A27',
          'A9',
          'A34',
          'C38',
          'A46',
          'C22',
          'D39',
          'B2',
          'C5',
          'B5',
          'C19',
          'A52',
          'D41',
          'D1',
          'A40',
          'B15',
          'D57',
          'D29',
          'A11',
          'C15',
          'B28',
          'D32',
          'B43',
          'C28',
          'A6',
          'A31',
          'A4',
          'B40',
          'D16',
          'C44',
          'D23',
          'D52',
          'B58',
          'A43',
          'D21',
          'B2',
          'C22',
          'B16',
          'D46',
          'A36',
          'C47',
          'A45',
          'B37',
          'D54',
          'A26',
          'D5',
          'C57',
          'D14',
          'B41',
          'D44',
          'A3',
          'B28',
          'C4',
          'B12',
          'C24',
          'D8',
          'C1',
          'B50',
          'D59',
          'C22',
          'B26',
          'C35',
          'B3',
          'A29',
          'B58',
          'A10',
          'B15',
          'C37',
          'D19',
          'D41',
          'C21',
          'B46',
          'A45',
          'C40',
          'B22',
          'A18',
          'D37',
          'B37',
          'C51',
          'B5',
          'D26',
          'B19',
          'D38',
          'C45',
          'B1',
          'C41',
          'D39',
          'D24',
          'A26',
          'B8',
          'D49',
          'B13',
          'A48',
          'D59',
          'D43',
          'C14',
          'B6',
          'C49',
          'A30',
          'B33',
          'A1',
          'B53',
          'C38',
          'B59',
          'A5',
          'A42',
          'C9',
          'A16',
          'C23',
          'B48',
          'D5',
          'C28',
          'D40',
          'A59',
          'C25',
          'C53',
          'B32',
          'C31',
          'B4',
          'C8',
          'D6',
          'C6',
          'A47',
          'D34',
          'B13',
          'B54',
          'C49',
          'B25',
          'A8',
          'D12',
          'C17',
          'B26',
          'D33',
          'B41',
          'C44',
          'B10',
          'C11',
          'D5',
          'C37',
          'D51',
          'B37',
          'D19',
          'B47',
          'A39',
          'C42',
          'A13',
          'B23',
          'C54',
          'D17',
          'C15',
          'A36',
          'B57',
          'C28',
          'B60',
          'A35',
          'C26',
          'D1',
          'C20',
          'A60',
          'A33',
          'D35',
          'B25',
          'C37',
          'B39',
          'C29',
          'A32',
          'D43',
          'A46',
          'C36',
          'A50',
          'C32',
          'A51',
          'C12',
          'D46',
          'A23',
          'B9',
          'B40',
          'C59',
          'A42',
          'B27',
          'A6',
          'D42',
          'C20',
          'D2',
          'C58',
          'A45',
          'D44',
          'B38',
          'A35',
          'D11',
          'B45',
          'C32',
          'B29',
          'C10',
          'D54',
          'B11',
          'D12',
          'D58',
          'B21',
          'D37',
          'B2',
          'D28',
          'B16',
          'C60',
          'A38',
          'B48',
          'A13',
          'A29',
          'D50',
          'C54',
          'B55',
          'C33',
          'D53',
          'D13',
          'B3',
          'B36',
          'C39',
          'B4',
          'C55',
          'A55',
          'D47',
          'C42',
          'A18',
          'B42',
          'C34',
          'D25',
          'B39',
          'D23',
          'D51',
          'B44',
          'C51',
          'D15',
          'C48',
          'D19',
          'C28',
          'C13',
          'A44',
          'C54',
          'B35',
          'C53',
          'B9',
          'C26',
          'D5',
          'A13',
          'B5',
          'C16',
          'D6',
          'C4',
          'A23',
          'D40',
          'C22',
          'B43',
          'D12',
          'A12',
          'D38',
          'B13',
          'A2',
          'A33',
          'B29',
          'D28',
          'C27',
          'B26',
          'A44',
          'D59',
          'A58',
          'C52',
          'B20',
          'D42',
          'D27',
          'B30',
          'D55',
          'B15',
          'C9',
          'A53',
          'C28',
          'B21',
          'C41',
          'B44',
          'A54',
          'C18',
          'B12',
          'D60',
          'D9',
          'B45',
          'D32',
          'C44',
          'A38',
          'A13',
          'C4',
          'B16',
          'D45',
          'B17',
          'C60',
          'B53',
          'A22',
          'C19',
          'D8',
          'C1',
          'A55',
          'D44',
          'D14',
          'D52',
          'B28',
          'D29',
          'A51',
          'C29',
          'C49',
          'D5',
          'B49',
          'C50',
          'B1',
          'A15',
          'B46',
          'C12',
          'A27',
          'A8',
          'C20',
          'A50',
          'D30',
          'C47',
          'B37',
          'A19',
          'C23',
          'B23',
          'D41',
          'C43',
          'A43',
          'C57',
          'B3',
          'A14',
          'D4',
          'C41',
          'B11',
          'C6',
          'A56',
          'C5',
          'B26',
          'D31',
          'C52',
          'A47',
          'C22',
          'A21',
          'D23',
          'B43',
          'C54',
          'B19',
          'B53',
          'C21',
          'B60',
          'A31',
          'A4',
          'D1',
          'A19',
          'B38',
          'D26',
          'A7',
          'B8',
          'C49',
          'D56',
          'D8',
          'B20',
          'D38',
          'A15',
          'C29',
          'A46',
          'C35',
          'B27',
          'A39',
          'B37',
          'C14',
          'D45',
          'C20',
          'D5',
          'C56',
          'D39',
          'B10',
          'C5',
          'B54',
          'C40',
          'A31',
          'A60',
          'D30',
          'D15',
          'A26',
          'B16',
          'D16',
          'C55',
          'C6',
          'C32',
          'C47',
          'D37',
          'B24',
          'C52',
          'D12',
          'B30',
          'C2',
          'D7',
          'B46',
          'D22',
          'A56',
          'B23',
          'C40',
          'D43',
          'B1',
          'C43',
          'B52',
          'C50',
          'A48',
          'D32',
          'C23',
          'B49',
          'C32',
          'D25',
          'B32',
          'D53',
          'C14',
          'B7',
          'A29',
          'A6',
          'B34',
          'C22',
          'B51',
          'A8',
          'B18',
          'C57',
          'B31',
          'C34',
          'D47',
          'C11',
          'A36',
          'A1',
          'B10',
          'C46',
          'B20',
          'C35',
          'B30',
          'D37',
          'C60',
          'A41',
          'C38',
          'D44',
          'A31',
          'B32',
          'B48',
          'A37',
          'D42',
          'C43',
          'B43',
          'C34',
          'B59',
          'C3',
          'B19',
          'D45',
          'A60',
          'C15',
          'A46',
          'C58',
          'A51',
          'D50',
          'B11',
          'D21',
          'B34',
          'C56',
          'A57',
          'D53',
          'A3',
          'C30',
          'B4',
          'C19',
          'A34',
          'D33',
          'D13',
          'A30',
          'B48',
          'A10',
          'D25',
          'C26',
          'A6',
          'D27',
          'B14',
          'A20',
          'B58',
          'C28',
          'D7',
          'D41',
          'B18',
          'D10',
          'D54',
          'D11',
          'A13',
          'D52',
          'A24',
        ],
      },
    ],
  },
  {
    title: 'Marilyn Monroe',
    id: 'marilyn-monroe',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Marilyn%20Monroe.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C16',
          'C32',
          'C17',
          'C35',
          'C20',
          'C36',
          'C15',
          'C34',
          'C19',
          'C37',
          'C22',
          'C38',
          'C18',
          'C33',
          'C14',
          'C29',
          'C13',
          'C31',
          'C12',
          'C27',
          'C5',
          'C30',
          'C7',
          'C28',
          'C2',
          'C26',
          'C8',
          'C32',
          'C15',
          'C36',
          'C16',
          'C35',
          'C20',
          'C39',
          'C17',
          'C37',
          'C14',
          'C38',
          'C13',
          'A40',
          'C29',
          'A42',
          'C31',
          'A43',
          'C30',
          'A45',
          'C33',
          'A46',
          'C32',
          'A44',
          'C15',
          'A41',
          'C16',
          'C34',
          'C12',
          'C36',
          'D59',
          'C35',
          'C14',
          'A39',
          'C28',
          'A47',
          'C29',
          'C1',
          'C45',
          'C2',
          'C24',
          'A42',
          'C13',
          'D25',
          'D40',
          'D24',
          'D39',
          'D23',
          'D38',
          'C22',
          'C37',
          'C15',
          'C30',
          'A46',
          'C16',
          'A43',
          'C28',
          'A44',
          'C31',
          'A45',
          'C17',
          'C32',
          'A41',
          'C14',
          'A42',
          'C33',
          'A47',
          'C18',
          'D29',
          'C20',
          'D31',
          'C19',
          'A38',
          'C15',
          'A46',
          'C29',
          'D54',
          'C35',
          'A49',
          'C16',
          'A44',
          'C34',
          'D56',
          'C32',
          'A48',
          'C28',
          'A45',
          'C14',
          'D24',
          'D39',
          'C22',
          'A40',
          'C17',
          'A50',
          'C36',
          'D57',
          'C27',
          'D59',
          'C26',
          'A35',
          'C10',
          'D21',
          'D36',
          'D20',
          'C11',
          'D25',
          'D40',
          'C21',
          'A24',
          'C3',
          'C44',
          'D58',
          'C34',
          'A45',
          'C16',
          'A48',
          'C15',
          'A42',
          'C30',
          'A41',
          'C29',
          'D50',
          'C28',
          'A46',
          'C14',
          'D22',
          'C10',
          'A36',
          'C9',
          'D16',
          'C8',
          'A37',
          'C17',
          'A10',
          'C18',
          'A14',
          'C19',
          'D32',
          'C16',
          'A50',
          'C35',
          'A51',
          'C31',
          'A40',
          'C21',
          'A41',
          'C13',
          'D22',
          'D37',
          'C23',
          'A30',
          'C6',
          'A31',
          'C5',
          'A32',
          'C24',
          'D46',
          'C28',
          'A49',
          'C15',
          'A43',
          'C29',
          'A39',
          'C18',
          'A44',
          'C26',
          'A46',
          'C13',
          'D26',
          'C10',
          'D15',
          'C9',
          'D18',
          'C8',
          'D22',
          'C46',
          'D23',
          'D38',
          'C23',
          'A42',
          'C17',
          'A47',
          'C16',
          'A51',
          'C15',
          'A8',
          'C18',
          'A45',
          'C29',
          'A48',
          'C36',
          'D55',
          'C35',
          'A43',
          'C32',
          'A50',
          'C28',
          'D52',
          'C27',
          'A37',
          'C20',
          'A23',
          'C44',
          'D19',
          'C12',
          'D24',
          'D39',
          'C22',
          'A28',
          'C7',
          'A38',
          'C11',
          'A34',
          'C8',
          'D13',
          'C10',
          'D27',
          'D42',
          'D21',
          'D36',
          'C24',
          'A46',
          'C17',
          'A11',
          'C16',
          'A52',
          'C15',
          'D23',
          'C13',
          'A47',
          'C37',
          'A53',
          'C18',
          'A54',
          'C38',
          'A56',
          'C20',
          'A40',
          'C27',
          'A45',
          'C32',
          'A39',
          'C7',
          'D13',
          'C6',
          'A37',
          'C16',
          'A7',
          'B56',
          'A8',
          'B57',
          'A9',
          'C19',
          'C34',
          'A47',
          'C15',
          'D21',
          'C45',
          'D26',
          'C12',
          'D27',
          'C9',
          'D28',
          'D43',
          'D22',
          'C40',
          'D24',
          'C47',
          'D25',
          'C46',
          'D60',
          'A40',
          'C16',
          'A53',
          'C31',
          'A55',
          'C19',
          'A48',
          'C17',
          'A14',
          'C21',
          'A25',
          'C45',
          'D28',
          'C8',
          'D29',
          'C56',
          'D30',
          'C44',
          'C24',
          'A31',
          'C9',
          'A33',
          'C4',
          'A38',
          'C28',
          'A51',
          'C27',
          'A41',
          'C10',
          'D17',
          'C11',
          'D26',
          'C42',
          'D27',
          'D42',
          'C22',
          'A58',
          'C23',
          'A46',
          'C19',
          'D25',
          'C14',
          'A48',
          'D33',
          'A50',
          'C13',
          'D23',
          'C51',
          'D24',
          'D39',
          'A5',
          'B54',
          'A6',
          'C17',
          'A36',
          'C5',
          'D9',
          'C7',
          'A29',
          'A44',
          'C35',
          'A45',
          'C25',
          'A33',
          'C6',
          'D10',
          'C39',
          'A59',
          'C23',
          'A47',
          'D33',
          'A51',
          'C18',
          'D31',
          'C8',
          'A35',
          'C11',
          'A42',
          'D60',
          'A27',
          'C45',
          'D20',
          'C9',
          'D21',
          'C14',
          'A49',
          'C34',
          'D4',
          'A17',
          'D3',
          'A16',
          'C30',
          'D49',
          'A20',
          'C43',
          'A21',
          'D53',
          'A23',
          'C22',
          'C42',
          'D28',
          'C21',
          'A26',
          'D56',
          'A39',
          'C3',
          'A44',
          'A28',
          'C2',
          'A41',
          'C25',
          'C41',
          'D14',
          'C10',
          'D27',
          'C54',
          'D26',
          'C48',
          'C1',
          'C40',
          'A57',
          'C38',
          'D10',
          'C5',
          'A40',
          'C30',
          'A47',
          'C22',
          'A24',
          'C44',
          'D20',
          'C39',
          'A55',
          'D34',
          'A50',
          'D32',
          'A52',
          'C14',
          'A9',
          'B58',
          'A8',
          'C16',
          'A54',
          'D33',
          'A45',
          'A28',
          'D56',
          'A22',
          'D8',
          'C6',
          'A46',
          'C18',
          'A35',
          'C7',
          'D29',
          'D46',
          'D30',
          'C21',
          'A37',
          'B21',
          'A36',
          'C27',
          'B1',
          'C26',
          'A39',
          'D58',
          'A41',
          'C20',
          'A24',
          'D9',
          'A22',
          'C43',
          'D25',
          'D48',
          'D26',
          'C46',
          'D27',
          'C9',
          'D12',
          'C45',
          'D29',
          'A44',
          'C2',
          'C44',
          'A25',
          'C22',
          'A29',
          'C6',
          'D14',
          'C40',
          'B39',
          'C34',
          'D5',
          'A19',
          'D50',
          'A21',
          'D7',
          'C35',
          'D3',
          'C33',
          'A40',
          'C15',
          'A53',
          'D34',
          'A57',
          'D33',
          'A49',
          'C17',
          'A51',
          'D31',
          'C58',
          'B35',
          'C57',
          'D28',
          'D43',
          'C22',
          'A12',
          'B60',
          'A10',
          'B59',
          'A9',
          'C18',
          'C36',
          'D53',
          'A20',
          'D51',
          'C29',
          'B3',
          'C28',
          'A53',
          'D31',
          'C17',
          'D25',
          'C48',
          'C3',
          'A40',
          'D59',
          'A41',
          'C19',
          'A56',
          'C31',
          'A46',
          'D33',
          'A55',
          'C18',
          'A13',
          'B45',
          'C43',
          'D17',
          'C9',
          'D23',
          'D50',
          'D24',
          'C20',
          'D30',
          'C42',
          'B42',
          'C41',
          'A19',
          'D6',
          'C33',
          'A44',
          'D31',
          'C7',
          'D7',
          'C4',
          'A37',
          'B19',
          'D36',
          'B16',
          'D35',
          'B8',
          'D34',
          'A48',
          'C11',
          'D12',
          'C44',
          'B46',
          'A15',
          'D2',
          'C40',
          'A60',
          'C24',
          'A32',
          'C10',
          'A42',
          'D59',
          'A33',
          'B14',
          'D36',
          'A2',
          'D52',
          'A23',
          'D10',
          'C37',
          'A17',
          'B48',
          'A4',
          'B54',
          'A3',
          'D48',
          'D27',
          'C53',
          'C4',
          'C43',
          'A25',
          'A45',
          'C2',
          'A24',
          'C1',
          'A38',
          'B18',
          'D35',
          'B20',
          'C23',
          'A39',
          'C22',
          'A48',
          'D32',
          'C7',
          'A32',
          'C46',
          'A29',
          'D60',
          'C49',
          'D21',
          'C43',
          'B41',
          'C42',
          'C24',
          'C39',
          'C14',
          'A10',
          'B43',
          'A11',
          'C23',
          'D41',
          'C55',
          'B31',
          'C25',
          'A34',
          'B21',
          'D35',
          'B5',
          'C31',
          'D3',
          'C40',
          'B1',
          'C52',
          'D22',
          'C18',
          'D33',
          'A58',
          'C51',
          'D31',
          'C42',
          'B43',
          'A2',
          'D49',
          'A22',
          'C45',
          'D57',
          'A41',
          'B23',
          'A36',
          'C6',
          'D15',
          'C10',
          'D19',
          'A35',
          'B13',
          'C52',
          'A57',
          'C31',
          'B6',
          'D34',
          'A44',
          'D28',
          'C9',
          'D30',
          'D46',
          'C22',
          'B29',
          'C26',
          'B30',
          'C56',
          'B33',
          'C32',
          'A52',
          'C13',
          'D25',
          'C55',
          'B32',
          'C24',
          'B34',
          'C59',
          'B36',
          'C58',
          'D32',
          'C6',
          'A38',
          'B20',
          'A35',
          'C17',
          'A39',
          'C1',
          'A41',
          'D33',
          'B9',
          'D34',
          'A51',
          'D30',
          'A55',
          'C16',
          'A14',
          'D1',
          'C32',
          'B34',
          'C31',
          'D48',
          'A21',
          'D9',
          'C33',
          'A49',
          'C37',
          'D11',
          'A24',
          'C7',
          'D12',
          'C11',
          'D14',
          'C46',
          'A33',
          'B22',
          'C30',
          'B25',
          'C17',
          'A41',
          'C34',
          'D2',
          'A14',
          'B46',
          'A3',
          'B47',
          'A15',
          'C31',
          'B7',
          'D35',
          'B23',
          'C16',
          'D20',
          'C38',
          'A46',
          'D30',
          'C40',
          'D4',
          'C32',
          'D5',
          'A20',
          'C20',
          'A50',
          'C33',
          'B39',
          'C41',
          'D26',
          'C11',
          'A49',
          'D34',
          'B11',
          'D33',
          'C6',
          'A39',
          'D55',
          'A24',
          'A45',
          'C15',
          'A9',
          'B60',
          'C2',
          'A37',
          'B22',
          'A32',
          'B14',
          'C52',
          'D25',
          'C12',
          'D11',
          'A49',
          'C40',
          'D5',
          'A18',
          'B57',
          'A7',
          'B58',
          'C30',
          'D49',
          'C60',
          'B49',
          'C58',
          'B37',
          'C39',
          'D6',
          'C8',
          'D52',
          'A19',
          'D7',
          'C42',
          'B44',
          'A12',
          'C21',
          'D22',
          'C16',
          'B25',
          'B10',
          'C53',
          'B12',
          'D33',
          'A52',
          'C17',
          'A32',
          'B15',
          'C52',
          'B18',
          'C51',
          'B19',
          'C24',
          'B1',
          'D34',
          'A47',
          'D29',
          'C50',
          'D59',
          'D32',
          'D48',
          'D27',
          'C22',
          'A27',
          'C8',
          'D30',
          'A52',
          'D14',
          'C39',
          'A18',
          'D7',
          'C3',
          'A34',
          'B13',
          'D33',
          'B16',
          'C52',
          'D23',
          'C41',
          'D13',
          'C40',
          'B2',
          'A47',
          'C6',
          'D16',
          'C42',
          'B45',
          'A4',
          'B51',
          'A12',
          'B52',
          'A2',
          'C14',
          'A37',
          'C12',
          'C39',
          'D27',
          'A44',
          'C1',
          'B60',
          'C33',
          'C18',
          'B24',
          'C31',
          'B27',
          'C23',
          'B32',
          'C38',
          'C15',
          'D24',
          'D50',
          'A20',
          'B59',
          'A11',
          'D43',
          'C57',
          'B36',
          'C34',
          'A17',
          'B49',
          'A15',
          'B50',
          'A5',
          'C19',
          'A7',
          'B40',
          'C33',
          'B8',
          'B28',
          'C17',
          'B20',
          'A42',
          'D58',
          'D32',
          'A29',
          'B15',
          'A28',
          'C47',
          'A31',
          'C8',
          'D8',
          'C42',
          'D9',
          'C4',
          'A23',
          'A45',
          'D34',
          'A1',
          'B52',
          'A5',
          'D55',
          'C29',
          'A16',
          'B48',
          'A3',
          'D37',
          'B15',
          'D36',
          'B17',
          'D33',
          'A42',
          'D29',
          'A54',
          'C37',
          'D52',
          'A24',
          'C23',
          'B21',
          'A56',
          'C25',
          'A57',
          'C15',
          'A36',
          'C47',
          'A37',
          'B12',
          'C53',
          'A60',
          'C32',
          'D6',
          'C5',
          'D33',
          'B23',
          'A55',
          'C37',
          'B30',
          'C20',
          'A21',
          'D57',
          'A38',
          'C27',
          'B3',
          'D34',
          'A56',
          'C16',
          'B21',
          'C51',
          'D25',
          'C10',
          'D26',
          'C40',
          'D31',
          'A50',
          'D14',
          'C11',
          'D18',
          'A36',
          'D12',
          'C35',
          'A46',
          'B26',
          'D30',
          'A28',
          'C45',
          'B44',
          'C44',
          'B6',
          'C54',
          'A59',
          'C34',
          'B37',
          'C32',
          'D47',
          'D31',
          'C8',
          'D52',
          'D21',
          'D51',
          'A24',
          'D8',
          'C38',
          'B33',
          'C25',
          'B18',
          'D33',
          'B25',
          'A54',
          'C33',
          'B41',
          'A8',
          'D41',
          'C21',
          'B21',
          'D34',
          'C60',
          'D14',
          'C7',
          'A36',
          'B12',
          'C23',
          'B34',
          'C22',
          'B19',
          'C52',
          'B2',
          'C32',
          'B8',
          'C54',
          'B11',
          'D36',
          'C50',
          'A55',
          'D29',
          'B27',
          'C18',
          'A33',
          'D17',
          'C6',
          'D2',
          'C41',
          'D3',
          'A12',
          'C19',
          'A50',
          'D11',
          'C12',
          'D13',
          'A51',
          'C40',
          'D9',
          'C36',
          'A44',
          'D33',
          'B39',
          'C32',
          'D7',
          'A23',
          'C43',
          'B46',
          'A16',
          'C28',
          'D51',
          'A18',
          'D54',
          'A37',
          'C3',
          'A43',
          'D30',
          'A53',
          'B26',
          'C16',
          'A35',
          'B23',
          'A31',
          'C23',
          'D26',
          'D50',
          'C30',
          'D1',
          'A11',
          'B51',
          'C58',
          'D46',
          'C9',
          'D28',
          'A48',
          'C34',
          'D10',
          'A25',
          'A41',
          'C12',
          'D16',
          'C11',
          'A50',
          'C32',
          'B57',
          'A6',
          'B55',
          'A3',
          'D38',
          'A51',
          'B6',
          'C30',
          'B59',
          'C29',
          'B22',
          'A42',
          'B29',
          'D33',
          'D57',
          'A40',
          'D56',
          'C28',
          'B17',
          'C53',
          'B13',
          'C50',
          'B21',
          'D20',
          'C11',
          'D21',
          'B20',
          'C52',
          'B23',
          'C24',
          'A29',
          'C45',
          'D11',
          'A35',
          'C5',
          'D34',
          'B22',
          'C25',
          'B25',
          'D31',
          'C39',
          'A47',
          'B6',
          'C58',
          'B52',
          'C29',
          'D45',
          'C57',
          'B5',
          'C55',
          'D26',
          'C19',
          'A34',
          'C46',
          'D15',
          'A53',
          'D14',
          'A49',
          'D32',
          'A45',
          'A22',
          'D47',
          'A20',
          'D6',
          'C43',
          'D22',
          'C39',
          'B60',
          'D60',
          'C25',
          'B37',
          'A1',
          'D50',
          'A6',
          'B40',
          'C41',
          'D4',
          'A41',
          'D56',
          'D33',
          'B42',
          'A9',
          'D40',
          'C24',
          'B33',
          'C37',
          'D54',
          'A22',
          'A46',
          'B28',
          'C16',
          'B17',
          'A39',
          'C4',
          'C21',
          'B26',
          'D27',
          'C10',
          'A30',
          'B14',
          'A57',
          'B19',
          'A44',
          'D26',
          'B27',
          'C36',
          'A17',
          'B56',
          'C31',
          'B57',
          'D57',
          'C26',
          'A48',
          'C38',
          'A52',
          'D31',
          'A29',
          'B24',
          'D34',
          'A42',
          'D32',
          'C39',
          'A19',
          'D48',
          'C9',
          'D29',
          'A56',
          'C33',
          'A59',
          'C15',
          'B21',
          'C49',
          'D27',
          'C19',
          'B9',
          'C46',
          'A24',
          'B53',
          'A14',
          'B50',
          'A13',
          'C17',
          'B15',
          'C27',
          'B17',
          'C52',
          'D24',
          'A7',
          'D41',
          'A54',
          'D31',
          'C7',
          'A33',
          'D18',
          'A35',
          'B60',
          'C29',
          'B25',
          'C24',
          'D25',
          'B27',
          'A51',
          'D12',
          'A25',
          'A41',
          'B30',
          'C54',
          'B9',
          'C33',
          'D45',
          'A2',
          'B55',
          'C32',
          'A14',
          'B54',
          'A7',
          'D54',
          'D33',
          'D49',
          'A23',
          'D8',
          'A31',
          'D58',
          'A37',
          'B20',
          'C51',
          'B24',
          'A55',
          'C35',
          'D4',
          'C10',
          'A28',
          'D32',
          'B18',
          'C23',
          'B35',
          'C22',
          'D19',
          'C20',
          'D22',
          'C53',
          'B14',
          'D34',
          'B38',
          'C34',
          'D12',
          'C59',
          'B34',
          'C38',
          'B56',
          'A5',
          'B53',
          'A26',
          'C46',
          'B43',
          'D33',
          'B3',
          'C42',
          'B6',
          'C57',
          'B7',
          'C55',
          'D43',
          'C21',
          'B27',
          'A29',
          'A59',
          'D34',
          'B41',
          'C41',
          'D29',
          'D60',
          'D1',
          'A10',
          'D42',
          'C58',
          'B34',
          'C20',
          'D46',
          'A57',
          'D28',
          'B25',
          'A47',
          'A21',
          'C24',
          'B17',
          'D35',
          'B2',
          'A28',
          'B28',
          'C36',
          'A52',
          'C5',
          'C52',
          'C2',
          'B59',
          'C38',
          'D32',
          'B19',
          'C18',
          'B30',
          'D31',
          'C59',
          'D13',
          'C12',
          'A33',
          'B13',
          'D37',
          'A49',
          'C29',
          'B4',
          'D33',
          'B44',
          'C41',
          'A48',
          'C5',
          'D49',
          'A56',
          'B22',
          'A30',
          'C45',
          'B47',
          'A18',
          'B48',
          'A15',
          'D3',
          'C6',
          'D5',
          'C31',
          'B8',
          'C22',
          'D29',
          'A29',
          'D6',
          'A43',
          'B28',
          'C23',
          'D17',
          'A2',
          'D53',
          'C44',
          'D24',
          'A3',
          'B51',
          'A17',
          'C32',
          'B40',
          'D57',
          'B50',
          'A12',
          'B53',
          'C58',
          'B4',
          'D28',
          'C19',
          'D30',
          'C9',
          'A34',
          'B33',
          'A29',
          'D13',
          'C35',
          'A47',
          'A20',
          'C1',
          'D31',
          'A28',
          'B29',
          'C21',
          'D17',
          'B8',
          'A46',
          'C7',
          'B53',
          'C28',
          'B57',
          'A31',
          'B21',
          'D19',
          'C17',
          'D33',
          'D49',
          'A18',
          'B49',
          'A16',
          'D2',
          'C37',
          'D8',
          'A13',
          'C14',
          'D24',
          'C50',
          'D20',
          'A57',
          'B16',
          'A32',
          'B26',
          'C24',
          'A53',
          'D42',
          'A8',
          'B59',
          'C1',
          'A45',
          'C39',
          'B31',
          'C54',
          'B11',
          'D18',
          'C48',
          'B13',
          'C45',
          'D21',
          'C22',
          'B20',
          'C26',
          'A30',
          'B23',
          'D19',
          'A34',
          'D44',
          'C31',
          'A60',
          'C23',
          'B41',
          'C32',
          'A54',
          'D34',
          'C4',
          'D10',
          'C13',
          'A48',
          'D39',
          'C56',
          'D8',
          'A46',
          'B36',
          'C42',
          'B38',
          'D58',
          'B39',
          'C22',
          'B42',
          'C35',
          'B23',
          'D32',
          'B5',
          'C32',
          'B58',
          'C7',
          'D43',
          'A52',
          'C34',
          'A43',
          'B19',
          'A4',
          'D36',
          'B20',
          'C16',
          'B29',
          'A25',
          'C43',
          'A26',
          'C1',
          'B37',
          'C57',
          'B52',
          'C8',
          'A28',
          'B33',
          'C33',
          'D10',
          'A22',
          'C45',
          'C23',
          'A21',
          'D46',
          'C7',
          'D1',
          'A37',
          'B28',
          'D28',
          'C49',
          'B22',
          'C15',
          'B23',
          'A1',
          'B55',
          'C39',
          'A51',
          'D44',
          'C21',
          'D23',
          'A6',
          'B51',
          'C30',
          'B24',
          'A48',
          'D35',
          'B40',
          'D56',
          'D34',
          'A41',
          'A24',
          'B30',
          'C16',
          'D33',
          'B45',
          'C46',
          'A35',
          'D60',
          'A39',
          'B19',
          'C57',
          'B10',
          'A38',
          'C47',
          'B18',
          'A5',
          'D52',
          'B2',
          'D29',
          'C43',
          'D7',
          'C39',
          'B38',
          'C25',
          'A59',
          'D33',
          'A43',
          'D59',
          'B37',
          'C60',
          'B50',
          'D56',
          'B57',
          'C8',
          'A38',
          'C33',
          'B1',
          'B18',
          'C19',
          'A36',
          'B22',
          'A5',
          'D40',
          'A55',
          'C32',
          'D8',
          'C6',
          'D31',
          'B26',
          'A30',
          'C3',
          'C54',
          'D4',
          'A27',
          'C21',
          'B35',
          'A28',
          'B55',
          'C27',
          'A16',
          'B47',
          'C44',
          'D27',
          'C10',
          'A37',
          'D17',
          'B14',
          'C28',
          'B16',
          'D37',
          'C40',
          'B36',
          'C3',
          'D8',
          'B1',
          'D30',
          'A20',
          'C42',
          'A50',
          'B18',
          'A47',
          'B52',
          'C56',
          'B34',
          'A41',
          'D55',
          'D35',
          'D53',
          'C30',
          'B28',
          'D25',
          'C20',
          'A22',
          'D11',
          'C39',
          'B58',
          'C31',
          'D48',
          'A24',
          'B33',
          'C23',
          'D18',
          'C21',
          'B8',
          'D14',
          'A38',
          'C2',
          'B36',
          'C33',
          'A13',
          'B43',
          'A9',
          'D39',
          'C53',
          'B15',
          'A26',
          'C9',
          'B56',
          'C37',
          'D16',
          'A52',
          'D34',
          'A29',
          'B28',
          'C57',
          'B29',
          'A36',
          'C4',
          'C20',
          'B35',
          'D30',
          'C18',
          'B9',
          'C11',
          'D24',
          'C27',
          'B18',
          'A32',
          'C47',
          'B19',
          'D22',
          'A38',
          'B11',
          'A51',
          'C50',
          'B17',
          'D34',
          'C16',
          'D23',
          'C53',
          'A54',
          'D15',
          'C45',
          'B45',
          'D32',
          'B24',
          'C26',
          'B32',
          'A30',
          'C25',
          'B47',
          'A5',
          'D43',
          'C32',
          'B6',
          'C29',
          'D1',
          'C42',
          'A59',
          'D46',
          'A35',
          'B12',
          'C54',
          'D5',
          'A17',
          'A41',
          'D60',
          'D31',
          'A45',
          'B26',
          'C15',
          'A32',
          'B56',
          'D58',
          'B48',
          'C25',
          'D17',
          'A36',
          'C18',
          'D32',
          'A20',
          'B58',
          'A10',
          'C24',
          'A33',
          'D42',
          'A60',
          'D33',
          'B22',
          'C27',
          'B23',
          'D18',
          'A3',
          'B19',
          'C17',
          'B24',
          'D29',
          'B3',
          'C40',
          'D6',
          'A12',
          'C15',
          'D19',
          'A44',
          'B27',
          'A26',
          'C23',
          'B1',
          'D58',
          'B49',
          'A14',
          'D10',
          'C36',
          'A45',
          'C33',
          'A47',
          'D40',
          'C9',
          'B51',
          'D56',
          'D39',
          'A8',
          'D25',
          'C11',
          'D26',
          'B26',
          'A3',
          'D47',
          'C57',
          'B32',
          'C39',
          'D38',
          'A52',
          'B16',
          'A26',
          'D4',
          'C7',
          'D51',
          'A29',
          'B54',
          'A51',
          'C26',
          'B45',
          'C34',
          'B21',
          'A49',
          'C4',
          'B35',
          'A24',
          'C29',
          'D23',
          'C20',
          'B8',
          'A53',
          'C36',
          'A18',
          'D28',
          'B5',
          'C59',
          'D14',
          'C35',
          'D42',
          'C9',
          'B9',
          'D35',
          'B10',
          'C55',
          'B49',
          'C25',
          'A20',
          'C41',
          'D5',
          'A43',
          'D36',
          'D52',
          'A27',
          'D27',
          'B25',
          'C51',
          'C6',
          'A34',
          'B24',
          'C15',
          'B27',
          'D23',
          'C45',
          'A32',
          'D18',
          'B12',
          'C35',
          'A58',
          'D13',
          'A48',
          'B55',
          'A26',
          'D10',
          'A47',
          'B56',
          'A4',
          'D20',
          'C23',
          'B23',
          'A49',
          'D35',
          'C54',
          'B13',
          'A33',
          'D9',
          'A23',
          'C3',
          'B43',
          'C1',
          'A46',
          'D29',
          'C57',
          'D14',
          'A28',
          'B37',
          'C43',
          'B47',
          'D59',
          'A20',
          'D45',
          'A50',
          'C41',
          'A21',
          'B60',
          'C38',
          'D39',
          'A31',
          'B31',
          'A10',
          'B41',
          'A7',
          'B55',
          'A57',
          'C30',
          'D22',
          'C22',
          'C2',
          'A40',
          'C37',
          'A42',
          'C16',
          'A58',
          'D35',
          'B37',
          'C20',
          'D26',
          'D57',
          'B58',
          'A51',
          'B16',
          'C51',
          'B59',
          'A36',
          'B30',
          'A21',
          'D49',
          'C21',
          'B33',
          'A10',
          'B51',
          'C25',
          'D24',
          'C38',
          'D7',
          'C7',
          'B42',
          'C33',
          'D42',
          'C54',
          'B44',
          'C27',
          'B28',
          'A26',
          'D53',
          'A57',
          'C24',
          'D17',
          'A34',
          'C30',
          'A15',
          'A41',
          'B8',
          'D36',
          'A44',
          'B40',
          'C40',
          'D15',
          'A16',
          'C26',
          'B46',
          'D32',
          'C6',
          'B42',
          'C4',
          'A28',
          'B39',
          'A24',
          'B55',
          'C11',
          'D3',
          'A39',
          'C47',
          'B44',
          'C1',
          'D43',
          'A34',
          'B25',
          'A33',
          'C10',
          'D28',
          'C8',
          'D30',
          'B20',
          'D20',
          'C48',
          'A30',
          'C23',
          'B13',
          'C44',
          'D5',
          'C9',
          'B2',
          'C30',
          'B21',
          'A60',
          'D9',
          'C57',
          'B38',
          'C33',
          'D4',
          'A20',
          'D26',
          'A3',
          'B20',
          'C35',
          'A15',
          'C18',
          'A38',
          'B19',
          'D31',
          'D57',
          'A42',
          'C36',
          'D11',
          'A21',
          'B50',
          'C59',
          'B35',
          'C56',
          'B7',
          'C22',
          'B23',
          'C19',
          'B29',
          'A22',
          'C41',
          'D36',
          'C5',
          'B34',
          'A29',
          'B26',
          'A2',
          'D37',
          'A31',
          'B25',
          'A47',
          'D27',
          'B30',
          'A11',
          'D3',
          'D60',
          'B36',
          'D51',
          'C20',
          'A4',
          'D23',
          'C15',
          'B28',
          'D21',
          'A56',
          'C32',
          'D20',
          'C31',
          'D45',
          'B2',
          'D50',
          'B37',
          'C42',
          'D12',
          'A37',
          'B20',
          'A32',
          'C34',
          'B40',
          'A23',
          'C8',
          'B41',
          'A6',
          'B17',
          'A51',
          'C37',
          'D3',
          'A25',
          'B32',
          'C54',
          'B50',
          'C10',
          'B55',
          'C2',
          'A43',
          'B1',
          'A34',
          'B60',
          'A13',
          'B49',
          'C36',
          'D15',
          'C15',
          'B29',
          'A18',
          'D36',
          'D52',
          'A58',
          'B53',
          'A52',
          'D29',
          'A27',
          'C48',
          'B54',
          'C12',
          'D23',
          'C28',
          'B21',
          'C33',
          'B43',
          'A1',
          'B26',
          'C19',
          'A23',
          'D58',
          'B44',
          'C23',
          'D40',
          'D56',
          'B58',
          'A47',
          'D15',
          'A15',
          'D46',
          'A4',
          'D35',
          'A51',
          'C25',
          'B3',
          'C33',
          'B56',
          'C2',
          'D28',
          'C44',
          'B45',
          'A28',
          'D26',
          'C52',
          'C4',
          'A40',
          'B9',
          'C16',
          'A31',
          'B13',
          'A53',
          'C34',
          'A55',
          'B57',
          'C40',
          'A22',
          'C23',
          'B25',
          'C22',
          'D7',
          'A30',
          'B27',
          'D22',
          'A6',
          'C20',
          'B14',
          'D32',
          'A51',
          'B22',
          'A35',
          'C48',
          'B17',
          'D60',
          'D4',
          'C30',
          'B8',
          'D45',
          'C59',
          'D50',
          'A28',
          'B57',
          'C8',
          'A32',
          'B30',
          'C15',
          'D26',
          'B18',
          'D37',
          'C55',
          'D6',
          'A17',
          'D30',
          'B40',
          'C24',
          'B22',
          'A3',
          'D36',
          'A46',
          'C36',
          'B13',
          'C28',
          'C3',
          'D9',
          'A48',
          'B49',
          'A35',
          'B23',
          'C60',
          'B33',
          'C6',
          'D18',
          'C18',
          'B14',
          'A59',
          'B51',
          'D55',
          'A56',
          'C55',
          'C2',
          'D34',
          'B10',
          'C46',
          'D22',
          'A37',
          'C5',
          'D8',
          'C54',
          'D9',
          'C38',
          'B35',
          'C19',
          'A51',
          'B56',
          'A3',
          'D41',
          'B2',
          'C59',
          'B48',
          'C56',
          'B8',
          'D28',
          'B24',
          'C35',
          'D39',
          'C25',
          'B52',
          'A25',
          'B34',
          'A9',
          'D26',
          'C11',
          'B49',
          'C29',
          'D49',
          'A36',
          'C46',
          'B12',
          'D34',
          'C42',
          'A19',
          'B58',
          'C8',
          'B59',
          'C7',
          'A57',
          'D48',
          'A2',
          'B22',
          'D20',
          'D54',
          'A39',
          'B9',
          'D14',
          'A23',
          'B35',
          'C35',
          'B36',
          'C26',
          'A55',
          'D13',
          'C58',
          'C4',
          'B53',
          'C13',
          'A53',
          'D28',
          'A20',
          'A48',
          'C36',
          'D16',
          'A15',
          'D33',
          'D49',
          'C5',
          'A22',
          'C39',
          'D1',
          'A23',
          'B52',
          'C15',
          'A1',
          'B27',
          'C17',
          'B16',
          'A40',
          'D34',
          'B42',
          'C2',
          'D2',
          'C1',
          'A28',
          'B40',
          'C9',
          'B56',
          'A56',
          'C18',
          'A16',
          'B19',
          'D8',
          'A19',
          'D29',
          'C10',
          'B39',
          'C42',
          'B46',
          'A4',
          'B18',
          'A45',
          'D38',
          'A6',
          'B21',
          'C48',
          'B55',
          'C47',
          'D16',
          'C26',
          'A22',
          'B40',
          'C59',
          'B51',
          'C55',
          'B29',
          'A26',
          'B31',
          'D26',
          'A42',
          'B6',
          'D36',
          'B22',
          'A31',
          'B26',
          'A27',
          'C12',
          'D19',
          'C39',
          'D29',
          'D59',
          'D34',
          'B2',
          'C34',
          'D40',
          'C22',
          'D17',
          'A26',
          'D11',
          'C44',
          'B35',
          'C43',
          'A49',
          'B47',
          'C23',
          'D28',
          'A28',
          'D55',
          'B1',
          'A33',
          'B14',
          'A53',
          'B38',
          'A25',
          'C24',
          'B3',
          'D16',
          'A1',
          'D23',
          'B18',
          'D48',
          'A23',
          'C30',
          'A17',
          'B30',
          'C27',
          'A47',
          'D60',
          'D5',
          'A44',
          'C29',
          'B19',
          'A36',
          'C3',
          'B35',
          'D26',
          'C43',
          'D8',
          'C41',
          'A59',
          'D44',
          'C60',
          'B34',
          'C55',
          'B52',
          'C14',
          'A40',
          'D27',
          'C27',
          'B56',
          'D58',
          'D29',
          'B20',
          'D7',
          'A43',
          'C48',
          'A31',
          'D36',
          'A50',
          'B59',
          'A46',
          'D42',
          'C38',
          'A39',
          'C21',
          'D24',
          'A19',
          'D4',
          'C13',
          'D15',
          'B4',
          'C33',
          'A14',
          'B44',
          'A11',
          'B50',
          'C26',
          'B21',
          'C17',
          'B32',
          'A29',
          'B36',
          'C23',
          'B38',
          'D59',
          'D43',
          'C53',
          'D6',
          'C44',
          'A21',
          'B40',
          'C22',
          'A26',
          'B33',
          'A17',
          'D35',
          'A60',
          'D22',
          'C32',
          'B43',
          'C28',
          'B19',
          'A50',
          'C40',
          'D16',
          'B20',
          'C50',
          'B57',
          'C17',
          'D42',
          'A12',
          'D8',
          'C22',
          'B44',
          'D57',
          'D31',
          'B47',
          'A4',
          'B49',
          'A22',
          'D1',
          'C38',
          'D51',
          'A19',
          'D33',
          'C6',
          'A58',
          'D47',
          'A52',
          'B39',
          'A5',
          'B55',
          'C10',
          'A57',
          'A35',
          'B24',
          'A32',
          'D19',
          'C33',
          'B18',
          'A40',
          'D58',
          'C51',
          'D37',
          'A7',
          'B31',
          'A30',
          'D60',
          'B43',
          'D59',
          'A36',
          'B10',
          'A55',
          'C24',
          'A15',
          'D5',
          'C13',
          'D24',
          'C53',
          'D2',
          'A37',
          'B21',
          'C4',
          'C20',
          'A34',
          'B12',
          'D32',
          'B29',
          'C36',
          'A54',
          'C28',
          'A9',
          'B50',
          'C56',
          'D14',
          'A16',
          'B45',
          'C22',
          'B28',
          'A32',
          'D38',
          'C50',
          'C5',
          'A27',
          'B42',
          'A24',
          'D12',
          'A21',
          'D31',
          'C57',
          'B47',
          'D30',
          'C8',
          'B9',
          'D11',
          'C38',
          'A41',
          'D9',
          'C14',
          'B24',
          'C25',
          'D45',
          'A58',
          'C43',
          'D38',
          'C36',
          'B41',
          'C59',
          'D19',
          'A3',
          'D51',
          'D16',
          'A40',
          'D32',
          'C37',
          'A43',
          'D57',
          'D34',
          'B15',
          'C45',
          'A33',
          'B19',
          'C27',
          'B31',
          'D31',
          'A60',
          'C34',
          'D60',
          'B37',
          'A26',
          'D2',
          'C32',
          'D38',
          'A48',
          'B57',
          'D58',
          'C37',
          'D12',
          'C22',
          'D48',
          'A36',
          'C13',
          'A13',
          'B54',
          'B2',
          'D8',
          'B15',
          'D31',
          'A19',
          'C2',
          'B27',
          'C58',
          'B5',
          'C18',
          'B56',
          'C47',
          'D18',
          'B24',
          'A28',
          'B41',
          'A21',
          'D40',
          'D56',
          'A55',
          'B11',
          'A38',
          'B18',
          'C32',
          'B9',
          'C7',
          'D30',
          'B23',
          'C31',
          'A14',
          'D11',
          'C58',
          'A58',
          'D38',
          'B44',
          'C46',
          'D37',
          'C54',
          'B52',
          'A27',
          'B27',
          'A19',
          'C21',
          'D50',
          'A55',
          'B37',
          'C26',
          'D23',
          'B31',
          'A5',
          'B40',
          'C44',
          'B48',
          'D57',
          'B49',
          'C57',
          'D32',
          'C51',
          'A54',
          'D14',
          'A47',
          'D39',
          'C30',
          'A25',
          'C6',
          'B9',
          'D49',
          'C43',
          'D11',
          'C4',
          'B34',
          'C54',
          'C5',
          'B41',
          'C22',
          'A11',
          'B32',
          'A19',
          'A48',
          'B16',
          'A7',
          'B20',
          'A32',
          'D9',
          'C46',
          'B17',
          'D31',
          'B2',
          'D28',
          'B29',
          'A12',
          'C34',
          'B7',
          'C10',
          'C32',
          'D26',
          'B36',
          'A2',
          'D22',
          'C17',
          'B60',
          'C51',
          'D4',
          'A16',
          'B52',
          'D55',
          'B57',
          'C39',
          'A23',
          'B28',
          'C14',
          'A51',
          'C35',
          'D15',
          'C7',
          'A42',
          'C4',
          'D38',
          'B1',
          'B56',
          'A24',
          'D10',
          'C13',
          'D12',
          'A14',
          'A41',
          'B51',
          'B36',
          'C28',
          'B54',
          'C60',
          'B39',
          'A45',
          'C20',
          'D52',
          'D36',
          'B9',
          'D60',
          'A19',
          'B47',
          'A17',
          'D19',
          'D55',
          'B58',
          'C18',
          'A34',
          'D2',
          'A10',
          'D43',
          'A59',
          'C24',
          'B31',
          'A35',
          'C9',
          'A37',
          'B23',
          'A48',
          'C49',
          'A29',
          'B38',
          'A23',
          'B43',
          'C60',
          'D24',
          'C12',
          'D25',
          'C45',
          'B5',
          'D13',
          'C38',
          'B55',
          'C19',
          'D17',
          'A24',
          'B27',
          'A47',
          'D59',
          'B45',
          'C33',
          'B57',
          'A21',
          'D3',
          'C53',
          'B43',
          'A20',
          'D38',
          'C26',
          'C41',
          'B35',
          'C1',
          'D28',
          'A11',
          'B60',
          'C16',
          'D1',
          'C7',
          'B32',
          'A31',
          'C25',
          'C43',
          'D44',
          'A7',
          'C29',
          'A50',
          'B22',
          'C17',
          'B58',
          'C28',
          'B27',
          'A33',
          'C48',
          'D33',
          'A28',
          'B25',
          'C20',
          'B3',
          'B20',
          'D6',
          'C31',
          'D21',
          'A5',
          'B33',
          'A31',
          'D32',
          'A16',
          'C38',
          'A45',
          'B29',
          'C8',
          'B5',
          'B53',
          'A4',
          'C14',
          'D1',
          'C5',
          'C57',
          'B51',
          'C29',
          'A25',
          'B54',
          'A49',
          'C36',
          'B50',
          'A34',
          'C12',
          'A46',
          'D32',
          'C59',
          'B3',
          'D27',
          'C11',
          'A51',
          'B14',
          'A29',
          'C27',
          'D15',
          'A31',
          'B36',
          'A24',
          'D37',
          'C37',
          'B57',
          'A17',
          'C29',
          'B2',
          'D22',
          'B9',
          'B43',
          'C45',
          'D10',
          'A19',
          'D46',
          'C2',
          'D39',
          'D55',
          'D31',
          'A48',
          'B24',
          'A37',
          'D28',
          'B34',
          'B53',
          'A22',
          'D30',
          'C3',
          'B41',
          'C40',
          'B59',
          'C18',
          'B6',
          'C1',
          'D2',
          'C50',
          'A57',
          'C36',
          'B30',
          'A8',
          'D40',
          'A60',
          'C60',
          'D51',
          'D17',
          'A17',
          'D48',
          'A51',
          'D26',
          'D11',
          'A48',
          'C20',
          'B41',
          'A43',
          'B57',
          'A4',
          'D25',
          'B37',
          'A24',
          'B45',
          'D58',
          'A7',
          'B39',
          'A23',
          'B46',
          'A13',
          'D7',
          'C24',
          'D16',
          'C7',
          'B30',
          'C5',
          'B33',
          'C57',
          'C1',
          'A34',
          'D8',
          'B12',
          'A36',
          'D45',
          'C23',
          'A25',
          'B16',
          'C29',
          'D22',
          'C14',
          'A32',
          'B31',
          'C18',
          'B18',
          'A56',
          'D54',
          'A10',
          'D39',
          'A44',
          'C48',
          'D21',
          'B19',
          'A38',
          'D59',
          'D5',
          'C51',
          'D19',
          'B10',
          'D13',
          'C60',
          'D30',
          'A27',
          'B28',
          'D34',
          'C39',
          'D36',
          'A48',
          'D26',
          'B9',
          'D44',
          'C35',
          'A18',
          'B53',
          'C55',
          'B8',
          'C10',
          'D29',
          'C21',
          'A26',
          'B42',
          'A21',
          'C27',
          'B43',
          'B58',
          'A16',
          'D34',
          'D55',
          'A19',
          'B48',
          'A2',
          'D42',
          'A15',
          'D19',
          'D3',
          'B16',
          'D7',
          'C17',
          'A30',
          'B25',
          'A37',
          'A53',
          'B21',
          'C47',
          'C24',
          'B43',
          'D39',
          'C10',
          'A58',
          'A35',
          'B35',
          'C34',
          'D11',
          'A23',
          'B56',
          'A2',
          'C21',
          'D21',
          'C40',
          'D28',
          'A60',
          'C41',
          'B45',
          'D57',
          'A44',
          'B29',
          'C53',
          'D35',
          'B11',
          'D12',
          'C4',
          'D27',
          'B5',
          'C29',
          'D2',
          'A13',
          'C32',
          'D44',
          'B14',
          'D9',
          'A12',
          'B54',
          'C49',
          'D19',
          'C44',
          'B49',
          'B19',
          'A14',
          'C15',
          'A31',
          'B37',
          'A30',
          'D33',
          'A18',
          'D18',
          'C13',
          'A54',
          'C4',
          'D1',
          'A16',
          'C40',
          'B4',
          'C19',
          'A20',
          'B46',
          'A51',
          'D16',
          'C57',
          'B20',
          'C25',
          'D6',
          'C18',
          'B51',
          'A26',
          'B44',
          'A5',
          'D22',
          'D58',
          'B9',
          'A39',
          'D21',
          'C44',
          'D31',
          'B33',
          'C2',
          'B34',
          'C53',
          'C6',
          'A59',
          'D32',
          'C8',
          'A41',
          'B6',
          'C40',
          'B54',
          'C39',
          'D41',
          'A11',
          'C20',
          'A49',
          'B4',
          'D14',
          'C58',
          'B31',
          'C15',
          'B60',
          'C31',
          'B1',
          'C60',
          'B27',
          'C16',
          'D9',
          'A20',
          'B56',
          'D57',
          'B12',
          'A34',
          'B26',
          'C29',
          'B18',
          'D34',
          'D50',
          'A29',
          'B39',
          'C46',
          'B46',
          'C22',
          'A28',
          'B48',
          'D30',
          'A38',
          'B20',
          'A2',
          'C4',
          'B11',
          'B55',
          'D59',
          'A22',
          'C33',
          'A51',
          'C6',
          'D33',
          'D9',
          'C14',
          'D11',
          'C8',
          'A45',
          'C37',
          'A16',
          'D18',
          'D57',
          'D32',
          'C56',
          'B51',
          'C16',
          'A30',
          'B33',
          'A25',
          'D16',
          'B1',
          'D35',
          'C51',
          'B29',
          'A33',
          'D3',
          'C36',
          'B55',
          'A18',
          'D4',
          'A10',
          'D28',
          'C26',
          'B39',
          'C25',
          'A9',
          'D43',
          'C6',
          'C49',
          'B59',
          'C32',
          'A59',
          'A35',
          'B25',
          'A6',
          'B30',
          'C51',
          'B41',
          'D60',
          'B44',
          'D34',
          'A4',
          'B50',
          'A20',
          'D1',
          'A8',
          'B19',
          'B46',
          'D32',
          'A19',
          'B28',
          'C9',
          'C24',
          'B49',
          'B2',
          'C26',
          'B6',
          'D10',
          'D31',
          'D16',
          'A31',
          'C11',
          'B54',
          'C3',
          'B11',
          'A37',
          'A55',
          'D42',
          'C56',
          'D7',
          'C53',
          'D9',
          'C41',
          'B1',
          'A32',
          'B21',
          'D6',
          'C23',
          'B22',
          'A49',
          'C49',
          'C24',
          'D39',
          'D55',
          'B13',
          'D52',
          'B35',
          'C31',
          'A22',
          'B39',
          'A12',
          'C18',
          'B7',
          'C17',
          'D8',
          'C15',
          'D20',
          'A16',
          'B30',
          'B55',
          'A4',
          'D54',
          'D22',
          'D51',
          'A7',
          'B42',
          'C47',
          'A40',
          'C9',
          'D29',
          'A50',
          'C27',
          'B35',
          'A32',
          'C11',
          'D28',
          'B17',
          'A33',
          'D10',
          'B25',
          'C17',
          'B26',
          'A28',
          'B49',
          'D5',
          'D58',
          'D16',
          'A12',
          'D30',
          'C58',
          'B6',
          'C24',
          'B11',
          'D1',
          'A35',
          'C30',
          'D44',
          'B16',
          'A24',
          'B38',
          'A13',
          'B47',
          'C17',
          'B59',
          'C28',
          'D14',
          'C16',
          'A21',
          'D27',
          'B34',
          'C51',
          'C4',
          'B30',
          'A33',
          'D52',
          'B45',
          'C27',
          'A54',
          'C44',
          'B50',
          'A18',
          'C34',
          'A19',
          'B43',
          'C2',
          'C56',
          'B28',
          'C6',
          'B5',
          'A45',
          'D37',
          'C45',
          'B46',
          'C26',
          'B53',
          'C54',
          'A53',
          'C5',
          'B9',
          'D57',
          'D19',
          'A47',
          'C42',
          'D39',
          'A57',
          'C20',
          'B54',
          'A27',
          'B14',
          'D38',
          'A19',
          'C24',
          'B20',
          'A36',
          'B24',
          'A31',
          'B47',
          'D7',
          'C23',
          'D42',
          'B9',
          'D24',
          'C47',
          'D13',
          'A22',
          'D6',
          'A16',
          'D28',
          'B26',
          'A25',
          'B35',
          'D16',
          'C22',
          'A29',
          'B44',
          'C60',
          'D35',
          'D51',
          'A54',
          'B19',
          'D17',
          'C16',
          'D45',
          'C56',
          'B46',
          'C33',
          'B10',
          'A39',
          'C12',
          'D25',
          'A18',
          'B58',
          'C50',
          'B34',
          'D53',
          'A10',
          'C35',
          'B1',
          'D32',
          'B16',
          'C54',
          'C1',
          'B36',
          'D6',
          'C36',
          'D43',
          'A1',
          'B38',
          'A30',
          'C49',
          'B60',
          'C8',
          'A52',
          'B7',
          'A40',
          'D14',
          'C47',
          'C6',
          'B29',
          'A9',
          'D9',
          'C2',
          'D1',
          'A55',
          'D47',
          'C7',
          'A21',
          'B45',
          'A5',
          'B48',
          'C52',
          'B27',
          'C24',
          'B50',
          'C31',
          'D38',
          'A10',
          'C23',
          'B59',
          'C22',
          'D20',
          'C47',
          'D36',
          'C38',
          'A50',
          'C18',
          'B20',
          'D11',
          'D26',
          'A26',
          'C29',
          'A8',
          'B34',
          'C39',
          'B39',
          'C4',
          'B31',
          'C3',
          'A54',
          'D7',
          'C21',
          'D45',
          'B13',
          'A34',
          'B28',
          'A14',
          'D32',
          'A32',
          'B32',
          'A27',
          'C23',
          'B6',
          'D31',
          'B46',
          'D52',
          'D13',
          'C34',
          'B42',
          'B11',
          'C19',
          'B56',
          'C60',
          'B40',
          'C43',
          'A54',
          'D35',
          'A3',
          'B31',
          'C48',
          'D12',
          'A13',
          'A44',
          'B8',
          'A41',
          'D28',
          'A4',
          'B25',
          'A46',
          'C34',
          'B55',
          'A25',
          'C42',
          'D2',
          'D59',
          'D30',
          'B49',
          'C50',
          'D32',
          'B22',
          'A38',
          'C23',
          'B39',
          'C1',
          'D3',
          'A28',
          'D11',
          'A20',
          'B26',
          'C37',
          'B48',
          'C58',
          'B9',
          'D7',
          'C18',
          'C2',
          'B35',
          'D41',
          'A32',
          'C4',
          'C55',
          'B33',
          'A16',
          'D50',
          'B39',
          'D36',
          'C33',
          'C7',
          'B27',
          'A36',
          'C21',
          'A44',
          'C44',
          'D33',
          'B45',
          'A14',
          'D20',
          'D57',
          'A10',
          'D60',
          'A45',
          'B26',
          'C13',
          'B7',
          'C8',
          'D12',
          'B56',
          'C32',
          'B15',
          'A33',
          'B31',
          'C56',
          'D17',
          'D58',
          'D37',
          'A59',
          'A1',
          'B39',
          'D25',
          'C41',
          'D40',
          'D56',
          'B12',
          'D53',
          'B1',
          'C30',
          'A6',
          'B32',
          'C3',
          'B42',
          'A20',
          'D31',
          'D16',
          'B46',
          'D13',
          'A32',
          'B22',
          'C16',
          'D18',
          'A24',
          'C46',
          'C25',
          'A22',
          'B20',
          'A50',
          'C15',
          'A35',
          'B10',
          'C5',
          'B29',
          'D20',
          'C51',
          'B48',
          'C45',
          'B36',
          'A27',
          'B41',
          'D35',
          'C42',
          'D15',
          'A13',
          'C58',
          'D5',
          'B49',
          'A12',
          'C9',
          'B3',
          'A43',
          'D10',
          'A49',
          'C34',
          'A58',
          'D34',
          'A19',
          'B46',
          'D60',
          'B54',
          'C26',
          'A8',
          'B24',
          'A21',
          'D29',
          'B23',
          'A29',
          'B40',
          'C21',
          'D58',
          'B11',
          'D24',
          'C14',
          'B25',
          'C9',
          'B4',
          'C28',
          'B5',
          'C25',
          'A13',
          'D41',
          'B14',
          'D51',
          'A22',
          'B44',
          'C53',
          'D10',
          'C17',
          'B8',
          'C8',
          'B26',
          'C59',
          'D4',
          'D59',
          'D15',
          'C58',
          'D60',
          'A60',
          'C15',
          'D23',
          'A36',
          'C48',
          'B53',
          'A15',
          'D9',
          'B46',
          'D41',
          'A47',
          'C30',
          'B3',
          'D21',
          'C42',
          'C27',
          'D44',
          'A18',
          'D37',
          'C33',
          'A53',
          'C17',
          'A16',
          'D35',
          'B27',
          'A17',
          'B59',
          'A24',
          'D2',
          'C23',
          'D11',
          'B5',
          'D46',
          'C16',
          'A39',
          'B17',
          'D24',
          'C13',
          'A55',
          'B15',
          'A41',
          'C47',
          'B38',
          'B12',
          'B54',
          'C38',
        ],
      },
    ],
  },

  {
    title: 'Cat',
    id: 'cat',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Cat%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C7',
          'A41',
          'C8',
          'A43',
          'C9',
          'A44',
          'C6',
          'A42',
          'C4',
          'A40',
          'D60',
          'C16',
          'A54',
          'C10',
          'A45',
          'C5',
          'A47',
          'C11',
          'A51',
          'C17',
          'D57',
          'C13',
          'A46',
          'C8',
          'A43',
          'C7',
          'A41',
          'C2',
          'A42',
          'C9',
          'A49',
          'C6',
          'A40',
          'D59',
          'A44',
          'C4',
          'A48',
          'C10',
          'A55',
          'C18',
          'B25',
          'C25',
          'B26',
          'C24',
          'B24',
          'C22',
          'B27',
          'C27',
          'B28',
          'C21',
          'B2',
          'C20',
          'B21',
          'C19',
          'B6',
          'C26',
          'B10',
          'C23',
          'B23',
          'C17',
          'D51',
          'A40',
          'D42',
          'A39',
          'D41',
          'A38',
          'D43',
          'A41',
          'C10',
          'A58',
          'C9',
          'A45',
          'C8',
          'A42',
          'D60',
          'A43',
          'C11',
          'A44',
          'C7',
          'A54',
          'C5',
          'B4',
          'C24',
          'B27',
          'C29',
          'B29',
          'C31',
          'B31',
          'C30',
          'B26',
          'C18',
          'B6',
          'C23',
          'B25',
          'C20',
          'A57',
          'C19',
          'B24',
          'C16',
          'D52',
          'C8',
          'A47',
          'D59',
          'A41',
          'C9',
          'A51',
          'C4',
          'A55',
          'C3',
          'A40',
          'D49',
          'C34',
          'D50',
          'C18',
          'B27',
          'C23',
          'B1',
          'C22',
          'B28',
          'C20',
          'B4',
          'C19',
          'B25',
          'C16',
          'B7',
          'C26',
          'B12',
          'D54',
          'B22',
          'D56',
          'B23',
          'C21',
          'B3',
          'C10',
          'B6',
          'C17',
          'B26',
          'C28',
          'B13',
          'C25',
          'B14',
          'C23',
          'B24',
          'C12',
          'A42',
          'D45',
          'A38',
          'D40',
          'A39',
          'D52',
          'C15',
          'D54',
          'B23',
          'C20',
          'D49',
          'A44',
          'D48',
          'C35',
          'B34',
          'C34',
          'D51',
          'A41',
          'C1',
          'B1',
          'C21',
          'B24',
          'D56',
          'A42',
          'C10',
          'A56',
          'C7',
          'B4',
          'D50',
          'A43',
          'C5',
          'B25',
          'C15',
          'B7',
          'D53',
          'B12',
          'C29',
          'B16',
          'C28',
          'B27',
          'C17',
          'A52',
          'C9',
          'B3',
          'C25',
          'B23',
          'C47',
          'B24',
          'D54',
          'C10',
          'A43',
          'D45',
          'C16',
          'B26',
          'C19',
          'B5',
          'C27',
          'B9',
          'C24',
          'A60',
          'C6',
          'B14',
          'C22',
          'B22',
          'C21',
          'B29',
          'C28',
          'B8',
          'D51',
          'A42',
          'D46',
          'A37',
          'D38',
          'A36',
          'D37',
          'A58',
          'C3',
          'B25',
          'C8',
          'A48',
          'C18',
          'B19',
          'D55',
          'B21',
          'C43',
          'D49',
          'C19',
          'B12',
          'D52',
          'B5',
          'C24',
          'B13',
          'C7',
          'B17',
          'C31',
          'B22',
          'D53',
          'B23',
          'C10',
          'B9',
          'D51',
          'C40',
          'B20',
          'D56',
          'A39',
          'D44',
          'A43',
          'C14',
          'D58',
          'A45',
          'D48',
          'C41',
          'D50',
          'C37',
          'D52',
          'B7',
          'C13',
          'A44',
          'C2',
          'A58',
          'C22',
          'A60',
          'D40',
          'A40',
          'D16',
          'A39',
          'D17',
          'A36',
          'D36',
          'A34',
          'D7',
          'A33',
          'D34',
          'A55',
          'C16',
          'B11',
          'C27',
          'B29',
          'A22',
          'B34',
          'C60',
          'B35',
          'A21',
          'B40',
          'A26',
          'D27',
          'A45',
          'D47',
          'C21',
          'B24',
          'D53',
          'C14',
          'B25',
          'C7',
          'B26',
          'C5',
          'A41',
          'C3',
          'B2',
          'C19',
          'A53',
          'C11',
          'B15',
          'C6',
          'B17',
          'C4',
          'A52',
          'D59',
          'A35',
          'D11',
          'A39',
          'D50',
          'B9',
          'C18',
          'A50',
          'C8',
          'A40',
          'D53',
          'B6',
          'C7',
          'A57',
          'D38',
          'A60',
          'D58',
          'A47',
          'C13',
          'B10',
          'D51',
          'B23',
          'C46',
          'B24',
          'C20',
          'C40',
          'C25',
          'B8',
          'C22',
          'C39',
          'D42',
          'B2',
          'C11',
          'B16',
          'C8',
          'A53',
          'C17',
          'A43',
          'D43',
          'C38',
          'B51',
          'A9',
          'B53',
          'A11',
          'B25',
          'A10',
          'B54',
          'A8',
          'B26',
          'C4',
          'B18',
          'D55',
          'B22',
          'C51',
          'D6',
          'C50',
          'B28',
          'C32',
          'B30',
          'C27',
          'B6',
          'D44',
          'C41',
          'B58',
          'A14',
          'B59',
          'C43',
          'B60',
          'A18',
          'B38',
          'A33',
          'D52',
          'B24',
          'C45',
          'B25',
          'A9',
          'D56',
          'B21',
          'C42',
          'C19',
          'D51',
          'C38',
          'D49',
          'A41',
          'D58',
          'A52',
          'D32',
          'A31',
          'D5',
          'C14',
          'A45',
          'D26',
          'A47',
          'C12',
          'B27',
          'C55',
          'D11',
          'A33',
          'D35',
          'A54',
          'D52',
          'C36',
          'B49',
          'A5',
          'B50',
          'A10',
          'D55',
          'B23',
          'A1',
          'D50',
          'B10',
          'C25',
          'B30',
          'A26',
          'B41',
          'A21',
          'C4',
          'A24',
          'B33',
          'C55',
          'D45',
          'C41',
          'C14',
          'D27',
          'A46',
          'C17',
          'A57',
          'D52',
          'C8',
          'B5',
          'C18',
          'B22',
          'C48',
          'D3',
          'C47',
          'D4',
          'A29',
          'D30',
          'C16',
          'D32',
          'A30',
          'B32',
          'C57',
          'D13',
          'C24',
          'C41',
          'D41',
          'C40',
          'B55',
          'A15',
          'B57',
          'A12',
          'D59',
          'A56',
          'D34',
          'A34',
          'B45',
          'C30',
          'D17',
          'C4',
          'A26',
          'B44',
          'A35',
          'D58',
          'B8',
          'D52',
          'C33',
          'B32',
          'C32',
          'B47',
          'A6',
          'B48',
          'A37',
          'D9',
          'C53',
          'B31',
          'C52',
          'B25',
          'A5',
          'B51',
          'C6',
          'A23',
          'B33',
          'A19',
          'B41',
          'A18',
          'D2',
          'C45',
          'B26',
          'C3',
          'D13',
          'C14',
          'D55',
          'C40',
          'C15',
          'A42',
          'D21',
          'C7',
          'B7',
          'D44',
          'C56',
          'D10',
          'C32',
          'B15',
          'C5',
          'A50',
          'C1',
          'A53',
          'D31',
          'A30',
          'B39',
          'A27',
          'D28',
          'A45',
          'C2',
          'B3',
          'D45',
          'A29',
          'B32',
          'C31',
          'D19',
          'A4',
          'D51',
          'B24',
          'C17',
          'A44',
          'D24',
          'C43',
          'D1',
          'A17',
          'C5',
          'A20',
          'B40',
          'D56',
          'B46',
          'C56',
          'D13',
          'C37',
          'C21',
          'B13',
          'D52',
          'B22',
          'D49',
          'C40',
          'B57',
          'C6',
          'A30',
          'D41',
          'A37',
          'C3',
          'A43',
          'D20',
          'A3',
          'D53',
          'A49',
          'C8',
          'A55',
          'D39',
          'C50',
          'D7',
          'C14',
          'A46',
          'C9',
          'A47',
          'D23',
          'A23',
          'B39',
          'A22',
          'D12',
          'C58',
          'B30',
          'A25',
          'B45',
          'A2',
          'D16',
          'C5',
          'B16',
          'C1',
          'A18',
          'B42',
          'A16',
          'B56',
          'A13',
          'B59',
          'C4',
          'D18',
          'C3',
          'B18',
          'C32',
          'C17',
          'C39',
          'B20',
          'D55',
          'B25',
          'C51',
          'D38',
          'A38',
          'D13',
          'C23',
          'A59',
          'D60',
          'A9',
          'B55',
          'C37',
          'B36',
          'A29',
          'C7',
          'B50',
          'C36',
          'D29',
          'C17',
          'B9',
          'D58',
          'B1',
          'C20',
          'D2',
          'A16',
          'B27',
          'C11',
          'C41',
          'B53',
          'C38',
          'D22',
          'C3',
          'D31',
          'C35',
          'B12',
          'C34',
          'C14',
          'D14',
          'C13',
          'B25',
          'A4',
          'B51',
          'C60',
          'B23',
          'C59',
          'D50',
          'B32',
          'A27',
          'B43',
          'C54',
          'D8',
          'C29',
          'B45',
          'A21',
          'B36',
          'A32',
          'B38',
          'C39',
          'B44',
          'C37',
          'D14',
          'C6',
          'B58',
          'C14',
          'A42',
          'D48',
          'A5',
          'D50',
          'B34',
          'C57',
          'B47',
          'C31',
          'D45',
          'B4',
          'C26',
          'D25',
          'A24',
          'C5',
          'B51',
          'D56',
          'A14',
          'B27',
          'C45',
          'D3',
          'C4',
          'A54',
          'D51',
          'C17',
          'B19',
          'C41',
          'B54',
          'C38',
          'D12',
          'A32',
          'D8',
          'C28',
          'D17',
          'D32',
          'C3',
          'D34',
          'A57',
          'C16',
          'A44',
          'C15',
          'B11',
          'D57',
          'A13',
          'B52',
          'A10',
          'B36',
          'A19',
          'B45',
          'A1',
          'D20',
          'C36',
          'D30',
          'A49',
          'C2',
          'D35',
          'A55',
          'D47',
          'B22',
          'C50',
          'C19',
          'C54',
          'D12',
          'C25',
          'C49',
          'D7',
          'A16',
          'B43',
          'C53',
          'D45',
          'B19',
          'A2',
          'D17',
          'C13',
          'D30',
          'A10',
          'B57',
          'C51',
          'B31',
          'C58',
          'B42',
          'A28',
          'B38',
          'A31',
          'D9',
          'C14',
          'D16',
          'C37',
          'D27',
          'C4',
          'A27',
          'D2',
          'C19',
          'B8',
          'C7',
          'B53',
          'C44',
          'B58',
          'C5',
          'D1',
          'C46',
          'C20',
          'A52',
          'D57',
          'A8',
          'B55',
          'A3',
          'C24',
          'B23',
          'C48',
          'B57',
          'D13',
          'C22',
          'B40',
          'A19',
          'B27',
          'C10',
          'C43',
          'D25',
          'C15',
          'B26',
          'A15',
          'B45',
          'C55',
          'B46',
          'C42',
          'D47',
          'A31',
          'B34',
          'D49',
          'B10',
          'C17',
          'B36',
          'C57',
          'C18',
          'A51',
          'D60',
          'D3',
          'A8',
          'C1',
          'D36',
          'A52',
          'C13',
          'D32',
          'C35',
          'C20',
          'B3',
          'D40',
          'C49',
          'D8',
          'A34',
          'B37',
          'A29',
          'B49',
          'A11',
          'D29',
          'D13',
          'C54',
          'A9',
          'B57',
          'C39',
          'D15',
          'C6',
          'A19',
          'D10',
          'A5',
          'B30',
          'D51',
          'A57',
          'D33',
          'C12',
          'A59',
          'D41',
          'A27',
          'B29',
          'D52',
          'A10',
          'C29',
          'C48',
          'D26',
          'A22',
          'B44',
          'A16',
          'C28',
          'B45',
          'D6',
          'C15',
          'B14',
          'D24',
          'A46',
          'D60',
          'A21',
          'D5',
          'C51',
          'C18',
          'A4',
          'B41',
          'A17',
          'B42',
          'D53',
          'A32',
          'C6',
          'D21',
          'C4',
          'D29',
          'C14',
          'D10',
          'C31',
          'A2',
          'B21',
          'D54',
          'B11',
          'C30',
          'B15',
          'D23',
          'C44',
          'B18',
          'A6',
          'B24',
          'C59',
          'B49',
          'D5',
          'C24',
          'C50',
          'B31',
          'D51',
          'C58',
          'D4',
          'C45',
          'B52',
          'A38',
          'D37',
          'B1',
          'C8',
          'D20',
          'C23',
          'A60',
          'D59',
          'D36',
          'A27',
          'B38',
          'A24',
          'B30',
          'C55',
          'B60',
          'C17',
          'A5',
          'B58',
          'C48',
          'D41',
          'D21',
          'B45',
          'C60',
          'D17',
          'A17',
          'C27',
          'D1',
          'A26',
          'A11',
          'B50',
          'C35',
          'D12',
          'C24',
          'B36',
          'A35',
          'D52',
          'B18',
          'C26',
          'A18',
          'B46',
          'A15',
          'B56',
          'C7',
          'D10',
          'C53',
          'D13',
          'C21',
          'A56',
          'D58',
          'D37',
          'D22',
          'C2',
          'A7',
          'D12',
          'B11',
          'D25',
          'A8',
          'C30',
          'A50',
          'C14',
          'D20',
          'B46',
          'C54',
          'B60',
          'A13',
          'B27',
          'C7',
          'D13',
          'A42',
          'D44',
          'A37',
          'D39',
          'A56',
          'D1',
          'C51',
          'A15',
          'C29',
          'A51',
          'C8',
          'B3',
          'C6',
          'A26',
          'B49',
          'A36',
          'C16',
          'B19',
          'C3',
          'B47',
          'D12',
          'C15',
          'D26',
          'C43',
          'D4',
          'C22',
          'B23',
          'C58',
          'C32',
          'C47',
          'B25',
          'D54',
          'B6',
          'D6',
          'B34',
          'A30',
          'B35',
          'D49',
          'B36',
          'D22',
          'A41',
          'C11',
          'A54',
          'C9',
          'B14',
          'C56',
          'B28',
          'C19',
          'A3',
          'B44',
          'D30',
          'C4',
          'A29',
          'B39',
          'A21',
          'C25',
          'A25',
          'D14',
          'D44',
          'B19',
          'A10',
          'B53',
          'A47',
          'B55',
          'C11',
          'A22',
          'B30',
          'C5',
          'D16',
          'D43',
          'A45',
          'C15',
          'B24',
          'C6',
          'D26',
          'D41',
          'C2',
          'D32',
          'B42',
          'C38',
          'B35',
          'C19',
          'D8',
          'C18',
          'A43',
          'D19',
          'C45',
          'A15',
          'D24',
          'A25',
          'D2',
          'C5',
          'D17',
          'B46',
          'C53',
          'A13',
          'C30',
          'A3',
          'B59',
          'C41',
          'B39',
          'A20',
          'C26',
          'D1',
          'C25',
          'B15',
          'C56',
          'B29',
          'A26',
          'C24',
          'B42',
          'D22',
          'B16',
          'D58',
          'D41',
          'C59',
          'C31',
          'A49',
          'C19',
          'D13',
          'C18',
          'B8',
          'D44',
          'A30',
          'D48',
          'A46',
          'C12',
          'D14',
          'A34',
          'B55',
          'A14',
          'C30',
          'B39',
          'D34',
          'B41',
          'C44',
          'B54',
          'C36',
          'D53',
          'B28',
          'C15',
          'A33',
          'B33',
          'D51',
          'A11',
          'D60',
          'D22',
          'B44',
          'A20',
          'D33',
          'B15',
          'A57',
          'B21',
          'A1',
          'D15',
          'B46',
          'C7',
          'B14',
          'C33',
          'C55',
          'B34',
          'A23',
          'B40',
          'A18',
          'D9',
          'B47',
          'C30',
          'D7',
          'A13',
          'D11',
          'C39',
          'D48',
          'A52',
          'C12',
          'D17',
          'D43',
          'D18',
          'C58',
          'B33',
          'A32',
          'D29',
          'A8',
          'B57',
          'A39',
          'C16',
          'A6',
          'C31',
          'A12',
          'B52',
          'C42',
          'D28',
          'C37',
          'C53',
          'B60',
          'C52',
          'D37',
          'B38',
          'D35',
          'A52',
          'B22',
          'C29',
          'D12',
          'A5',
          'B18',
          'B3',
          'A47',
          'D54',
          'A15',
          'B47',
          'D3',
          'A18',
          'A38',
          'A11',
          'B56',
          'A46',
          'C10',
          'A59',
          'C9',
          'D19',
          'C24',
          'A29',
          'B41',
          'D33',
          'C2',
          'B4',
          'D11',
          'C26',
          'A24',
          'D60',
          'B39',
          'C50',
          'D21',
          'C4',
          'B30',
          'C54',
          'C35',
          'D34',
          'D50',
          'B33',
          'A11',
          'D19',
          'C5',
          'B17',
          'A7',
          'D58',
          'B2',
          'D46',
          'A32',
          'C24',
          'C9',
          'B15',
          'D30',
          'B45',
          'C57',
          'B13',
          'C27',
          'A19',
          'C10',
          'B55',
          'C21',
          'A60',
          'D36',
          'B35',
          'A22',
          'A37',
          'C1',
          'D44',
          'B20',
          'B2',
          'D7',
          'C48',
          'D9',
          'A33',
          'B14',
          'D51',
          'A49',
          'C15',
          'D13',
          'A35',
          'B53',
          'C59',
          'A38',
          'B43',
          'D31',
          'A9',
          'D5',
          'C50',
          'B41',
          'C34',
          'A1',
          'C32',
          'B21',
          'A54',
          'D40',
          'B36',
          'C16',
          'A8',
          'D11',
          'A40',
          'D52',
          'B30',
          'A4',
          'B58',
          'D30',
          'C4',
          'A32',
          'C22',
          'B2',
          'C10',
          'D14',
          'C7',
          'A25',
          'B32',
          'A49',
          'B52',
          'A44',
          'C12',
          'A53',
          'C6',
          'D15',
          'A40',
          'A11',
          'C28',
          'A18',
          'B54',
          'A48',
          'B22',
          'C8',
          'B6',
          'D56',
          'D22',
          'C45',
          'B49',
          'A21',
          'C23',
          'D12',
          'C59',
          'C17',
          'D13',
          'C13',
          'A29',
          'D40',
          'A49',
          'D25',
          'B13',
          'D11',
          'B54',
          'A16',
          'C29',
          'A4',
          'B42',
          'D52',
          'A15',
          'D6',
          'B16',
          'C7',
          'B32',
          'C50',
          'D1',
          'C24',
          'A19',
          'B22',
          'A12',
          'B27',
          'D54',
          'A39',
          'D36',
          'C55',
          'C18',
          'B23',
          'A47',
          'C20',
          'C49',
          'D25',
          'C5',
          'D26',
          'C38',
          'B60',
          'C28',
          'A51',
          'C14',
          'A28',
          'C25',
          'B1',
          'D8',
          'C15',
          'A37',
          'B38',
          'A25',
          'B43',
          'A23',
          'C27',
          'D7',
          'C20',
          'B5',
          'D45',
          'A2',
          'B59',
          'C56',
          'C32',
          'D6',
          'A34',
          'D57',
          'B19',
          'A55',
          'D32',
          'D60',
          'D33',
          'A32',
          'B20',
          'A3',
          'D8',
          'B15',
          'C15',
          'B36',
          'B53',
          'A30',
          'C23',
          'D5',
          'B17',
          'A33',
          'C22',
          'D20',
          'C5',
          'D28',
          'B45',
          'A16',
          'C44',
          'B25',
          'C6',
          'B46',
          'C39',
          'B59',
          'A39',
          'C57',
          'B23',
          'D57',
          'D37',
          'B34',
          'D41',
          'A35',
          'B12',
          'C17',
          'D24',
          'B41',
          'C43',
          'A17',
          'C29',
          'A27',
          'C26',
          'B21',
          'A31',
          'C7',
          'B60',
          'C6',
          'D15',
          'A25',
          'D26',
          'A48',
          'B11',
          'C52',
          'B56',
          'A10',
          'C46',
          'D18',
          'A18',
          'B22',
          'A2',
          'B44',
          'B28',
          'A28',
          'D5',
          'C43',
          'D46',
          'B24',
          'A46',
          'C2',
          'B25',
          'C12',
          'B1',
          'D53',
          'D37',
          'D22',
          'A13',
          'D59',
          'A51',
          'B51',
          'A45',
          'C11',
          'A57',
          'D47',
          'B23',
          'C16',
          'A41',
          'C13',
          'B54',
          'A15',
          'C30',
          'A9',
          'B58',
          'C40',
          'B43',
          'A17',
          'B24',
          'C53',
          'D14',
          'B46',
          'C37',
          'D18',
          'C10',
          'D8',
          'C8',
          'B45',
          'D19',
          'D43',
          'B33',
          'D38',
          'B37',
          'C55',
          'A39',
          'D12',
          'C57',
          'B49',
          'A19',
          'B39',
          'D57',
          'B3',
          'D39',
          'A59',
          'A21',
          'C19',
          'D9',
          'C8',
          'A22',
          'C28',
          'A52',
          'B29',
          'A6',
          'B57',
          'A45',
          'B32',
          'D52',
          'A41',
          'D46',
          'A58',
          'B13',
          'A34',
          'C21',
          'D5',
          'C34',
          'C49',
          'D2',
          'C52',
          'A39',
          'C1',
          'A54',
          'B28',
          'A46',
          'B31',
          'C27',
          'B43',
          'A6',
          'D47',
          'A52',
          'C18',
          'B35',
          'D25',
          'B39',
          'D36',
          'A15',
          'B58',
          'C34',
          'A8',
          'C32',
          'A48',
          'C9',
          'B21',
          'A53',
          'B48',
          'D8',
          'C27',
          'A31',
          'C23',
          'B34',
          'A11',
          'B38',
          'C51',
          'D20',
          'D43',
          'B10',
          'D26',
          'D41',
          'D57',
          'B25',
          'C43',
          'D27',
          'C3',
          'D30',
          'A24',
          'B32',
          'D44',
          'A44',
          'C10',
          'A23',
          'C5',
          'A31',
          'B22',
          'C26',
          'B24',
          'C4',
          'A33',
          'B19',
          'A58',
          'C16',
          'D5',
          'A8',
          'C49',
          'B40',
          'D35',
          'A47',
          'B12',
          'C12',
          'A30',
          'B36',
          'D39',
          'B32',
          'C56',
          'D16',
          'C6',
          'B7',
          'C20',
          'C53',
          'B34',
          'D26',
          'C47',
          'D14',
          'C7',
          'B1',
          'A11',
          'C32',
          'B46',
          'D13',
          'C11',
          'D2',
          'A10',
          'C15',
          'A34',
          'D53',
          'B29',
          'A28',
          'C60',
          'D6',
          'C31',
          'A26',
          'B42',
          'D16',
          'C39',
          'A18',
          'C25',
          'D11',
          'A6',
          'B49',
          'D5',
          'A41',
          'D41',
          'B35',
          'A33',
          'D55',
          'A14',
          'B56',
          'C49',
          'B21',
          'A50',
          'B31',
          'D53',
          'B25',
          'C1',
          'B9',
          'D54',
          'B13',
          'C17',
          'A21',
          'B43',
          'D24',
          'C6',
          'B8',
          'A53',
          'C10',
          'A57',
          'C42',
          'B56',
          'A31',
          'B24',
          'A12',
          'B32',
          'D51',
          'D14',
          'D29',
          'C22',
          'A22',
          'B38',
          'D49',
          'D15',
          'C15',
          'A39',
          'C44',
          'A46',
          'C25',
          'D18',
          'B45',
          'C43',
          'A48',
          'C20',
          'B34',
          'D43',
          'C42',
          'B2',
          'C9',
          'B24',
          'C57',
          'C35',
          'D4',
          'A14',
          'C47',
          'D9',
          'B14',
          'D48',
          'B32',
          'A44',
          'B54',
          'C56',
          'B36',
          'D36',
          'B37',
          'A28',
          'C28',
          'A17',
          'B53',
          'A26',
          'D42',
          'A45',
          'C33',
          'D5',
          'A20',
          'C24',
          'D46',
          'B14',
          'D31',
          'B42',
          'C47',
          'B57',
          'C35',
          'A5',
          'B19',
          'C11',
          'A42',
          'B32',
          'A21',
          'A37',
          'B11',
          'C36',
          'B60',
          'C27',
          'D10',
          'A12',
          'A60',
          'D57',
          'B9',
          'A51',
          'B30',
          'A1',
          'B33',
          'A31',
          'D13',
          'C8',
          'D20',
          'C5',
          'D26',
          'D41',
          'C47',
          'D15',
          'A10',
          'C33',
          'B42',
          'A39',
          'C51',
          'A6',
          'D24',
          'C44',
          'D3',
          'C24',
          'A28',
          'C19',
          'D11',
          'B46',
          'A16',
          'B22',
          'C49',
          'B41',
          'D32',
          'A35',
          'B18',
          'D58',
          'D33',
          'B39',
          'A10',
          'C1',
          'D30',
          'A55',
          'D60',
          'D34',
          'A19',
          'B50',
          'C54',
          'B33',
          'C36',
          'B35',
          'D23',
          'B43',
          'C46',
          'D2',
          'C25',
          'B4',
          'C9',
          'B55',
          'A12',
          'C27',
          'D17',
          'C11',
          'A52',
          'C2',
          'A36',
          'B52',
          'A5',
          'D25',
          'B40',
          'A27',
          'B14',
          'A47',
          'B30',
          'D55',
          'A46',
          'C24',
          'D24',
          'C16',
          'D11',
          'B47',
          'C25',
          'B49',
          'C18',
          'A56',
          'B16',
          'A34',
          'C13',
          'A43',
          'C34',
          'A6',
          'A38',
          'C58',
          'B43',
          'D20',
          'C1',
          'D45',
          'B6',
          'D55',
          'A2',
          'B60',
          'C51',
          'A40',
          'C48',
          'D13',
          'B10',
          'D42',
          'B33',
          'A17',
          'B44',
          'C27',
          'B45',
          'D16',
          'C29',
          'A49',
          'C12',
          'B54',
          'D10',
          'C40',
          'B2',
          'D60',
          'B17',
          'A13',
          'A37',
          'B32',
          'A20',
          'B21',
          'C30',
          'B28',
          'C1',
          'D21',
          'D43',
          'D23',
          'C27',
          'A51',
          'C21',
          'D6',
          'C49',
          'A9',
          'B56',
          'C41',
          'A17',
          'D10',
          'A30',
          'B22',
          'A1',
          'D16',
          'D48',
          'B18',
          'D46',
          'C22',
          'A55',
          'B20',
          'A32',
          'C23',
          'D28',
          'C5',
          'B11',
          'D49',
          'B30',
          'D44',
          'A25',
          'C32',
          'A14',
          'B46',
          'C26',
          'B25',
          'D51',
          'C37',
          'A40',
          'C43',
          'A50',
          'B10',
          'D56',
          'A55',
          'C13',
          'A58',
          'C8',
          'B24',
          'A23',
          'B28',
          'D54',
          'B41',
          'D23',
          'C50',
          'B55',
          'A18',
          'C19',
          'B7',
          'C28',
          'A30',
          'D19',
          'C9',
          'B5',
          'C2',
          'B15',
          'D29',
          'B33',
          'D27',
          'C24',
          'B30',
          'D40',
          'B35',
          'C59',
          'A29',
          'C14',
          'D12',
          'C34',
          'A44',
          'C3',
          'A59',
          'D59',
          'B8',
          'D14',
          'A15',
          'C26',
          'A42',
          'C7',
          'A48',
          'D52',
          'B43',
          'C38',
          'A19',
          'C18',
          'D9',
          'A36',
          'C13',
          'D15',
          'B6',
          'D50',
          'D34',
          'B14',
          'C12',
          'B36',
          'D4',
          'B18',
          'C42',
          'A10',
          'B40',
          'A5',
          'B59',
          'C55',
          'B39',
          'A18',
          'A59',
          'D37',
          'C49',
          'A39',
          'C28',
          'D6',
          'B47',
          'A8',
          'B58',
          'C29',
          'A50',
          'B51',
          'C54',
          'D1',
          'C38',
          'D11',
          'A2',
          'C44',
          'A47',
          'B16',
          'D20',
          'B44',
          'D52',
          'D13',
          'C52',
          'B40',
          'A17',
          'B57',
          'C7',
          'D23',
          'A7',
          'B27',
          'D58',
          'A48',
          'C3',
          'D31',
          'C14',
          'A33',
          'B37',
          'A23',
          'C17',
          'D1',
          'C23',
          'A46',
          'B17',
          'D55',
          'D33',
          'B40',
          'C41',
          'D9',
          'A59',
          'C32',
          'D18',
          'C6',
          'B29',
          'A2',
          'A37',
          'C60',
          'B48',
          'A22',
          'B31',
          'A45',
          'C47',
          'D17',
          'C39',
          'D50',
          'A49',
          'D38',
          'C3',
          'A30',
          'B24',
          'D45',
          'C40',
          'A17',
          'C26',
          'B29',
          'A25',
          'C5',
          'D29',
          'C21',
          'B4',
          'D53',
          'B13',
          'D49',
          'B46',
          'A1',
          'B34',
          'D42',
          'C46',
          'A45',
          'C1',
          'B38',
          'A21',
          'C29',
          'B59',
          'C54',
          'D4',
          'B48',
          'C58',
          'B53',
          'C42',
          'D5',
          'C9',
          'D7',
          'C20',
          'A56',
          'C43',
          'B33',
          'A44',
          'C48',
          'D2',
          'C17',
          'D10',
          'B11',
          'A36',
          'B35',
          'A11',
          'B27',
          'A55',
          'C44',
          'D25',
          'A45',
          'C45',
          'D20',
          'C34',
          'D28',
          'C15',
          'B37',
          'D26',
          'C26',
          'B60',
          'C49',
          'A43',
          'C4',
          'A57',
          'C41',
          'B21',
          'A32',
          'B55',
          'C57',
          'A30',
          'C53',
          'A38',
          'D17',
          'D47',
          'B27',
          'A47',
          'C33',
          'B43',
          'A4',
          'A26',
          'A3',
          'B58',
          'A44',
          'D15',
          'C40',
          'D25',
          'B42',
          'A7',
          'B28',
          'A53',
          'C18',
          'B50',
          'A52',
          'C27',
          'B17',
          'D53',
          'B21',
          'A13',
          'B51',
          'C53',
          'B59',
          'C28',
          'B22',
          'C11',
          'A30',
          'B38',
          'D34',
          'D50',
          'A54',
          'C23',
          'D3',
          'D44',
          'B31',
          'A28',
          'C17',
          'D14',
          'C7',
          'B5',
          'C16',
          'B8',
          'D55',
          'B40',
          'C51',
          'A41',
          'C35',
          'B35',
          'C58',
          'D11',
          'C8',
          'B7',
          'D43',
          'D24',
          'A50',
          'C32',
          'B51',
          'D15',
          'C2',
          'D18',
          'D47',
          'B30',
          'C23',
          'D6',
          'C42',
          'B3',
          'C40',
          'B27',
          'A30',
          'C50',
          'A42',
          'C35',
          'C5',
          'A28',
          'A3',
          'D10',
          'C49',
          'B51',
          'A11',
          'B54',
          'A35',
          'B19',
          'A33',
          'D54',
          'B10',
          'A41',
          'B34',
          'A19',
          'B52',
          'D13',
          'C8',
          'C34',
          'A20',
          'B45',
          'C20',
          'D8',
          'C9',
          'B37',
          'D39',
          'C3',
          'D14',
          'D50',
          'B21',
          'A49',
          'B53',
          'A4',
          'D7',
          'C41',
          'C7',
          'D25',
          'D40',
          'B31',
          'A26',
          'C30',
          'B57',
          'C45',
          'B40',
          'C35',
          'D16',
          'C13',
          'A60',
          'C10',
          'D2',
          'C51',
          'B34',
          'C22',
          'A46',
          'B29',
          'C59',
          'B58',
          'A7',
          'B33',
          'A42',
          'C24',
          'B47',
          'C55',
          'D12',
          'C20',
          'A17',
          'B26',
          'A57',
          'C14',
          'B53',
          'A45',
          'D56',
          'B1',
          'B18',
          'A34',
          'D51',
          'D22',
          'D59',
          'B4',
          'D31',
          'C1',
          'A58',
          'C29',
          'B40',
          'A30',
          'C10',
          'D17',
          'C52',
          'A31',
          'B26',
          'C2',
          'D19',
          'C37',
          'A4',
          'B56',
          'C38',
          'A39',
          'C14',
          'D13',
          'C41',
          'B45',
          'A17',
          'B49',
          'C55',
          'A11',
          'B57',
          'C18',
          'A22',
          'B42',
          'D21',
          'D36',
          'C53',
          'D1',
          'C21',
          'D6',
          'C16',
          'B13',
          'C8',
          'B31',
          'D30',
          'A42',
          'C13',
          'A45',
          'C30',
          'A16',
          'B55',
          'C44',
          'D4',
          'C25',
          'A27',
          'B41',
          'A24',
          'B20',
          'A60',
          'C51',
          'B11',
          'D42',
          'D58',
          'B29',
          'C47',
          'B37',
          'A32',
          'B23',
          'C13',
          'A48',
          'B26',
          'C12',
          'D7',
          'C11',
          'A55',
          'D22',
          'D47',
          'C40',
          'D27',
          'C2',
          'B36',
          'D35',
          'D60',
          'D39',
          'B19',
          'A4',
          'B57',
          'C42',
          'A3',
          'B28',
          'A27',
          'C20',
          'B47',
          'A7',
          'A24',
          'C33',
          'B44',
          'A21',
          'B56',
          'C32',
          'D5',
          'C22',
          'B3',
          'C3',
          'B24',
          'C48',
          'B52',
          'A50',
          'B10',
          'A39',
          'B17',
          'A36',
          'D31',
          'C21',
          'D48',
          'B21',
          'A24',
          'B34',
          'C42',
          'A15',
          'D9',
          'C9',
          'D12',
          'C18',
          'A32',
          'C15',
          'D6',
          'A8',
          'B16',
          'D28',
          'C2',
          'B37',
          'D35',
          'C56',
          'A38',
          'C52',
          'D4',
          'A22',
          'B43',
          'D14',
          'C7',
          'D17',
          'C6',
          'D19',
          'C60',
          'B22',
          'C24',
          'A30',
          'B48',
          'A20',
          'C37',
          'B59',
          'C27',
          'A26',
          'B37',
          'C42',
          'B38',
          'D26',
          'C4',
          'B1',
          'C18',
          'B4',
          'D49',
          'B17',
          'C43',
          'A51',
          'C13',
          'D6',
          'A17',
          'B50',
          'A6',
          'B34',
          'A34',
          'C8',
          'D13',
          'C38',
          'C19',
          'A50',
          'B13',
          'D41',
          'D26',
          'A4',
          'D9',
          'C51',
          'D22',
          'C28',
          'B12',
          'A35',
          'C14',
          'B15',
          'D7',
          'C59',
          'B38',
          'C56',
          'B58',
          'C30',
          'A48',
          'D54',
          'B30',
          'A46',
          'B50',
          'C13',
          'B11',
          'C29',
          'A59',
          'C24',
          'B59',
          'C18',
          'D20',
          'C5',
          'A53',
          'B8',
          'D43',
          'B32',
          'D37',
          'B35',
          'A31',
          'C49',
          'D39',
          'A35',
          'A3',
          'D15',
          'C38',
          'D17',
          'B41',
          'A23',
          'C34',
          'A9',
          'B27',
          'C9',
          'A57',
          'C40',
          'B45',
          'C56',
          'A29',
          'C54',
          'B36',
          'A24',
          'A6',
          'B51',
          'C46',
          'D3',
          'C17',
          'D4',
          'A46',
          'D59',
          'D43',
          'A31',
          'C19',
          'B47',
          'D49',
          'D15',
          'D45',
          'B29',
          'D56',
          'B3',
          'D46',
          'A52',
          'C26',
          'B2',
          'C8',
          'B15',
          'C4',
          'D21',
          'B39',
          'A11',
          'D1',
          'A39',
          'B31',
          'A35',
          'D18',
          'A1',
          'A26',
          'C21',
          'B40',
          'B55',
          'C53',
          'D12',
          'C42',
          'B41',
          'A10',
          'D4',
          'C24',
          'D11',
          'C43',
          'A53',
          'B49',
          'A20',
          'C30',
          'A57',
          'D50',
          'B29',
          'A52',
          'C7',
          'B28',
          'C28',
          'A45',
          'C45',
          'C60',
          'B52',
          'D8',
          'A37',
          'B15',
          'D59',
          'D23',
          'D43',
          'D28',
          'C33',
          'A13',
          'B23',
          'C11',
          'D10',
          'C9',
          'D12',
          'B43',
          'A18',
          'D5',
          'A2',
          'B20',
          'A33',
          'D22',
          'B37',
          'A19',
          'B53',
          'C35',
          'A7',
          'B48',
          'C19',
          'A47',
          'C10',
          'B12',
          'C18',
          'D23',
          'D56',
          'D31',
          'C1',
          'D21',
          'C31',
          'A14',
          'C46',
          'A31',
          'C25',
          'B11',
          'C20',
          'B22',
          'A49',
          'C44',
          'D10',
          'C39',
          'B52',
          'C28',
          'A50',
          'D33',
          'D54',
          'B20',
          'A27',
          'B33',
          'A12',
          'B37',
          'A1',
          'B44',
          'A9',
          'D2',
          'C27',
          'D24',
          'C50',
          'B49',
          'C8',
          'D13',
          'D55',
          'A39',
          'C46',
          'A41',
          'B16',
          'A37',
          'D60',
          'A58',
          'C43',
          'B26',
          'A24',
          'B42',
          'C28',
          'B53',
          'C57',
          'B56',
          'C31',
          'A44',
          'B8',
          'A42',
          'B14',
          'C10',
          'D6',
          'C52',
          'D19',
          'C6',
          'D25',
          'C17',
          'B22',
          'D38',
          'D54',
          'D13',
          'D52',
          'B31',
          'A31',
          'B37',
          'A22',
          'C15',
          'D9',
          'C45',
          'A46',
          'C21',
          'A16',
          'B57',
          'C25',
          'D26',
          'C4',
          'D29',
          'C2',
          'A53',
          'D57',
          'B16',
          'B55',
          'C33',
          'A43',
          'C32',
          'D20',
          'B40',
          'C34',
          'D16',
          'B5',
          'D7',
          'C16',
          'D12',
          'B12',
          'A11',
          'C48',
          'D8',
          'A16',
          'B53',
          'C55',
          'B58',
          'C25',
          'B2',
          'C23',
          'B7',
          'D5',
          'C17',
          'A54',
          'B47',
          'A1',
          'B50',
          'C58',
          'A28',
          'B19',
          'D3',
          'A30',
          'B25',
          'A20',
          'B42',
          'A10',
          'B46',
          'A9',
          'D15',
          'C7',
          'D18',
          'D45',
          'B30',
          'A23',
          'C9',
          'B23',
          'A31',
          'C44',
          'D42',
          'B24',
          'D38',
          'B34',
          'C39',
          'B53',
          'C26',
          'A45',
          'B36',
          'D28',
          'D43',
          'A54',
          'B9',
          'C22',
          'D11',
          'B48',
          'C37',
          'A41',
          'B41',
          'C29',
          'A29',
          'B27',
          'C8',
          'B55',
          'C43',
          'B1',
          'C10',
          'B20',
          'C44',
          'B24',
          'D55',
          'B3',
          'D60',
          'A45',
          'C24',
          'B60',
          'C18',
          'D1',
          'C12',
          'C40',
          'B28',
          'D46',
          'B4',
          'D38',
          'B30',
          'C57',
          'D7',
          'C10',
          'A31',
          'C3',
          'B29',
          'A13',
          'B31',
          'D28',
          'A15',
          'C31',
          'A7',
          'D6',
          'A41',
          'C15',
          'A50',
          'B15',
          'A35',
          'C22',
          'B10',
          'A38',
          'C47',
          'B60',
          'C35',
          'C60',
          'B57',
          'C38',
          'D27',
          'B36',
          'A27',
          'C30',
          'A49',
          'D49',
          'D33',
          'B30',
          'D31',
          'D59',
          'B17',
          'A32',
          'B21',
          'C22',
          'B54',
          'A6',
          'A25',
          'C31',
          'B46',
          'A21',
          'C11',
          'D11',
          'C10',
          'A50',
          'C9',
          'D12',
          'C40',
          'A4',
          'B49',
          'A9',
          'D4',
          'C23',
          'D7',
          'C52',
          'D21',
          'C3',
          'D14',
          'D50',
          'B12',
          'C5',
          'D20',
          'C60',
          'A26',
          'D3',
          'C55',
          'A40',
          'C36',
          'C51',
          'C10',
          'D18',
          'B40',
          'A25',
          'B33',
          'C7',
          'B23',
          'A47',
          'C16',
          'B22',
          'D46',
          'D22',
          'B38',
          'A12',
          'C34',
          'A42',
          'C46',
          'A44',
          'C29',
          'B46',
          'A13',
          'B32',
          'D30',
          'C4',
          'A34',
          'B18',
          'D40',
          'C48',
          'D10',
          'C26',
          'A29',
          'B56',
          'A19',
          'B42',
          'D15',
          'C53',
          'A30',
          'B26',
          'A14',
          'C27',
          'D3',
          'A16',
          'D13',
          'C8',
          'D24',
          'C42',
          'D44',
          'D60',
          'D21',
          'C30',
          'A43',
          'B9',
          'C16',
          'B14',
          'A40',
          'C47',
          'B38',
          'D58',
          'A59',
          'C28',
          'B58',
          'B43',
          'A10',
          'D19',
          'C6',
          'D27',
          'B5',
          'A45',
          'B56',
          'A1',
          'D5',
          'C54',
          'D9',
          'C12',
          'B28',
          'A47',
          'D51',
          'B3',
          'C26',
          'C11',
          'A46',
          'B55',
          'A11',
          'B40',
          'D53',
          'B41',
          'A30',
          'C47',
          'D19',
          'C35',
          'B54',
          'A5',
          'D4',
          'B8',
          'A52',
          'C19',
          'B11',
          'C1',
          'D32',
          'D59',
          'D35',
          'B13',
          'C13',
          'B16',
          'A14',
          'B20',
          'C38',
          'B45',
          'A23',
          'B36',
          'A13',
          'B54',
          'C26',
          'D17',
          'C23',
          'A45',
          'C20',
          'A49',
          'B25',
          'A32',
          'C9',
          'A60',
          'C37',
          'B10',
          'C33',
          'D16',
          'C53',
          'A35',
          'B32',
          'D28',
          'C22',
          'D22',
          'D37',
          'D53',
          'A53',
          'D33',
          'B24',
          'C16',
          'D8',
          'C46',
          'B39',
          'D26',
          'C42',
          'A40',
          'C27',
          'B46',
          'D13',
          'B57',
          'C13',
          'B58',
          'C54',
          'A26',
          'B27',
          'C43',
          'B51',
          'A20',
          'C20',
          'B56',
          'A8',
          'D45',
          'B7',
          'D56',
          'B19',
          'A34',
          'B35',
          'A25',
          'B50',
          'C50',
          'D38',
          'A17',
          'A2',
          'B36',
          'C53',
          'D18',
          'C7',
          'B34',
          'D24',
          'A53',
          'B13',
          'D32',
          'D52',
          'A4',
          'C36',
          'D15',
          'A15',
          'A59',
          'C11',
          'B28',
          'D41',
          'B29',
          'A36',
          'B17',
          'C25',
          'B6',
          'C24',
          'A48',
          'C45',
          'D23',
          'C22',
          'D30',
          'C2',
          'A55',
          'C43',
          'B30',
          'C3',
          'A51',
          'D58',
          'D21',
          'C46',
          'B44',
          'C58',
          'D6',
          'C12',
          'D16',
          'C8',
          'B9',
          'D44',
          'B21',
          'A27',
          'B48',
          'C56',
          'B23',
          'A18',
          'C35',
          'A22',
          'C10',
          'C42',
          'D8',
          'C57',
          'B35',
          'A28',
          'C30',
          'A38',
          'B14',
          'D42',
          'B27',
          'A8',
          'B32',
          'C59',
          'A37',
          'C2',
          'D20',
          'B50',
          'C33',
          'B45',
          'D51',
          'B5',
          'D60',
          'B12',
          'A43',
          'B31',
          'D38',
          'B38',
          'C40',
          'B39',
          'D21',
          'D57',
          'B2',
          'C7',
          'A23',
          'B53',
          'D9',
          'C50',
          'A38',
          'C39',
          'D12',
          'C9',
          'A33',
          'B20',
          'D51',
          'B27',
          'A4',
          'B60',
          'C13',
          'D4',
          'A21',
          'C16',
          'A48',
          'C5',
          'D20',
          'D49',
          'B51',
          'C29',
          'A9',
          'B52',
          'A24',
          'D3',
          'C26',
          'B56',
          'C19',
          'A54',
          'C14',
          'A44',
          'C27',
          'A59',
          'D47',
          'B13',
          'D10',
          'C12',
          'A56',
          'C17',
          'B4',
          'D30',
          'C15',
          'B19',
          'D52',
          'B1',
          'C41',
          'D14',
          'B9',
          'D53',
          'A55',
          'C42',
          'B44',
          'A18',
          'C21',
          'A30',
          'B31',
          'A3',
          'C48',
          'A12',
          'D18',
          'C23',
          'D19',
          'B39',
          'A28',
          'D1',
          'A19',
          'C31',
          'B57',
          'C58',
          'A29',
          'C25',
          'B59',
          'C52',
          'D8',
          'C13',
          'B26',
          'A56',
          'C5',
          'D28',
          'B34',
          'A39',
          'B47',
          'C39',
          'B39',
          'C59',
          'B41',
          'D17',
          'C8',
          'B28',
          'A14',
          'A59',
          'B25',
          'A18',
          'D11',
          'C10',
          'B36',
          'C48',
          'D16',
          'B45',
          'D50',
          'D34',
          'C57',
          'A37',
          'C53',
          'B58',
          'C38',
          'D30',
          'C2',
          'A57',
          'C39',
          'B49',
          'A7',
          'C17',
          'A38',
          'C29',
          'A43',
          'B13',
          'D60',
          'B28',
          'C60',
          'B37',
          'D27',
          'C36',
          'D3',
          'A6',
          'B17',
          'A35',
          'C20',
          'A46',
          'C6',
          'D19',
          'B7',
          'D15',
          'C57',
          'B57',
          'B14',
          'D45',
          'C37',
          'B49',
          'D10',
          'C42',
          'A16',
          'C25',
          'D6',
          'A1',
          'D11',
          'A21',
          'B20',
          'D39',
          'B29',
          'A45',
          'B6',
          'C14',
          'B22',
          'A33',
          'B52',
          'A31',
          'C40',
          'B46',
          'A32',
          'C21',
          'D32',
          'D57',
          'D41',
          'B14',
          'D60',
          'B40',
          'A22',
          'B53',
          'A7',
          'B56',
          'B15',
          'A49',
          'C44',
          'A50',
          'C6',
          'D25',
          'C39',
          'B3',
          'C19',
          'B58',
          'D49',
          'D21',
          'C23',
          'D27',
          'C17',
          'A19',
          'B21',
          'C18',
          'D2',
          'C56',
          'D11',
          'C42',
          'A56',
          'C27',
          'B52',
          'B16',
          'A40',
          'C25',
          'D10',
          'A26',
          'C59',
          'B42',
          'C45',
          'A54',
          'C28',
          'D16',
          'C24',
          'B18',
          'C19',
          'B34',
          'A29',
          'C54',
          'B53',
          'C37',
          'D1',
          'C11',
          'B26',
          'A10',
          'B48',
          'A18',
          'A1',
          'B27',
          'C51',
          'B43',
          'A37',
          'B16',
          'C4',
          'B2',
          'D47',
          'D31',
          'D55',
          'D23',
          'B36',
          'C43',
          'D9',
          'C35',
          'D15',
          'C9',
          'B6',
          'D49',
          'C5',
          'D29',
          'B30',
          'A44',
          'C51',
          'D8',
          'A6',
          'B35',
          'A24',
          'A5',
          'C32',
          'D16',
          'C7',
          'D18',
          'A45',
          'C12',
          'A29',
          'B18',
          'B51',
          'A37',
          'C48',
          'B28',
          'D51',
          'B11',
          'A52',
          'C44',
          'B43',
          'D10',
          'C30',
          'B50',
          'C55',
          'B35',
          'C3',
          'D17',
          'B4',
          'C39',
          'B55',
          'A10',
          'B22',
          'A27',
          'B23',
          'D46',
          'C41',
          'D24',
          'C7',
          'B37',
          'D59',
          'D36',
          'C51',
          'D13',
          'C36',
          'A41',
          'B13',
          'C26',
          'B5',
          'C17',
          'D5',
          'A16',
          'B35',
          'D29',
          'A29',
          'B52',
          'C59',
          'B54',
          'A17',
          'C24',
          'B1',
          'C35',
          'D49',
          'B59',
          'C50',
          'D15',
          'C25',
          'A44',
          'C46',
          'A47',
          'B3',
          'D32',
          'B29',
          'C8',
          'B32',
          'A3',
          'A36',
          'A1',
          'B25',
          'A16',
          'D51',
          'A48',
          'B27',
          'C16',
          'D13',
          'A21',
          'B24',
          'C14',
          'B10',
          'C21',
          'D34',
          'B23',
          'D43',
          'D24',
          'B40',
          'C7',
          'A39',
          'C52',
          'B33',
          'C60',
          'D11',
          'C41',
          'A58',
          'D48',
          'B22',
          'D59',
          'A42',
          'C31',
          'A8',
          'B44',
          'C44',
          'B59',
          'A32',
          'B39',
          'A24',
          'C32',
          'D28',
          'D43',
          'B12',
          'C16',
          'D14',
          'C40',
          'B33',
          'D44',
          'B47',
          'A21',
          'D21',
          'C29',
          'B57',
          'C55',
          'D7',
          'B43',
          'C7',
          'A45',
          'C18',
          'A49',
          'B16',
          'A25',
          'B19',
          'A34',
          'C54',
          'D11',
          'C21',
          'D36',
          'C12',
          'A50',
          'C4',
          'D31',
          'B24',
          'A5',
          'B57',
          'C26',
          'B8',
          'A40',
          'C47',
          'A43',
          'C28',
          'A10',
          'D7',
          'B32',
          'A34',
          'B36',
          'B51',
          'C45',
          'B53',
          'C27',
          'A60',
          'C2',
          'B10',
          'C6',
          'D27',
          'B38',
          'C31',
          'D53',
          'C17',
          'D6',
          'C44',
          'A54',
          'C43',
          'B37',
          'A13',
          'C48',
          'A39',
          'D2',
          'C37',
          'D11',
          'C10',
          'B26',
          'C41',
          'B2',
          'C1',
          'D32',
          'B32',
          'A43',
          'D36',
          'B22',
          'A14',
          'C45',
          'A48',
          'B49',
          'D5',
          'A13',
          'B41',
          'A11',
          'C12',
          'D10',
          'A4',
          'D4',
          'C34',
          'B9',
          'C7',
          'D13',
          'B42',
          'C57',
          'B44',
          'A24',
          'B23',
          'A9',
          'D19',
          'C6',
          'D27',
          'C35',
          'C11',
          'D16',
          'D48',
          'B12',
          'D57',
          'B28',
          'D34',
          'A21',
          'B59',
          'C22',
          'B60',
          'C42',
          'A59',
          'C16',
          'A38',
          'B14',
          'C5',
          'D20',
          'C47',
          'A41',
          'C14',
          'D15',
          'C34',
          'B42',
          'A27',
          'D9',
          'A6',
          'C51',
          'B44',
          'C41',
          'B37',
          'D50',
          'B57',
          'C24',
          'B20',
          'A31',
          'C29',
          'A25',
          'A5',
          'B41',
          'A40',
          'B30',
          'A14',
          'B35',
          'D28',
          'D43',
          'C44',
          'B29',
          'C25',
          'B53',
          'C23',
          'D10',
          'A2',
          'A28',
          'C48',
          'A32',
          'C17',
          'D11',
          'A50',
          'B18',
          'A36',
          'C8',
          'D17',
          'C24',
          'A31',
          'D8',
          'C55',
          'B42',
          'A5',
          'B26',
          'D55',
          'D35',
          'C54',
          'D14',
          'C39',
          'B5',
          'C12',
          'B7',
          'D58',
          'D22',
          'D45',
          'B31',
          'A38',
          'C56',
          'B49',
          'C34',
          'A58',
          'C28',
          'B43',
          'A3',
          'B56',
          'C50',
          'D11',
          'C48',
          'B59',
          'C19',
          'D19',
          'C2',
          'D20',
          'D60',
          'D39',
          'D55',
          'B16',
          'A37',
          'B34',
          'A26',
        ],
      },
    ],
  },
  {
    title: 'Michael Jackson',
    id: 'michael-jackson',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Michael%20Jackson.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D49',
          'B50',
          'D48',
          'B51',
          'D47',
          'B45',
          'D39',
          'B38',
          'D33',
          'B32',
          'D32',
          'B46',
          'B6',
          'B49',
          'B2',
          'B47',
          'D44',
          'B41',
          'D43',
          'B52',
          'D42',
          'B42',
          'D40',
          'B37',
          'A44',
          'C47',
          'A42',
          'C45',
          'A40',
          'B34',
          'A47',
          'B38',
          'D38',
          'B45',
          'D33',
          'B39',
          'A54',
          'B16',
          'B55',
          'B8',
          'B29',
          'A38',
          'B31',
          'A48',
          'C46',
          'A49',
          'B30',
          'D21',
          'B26',
          'A55',
          'B43',
          'D39',
          'B36',
          'D41',
          'C8',
          'D51',
          'C13',
          'D53',
          'C12',
          'D56',
          'D17',
          'D59',
          'C18',
          'D49',
          'B48',
          'D32',
          'B50',
          'D44',
          'B51',
          'B4',
          'C60',
          'A59',
          'B49',
          'D50',
          'C16',
          'D46',
          'B45',
          'B7',
          'B23',
          'A56',
          'C49',
          'C7',
          'C48',
          'A45',
          'C47',
          'A51',
          'C46',
          'A41',
          'B30',
          'D33',
          'B41',
          'D25',
          'B43',
          'A50',
          'B16',
          'B40',
          'D44',
          'B51',
          'D32',
          'B33',
          'A48',
          'C45',
          'A49',
          'B37',
          'D39',
          'B47',
          'B5',
          'C60',
          'B6',
          'B56',
          'B15',
          'A18',
          'B17',
          'B54',
          'D42',
          'C14',
          'D47',
          'B44',
          'B8',
          'A5',
          'B3',
          'A1',
          'A58',
          'B50',
          'D46',
          'B46',
          'D45',
          'C19',
          'D48',
          'B53',
          'D43',
          'B39',
          'A47',
          'C47',
          'C2',
          'C49',
          'C5',
          'C51',
          'C10',
          'D54',
          'D4',
          'B14',
          'A59',
          'C59',
          'B6',
          'B36',
          'A45',
          'C44',
          'A40',
          'C48',
          'A41',
          'B33',
          'D21',
          'B24',
          'A56',
          'B47',
          'D33',
          'B42',
          'A57',
          'A4',
          'C17',
          'D41',
          'B54',
          'D39',
          'B40',
          'A46',
          'C43',
          'A45',
          'C46',
          'A43',
          'B29',
          'A51',
          'B26',
          'D24',
          'B28',
          'A39',
          'B34',
          'A38',
          'C42',
          'A42',
          'C48',
          'C8',
          'C52',
          'C9',
          'D47',
          'B53',
          'B4',
          'A5',
          'B9',
          'B25',
          'A40',
          'B27',
          'D20',
          'B31',
          'A36',
          'B32',
          'D24',
          'C3',
          'C46',
          'A52',
          'B24',
          'A44',
          'C48',
          'C1',
          'D23',
          'B29',
          'D32',
          'B52',
          'D50',
          'C15',
          'D41',
          'B37',
          'D34',
          'B40',
          'D30',
          'B42',
          'D39',
          'B48',
          'B7',
          'C60',
          'A58',
          'B17',
          'A49',
          'B27',
          'A44',
          'B38',
          'D37',
          'B47',
          'A55',
          'C59',
          'A60',
          'C57',
          'B1',
          'B51',
          'D51',
          'C25',
          'D47',
          'C22',
          'D52',
          'C15',
          'A4',
          'A58',
          'C56',
          'B5',
          'B50',
          'D33',
          'B31',
          'A43',
          'C42',
          'A45',
          'C31',
          'A46',
          'C48',
          'C9',
          'C51',
          'C12',
          'D42',
          'B53',
          'D32',
          'B54',
          'B3',
          'C57',
          'B4',
          'B23',
          'A53',
          'B45',
          'D25',
          'B25',
          'A29',
          'C39',
          'A33',
          'C38',
          'A34',
          'B29',
          'A37',
          'B30',
          'D34',
          'B31',
          'B1',
          'B27',
          'D26',
          'B40',
          'D43',
          'C20',
          'A10',
          'C19',
          'D40',
          'B35',
          'D21',
          'B36',
          'A48',
          'C33',
          'A49',
          'B32',
          'D31',
          'B33',
          'D38',
          'B34',
          'D24',
          'C1',
          'C46',
          'A34',
          'B31',
          'A40',
          'C43',
          'A32',
          'C40',
          'A52',
          'B25',
          'B4',
          'C55',
          'B5',
          'A17',
          'B18',
          'A20',
          'B20',
          'A18',
          'C24',
          'D49',
          'B49',
          'D33',
          'B43',
          'D46',
          'C26',
          'D50',
          'C6',
          'C50',
          'C11',
          'D45',
          'C17',
          'A3',
          'C16',
          'D60',
          'C47',
          'C10',
          'D54',
          'D6',
          'D53',
          'B58',
          'D52',
          'C19',
          'A12',
          'C18',
          'D40',
          'B44',
          'B10',
          'A21',
          'B16',
          'A20',
          'B21',
          'B5',
          'B56',
          'B8',
          'C60',
          'A54',
          'C48',
          'A50',
          'B26',
          'A47',
          'B41',
          'D41',
          'B55',
          'B12',
          'A19',
          'B13',
          'A55',
          'C42',
          'A46',
          'B30',
          'A32',
          'C36',
          'A52',
          'C35',
          'A27',
          'B25',
          'D23',
          'B43',
          'D45',
          'B50',
          'D38',
          'B36',
          'D31',
          'B38',
          'A43',
          'C45',
          'A50',
          'C34',
          'A47',
          'C32',
          'D54',
          'D17',
          'D58',
          'C24',
          'A16',
          'B18',
          'B53',
          'B9',
          'A5',
          'B10',
          'B47',
          'D25',
          'B48',
          'D48',
          'C27',
          'D49',
          'B52',
          'D44',
          'B39',
          'D31',
          'B46',
          'A57',
          'C59',
          'B1',
          'C57',
          'B2',
          'C53',
          'C7',
          'C52',
          'A41',
          'C44',
          'A31',
          'C37',
          'A33',
          'C43',
          'D59',
          'C23',
          'D50',
          'B49',
          'A56',
          'B38',
          'A48',
          'B19',
          'A21',
          'B11',
          'B42',
          'D26',
          'B24',
          'D19',
          'B22',
          'A57',
          'C58',
          'A59',
          'A4',
          'C14',
          'D35',
          'B36',
          'A40',
          'C47',
          'C11',
          'D36',
          'B34',
          'A36',
          'C40',
          'A53',
          'A3',
          'C15',
          'D34',
          'B38',
          'D21',
          'B39',
          'A43',
          'C41',
          'A28',
          'B26',
          'A44',
          'B28',
          'A38',
          'B35',
          'A49',
          'C59',
          'A50',
          'C37',
          'A51',
          'B13',
          'A16',
          'C22',
          'A13',
          'C18',
          'D53',
          'C9',
          'D34',
          'B34',
          'D32',
          'B54',
          'D47',
          'C28',
          'D45',
          'B46',
          'B3',
          'C56',
          'B6',
          'C55',
          'A49',
          'C38',
          'A53',
          'B47',
          'D43',
          'B51',
          'D33',
          'B52',
          'A60',
          'C58',
          'A51',
          'C45',
          'D58',
          'D13',
          'D57',
          'C26',
          'D49',
          'B49',
          'D48',
          'B45',
          'B5',
          'B57',
          'B7',
          'C56',
          'B4',
          'C53',
          'C6',
          'C49',
          'D60',
          'D16',
          'C2',
          'C51',
          'C4',
          'C46',
          'A39',
          'B27',
          'A55',
          'C48',
          'C3',
          'C22',
          'A17',
          'B19',
          'B47',
          'D31',
          'B54',
          'B8',
          'A7',
          'B12',
          'A21',
          'B14',
          'B51',
          'D37',
          'B33',
          'B16',
          'D8',
          'B18',
          'B49',
          'D47',
          'B45',
          'A58',
          'C46',
          'C8',
          'D24',
          'D60',
          'D7',
          'D59',
          'D18',
          'D58',
          'D20',
          'B28',
          'D33',
          'B44',
          'A54',
          'C37',
          'A55',
          'C59',
          'A44',
          'C45',
          'C12',
          'A4',
          'A60',
          'C51',
          'A41',
          'C43',
          'A51',
          'C41',
          'A31',
          'C35',
          'A53',
          'B46',
          'D44',
          'C23',
          'D51',
          'B58',
          'D48',
          'C28',
          'A42',
          'B32',
          'A35',
          'B28',
          'D26',
          'B25',
          'A56',
          'C42',
          'A48',
          'C32',
          'A45',
          'B23',
          'D23',
          'B26',
          'B45',
          'D46',
          'B42',
          'A47',
          'C43',
          'D60',
          'C22',
          'A10',
          'C18',
          'C2',
          'D17',
          'C6',
          'D42',
          'B53',
          'B13',
          'D4',
          'D53',
          'C33',
          'A50',
          'B29',
          'D22',
          'B34',
          'B1',
          'C54',
          'A49',
          'C53',
          'C3',
          'C52',
          'A44',
          'B30',
          'D32',
          'B31',
          'A37',
          'C46',
          'A42',
          'C44',
          'C9',
          'C49',
          'A39',
          'B33',
          'D39',
          'B49',
          'A55',
          'B28',
          'A52',
          'C39',
          'A31',
          'B22',
          'B2',
          'B25',
          'A60',
          'B48',
          'D31',
          'B51',
          'D46',
          'C29',
          'A41',
          'C48',
          'C10',
          'D40',
          'B36',
          'D20',
          'B39',
          'D42',
          'B54',
          'D37',
          'B55',
          'B6',
          'C54',
          'C11',
          'D49',
          'C5',
          'C50',
          'C8',
          'D35',
          'C13',
          'A3',
          'B10',
          'A2',
          'A53',
          'C43',
          'A57',
          'B18',
          'A15',
          'C21',
          'D40',
          'B46',
          'D38',
          'B37',
          'A48',
          'A1',
          'B3',
          'B56',
          'B11',
          'D2',
          'B14',
          'A19',
          'B16',
          'A18',
          'B17',
          'A16',
          'B19',
          'D14',
          'C3',
          'D15',
          'B21',
          'A17',
          'C25',
          'D48',
          'B44',
          'D39',
          'C20',
          'A11',
          'C16',
          'A9',
          'C23',
          'A12',
          'B14',
          'B41',
          'D43',
          'B52',
          'D38',
          'B53',
          'A60',
          'C58',
          'B2',
          'C57',
          'B5',
          'C53',
          'A50',
          'C47',
          'A32',
          'B31',
          'D35',
          'C12',
          'C48',
          'A56',
          'A5',
          'C14',
          'D48',
          'B46',
          'B12',
          'B41',
          'A46',
          'C30',
          'A43',
          'C41',
          'A54',
          'C36',
          'A51',
          'C34',
          'D52',
          'D4',
          'B17',
          'B51',
          'D28',
          'B33',
          'D24',
          'D59',
          'D41',
          'B43',
          'D21',
          'D58',
          'D42',
          'B35',
          'A60',
          'A16',
          'B1',
          'B47',
          'D51',
          'C21',
          'A17',
          'B16',
          'A12',
          'C17',
          'D40',
          'C7',
          'C47',
          'A41',
          'C40',
          'A28',
          'B27',
          'D29',
          'B32',
          'A34',
          'C43',
          'A58',
          'B51',
          'B2',
          'B17',
          'B39',
          'A48',
          'A3',
          'C19',
          'A4',
          'B1',
          'B44',
          'B6',
          'A20',
          'B11',
          'C58',
          'A44',
          'C44',
          'C13',
          'A5',
          'B12',
          'D5',
          'D55',
          'D3',
          'D54',
          'C31',
          'A47',
          'C38',
          'A48',
          'B40',
          'D45',
          'B50',
          'D39',
          'B29',
          'D34',
          'C15',
          'D41',
          'B35',
          'D24',
          'C7',
          'C51',
          'C1',
          'D17',
          'D53',
          'B48',
          'A57',
          'A7',
          'B9',
          'C57',
          'B10',
          'A25',
          'B12',
          'A2',
          'B13',
          'A1',
          'A56',
          'C41',
          'A45',
          'C40',
          'A46',
          'B42',
          'D22',
          'B37',
          'D48',
          'B50',
          'D38',
          'B54',
          'D31',
          'B55',
          'B4',
          'B57',
          'D54',
          'C27',
          'D45',
          'B51',
          'B9',
          'B43',
          'A48',
          'C35',
          'A54',
          'A6',
          'A55',
          'B36',
          'B20',
          'A16',
          'C26',
          'D55',
          'C25',
          'D57',
          'C42',
          'A57',
          'C48',
          'D59',
          'D12',
          'C5',
          'C52',
          'C4',
          'D4',
          'C3',
          'C54',
          'A44',
          'C43',
          'C10',
          'D22',
          'C2',
          'B60',
          'B9',
          'A4',
          'C16',
          'A12',
          'B17',
          'D10',
          'B18',
          'B45',
          'D33',
          'B53',
          'D28',
          'B34',
          'A50',
          'C38',
          'A53',
          'C37',
          'A56',
          'C59',
          'A43',
          'C29',
          'D44',
          'B31',
          'D25',
          'B27',
          'A27',
          'C32',
          'D49',
          'B35',
          'D39',
          'B30',
          'A39',
          'C48',
          'C6',
          'C22',
          'A14',
          'B5',
          'A5',
          'C12',
          'C46',
          'A30',
          'C45',
          'A32',
          'C44',
          'A45',
          'C39',
          'A46',
          'B19',
          'A15',
          'D1',
          'C2',
          'D18',
          'B36',
          'A38',
          'C24',
          'D49',
          'B47',
          'A52',
          'A7',
          'A53',
          'B8',
          'C56',
          'B9',
          'B55',
          'D37',
          'B39',
          'D53',
          'B40',
          'D42',
          'B41',
          'D34',
          'B42',
          'B20',
          'D4',
          'D59',
          'C45',
          'A39',
          'B35',
          'A58',
          'C59',
          'B3',
          'C58',
          'B12',
          'B54',
          'D28',
          'D58',
          'D24',
          'B36',
          'D49',
          'B39',
          'D36',
          'B40',
          'A44',
          'C29',
          'D47',
          'B49',
          'D46',
          'B32',
          'B14',
          'A51',
          'C35',
          'A53',
          'B11',
          'A17',
          'A59',
          'B27',
          'A47',
          'C39',
          'A32',
          'B28',
          'D38',
          'B29',
          'D33',
          'C16',
          'C59',
          'B8',
          'A6',
          'B2',
          'C52',
          'C1',
          'C25',
          'A18',
          'A58',
          'B47',
          'D24',
          'B41',
          'D53',
          'C28',
          'D50',
          'B34',
          'B12',
          'D4',
          'D48',
          'C33',
          'A26',
          'B24',
          'A41',
          'C26',
          'A21',
          'B15',
          'D8',
          'A19',
          'B21',
          'D21',
          'B44',
          'D49',
          'C31',
          'A42',
          'C43',
          'C2',
          'C24',
          'C9',
          'D22',
          'C8',
          'C51',
          'C3',
          'D12',
          'D54',
          'B38',
          'D43',
          'D3',
          'D42',
          'B47',
          'D33',
          'B53',
          'A59',
          'C45',
          'A30',
          'B24',
          'A23',
          'B8',
          'C58',
          'A45',
          'C29',
          'D49',
          'C5',
          'C55',
          'B9',
          'A37',
          'B35',
          'A46',
          'C44',
          'A50',
          'B22',
          'D14',
          'B17',
          'A49',
          'B29',
          'D29',
          'B30',
          'A35',
          'C39',
          'A56',
          'C36',
          'A47',
          'B40',
          'D52',
          'B48',
          'D51',
          'D6',
          'C4',
          'D7',
          'D59',
          'C47',
          'C9',
          'A4',
          'B2',
          'B55',
          'B7',
          'A9',
          'C12',
          'A6',
          'B11',
          'C57',
          'B3',
          'C52',
          'D60',
          'B57',
          'D53',
          'B42',
          'D52',
          'D17',
          'C5',
          'C45',
          'A41',
          'C56',
          'B10',
          'C55',
          'A43',
          'B40',
          'D20',
          'B21',
          'A16',
          'D1',
          'A14',
          'C19',
          'A33',
          'C37',
          'A49',
          'C35',
          'A54',
          'B48',
          'B9',
          'A3',
          'C20',
          'C4',
          'C50',
          'A38',
          'C23',
          'D55',
          'B47',
          'B14',
          'A2',
          'C14',
          'C45',
          'A34',
          'C41',
          'A44',
          'B35',
          'D38',
          'B37',
          'D47',
          'B44',
          'D34',
          'B46',
          'D27',
          'B23',
          'A48',
          'C39',
          'A52',
          'C34',
          'A49',
          'B34',
          'D46',
          'B58',
          'D60',
          'D17',
          'A36',
          'D41',
          'C26',
          'D43',
          'A41',
          'B26',
          'D35',
          'B40',
          'B15',
          'A51',
          'A14',
          'B59',
          'A12',
          'B60',
          'D53',
          'D12',
          'A21',
          'D9',
          'A18',
          'B18',
          'A6',
          'C8',
          'C49',
          'A40',
          'D43',
          'C30',
          'D45',
          'B30',
          'A31',
          'B31',
          'D47',
          'D4',
          'A25',
          'C42',
          'A42',
          'C40',
          'A51',
          'B45',
          'B4',
          'B56',
          'B1',
          'A8',
          'C19',
          'D56',
          'C46',
          'C11',
          'D21',
          'D48',
          'B52',
          'B13',
          'D3',
          'B10',
          'B42',
          'D50',
          'C24',
          'A39',
          'B37',
          'D54',
          'B39',
          'D27',
          'B47',
          'D23',
          'C48',
          'A43',
          'D43',
          'B32',
          'A51',
          'B8',
          'A20',
          'D10',
          'C50',
          'D25',
          'C60',
          'A54',
          'C38',
          'A55',
          'C35',
          'D51',
          'B33',
          'B2',
          'A8',
          'C13',
          'A4',
          'B5',
          'B53',
          'D43',
          'C25',
          'A37',
          'D41',
          'B41',
          'D31',
          'B55',
          'C1',
          'C53',
          'C2',
          'D7',
          'A30',
          'C40',
          'A43',
          'B30',
          'D35',
          'C7',
          'D16',
          'A35',
          'D40',
          'C28',
          'A44',
          'B36',
          'C4',
          'D11',
          'A23',
          'B25',
          'A48',
          'C58',
          'C5',
          'C43',
          'C13',
          'C59',
          'B9',
          'B54',
          'B1',
          'A15',
          'B21',
          'A12',
          'C15',
          'A33',
          'C14',
          'D34',
          'A1',
          'A42',
          'D44',
          'B52',
          'D39',
          'B27',
          'D23',
          'C47',
          'C3',
          'D15',
          'C9',
          'B46',
          'C7',
          'B44',
          'C6',
          'C52',
          'A50',
          'C42',
          'B59',
          'A13',
          'B60',
          'C1',
          'D11',
          'C50',
          'D58',
          'D31',
          'A22',
          'D9',
          'A20',
          'B12',
          'C57',
          'D30',
          'A21',
          'B17',
          'D12',
          'D59',
          'D18',
          'D57',
          'B51',
          'A57',
          'C37',
          'A58',
          'B41',
          'B22',
          'D33',
          'B58',
          'B6',
          'C53',
          'A40',
          'B28',
          'B13',
          'A13',
          'C23',
          'A18',
          'D28',
          'C55',
          'C2',
          'C50',
          'B60',
          'C42',
          'A27',
          'D5',
          'A25',
          'D10',
          'A19',
          'B15',
          'B50',
          'A56',
          'C38',
          'B46',
          'B17',
          'A4',
          'C7',
          'C44',
          'D2',
          'D56',
          'D18',
          'A38',
          'B37',
          'C4',
          'B38',
          'D50',
          'B43',
          'A47',
          'D44',
          'C20',
          'A15',
          'D26',
          'A2',
          'B15',
          'A50',
          'C51',
          'D57',
          'B53',
          'B10',
          'A23',
          'A60',
          'C45',
          'C13',
          'B42',
          'A56',
          'B46',
          'C17',
          'D32',
          'B45',
          'D45',
          'A44',
          'D43',
          'B37',
          'B18',
          'A49',
          'C32',
          'D53',
          'D22',
          'B47',
          'D21',
          'D47',
          'A51',
          'C44',
          'C15',
          'C60',
          'A42',
          'C26',
          'A11',
          'B59',
          'B7',
          'A8',
          'C10',
          'C49',
          'D11',
          'D60',
          'B60',
          'A15',
          'B22',
          'A49',
          'B38',
          'D38',
          'A32',
          'B29',
          'B2',
          'B46',
          'C6',
          'D8',
          'B19',
          'B44',
          'C14',
          'B45',
          'D41',
          'A40',
          'C46',
          'D4',
          'C1',
          'B56',
          'A11',
          'C24',
          'A41',
          'B35',
          'A36',
          'D40',
          'B26',
          'D30',
          'C56',
          'B11',
          'A3',
          'B12',
          'C55',
          'D29',
          'A19',
          'D6',
          'A29',
          'C12',
          'B41',
          'A43',
          'C45',
          'C4',
          'D8',
          'B20',
          'B35',
          'C5',
          'B44',
          'D56',
          'C22',
          'A38',
          'C49',
          'B59',
          'C3',
          'D16',
          'A34',
          'C21',
          'B47',
          'D40',
          'A44',
          'B39',
          'B11',
          'A52',
          'C44',
          'D3',
          'A26',
          'C31',
          'D42',
          'D58',
          'C48',
          'C2',
          'D36',
          'D60',
          'D17',
          'A37',
          'D37',
          'B52',
          'D31',
          'C16',
          'B43',
          'C15',
          'A13',
          'D24',
          'C60',
          'A59',
          'B34',
          'A45',
          'C54',
          'A46',
          'D45',
          'B32',
          'A32',
          'C38',
          'B47',
          'D54',
          'C27',
          'A40',
          'C30',
          'D46',
          'D21',
          'C45',
          'B1',
          'A14',
          'D2',
          'B15',
          'A1',
          'D26',
          'C52',
          'D14',
          'B18',
          'D12',
          'A22',
          'B10',
          'A7',
          'B19',
          'A6',
          'C11',
          'B45',
          'C8',
          'D15',
          'B16',
          'A50',
          'B33',
          'D50',
          'B48',
          'C20',
          'A34',
          'D39',
          'B32',
          'A40',
          'D42',
          'B57',
          'B3',
          'A8',
          'D38',
          'C50',
          'C9',
          'C28',
          'D51',
          'B36',
          'C16',
          'C43',
          'B58',
          'D37',
          'A38',
          'C47',
          'A33',
          'C13',
          'B43',
          'C10',
          'C46',
          'D34',
          'B32',
          'D52',
          'C35',
          'A31',
          'D13',
          'C4',
          'B39',
          'A41',
          'D41',
          'A47',
          'B29',
          'A45',
          'C38',
          'A52',
          'B12',
          'A17',
          'B17',
          'A8',
          'C9',
          'B30',
          'D26',
          'B46',
          'D56',
          'C43',
          'D20',
          'B48',
          'A53',
          'C34',
          'B43',
          'C8',
          'A2',
          'C17',
          'A10',
          'B56',
          'C40',
          'A19',
          'C39',
          'A51',
          'B28',
          'A27',
          'D6',
          'A16',
          'D26',
          'B23',
          'A42',
          'C24',
          'D43',
          'A39',
          'D18',
          'D54',
          'B40',
          'D45',
          'B51',
          'C26',
          'C11',
          'B32',
          'D22',
          'A12',
          'D23',
          'C59',
          'A43',
          'B42',
          'D29',
          'A6',
          'C6',
          'B36',
          'A36',
          'B27',
          'B4',
          'C57',
          'B5',
          'C56',
          'B13',
          'A5',
          'D36',
          'B41',
          'C9',
          'D53',
          'C34',
          'D47',
          'A52',
          'D45',
          'D21',
          'D44',
          'B34',
          'C14',
          'A31',
          'C46',
          'D6',
          'A28',
          'C33',
          'B42',
          'D24',
          'B24',
          'D59',
          'C53',
          'D27',
          'A17',
          'D7',
          'A29',
          'B26',
          'A56',
          'A20',
          'D11',
          'A25',
          'D17',
          'A35',
          'C15',
          'B36',
          'D55',
          'B51',
          'D50',
          'C36',
          'A57',
          'B37',
          'C5',
          'D3',
          'A24',
          'D32',
          'B35',
          'A49',
          'B44',
          'C4',
          'C31',
          'A48',
          'C57',
          'D31',
          'A9',
          'C11',
          'A2',
          'D34',
          'C45',
          'A44',
          'C40',
          'A23',
          'D4',
          'B9',
          'A54',
          'D46',
          'B29',
          'C8',
          'B30',
          'A30',
          'D9',
          'C48',
          'B59',
          'C47',
          'C13',
          'B34',
          'C7',
          'B43',
          'D43',
          'A46',
          'B22',
          'A12',
          'B58',
          'D42',
          'D3',
          'C4',
          'C53',
          'D41',
          'C32',
          'A28',
          'C40',
          'A47',
          'A5',
          'C16',
          'A31',
          'B23',
          'D33',
          'C60',
          'C18',
          'A16',
          'A57',
          'B49',
          'D46',
          'A45',
          'B38',
          'A55',
          'B50',
          'C28',
          'C5',
          'D5',
          'A17',
          'B20',
          'B5',
          'B55',
          'C39',
          'A53',
          'D47',
          'A21',
          'D10',
          'C48',
          'D38',
          'C29',
          'D13',
          'C4',
          'D9',
          'C26',
          'D54',
          'C30',
          'A45',
          'B23',
          'A15',
          'D25',
          'A14',
          'B6',
          'C53',
          'D17',
          'C3',
          'B35',
          'C13',
          'D34',
          'B21',
          'A52',
          'C36',
          'A58',
          'B13',
          'C58',
          'B1',
          'B57',
          'B14',
          'D10',
          'A24',
          'D12',
          'D52',
          'B46',
          'C37',
          'A34',
          'B33',
          'D20',
          'C46',
          'A41',
          'D38',
          'C52',
          'D15',
          'A36',
          'D16',
          'A33',
          'C18',
          'B38',
          'C10',
          'B47',
          'A6',
          'B48',
          'D19',
          'A40',
          'B26',
          'D47',
          'B28',
          'A50',
          'D48',
          'A21',
          'C25',
          'B51',
          'D39',
          'A35',
          'C14',
          'D51',
          'C36',
          'A45',
          'B41',
          'B4',
          'A24',
          'B7',
          'A7',
          'D30',
          'A20',
          'D3',
          'D38',
          'C45',
          'D60',
          'B23',
          'D5',
          'B11',
          'C56',
          'D29',
          'B38',
          'D47',
          'A55',
          'B51',
          'C28',
          'A45',
          'D42',
          'C27',
          'A15',
          'C19',
          'B48',
          'C43',
          'D2',
          'A25',
          'D32',
          'B53',
          'C32',
          'D48',
          'A22',
          'D11',
          'D60',
          'B26',
          'A23',
          'C39',
          'A60',
          'A20',
          'D49',
          'C17',
          'A34',
          'C16',
          'D33',
          'B33',
          'A37',
          'B19',
          'D57',
          'C13',
          'B48',
          'C40',
          'B50',
          'D58',
          'B22',
          'A16',
          'C15',
          'A7',
          'B17',
          'A3',
          'C18',
          'D30',
          'B55',
          'B23',
          'D42',
          'A38',
          'B30',
          'C6',
          'B47',
          'D38',
          'A9',
          'B56',
          'B2',
          'C45',
          'A49',
          'D46',
          'A55',
          'C44',
          'D34',
          'B50',
          'C48',
          'A48',
          'B44',
          'D43',
          'A14',
          'C21',
          'A38',
          'C16',
          'B37',
          'A36',
          'D35',
          'A4',
          'D28',
          'B54',
          'D38',
          'B32',
          'C8',
          'C43',
          'A20',
          'B19',
          'A18',
          'B10',
          'C59',
          'D24',
          'C49',
          'B60',
          'A11',
          'D22',
          'C2',
          'D46',
          'A43',
          'B16',
          'A19',
          'D28',
          'B55',
          'B17',
          'A3',
          'C5',
          'B38',
          'D55',
          'C48',
          'D35',
          'C43',
          'C14',
          'B46',
          'D43',
          'C58',
          'C4',
          'B40',
          'D44',
          'A18',
          'D6',
          'A11',
          'B16',
          'A47',
          'A4',
          'B8',
          'B57',
          'C1',
          'B21',
          'A57',
          'C38',
          'B49',
          'C29',
          'D41',
          'B25',
          'A45',
          'D45',
          'A50',
          'D42',
          'B24',
          'B40',
          'D38',
          'B48',
          'B4',
          'A8',
          'D30',
          'C55',
          'C6',
          'B34',
          'C11',
          'A29',
          'D5',
          'A26',
          'B28',
          'D40',
          'B31',
          'A30',
          'D37',
          'C51',
          'A35',
          'D15',
          'B43',
          'C33',
          'D52',
          'A53',
          'B14',
          'A38',
          'D16',
          'A25',
          'C34',
          'B44',
          'D55',
          'C15',
          'A34',
          'D17',
          'C40',
          'A31',
          'D36',
          'B52',
          'C28',
          'D41',
          'C51',
          'D26',
          'A14',
          'B23',
          'A12',
          'D31',
          'A23',
          'D13',
          'C30',
          'A47',
          'B44',
          'A59',
          'C37',
          'A36',
          'B29',
          'A42',
          'C46',
          'D57',
          'B21',
          'B5',
          'D1',
          'C45',
          'C6',
          'C27',
          'B50',
          'D33',
          'B59',
          'B4',
          'C54',
          'B9',
          'A29',
          'C47',
          'C4',
          'D14',
          'C31',
          'A44',
          'C59',
          'A59',
          'C43',
          'C11',
          'B39',
          'D47',
          'C26',
          'D10',
          'A23',
          'D50',
          'B28',
          'D37',
          'B48',
          'A6',
          'D36',
          'A38',
          'B18',
          'A4',
          'C4',
          'B41',
          'D20',
          'C57',
          'D21',
          'A10',
          'B6',
          'C49',
          'D8',
          'B22',
          'D57',
          'B43',
          'B7',
          'B58',
          'A14',
          'D3',
          'A25',
          'B16',
          'D56',
          'C47',
          'D38',
          'C43',
          'A52',
          'D43',
          'A47',
          'D48',
          'C25',
          'C10',
          'B29',
          'A40',
          'C49',
          'D1',
          'A13',
          'D42',
          'B27',
          'A53',
          'B42',
          'C32',
          'C3',
          'B36',
          'A37',
          'D18',
          'A7',
          'C20',
          'B39',
          'D40',
          'C33',
          'D45',
          'A18',
          'C19',
          'A38',
          'C15',
          'B33',
          'A30',
          'D5',
          'A24',
          'D17',
          'D51',
          'B37',
          'D32',
          'A23',
          'D47',
          'B44',
          'D39',
          'B46',
          'A51',
          'B21',
          'A11',
          'B55',
          'D36',
          'A20',
          'D12',
          'C9',
          'B31',
          'A35',
          'D41',
          'C56',
          'B13',
          'C55',
          'C3',
          'B23',
          'D25',
          'A1',
          'C20',
          'A17',
          'D28',
          'A5',
          'C23',
          'B51',
          'C48',
          'D11',
          'A21',
          'D50',
          'C22',
          'A33',
          'D39',
          'A10',
          'D31',
          'B53',
          'B8',
          'A48',
          'D21',
          'B27',
          'C7',
          'A3',
          'C2',
          'B36',
          'A35',
          'B37',
          'D56',
          'B50',
          'A18',
          'C18',
          'A45',
          'B26',
          'A37',
          'D38',
          'B51',
          'A8',
          'B56',
          'A6',
          'B7',
          'C57',
          'B8',
          'D5',
          'C45',
          'D22',
          'A50',
          'C46',
          'D59',
          'D14',
          'D57',
          'D41',
          'C50',
          'C7',
          'B35',
          'A40',
          'D49',
          'A24',
          'D9',
          'C49',
          'B58',
          'D31',
          'C41',
          'B59',
          'C2',
          'D8',
          'A31',
          'D19',
          'A44',
          'B12',
          'B36',
          'C13',
          'A34',
          'C40',
          'D20',
          'B42',
          'C9',
          'B48',
          'A56',
          'C35',
          'A45',
          'C26',
          'D42',
          'C56',
          'A48',
          'D41',
          'B27',
          'A43',
          'D45',
          'A55',
          'D53',
          'C31',
          'C8',
          'C44',
          'B49',
          'A53',
          'D43',
          'B22',
          'A49',
          'C48',
          'B58',
          'B1',
          'B20',
          'A13',
          'D23',
          'C46',
          'A56',
          'A19',
          'B52',
          'D33',
          'C47',
          'B60',
          'A14',
          'B19',
          'A4',
          'B45',
          'A57',
          'B23',
          'A38',
          'D19',
          'A7',
          'C7',
          'D7',
          'C5',
          'B39',
          'C19',
          'A11',
          'D23',
          'C2',
          'D30',
          'B28',
          'B43',
          'C6',
          'B25',
          'D5',
          'A16',
          'B3',
          'A15',
          'B4',
          'C38',
          'B45',
          'C37',
          'A24',
          'D54',
          'C21',
          'A45',
          'C17',
          'C42',
          'A20',
          'D29',
          'B16',
          'A8',
          'D31',
          'C59',
          'D32',
          'B20',
          'A59',
          'B36',
          'C12',
          'B31',
          'A27',
          'B32',
          'D25',
          'B40',
          'C11',
          'C45',
          'C1',
          'D8',
          'C27',
          'A32',
          'D16',
          'C60',
          'A42',
          'D37',
          'A7',
          'B21',
          'D56',
          'C52',
          'D58',
          'D36',
          'A40',
          'B39',
          'C3',
          'C42',
          'D19',
          'B49',
          'A15',
          'D34',
          'C49',
          'D10',
          'A30',
          'B36',
          'C6',
          'C51',
          'D60',
          'B52',
          'C27',
          'D46',
          'A21',
          'B55',
          'A10',
          'D4',
          'A18',
          'A60',
          'A17',
          'D25',
          'B46',
          'A5',
          'C22',
          'A42',
          'B31',
          'D53',
          'B44',
          'D15',
          'C1',
          'D30',
          'C42',
          'C15',
          'B34',
          'D40',
          'C31',
          'B51',
          'C50',
          'D26',
          'B39',
          'A49',
          'D13',
          'A47',
          'D46',
          'C35',
          'B5',
          'C54',
          'A48',
          'B24',
          'C3',
          'B41',
          'B11',
          'A14',
          'B12',
          'C57',
          'D22',
          'A40',
          'D48',
          'A51',
          'D44',
          'C58',
          'B3',
          'C45',
          'D23',
          'C60',
          'D17',
          'C43',
          'B49',
          'C29',
          'C4',
          'A5',
          'A57',
          'A19',
          'D11',
          'B16',
          'D33',
          'B34',
          'A42',
          'D19',
          'A45',
          'B44',
          'D46',
          'C23',
          'A37',
          'D35',
          'A2',
          'C10',
          'A29',
          'D9',
          'C25',
          'A13',
          'B15',
          'A60',
          'A16',
          'B48',
          'C47',
          'D5',
          'A11',
          'B58',
          'A8',
          'B18',
          'D58',
          'C54',
          'D28',
          'B38',
          'D51',
          'A22',
          'B26',
          'A54',
          'B46',
          'B10',
          'C40',
          'B12',
          'D27',
          'A3',
          'D34',
          'C41',
          'A27',
          'D56',
          'D20',
          'A7',
          'D22',
          'A51',
          'C24',
          'C9',
          'D13',
          'A48',
          'B28',
          'A43',
          'C19',
          'A44',
          'C57',
          'D40',
          'C30',
          'D14',
          'A25',
          'D31',
          'C56',
          'D43',
          'B51',
          'D19',
          'A29',
          'C33',
          'D47',
          'C20',
          'D57',
          'C49',
          'D37',
          'A33',
          'B29',
          'A31',
          'D9',
          'B18',
          'D3',
          'B11',
          'C54',
          'D38',
          'C42',
          'D59',
          'B25',
          'D48',
          'A54',
          'B41',
          'D40',
          'C27',
          'B53',
          'A18',
          'D35',
          'A28',
          'C13',
          'B37',
          'C12',
          'C44',
          'A56',
          'D46',
          'B39',
          'A36',
          'C14',
          'B33',
          'C8',
          'B48',
          'D24',
          'A12',
          'B44',
          'C33',
          'D38',
          'A38',
          'B32',
          'C45',
          'D6',
          'B7',
          'C36',
          'A53',
          'D23',
          'A49',
          'D50',
          'A25',
          'D1',
          'A23',
          'C38',
          'B5',
          'C31',
          'B55',
          'A58',
          'B15',
          'D34',
          'A16',
          'D27',
          'B26',
          'D43',
          'A15',
          'B10',
          'A4',
          'D21',
          'B47',
          'A14',
          'D4',
          'C3',
          'B25',
          'A57',
          'C35',
          'B42',
          'A49',
          'D49',
          'B48',
          'C37',
          'A27',
          'B33',
          'C12',
          'C27',
          'A44',
          'C51',
          'B3',
          'C26',
          'C7',
          'D47',
          'B50',
          'A6',
          'D30',
          'B53',
          'D43',
          'C53',
          'B8',
          'B42',
          'A10',
          'C21',
          'A47',
          'C18',
          'A48',
          'C47',
          'D58',
          'C30',
          'D21',
          'B29',
          'A28',
          'B30',
          'D48',
          'B36',
          'C1',
          'B20',
          'A58',
          'D25',
          'A4',
          'C6',
          'B37',
          'D37',
          'C46',
          'B47',
          'A51',
          'C42',
          'B51',
          'C22',
          'D5',
          'C3',
          'D7',
          'C56',
          'A42',
          'C30',
          'B50',
          'C29',
          'D60',
          'D8',
          'D39',
          'A9',
          'D29',
          'B45',
          'C5',
          'C46',
          'B48',
          'C45',
          'C7',
          'B29',
          'A39',
          'D16',
          'C52',
          'D36',
          'B44',
          'C59',
          'A47',
          'D51',
          'A54',
          'A20',
          'B14',
          'D55',
          'B18',
          'A3',
          'B43',
          'A43',
          'A1',
          'D15',
          'D57',
          'D41',
          'C48',
          'D53',
          'A23',
          'D5',
          'A28',
          'C26',
          'B53',
          'D19',
          'A8',
          'B20',
          'A53',
          'D42',
          'C25',
          'A37',
          'D16',
          'A24',
          'A59',
          'B54',
          'B7',
          'C35',
          'B43',
          'D40',
          'B22',
          'A46',
          'D13',
          'A33',
          'C49',
          'A44',
          'D47',
          'A57',
          'D17',
          'A5',
          'C3',
          'A58',
          'B36',
          'A50',
          'C45',
          'D2',
          'A24',
          'D52',
          'D22',
          'A13',
          'C14',
          'A38',
          'B38',
          'D48',
          'C19',
          'D1',
          'C50',
          'A35',
          'B40',
          'C32',
          'D38',
          'C28',
          'B2',
          'C8',
          'B34',
          'A37',
          'C20',
          'B51',
          'D34',
          'B22',
          'D32',
          'A31',
          'D23',
          'C58',
          'B17',
          'D56',
          'B39',
          'D55',
          'C47',
          'A35',
          'C38',
          'B11',
          'A10',
          'B14',
          'A14',
          'B48',
          'D36',
          'C50',
          'B52',
          'A6',
          'C21',
          'D42',
          'B20',
          'B51',
          'C29',
          'D21',
          'B23',
          'A11',
          'D32',
          'A22',
          'D10',
          'A29',
          'B15',
          'A1',
          'D33',
          'C45',
          'B33',
          'A60',
          'C37',
          'B49',
          'A7',
          'B22',
          'D28',
          'B55',
          'C38',
          'B24',
          'B47',
          'D53',
          'C28',
          'B5',
          'A15',
          'B8',
          'C18',
          'D57',
          'C13',
          'B30',
          'A52',
          'C10',
          'A13',
          'B57',
          'A60',
          'C48',
          'D19',
          'A41',
          'D49',
          'A57',
          'C41',
          'A18',
          'D26',
          'C60',
          'D18',
          'A26',
          'D12',
          'C5',
          'B34',
          'A27',
          'D51',
          'D22',
          'B52',
          'D59',
          'B26',
          'C40',
          'B27',
          'A52',
          'C42',
          'C9',
          'B28',
          'D5',
          'C21',
          'D25',
          'C33',
          'B54',
          'C32',
          'D13',
          'C23',
          'A21',
          'D52',
          'B34',
          'D21',
          'A47',
          'C49',
          'A53',
          'C30',
          'A25',
          'D33',
          'B17',
          'C28',
          'D44',
          'A45',
          'B27',
          'C39',
          'A20',
          'C27',
          'B5',
          'C15',
          'B35',
          'C14',
          'B1',
          'C48',
          'D54',
          'B13',
          'D11',
          'B10',
          'C22',
          'A35',
          'B41',
          'D35',
          'A40',
          'B16',
          'A13',
          'D9',
          'A32',
          'D60',
          'C46',
          'D7',
          'A16',
          'A60',
          'C20',
          'D26',
          'A12',
          'D41',
          'C58',
          'D31',
          'D1',
          'B6',
          'C38',
          'B12',
          'B53',
          'C25',
          'D45',
          'C56',
          'C4',
          'B43',
          'C32',
          'A43',
          'C53',
          'D28',
          'B25',
          'C42',
          'B49',
          'A52',
          'D48',
          'B48',
          'B1',
          'D1',
          'C18',
          'A49',
          'C47',
          'D36',
          'C43',
          'B33',
          'C10',
          'B39',
          'D51',
          'B40',
          'A10',
          'B44',
          'A3',
          'D35',
          'A45',
          'D13',
          'A50',
          'B21',
          'D44',
          'A41',
          'D23',
          'A7',
          'B58',
          'D37',
          'C30',
          'A26',
          'D49',
          'C5',
          'C21',
          'A42',
          'B38',
          'A30',
          'D31',
          'B9',
          'C24',
          'B53',
          'D46',
          'C15',
          'A11',
          'B17',
          'D29',
          'B24',
          'A38',
          'C38',
          'A48',
          'D40',
          'B49',
          'C8',
          'A29',
          'D16',
          'A22',
          'D49',
          'A39',
          'D37',
          'B21',
          'D60',
          'D17',
          'A56',
          'A18',
          'A58',
          'C35',
          'B45',
          'C59',
          'C10',
          'B40',
          'D22',
          'A52',
          'C9',
          'A13',
          'D25',
          'A16',
          'B2',
          'B17',
          'D39',
          'C29',
          'D43',
          'A37',
          'B55',
          'A19',
          'D2',
          'C50',
          'B53',
          'D19',
          'C47',
          'D41',
          'A45',
          'B32',
          'A31',
          'D6',
          'B22',
          'C31',
          'D50',
          'A24',
          'C36',
          'D44',
          'A55',
          'C40',
          'A57',
          'B38',
          'C21',
          'D55',
          'B19',
          'A1',
          'D16',
          'B42',
          'A52',
          'C37',
          'B12',
          'C43',
          'D29',
          'A5',
          'C25',
          'D54',
          'B30',
          'C5',
          'B46',
          'A15',
          'D12',
          'C50',
          'A37',
          'D19',
          'D59',
          'D8',
          'B14',
          'D38',
          'A34',
          'C51',
          'D35',
          'C44',
          'B28',
          'D23',
          'A47',
          'B47',
          'A5',
          'D20',
          'B43',
          'D49',
          'A45',
          'C7',
          'A60',
          'B18',
          'C28',
          'D11',
          'A26',
          'D32',
          'B23',
          'C5',
          'A1',
          'D34',
          'B44',
          'A38',
          'C12',
          'A28',
          'C17',
          'B8',
          'C35',
          'D29',
          'B50',
          'C51',
          'B35',
          'D52',
          'A25',
          'D13',
          'C22',
          'A59',
          'C59',
          'D44',
          'C26',
          'B5',
          'C48',
          'A44',
          'B42',
          'A9',
          'D2',
          'C42',
          'A21',
          'D28',
          'A7',
          'B23',
          'B41',
          'C34',
          'D36',
          'B27',
          'A33',
          'B30',
          'A17',
          'D3',
          'C4',
          'D14',
          'A3',
          'D60',
          'B36',
          'A39',
          'B17',
          'A54',
          'D23',
          'A14',
          'D41',
          'C27',
          'A56',
          'B56',
          'A36',
          'C29',
          'B55',
          'A42',
          'D24',
          'C47',
          'D1',
          'C5',
          'A60',
          'A9',
          'B57',
          'C40',
          'D35',
          'A21',
          'B54',
          'D19',
          'C41',
          'D30',
          'D48',
          'C13',
          'A56',
          'B25',
          'A38',
          'C50',
          'B59',
          'B6',
          'C48',
          'B52',
          'B1',
          'B23',
          'C31',
          'D36',
          'A44',
          'C42',
          'B29',
          'D20',
          'C39',
          'B3',
          'C16',
          'B32',
          'A2',
          'B42',
          'C12',
          'B38',
          'D41',
          'C34',
          'A27',
          'D4',
          'A24',
          'D45',
          'C24',
          'D55',
          'B52',
          'D33',
          'B59',
          'A8',
          'D21',
          'A49',
          'A13',
          'B21',
          'C32',
          'B4',
          'C58',
          'B42',
          'A35',
          'D32',
          'A10',
          'B41',
          'D51',
          'C11',
          'B28',
          'A21',
          'D54',
          'B11',
          'D33',
          'B19',
          'B52',
          'C43',
          'D18',
          'C54',
          'D29',
          'C33',
          'A16',
          'B15',
          'D53',
          'C42',
          'C12',
          'D43',
          'A49',
          'C17',
          'A33',
          'D60',
          'C57',
          'B37',
          'A32',
          'D40',
          'A34',
          'D14',
          'A23',
          'C44',
          'B48',
          'D34',
          'C32',
          'B44',
          'B5',
          'D7',
          'B9',
          'A24',
          'B47',
          'A38',
          'D32',
          'B54',
          'A44',
          'B8',
          'C16',
          'A6',
          'D26',
          'B26',
          'B41',
          'A48',
          'C21',
          'B10',
          'C53',
          'D45',
          'B49',
          'A5',
          'B35',
          'C7',
          'B45',
          'A10',
          'D5',
          'C55',
          'D44',
          'D21',
          'B3',
          'D28',
          'C52',
          'A51',
          'C11',
          'B46',
          'C59',
          'D16',
          'C3',
          'D17',
          'A58',
          'C2',
          'C45',
          'C16',
          'B33',
          'B8',
          'A14',
          'B15',
          'C26',
          'B50',
          'D44',
          'C31',
          'B40',
          'A55',
          'A22',
          'C25',
          'B55',
          'C42',
          'A53',
          'C9',
          'A9',
          'D20',
          'C3',
          'A38',
          'C46',
          'D58',
          'A45',
          'D11',
          'C6',
          'B48',
          'D47',
          'A58',
          'A18',
          'D8',
          'A30',
          'B39',
          'A56',
          'D54',
          'B14',
          'D37',
          'B40',
          'C8',
          'B42',
          'C34',
          'A15',
          'B25',
          'A12',
          'B27',
          'C38',
          'A51',
          'C17',
          'A37',
          'D22',
          'C60',
          'B43',
          'D48',
          'C23',
          'A41',
          'A1',
          'D43',
          'B56',
          'D28',
          'A6',
          'A46',
          'B24',
          'D49',
          'A50',
          'D23',
          'C57',
          'D41',
          'C45',
          'D16',
          'A23',
          'B11',
          'D52',
          'B36',
          'C14',
          'A7',
          'C11',
          'A28',
          'D18',
          'A43',
          'B22',
          'A58',
          'D2',
          'C5',
          'B2',
          'C40',
          'B31',
          'A46',
          'C48',
          'B60',
          'A42',
          'C20',
          'B35',
          'A4',
          'D26',
          'D59',
          'B49',
          'D20',
          'B30',
          'C44',
          'A26',
          'D34',
          'B51',
          'C51',
          'D39',
          'C49',
          'A41',
          'D9',
          'C27',
          'D29',
          'B52',
          'A57',
          'C16',
          'B44',
          'C60',
          'D10',
          'C33',
          'A50',
          'B56',
          'A20',
          'C26',
          'D47',
          'A19',
          'B29',
          'C9',
          'B33',
          'A43',
          'D52',
          'C8',
          'A10',
          'B53',
          'C53',
          'B37',
          'D18',
          'A57',
          'C13',
          'B5',
          'B38',
          'B12',
          'C36',
          'A35',
          'D37',
          'A24',
          'D13',
          'C52',
          'B19',
          'D38',
          'C30',
          'A55',
          'A20',
          'B24',
          'C5',
          'C42',
          'B33',
          'D53',
          'B16',
          'A5',
          'D35',
          'B43',
          'C3',
          'D21',
          'C28',
          'D6',
          'B10',
          'C39',
          'A34',
          'C23',
          'B9',
          'D24',
          'A43',
          'B56',
          'A40',
          'C25',
          'D49',
          'B26',
          'C43',
          'A49',
          'B23',
          'A29',
          'C41',
          'B60',
          'D33',
          'C50',
          'D55',
          'A48',
          'D45',
          'A57',
          'C9',
          'B47',
          'D52',
          'A22',
          'B13',
          'C36',
          'B24',
          'C1',
          'B25',
          'D31',
          'D1',
          'C17',
          'B35',
          'C5',
          'D5',
          'B26',
          'D42',
          'B36',
          'D39',
          'C58',
          'B18',
          'C26',
          'A29',
          'D12',
          'A47',
          'C46',
          'B1',
          'C59',
          'D22',
          'D50',
          'C19',
          'A45',
          'C15',
          'B10',
          'C12',
          'B44',
          'D14',
          'C51',
          'A36',
          'C22',
          'A44',
          'D13',
          'A51',
          'C10',
          'B27',
          'D60',
          'D23',
          'B36',
          'B51',
          'A18',
          'D41',
          'B28',
          'A12',
          'B29',
          'A1',
          'A57',
          'C4',
          'D15',
          'A19',
          'D30',
          'D47',
          'C60',
          'A43',
          'C55',
          'B39',
          'A38',
          'D35',
          'A16',
          'B24',
          'C32',
          'B55',
          'C28',
          'D39',
          'B53',
          'C45',
          'D19',
          'C40',
          'D9',
          'B30',
          'C43',
          'A50',
          'B46',
          'C34',
          'D24',
          'B25',
          'D44',
          'A14',
          'B20',
          'C2',
          'D46',
          'A22',
          'D29',
          'C21',
          'B12',
          'A23',
          'D8',
          'C58',
          'A47',
          'B21',
          'D33',
          'A45',
          'C6',
          'A59',
          'D28',
          'B5',
          'C19',
          'A48',
          'C44',
          'D52',
          'B57',
          'A10',
          'D40',
          'C16',
          'C42',
          'B8',
          'C11',
          'A33',
          'C27',
          'B2',
          'D7',
          'A31',
          'D24',
          'B27',
          'D59',
          'C15',
          'D5',
          'B30',
          'B55',
          'B1',
          'C44',
          'D4',
          'A30',
          'D35',
          'C39',
          'B4',
          'B54',
          'C2',
          'A60',
          'B59',
          'A10',
          'B46',
          'C23',
          'A4',
          'D36',
          'A36',
          'B57',
          'B10',
          'D53',
          'B17',
          'A2',
          'D14',
          'B12',
          'A6',
          'D16',
          'C33',
          'D30',
          'C17',
          'A52',
          'B44',
          'C39',
          'B47',
          'D51',
          'B42',
          'A5',
          'C18',
          'A50',
          'D36',
          'A25',
          'B6',
          'C37',
          'A55',
          'C14',
          'D41',
          'A53',
          'B20',
          'D45',
          'C36',
          'B14',
          'B52',
          'C33',
          'A17',
          'D8',
          'C47',
          'A44',
          'C45',
          'B17',
          'D15',
          'B29',
          'D57',
          'B15',
          'C22',
          'A39',
          'C50',
          'A43',
          'D19',
          'B55',
          'B11',
          'C53',
          'D55',
          'A60',
          'D26',
          'B22',
          'C2',
          'B23',
          'A24',
          'B49',
          'D36',
          'A19',
          'C44',
          'C4',
          'B2',
          'C59',
          'D25',
          'B8',
          'C10',
          'A30',
          'B40',
          'C21',
          'D13',
          'C8',
          'B6',
          'C13',
          'A10',
          'D38',
          'C41',
          'A50',
          'C40',
          'B32',
          'D54',
          'B41',
          'D22',
          'A39',
          'B22',
          'C36',
          'B27',
          'D32',
          'A13',
          'B24',
          'D45',
          'A5',
          'B56',
          'A12',
          'C7',
          'B47',
          'C48',
          'D57',
          'A45',
          'B52',
          'D21',
          'A51',
          'C33',
          'D56',
          'B29',
          'D41',
          'B46',
          'B30',
          'A1',
          'A41',
          'C22',
          'B37',
          'D52',
          'A29',
          'B27',
          'A22',
          'C24',
          'D8',
          'C19',
          'D29',
          'B53',
          'C38',
          'B58',
        ],
      },
    ],
  },
  {
    title: 'Lotus',
    id: 'lotus',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Lotus%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C16',
          'C38',
          'C23',
          'C47',
          'C13',
          'C39',
          'C6',
          'D41',
          'C26',
          'C45',
          'C15',
          'D37',
          'C8',
          'C43',
          'C28',
          'D51',
          'D20',
          'D35',
          'C18',
          'C36',
          'C2',
          'D42',
          'D58',
          'C23',
          'D34',
          'C20',
          'C49',
          'A28',
          'B19',
          'B58',
          'B41',
          'B26',
          'D9',
          'B36',
          'A49',
          'A29',
          'B13',
          'A53',
          'A12',
          'C57',
          'A26',
          'C51',
          'A27',
          'A50',
          'B18',
          'B60',
          'B38',
          'D8',
          'B50',
          'B20',
          'A28',
          'C47',
          'C10',
          'D35',
          'C16',
          'C44',
          'A13',
          'A51',
          'B14',
          'D1',
          'B17',
          'A29',
          'B4',
          'B51',
          'B19',
          'A49',
          'A15',
          'C55',
          'A20',
          'C52',
          'A23',
          'B13',
          'D2',
          'B16',
          'A38',
          'B31',
          'D12',
          'B35',
          'B20',
          'B45',
          'A59',
          'A13',
          'B1',
          'A30',
          'A45',
          'B29',
          'D13',
          'B30',
          'A41',
          'B15',
          'A22',
          'C54',
          'C13',
          'D39',
          'C7',
          'D51',
          'D22',
          'D49',
          'D25',
          'D47',
          'C29',
          'D50',
          'D16',
          'D37',
          'C14',
          'C46',
          'A28',
          'C44',
          'C21',
          'D29',
          'C24',
          'D33',
          'D17',
          'D52',
          'C26',
          'D57',
          'C13',
          'C49',
          'A23',
          'B14',
          'A43',
          'B29',
          'B60',
          'B39',
          'D7',
          'B51',
          'B7',
          'A52',
          'B28',
          'A48',
          'B37',
          'D11',
          'B32',
          'A37',
          'A3',
          'A19',
          'C58',
          'A21',
          'A50',
          'A15',
          'A58',
          'A14',
          'B3',
          'B52',
          'B18',
          'A29',
          'C44',
          'C27',
          'D40',
          'D14',
          'D39',
          'C4',
          'D41',
          'C23',
          'C7',
          'C48',
          'A27',
          'B20',
          'D8',
          'B45',
          'B23',
          'D10',
          'B33',
          'A40',
          'B30',
          'A39',
          'A16',
          'A47',
          'A31',
          'B12',
          'A57',
          'B27',
          'A49',
          'B24',
          'B43',
          'B58',
          'B10',
          'A44',
          'B28',
          'D14',
          'D29',
          'C19',
          'D34',
          'C17',
          'C43',
          'A29',
          'C42',
          'C22',
          'C48',
          'A24',
          'C52',
          'C12',
          'C50',
          'A27',
          'C45',
          'A28',
          'B21',
          'B49',
          'D12',
          'B36',
          'D2',
          'B12',
          'A30',
          'B16',
          'D5',
          'B40',
          'A53',
          'A11',
          'C53',
          'A16',
          'A57',
          'A31',
          'B9',
          'A50',
          'A14',
          'C59',
          'C21',
          'D33',
          'D18',
          'D52',
          'C14',
          'D38',
          'C10',
          'D40',
          'C46',
          'C25',
          'D32',
          'C22',
          'D51',
          'C18',
          'D36',
          'C16',
          'C49',
          'A12',
          'B1',
          'B26',
          'D12',
          'B30',
          'A36',
          'B32',
          'D8',
          'B37',
          'A47',
          'B28',
          'A41',
          'B29',
          'D14',
          'D38',
          'C8',
          'C47',
          'A24',
          'B12',
          'B54',
          'B6',
          'A17',
          'C36',
          'A20',
          'C50',
          'C15',
          'D29',
          'C23',
          'D30',
          'C11',
          'C46',
          'A25',
          'B18',
          'B59',
          'B40',
          'B55',
          'B5',
          'B25',
          'D13',
          'B34',
          'D9',
          'B19',
          'A29',
          'C41',
          'D20',
          'D50',
          'A9',
          'D49',
          'C30',
          'C45',
          'D42',
          'C1',
          'D44',
          'D60',
          'D23',
          'D38',
          'D14',
          'D35',
          'D15',
          'D53',
          'C13',
          'C51',
          'A7',
          'A43',
          'B11',
          'A32',
          'A60',
          'B44',
          'D11',
          'B55',
          'B19',
          'A24',
          'C38',
          'C17',
          'C46',
          'D41',
          'C8',
          'D30',
          'C19',
          'C34',
          'A13',
          'B3',
          'A12',
          'C47',
          'C16',
          'D36',
          'C15',
          'C56',
          'C12',
          'D37',
          'C41',
          'D21',
          'C43',
          'A31',
          'B15',
          'D6',
          'B38',
          'A46',
          'B8',
          'A33',
          'A55',
          'B27',
          'A53',
          'A9',
          'C50',
          'A21',
          'B16',
          'D4',
          'B46',
          'A58',
          'A14',
          'A60',
          'B26',
          'A27',
          'B21',
          'D13',
          'D44',
          'C45',
          'C18',
          'D30',
          'D15',
          'B24',
          'A28',
          'C44',
          'C19',
          'D35',
          'C17',
          'D52',
          'C8',
          'D43',
          'C47',
          'A26',
          'C46',
          'C24',
          'C41',
          'D18',
          'C40',
          'D21',
          'D57',
          'B35',
          'A39',
          'B33',
          'D3',
          'B11',
          'A25',
          'C52',
          'C11',
          'D37',
          'A4',
          'D39',
          'A5',
          'A53',
          'A35',
          'B32',
          'D12',
          'B42',
          'B1',
          'B49',
          'B8',
          'B23',
          'B48',
          'B9',
          'A26',
          'C38',
          'D19',
          'C42',
          'A31',
          'C41',
          'C26',
          'D31',
          'C23',
          'D45',
          'C44',
          'A30',
          'B17',
          'B60',
          'B21',
          'A25',
          'C49',
          'A21',
          'A56',
          'A33',
          'B10',
          'D4',
          'B27',
          'D15',
          'D30',
          'C25',
          'D33',
          'C20',
          'D28',
          'C24',
          'D50',
          'A8',
          'A58',
          'B50',
          'B33',
          'D18',
          'C39',
          'D14',
          'D48',
          'A7',
          'C30',
          'A21',
          'B13',
          'A17',
          'C53',
          'A18',
          'A56',
          'B47',
          'D3',
          'B17',
          'B53',
          'B7',
          'A35',
          'C46',
          'D43',
          'D13',
          'B31',
          'A40',
          'B34',
          'D56',
          'C33',
          'D8',
          'B35',
          'D58',
          'B36',
          'D1',
          'B16',
          'A51',
          'A10',
          'D52',
          'D19',
          'C37',
          'C1',
          'C40',
          'C5',
          'C49',
          'A39',
          'B6',
          'A12',
          'C56',
          'A14',
          'C39',
          'D21',
          'D56',
          'C12',
          'D40',
          'C47',
          'A21',
          'C27',
          'D44',
          'C6',
          'B57',
          'B42',
          'A45',
          'B27',
          'A27',
          'B28',
          'D15',
          'C41',
          'D16',
          'B23',
          'A31',
          'B16',
          'A20',
          'C53',
          'A22',
          'A46',
          'B40',
          'D13',
          'D42',
          'C15',
          'C48',
          'C9',
          'C39',
          'D22',
          'D37',
          'B43',
          'A44',
          'B45',
          'D11',
          'C37',
          'A26',
          'C36',
          'D34',
          'C22',
          'D35',
          'B44',
          'D33',
          'D18',
          'B35',
          'A51',
          'B23',
          'A29',
          'B30',
          'D9',
          'C35',
          'D10',
          'B18',
          'A30',
          'B2',
          'B17',
          'D11',
          'B49',
          'A55',
          'A5',
          'D40',
          'A6',
          'D49',
          'C15',
          'D36',
          'B43',
          'D12',
          'B22',
          'A24',
          'C33',
          'B60',
          'C9',
          'C53',
          'A49',
          'A34',
          'B7',
          'A12',
          'B8',
          'A32',
          'B14',
          'D5',
          'B15',
          'A21',
          'C39',
          'A23',
          'C50',
          'C14',
          'D43',
          'C44',
          'D47',
          'C4',
          'D24',
          'C43',
          'D20',
          'C42',
          'D48',
          'D22',
          'C38',
          'D35',
          'C17',
          'C37',
          'C22',
          'A17',
          'A56',
          'A5',
          'D41',
          'C47',
          'A34',
          'B33',
          'D54',
          'A10',
          'B3',
          'B41',
          'A46',
          'B25',
          'A28',
          'B30',
          'D14',
          'D55',
          'B35',
          'D3',
          'C19',
          'D34',
          'C46',
          'A36',
          'A59',
          'B26',
          'D16',
          'C40',
          'D20',
          'C37',
          'D49',
          'A8',
          'D51',
          'C27',
          'D30',
          'C24',
          'D33',
          'C23',
          'C58',
          'A43',
          'C49',
          'A30',
          'B29',
          'A42',
          'B25',
          'D10',
          'B54',
          'B4',
          'A13',
          'B5',
          'A48',
          'A16',
          'C55',
          'C12',
          'D43',
          'A7',
          'D52',
          'D15',
          'D35',
          'B46',
          'D33',
          'B48',
          'D32',
          'C33',
          'D9',
          'B31',
          'D4',
          'B9',
          'A12',
          'B10',
          'A26',
          'B29',
          'A37',
          'C46',
          'A39',
          'B36',
          'D57',
          'C32',
          'B59',
          'C31',
          'D7',
          'B51',
          'A60',
          'A14',
          'C16',
          'D41',
          'A4',
          'C48',
          'A33',
          'C41',
          'D17',
          'B22',
          'B2',
          'A15',
          'A57',
          'B44',
          'C15',
          'C39',
          'D48',
          'C4',
          'B56',
          'C7',
          'B58',
          'C6',
          'D45',
          'C43',
          'A13',
          'C40',
          'D13',
          'D41',
          'C45',
          'A39',
          'A54',
          'A35',
          'A59',
          'B43',
          'D39',
          'A3',
          'D47',
          'C19',
          'A18',
          'C51',
          'C4',
          'C35',
          'D2',
          'C18',
          'D31',
          'D16',
          'C17',
          'A13',
          'B6',
          'B57',
          'B11',
          'A12',
          'C46',
          'D44',
          'C40',
          'D56',
          'B36',
          'D60',
          'B37',
          'D1',
          'B9',
          'A30',
          'C47',
          'D39',
          'B45',
          'A43',
          'B28',
          'D10',
          'B49',
          'D31',
          'C31',
          'D8',
          'C30',
          'D32',
          'C26',
          'A21',
          'B21',
          'A47',
          'B24',
          'D14',
          'D54',
          'C10',
          'B41',
          'A42',
          'A17',
          'C20',
          'C35',
          'A26',
          'B30',
          'A25',
          'C48',
          'A10',
          'D53',
          'B33',
          'D7',
          'B13',
          'A16',
          'C37',
          'D22',
          'C36',
          'D3',
          'C18',
          'D33',
          'A1',
          'D38',
          'A5',
          'A58',
          'A31',
          'A60',
          'B23',
          'A50',
          'C53',
          'D24',
          'B37',
          'D59',
          'B38',
          'B59',
          'C34',
          'B60',
          'C32',
          'D49',
          'D16',
          'D31',
          'B50',
          'D29',
          'C58',
          'A52',
          'B34',
          'A34',
          'B36',
          'D55',
          'C15',
          'B41',
          'D34',
          'B43',
          'C13',
          'D36',
          'A3',
          'C20',
          'A12',
          'D58',
          'C14',
          'B40',
          'B4',
          'A27',
          'B29',
          'A40',
          'C45',
          'D46',
          'C40',
          'D17',
          'C17',
          'A17',
          'A45',
          'B44',
          'A51',
          'A6',
          'D41',
          'C10',
          'D43',
          'C28',
          'A15',
          'C38',
          'D18',
          'C35',
          'D50',
          'C30',
          'D9',
          'B12',
          'A22',
          'B14',
          'B53',
          'D29',
          'C20',
          'D51',
          'A10',
          'B10',
          'B60',
          'C8',
          'B40',
          'A51',
          'C53',
          'A52',
          'A15',
          'A59',
          'A5',
          'C48',
          'D42',
          'C47',
          'A34',
          'B27',
          'D12',
          'B16',
          'A19',
          'B17',
          'B56',
          'C18',
          'D34',
          'B47',
          'D39',
          'A2',
          'C21',
          'D21',
          'C38',
          'D14',
          'B20',
          'A29',
          'B9',
          'A11',
          'D56',
          'B37',
          'A38',
          'C50',
          'A24',
          'C37',
          'D4',
          'C20',
          'A5',
          'D48',
          'D16',
          'C43',
          'D47',
          'D22',
          'C21',
          'D46',
          'D25',
          'C56',
          'A48',
          'C54',
          'A9',
          'B10',
          'D3',
          'B15',
          'D13',
          'C39',
          'A31',
          'B28',
          'A46',
          'A17',
          'C16',
          'D53',
          'D14',
          'C42',
          'D60',
          'B38',
          'D58',
          'C31',
          'B57',
          'C30',
          'D30',
          'B44',
          'C14',
          'B41',
          'A49',
          'B47',
          'B19',
          'B1',
          'B43',
          'C12',
          'D26',
          'C55',
          'A46',
          'B26',
          'A58',
          'B52',
          'D8',
          'B28',
          'A29',
          'B33',
          'A53',
          'B21',
          'A19',
          'B23',
          'D17',
          'D48',
          'C38',
          'C10',
          'D3',
          'C17',
          'D39',
          'B48',
          'D32',
          'C29',
          'A14',
          'B2',
          'B55',
          'C28',
          'A20',
          'C40',
          'D19',
          'C35',
          'D22',
          'C20',
          'A15',
          'B13',
          'D6',
          'B35',
          'A50',
          'B46',
          'A44',
          'C45',
          'A46',
          'B43',
          'D38',
          'A4',
          'D42',
          'C41',
          'A12',
          'C18',
          'A16',
          'A48',
          'C46',
          'A43',
          'A23',
          'B11',
          'A55',
          'B32',
          'D51',
          'D15',
          'C42',
          'D17',
          'B25',
          'D14',
          'B33',
          'D52',
          'C8',
          'D43',
          'D25',
          'C24',
          'A1',
          'D40',
          'C17',
          'B56',
          'C3',
          'D24',
          'B50',
          'D10',
          'C33',
          'D1',
          'C19',
          'A6',
          'A54',
          'B39',
          'A47',
          'A22',
          'B20',
          'A20',
          'B22',
          'D18',
          'C32',
          'A25',
          'B31',
          'A37',
          'B38',
          'D56',
          'A9',
          'D48',
          'C34',
          'D2',
          'B8',
          'A32',
          'C49',
          'A45',
          'C57',
          'D29',
          'C12',
          'B39',
          'C1',
          'B40',
          'C13',
          'B44',
          'D41',
          'D25',
          'C44',
          'A46',
          'B47',
          'D13',
          'B26',
          'A56',
          'A33',
          'B37',
          'A31',
          'C38',
          'D57',
          'B34',
          'A29',
          'C50',
          'A42',
          'C59',
          'D28',
          'B51',
          'D7',
          'C28',
          'D50',
          'A4',
          'D36',
          'B45',
          'D38',
          'A3',
          'A59',
          'B20',
          'A48',
          'C44',
          'D44',
          'C24',
          'A21',
          'B28',
          'A38',
          'C47',
          'D45',
          'A8',
          'C19',
          'D2',
          'C9',
          'B41',
          'D13',
          'D56',
          'C43',
          'A46',
          'B48',
          'D30',
          'B49',
          'A45',
          'C46',
          'D39',
          'A6',
          'D53',
          'A19',
          'C51',
          'A11',
          'D55',
          'C44',
          'D16',
          'C39',
          'D20',
          'C22',
          'A1',
          'A37',
          'B42',
          'C16',
          'D60',
          'A33',
          'B3',
          'B25',
          'A18',
          'C17',
          'D48',
          'D18',
          'D33',
          'B47',
          'D12',
          'B11',
          'A32',
          'B15',
          'A15',
          'C23',
          'D50',
          'B32',
          'A54',
          'B50',
          'C24',
          'C57',
          'D24',
          'C52',
          'D22',
          'C19',
          'A41',
          'B26',
          'A17',
          'A56',
          'D33',
          'A59',
          'A2',
          'D34',
          'B45',
          'C15',
          'D51',
          'D15',
          'B23',
          'A18',
          'D52',
          'B34',
          'D13',
          'B35',
          'D59',
          'A12',
          'B13',
          'B56',
          'C28',
          'D39',
          'C60',
          'A38',
          'C46',
          'D35',
          'C35',
          'D40',
          'B44',
          'C12',
          'B59',
          'C6',
          'B60',
          'C32',
          'A13',
          'B7',
          'A52',
          'B43',
          'A36',
          'A18',
          'C16',
          'D17',
          'C39',
          'D47',
          'D22',
          'D45',
          'C46',
          'A49',
          'B35',
          'A29',
          'B18',
          'B3',
          'A5',
          'D37',
          'B44',
          'A48',
          'B36',
          'D54',
          'C10',
          'B42',
          'A31',
          'D57',
          'A32',
          'B27',
          'D11',
          'C33',
          'B58',
          'C30',
          'C2',
          'A13',
          'A60',
          'D34',
          'A1',
          'D46',
          'C44',
          'A40',
          'D29',
          'B55',
          'B3',
          'A2',
          'B7',
          'B24',
          'A19',
          'D55',
          'C34',
          'D20',
          'B23',
          'A48',
          'C43',
          'D59',
          'B39',
          'B5',
          'A5',
          'B6',
          'B38',
          'A30',
          'C40',
          'D37',
          'C18',
          'B43',
          'C17',
          'C32',
          'D7',
          'C27',
          'B53',
          'C26',
          'C60',
          'A41',
          'D31',
          'A56',
          'B31',
          'A24',
          'D23',
          'C54',
          'D26',
          'C9',
          'D3',
          'B7',
          'A1',
          'C14',
          'D17',
          'C13',
          'B40',
          'A38',
          'B33',
          'A35',
          'C47',
          'A27',
          'D27',
          'B52',
          'D12',
          'B56',
          'B1',
          'A8',
          'D48',
          'C33',
          'B59',
          'C11',
          'C57',
          'A47',
          'B46',
          'C49',
          'D41',
          'A3',
          'B6',
          'A38',
          'B41',
          'B2',
          'D50',
          'B49',
          'D38',
          'B42',
          'A53',
          'C56',
          'C25',
          'D28',
          'A40',
          'C20',
          'B47',
          'C51',
          'B48',
          'C50',
          'B50',
          'D30',
          'B51',
          'A46',
          'A7',
          'D51',
          'C12',
          'B40',
          'A47',
          'B24',
          'D19',
          'D48',
          'D20',
          'C32',
          'A26',
          'B20',
          'D52',
          'C44',
          'D18',
          'C42',
          'A48',
          'B38',
          'B11',
          'D10',
          'A10',
          'C21',
          'D32',
          'A57',
          'A16',
          'B26',
          'A15',
          'C19',
          'D4',
          'C36',
          'D1',
          'C6',
          'A35',
          'B43',
          'C16',
          'B55',
          'D26',
          'D41',
          'A1',
          'A19',
          'A44',
          'C58',
          'C22',
          'D16',
          'B27',
          'A50',
          'B16',
          'A30',
          'D29',
          'C56',
          'A59',
          'B57',
          'C8',
          'B41',
          'A32',
          'D58',
          'D14',
          'D53',
          'A3',
          'C21',
          'B48',
          'C49',
          'B50',
          'D50',
          'D15',
          'B29',
          'C26',
          'D5',
          'A9',
          'D6',
          'C29',
          'B16',
          'B37',
          'B7',
          'C60',
          'C23',
          'B14',
          'D14',
          'C37',
          'D21',
          'A25',
          'D25',
          'C43',
          'A40',
          'B32',
          'D49',
          'C36',
          'C3',
          'C52',
          'A22',
          'A55',
          'A19',
          'D54',
          'B21',
          'A57',
          'A9',
          'D7',
          'C26',
          'A24',
          'B10',
          'C11',
          'D38',
          'A2',
          'D40',
          'C48',
          'A29',
          'D24',
          'C37',
          'D59',
          'B40',
          'A43',
          'B46',
          'D45',
          'B3',
          'C57',
          'D28',
          'C22',
          'A42',
          'B47',
          'D33',
          'C20',
          'D16',
          'C18',
          'D38',
          'B50',
          'C53',
          'A53',
          'B22',
          'A22',
          'C51',
          'A37',
          'A18',
          'B24',
          'A43',
          'C47',
          'D34',
          'B1',
          'A7',
          'B4',
          'B58',
          'C35',
          'C6',
          'D45',
          'C41',
          'D19',
          'B32',
          'A24',
          'D24',
          'A28',
          'D50',
          'C17',
          'A16',
          'C27',
          'B13',
          'D15',
          'B19',
          'A23',
          'C49',
          'D43',
          'C45',
          'A45',
          'B45',
          'D6',
          'B9',
          'C9',
          'D4',
          'C18',
          'A9',
          'C54',
          'A14',
          'D35',
          'A3',
          'D37',
          'D22',
          'D44',
          'B46',
          'D40',
          'C16',
          'B56',
          'A47',
          'C45',
          'D17',
          'C5',
          'B8',
          'A5',
          'D54',
          'B22',
          'A19',
          'B28',
          'B43',
          'A31',
          'D56',
          'C12',
          'B44',
          'A46',
          'C41',
          'D55',
          'B37',
          'D12',
          'C39',
          'D5',
          'C38',
          'D46',
          'A8',
          'D3',
          'B47',
          'C50',
          'D20',
          'D48',
          'B19',
          'A21',
          'C17',
          'A23',
          'C34',
          'D31',
          'A42',
          'B27',
          'A15',
          'D36',
          'B46',
          'D34',
          'A58',
          'D32',
          'B49',
          'C54',
          'D8',
          'A9',
          'A60',
          'D42',
          'C46',
          'D54',
          'B37',
          'C11',
          'B44',
          'A35',
          'C4',
          'B24',
          'A17',
          'B25',
          'D19',
          'C39',
          'D56',
          'B34',
          'A51',
          'B45',
          'D37',
          'A2',
          'C22',
          'A56',
          'B23',
          'C3',
          'C29',
          'B30',
          'C32',
          'C1',
          'A13',
          'C30',
          'B29',
          'D10',
          'B54',
          'C31',
          'D49',
          'B2',
          'A6',
          'A36',
          'C7',
          'B57',
          'D28',
          'A26',
          'D22',
          'B51',
          'C24',
          'B9',
          'A25',
          'A41',
          'D30',
          'A55',
          'B38',
          'A28',
          'D27',
          'C60',
          'D38',
          'C39',
          'D45',
          'A24',
          'B12',
          'B36',
          'A32',
          'B33',
          'D49',
          'A7',
          'D42',
          'B44',
          'C19',
          'A40',
          'C41',
          'A48',
          'B55',
          'B11',
          'C14',
          'B39',
          'C11',
          'A38',
          'A55',
          'D29',
          'B50',
          'C49',
          'A26',
          'D25',
          'A29',
          'B37',
          'A56',
          'C21',
          'A4',
          'B5',
          'C58',
          'D23',
          'B24',
          'D11',
          'C30',
          'D31',
          'A40',
          'D27',
          'A25',
          'D46',
          'C35',
          'A21',
          'D8',
          'B12',
          'C26',
          'B15',
          'C24',
          'A24',
          'D22',
          'C17',
          'D35',
          'A13',
          'C40',
          'D14',
          'D29',
          'A42',
          'C48',
          'A33',
          'B5',
          'D47',
          'C3',
          'B24',
          'D20',
          'B51',
          'A45',
          'C44',
          'D23',
          'C5',
          'C28',
          'B30',
          'C31',
          'B55',
          'C15',
          'D53',
          'A17',
          'B29',
          'D7',
          'C53',
          'A18',
          'D56',
          'B35',
          'A35',
          'B1',
          'B19',
          'D47',
          'C60',
          'D41',
          'C16',
          'D4',
          'A10',
          'D6',
          'B30',
          'A57',
          'B59',
          'C10',
          'A15',
          'C18',
          'A17',
          'C15',
          'D16',
          'B12',
          'A20',
          'D59',
          'B5',
          'A27',
          'B8',
          'A4',
          'D35',
          'C9',
          'B42',
          'A47',
          'C43',
          'A49',
          'B54',
          'C29',
          'D9',
          'A9',
          'C42',
          'D41',
          'B45',
          'A40',
          'D30',
          'D47',
          'C37',
          'C19',
          'B47',
          'C49',
          'D20',
          'A27',
          'B39',
          'A52',
          'B22',
          'D21',
          'C22',
          'D31',
          'A59',
          'A15',
          'D37',
          'B28',
          'C27',
          'C55',
          'B51',
          'C54',
          'D27',
          'A44',
          'C47',
          'B21',
          'C46',
          'D36',
          'C42',
          'A49',
          'B59',
          'B30',
          'C26',
          'D8',
          'C53',
          'A54',
          'B18',
          'A24',
          'B33',
          'A52',
          'D13',
          'B20',
          'D51',
          'A28',
          'B41',
          'C2',
          'A34',
          'D59',
          'B53',
          'D27',
          'C45',
          'D39',
          'C38',
          'D58',
          'A35',
          'B26',
          'D17',
          'D50',
          'B3',
          'D34',
          'B16',
          'A12',
          'D6',
          'A11',
          'C19',
          'A13',
          'B2',
          'D44',
          'C8',
          'B8',
          'C2',
          'A30',
          'D23',
          'C13',
          'B38',
          'D11',
          'B28',
          'A18',
          'C15',
          'D42',
          'C40',
          'A34',
          'B7',
          'C4',
          'B10',
          'D13',
          'B27',
          'C29',
          'B55',
          'C27',
          'A44',
          'B23',
          'A21',
          'D9',
          'C26',
          'B13',
          'C24',
          'A14',
          'C5',
          'D45',
          'B30',
          'A17',
          'D52',
          'A6',
          'B4',
          'C57',
          'D25',
          'A24',
          'C30',
          'B15',
          'C23',
          'D19',
          'A23',
          'B21',
          'C45',
          'D34',
          'C18',
          'B57',
          'D26',
          'C24',
          'A43',
          'B49',
          'C50',
          'B45',
          'A34',
          'C5',
          'B60',
          'A56',
          'A17',
          'C55',
          'D7',
          'B51',
          'C49',
          'D21',
          'C58',
          'B6',
          'D4',
          'B32',
          'A41',
          'D29',
          'A29',
          'D52',
          'B10',
          'C25',
          'D49',
          'A27',
          'B41',
          'C1',
          'B8',
          'A3',
          'D33',
          'B47',
          'D48',
          'B20',
          'D47',
          'C35',
          'A12',
          'B26',
          'D19',
          'B34',
          'A17',
          'A46',
          'C37',
          'B60',
          'D25',
          'C39',
          'C2',
          'B23',
          'D56',
          'B24',
          'A1',
          'D2',
          'B28',
          'D36',
          'C9',
          'A15',
          'C59',
          'B2',
          'A35',
          'A54',
          'A25',
          'B11',
          'C13',
          'B41',
          'D39',
          'A58',
          'B58',
          'B38',
          'D54',
          'A2',
          'B8',
          'D50',
          'A10',
          'D7',
          'C52',
          'B46',
          'C21',
          'A7',
          'C20',
          'C35',
          'D3',
          'C27',
          'B29',
          'D36',
          'A14',
          'B4',
          'C59',
          'C29',
          'B57',
          'A47',
          'C55',
          'D25',
          'C13',
          'B43',
          'A49',
          'C41',
          'A10',
          'D57',
          'B53',
          'A50',
          'B40',
          'A33',
          'C6',
          'C27',
          'A23',
          'D20',
          'C38',
          'B32',
          'D47',
          'D31',
          'B47',
          'D11',
          'A21',
          'B24',
          'D22',
          'C14',
          'D54',
          'C45',
          'D15',
          'C44',
          'D34',
          'B46',
          'C51',
          'A41',
          'C19',
          'D32',
          'A60',
          'B56',
          'B7',
          'A33',
          'B26',
          'A11',
          'D10',
          'A9',
          'D51',
          'C17',
          'B42',
          'D35',
          'C47',
          'A8',
          'B3',
          'A30',
          'B17',
          'A26',
          'B41',
          'A24',
          'D44',
          'A45',
          'D28',
          'B51',
          'D54',
          'D13',
          'A51',
          'B26',
          'C28',
          'B27',
          'D9',
          'C34',
          'B18',
          'A12',
          'C3',
          'B7',
          'C10',
          'B44',
          'D39',
          'A43',
          'D29',
          'A28',
          'D23',
          'A31',
          'B15',
          'D12',
          'A52',
          'C7',
          'B26',
          'D14',
          'D53',
          'A18',
          'B12',
          'C28',
          'A22',
          'C18',
          'D5',
          'A12',
          'C33',
          'B31',
          'C32',
          'A14',
          'C21',
          'A39',
          'C46',
          'A44',
          'B44',
          'D13',
          'B14',
          'C24',
          'B16',
          'D33',
          'A57',
          'A16',
          'D37',
          'C58',
          'D24',
          'C2',
          'A35',
          'C1',
          'B36',
          'A17',
          'D57',
          'C13',
          'D34',
          'A12',
          'C45',
          'D28',
          'A25',
          'D26',
          'A43',
          'C28',
          'B52',
          'D30',
          'A42',
          'C19',
          'A16',
          'B25',
          'B2',
          'B42',
          'C1',
          'A37',
          'D60',
          'C41',
          'D43',
          'A47',
          'B58',
          'B31',
          'C26',
          'A58',
          'A4',
          'D4',
          'D25',
          'C52',
          'D8',
          'C18',
          'D39',
          'C43',
          'A30',
          'B40',
          'C7',
          'B59',
          'B11',
          'C11',
          'A53',
          'B14',
          'C28',
          'D6',
          'C54',
          'C30',
          'A14',
          'B27',
          'A48',
          'B48',
          'C48',
          'B20',
          'C47',
          'A36',
          'B34',
          'D51',
          'D35',
          'B45',
          'D46',
          'B5',
          'C57',
          'B50',
          'C56',
          'D30',
          'C29',
          'D49',
          'B8',
          'B36',
          'D60',
          'A9',
          'D54',
          'C42',
          'D16',
          'D53',
          'C47',
          'B34',
          'A31',
          'D26',
          'B58',
          'C53',
          'D4',
          'A3',
          'A39',
          'C60',
          'B1',
          'D48',
          'A26',
          'D19',
          'B53',
          'C30',
          'A25',
          'D10',
          'C26',
          'B29',
          'C35',
          'A46',
          'D29',
          'C25',
          'D32',
          'B49',
          'C48',
          'B50',
          'A51',
          'D14',
          'C6',
          'B6',
          'C57',
          'D21',
          'A29',
          'B40',
          'D58',
          'B5',
          'B20',
          'D46',
          'B47',
          'D44',
          'A23',
          'C16',
          'D15',
          'C43',
          'D40',
          'A4',
          'D52',
          'A19',
          'A55',
          'D28',
          'A27',
          'D24',
          'B23',
          'A2',
          'C11',
          'B41',
          'D36',
          'A1',
          'D55',
          'A48',
          'B60',
          'D22',
          'B25',
          'A38',
          'A18',
          'B40',
          'B3',
          'C46',
          'B20',
          'A24',
          'C23',
          'B8',
          'D7',
          'A8',
          'D4',
          'B14',
          'B35',
          'C9',
          'D41',
          'A59',
          'A41',
          'B44',
          'A31',
          'C3',
          'C38',
          'D23',
          'C35',
          'C11',
          'D34',
          'B4',
          'B39',
          'A51',
          'C4',
          'B22',
          'C50',
          'B46',
          'C20',
          'B48',
          'D33',
          'C19',
          'A21',
          'C57',
          'C30',
          'B28',
          'C34',
          'B29',
          'C25',
          'C60',
          'B3',
          'C45',
          'B21',
          'A20',
          'C31',
          'B17',
          'A25',
          'D24',
          'A41',
          'D32',
          'B50',
          'D10',
          'C36',
          'D39',
          'A18',
          'D55',
          'B24',
          'D18',
          'C15',
          'B10',
          'C3',
          'D47',
          'A26',
          'B5',
          'D5',
          'B30',
          'D44',
          'B3',
          'C44',
          'D38',
          'C47',
          'A10',
          'A56',
          'A4',
          'B1',
          'A40',
          'C16',
          'A16',
          'A58',
          'C25',
          'B52',
          'C5',
          'B6',
          'A36',
          'D12',
          'A51',
          'C9',
          'A37',
          'C8',
          'B11',
          'D52',
          'B36',
          'D59',
          'B54',
          'D27',
          'A29',
          'D22',
          'C59',
          'C27',
          'B30',
          'B47',
          'D43',
          'A23',
          'C48',
          'B19',
          'D50',
          'C21',
          'A57',
          'A6',
          'B53',
          'C25',
          'A13',
          'D4',
          'A2',
          'D32',
          'B16',
          'B54',
          'D10',
          'A8',
          'B10',
          'C14',
          'B39',
          'A34',
          'B2',
          'C58',
          'D27',
          'B26',
          'C15',
          'A23',
          'B41',
          'C7',
          'D39',
          'C24',
          'A40',
          'C40',
          'D6',
          'D22',
          'B30',
          'A16',
          'C13',
          'B12',
          'D14',
          'C36',
          'A10',
          'D2',
          'B34',
          'D49',
          'B46',
          'A33',
          'B10',
          'B39',
          'C31',
          'A45',
          'D41',
          'C34',
          'A46',
          'B58',
          'C18',
          'D6',
          'C39',
          'D57',
          'A49',
          'B57',
          'C3',
          'A21',
          'C29',
          'B28',
          'C28',
          'D9',
          'A26',
          'B10',
          'A2',
          'B24',
          'A19',
          'B38',
          'A35',
          'C38',
          'D25',
          'D40',
          'D56',
          'D13',
          'C43',
          'D17',
          'C59',
          'D20',
          'C15',
          'B42',
          'A50',
          'B56',
          'B41',
          'D58',
          'A20',
          'B23',
          'C5',
          'B26',
          'A54',
          'A24',
          'D28',
          'A42',
          'B31',
          'C26',
          'A44',
          'D30',
          'C21',
          'D32',
          'B1',
          'A3',
          'B2',
          'B48',
          'C55',
          'D29',
          'B50',
          'D23',
          'C53',
          'C32',
          'D48',
          'B21',
          'C45',
          'A27',
          'D12',
          'A53',
          'B32',
          'C37',
          'D1',
          'C5',
          'B9',
          'D5',
          'A11',
          'B27',
          'C33',
          'D31',
          'B41',
          'C16',
          'D35',
          'A2',
          'D46',
          'C43',
          'D28',
          'A46',
          'D53',
          'B3',
          'C56',
          'B6',
          'C22',
          'B7',
          'C9',
          'B28',
          'D13',
          'B13',
          'A11',
          'A60',
          'A9',
          'D2',
          'B15',
          'C28',
          'D11',
          'A13',
          'B25',
          'B10',
          'D51',
          'C50',
          'A7',
          'D44',
          'D22',
          'A25',
          'A41',
          'C29',
          'A15',
          'A59',
          'B49',
          'C53',
          'D27',
          'C26',
          'B32',
          'B57',
          'C55',
          'D10',
          'B30',
          'D43',
          'B11',
          'D17',
          'C36',
          'D60',
          'A19',
          'B39',
          'D37',
          'C57',
          'D45',
          'A44',
          'A25',
          'D18',
          'B25',
          'A2',
          'D1',
          'C52',
          'D4',
          'C41',
          'A50',
          'C8',
          'D52',
          'D14',
          'D29',
          'A57',
          'A38',
          'A6',
          'C31',
          'A12',
          'B19',
          'A30',
          'D21',
          'C42',
          'A32',
          'B47',
          'D41',
          'C13',
          'A39',
          'D26',
          'A24',
          'B35',
          'C8',
          'B44',
          'C51',
          'A22',
          'C17',
          'D52',
          'C49',
          'B22',
          'C1',
          'B9',
          'C16',
          'B27',
          'A30',
          'C4',
          'B52',
          'A51',
          'D15',
          'C6',
          'A48',
          'D43',
          'C33',
          'A60',
          'D1',
          'C60',
          'B8',
          'A31',
          'B11',
          'B47',
          'D36',
          'B30',
          'C27',
          'A57',
          'C24',
          'D31',
          'B16',
          'D11',
          'C26',
          'B51',
          'C53',
          'A20',
          'A51',
          'C3',
          'B44',
          'C7',
          'A14',
          'C20',
          'A39',
          'C42',
          'A45',
          'D32',
          'A49',
          'C40',
          'D47',
          'B22',
          'C48',
          'B33',
          'A37',
          'C47',
          'D18',
          'D50',
          'C19',
          'B39',
          'A17',
          'B40',
          'D37',
          'A57',
          'C23',
          'A1',
          'D19',
          'C20',
          'D2',
          'C60',
          'B24',
          'D54',
          'A16',
          'D38',
          'A43',
          'B58',
          'B16',
          'C28',
          'B29',
          'C2',
          'B7',
          'D14',
          'A52',
          'B49',
          'D30',
          'C58',
          'A44',
          'A18',
          'A55',
          'B20',
          'A21',
          'B35',
          'C51',
          'B19',
          'D49',
          'A31',
          'C50',
          'B51',
          'D28',
          'B52',
          'C24',
          'D40',
          'A28',
          'C5',
          'B38',
          'A53',
          'C6',
          'C53',
          'A59',
          'D43',
          'D22',
          'A14',
          'C11',
          'B43',
          'C41',
          'D5',
          'A8',
          'C20',
          'A10',
          'B26',
          'C32',
          'C56',
          'D24',
          'C35',
          'A31',
          'B16',
          'C23',
          'A43',
          'D31',
          'B48',
          'D54',
          'C10',
          'A34',
          'D57',
          'D12',
          'B56',
          'C15',
          'D55',
          'B34',
          'A27',
          'D23',
          'B21',
          'A3',
          'B22',
          'A30',
          'D58',
          'B42',
          'C14',
          'B26',
          'D5',
          'C9',
          'B10',
          'C1',
          'B44',
          'D43',
          'B3',
          'C43',
          'D53',
          'B12',
          'C16',
          'A22',
          'D9',
          'C53',
          'A60',
          'A5',
          'C32',
          'B40',
          'D32',
          'C39',
          'B47',
          'A44',
          'B52',
          'D22',
          'A38',
          'C25',
          'A23',
          'B23',
          'A3',
          'B37',
          'A20',
          'B55',
          'A19',
          'C18',
          'B46',
          'B31',
          'D48',
          'D20',
          'D3',
          'C59',
          'D23',
          'C51',
          'D4',
          'C15',
          'D21',
          'A38',
          'B19',
          'D36',
          'C41',
          'D24',
          'D60',
          'D44',
          'C49',
          'A32',
          'D27',
          'C44',
          'B21',
          'D19',
          'C60',
          'D33',
          'C37',
          'D38',
          'C29',
          'A42',
          'C47',
          'B2',
          'D48',
          'C26',
          'A16',
          'C59',
          'A39',
          'B15',
          'A57',
          'C22',
          'D45',
          'B45',
          'C30',
          'A40',
          'D12',
          'B23',
          'C1',
          'B11',
          'C27',
          'B35',
          'C50',
          'B19',
          'A25',
          'D29',
          'A59',
          'D2',
          'C19',
          'D15',
          'B25',
          'A19',
          'C55',
          'B50',
          'A48',
          'B57',
          'C40',
          'A50',
          'D56',
          'B53',
          'D17',
          'A12',
          'B20',
          'A4',
          'B6',
          'C12',
          'B9',
          'C10',
          'B27',
          'D3',
          'A11',
          'D33',
          'A58',
          'A16',
          'D36',
          'B21',
          'D22',
          'A32',
          'C3',
          'A48',
          'C54',
          'B23',
          'D21',
          'B13',
          'C6',
          'C34',
          'D42',
          'A23',
          'A54',
          'A20',
          'D6',
          'C38',
          'D10',
          'C31',
          'B40',
          'C48',
          'A33',
          'C11',
          'D39',
          'B48',
          'A60',
          'A1',
          'B23',
          'C53',
          'B59',
          'A45',
          'D40',
          'D25',
          'C37',
          'B19',
          'A49',
          'C10',
          'B29',
          'C9',
          'B43',
          'C40',
          'D55',
          'D13',
          'D54',
          'B53',
          'A43',
          'C22',
          'B14',
          'D7',
          'C54',
          'B45',
          'C7',
          'D43',
          'A44',
          'A24',
          'B38',
          'C4',
          'D17',
          'B36',
          'A4',
          'B13',
          'C29',
          'A8',
          'D58',
          'B50',
          'B28',
          'D1',
          'C24',
          'A41',
          'C18',
          'D15',
          'C40',
          'A51',
          'C20',
          'B40',
          'A45',
          'C56',
          'D38',
          'C25',
          'B30',
          'D42',
          'B11',
          'A21',
          'B54',
          'C30',
          'A59',
          'A14',
          'B17',
          'D30',
          'A56',
          'A17',
          'C29',
          'A26',
          'D27',
          'C52',
          'B34',
          'C48',
          'B42',
          'C42',
          'A47',
          'D52',
          'B12',
          'C10',
          'B37',
          'D60',
          'B39',
          'A16',
          'D51',
          'A18',
          'B35',
          'B17',
          'A13',
          'B8',
          'C22',
          'B15',
          'D35',
          'B30',
          'D20',
          'C58',
          'C30',
          'D17',
          'C1',
          'B29',
          'C33',
          'C51',
          'A28',
          'B28',
          'C27',
          'D6',
          'A7',
          'B52',
          'D8',
          'A14',
          'B5',
          'C11',
          'C26',
          'D37',
          'D22',
          'C14',
          'D32',
          'B46',
          'C40',
          'B2',
          'C58',
          'D15',
          'A52',
          'A26',
          'B34',
          'A22',
          'D43',
          'C53',
          'D10',
          'C21',
          'D11',
          'A53',
          'C10',
          'A36',
          'D59',
          'A51',
          'C17',
          'B25',
          'A12',
          'D9',
          'A10',
          'C32',
          'D8',
          'B7',
          'A48',
          'D31',
          'C46',
          'B20',
          'A11',
          'D54',
          'B39',
          'C13',
          'D37',
          'B45',
          'C29',
          'A59',
          'D40',
          'B42',
          'C41',
          'D13',
          'A53',
          'B48',
          'D25',
          'A12',
          'D1',
          'C40',
          'D24',
          'A32',
          'C18',
          'D16',
          'C6',
          'D22',
          'B55',
          'A51',
          'D56',
          'B25',
          'C31',
          'A13',
          'B12',
          'B37',
          'A54',
          'D26',
          'A27',
          'B9',
          'D14',
          'A11',
          'C56',
          'B23',
          'D50',
          'B1',
          'B60',
          'A1',
          'C12',
          'B42',
          'C22',
          'A24',
          'A45',
          'C46',
          'B2',
          'B59',
          'A3',
          'D34',
          'A56',
          'A17',
          'C25',
          'A39',
          'C44',
          'D12',
          'A38',
          'C47',
          'B19',
          'A36',
          'A5',
          'D26',
          'A44',
          'D42',
          'C10',
          'B6',
          'D48',
          'D23',
          'C42',
          'B44',
          'D60',
          'D44',
          'C48',
          'A34',
          'B56',
          'C56',
          'B36',
          'C27',
          'D31',
          'C20',
          'B45',
          'D35',
          'C33',
          'B17',
          'A57',
          'D3',
          'B12',
          'C8',
          'B25',
          'D6',
          'C11',
          'B23',
          'D46',
          'C46',
          'B34',
          'C15',
          'A41',
          'C59',
          'B24',
          'D8',
          'A27',
          'B16',
          'A15',
          'D23',
          'C49',
          'B17',
          'C30',
          'A16',
          'B40',
          'D54',
          'B54',
          'C17',
          'B26',
          'A9',
          'B20',
          'A8',
          'C44',
          'A47',
          'C5',
          'D43',
          'A24',
          'D12',
          'A54',
          'C13',
          'B58',
          'C8',
          'B27',
          'C32',
          'A39',
          'C31',
          'B31',
          'D20',
          'B13',
          'A33',
          'C18',
          'D34',
          'C43',
          'A59',
          'D28',
          'A7',
          'D59',
          'D24',
          'A30',
          'D45',
          'C59',
          'D39',
          'A46',
          'B9',
          'C2',
          'B21',
          'C44',
          'D19',
          'B56',
          'B34',
          'B49',
          'C23',
          'A55',
          'A18',
          'B38',
          'C29',
          'D56',
          'A17',
          'C19',
          'B45',
          'C53',
          'B60',
          'C50',
          'A22',
          'B18',
          'C52',
          'A20',
          'A54',
          'C30',
          'B31',
          'A28',
          'D21',
          'B26',
          'D29',
          'C28',
          'D30',
          'B49',
          'A40',
          'B4',
          'D33',
          'C14',
          'A23',
          'D27',
          'B54',
          'C16',
          'D16',
          'C60',
          'D36',
          'C38',
          'B46',
          'C17',
          'B8',
          'D41',
          'B30',
          'D1',
          'D22',
          'C50',
          'B52',
          'D58',
          'B9',
          'A3',
          'C33',
          'D47',
          'A29',
          'C5',
          'D49',
          'A43',
          'C59',
          'B1',
          'C53',
          'B5',
          'C23',
          'B43',
          'A33',
          'B14',
          'D12',
          'A37',
          'C35',
          'D25',
          'A30',
          'D20',
          'D4',
          'C40',
          'B20',
          'D24',
          'A26',
          'B16',
          'D30',
          'D46',
          'C41',
          'B33',
          'D57',
          'B5',
          'D53',
          'D17',
          'A24',
          'D41',
          'A29',
          'B43',
          'C35',
          'D1',
          'A48',
          'B39',
          'C39',
          'A14',
          'D34',
          'B46',
          'C15',
          'B8',
          'D40',
          'C11',
          'A22',
          'C26',
          'C6',
          'B7',
          'C58',
          'B24',
          'C27',
          'D9',
          'A36',
          'A4',
          'A58',
          'A16',
          'C23',
          'D1',
          'C4',
          'A45',
          'C30',
          'B39',
          'A46',
          'D38',
          'B44',
          'C55',
          'A56',
          'D12',
          'B48',
          'D51',
          'B21',
          'A1',
          'D30',
          'B30',
          'C3',
          'A34',
          'C39',
          'B1',
          'C37',
          'C5',
          'B13',
          'D16',
          'C38',
          'B56',
          'C50',
          'B20',
          'A4',
          'B37',
          'A49',
          'D45',
          'A23',
          'B17',
          'B51',
          'D60',
          'C52',
          'B49',
          'A19',
          'C17',
          'B28',
          'C36',
          'B1',
          'D31',
          'B15',
          'B59',
          'A42',
          'C18',
          'D47',
          'B6',
          'C21',
          'A50',
          'D32',
          'B47',
          'D52',
          'D19',
          'C16',
          'A51',
          'A36',
          'C38',
          'A40',
          'C23',
          'B50',
          'D29',
          'A24',
          'C50',
          'D42',
          'A47',
          'C2',
          'B38',
          'D55',
          'B14',
          'C18',
          'A18',
          'D54',
          'B41',
          'A22',
          'D15',
          'B32',
          'C49',
          'C6',
          'B27',
          'C31',
          'A15',
          'C54',
          'B57',
          'D13',
          'B36',
          'A57',
          'A6',
          'B52',
          'D28',
          'B59',
          'C38',
          'A45',
          'D54',
          'D1',
          'C33',
          'B18',
          'A13',
          'D20',
          'A38',
          'C45',
          'A28',
          'D10',
          'C29',
          'B46',
          'D47',
          'D31',
          'B48',
          'A32',
          'B14',
          'A16',
          'D58',
          'A36',
          'B40',
          'C39',
          'D34',
          'B6',
          'C3',
          'A27',
          'D50',
          'D21',
          'C51',
          'B49',
          'C58',
          'D33',
          'C46',
          'B20',
          'D55',
          'C32',
          'B29',
          'A59',
          'B35',
          'A5',
          'B19',
          'A10',
          'D59',
          'C15',
          'D5',
          'C30',
          'D36',
          'C14',
          'B55',
          'D27',
          'B30',
          'C2',
          'B43',
          'A47',
          'D32',
          'C24',
          'B10',
          'D11',
          'C53',
          'B17',
          'B33',
          'A23',
          'D8',
          'B13',
          'A24',
          'C47',
          'B22',
          'D36',
          'D52',
          'C48',
          'A35',
          'B4',
          'C4',
          'C29',
          'A54',
          'C12',
          'A32',
          'B49',
          'D51',
          'A47',
          'D37',
          'A19',
          'B50',
          'C1',
          'D19',
          'C51',
          'A20',
          'D4',
          'A49',
          'D41',
          'C22',
          'A6',
          'D40',
          'C37',
          'D48',
          'B34',
          'A50',
          'D13',
          'C38',
          'B47',
          'C56',
          'A12',
          'C41',
          'A60',
          'D33',
          'B35',
          'C15',
          'B9',
          'A35',
          'C9',
          'B37',
          'A40',
          'B53',
          'C32',
          'A44',
          'C43',
          'B21',
          'D53',
          'A45',
          'D45',
          'B18',
          'A11',
          'B54',
          'C28',
          'A59',
          'A10',
          'D11',
          'A41',
          'C23',
          'B28',
          'C16',
          'D27',
          'B59',
          'D26',
          'C59',
          'B4',
          'C56',
          'B43',
          'D42',
          'C13',
          'B5',
          'C22',
          'C6',
          'A36',
          'B42',
          'C8',
          'B2',
          'D31',
          'A10',
          'D55',
          'A3',
          'D22',
          'D1',
          'A55',
          'B31',
          'C25',
          'B44',
          'C41',
          'B56',
          'C34',
          'B26',
          'C11',
          'D16',
          'B24',
          'C26',
          'D12',
          'A26',
          'C46',
          'B11',
          'D60',
          'C38',
          'A50',
          'B36',
          'A21',
          'B41',
          'A48',
          'A22',
          'C9',
          'A54',
          'C45',
          'B20',
          'A2',
          'B59',
          'C30',
          'D18',
          'D1',
          'C3',
          'B37',
          'A1',
          'B25',
          'C4',
          'B42',
          'D54',
          'D4',
          'D44',
          'A46',
          'B52',
          'A19',
          'C29',
          'A45',
          'A24',
          'D20',
          'C23',
          'D26',
          'A16',
          'C54',
          'D30',
          'B49',
          'C47',
          'A44',
          'C57',
          'D17',
          'A52',
          'B51',
          'D50',
          'B24',
          'D46',
          'C56',
          'D6',
          'A15',
          'B16',
          'D57',
          'D12',
          'C21',
          'B13',
          'B35',
          'A23',
          'D21',
          'C36',
          'B19',
          'C49',
          'A32',
          'B15',
          'C8',
          'B28',
          'D34',
          'A57',
          'A37',
          'A1',
          'D3',
          'A9',
          'C4',
          'C50',
          'B41',
          'C31',
          'B44',
          'D42',
          'A48',
          'C2',
          'A38',
          'C33',
          'C48',
          'B43',
          'C34',
          'B57',
          'A44',
          'D49',
          'B1',
          'C35',
          'B28',
          'D28',
          'B53',
          'A19',
          'D40',
          'B47',
          'B3',
          'C7',
          'B60',
          'D25',
          'C49',
          'B4',
          'C24',
          'B21',
          'A4',
          'A60',
          'B15',
          'A20',
          'A35',
          'A8',
          'C10',
          'C37',
          'D36',
          'B48',
          'C41',
          'D28',
          'A29',
          'B29',
          'A25',
          'B34',
          'C57',
          'D22',
          'C1',
          'B30',
          'B59',
          'A51',
          'B33',
          'C6',
          'B8',
          'D15',
          'C21',
          'A16',
          'C12',
          'A47',
          'C47',
          'B19',
          'D30',
          'A48',
          'D50',
          'A17',
          'D38',
          'A28',
          'A43',
          'C48',
          'B44',
          'D59',
          'D43',
          'B12',
          'A55',
          'C28',
          'B57',
          'C2',
          'B10',
          'C12',
          'B24',
          'A30',
          'C51',
          'B18',
          'A56',
          'C20',
          'D32',
          'C38',
          'A60',
          'A1',
          'C34',
          'B30',
          'D40',
          'D25',
          'A39',
          'B39',
          'A12',
          'D24',
          'C50',
          'A31',
          'D19',
          'D3',
          'C42',
          'D27',
          'B56',
          'A19',
          'C16',
          'B40',
          'D60',
          'B41',
          'C49',
          'A41',
          'C14',
          'D38',
          'B38',
          'A20',
          'B48',
          'A5',
          'D6',
          'D54',
          'C41',
          'A47',
          'C37',
          'D5',
          'C40',
          'D7',
          'C25',
          'B49',
          'D36',
          'A57',
          'B53',
          'A12',
          'D2',
          'C43',
          'B58',
          'C50',
          'D49',
          'A10',
          'C27',
          'A15',
          'D51',
          'A43',
          'C44',
          'B45',
          'A48',
          'D46',
          'B6',
          'B38',
          'A49',
          'B22',
          'C46',
          'B27',
          'C26',
          'D43',
        ],
      },
    ],
  },
  {
    title: 'Frida Kahlo 2',
    id: 'frida-kahlo-2',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Frida%20Kahlo%202.png',
    artData: [
      {
        size: {
          x: 301,
          y: 301,
        },
        pegs: [
          {
            x: 301,
            y: 150.5,
            angle: 0,
            name: 'D1',
          },
          {
            x: 300.9484274088214,
            y: 154.4396307203349,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 300.79374498056336,
            y: 158.376561414563,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 300.5360587268358,
            y: 162.3080939070407,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 300.17554525292513,
            y: 166.23153372178183,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 299.7124516367585,
            y: 170.14419192911777,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 299.14709525956823,
            y: 174.04338698855474,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 298.47986358837517,
            y: 177.92644658656818,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 297.7112139104377,
            y: 181.79070946807278,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 296.8416730198503,
            y: 185.63352726031374,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 295.8718368565048,
            y: 189.45226628792935,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 294.8023700976631,
            y: 193.24430937794037,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 293.6340057024206,
            y: 197.00705765342957,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 292.36754440937284,
            y: 200.7379323146825,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 291.00385418782884,
            y: 204.43437640656765,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 289.54386964294866,
            y: 208.09385657094597,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.98859137521146,
            y: 211.71386478290793,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 286.339085294654,
            y: 215.29192006964837,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 284.59648189034937,
            y: 218.82557021080177,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 282.7619754556258,
            y: 222.31239341907104,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 280.836823269558,
            y: 225.74999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 278.82234473529087,
            y: 229.13603398975027,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 276.7199204757863,
            y: 232.46817476976156,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 274.53099138761337,
            y: 235.74413865718734,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 272.25705765342957,
            y: 238.9616804700172,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.8996777138309,
            y: 242.11859506581243,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 267.46046719927415,
            y: 245.21271885300055,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.9410978228047,
            y: 248.24193127369267,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 262.3432962343478,
            y: 251.20415625700815,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 259.6688428373493,
            y: 254.09736364191,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.9195705685754,
            y: 256.9195705685754,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 254.09736364190996,
            y: 259.6688428373493,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 251.2041562570081,
            y: 262.34329623434786,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 248.2419312736926,
            y: 264.9410978228047,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 245.2127188530005,
            y: 267.46046719927415,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 242.1185950658124,
            y: 269.8996777138309,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.96168047001717,
            y: 272.2570576534296,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 235.74413865718728,
            y: 274.53099138761337,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 232.4681747697615,
            y: 276.71992047578635,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 229.13603398975022,
            y: 278.82234473529087,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 225.74999999999997,
            y: 280.836823269558,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 222.312393419071,
            y: 282.7619754556258,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.82557021080177,
            y: 284.59648189034937,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 215.29192006964837,
            y: 286.339085294654,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.71386478290793,
            y: 287.9885913752114,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 208.093856570946,
            y: 289.54386964294866,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 204.4343764065677,
            y: 291.00385418782884,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.73793231468255,
            y: 292.36754440937284,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 197.00705765342963,
            y: 293.6340057024206,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 193.2443093779404,
            y: 294.8023700976631,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 189.45226628792943,
            y: 295.8718368565048,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.63352726031383,
            y: 296.8416730198503,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.79070946807283,
            y: 297.7112139104377,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.9264465865683,
            y: 298.4798635883751,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 174.04338698855483,
            y: 299.14709525956823,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 170.1441919291179,
            y: 299.71245163675843,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 166.23153372178197,
            y: 300.17554525292513,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 162.3080939070408,
            y: 300.53605872683573,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 158.37656141456318,
            y: 300.79374498056336,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 154.43963072033506,
            y: 300.9484274088214,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.50000000000017,
            y: 301,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.56036927966528,
            y: 300.9484274088214,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.62343858543716,
            y: 300.79374498056336,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.69190609295953,
            y: 300.5360587268358,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.76846627821837,
            y: 300.1755452529252,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.85580807088246,
            y: 299.7124516367585,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.95661301144548,
            y: 299.1470952595683,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 123.07355341343207,
            y: 298.47986358837517,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 119.20929053192748,
            y: 297.7112139104378,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 115.36647273968649,
            y: 296.8416730198504,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.5477337120709,
            y: 295.87183685650484,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.75569062205994,
            y: 294.80237009766313,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.99294234657071,
            y: 293.63400570242067,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 100.26206768531779,
            y: 292.3675444093729,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.5656235934326,
            y: 291.00385418782895,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.90614342905431,
            y: 289.5438696429488,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 89.2861352170924,
            y: 287.98859137521157,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.70807993035191,
            y: 286.3390852946542,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 82.17442978919851,
            y: 284.59648189034954,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.68760658092921,
            y: 282.76197545562593,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.25000000000026,
            y: 280.8368232695582,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.86396601024994,
            y: 278.822344735291,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.53182523023864,
            y: 276.7199204757864,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.25586134281286,
            y: 274.5309913876135,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 62.03831952998296,
            y: 272.25705765342974,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.88140493418769,
            y: 269.89967771383095,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.78728114699959,
            y: 267.4604671992742,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.75806872630748,
            y: 264.94109782280475,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.795843742991934,
            y: 262.3432962343479,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.9026363580901,
            y: 259.66884283734936,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 44.080429431424655,
            y: 256.91957056857547,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.33115716265075,
            y: 254.09736364191002,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.656703765652196,
            y: 251.20415625700815,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 36.05890217719534,
            y: 248.24193127369267,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.53953280072588,
            y: 245.2127188530005,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 31.100322286169092,
            y: 242.1185950658124,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.742942346570374,
            y: 238.96168047001717,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.469008612386602,
            y: 235.74413865718728,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.28007952421363,
            y: 232.4681747697615,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.17765526470906,
            y: 229.13603398975022,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.163176730441904,
            y: 225.74999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.238024544374127,
            y: 222.31239341907093,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.403518109650545,
            y: 218.8255702108016,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.660914705345892,
            y: 215.2919200696482,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 13.01140862478847,
            y: 211.7138647829077,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.456130357051245,
            y: 208.09385657094577,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.996145812171036,
            y: 204.43437640656745,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.632455590627046,
            y: 200.73793231468224,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.365994297579293,
            y: 197.00705765342929,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.197629902336845,
            y: 193.24430937794,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.128163143495136,
            y: 189.452266287929,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.158326980149578,
            y: 185.63352726031337,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2887860895621603,
            y: 181.7907094680724,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.520136411624763,
            y: 177.92644658656778,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8529047404316987,
            y: 174.0433869885543,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2875483632414704,
            y: 170.14419192911728,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.824454747074803,
            y: 166.23153372178137,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.4639412731641913,
            y: 162.30809390704013,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.20625501943660468,
            y: 158.3765614145625,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.05157259117861568,
            y: 154.43963072033432,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 150.4999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.051572591178649096,
            y: 146.56036927966449,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20625501943667152,
            y: 142.62343858543633,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.46394127316429157,
            y: 138.69190609295867,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8244547470749367,
            y: 134.7684662782175,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.287548363241621,
            y: 130.85580807088155,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.8529047404318826,
            y: 126.95661301144453,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.520136411624964,
            y: 123.07355341343107,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2887860895624104,
            y: 119.20929053192646,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.1583269801498615,
            y: 115.36647273968548,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.128163143495436,
            y: 111.54773371206986,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.197629902337179,
            y: 107.75569062205885,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.365994297579644,
            y: 103.9929423465696,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.632455590627448,
            y: 100.26206768531664,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.996145812171454,
            y: 96.56562359343147,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.456130357051697,
            y: 92.90614342905313,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 13.011408624788954,
            y: 89.28613521709121,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.660914705346391,
            y: 85.70807993035072,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.40351810965108,
            y: 82.17442978919733,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.238024544374692,
            y: 78.68760658092803,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.16317673044249,
            y: 75.2499999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.17765526470966,
            y: 71.8639660102488,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.280079524214262,
            y: 68.53182523023752,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.469008612387253,
            y: 65.25586134281176,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.74294234657106,
            y: 62.03831952998189,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 31.100322286169792,
            y: 58.88140493418664,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.53953280072661,
            y: 55.787281146998566,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 36.05890217719611,
            y: 52.758068726306455,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.65670376565297,
            y: 49.795843742990954,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.33115716265154,
            y: 46.90263635808913,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 44.08042943142547,
            y: 44.08042943142372,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.90263635809094,
            y: 41.33115716264984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.7958437429928,
            y: 38.6567037656513,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.75806872630831,
            y: 36.058902177194526,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.78728114700044,
            y: 33.53953280072508,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.88140493418856,
            y: 31.10032228616832,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 62.03831952998386,
            y: 28.742942346569638,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.25586134281376,
            y: 26.469008612385885,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.53182523023956,
            y: 24.280079524212944,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.86396601025088,
            y: 22.17765526470839,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.25000000000121,
            y: 20.16317673044129,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.68760658093017,
            y: 18.238024544373523,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 82.17442978919948,
            y: 16.40351810964998,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.70807993035291,
            y: 14.66091470534534,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 89.28613521709343,
            y: 13.01140862478795,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.90614342905538,
            y: 11.456130357050762,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.56562359343374,
            y: 9.996145812170585,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 100.26206768531894,
            y: 8.632455590626627,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.99294234657191,
            y: 7.365994297578892,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.75569062206118,
            y: 6.1976299023364945,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.54773371207219,
            y: 5.128163143494801,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 115.36647273968782,
            y: 4.158326980149294,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 119.20929053192884,
            y: 3.2887860895619094,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 123.07355341343347,
            y: 2.5201364116245295,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.95661301144695,
            y: 1.8529047404314982,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.85580807088397,
            y: 1.2875483632413036,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.7684662782199,
            y: 0.824454747074686,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.6919060929611,
            y: 0.46394127316410777,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.62343858543875,
            y: 0.20625501943653785,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.5603692796669,
            y: 0.05157259117858226,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.50000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 154.43963072033677,
            y: 0.051572591178682514,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 158.37656141456495,
            y: 0.20625501943673835,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 162.30809390704258,
            y: 0.4639412731643918,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 166.23153372178376,
            y: 0.8244547470750704,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 170.1441919291197,
            y: 1.2875483632417881,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 174.04338698855668,
            y: 1.852904740432083,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.92644658657017,
            y: 2.520136411625198,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.79070946807477,
            y: 3.2887860895626777,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.63352726031576,
            y: 4.158326980150147,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 189.45226628793137,
            y: 5.128163143495754,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 193.2443093779424,
            y: 6.19762990233753,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 197.00705765343162,
            y: 7.365994297580045,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.7379323146845,
            y: 8.632455590627865,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 204.4343764065697,
            y: 9.996145812171905,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 208.09385657094805,
            y: 11.456130357052182,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.7138647829099,
            y: 13.011408624789455,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 215.29192006965044,
            y: 14.660914705346926,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.8255702108038,
            y: 16.40351810965165,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 222.31239341907306,
            y: 18.238024544375296,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.750000000002,
            y: 20.16317673044313,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 229.13603398975226,
            y: 22.177655264710328,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 232.46817476976352,
            y: 24.28007952421495,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 235.74413865718927,
            y: 26.469008612387974,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.96168047001913,
            y: 28.742942346571812,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 242.11859506581436,
            y: 31.100322286170563,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 245.2127188530024,
            y: 33.5395328007274,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 248.24193127369446,
            y: 36.058902177196934,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 251.20415625700994,
            y: 38.656703765653816,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 254.09736364191176,
            y: 41.33115716265242,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.91957056857717,
            y: 44.08042943142638,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 259.668842837351,
            y: 46.902636358091854,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 262.34329623434957,
            y: 49.79584374299374,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.94109782280634,
            y: 52.75806872630932,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 267.46046719927574,
            y: 55.78728114700147,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.89967771383243,
            y: 58.88140493418961,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 272.2570576534311,
            y: 62.038319529984925,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 274.53099138761485,
            y: 65.25586134281484,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 276.7199204757878,
            y: 68.53182523024067,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 278.82234473529223,
            y: 71.86396601025201,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 280.8368232695594,
            y: 75.25000000000237,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 282.76197545562707,
            y: 78.68760658093136,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 284.5964818903506,
            y: 82.17442978920069,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 286.33908529465526,
            y: 85.7080799303541,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.98859137521254,
            y: 89.28613521709465,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 289.54386964294974,
            y: 92.90614342905661,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 291.00385418782986,
            y: 96.56562359343498,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 292.3675444093738,
            y: 100.26206768532019,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 293.63400570242146,
            y: 103.99294234657317,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 294.80237009766387,
            y: 107.75569062206245,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 295.8718368565055,
            y: 111.54773371207347,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 296.841673019851,
            y: 115.36647273968913,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 297.71121391043835,
            y: 119.20929053193014,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 298.47986358837574,
            y: 123.07355341343477,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 299.1470952595687,
            y: 126.95661301144824,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 299.7124516367589,
            y: 130.85580807088527,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 300.1755452529254,
            y: 134.7684662782212,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 300.536058726836,
            y: 138.69190609296243,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 300.79374498056353,
            y: 142.62343858544008,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 300.94842740882143,
            y: 146.56036927966824,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'A46',
          'B2',
          'A43',
          'B8',
          'A19',
          'B4',
          'A17',
          'B5',
          'A45',
          'B13',
          'A42',
          'B15',
          'A56',
          'A14',
          'A57',
          'A13',
          'A28',
          'A9',
          'A30',
          'A8',
          'A58',
          'A4',
          'A59',
          'A1',
          'A60',
          'A7',
          'A31',
          'A2',
          'A48',
          'B12',
          'A32',
          'B14',
          'A44',
          'B6',
          'A16',
          'B8',
          'A15',
          'A55',
          'A5',
          'A57',
          'A9',
          'A50',
          'B10',
          'A43',
          'B17',
          'A41',
          'B18',
          'A33',
          'A2',
          'A58',
          'C60',
          'A60',
          'C59',
          'A51',
          'A11',
          'A26',
          'A10',
          'A52',
          'B7',
          'A20',
          'B3',
          'A7',
          'B4',
          'A6',
          'A53',
          'A3',
          'A57',
          'A1',
          'B1',
          'A21',
          'B2',
          'C60',
          'A31',
          'B15',
          'A23',
          'A56',
          'A22',
          'A59',
          'A8',
          'A52',
          'A4',
          'A30',
          'B16',
          'A42',
          'B10',
          'A19',
          'B11',
          'A18',
          'B12',
          'A17',
          'A54',
          'A2',
          'B4',
          'C60',
          'B5',
          'A6',
          'A47',
          'A3',
          'A59',
          'A1',
          'B3',
          'A20',
          'B9',
          'A14',
          'A58',
          'A13',
          'A50',
          'A12',
          'A28',
          'A10',
          'A56',
          'A4',
          'C4',
          'A5',
          'C5',
          'A30',
          'B18',
          'A40',
          'C17',
          'A38',
          'C14',
          'A39',
          'B19',
          'A32',
          'A7',
          'A36',
          'C12',
          'A33',
          'B10',
          'A13',
          'A59',
          'C60',
          'B5',
          'C59',
          'B1',
          'A8',
          'B2',
          'C58',
          'A60',
          'B15',
          'A44',
          'B7',
          'A6',
          'C6',
          'A29',
          'A9',
          'A51',
          'A5',
          'A58',
          'A2',
          'A34',
          'C12',
          'A38',
          'A7',
          'C8',
          'A32',
          'A4',
          'C3',
          'A27',
          'C1',
          'A30',
          'A8',
          'C9',
          'A12',
          'A50',
          'B16',
          'A26',
          'C2',
          'A29',
          'B18',
          'A37',
          'C15',
          'A28',
          'D59',
          'A24',
          'D58',
          'A25',
          'A53',
          'B15',
          'A38',
          'B17',
          'A42',
          'C17',
          'A43',
          'B14',
          'A58',
          'A9',
          'C10',
          'A35',
          'A2',
          'A59',
          'C60',
          'B6',
          'C59',
          'A52',
          'A7',
          'C6',
          'A34',
          'A8',
          'B3',
          'A1',
          'B4',
          'C58',
          'B5',
          'C57',
          'A60',
          'A45',
          'A4',
          'A53',
          'A3',
          'A56',
          'A2',
          'B2',
          'A21',
          'C4',
          'A30',
          'D58',
          'A14',
          'A49',
          'A18',
          'C3',
          'A5',
          'C7',
          'A8',
          'A50',
          'A11',
          'A51',
          'C46',
          'A52',
          'A7',
          'B6',
          'C58',
          'A53',
          'C43',
          'A44',
          'C19',
          'A41',
          'B14',
          'A19',
          'C2',
          'A6',
          'B8',
          'A4',
          'C5',
          'A9',
          'D59',
          'A29',
          'B19',
          'A28',
          'D60',
          'A23',
          'D55',
          'A25',
          'D57',
          'A30',
          'C11',
          'A10',
          'C1',
          'A22',
          'C14',
          'A26',
          'B18',
          'A36',
          'B20',
          'A32',
          'B15',
          'A52',
          'A5',
          'C2',
          'A21',
          'D54',
          'A24',
          'D60',
          'A16',
          'A48',
          'B16',
          'A23',
          'D59',
          'A31',
          'D58',
          'A29',
          'C10',
          'A13',
          'C5',
          'A19',
          'C13',
          'A37',
          'B17',
          'A32',
          'B21',
          'A36',
          'C9',
          'A9',
          'A51',
          'B8',
          'A44',
          'B16',
          'A40',
          'C12',
          'A11',
          'C8',
          'A21',
          'D60',
          'A2',
          'A59',
          'C59',
          'B3',
          'A46',
          'B12',
          'A16',
          'A49',
          'A14',
          'D59',
          'A26',
          'D54',
          'A27',
          'C16',
          'A44',
          'C42',
          'A53',
          'B11',
          'A33',
          'D58',
          'A15',
          'C8',
          'A37',
          'B19',
          'A25',
          'D60',
          'A5',
          'C6',
          'A20',
          'C3',
          'A3',
          'A57',
          'A1',
          'B4',
          'C59',
          'B7',
          'C58',
          'A59',
          'C53',
          'A60',
          'C52',
          'B1',
          'C51',
          'A53',
          'A2',
          'A36',
          'D58',
          'A22',
          'D56',
          'A31',
          'B17',
          'A23',
          'A8',
          'A51',
          'A35',
          'C17',
          'A41',
          'B15',
          'A27',
          'D57',
          'A16',
          'C9',
          'C26',
          'C10',
          'A7',
          'C3',
          'A11',
          'C1',
          'A20',
          'D55',
          'A31',
          'A9',
          'C4',
          'A17',
          'C2',
          'A30',
          'D60',
          'A13',
          'C11',
          'A38',
          'B16',
          'A22',
          'D54',
          'A29',
          'B20',
          'A28',
          'C3',
          'A35',
          'C1',
          'A2',
          'B2',
          'C56',
          'B5',
          'A8',
          'C6',
          'A4',
          'A52',
          'C44',
          'A43',
          'B11',
          'A50',
          'A10',
          'C2',
          'A22',
          'D60',
          'A38',
          'D59',
          'A32',
          'B22',
          'A36',
          'A1',
          'A58',
          'A9',
          'C11',
          'A19',
          'C4',
          'A26',
          'D58',
          'A34',
          'A51',
          'A8',
          'C5',
          'A6',
          'A54',
          'C42',
          'A49',
          'B14',
          'A39',
          'D59',
          'A13',
          'C6',
          'A23',
          'C1',
          'A37',
          'C18',
          'A45',
          'B13',
          'A33',
          'C7',
          'A4',
          'A55',
          'B10',
          'A11',
          'A28',
          'D56',
          'A17',
          'C9',
          'A22',
          'B17',
          'A30',
          'A3',
          'A58',
          'C54',
          'A59',
          'C50',
          'B1',
          'C57',
          'B6',
          'C56',
          'A53',
          'C41',
          'A49',
          'A13',
          'D57',
          'A23',
          'D53',
          'A25',
          'D59',
          'A5',
          'C8',
          'C28',
          'C13',
          'A20',
          'A48',
          'C45',
          'A51',
          'A8',
          'C2',
          'A12',
          'D60',
          'A31',
          'D54',
          'A32',
          'D56',
          'A24',
          'C6',
          'A10',
          'C10',
          'C25',
          'C5',
          'A16',
          'D59',
          'A21',
          'C3',
          'A40',
          'B18',
          'A35',
          'B23',
          'A41',
          'B11',
          'A39',
          'D58',
          'A27',
          'C4',
          'A11',
          'A29',
          'D55',
          'A26',
          'B19',
          'A33',
          'D54',
          'A18',
          'C1',
          'A4',
          'A55',
          'B14',
          'A17',
          'C7',
          'A6',
          'C9',
          'A43',
          'C19',
          'A15',
          'C12',
          'A21',
          'D58',
          'A37',
          'D57',
          'A31',
          'B22',
          'A39',
          'B15',
          'A51',
          'C42',
          'A52',
          'A7',
          'C4',
          'C29',
          'C6',
          'A18',
          'D59',
          'A30',
          'C10',
          'A8',
          'B10',
          'A47',
          'C20',
          'A48',
          'A19',
          'C1',
          'A15',
          'C7',
          'A43',
          'C43',
          'A46',
          'C17',
          'A32',
          'B23',
          'A39',
          'A1',
          'B4',
          'C59',
          'A52',
          'B9',
          'A6',
          'A38',
          'C8',
          'A8',
          'A35',
          'C13',
          'A14',
          'C2',
          'A2',
          'A60',
          'C49',
          'B1',
          'C45',
          'A42',
          'C10',
          'A31',
          'D53',
          'A27',
          'D60',
          'A20',
          'C4',
          'A34',
          'D54',
          'A23',
          'D58',
          'A40',
          'B21',
          'A28',
          'D55',
          'A21',
          'B17',
          'A47',
          'C43',
          'A55',
          'C42',
          'A56',
          'A4',
          'C2',
          'A11',
          'C7',
          'C23',
          'C6',
          'A3',
          'C4',
          'A36',
          'D59',
          'A22',
          'D57',
          'A38',
          'B16',
          'A33',
          'C5',
          'A10',
          'A50',
          'C47',
          'A41',
          'B15',
          'A49',
          'C20',
          'A53',
          'B12',
          'A43',
          'C6',
          'A15',
          'D60',
          'A17',
          'D55',
          'A30',
          'D54',
          'A36',
          'C60',
          'A60',
          'C51',
          'B4',
          'C58',
          'B8',
          'C57',
          'A52',
          'C16',
          'A29',
          'A11',
          'C13',
          'C28',
          'C1',
          'A12',
          'C18',
          'A14',
          'C11',
          'A28',
          'D57',
          'A26',
          'B20',
          'A37',
          'D54',
          'A8',
          'C12',
          'A23',
          'D56',
          'A38',
          'B25',
          'A36',
          'C2',
          'A16',
          'D58',
          'A19',
          'B15',
          'A43',
          'C5',
          'A25',
          'C1',
          'A6',
          'B46',
          'A5',
          'B45',
          'A4',
          'C8',
          'A9',
          'A31',
          'B23',
          'A38',
          'B17',
          'A41',
          'C44',
          'A45',
          'B3',
          'C60',
          'A37',
          'D60',
          'A10',
          'C3',
          'A15',
          'C10',
          'A21',
          'C1',
          'A29',
          'D56',
          'A33',
          'D55',
          'A35',
          'B25',
          'A43',
          'B8',
          'C56',
          'B6',
          'C55',
          'A54',
          'A6',
          'A32',
          'A3',
          'C2',
          'C27',
          'C4',
          'A13',
          'C15',
          'A53',
          'B13',
          'A58',
          'A1',
          'B5',
          'C59',
          'B7',
          'A7',
          'C7',
          'A22',
          'D53',
          'A34',
          'B18',
          'A30',
          'D56',
          'A19',
          'D59',
          'A15',
          'C18',
          'A10',
          'A57',
          'C21',
          'A55',
          'C41',
          'A51',
          'C17',
          'A33',
          'D60',
          'C29',
          'C11',
          'A8',
          'B47',
          'A7',
          'D55',
          'A39',
          'D54',
          'A25',
          'D56',
          'A16',
          'C1',
          'A26',
          'D53',
          'A30',
          'B19',
          'A41',
          'D58',
          'A20',
          'D57',
          'A24',
          'C15',
          'A44',
          'C9',
          'A14',
          'B12',
          'A13',
          'C19',
          'A8',
          'C16',
          'A36',
          'D55',
          'A32',
          'C6',
          'A9',
          'C1',
          'A17',
          'C12',
          'A37',
          'B24',
          'A40',
          'D54',
          'A38',
          'B26',
          'A35',
          'D60',
          'A18',
          'C5',
          'A3',
          'B44',
          'A2',
          'A56',
          'C41',
          'A52',
          'B13',
          'A42',
          'B16',
          'A48',
          'C43',
          'A50',
          'A10',
          'A29',
          'D57',
          'A21',
          'D52',
          'A27',
          'B18',
          'A23',
          'C16',
          'A6',
          'B47',
          'A5',
          'D58',
          'C30',
          'C3',
          'A2',
          'D21',
          'C59',
          'A58',
          'C21',
          'A59',
          'A9',
          'A31',
          'C9',
          'A24',
          'D55',
          'A37',
          'B17',
          'A52',
          'B32',
          'A49',
          'A13',
          'B53',
          'A12',
          'C7',
          'A38',
          'C4',
          'A14',
          'C1',
          'A1',
          'B50',
          'A2',
          'D59',
          'A33',
          'D53',
          'A35',
          'D57',
          'A17',
          'D58',
          'A28',
          'C36',
          'A29',
          'C15',
          'A54',
          'B9',
          'A44',
          'C3',
          'A9',
          'C12',
          'C27',
          'C7',
          'A30',
          'B23',
          'A34',
          'D56',
          'A26',
          'C37',
          'A27',
          'C20',
          'A12',
          'D59',
          'A4',
          'B43',
          'A3',
          'D21',
          'C58',
          'B5',
          'C53',
          'B6',
          'C51',
          'D20',
          'C50',
          'B2',
          'C45',
          'A40',
          'B19',
          'A23',
          'C11',
          'A5',
          'A54',
          'B31',
          'A53',
          'A7',
          'B45',
          'A3',
          'A55',
          'C15',
          'A12',
          'A49',
          'B33',
          'A48',
          'A15',
          'C13',
          'A32',
          'D57',
          'A42',
          'D54',
          'A41',
          'C16',
          'A46',
          'B18',
          'A21',
          'D56',
          'A39',
          'C4',
          'A43',
          'B28',
          'A40',
          'D55',
          'A22',
          'C5',
          'A12',
          'B12',
          'A57',
          'C41',
          'A37',
          'D53',
          'A28',
          'D24',
          'A25',
          'B20',
          'A33',
          'C9',
          'A5',
          'D27',
          'A8',
          'B48',
          'A7',
          'C20',
          'A50',
          'B13',
          'A45',
          'B14',
          'A43',
          'D54',
          'A19',
          'C19',
          'A11',
          'D60',
          'A3',
          'B51',
          'A2',
          'D26',
          'A35',
          'C5',
          'A29',
          'D24',
          'A33',
          'D52',
          'A30',
          'C36',
          'A31',
          'C1',
          'A24',
          'D23',
          'C55',
          'D21',
          'C49',
          'D16',
          'C50',
          'B40',
          'A58',
          'B42',
          'C53',
          'A59',
          'B39',
          'C46',
          'A60',
          'B38',
          'A52',
          'C12',
          'A18',
          'D58',
          'A13',
          'B54',
          'A15',
          'C2',
          'A44',
          'B27',
          'A38',
          'C10',
          'C25',
          'D10',
          'C23',
          'A10',
          'C8',
          'A6',
          'D21',
          'A1',
          'B44',
          'A58',
          'C20',
          'A28',
          'D25',
          'A26',
          'D52',
          'A31',
          'D24',
          'A36',
          'B17',
          'A42',
          'C7',
          'A19',
          'D57',
          'A47',
          'D59',
          'C30',
          'C5',
          'A2',
          'B43',
          'C60',
          'B49',
          'A10',
          'D58',
          'A38',
          'B28',
          'A37',
          'C17',
          'A34',
          'D24',
          'C58',
          'D54',
          'A44',
          'C1',
          'A32',
          'B24',
          'A39',
          'B15',
          'A18',
          'C8',
          'A16',
          'C18',
          'A13',
          'C3',
          'A24',
          'D53',
          'A20',
          'C2',
          'A28',
          'D51',
          'A23',
          'D23',
          'A26',
          'B21',
          'A41',
          'D55',
          'A27',
          'B19',
          'D60',
          'A1',
          'B45',
          'C59',
          'D20',
          'C48',
          'D21',
          'C57',
          'D24',
          'A32',
          'A3',
          'B3',
          'A2',
          'A56',
          'B11',
          'A47',
          'C9',
          'C28',
          'C6',
          'A52',
          'C3',
          'A19',
          'C33',
          'A21',
          'C16',
          'A53',
          'B35',
          'A59',
          'B41',
          'C52',
          'D40',
          'D21',
          'C54',
          'B4',
          'A45',
          'D54',
          'C29',
          'C1',
          'B26',
          'A34',
          'D59',
          'A49',
          'A11',
          'B51',
          'A10',
          'C20',
          'A60',
          'B49',
          'A9',
          'D24',
          'A7',
          'D26',
          'A39',
          'D57',
          'C31',
          'A21',
          'B19',
          'C21',
          'A8',
          'A51',
          'C43',
          'A54',
          'C11',
          'A26',
          'D60',
          'A46',
          'D27',
          'A30',
          'C15',
          'A56',
          'B30',
          'C1',
          'B27',
          'A41',
          'C12',
          'D7',
          'C13',
          'A16',
          'B56',
          'A15',
          'A48',
          'D57',
          'A14',
          'C20',
          'B20',
          'A43',
          'D25',
          'A40',
          'C19',
          'A9',
          'C7',
          'A24',
          'B14',
          'D28',
          'A8',
          'C4',
          'A18',
          'B58',
          'A17',
          'C18',
          'A27',
          'D26',
          'A1',
          'B51',
          'B1',
          'B17',
          'A31',
          'C3',
          'A25',
          'C38',
          'A44',
          'D25',
          'A29',
          'D53',
          'A38',
          'C16',
          'A43',
          'B15',
          'D29',
          'A10',
          'B12',
          'A47',
          'B33',
          'A60',
          'B46',
          'A58',
          'C15',
          'A7',
          'B55',
          'A17',
          'C10',
          'D3',
          'C48',
          'D15',
          'C47',
          'B40',
          'C46',
          'A39',
          'B16',
          'A32',
          'D60',
          'A40',
          'D53',
          'A36',
          'D56',
          'A20',
          'C19',
          'B19',
          'D59',
          'A27',
          'D25',
          'A38',
          'D26',
          'A4',
          'B44',
          'C60',
          'B51',
          'A60',
          'A9',
          'C24',
          'D10',
          'C21',
          'A11',
          'C6',
          'A28',
          'B17',
          'D60',
          'B26',
          'A32',
          'D51',
          'A30',
          'D24',
          'A35',
          'D59',
          'A52',
          'B36',
          'A51',
          'C16',
          'A5',
          'B53',
          'A4',
          'C10',
          'A23',
          'C4',
          'A2',
          'D20',
          'D35',
          'B16',
          'C23',
          'D11',
          'C27',
          'D12',
          'C25',
          'A19',
          'B57',
          'B4',
          'C57',
          'B7',
          'C53',
          'D21',
          'D43',
          'D22',
          'D42',
          'A17',
          'A47',
          'D27',
          'A28',
          'C18',
          'A50',
          'A10',
          'C12',
          'D7',
          'C11',
          'A20',
          'D59',
          'A37',
          'D28',
          'A5',
          'A54',
          'B14',
          'A33',
          'B21',
          'A25',
          'C2',
          'B25',
          'A39',
          'C60',
          'B3',
          'A8',
          'B46',
          'A7',
          'C9',
          'A53',
          'C3',
          'B31',
          'C1',
          'A45',
          'D32',
          'D17',
          'C49',
          'B39',
          'C45',
          'B40',
          'A56',
          'C14',
          'A12',
          'B50',
          'B2',
          'B37',
          'B1',
          'C44',
          'A42',
          'D25',
          'A9',
          'A50',
          'C17',
          'B18',
          'D60',
          'A29',
          'D23',
          'A31',
          'C2',
          'B26',
          'D59',
          'A54',
          'C16',
          'B17',
          'C23',
          'B20',
          'C19',
          'A12',
          'C4',
          'A24',
          'D52',
          'A22',
          'C13',
          'D8',
          'C12',
          'A14',
          'B54',
          'A6',
          'A51',
          'C40',
          'A53',
          'B40',
          'C49',
          'D19',
          'B11',
          'A15',
          'A48',
          'D56',
          'A35',
          'A20',
          'C7',
          'A56',
          'B43',
          'C54',
          'D45',
          'A17',
          'C5',
          'B28',
          'C1',
          'A28',
          'D54',
          'A46',
          'C44',
          'B2',
          'B45',
          'B1',
          'C20',
          'A8',
          'D21',
          'D39',
          'C52',
          'D41',
          'A18',
          'C34',
          'A27',
          'B20',
          'C18',
          'A6',
          'D26',
          'A41',
          'C8',
          'A23',
          'D24',
          'A10',
          'B18',
          'A42',
          'D55',
          'A44',
          'D32',
          'A46',
          'D58',
          'B19',
          'A8',
          'B49',
          'A59',
          'B38',
          'A51',
          'C13',
          'C28',
          'D3',
          'C47',
          'D2',
          'C9',
          'A35',
          'D27',
          'B14',
          'D29',
          'B13',
          'C21',
          'A32',
          'C11',
          'A15',
          'D57',
          'A4',
          'B46',
          'C60',
          'D21',
          'C52',
          'B7',
          'C51',
          'D37',
          'B16',
          'C26',
          'D3',
          'B5',
          'C54',
          'A54',
          'C8',
          'A55',
          'B10',
          'A7',
          'C22',
          'A43',
          'C3',
          'C29',
          'D27',
          'C28',
          'D5',
          'D33',
          'D18',
          'B11',
          'A11',
          'A49',
          'D32',
          'A13',
          'C16',
          'A9',
          'B2',
          'C15',
          'B17',
          'D40',
          'A19',
          'B59',
          'A21',
          'A37',
          'A18',
          'D44',
          'C53',
          'D6',
          'B7',
          'C56',
          'D19',
          'C54',
          'B6',
          'D2',
          'C49',
          'B41',
          'C47',
          'D20',
          'C58',
          'B44',
          'B4',
          'B36',
          'B3',
          'B58',
          'A10',
          'A37',
          'D24',
          'D43',
          'D25',
          'A45',
          'B14',
          'D26',
          'A26',
          'C3',
          'B25',
          'A31',
          'D50',
          'A29',
          'D27',
          'C27',
          'D2',
          'C8',
          'A17',
          'B15',
          'D59',
          'B18',
          'C26',
          'D11',
          'C21',
          'B21',
          'A40',
          'D56',
          'A27',
          'C2',
          'C33',
          'A26',
          'C7',
          'A58',
          'B47',
          'C59',
          'B49',
          'A3',
          'B52',
          'A14',
          'C19',
          'A21',
          'B60',
          'A20',
          'C5',
          'A38',
          'C1',
          'A53',
          'C12',
          'A19',
          'C32',
          'D60',
          'B28',
          'C4',
          'A41',
          'D25',
          'A34',
          'D26',
          'A40',
          'B22',
          'C44',
          'B23',
          'A29',
          'C13',
          'A25',
          'D23',
          'C28',
          'D1',
          'A13',
          'D31',
          'A43',
          'B16',
          'C1',
          'B31',
          'A50',
          'C60',
          'B38',
          'A57',
          'C55',
          'B8',
          'C51',
          'D15',
          'C50',
          'D21',
          'C47',
          'D4',
          'C26',
          'D27',
          'A48',
          'A15',
          'C9',
          'C24',
          'A11',
          'C17',
          'A52',
          'A4',
          'B1',
          'B53',
          'A2',
          'B42',
          'A57',
          'C40',
          'A37',
          'A17',
          'A47',
          'D54',
          'A20',
          'D40',
          'D25',
          'A30',
          'D22',
          'A27',
          'D27',
          'A3',
          'C8',
          'A25',
          'D51',
          'A33',
          'D57',
          'B19',
          'D56',
          'C59',
          'D8',
          'D33',
          'A46',
          'D31',
          'A14',
          'B55',
          'B2',
          'B48',
          'A10',
          'C22',
          'B12',
          'C20',
          'B13',
          'D23',
          'C26',
          'C7',
          'A60',
          'C6',
          'A16',
          'C14',
          'D5',
          'C10',
          'A12',
          'D2',
          'C48',
          'B39',
          'B1',
          'A3',
          'A55',
          'A15',
          'A56',
          'C8',
          'A34',
          'C2',
          'B29',
          'D60',
          'B30',
          'B3',
          'B44',
          'A6',
          'B45',
          'A59',
          'C52',
          'D42',
          'D12',
          'C42',
          'A50',
          'D38',
          'C51',
          'A54',
          'C40',
          'A55',
          'D59',
          'A43',
          'D24',
          'A44',
          'D58',
          'B18',
          'C20',
          'A51',
          'A5',
          'A38',
          'A16',
          'B15',
          'D28',
          'A7',
          'C21',
          'A56',
          'B44',
          'C55',
          'D7',
          'B7',
          'D1',
          'A14',
          'C16',
          'D6',
          'C14',
          'A21',
          'D53',
          'A41',
          'C2',
          'B27',
          'C6',
          'B53',
          'B3',
          'C60',
          'B19',
          'D55',
          'A12',
          'B17',
          'C1',
          'A3',
          'D19',
          'C53',
          'B8',
          'D10',
          'C38',
          'A45',
          'D27',
          'A31',
          'C5',
          'C29',
          'D31',
          'A44',
          'C22',
          'B39',
          'C44',
          'B40',
          'A60',
          'C12',
          'B4',
          'C60',
          'D26',
          'A36',
          'B24',
          'A21',
          'D1',
          'A10',
          'C26',
          'D28',
          'B13',
          'D58',
          'C59',
          'B38',
          'C58',
          'D8',
          'C13',
          'A39',
          'C1',
          'A56',
          'A2',
          'B2',
          'C20',
          'B14',
          'C12',
          'B30',
          'C11',
          'A29',
          'C3',
          'A33',
          'D50',
          'A5',
          'B54',
          'C6',
          'B33',
          'A46',
          'B12',
          'D29',
          'B55',
          'A16',
          'A41',
          'C46',
          'D2',
          'C24',
          'A19',
          'D41',
          'D12',
          'C41',
          'A59',
          'B50',
          'C60',
          'B6',
          'B43',
          'B5',
          'B35',
          'C57',
          'D8',
          'C12',
          'A22',
          'D46',
          'C54',
          'A58',
          'C14',
          'B18',
          'A9',
          'A50',
          'D32',
          'D17',
          'B10',
          'D16',
          'C48',
          'D4',
          'C15',
          'A15',
          'D42',
          'D25',
          'D40',
          'A56',
          'C60',
          'B52',
          'C5',
          'A23',
          'B21',
          'C20',
          'A11',
          'A49',
          'D37',
          'A12',
          'C17',
          'B59',
          'A18',
          'C16',
          'A28',
          'D8',
          'D43',
          'A17',
          'A47',
          'D58',
          'A32',
          'D22',
          'C49',
          'D3',
          'C9',
          'A51',
          'A8',
          'D25',
          'A37',
          'B29',
          'D59',
          'B17',
          'D39',
          'D9',
          'B8',
          'C51',
          'B41',
          'A55',
          'C16',
          'B18',
          'A38',
          'C15',
          'B3',
          'B59',
          'A9',
          'C25',
          'B40',
          'C24',
          'D3',
          'A23',
          'D45',
          'D20',
          'C56',
          'B19',
          'C43',
          'B22',
          'A33',
          'D27',
          'A26',
          'C5',
          'A60',
          'B52',
          'A13',
          'C21',
          'B11',
          'D57',
          'C60',
          'D9',
          'C20',
          'B60',
          'B4',
          'C11',
          'A41',
          'D60',
          'B31',
          'B2',
          'A1',
          'B42',
          'C55',
          'D24',
          'C28',
          'D11',
          'A4',
          'A54',
          'D34',
          'A52',
          'C2',
          'C31',
          'A23',
          'A46',
          'A21',
          'D23',
          'A36',
          'C22',
          'B9',
          'A49',
          'A11',
          'B56',
          'B5',
          'C57',
          'B16',
          'D36',
          'A44',
          'C7',
          'A10',
          'A57',
          'A2',
          'B54',
          'C28',
          'D25',
          'D40',
          'A21',
          'B57',
          'A16',
          'D33',
          'D6',
          'C10',
          'A32',
          'B27',
          'C5',
          'B33',
          'A43',
          'C39',
          'A37',
          'A9',
          'B47',
          'B3',
          'C14',
          'D9',
          'C37',
          'A59',
          'C24',
          'D27',
          'B13',
          'A40',
          'C18',
          'B16',
          'D34',
          'A42',
          'C8',
          'A19',
          'A35',
          'B19',
          'D54',
          'A48',
          'A14',
          'D4',
          'D38',
          'A15',
          'B21',
          'C60',
          'D19',
          'C52',
          'D5',
          'C17',
          'A8',
          'B59',
          'B5',
          'B49',
          'A1',
          'B18',
          'D57',
          'A45',
          'D55',
          'A4',
          'B47',
          'A59',
          'C8',
          'D3',
          'C46',
          'D21',
          'B12',
          'A7',
          'A53',
          'D41',
          'D13',
          'C27',
          'B60',
          'C48',
          'D1',
          'A24',
          'B16',
          'C17',
          'A25',
          'D6',
          'C52',
          'B5',
          'D4',
          'A23',
          'C30',
          'A14',
          'A48',
          'D36',
          'C51',
          'D39',
          'A56',
          'C58',
          'D55',
          'A18',
          'C13',
          'B18',
          'D51',
          'A27',
          'D48',
          'A29',
          'D28',
          'A36',
          'C1',
          'B25',
          'A33',
          'C21',
          'A60',
          'B37',
          'C15',
          'D5',
          'B8',
          'B42',
          'C52',
          'A52',
          'D33',
          'D9',
          'C36',
          'A57',
          'C16',
          'B32',
          'C7',
          'B1',
          'B48',
          'A6',
          'D54',
          'A49',
          'D39',
          'D12',
          'A4',
          'B50',
          'C23',
          'A12',
          'B52',
          'C5',
          'B53',
          'C60',
          'D11',
          'D45',
          'D20',
          'B12',
          'C19',
          'A16',
          'A47',
          'D26',
          'A9',
          'A50',
          'A6',
          'B57',
          'C14',
          'A17',
          'D59',
          'A41',
          'C18',
          'A48',
          'A13',
          'D54',
          'C32',
          'A20',
          'A46',
          'D25',
          'D40',
          'C51',
          'B9',
          'B58',
          'C25',
          'A10',
          'B21',
          'C45',
          'B4',
          'B46',
          'A57',
          'C55',
          'B3',
          'C20',
          'B15',
          'D38',
          'A51',
          'A5',
          'A60',
          'D43',
          'D8',
          'C56',
          'D21',
          'D45',
          'A16',
          'D6',
          'C14',
          'B16',
          'D60',
          'A4',
          'A54',
          'B42',
          'B7',
          'C25',
          'D1',
          'C47',
          'B38',
          'C20',
          'D11',
          'D30',
          'A43',
          'D53',
          'B19',
          'C54',
          'D47',
          'A21',
          'A40',
          'C10',
          'B29',
          'C4',
          'A28',
          'D23',
          'C24',
          'D4',
          'C8',
          'A51',
          'A5',
          'B57',
          'C39',
          'A55',
          'A3',
          'B1',
          'B54',
          'A12',
          'A48',
          'C32',
          'D56',
          'B10',
          'C22',
          'A31',
          'D22',
          'C48',
          'D18',
          'D33',
          'C28',
          'B52',
          'B4',
          'D1',
          'D32',
          'A43',
          'C2',
          'A1',
          'B20',
          'C16',
          'A34',
          'B15',
          'A15',
          'A41',
          'C42',
          'B2',
          'B56',
          'A8',
          'D58',
          'B17',
          'A2',
          'B48',
          'C56',
          'A56',
          'D59',
          'B30',
          'C10',
          'A43',
          'D56',
          'B18',
          'C57',
          'B36',
          'A50',
          'D54',
          'A11',
          'A49',
          'A10',
          'C21',
          'C5',
          'B1',
          'B32',
          'C4',
          'B48',
          'A58',
          'D41',
          'B17',
          'D38',
          'C28',
          'D3',
          'C27',
          'B34',
          'B6',
          'B45',
          'C20',
          'A15',
          'A42',
          'D24',
          'A24',
          'D42',
          'D8',
          'A27',
          'C33',
          'A35',
          'A19',
          'A46',
          'D55',
          'A38',
          'C22',
          'D1',
          'C9',
          'A37',
          'D26',
          'B15',
          'C19',
          'D6',
          'C13',
          'A59',
          'C36',
          'A39',
          'D25',
          'D40',
          'A48',
          'D33',
          'D9',
          'C57',
          'D18',
          'C55',
          'B21',
          'C18',
          'A51',
          'C4',
          'B49',
          'D32',
          'A12',
          'C6',
          'A21',
          'D44',
          'A55',
          'C2',
          'A35',
          'A18',
          'A40',
          'C22',
          'A8',
          'B60',
          'B8',
          'C25',
          'B10',
          'D15',
          'C49',
          'D24',
          'D8',
          'B7',
          'C54',
          'B21',
          'C44',
          'B24',
          'C3',
          'B32',
          'C15',
          'B58',
          'A22',
          'C37',
          'B57',
          'A20',
          'B13',
          'C22',
          'B17',
          'A11',
          'B52',
          'A7',
          'C23',
          'B40',
          'C48',
          'B41',
          'C46',
          'D4',
          'D38',
          'D8',
          'C12',
          'A25',
          'C6',
          'A2',
          'B3',
          'B54',
          'B6',
          'A51',
          'A8',
          'B20',
          'C56',
          'D49',
          'A26',
          'B23',
          'C43',
          'D13',
          'A4',
          'D48',
          'D15',
          'A35',
          'D51',
          'B19',
          'A17',
          'C35',
          'C4',
          'A32',
          'D27',
          'A2',
          'B10',
          'C18',
          'B58',
          'C39',
          'A56',
          'D35',
          'A53',
          'C17',
          'D11',
          'D29',
          'D12',
          'B9',
          'B41',
          'C27',
          'B52',
          'C25',
          'B11',
          'D56',
          'A3',
          'A55',
          'D43',
          'B1',
          'D42',
          'D22',
          'A11',
          'B59',
          'C16',
          'A16',
          'A47',
          'D55',
          'A19',
          'B16',
          'A13',
          'D39',
          'A21',
          'B6',
          'B48',
          'A9',
          'A50',
          'C33',
          'C8',
          'D5',
          'B49',
          'B3',
          'C59',
          'B17',
          'D37',
          'A51',
          'A3',
          'D38',
          'C52',
          'B7',
          'B44',
          'A5',
          'D11',
          'A30',
          'C6',
          'A44',
          'D59',
          'B16',
          'C25',
          'D3',
          'B6',
          'D6',
          'C9',
          'B34',
          'C8',
          'A57',
          'B39',
          'C43',
          'A52',
          'D54',
          'B18',
          'C12',
          'A4',
          'C4',
          'B50',
          'D32',
          'D2',
          'A24',
          'D44',
          'D11',
          'C40',
          'B2',
          'B57',
          'A12',
          'A48',
          'C44',
          'A49',
          'D27',
          'B11',
          'C7',
          'A3',
          'B16',
          'C16',
          'B21',
          'C42',
          'A54',
          'C5',
          'A40',
          'A18',
          'A46',
          'D38',
          'A17',
          'B54',
          'D32',
          'A52',
          'A8',
          'D49',
          'A1',
          'B39',
          'A51',
          'C58',
          'B47',
          'B32',
          'C1',
          'B27',
          'D59',
          'A6',
          'D1',
          'C50',
          'B8',
          'B52',
          'D29',
          'D13',
          'C29',
          'D32',
          'B15',
          'A14',
          'C17',
          'C32',
          'A22',
          'C16',
          'D4',
          'C11',
          'A34',
          'D57',
          'A18',
          'C34',
          'D7',
          'A28',
          'C12',
          'B5',
          'B45',
          'A2',
          'D19',
          'B12',
          'A44',
          'D23',
          'C53',
          'D43',
          'D11',
          'C39',
          'B59',
          'A7',
          'A53',
          'D60',
          'B14',
          'D34',
          'C28',
          'D40',
          'A38',
          'D41',
          'A16',
          'A47',
          'D37',
          'A57',
          'C15',
          'A31',
          'D28',
          'C24',
          'B2',
          'C38',
          'B56',
          'B3',
          'C13',
          'B29',
          'B44',
          'A55',
          'C20',
          'B46',
          'D32',
          'D6',
          'C48',
          'A40',
          'C9',
          'B30',
          'C4',
          'A42',
          'D60',
          'B25',
          'A15',
          'D45',
          'A19',
          'B60',
          'C19',
          'A45',
          'D38',
          'A56',
          'C38',
          'B59',
          'C49',
          'A54',
          'A4',
          'A60',
          'B54',
          'A1',
          'B40',
          'B10',
          'D28',
          'A28',
          'B14',
          'C21',
          'B17',
          'C14',
          'A24',
          'C30',
          'D26',
          'D11',
          'C59',
          'D23',
          'A43',
          'A23',
          'D43',
          'C51',
          'D5',
          'C15',
          'D10',
          'D46',
          'A56',
          'C35',
          'A39',
          'C11',
          'B18',
          'D50',
          'C55',
          'B4',
          'B29',
          'A40',
          'D21',
          'A22',
          'A60',
          'D56',
          'A44',
          'D27',
          'A36',
          'C21',
          'A14',
          'B51',
          'B5',
          'B55',
          'C6',
          'C28',
          'B49',
          'C57',
          'B38',
          'C42',
          'B22',
          'C59',
          'B18',
          'D49',
          'A20',
          'B14',
          'D40',
          'C26',
          'A9',
          'A50',
          'D39',
          'B16',
          'D26',
          'C29',
          'D2',
          'A11',
          'C18',
          'B17',
          'D35',
          'A19',
          'B26',
          'C7',
          'B2',
          'B52',
          'B31',
          'C12',
          'A31',
          'C30',
          'D12',
          'D38',
          'A58',
          'B37',
          'D30',
          'B58',
          'A20',
          'C34',
          'C1',
          'A40',
          'D43',
          'A5',
          'A52',
          'C41',
          'A54',
          'C17',
          'B56',
          'C40',
          'B57',
          'A9',
          'C22',
          'D3',
          'C7',
          'B34',
          'A46',
          'A18',
          'C18',
          'A20',
          'B25',
          'A30',
          'C16',
          'A60',
          'D41',
          'A12',
          'A48',
          'D55',
          'B8',
          'B43',
          'C54',
          'D22',
          'A34',
          'C3',
          'B59',
          'C37',
          'D10',
          'A1',
          'B1',
          'A3',
          'B9',
          'B51',
          'C4',
          'B52',
          'A2',
          'B17',
          'D28',
          'C49',
          'B57',
          'C13',
          'A33',
          'D26',
          'C28',
          'D49',
          'D13',
          'D29',
          'D14',
          'B11',
          'D55',
          'A51',
          'D34',
          'C50',
          'B9',
          'C20',
          'B47',
          'B7',
          'B53',
          'A16',
          'A47',
          'C46',
          'B36',
          'B51',
          'D32',
          'A17',
          'D40',
          'D6',
          'B8',
          'C48',
          'D24',
          'A27',
          'C5',
          'B2',
          'A1',
          'B52',
          'D8',
          'A19',
          'C6',
          'A37',
          'D56',
          'A45',
          'D59',
          'C60',
          'D20',
          'C54',
          'B22',
          'C45',
          'B38',
          'D30',
          'B33',
          'C24',
          'C9',
          'A48',
          'B32',
          'C53',
          'D4',
          'C7',
          'B57',
          'B1',
          'C16',
          'B56',
          'A13',
          'C25',
          'B12',
          'D33',
          'A53',
          'A5',
          'D2',
          'B4',
          'C10',
          'A18',
          'D37',
          'B15',
          'A4',
          'A60',
          'C41',
          'B38',
          'B8',
          'D53',
          'C33',
          'A51',
          'C53',
          'D45',
          'A54',
          'D54',
          'A15',
          'B55',
          'C20',
          'B48',
          'B28',
          'A28',
          'D47',
          'D15',
          'A56',
          'D43',
          'C59',
          'B37',
          'C21',
          'B39',
          'D30',
          'D2',
          'A26',
          'C18',
          'A43',
          'A22',
          'A44',
          'D60',
          'B32',
          'C22',
          'A12',
          'D58',
          'C31',
          'A13',
          'B60',
          'C4',
          'B24',
          'A17',
          'B7',
          'D28',
          'A4',
          'D51',
          'A45',
          'C17',
          'B10',
          'A1',
          'B57',
          'C1',
          'A43',
          'D35',
          'C28',
          'B50',
          'B1',
          'D41',
          'C26',
          'B53',
          'A60',
          'C36',
          'D15',
          'A58',
          'D42',
          'A37',
          'A8',
          'A52',
          'C18',
          'B55',
          'A10',
          'A49',
          'D36',
          'B15',
          'C12',
          'A16',
          'C20',
          'B4',
          'B48',
          'C55',
          'B18',
          'C50',
          'D39',
          'B11',
          'D26',
          'A30',
          'C3',
          'B26',
          'A15',
          'C21',
          'A39',
          'A24',
          'A40',
          'B14',
          'C15',
          'D7',
          'C11',
          'B56',
          'D29',
          'D14',
          'C49',
          'D1',
          'C45',
          'B23',
          'C42',
          'D18',
          'A56',
          'C17',
          'D3',
          'A8',
          'A59',
          'B51',
          'B32',
          'C21',
          'B44',
          'C3',
          'A38',
          'A2',
          'B19',
          'C59',
          'B43',
          'C53',
          'B33',
          'D43',
          'D11',
          'D42',
          'A49',
          'A10',
          'C19',
          'A39',
          'D24',
          'B13',
          'A18',
          'A46',
          'C15',
          'B20',
          'C55',
          'D9',
          'D32',
          'A51',
          'B14',
          'D58',
          'B29',
          'B55',
          'C47',
          'D5',
          'A56',
          'D55',
          'C60',
          'B10',
          'A21',
          'A43',
          'D60',
          'B57',
          'B28',
          'C11',
          'D8',
          'A18',
          'B17',
          'D52',
          'A48',
          'A12',
          'D42',
          'A55',
          'A3',
          'B16',
          'D33',
          'A2',
          'D46',
          'D10',
          'C19',
          'B13',
          'A7',
          'B15',
          'C60',
          'D47',
          'A23',
          'C15',
          'B21',
          'C50',
          'A53',
          'D58',
          'B3',
          'C58',
          'D57',
          'C35',
          'B54',
          'B30',
          'C2',
          'A39',
          'B56',
          'B4',
          'B58',
          'D29',
          'B28',
          'C6',
          'B47',
          'B32',
          'D6',
          'A43',
          'B34',
          'D30',
          'A9',
          'A50',
          'A6',
          'C19',
          'D1',
          'C18',
          'A29',
          'C29',
          'D22',
          'D42',
          'A59',
          'D17',
          'A57',
          'D45',
          'B38',
          'C44',
          'A53',
          'A5',
          'D12',
          'D38',
          'A36',
          'C11',
          'A55',
          'D34',
          'A20',
          'B10',
          'B56',
          'C47',
          'D16',
          'C39',
          'B4',
          'C9',
          'D1',
          'B5',
          'C25',
          'A8',
          'B50',
          'A3',
          'B41',
          'C26',
          'B59',
          'B8',
          'B46',
          'A9',
          'A50',
          'A6',
          'D48',
          'B18',
          'C10',
          'A46',
          'A13',
          'C22',
          'A32',
          'C5',
          'C34',
          'B36',
          'C33',
          'A28',
          'D9',
          'B7',
          'D59',
          'B57',
          'C35',
          'D8',
          'B8',
          'D27',
          'A6',
          'D10',
          'B2',
          'C16',
          'D3',
          'D19',
          'A1',
          'D34',
          'A45',
          'D53',
          'C59',
          'B23',
          'D58',
          'B30',
          'D41',
          'C60',
          'B16',
          'C56',
          'B45',
          'D49',
          'A60',
          'C24',
          'B15',
          'C17',
          'B19',
          'C42',
          'B39',
          'A33',
          'C4',
          'A16',
          'A47',
          'A15',
          'D5',
          'A24',
          'A40',
          'A23',
          'A58',
          'C43',
          'B1',
          'D40',
          'D25',
          'A25',
          'D42',
          'C54',
          'B20',
          'C12',
          'A35',
          'C23',
          'A8',
          'B6',
          'C25',
          'B41',
          'D30',
          'C30',
          'A45',
          'D24',
          'C53',
          'B47',
          'C5',
          'B29',
          'C50',
          'B58',
          'A16',
          'D44',
          'A54',
          'A4',
          'A60',
          'A2',
          'B1',
          'C41',
          'B22',
          'D42',
          'C26',
          'B60',
          'C3',
          'B28',
          'D59',
          'A57',
          'A3',
          'A58',
          'D37',
          'D1',
          'B34',
          'D43',
          'C24',
          'B16',
          'C12',
          'B35',
          'B9',
          'B57',
          'B41',
          'D21',
          'C45',
          'D2',
          'B8',
          'C47',
          'A54',
          'C23',
          'A30',
          'B27',
          'C9',
          'A55',
          'C18',
          'B13',
          'D22',
          'A41',
          'C1',
          'C17',
          'A59',
          'D55',
          'B16',
          'C53',
          'B3',
          'C40',
          'B58',
          'D21',
          'D45',
          'D10',
          'A22',
          'A45',
          'D50',
          'A19',
          'C20',
          'B57',
          'D34',
          'B17',
          'C56',
          'B35',
          'D30',
          'A14',
          'C29',
          'D33',
          'B48',
          'D51',
          'C57',
          'A53',
          'A5',
          'D14',
          'D29',
          'B30',
          'D8',
          'C34',
          'A25',
          'C19',
          'A42',
          'C11',
          'B16',
          'C15',
          'B6',
          'C60',
          'D7',
          'C14',
          'A31',
          'D10',
          'B3',
          'B60',
          'A12',
          'A48',
          'D60',
          'A54',
          'C21',
          'A40',
          'A18',
          'A46',
          'B38',
          'B4',
          'C38',
          'C1',
          'B33',
          'D5',
          'C7',
          'B27',
          'A14',
          'D38',
          'C27',
          'D35',
          'B13',
          'C25',
          'A11',
          'A49',
          'A10',
          'D2',
          'C9',
          'B18',
          'C3',
          'B45',
          'C46',
          'D20',
          'A20',
          'B56',
          'B35',
          'D43',
          'A57',
          'D16',
          'D47',
          'A7',
          'D3',
          'D30',
          'A31',
          'C29',
          'B10',
          'B50',
          'C56',
          'A53',
          'C34',
          'B15',
          'C58',
          'D17',
          'D52',
          'B19',
          'D50',
          'C60',
          'B11',
          'B55',
          'A2',
          'D38',
          'A48',
          'A12',
          'C13',
          'B13',
          'A16',
          'D37',
          'A4',
          'A54',
          'D58',
          'B54',
          'B39',
          'D24',
          'A22',
          'A45',
          'A21',
          'D48',
          'A1',
          'B2',
          'D42',
          'D11',
          'A52',
          'A8',
          'B11',
          'B49',
          'A58',
          'D13',
          'C42',
          'A48',
          'D31',
          'B32',
          'C14',
          'B36',
          'C32',
          'A28',
          'B29',
          'D10',
          'C18',
          'A44',
          'C46',
          'B58',
          'C36',
          'D55',
          'B18',
          'D53',
          'C57',
          'B34',
          'C47',
          'B57',
          'A38',
          'D39',
          'A57',
          'C26',
          'B25',
          'C8',
          'C27',
          'B54',
          'C48',
          'D14',
          'D29',
          'B8',
          'C5',
          'B9',
          'C19',
          'A50',
          'A6',
          'A52',
          'C10',
          'B60',
          'C46',
          'A54',
          'D35',
          'C52',
          'B3',
          'C50',
          'B39',
          'D45',
          'C24',
          'D12',
          'A51',
          'D60',
          'C60',
          'B12',
          'D39',
          'C27',
          'A39',
          'D41',
          'D13',
          'A25',
          'B52',
          'D32',
          'B16',
          'D58',
          'C28',
          'B51',
          'A13',
          'A42',
          'C3',
          'B29',
          'C52',
          'B30',
          'D7',
          'A57',
          'D35',
          'A17',
          'A40',
          'C13',
          'B58',
          'B26',
          'C45',
          'B35',
          'B50',
          'B3',
          'A18',
          'A46',
          'C30',
          'D4',
          'C16',
          'B10',
          'C28',
          'D9',
          'B13',
          'A5',
          'A53',
          'D47',
          'D7',
          'C10',
          'A50',
          'A9',
          'D42',
          'D22',
          'A10',
          'A49',
          'D35',
          'B15',
          'C57',
          'B19',
          'C40',
          'D18',
          'A58',
          'C55',
          'B23',
          'C54',
          'D48',
          'B45',
          'C21',
          'B1',
          'A2',
          'B7',
          'D52',
          'A13',
          'A47',
          'C1',
          'B34',
          'B58',
          'C20',
          'B5',
          'C38',
          'A26',
          'B50',
          'C3',
          'C36',
          'A16',
          'C11',
          'B20',
          'C5',
          'B43',
          'C25',
          'D42',
          'A23',
          'A40',
          'D5',
          'B6',
          'B28',
          'D11',
          'D26',
          'B12',
          'C58',
          'B17',
          'D57',
          'B54',
          'D55',
          'B1',
          'C39',
          'A24',
          'A41',
          'D4',
          'C49',
          'B39',
          'C41',
          'B23',
          'D43',
          'A19',
          'D36',
          'C28',
          'B53',
          'C36',
          'A37',
          'C22',
          'B33',
          'D3',
          'C45',
          'D14',
          'D53',
          'B40',
          'A33',
          'D23',
          'C29',
          'B15',
          'C16',
          'D11',
          'B2',
          'D59',
          'C20',
          'B6',
          'D27',
          'C15',
          'A11',
          'B48',
          'A5',
          'A59',
          'C6',
          'A26',
          'D7',
          'B31',
          'C52',
          'B56',
          'C51',
          'B20',
          'A16',
          'B24',
          'D29',
          'B59',
          'C25',
          'A14',
          'D51',
          'D14',
          'D49',
          'B7',
          'D3',
          'A53',
          'C33',
          'D6',
          'A29',
          'D47',
          'D19',
          'C39',
          'D60',
          'A55',
          'A3',
          'D12',
          'B27',
          'C32',
          'B29',
          'D9',
          'B3',
          'C49',
          'D39',
          'A1',
          'B5',
          'B47',
          'D52',
          'A49',
          'A10',
          'D55',
          'B9',
          'B37',
          'C57',
          'B43',
          'C18',
          'D2',
          'A2',
          'A57',
          'C60',
          'B7',
          'C20',
          'C4',
          'A40',
          'A17',
          'D31',
          'C30',
          'A28',
          'D19',
          'C55',
          'A60',
          'B55',
          'C41',
          'D10',
          'D32',
          'A41',
          'A14',
          'B53',
          'A29',
          'B27',
          'A10',
          'A49',
          'D40',
          'D25',
          'C31',
          'A22',
          'D36',
          'A50',
          'A9',
          'A51',
          'B46',
          'C50',
          'B56',
          'C15',
          'B7',
          'B60',
          'C39',
          'B3',
          'D57',
          'B29',
          'D56',
          'C58',
          'B13',
          'C10',
          'B35',
          'C27',
          'B4',
          'C22',
          'D23',
          'D45',
          'D7',
          'C59',
          'B24',
          'D58',
          'A1',
          'B2',
          'C54',
          'B41',
          'B8',
          'B58',
          'C1',
          'A50',
          'A6',
          'A52',
          'D10',
          'C56',
          'D17',
          'D51',
          'B49',
          'B4',
          'D25',
          'D40',
          'A13',
          'A47',
          'A15',
          'D46',
          'C24',
          'A51',
          'D31',
          'B36',
          'B55',
          'A1',
          'C4',
          'B16',
          'C10',
          'B41',
          'D47',
          'D19',
          'C48',
          'A53',
          'A5',
          'D49',
          'B34',
          'C53',
          'B17',
          'C43',
          'B20',
          'C30',
          'B9',
          'C47',
          'A36',
          'A19',
          'B58',
          'C35',
          'D15',
          'A34',
          'B38',
          'C19',
          'B54',
          'A3',
          'A55',
          'C42',
          'A58',
          'C26',
          'D44',
          'D20',
          'A23',
          'B51',
          'A4',
          'A54',
          'D14',
          'C33',
          'D31',
          'C28',
          'D37',
          'A15',
          'A48',
          'A12',
          'D9',
          'C59',
          'B10',
          'C15',
          'D59',
          'B21',
          'C23',
          'D47',
          'B18',
          'D40',
          'C27',
          'A9',
          'A60',
          'D44',
          'C55',
          'B24',
          'C43',
          'B38',
          'B23',
          'C5',
          'A37',
          'C2',
          'B31',
          'D41',
          'A48',
          'A12',
          'D53',
          'A19',
          'A36',
          'D51',
          'B50',
          'A24',
          'D12',
          'A56',
          'C56',
          'B22',
          'C31',
          'A30',
          'A45',
          'A21',
          'D43',
          'B58',
          'D34',
          'B15',
          'C13',
          'D4',
          'C45',
          'D22',
          'D42',
          'A40',
          'B55',
          'C53',
          'B6',
          'D7',
          'B41',
          'D29',
          'D14',
          'C44',
          'A54',
          'A4',
          'D54',
          'C21',
          'B2',
          'B53',
          'A11',
          'D43',
          'B36',
          'C31',
          'A48',
          'A12',
          'B37',
          'C47',
          'B59',
          'D20',
          'A39',
          'C26',
          'D58',
          'A56',
          'D4',
          'C6',
          'B7',
          'B43',
          'D56',
          'B23',
          'C23',
          'A57',
          'C35',
          'A19',
          'C9',
          'B28',
          'D18',
          'A60',
          'B34',
          'D48',
          'D6',
          'C32',
          'B12',
        ],
      },
    ],
  },
  {
    title: 'Cat 2',
    id: 'cat-2',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Cat2.%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C1',
          'B20',
          'C2',
          'B21',
          'D60',
          'B18',
          'C3',
          'B22',
          'C6',
          'B24',
          'C4',
          'B25',
          'C7',
          'B27',
          'C9',
          'B28',
          'C8',
          'B23',
          'C5',
          'B19',
          'D58',
          'B15',
          'D57',
          'A21',
          'D27',
          'A20',
          'D25',
          'A17',
          'D26',
          'A18',
          'D24',
          'A16',
          'D59',
          'A19',
          'D23',
          'A15',
          'D52',
          'B12',
          'D56',
          'B17',
          'C1',
          'B22',
          'D60',
          'A22',
          'D28',
          'A23',
          'D33',
          'A26',
          'D34',
          'A21',
          'C2',
          'A18',
          'D27',
          'A16',
          'D22',
          'A13',
          'D20',
          'A12',
          'D21',
          'A17',
          'D54',
          'B15',
          'D53',
          'A14',
          'D24',
          'A19',
          'C4',
          'B20',
          'D57',
          'B21',
          'C8',
          'B24',
          'C3',
          'B19',
          'D60',
          'A18',
          'D30',
          'A25',
          'C16',
          'B29',
          'C15',
          'A58',
          'C14',
          'B33',
          'C29',
          'B56',
          'C43',
          'B53',
          'C41',
          'B54',
          'C25',
          'B38',
          'C18',
          'B44',
          'C38',
          'B45',
          'C19',
          'B46',
          'C27',
          'B31',
          'C22',
          'B49',
          'C21',
          'B28',
          'C7',
          'A20',
          'D58',
          'A23',
          'D15',
          'A13',
          'D32',
          'A25',
          'C4',
          'A22',
          'D6',
          'A8',
          'D7',
          'A21',
          'D55',
          'B24',
          'C2',
          'B25',
          'D56',
          'A16',
          'D51',
          'A18',
          'D3',
          'A4',
          'B60',
          'C30',
          'B35',
          'C29',
          'B57',
          'C28',
          'B33',
          'C12',
          'A56',
          'C13',
          'B31',
          'C21',
          'B32',
          'C27',
          'B47',
          'C20',
          'B5',
          'C19',
          'B12',
          'D55',
          'B25',
          'C6',
          'A21',
          'C1',
          'A23',
          'D35',
          'B18',
          'D34',
          'A24',
          'D29',
          'A19',
          'D2',
          'C32',
          'B38',
          'C31',
          'D3',
          'A3',
          'D4',
          'C52',
          'D42',
          'A28',
          'D38',
          'A18',
          'D36',
          'B17',
          'D59',
          'B14',
          'D58',
          'A16',
          'D19',
          'A43',
          'D18',
          'A10',
          'D9',
          'A20',
          'C3',
          'A17',
          'D3',
          'C30',
          'B36',
          'C16',
          'A60',
          'C15',
          'A25',
          'D37',
          'C45',
          'B51',
          'C36',
          'B26',
          'C11',
          'B23',
          'D60',
          'B28',
          'D59',
          'A23',
          'D57',
          'B22',
          'C9',
          'A21',
          'D13',
          'A19',
          'D25',
          'A15',
          'D34',
          'C42',
          'D35',
          'B17',
          'C31',
          'B59',
          'C47',
          'B56',
          'A2',
          'D47',
          'C59',
          'D45',
          'C56',
          'D43',
          'C51',
          'D40',
          'A27',
          'D33',
          'B18',
          'C32',
          'B45',
          'C54',
          'D14',
          'A22',
          'D26',
          'A3',
          'B60',
          'C29',
          'B43',
          'C39',
          'B21',
          'C34',
          'B41',
          'C16',
          'A21',
          'D35',
          'B19',
          'D56',
          'A24',
          'D33',
          'C43',
          'B48',
          'C27',
          'B59',
          'A5',
          'D10',
          'A22',
          'C2',
          'B16',
          'D37',
          'A17',
          'C1',
          'B10',
          'C17',
          'A59',
          'C16',
          'A27',
          'D34',
          'A43',
          'D51',
          'A34',
          'D49',
          'A60',
          'D35',
          'C41',
          'B48',
          'C26',
          'B53',
          'C59',
          'D1',
          'C58',
          'D48',
          'A32',
          'D46',
          'C60',
          'B58',
          'C46',
          'D9',
          'C43',
          'D10',
          'C17',
          'B2',
          'D44',
          'A29',
          'D35',
          'A12',
          'D30',
          'B17',
          'D34',
          'B1',
          'C18',
          'B3',
          'D37',
          'C41',
          'B41',
          'C33',
          'B21',
          'C10',
          'B26',
          'C35',
          'D5',
          'A22',
          'D54',
          'B24',
          'D53',
          'A17',
          'D28',
          'A21',
          'D6',
          'A6',
          'D9',
          'A1',
          'D27',
          'A19',
          'C5',
          'B20',
          'C3',
          'B23',
          'D57',
          'A15',
          'D17',
          'A42',
          'D19',
          'A44',
          'D20',
          'A45',
          'D21',
          'A46',
          'D22',
          'C58',
          'D46',
          'C55',
          'B50',
          'C21',
          'B14',
          'C20',
          'B6',
          'C15',
          'B8',
          'D43',
          'A30',
          'D40',
          'C49',
          'D1',
          'A11',
          'D34',
          'C46',
          'B55',
          'A7',
          'D5',
          'C32',
          'B21',
          'D56',
          'B23',
          'C22',
          'B11',
          'D60',
          'A25',
          'D39',
          'C53',
          'D14',
          'A39',
          'D47',
          'A56',
          'D36',
          'B16',
          'D58',
          'B20',
          'C35',
          'D33',
          'A43',
          'D52',
          'A21',
          'C5',
          'A27',
          'C15',
          'B36',
          'C23',
          'B9',
          'D43',
          'A49',
          'D24',
          'A26',
          'C17',
          'A24',
          'D15',
          'C56',
          'B48',
          'C25',
          'B39',
          'C39',
          'D35',
          'B16',
          'D31',
          'A19',
          'B60',
          'A12',
          'D58',
          'A21',
          'D8',
          'A28',
          'C4',
          'B13',
          'C33',
          'B59',
          'C54',
          'B46',
          'C38',
          'D36',
          'A16',
          'D55',
          'A38',
          'D54',
          'B25',
          'D53',
          'A45',
          'D37',
          'A29',
          'C16',
          'D11',
          'A7',
          'D15',
          'C52',
          'D39',
          'A59',
          'C14',
          'B36',
          'C1',
          'A28',
          'D34',
          'B19',
          'C38',
          'B37',
          'C49',
          'D41',
          'A20',
          'C10',
          'A54',
          'D45',
          'A1',
          'B57',
          'A17',
          'D5',
          'C31',
          'B34',
          'C17',
          'B15',
          'D52',
          'D35',
          'B3',
          'C16',
          'A52',
          'C8',
          'B41',
          'C7',
          'D1',
          'C33',
          'B22',
          'D51',
          'A22',
          'D4',
          'C51',
          'D15',
          'A40',
          'D48',
          'A13',
          'B59',
          'A21',
          'D25',
          'A48',
          'D23',
          'C30',
          'B42',
          'C17',
          'D14',
          'A9',
          'D55',
          'B14',
          'C26',
          'D18',
          'C60',
          'D27',
          'B16',
          'D57',
          'A40',
          'D32',
          'B18',
          'C6',
          'A18',
          'D35',
          'D51',
          'A35',
          'D17',
          'C46',
          'D36',
          'A26',
          'D41',
          'A47',
          'D21',
          'A7',
          'D2',
          'A11',
          'D59',
          'B8',
          'C24',
          'B51',
          'C21',
          'B4',
          'D34',
          'A42',
          'D50',
          'B41',
          'C5',
          'A22',
          'C16',
          'B7',
          'D47',
          'A57',
          'D36',
          'A10',
          'D54',
          'A24',
          'C1',
          'A15',
          'D19',
          'C59',
          'D21',
          'C48',
          'D42',
          'D27',
          'A3',
          'D13',
          'A33',
          'D17',
          'A32',
          'C16',
          'B30',
          'C39',
          'D30',
          'C33',
          'B39',
          'C31',
          'B18',
          'C19',
          'B32',
          'C11',
          'A53',
          'D34',
          'A22',
          'C18',
          'B45',
          'D54',
          'A30',
          'D8',
          'A35',
          'D9',
          'A27',
          'D58',
          'B8',
          'D47',
          'C55',
          'B56',
          'A15',
          'D5',
          'C10',
          'A55',
          'C16',
          'D15',
          'A21',
          'C8',
          'B39',
          'A8',
          'B52',
          'C48',
          'B37',
          'D46',
          'A30',
          'C7',
          'D2',
          'A5',
          'B34',
          'C12',
          'A19',
          'D26',
          'B16',
          'C17',
          'B35',
          'C27',
          'A37',
          'C22',
          'A42',
          'D33',
          'B17',
          'C36',
          'B27',
          'C42',
          'B40',
          'D51',
          'B2',
          'D36',
          'B15',
          'C25',
          'A49',
          'D22',
          'C53',
          'B57',
          'C26',
          'D20',
          'A10',
          'D10',
          'C41',
          'D38',
          'A31',
          'D45',
          'A3',
          'D30',
          'A57',
          'C16',
          'B9',
          'D42',
          'C57',
          'D49',
          'B41',
          'A8',
          'B54',
          'C59',
          'D28',
          'A5',
          'D35',
          'B25',
          'D52',
          'A33',
          'D34',
          'C43',
          'B28',
          'C20',
          'D13',
          'A39',
          'C30',
          'A40',
          'C6',
          'B39',
          'C3',
          'A23',
          'D9',
          'C60',
          'B33',
          'C27',
          'C12',
          'A47',
          'D23',
          'D38',
          'C38',
          'B20',
          'C34',
          'B51',
          'A9',
          'B40',
          'C24',
          'A46',
          'D55',
          'B21',
          'C5',
          'A48',
          'D41',
          'C58',
          'D24',
          'A21',
          'C17',
          'B38',
          'C9',
          'A29',
          'D15',
          'A34',
          'D12',
          'A6',
          'B55',
          'C42',
          'D36',
          'B18',
          'D31',
          'A26',
          'D59',
          'A60',
          'D42',
          'B33',
          'C46',
          'D18',
          'A23',
          'C16',
          'B42',
          'D49',
          'A55',
          'D37',
          'C53',
          'D47',
          'B12',
          'C32',
          'B22',
          'C4',
          'B57',
          'C25',
          'A50',
          'C15',
          'D11',
          'A37',
          'D56',
          'B13',
          'C22',
          'A39',
          'D46',
          'A51',
          'D21',
          'B9',
          'D53',
          'A18',
          'D1',
          'C29',
          'B31',
          'C39',
          'B32',
          'D43',
          'A21',
          'D60',
          'B33',
          'D41',
          'B6',
          'D48',
          'C52',
          'B43',
          'C37',
          'D7',
          'A7',
          'D55',
          'B44',
          'A11',
          'D35',
          'B26',
          'D52',
          'A44',
          'C32',
          'B13',
          'C3',
          'B57',
          'C45',
          'D9',
          'C18',
          'A54',
          'C8',
          'B60',
          'A22',
          'C11',
          'A19',
          'D34',
          'A55',
          'D45',
          'B3',
          'D18',
          'A14',
          'D25',
          'A4',
          'B50',
          'C54',
          'D6',
          'A26',
          'D23',
          'D38',
          'B15',
          'D32',
          'C44',
          'B47',
          'C57',
          'D44',
          'A52',
          'C26',
          'A35',
          'C1',
          'A29',
          'D53',
          'A38',
          'D31',
          'A2',
          'D3',
          'C11',
          'C30',
          'B55',
          'A16',
          'D54',
          'B46',
          'D52',
          'B16',
          'D25',
          'A57',
          'C12',
          'B43',
          'A8',
          'B56',
          'C38',
          'A56',
          'C18',
          'B6',
          'D39',
          'C50',
          'B36',
          'C27',
          'A34',
          'C9',
          'A51',
          'C35',
          'B27',
          'D35',
          'D51',
          'B3',
          'C48',
          'D43',
          'A52',
          'D20',
          'B9',
          'C15',
          'B30',
          'C23',
          'B10',
          'D36',
          'C54',
          'D13',
          'C18',
          'B54',
          'C52',
          'D16',
          'A41',
          'D49',
          'B1',
          'D59',
          'A42',
          'C21',
          'A44',
          'D35',
          'B28',
          'C42',
          'A50',
          'D23',
          'B12',
          'D46',
          'C56',
          'B49',
          'C14',
          'C29',
          'A40',
          'C22',
          'B48',
          'C16',
          'A26',
          'C6',
          'B19',
          'C36',
          'D7',
          'C10',
          'A24',
          'D47',
          'B36',
          'A1',
          'B32',
          'C59',
          'D10',
          'A30',
          'A54',
          'D54',
          'B14',
          'D28',
          'B15',
          'C21',
          'B22',
          'C40',
          'A53',
          'C26',
          'A37',
          'C1',
          'B54',
          'B39',
          'C2',
          'A14',
          'D33',
          'B1',
          'D58',
          'B10',
          'D35',
          'A40',
          'D56',
          'A47',
          'B8',
          'C50',
          'D16',
          'C16',
          'B51',
          'C14',
          'B30',
          'D44',
          'A4',
          'B34',
          'C11',
          'D4',
          'A31',
          'D42',
          'D27',
          'A10',
          'B42',
          'C47',
          'B43',
          'A7',
          'D8',
          'A2',
          'D54',
          'D33',
          'B19',
          'C19',
          'A21',
          'D26',
          'A49',
          'A32',
          'A52',
          'C6',
          'A41',
          'B8',
          'A30',
          'D60',
          'B7',
          'D44',
          'C47',
          'B4',
          'C16',
          'A33',
          'C10',
          'B23',
          'D55',
          'A15',
          'D6',
          'C38',
          'D28',
          'C58',
          'B32',
          'C18',
          'A23',
          'D13',
          'C14',
          'A27',
          'B9',
          'D41',
          'B34',
          'B56',
          'C46',
          'B50',
          'C37',
          'D36',
          'B24',
          'C13',
          'A50',
          'B6',
          'A31',
          'D5',
          'A21',
          'D14',
          'A54',
          'C40',
          'B23',
          'D51',
          'B26',
          'C8',
          'A35',
          'D42',
          'A46',
          'C55',
          'B11',
          'C51',
          'D49',
          'B5',
          'D57',
          'A36',
          'C49',
          'B2',
          'C19',
          'B20',
          'D52',
          'B39',
          'A10',
          'A53',
          'D21',
          'A6',
          'D34',
          'B13',
          'D29',
          'C34',
          'A48',
          'C43',
          'D12',
          'A1',
          'B52',
          'C14',
          'B6',
          'C2',
          'A27',
          'B12',
          'C23',
          'A31',
          'D9',
          'C35',
          'B28',
          'B56',
          'B37',
          'C5',
          'B42',
          'A9',
          'B53',
          'C40',
          'A55',
          'C27',
          'D17',
          'D39',
          'D18',
          'C25',
          'B29',
          'D55',
          'A4',
          'B59',
          'C3',
          'D47',
          'B43',
          'D56',
          'B11',
          'C24',
          'A49',
          'C43',
          'C58',
          'A39',
          'C49',
          'A47',
          'C44',
          'B50',
          'D54',
          'A56',
          'C37',
          'B18',
          'D59',
          'A43',
          'C9',
          'A53',
          'A9',
          'B12',
          'D35',
          'D51',
          'B28',
          'C17',
          'B45',
          'C47',
          'A39',
          'D17',
          'A60',
          'D41',
          'B36',
          'C22',
          'A58',
          'C18',
          'A25',
          'D21',
          'D36',
          'C36',
          'D8',
          'A36',
          'C28',
          'B16',
          'D24',
          'C29',
          'B32',
          'C13',
          'B52',
          'C56',
          'A45',
          'D22',
          'C31',
          'B10',
          'A11',
          'B11',
          'D53',
          'A57',
          'D16',
          'C47',
          'B5',
          'C16',
          'B37',
          'A1',
          'A38',
          'C59',
          'D23',
          'B14',
          'C11',
          'D6',
          'B45',
          'B28',
          'D45',
          'B29',
          'D35',
          'C55',
          'D11',
          'A27',
          'D43',
          'C54',
          'A46',
          'C45',
          'B5',
          'C43',
          'A52',
          'C41',
          'D4',
          'B46',
          'D51',
          'B11',
          'C18',
          'C2',
          'A59',
          'D50',
          'A19',
          'B58',
          'B26',
          'C6',
          'B31',
          'D44',
          'B8',
          'A29',
          'D7',
          'B33',
          'C39',
          'A55',
          'C19',
          'A22',
          'C8',
          'B29',
          'C40',
          'B24',
          'C28',
          'A48',
          'D57',
          'B10',
          'D41',
          'D15',
          'C54',
          'B60',
          'C6',
          'D1',
          'A12',
          'B9',
          'D59',
          'A14',
          'C59',
          'A44',
          'C57',
          'D2',
          'C37',
          'C13',
          'A59',
          'D48',
          'B42',
          'D7',
          'D23',
          'D38',
          'D18',
          'C45',
          'B58',
          'B33',
          'D35',
          'A28',
          'B8',
          'A13',
          'B46',
          'A57',
          'A30',
          'C50',
          'A46',
          'C3',
          'A26',
          'D50',
          'B21',
          'D58',
          'A34',
          'B4',
          'D15',
          'A6',
          'B56',
          'C30',
          'B9',
          'D37',
          'A56',
          'C36',
          'C15',
          'D12',
          'A20',
          'C9',
          'A55',
          'D55',
          'A25',
          'C7',
          'B38',
          'C60',
          'D20',
          'B14',
          'D40',
          'A47',
          'C48',
          'A51',
          'C42',
          'B9',
          'B59',
          'B25',
          'C37',
          'D28',
          'B17',
          'C23',
          'B24',
          'B60',
          'C28',
          'B34',
          'B51',
          'A4',
          'B35',
          'C2',
          'B57',
          'B27',
          'A27',
          'B14',
          'C32',
          'D30',
          'B12',
          'C9',
          'B23',
          'D8',
          'A25',
          'C33',
          'D21',
          'C43',
          'B8',
          'D42',
          'B32',
          'A40',
          'C58',
          'A15',
          'C51',
          'B1',
          'C17',
          'D10',
          'C34',
          'A56',
          'C27',
          'B13',
          'C16',
          'B54',
          'C40',
          'D27',
          'B15',
          'D34',
          'C1',
          'B2',
          'D55',
          'B48',
          'A5',
          'B49',
          'C47',
          'A48',
          'C26',
          'B21',
          'D52',
          'A58',
          'D24',
          'C25',
          'B9',
          'C24',
          'C9',
          'B1',
          'A30',
          'C43',
          'A54',
          'C42',
          'B11',
          'D36',
          'B3',
          'C17',
          'A56',
          'D57',
          'D34',
          'B16',
          'D54',
          'A37',
          'A3',
          'D56',
          'A21',
          'D10',
          'A4',
          'A35',
          'C50',
          'A16',
          'D17',
          'D1',
          'B23',
          'D49',
          'B11',
          'A10',
          'B45',
          'C26',
          'A38',
          'D45',
          'B4',
          'C48',
          'A53',
          'C7',
          'D3',
          'C6',
          'B40',
          'C35',
          'A47',
          'C29',
          'B7',
          'A14',
          'D19',
          'D34',
          'A56',
          'D26',
          'A2',
          'B31',
          'D8',
          'B10',
          'D54',
          'B51',
          'B28',
          'A26',
          'D2',
          'A4',
          'D53',
          'B17',
          'C17',
          'D57',
          'B8',
          'C47',
          'D33',
          'B29',
          'D9',
          'D39',
          'C37',
          'B44',
          'C31',
          'D31',
          'A41',
          'C49',
          'A50',
          'C24',
          'D18',
          'A1',
          'A43',
          'C58',
          'C32',
          'B49',
          'B34',
          'D6',
          'B11',
          'C47',
          'B12',
          'C18',
          'B46',
          'C17',
          'A52',
          'A31',
          'B7',
          'C14',
          'D14',
          'A8',
          'B13',
          'D46',
          'C54',
          'D21',
          'B8',
          'D1',
          'C28',
          'D16',
          'A59',
          'C39',
          'D27',
          'C59',
          'B58',
          'C55',
          'A45',
          'C51',
          'C34',
          'B17',
          'D32',
          'C3',
          'A29',
          'D10',
          'C16',
          'B55',
          'B29',
          'C22',
          'B2',
          'D13',
          'B28',
          'A16',
          'B6',
          'C1',
          'B15',
          'C11',
          'A23',
          'D38',
          'D54',
          'A31',
          'D16',
          'C40',
          'A58',
          'B44',
          'A7',
          'B47',
          'D51',
          'D35',
          'B14',
          'D29',
          'C58',
          'D39',
          'A50',
          'A35',
          'A2',
          'A37',
          'C10',
          'A28',
          'B10',
          'C56',
          'C33',
          'A43',
          'B54',
          'A40',
          'D38',
          'D10',
          'B22',
          'C39',
          'D4',
          'B48',
          'C49',
          'D11',
          'C44',
          'A51',
          'C24',
          'B41',
          'A12',
          'B42',
          'C4',
          'A52',
          'D27',
          'B17',
          'D44',
          'A48',
          'C45',
          'D2',
          'C12',
          'B22',
          'D56',
          'B45',
          'D53',
          'A39',
          'C9',
          'B6',
          'A43',
          'C52',
          'B44',
          'B29',
          'A25',
          'C32',
          'C16',
          'D18',
          'D38',
          'C40',
          'C13',
          'A52',
          'D48',
          'B11',
          'C21',
          'D7',
          'B24',
          'D40',
          'C36',
          'B30',
          'A3',
          'B55',
          'C59',
          'A38',
          'C50',
          'B38',
          'C38',
          'A59',
          'D60',
          'B39',
          'C28',
          'A58',
          'C7',
          'B23',
          'C32',
          'A46',
          'C49',
          'D20',
          'B46',
          'D47',
          'C47',
          'B13',
          'C43',
          'B50',
          'C17',
          'B32',
          'C6',
          'D45',
          'B14',
          'D19',
          'D34',
          'A60',
          'C20',
          'B44',
          'D21',
          'C26',
          'B40',
          'A27',
          'B16',
          'A10',
          'C52',
          'A44',
          'D51',
          'B51',
          'D18',
          'B56',
          'C5',
          'A24',
          'C16',
          'B14',
          'D39',
          'B25',
          'D14',
          'B1',
          'C50',
          'A18',
          'B27',
          'C6',
          'B41',
          'A59',
          'D56',
          'A12',
          'D24',
          'A48',
          'C25',
          'B58',
          'A14',
          'B29',
          'C32',
          'A54',
          'C41',
          'D15',
          'D40',
          'A58',
          'A29',
          'C51',
          'A33',
          'B5',
          'C22',
          'B9',
          'D9',
          'A34',
          'D50',
          'A60',
          'D38',
          'D23',
          'C49',
          'B38',
          'C48',
          'B7',
          'D59',
          'A30',
          'B26',
          'A28',
          'C18',
          'B49',
          'D55',
          'C20',
          'D18',
          'A44',
          'C46',
          'B59',
          'D37',
          'C42',
          'D12',
          'B29',
          'D43',
          'B31',
          'B54',
          'C32',
          'D7',
          'C44',
          'A53',
          'D58',
          'C15',
          'A57',
          'C33',
          'B17',
          'C11',
          'A20',
          'D14',
          'B27',
          'C56',
          'A15',
          'B6',
          'D31',
          'C5',
          'D3',
          'A1',
          'B47',
          'C50',
          'A45',
          'C46',
          'A47',
          'C27',
          'D12',
          'C21',
          'B33',
          'C13',
          'B13',
          'D55',
          'A30',
          'D39',
          'A27',
          'D1',
          'C43',
          'D35',
          'B34',
          'D5',
          'B36',
          'A16',
          'C55',
          'B28',
          'D50',
          'B22',
          'D15',
          'C14',
          'C39',
          'A54',
          'D46',
          'A25',
          'C30',
          'A46',
          'B53',
          'A56',
          'D23',
          'B16',
          'A8',
          'B42',
          'D57',
          'A58',
          'D51',
          'A7',
          'A42',
          'A2',
          'D11',
          'D37',
          'A48',
          'C44',
          'D33',
          'B4',
          'C12',
          'C41',
          'B28',
          'A15',
          'D58',
          'A43',
          'C47',
          'B52',
          'C60',
          'D44',
          'B42',
          'C3',
          'A47',
          'D45',
          'B6',
          'D16',
          'B21',
          'C9',
          'A44',
          'C57',
          'D14',
          'B57',
          'C1',
          'B20',
          'C33',
          'D4',
          'D39',
          'C40',
          'D3',
          'A22',
          'D55',
          'C58',
          'A41',
          'D34',
          'D19',
          'B4',
          'A48',
          'B38',
          'A11',
          'D60',
          'A29',
          'C8',
          'A55',
          'C38',
          'D12',
          'B32',
          'D8',
          'C50',
          'A19',
          'B26',
          'D46',
          'B3',
          'B51',
          'D50',
          'B2',
          'D38',
          'B8',
          'C45',
          'A50',
          'D21',
          'A14',
          'B37',
          'D41',
          'B21',
          'C19',
          'C36',
          'D28',
          'C35',
          'B50',
          'A47',
          'C23',
          'A59',
          'C28',
          'B30',
          'D9',
          'A11',
          'D44',
          'C16',
          'B56',
          'C58',
          'A43',
          'D54',
          'A46',
          'B58',
          'B10',
          'D20',
          'A18',
          'D39',
          'D5',
          'A33',
          'A5',
          'D52',
          'B27',
          'A17',
          'D10',
          'B28',
          'C37',
          'A55',
          'C18',
          'D55',
          'C54',
          'A52',
          'C42',
          'B13',
          'D30',
          'B18',
          'C30',
          'B20',
          'C47',
          'C14',
          'D16',
          'B14',
          'C4',
          'A39',
          'A23',
          'B10',
          'C22',
          'B30',
          'A13',
          'D23',
          'C26',
          'A54',
          'D35',
          'D11',
          'C28',
          'A33',
          'C50',
          'A40',
          'C19',
          'B38',
          'B53',
          'B32',
          'D36',
          'A48',
          'B48',
          'C39',
          'D5',
          'B42',
          'C48',
          'A42',
          'D60',
          'D44',
          'B28',
          'C34',
          'A24',
          'C8',
          'B12',
          'C7',
          'B5',
          'D32',
          'C2',
          'B27',
          'C44',
          'B4',
          'C23',
          'A60',
          'C13',
          'B36',
          'A49',
          'C14',
          'D18',
          'C41',
          'A52',
          'D52',
          'A59',
          'C37',
          'D6',
          'C51',
          'A11',
          'B41',
          'D23',
          'B43',
          'C46',
          'A19',
          'C44',
          'B12',
          'D41',
          'C35',
          'C16',
          'A42',
          'A23',
          'B16',
          'D33',
          'B30',
          'D54',
          'B23',
          'D15',
          'C29',
          'A26',
          'D7',
          'B32',
          'D49',
          'A8',
          'B46',
          'A6',
          'D1',
          'C42',
          'D14',
          'C17',
          'B53',
          'A44',
          'C56',
          'D2',
          'B7',
          'D45',
          'A30',
          'B6',
          'C49',
          'A52',
          'C12',
          'B9',
          'A24',
          'D52',
          'B1',
          'D43',
          'A53',
          'C41',
          'D21',
          'C32',
          'A57',
          'C1',
          'B16',
          'D29',
          'C6',
          'A20',
          'C17',
          'D42',
          'B35',
          'C36',
          'A46',
          'C50',
          'A49',
          'C46',
          'B46',
          'D32',
          'B2',
          'D34',
          'D56',
          'A60',
          'D48',
          'B5',
          'D17',
          'B20',
          'D59',
          'A48',
          'C23',
          'A38',
          'C53',
          'A17',
          'C52',
          'A28',
          'B11',
          'D31',
          'B48',
          'D20',
          'A32',
          'B25',
          'D13',
          'A27',
          'B54',
          'C47',
          'B57',
          'A8',
          'C2',
          'A20',
          'B56',
          'C27',
          'D19',
          'D34',
          'C33',
          'D10',
          'B8',
          'C41',
          'B13',
          'D41',
          'B39',
          'C43',
          'A50',
          'C7',
          'C26',
          'D25',
          'C25',
          'D26',
          'B17',
          'D52',
          'A17',
          'C47',
          'B60',
          'B45',
          'A9',
          'B37',
          'A30',
          'C24',
          'B18',
          'D45',
          'C5',
          'B12',
          'C22',
          'D54',
          'A57',
          'C29',
          'B8',
          'C37',
          'B16',
          'C35',
          'A55',
          'D58',
          'B22',
          'D2',
          'C36',
          'A19',
          'C10',
          'A38',
          'C48',
          'A54',
          'C38',
          'D27',
          'B21',
          'C7',
          'B29',
          'D39',
          'D6',
          'C31',
          'A24',
          'D21',
          'C60',
          'A37',
          'D18',
          'B1',
          'D42',
          'C8',
          'A56',
          'D15',
          'B24',
          'D49',
          'A27',
          'D26',
          'B39',
          'A28',
          'C14',
          'A51',
          'C45',
          'B11',
          'C10',
          'D8',
          'C19',
          'B44',
          'D57',
          'B12',
          'D48',
          'B28',
          'C31',
          'B35',
          'A50',
          'A34',
          'C51',
          'A37',
          'B54',
          'C17',
          'B4',
          'D50',
          'B40',
          'D25',
          'A5',
          'D15',
          'B51',
          'D7',
          'A6',
          'B58',
          'A22',
          'A43',
          'C16',
          'B10',
          'C48',
          'A46',
          'C47',
          'D8',
          'B18',
          'C23',
          'B1',
          'C13',
          'D57',
          'B24',
          'A33',
          'A3',
          'A18',
          'C37',
          'B19',
          'C35',
          'A56',
          'C33',
          'B50',
          'D20',
          'C44',
          'D9',
          'A5',
          'D36',
          'D15',
          'B6',
          'C43',
          'A51',
          'D29',
          'B3',
          'C49',
          'D32',
          'D59',
          'A44',
          'C51',
          'A45',
          'C9',
          'D28',
          'C7',
          'B17',
          'B36',
          'D1',
          'C16',
          'B28',
          'D56',
          'D40',
          'B37',
          'D27',
          'A55',
          'C41',
          'D23',
          'A20',
          'B40',
          'A13',
          'B32',
          'D48',
          'C46',
          'A47',
          'B52',
          'C42',
          'C11',
          'B10',
          'C6',
          'B36',
          'A32',
          'C52',
          'A7',
          'B54',
          'D54',
          'D3',
          'D40',
          'A25',
          'C3',
          'B5',
          'C21',
          'B23',
          'D41',
          'B28',
          'D34',
          'A44',
          'C16',
          'A56',
          'B47',
          'C42',
          'D9',
          'B41',
          'A10',
          'A41',
          'C28',
          'D17',
          'C56',
          'D53',
          'B14',
          'D37',
          'B15',
          'C44',
          'A49',
          'D58',
          'A59',
          'C8',
          'B1',
          'B40',
          'D24',
          'B13',
          'C2',
          'A32',
          'B5',
          'D42',
          'C36',
          'A54',
          'C19',
          'C34',
          'B19',
          'D18',
          'C13',
          'A18',
          'D4',
          'A16',
          'C38',
          'C15',
          'A26',
          'B41',
          'D60',
          'B16',
          'C26',
          'A41',
          'C57',
          'D45',
          'B38',
          'C5',
          'A56',
          'D53',
          'A23',
          'B25',
          'D6',
          'B33',
          'D33',
          'B42',
          'B57',
          'C38',
          'D39',
          'A32',
          'D44',
          'B11',
          'D42',
          'A2',
          'A42',
          'C53',
          'B59',
          'C24',
          'D26',
          'A4',
          'A36',
          'B3',
          'C30',
          'D14',
          'B14',
          'A24',
          'C19',
          'B27',
          'D51',
          'A59',
          'C33',
          'A19',
          'B49',
          'A1',
          'D60',
          'B57',
          'D13',
          'B31',
          'A23',
          'C23',
          'A41',
          'B53',
          'C60',
          'A42',
          'D38',
          'B12',
          'C43',
          'A53',
          'C39',
          'B34',
          'B19',
          'C14',
          'B8',
          'C26',
          'B22',
          'D26',
          'C41',
          'D49',
          'C5',
          'B26',
          'D57',
          'B18',
          'D51',
          'B31',
          'D39',
          'D7',
          'C58',
          'B57',
          'D50',
          'B10',
          'A14',
          'C54',
          'D17',
          'C42',
          'A53',
          'C6',
          'D30',
          'B36',
          'A15',
          'D16',
          'C57',
          'B9',
          'A29',
          'B38',
          'C4',
          'D31',
          'C50',
          'B11',
          'C20',
          'A42',
          'C10',
          'A52',
          'C45',
          'A47',
          'C8',
          'B20',
          'D42',
          'A20',
          'B48',
          'A3',
          'A40',
          'C47',
          'B32',
          'C16',
          'B35',
          'C7',
          'D18',
          'B14',
          'C41',
          'C14',
          'A57',
          'C27',
          'D22',
          'B43',
          'D5',
          'C45',
          'B46',
          'D44',
          'A26',
          'B54',
          'C13',
          'D27',
          'B38',
          'C1',
          'A58',
          'B45',
          'D21',
          'A54',
          'D59',
          'B39',
          'D40',
          'C37',
          'D12',
          'B40',
          'C60',
          'A43',
          'C47',
          'A18',
          'B22',
          'D16',
          'A24',
          'B15',
          'C20',
          'A21',
          'D39',
          'D24',
          'B42',
          'C53',
          'B60',
          'C34',
          'A55',
          'D43',
          'C7',
          'B24',
          'D14',
          'B30',
          'C4',
          'B56',
          'C12',
          'B16',
          'A22',
          'B26',
          'C57',
          'A51',
          'C29',
          'A60',
          'C18',
          'C38',
          'A17',
          'B35',
          'D48',
          'B58',
          'D41',
          'C21',
          'B9',
          'C2',
          'A36',
          'A1',
          'B20',
          'D51',
          'B1',
          'D39',
          'D9',
          'C10',
          'A26',
          'B12',
          'C42',
          'D22',
          'C52',
          'B45',
          'A7',
          'B57',
          'D49',
          'B2',
          'D30',
          'C38',
          'D35',
          'B15',
          'C16',
          'A35',
          'A56',
          'C31',
          'A32',
          'C11',
          'B22',
          'C8',
          'B4',
          'D18',
          'A34',
          'A5',
          'D16',
          'D36',
          'B25',
          'A31',
          'C17',
          'C58',
          'A50',
          'C46',
          'B7',
          'A30',
          'D6',
          'A14',
          'C39',
          'B54',
          'A17',
          'C34',
          'B14',
          'C9',
          'A46',
          'C47',
          'D17',
          'B21',
          'C1',
          'B32',
          'C57',
          'D26',
          'C52',
          'B10',
          'C25',
          'D40',
          'B28',
          'C19',
          'B47',
          'D56',
          'A54',
          'C17',
          'B12',
          'D43',
          'C35',
          'A52',
          'C40',
          'D29',
          'B36',
          'D28',
          'B3',
          'C26',
          'B26',
          'D15',
          'B53',
          'A28',
          'C29',
          'B1',
          'C4',
          'B17',
          'D31',
          'B30',
          'A24',
          'D51',
          'C23',
          'B8',
          'D54',
          'A59',
          'C32',
          'A19',
          'B31',
          'D50',
          'B23',
          'D47',
          'B5',
          'A32',
          'A56',
          'C10',
          'D27',
          'C24',
          'B3',
          'D55',
          'B22',
          'A1',
          'D19',
          'C45',
          'D26',
          'B39',
          'D14',
          'A2',
          'B16',
          'B38',
          'C29',
          'D10',
          'A3',
          'A41',
          'C22',
          'B21',
          'C47',
          'B34',
          'D31',
          'B35',
          'C57',
          'A12',
          'C48',
          'A15',
          'C39',
          'A58',
          'C27',
          'D24',
          'A8',
          'A31',
          'A6',
          'D8',
          'B41',
          'D58',
          'D32',
          'B29',
          'C43',
          'D11',
          'B40',
          'C40',
          'D34',
          'D55',
          'C16',
          'D45',
          'B26',
          'D42',
          'B7',
          'C58',
          'A43',
          'C53',
          'A60',
          'A35',
          'B3',
          'C22',
          'D40',
          'B58',
          'C32',
          'D36',
          'A30',
          'B48',
          'D4',
          'A32',
          'C29',
          'B44',
          'C48',
          'B19',
          'C13',
          'B15',
          'A11',
          'B52',
          'C34',
          'A18',
          'B33',
          'D39',
          'B7',
          'C3',
          'A50',
          'C44',
          'B51',
          'C19',
          'D41',
          'A29',
          'B25',
          'D49',
          'A25',
          'B13',
          'D21',
          'B47',
          'A49',
          'C47',
          'D24',
          'B3',
          'C15',
          'B5',
          'D46',
          'C14',
          'B11',
          'D58',
          'B2',
          'C50',
          'A45',
          'C49',
          'B51',
          'A19',
          'C30',
          'A56',
          'C3',
          'B30',
          'D40',
          'D3',
          'B6',
          'D26',
          'D11',
          'C39',
          'D23',
          'B19',
          'D53',
          'A60',
          'C52',
          'A44',
          'A59',
          'C27',
          'A36',
          'C11',
          'A54',
          'C35',
          'A16',
          'B57',
          'C43',
          'B33',
          'D32',
          'A26',
          'D10',
          'A9',
          'D6',
          'C16',
          'A45',
          'C4',
          'B4',
          'D51',
          'D35',
          'B20',
          'D56',
          'C57',
          'B37',
          'A13',
          'C40',
          'B47',
          'D47',
          'B25',
          'C12',
          'A18',
          'B34',
          'C10',
          'A60',
          'C27',
          'A23',
          'C17',
          'D43',
          'B4',
          'A34',
          'B23',
          'D16',
          'D31',
          'C38',
          'A50',
          'C36',
          'D17',
          'B48',
          'D51',
          'B54',
          'D20',
          'C55',
          'A41',
          'D60',
          'B17',
          'A22',
          'B31',
          'C22',
          'B14',
          'D57',
          'A19',
          'B32',
          'D34',
          'B26',
          'D11',
          'B33',
          'C11',
          'B16',
          'C8',
          'B37',
          'D28',
          'C24',
          'A36',
          'A4',
          'D17',
          'C21',
          'A51',
          'C19',
          'A42',
          'C59',
          'D15',
          'B38',
          'A9',
          'B55',
          'C6',
          'A54',
          'C31',
          'B19',
          'C2',
          'B10',
          'C46',
          'A46',
          'D44',
          'B32',
          'C15',
          'B50',
          'C22',
          'B43',
          'A12',
          'C42',
          'A51',
          'C7',
          'B6',
          'C17',
          'A25',
          'A40',
          'A1',
          'D55',
          'B45',
          'D48',
          'B20',
          'D18',
          'C28',
          'A46',
          'D20',
          'C16',
          'B1',
          'D35',
          'B21',
          'D59',
          'B43',
          'D3',
          'C29',
          'A35',
          'B22',
          'D52',
          'A57',
          'C41',
          'B15',
          'C34',
          'A58',
          'C21',
          'A59',
          'C19',
          'B31',
          'C40',
          'A46',
          'D58',
          'B13',
          'D38',
          'B58',
          'D14',
          'A19',
          'C29',
          'D16',
          'B5',
          'C12',
          'A31',
          'C54',
          'A41',
          'D53',
          'B16',
          'C25',
          'A29',
          'A11',
          'D18',
          'C6',
          'B11',
          'C45',
          'D8',
          'A18',
          'C33',
          'B53',
          'C48',
          'A45',
          'D46',
          'B57',
          'D42',
          'B12',
          'D22',
          'B38',
          'D5',
          'A27',
          'C30',
          'B34',
          'D49',
          'C5',
          'A57',
          'C35',
          'D24',
          'D39',
          'C26',
          'B4',
          'D4',
          'A56',
          'D55',
          'B26',
          'B57',
          'B11',
          'A28',
          'B27',
          'D42',
          'B36',
          'D25',
          'B39',
          'D13',
          'A6',
          'B47',
          'A32',
          'D32',
          'A1',
          'C30',
          'A59',
          'B43',
          'A10',
          'C55',
          'A51',
          'C28',
          'D23',
          'C16',
          'D26',
          'C14',
          'D58',
          'A54',
          'A38',
          'A4',
          'A39',
          'C8',
          'D27',
          'B5',
          'D34',
          'B8',
          'C3',
          'B34',
          'D30',
          'A48',
          'C51',
          'D7',
          'C31',
          'A25',
          'D35',
          'C47',
          'B22',
          'C18',
          'B2',
          'D52',
          'B30',
          'A27',
          'C13',
          'B3',
          'C5',
          'A34',
          'C25',
          'A52',
          'C44',
          'B54',
          'D50',
          'B55',
          'C37',
          'A53',
          'C24',
          'B19',
          'C20',
          'A23',
          'C32',
          'A31',
          'D33',
          'B26',
          'D53',
          'B10',
          'D45',
          'B23',
          'C14',
          'A44',
          'C52',
          'A14',
          'C36',
          'A15',
          'B31',
          'D37',
          'B17',
          'D58',
          'B43',
          'D22',
          'C50',
          'B49',
          'C55',
          'B44',
          'A8',
          'D11',
          'B54',
          'C31',
          'A57',
          'C24',
          'A37',
          'D60',
          'A52',
          'C38',
          'D1',
          'D41',
          'C18',
          'B9',
          'A29',
          'A10',
          'B31',
          'D32',
          'D57',
          'A59',
          'D40',
          'B5',
          'B21',
          'C20',
          'B1',
          'D56',
          'B24',
          'C41',
          'B52',
          'C11',
          'D7',
          'D38',
          'D10',
          'B40',
          'A18',
          'B17',
          'A2',
          'B46',
          'C52',
          'D37',
          'D12',
          'C19',
          'A60',
          'D47',
          'B48',
          'C57',
          'A43',
          'C53',
          'D5',
          'A16',
          'B27',
          'D45',
          'B30',
          'A23',
          'B14',
          'C45',
          'A49',
          'C39',
          'D15',
          'C17',
          'D17',
          'C50',
          'B50',
          'D30',
          'B35',
          'D60',
          'A47',
          'D53',
          'B20',
          'C59',
          'A39',
          'C23',
          'B25',
          'C9',
          'B15',
          'C27',
          'A51',
          'C46',
          'B60',
          'B8',
          'D26',
          'B33',
          'D43',
          'B34',
          'C8',
          'A48',
          'C49',
          'B9',
          'C1',
          'B11',
          'D24',
          'B37',
          'A12',
          'C41',
          'B25',
          'C34',
          'A53',
          'C33',
          'D35',
          'B35',
          'D29',
          'A4',
          'D54',
          'A21',
          'B18',
          'C17',
          'B29',
          'D53',
          'B50',
          'D25',
          'B41',
          'D7',
          'C30',
          'B57',
          'C57',
          'A45',
          'C10',
          'D41',
          'C37',
          'B31',
          'A1',
          'A41',
          'C21',
          'B27',
          'C5',
          'A58',
          'C31',
          'A26',
          'B9',
          'C29',
          'B10',
          'C15',
          'B39',
          'D51',
          'B33',
          'C58',
          'B8',
          'A30',
          'C30',
          'D9',
          'C22',
          'D11',
          'A23',
          'A6',
          'A32',
          'B6',
          'C4',
          'B18',
          'D56',
          'A55',
          'C32',
          'A20',
          'B25',
          'C1',
          'D45',
          'B57',
          'B42',
          'D23',
          'C48',
          'A48',
          'C46',
          'D25',
          'A52',
          'C7',
          'D28',
          'A54',
          'C23',
          'A57',
          'B52',
          'A10',
          'C43',
          'C10',
          'B24',
          'A24',
          'C36',
          'B8',
          'C32',
          'D2',
          'D41',
          'B29',
          'D15',
          'B13',
          'A28',
          'C2',
          'B40',
          'D40',
          'C24',
          'A22',
          'B39',
          'C57',
          'B59',
          'C55',
          'D3',
          'C27',
          'B20',
          'C58',
          'B21',
          'D51',
          'D35',
          'D20',
          'B18',
          'A2',
          'B19',
          'C7',
          'D43',
          'B25',
          'B1',
          'A38',
          'C9',
          'A23',
          'B15',
          'A3',
          'B7',
          'D55',
          'B28',
          'D33',
          'C34',
          'B37',
          'C33',
          'A45',
          'C2',
          'D18',
          'C56',
          'D57',
          'B27',
          'C20',
          'B9',
          'D46',
          'B22',
          'C58',
          'B25',
          'C40',
          'A48',
          'D42',
          'B38',
          'D27',
          'C45',
          'B49',
          'D16',
          'C51',
          'A44',
          'C5',
          'B15',
          'D21',
          'C29',
          'B14',
          'C18',
          'A42',
          'C59',
          'A49',
          'C8',
          'B54',
          'D10',
          'C50',
          'A47',
          'B57',
          'C19',
          'B4',
          'D49',
          'C24',
          'D36',
          'C30',
          'A50',
          'D51',
          'A54',
          'D33',
          'B12',
          'C14',
          'B24',
          'C5',
          'B29',
          'A28',
          'C28',
          'A35',
          'A6',
          'A37',
          'C60',
          'B46',
          'D18',
          'D54',
          'C21',
          'A57',
          'D46',
          'B6',
          'C30',
          'B37',
          'D16',
          'A55',
          'C43',
          'A20',
          'D3',
          'B47',
          'C58',
          'D15',
          'C53',
          'D6',
          'A7',
          'D12',
          'C15',
          'A43',
          'C22',
          'B16',
          'D32',
          'B34',
          'D39',
          'C45',
          'D31',
          'D16',
          'C37',
          'A13',
          'B33',
          'D50',
          'B1',
          'D47',
          'B55',
          'A26',
          'B14',
          'A4',
          'A39',
          'C27',
          'B49',
          'A48',
          'C32',
          'B52',
          'C19',
          'B13',
          'C12',
          'D27',
          'A23',
          'B38',
          'C3',
          'A58',
          'C20',
          'D40',
          'D4',
          'A11',
          'A34',
          'A8',
          'B53',
          'D14',
          'A6',
          'C44',
          'D34',
          'B3',
          'A44',
          'D53',
          'B30',
          'A20',
          'C29',
          'D31',
          'B31',
          'C18',
          'B4',
          'A33',
          'C11',
          'D18',
          'C50',
          'B23',
          'C42',
          'A56',
          'C28',
          'B47',
          'D32',
          'C48',
          'A16',
          'C35',
          'A17',
          'B44',
          'D22',
          'C49',
          'B35',
          'D26',
          'C40',
          'B27',
          'C1',
          'B40',
          'D39',
          'B10',
          'A29',
          'B26',
          'C4',
          'D43',
          'B16',
          'C44',
          'B60',
          'C17',
          'B19',
          'A21',
          'D1',
          'A25',
          'B14',
          'D33',
          'A12',
          'B47',
          'C38',
          'B27',
          'D49',
          'A58',
          'D60',
          'B42',
          'A18',
          'B26',
          'C8',
          'A42',
          'C54',
          'A53',
          'C45',
          'D48',
          'B33',
          'C56',
          'B53',
          'D55',
          'C15',
          'A51',
          'C2',
          'B15',
          'D19',
          'C50',
          'C14',
          'D42',
          'B57',
          'D13',
          'C55',
          'B22',
          'D9',
          'A16',
          'B45',
          'D52',
          'C23',
          'A49',
          'B37',
          'C27',
          'D38',
          'C59',
          'A40',
          'C24',
          'B10',
          'A12',
          'B49',
          'D31',
          'A7',
          'D35',
          'A48',
          'C16',
          'B21',
          'D11',
          'A57',
          'C8',
          'B5',
          'A31',
          'B7',
          'A30',
          'C18',
          'D9',
          'B31',
          'C60',
          'B56',
          'D47',
          'B28',
          'D54',
          'B13',
          'A5',
          'B58',
          'D30',
          'C6',
          'A55',
          'D24',
          'C26',
          'A56',
          'C40',
          'C24',
          'D42',
          'C58',
          'B34',
          'C35',
          'D18',
          'A22',
          'C22',
          'A50',
          'B46',
          'C51',
          'D9',
          'C41',
          'A45',
          'C56',
          'B40',
          'C15',
          'D24',
          'B41',
          'C54',
          'B56',
          'C40',
          'D10',
          'C26',
          'B49',
          'C42',
          'B7',
          'C27',
          'D4',
          'A8',
          'B17',
          'B45',
          'A15',
          'B9',
          'C7',
          'B54',
          'A12',
          'B14',
          'C12',
          'D56',
          'B44',
          'A27',
          'B11',
          'D34',
          'C39',
          'D37',
          'C28',
          'A20',
          'B41',
          'D35',
          'B30',
          'A2',
          'A39',
          'C25',
          'A35',
          'B1',
          'B37',
          'C23',
          'B38',
          'C16',
          'B2',
          'D42',
          'C10',
          'D10',
          'C60',
          'B18',
          'D55',
          'B9',
          'D25',
          'A16',
          'B29',
          'D38',
          'D18',
          'B47',
          'C46',
          'B3',
          'A35',
          'A5',
          'D45',
          'B15',
          'A9',
          'B43',
          'C53',
          'D7',
          'A4',
          'D36',
          'C29',
          'A33',
          'B4',
          'D56',
          'A20',
          'B19',
          'D19',
          'D34',
          'A45',
          'D59',
          'A58',
          'A37',
          'C11',
          'B40',
          'A60',
          'C21',
          'B10',
          'A29',
          'A9',
          'C49',
          'D24',
          'B52',
          'D17',
          'C51',
          'D30',
          'B21',
          'D48',
          'C26',
          'A28',
          'C12',
          'B19',
          'D32',
          'C36',
          'A27',
          'B13',
          'D36',
          'C43',
          'A11',
          'B30',
          'D51',
          'B50',
          'C20',
          'B39',
          'A1',
          'B55',
          'C41',
          'B18',
          'D54',
          'C10',
          'B3',
          'D58',
          'B23',
          'A25',
          'D23',
          'C36',
          'A53',
          'C13',
          'D1',
          'A17',
          'C31',
          'C59',
          'B31',
          'D27',
          'B7',
          'C25',
          'D39',
          'C23',
          'D53',
          'A31',
          'C30',
          'B58',
          'C56',
          'A47',
          'C55',
          'B35',
          'D6',
          'B41',
          'D34',
          'A38',
          'A55',
          'C48',
          'B46',
          'C44',
          'D8',
          'A1',
          'B24',
          'A21',
          'B39',
          'D48',
          'C42',
          'D20',
          'B13',
          'C24',
          'D50',
          'A56',
          'C25',
          'B8',
          'A30',
          'B51',
          'D47',
          'B4',
          'D36',
          'A22',
          'B17',
          'A4',
          'B44',
          'D5',
          'A8',
          'B31',
          'C16',
          'D51',
          'C41',
          'B26',
          'C30',
          'D15',
          'B20',
          'D12',
          'C57',
          'D29',
          'A20',
          'C1',
          'A39',
          'D28',
          'A51',
          'C50',
          'D47',
          'B11',
          'D55',
          'B49',
          'D5',
          'A5',
          'D6',
          'C46',
          'B4',
          'C39',
          'B36',
          'C58',
          'A44',
          'C47',
          'B35',
          'D43',
          'B51',
          'C15',
          'C40',
          'D22',
          'C29',
          'B2',
          'A38',
          'C31',
          'D35',
          'D12',
          'C10',
          'B40',
          'D26',
          'D11',
          'C41',
          'B33',
          'A23',
          'B15',
          'B30',
          'A16',
          'C4',
          'B19',
          'A17',
          'B26',
          'C3',
          'A19',
          'D3',
          'A12',
          'B27',
          'D56',
          'C20',
          'B10',
          'D59',
          'B40',
          'D36',
          'A37',
          'C60',
          'D11',
          'C25',
          'B2',
          'C48',
          'C32',
          'A49',
          'D57',
          'A53',
          'D52',
          'B19',
          'D31',
          'C37',
          'B36',
          'A14',
          'B45',
          'D21',
          'C53',
          'A43',
          'C18',
          'B16',
          'A23',
          'D55',
          'B4',
          'D28',
          'B20',
          'C17',
          'D22',
          'A56',
          'C9',
          'B40',
          'C55',
          'D14',
          'C52',
          'A47',
          'C41',
          'B49',
          'C35',
          'B12',
          'A29',
          'A7',
          'C7',
          'A43',
          'A60',
          'D60',
          'D28',
          'A11',
          'B33',
          'D44',
          'B23',
          'D7',
          'A3',
          'A36',
          'D6',
          'B26',
          'A21',
          'C42',
          'A44',
          'B59',
          'C18',
          'D13',
          'C38',
          'B7',
          'D15',
          'C8',
          'D17',
          'B22',
          'C24',
          'B35',
          'C45',
          'A55',
          'C31',
          'A18',
          'B43',
          'D33',
          'B45',
          'D19',
          'C43',
          'B46',
          'A7',
          'C51',
          'D46',
          'B28',
          'D42',
          'D58',
          'A48',
          'C27',
          'B1',
          'D37',
          'C26',
          'D38',
          'A47',
          'C33',
          'D6',
          'C59',
          'A42',
          'B60',
          'A56',
          'D31',
          'C4',
          'A46',
          'C1',
        ],
      },
    ],
  },

  {
    title: 'John F Kennedy',
    id: 'john-f-kennedy',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/John%20F%20Kennedy.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C16',
          'C32',
          'C11',
          'C27',
          'C42',
          'C10',
          'C34',
          'C6',
          'C28',
          'C13',
          'C40',
          'C20',
          'D58',
          'C14',
          'C38',
          'C22',
          'D57',
          'C17',
          'C41',
          'C12',
          'C31',
          'C2',
          'C35',
          'C5',
          'C39',
          'C1',
          'C36',
          'A32',
          'A17',
          'A34',
          'A16',
          'A35',
          'A15',
          'C32',
          'C7',
          'C27',
          'C3',
          'C42',
          'C11',
          'D54',
          'C10',
          'C43',
          'C2',
          'C29',
          'C14',
          'C34',
          'C19',
          'C41',
          'C4',
          'C36',
          'A31',
          'A6',
          'A34',
          'A4',
          'A35',
          'B36',
          'B18',
          'B33',
          'B9',
          'B37',
          'B17',
          'B34',
          'B8',
          'B38',
          'B15',
          'B30',
          'B10',
          'B35',
          'A44',
          'B39',
          'B7',
          'B31',
          'A28',
          'A9',
          'A33',
          'C55',
          'A34',
          'C54',
          'A59',
          'C59',
          'A32',
          'C38',
          'C3',
          'C43',
          'A29',
          'B34',
          'A46',
          'B36',
          'A43',
          'B37',
          'A40',
          'B38',
          'D25',
          'D57',
          'D26',
          'D56',
          'D20',
          'D49',
          'D11',
          'D48',
          'D12',
          'D47',
          'D14',
          'D46',
          'D15',
          'D45',
          'D16',
          'D44',
          'C1',
          'C44',
          'C9',
          'C35',
          'A31',
          'A12',
          'B22',
          'A46',
          'B32',
          'B11',
          'B28',
          'A28',
          'C42',
          'C16',
          'C31',
          'C5',
          'C41',
          'D60',
          'C37',
          'A24',
          'B29',
          'A20',
          'B31',
          'A42',
          'B37',
          'B8',
          'B35',
          'A37',
          'B36',
          'D20',
          'B40',
          'D28',
          'B41',
          'D24',
          'B39',
          'A31',
          'A5',
          'A32',
          'C57',
          'A33',
          'C38',
          'C6',
          'C22',
          'C37',
          'C8',
          'C25',
          'D56',
          'D27',
          'D55',
          'D18',
          'D44',
          'D20',
          'B42',
          'A58',
          'C50',
          'A56',
          'C49',
          'A60',
          'C60',
          'B1',
          'C48',
          'A55',
          'B37',
          'A29',
          'A8',
          'A30',
          'B32',
          'B10',
          'B36',
          'A44',
          'B20',
          'A11',
          'A26',
          'C40',
          'D60',
          'C45',
          'A46',
          'C43',
          'C4',
          'C33',
          'C10',
          'C25',
          'A28',
          'B37',
          'D26',
          'B40',
          'D19',
          'D55',
          'D28',
          'D44',
          'B58',
          'D43',
          'D1',
          'D47',
          'D4',
          'D46',
          'D6',
          'D49',
          'D8',
          'D24',
          'D58',
          'C42',
          'A46',
          'B15',
          'A6',
          'B18',
          'A43',
          'B33',
          'B4',
          'B35',
          'A50',
          'C46',
          'A33',
          'C37',
          'A19',
          'B31',
          'B14',
          'A4',
          'B16',
          'A45',
          'B34',
          'A38',
          'B37',
          'A37',
          'B38',
          'A32',
          'C35',
          'C1',
          'C33',
          'A14',
          'B25',
          'A13',
          'B26',
          'A12',
          'A27',
          'C34',
          'C3',
          'C45',
          'A52',
          'B33',
          'D17',
          'B40',
          'D30',
          'D55',
          'D33',
          'D49',
          'D9',
          'D51',
          'D10',
          'D50',
          'D5',
          'D26',
          'B41',
          'D23',
          'B42',
          'D16',
          'D42',
          'D20',
          'D41',
          'D21',
          'B39',
          'A29',
          'C60',
          'A31',
          'C51',
          'A59',
          'B25',
          'A19',
          'C32',
          'A16',
          'B27',
          'A57',
          'B36',
          'A41',
          'B11',
          'B26',
          'A48',
          'B33',
          'A39',
          'B35',
          'A32',
          'C56',
          'A33',
          'B40',
          'A36',
          'B38',
          'A30',
          'C37',
          'D58',
          'C41',
          'C11',
          'C30',
          'C5',
          'C40',
          'A27',
          'C27',
          'A28',
          'B39',
          'D29',
          'B37',
          'A23',
          'C44',
          'C2',
          'C47',
          'B1',
          'C54',
          'A34',
          'C53',
          'A59',
          'C48',
          'B2',
          'C60',
          'B3',
          'B34',
          'A42',
          'B20',
          'A6',
          'B21',
          'A51',
          'C52',
          'C4',
          'C42',
          'D59',
          'C36',
          'C8',
          'C31',
          'A15',
          'B24',
          'B8',
          'A4',
          'B11',
          'A37',
          'B46',
          'D35',
          'B47',
          'D34',
          'D55',
          'D20',
          'B32',
          'B5',
          'A42',
          'B29',
          'A17',
          'C15',
          'A18',
          'C38',
          'C7',
          'C57',
          'A52',
          'C44',
          'A47',
          'B33',
          'A30',
          'C58',
          'A31',
          'B40',
          'D29',
          'D57',
          'C43',
          'A48',
          'B28',
          'B8',
          'A10',
          'C12',
          'C27',
          'A29',
          'A7',
          'B17',
          'A45',
          'B35',
          'A22',
          'B36',
          'A39',
          'B37',
          'D22',
          'D40',
          'B52',
          'D39',
          'D55',
          'D21',
          'B41',
          'D17',
          'D56',
          'C23',
          'C4',
          'C32',
          'A20',
          'C17',
          'C37',
          'C3',
          'C53',
          'A34',
          'B36',
          'D27',
          'B39',
          'A35',
          'B40',
          'D25',
          'D40',
          'D1',
          'D47',
          'D12',
          'D46',
          'D7',
          'D50',
          'D10',
          'D49',
          'D11',
          'D26',
          'D41',
          'C3',
          'C47',
          'A34',
          'C46',
          'A45',
          'C39',
          'C2',
          'C49',
          'B3',
          'C56',
          'B11',
          'A3',
          'A28',
          'A2',
          'A30',
          'B40',
          'D14',
          'B42',
          'D19',
          'B48',
          'B6',
          'B31',
          'A41',
          'B32',
          'A49',
          'B14',
          'C60',
          'C7',
          'C42',
          'A43',
          'B30',
          'A32',
          'B36',
          'A31',
          'B44',
          'A30',
          'C30',
          'C9',
          'C35',
          'A13',
          'C34',
          'A28',
          'A11',
          'C13',
          'A7',
          'B19',
          'A52',
          'C43',
          'D58',
          'D24',
          'B40',
          'D41',
          'B54',
          'D39',
          'D2',
          'D47',
          'D13',
          'B42',
          'A35',
          'B34',
          'A40',
          'B32',
          'D18',
          'D56',
          'C38',
          'A25',
          'B38',
          'A23',
          'C43',
          'C6',
          'C58',
          'A57',
          'B28',
          'A44',
          'B22',
          'A6',
          'C11',
          'C29',
          'A30',
          'C28',
          'D55',
          'D35',
          'D20',
          'D4',
          'D51',
          'D6',
          'D38',
          'B49',
          'D34',
          'D7',
          'D50',
          'D3',
          'D39',
          'B39',
          'A39',
          'B44',
          'A58',
          'B26',
          'A51',
          'C45',
          'A53',
          'B37',
          'A27',
          'A10',
          'C11',
          'A25',
          'C33',
          'C6',
          'A8',
          'B6',
          'C53',
          'A60',
          'B23',
          'A6',
          'C19',
          'A19',
          'C18',
          'A21',
          'C16',
          'A16',
          'B29',
          'A44',
          'C45',
          'C1',
          'D45',
          'B57',
          'D44',
          'B43',
          'D12',
          'D43',
          'D18',
          'B37',
          'A26',
          'B33',
          'A46',
          'C39',
          'C21',
          'C5',
          'C51',
          'B4',
          'C50',
          'A59',
          'A1',
          'B1',
          'A45',
          'B19',
          'A12',
          'B27',
          'A50',
          'C46',
          'D59',
          'C41',
          'C8',
          'A17',
          'B18',
          'A4',
          'C12',
          'C40',
          'C1',
          'C48',
          'A27',
          'B28',
          'A47',
          'B14',
          'B54',
          'D38',
          'D22',
          'B34',
          'A33',
          'C56',
          'B12',
          'A13',
          'B29',
          'A41',
          'B33',
          'D23',
          'B36',
          'D31',
          'B39',
          'A25',
          'C10',
          'A21',
          'B35',
          'A40',
          'B1',
          'C47',
          'D58',
          'D25',
          'B37',
          'A22',
          'C46',
          'C4',
          'D43',
          'B42',
          'D17',
          'B48',
          'D16',
          'D57',
          'D31',
          'D56',
          'C44',
          'A45',
          'B26',
          'B9',
          'C58',
          'A31',
          'B27',
          'A29',
          'C26',
          'C11',
          'C33',
          'C18',
          'C40',
          'A33',
          'B45',
          'A35',
          'B46',
          'D20',
          'B39',
          'D18',
          'B40',
          'A25',
          'B28',
          'A43',
          'C41',
          'A50',
          'B10',
          'C57',
          'C8',
          'A14',
          'C23',
          'A22',
          'C9',
          'A8',
          'C7',
          'C37',
          'C15',
          'A20',
          'C20',
          'A5',
          'B13',
          'A37',
          'B39',
          'D38',
          'D23',
          'D59',
          'C34',
          'A17',
          'B31',
          'A57',
          'C55',
          'A33',
          'C36',
          'A12',
          'B29',
          'D9',
          'B42',
          'A30',
          'B36',
          'D30',
          'B38',
          'D22',
          'B40',
          'A29',
          'C42',
          'A47',
          'B10',
          'A45',
          'B23',
          'A51',
          'B24',
          'A6',
          'C10',
          'A24',
          'B30',
          'A42',
          'B35',
          'A38',
          'B44',
          'D16',
          'B50',
          'D14',
          'D46',
          'D17',
          'D45',
          'C1',
          'C50',
          'B2',
          'C47',
          'D27',
          'B34',
          'D26',
          'D58',
          'C38',
          'C2',
          'C54',
          'A35',
          'B35',
          'A33',
          'B37',
          'A41',
          'C37',
          'C4',
          'C44',
          'A53',
          'B8',
          'C53',
          'B1',
          'A1',
          'A28',
          'C59',
          'A29',
          'B38',
          'D40',
          'C5',
          'C60',
          'B15',
          'A46',
          'B25',
          'A6',
          'A26',
          'B41',
          'D42',
          'B58',
          'D41',
          'B59',
          'D48',
          'B60',
          'D16',
          'D57',
          'D32',
          'B43',
          'D17',
          'D56',
          'D40',
          'D25',
          'B35',
          'A47',
          'C19',
          'C36',
          'C8',
          'A19',
          'C14',
          'C40',
          'A50',
          'C51',
          'D60',
          'C46',
          'D27',
          'D58',
          'C49',
          'B4',
          'B25',
          'A49',
          'B27',
          'A45',
          'C38',
          'A29',
          'B44',
          'D11',
          'D48',
          'B60',
          'D41',
          'B41',
          'D18',
          'D42',
          'B53',
          'B15',
          'A10',
          'C21',
          'C36',
          'C5',
          'C44',
          'A24',
          'B39',
          'A32',
          'C58',
          'A58',
          'C55',
          'C1',
          'C41',
          'C13',
          'A30',
          'C27',
          'A56',
          'C26',
          'A57',
          'C51',
          'B1',
          'B20',
          'A7',
          'B21',
          'A4',
          'A26',
          'B38',
          'A39',
          'B30',
          'A21',
          'C15',
          'C33',
          'A18',
          'C9',
          'A25',
          'B41',
          'D21',
          'D36',
          'D3',
          'D48',
          'D11',
          'B50',
          'D38',
          'C6',
          'A9',
          'A24',
          'C16',
          'A17',
          'C39',
          'A26',
          'C10',
          'A4',
          'B25',
          'A60',
          'C55',
          'B11',
          'A14',
          'B24',
          'A50',
          'C20',
          'A28',
          'B40',
          'D27',
          'B37',
          'A31',
          'B45',
          'D16',
          'B54',
          'D37',
          'B39',
          'D30',
          'D3',
          'D52',
          'D8',
          'D50',
          'D10',
          'D49',
          'D33',
          'B38',
          'A38',
          'B33',
          'A36',
          'B34',
          'A43',
          'B29',
          'A40',
          'B36',
          'A18',
          'C19',
          'A29',
          'C12',
          'A5',
          'B10',
          'C57',
          'B14',
          'A53',
          'B22',
          'A3',
          'C13',
          'D52',
          'D5',
          'A13',
          'D6',
          'D51',
          'C15',
          'C30',
          'A4',
          'C21',
          'A27',
          'C17',
          'C56',
          'A52',
          'B20',
          'A17',
          'B37',
          'A15',
          'C7',
          'A7',
          'B57',
          'A6',
          'B27',
          'A44',
          'B32',
          'A39',
          'B47',
          'A37',
          'B43',
          'D43',
          'C3',
          'C48',
          'D28',
          'B33',
          'A34',
          'B46',
          'D36',
          'B36',
          'D19',
          'D46',
          'D12',
          'D38',
          'C42',
          'D24',
          'B35',
          'A29',
          'C34',
          'C9',
          'C43',
          'D25',
          'B47',
          'D20',
          'B41',
          'A36',
          'B14',
          'A14',
          'B37',
          'D23',
          'B27',
          'A48',
          'B31',
          'A16',
          'C10',
          'C59',
          'C3',
          'C54',
          'D59',
          'C48',
          'A60',
          'C29',
          'B1',
          'B21',
          'A58',
          'B26',
          'A4',
          'C8',
          'C45',
          'D26',
          'B39',
          'A21',
          'D8',
          'B50',
          'D6',
          'A12',
          'C6',
          'C39',
          'C14',
          'C53',
          'B9',
          'A9',
          'B29',
          'A6',
          'C5',
          'D38',
          'C41',
          'A44',
          'C16',
          'A46',
          'B28',
          'A42',
          'B32',
          'A37',
          'B44',
          'D45',
          'B56',
          'D15',
          'B48',
          'D35',
          'B36',
          'A29',
          'C52',
          'A55',
          'C28',
          'A58',
          'C26',
          'A60',
          'A7',
          'A24',
          'C43',
          'A53',
          'B26',
          'A50',
          'C19',
          'A20',
          'C8',
          'D52',
          'D9',
          'B44',
          'A28',
          'C48',
          'B3',
          'B18',
          'A54',
          'C42',
          'D57',
          'C50',
          'D29',
          'C49',
          'A21',
          'C32',
          'C10',
          'C41',
          'D36',
          'D21',
          'B35',
          'A32',
          'B43',
          'D17',
          'D38',
          'C4',
          'C45',
          'D55',
          'D7',
          'A15',
          'C23',
          'A60',
          'B49',
          'D33',
          'C35',
          'A26',
          'C22',
          'A3',
          'B23',
          'A52',
          'C56',
          'B13',
          'A1',
          'C3',
          'A7',
          'B58',
          'A8',
          'B16',
          'D12',
          'B48',
          'A35',
          'B29',
          'A33',
          'B46',
          'A31',
          'C13',
          'A26',
          'B42',
          'D18',
          'D56',
          'D40',
          'B38',
          'D32',
          'D17',
          'B25',
          'D21',
          'D55',
          'D8',
          'A20',
          'B35',
          'A16',
          'B36',
          'A10',
          'D3',
          'D47',
          'A40',
          'B31',
          'A47',
          'C18',
          'A22',
          'C45',
          'A43',
          'C17',
          'C32',
          'B53',
          'C30',
          'A31',
          'C12',
          'A11',
          'B22',
          'A7',
          'C20',
          'C39',
          'D36',
          'C7',
          'B10',
          'A42',
          'C43',
          'D56',
          'D6',
          'A14',
          'B38',
          'D21',
          'C40',
          'C4',
          'D39',
          'B40',
          'D22',
          'D60',
          'C42',
          'A48',
          'B21',
          'A43',
          'B23',
          'A18',
          'B28',
          'D23',
          'D59',
          'C56',
          'B14',
          'C13',
          'B15',
          'C12',
          'A30',
          'B34',
          'A34',
          'C46',
          'C3',
          'D43',
          'B43',
          'D16',
          'B46',
          'D8',
          'B52',
          'C29',
          'A57',
          'B12',
          'A9',
          'D3',
          'D35',
          'B37',
          'A13',
          'C5',
          'C42',
          'A55',
          'C27',
          'B51',
          'C26',
          'B1',
          'C50',
          'D30',
          'C52',
          'A34',
          'B41',
          'D17',
          'C53',
          'C4',
          'A10',
          'C14',
          'C35',
          'A42',
          'B24',
          'A41',
          'B34',
          'A23',
          'D10',
          'D49',
          'D27',
          'B33',
          'A37',
          'B28',
          'A45',
          'C17',
          'A28',
          'B36',
          'A24',
          'D11',
          'D48',
          'A7',
          'B56',
          'A5',
          'C7',
          'A19',
          'B35',
          'A36',
          'B45',
          'D45',
          'D12',
          'D34',
          'C37',
          'D36',
          'C6',
          'B10',
          'C53',
          'D60',
          'D44',
          'B44',
          'D32',
          'B50',
          'C33',
          'A14',
          'B28',
          'A41',
          'B30',
          'A6',
          'B58',
          'D14',
          'D58',
          'D15',
          'B19',
          'B4',
          'C48',
          'A33',
          'C55',
          'B3',
          'D60',
          'C49',
          'A25',
          'C18',
          'B20',
          'A54',
          'B9',
          'C8',
          'C59',
          'D47',
          'A8',
          'B29',
          'D7',
          'A14',
          'B35',
          'D34',
          'B37',
          'A10',
          'C37',
          'C13',
          'C43',
          'D23',
          'C41',
          'D39',
          'C39',
          'C24',
          'B50',
          'C31',
          'B8',
          'A49',
          'C48',
          'D29',
          'B39',
          'D40',
          'B53',
          'C29',
          'B4',
          'A9',
          'C5',
          'D41',
          'A28',
          'B41',
          'A33',
          'B47',
          'C28',
          'B49',
          'C25',
          'A58',
          'C22',
          'B48',
          'A43',
          'C38',
          'A46',
          'C15',
          'A47',
          'B21',
          'D11',
          'D48',
          'A40',
          'D46',
          'A6',
          'A27',
          'B32',
          'D29',
          'D58',
          'C55',
          'D19',
          'D34',
          'B54',
          'D32',
          'C54',
          'C13',
          'C48',
          'A44',
          'B33',
          'A25',
          'C26',
          'B53',
          'D42',
          'A29',
          'C59',
          'B16',
          'C15',
          'B17',
          'D10',
          'A22',
          'C8',
          'B11',
          'C7',
          'C46',
          'A55',
          'B13',
          'A48',
          'B27',
          'A47',
          'C47',
          'D28',
          'D3',
          'A11',
          'D4',
          'A10',
          'D50',
          'D8',
          'D54',
          'D9',
          'A23',
          'C11',
          'A3',
          'A26',
          'B43',
          'A40',
          'D45',
          'D17',
          'C52',
          'C20',
          'A8',
          'B35',
          'A34',
          'B39',
          'D23',
          'A2',
          'C18',
          'A27',
          'B42',
          'D21',
          'C58',
          'A32',
          'C57',
          'D58',
          'B1',
          'C25',
          'B51',
          'C24',
          'A48',
          'B18',
          'C16',
          'A45',
          'B31',
          'A38',
          'B39',
          'A30',
          'B45',
          'D26',
          'C44',
          'D39',
          'D55',
          'C26',
          'B47',
          'D36',
          'C35',
          'A32',
          'B41',
          'D38',
          'D18',
          'C56',
          'C9',
          'B8',
          'C5',
          'B9',
          'C58',
          'B16',
          'D11',
          'A25',
          'A1',
          'A26',
          'C49',
          'D28',
          'B35',
          'A13',
          'D51',
          'D2',
          'A17',
          'B34',
          'A39',
          'D48',
          'A6',
          'B56',
          'A4',
          'C1',
          'C32',
          'B49',
          'D16',
          'D57',
          'C39',
          'D40',
          'A29',
          'C11',
          'A11',
          'B7',
          'A15',
          'B36',
          'A9',
          'D4',
          'B9',
          'B30',
          'A31',
          'B31',
          'A43',
          'B27',
          'A56',
          'C30',
          'B50',
          'C28',
          'A59',
          'C27',
          'A57',
          'C44',
          'D55',
          'D5',
          'D52',
          'D9',
          'D51',
          'B48',
          'A33',
          'C31',
          'A3',
          'B54',
          'D10',
          'D49',
          'A11',
          'D2',
          'B9',
          'C32',
          'D30',
          'C51',
          'D42',
          'C10',
          'A17',
          'B23',
          'A59',
          'C22',
          'A60',
          'D59',
          'C38',
          'D35',
          'C40',
          'A34',
          'B44',
          'D27',
          'D12',
          'A25',
          'D36',
          'A27',
          'B34',
          'A37',
          'B40',
          'A24',
          'C36',
          'A41',
          'B38',
          'A13',
          'D7',
          'D56',
          'C51',
          'D14',
          'B51',
          'C30',
          'C15',
          'B18',
          'A46',
          'B22',
          'D10',
          'A21',
          'C14',
          'A6',
          'C1',
          'B5',
          'C29',
          'B46',
          'D48',
          'D11',
          'D47',
          'A41',
          'D53',
          'A15',
          'C11',
          'B13',
          'A9',
          'B59',
          'C60',
          'D22',
          'B24',
          'A39',
          'B28',
          'A36',
          'B47',
          'A31',
          'C44',
          'A51',
          'B25',
          'D2',
          'A16',
          'D8',
          'C42',
          'D21',
          'B45',
          'A32',
          'B50',
          'C23',
          'A48',
          'C16',
          'A53',
          'B16',
          'A49',
          'B26',
          'A17',
          'C50',
          'D14',
          'D43',
          'D28',
          'B42',
          'D42',
          'D18',
          'C54',
          'D16',
          'A22',
          'D57',
          'C24',
          'C9',
          'D35',
          'C56',
          'C2',
          'B4',
          'D60',
          'C36',
          'A43',
          'B22',
          'D20',
          'C39',
          'A37',
          'B54',
          'C28',
          'B5',
          'C25',
          'B52',
          'C35',
          'C5',
          'C26',
          'B48',
          'C31',
          'D31',
          'B55',
          'A5',
          'B57',
          'D45',
          'A41',
          'C44',
          'A54',
          'C53',
          'D18',
          'D38',
          'D23',
          'C40',
          'A42',
          'B33',
          'A21',
          'C47',
          'D54',
          'C46',
          'D40',
          'B40',
          'A20',
          'C50',
          'A34',
          'B36',
          'A27',
          'C10',
          'B13',
          'D12',
          'D45',
          'A31',
          'B35',
          'D32',
          'A23',
          'D11',
          'B51',
          'D7',
          'A19',
          'B32',
          'A35',
          'B38',
          'A17',
          'D54',
          'C39',
          'D23',
          'C58',
          'D46',
          'C2',
          'A4',
          'D24',
          'C43',
          'A57',
          'C30',
          'A33',
          'C54',
          'A47',
          'B16',
          'A44',
          'C18',
          'B21',
          'A52',
          'C17',
          'B20',
          'D14',
          'C49',
          'D43',
          'A29',
          'D41',
          'C41',
          'D22',
          'B40',
          'D37',
          'C43',
          'D59',
          'D13',
          'B43',
          'A27',
          'B39',
          'A16',
          'D52',
          'A41',
          'B26',
          'A38',
          'B32',
          'A9',
          'B37',
          'D31',
          'C52',
          'B11',
          'C6',
          'C30',
          'A59',
          'B14',
          'B57',
          'B18',
          'A42',
          'B23',
          'A7',
          'D47',
          'A5',
          'C28',
          'D18',
          'B16',
          'D8',
          'A22',
          'D9',
          'D51',
          'D2',
          'A8',
          'A24',
          'D34',
          'B34',
          'D33',
          'B42',
          'A41',
          'D60',
          'C34',
          'C15',
          'A53',
          'D53',
          'D4',
          'A13',
          'C4',
          'B3',
          'C50',
          'D42',
          'C9',
          'A12',
          'D5',
          'A11',
          'C37',
          'D59',
          'B4',
          'C43',
          'A45',
          'B50',
          'C27',
          'B46',
          'D47',
          'D11',
          'B14',
          'C10',
          'C35',
          'A43',
          'C44',
          'D57',
          'A57',
          'C18',
          'A50',
          'B12',
          'C13',
          'A9',
          'D50',
          'D9',
          'B48',
          'A31',
          'A2',
          'D25',
          'C45',
          'A34',
          'B28',
          'D10',
          'D49',
          'A39',
          'D46',
          'D15',
          'A21',
          'C7',
          'B9',
          'A52',
          'C46',
          'A59',
          'B59',
          'A12',
          'D48',
          'A3',
          'B60',
          'C60',
          'A26',
          'C33',
          'B56',
          'A37',
          'B52',
          'C34',
          'B49',
          'C31',
          'D32',
          'D17',
          'D45',
          'D21',
          'A1',
          'D38',
          'D23',
          'C57',
          'A32',
          'B29',
          'A48',
          'C39',
          'D55',
          'D7',
          'D53',
          'C40',
          'A47',
          'B32',
          'A28',
          'D42',
          'B39',
          'A15',
          'B34',
          'A10',
          'D5',
          'B8',
          'C29',
          'B9',
          'C4',
          'C60',
          'D39',
          'B36',
          'A19',
          'B37',
          'A45',
          'B21',
          'A21',
          'D27',
          'C45',
          'D54',
          'A22',
          'D56',
          'C37',
          'B47',
          'D19',
          'C54',
          'C19',
          'A51',
          'C18',
          'A9',
          'B60',
          'B44',
          'A27',
          'D12',
          'D41',
          'C12',
          'A26',
          'D34',
          'C55',
          'D17',
          'B26',
          'D1',
          'A45',
          'C4',
          'A14',
          'B36',
          'A8',
          'D10',
          'D49',
          'B45',
          'C30',
          'C10',
          'B8',
          'C44',
          'A55',
          'D55',
          'D8',
          'D53',
          'C48',
          'A34',
          'B40',
          'D29',
          'B41',
          'D37',
          'C42',
          'D9',
          'A17',
          'B28',
          'A10',
          'D47',
          'D13',
          'A25',
          'B34',
          'A21',
          'B22',
          'B1',
          'C3',
          'C41',
          'B53',
          'C25',
          'D15',
          'B49',
          'A1',
          'B50',
          'C26',
          'C7',
          'C37',
          'D39',
          'B38',
          'A43',
          'B32',
          'A56',
          'C28',
          'B55',
          'A3',
          'D22',
          'C59',
          'A30',
          'B30',
          'A48',
          'D47',
          'C3',
          'B7',
          'C32',
          'B56',
          'C46',
          'D26',
          'B46',
          'A58',
          'B24',
          'A5',
          'C19',
          'A26',
          'D12',
          'C48',
          'A45',
          'B59',
          'C59',
          'C6',
          'B3',
          'B19',
          'A23',
          'D33',
          'C57',
          'A53',
          'C14',
          'D40',
          'A30',
          'C11',
          'C58',
          'D24',
          'C41',
          'B54',
          'A4',
          'B27',
          'A38',
          'B51',
          'A33',
          'B42',
          'D27',
          'C27',
          'A60',
          'B31',
          'A12',
          'D52',
          'A53',
          'B32',
          'A16',
          'D1',
          'A1',
          'C1',
          'A7',
          'A24',
          'D35',
          'C52',
          'C15',
          'D39',
          'C43',
          'D42',
          'C7',
          'B12',
          'A39',
          'B25',
          'D20',
          'B43',
          'C20',
          'C35',
          'C8',
          'B6',
          'C51',
          'A32',
          'D47',
          'D13',
          'A20',
          'D29',
          'A25',
          'B42',
          'A29',
          'B30',
          'D28',
          'D43',
          'C48',
          'A51',
          'C41',
          'D52',
          'B51',
          'C23',
          'A49',
          'B20',
          'B3',
          'B58',
          'A5',
          'A24',
          'D9',
          'D50',
          'A41',
          'B48',
          'C29',
          'B10',
          'D7',
          'C40',
          'D38',
          'B38',
          'A10',
          'B35',
          'A30',
          'D37',
          'C56',
          'D32',
          'C30',
          'D54',
          'A15',
          'D1',
          'C34',
          'B54',
          'A36',
          'B31',
          'A6',
          'D25',
          'C60',
          'D48',
          'B60',
          'A12',
          'D3',
          'A8',
          'B37',
          'D41',
          'A5',
          'D6',
          'D56',
          'C27',
          'C12',
          'C56',
          'B33',
          'A35',
          'C47',
          'A49',
          'B26',
          'A46',
          'B58',
          'D37',
          'D22',
          'A1',
          'D23',
          'B47',
          'D17',
          'C57',
          'D36',
          'C34',
          'B20',
          'A60',
          'C21',
          'D57',
          'B1',
          'C42',
          'D51',
          'D6',
          'A11',
          'C10',
          'B15',
          'D13',
          'A17',
          'B30',
          'A38',
          'B53',
          'A5',
          'C4',
          'C38',
          'D55',
          'C53',
          'D16',
          'C25',
          'A47',
          'C46',
          'D43',
          'A39',
          'B29',
          'A45',
          'C3',
          'D21',
          'B21',
          'C17',
          'A58',
          'B25',
          'A54',
          'C41',
          'A55',
          'D54',
          'A40',
          'B23',
          'D18',
          'C34',
          'B45',
          'C32',
          'A36',
          'B37',
          'A12',
          'D4',
          'A60',
          'D7',
          'B51',
          'C22',
          'B44',
          'D50',
          'A15',
          'C35',
          'B48',
          'C21',
          'C51',
          'D29',
          'C33',
          'B20',
          'A23',
          'D58',
          'A2',
          'D21',
          'D44',
          'A40',
          'B45',
          'A29',
          'C49',
          'D30',
          'B34',
          'A16',
          'C9',
          'A27',
          'D40',
          'D16',
          'D47',
          'A4',
          'B53',
          'D39',
          'A32',
          'B33',
          'A28',
          'B38',
          'D28',
          'A21',
          'D9',
          'A7',
          'D45',
          'C56',
          'C14',
          'B17',
          'A53',
          'C43',
          'B7',
          'C30',
          'B54',
          'A35',
          'C54',
          'D20',
          'C53',
          'C20',
          'B45',
          'C29',
          'D54',
          'B53',
          'C40',
          'A36',
          'B32',
          'A33',
          'B49',
          'D53',
          'A18',
          'B25',
          'A50',
          'D50',
          'D9',
          'A16',
          'B37',
          'A44',
          'C47',
          'D11',
          'B55',
          'A2',
          'B60',
          'A43',
          'D2',
          'A19',
          'B22',
          'A52',
          'C16',
          'D58',
          'C39',
          'B44',
          'C36',
          'D32',
          'C7',
          'A17',
          'B35',
          'A23',
          'D17',
          'C37',
          'D21',
          'D36',
          'C6',
          'D42',
          'A31',
          'A1',
          'D26',
          'C28',
          'B45',
          'A34',
          'C49',
          'D15',
          'A20',
          'B5',
          'D60',
          'A50',
          'C23',
          'B46',
          'A29',
          'D38',
          'C45',
          'A49',
          'B28',
          'D14',
          'A26',
          'D10',
          'D49',
          'B47',
          'C27',
          'B11',
          'C12',
          'A9',
          'D5',
          'D57',
          'C58',
          'B57',
          'B8',
          'C8',
          'D28',
          'C47',
          'D56',
          'A19',
          'C52',
          'D13',
          'D45',
          'B40',
          'A43',
          'B44',
          'C35',
          'D58',
          'A56',
          'B15',
          'C9',
          'C24',
          'B45',
          'C39',
          'A49',
          'D51',
          'B51',
          'D41',
          'A27',
          'C26',
          'B2',
          'B53',
          'B18',
          'A41',
          'B49',
          'D19',
          'C59',
          'D25',
          'A5',
          'C15',
          'A54',
          'C47',
          'A32',
          'C56',
          'C19',
          'D10',
          'A9',
          'B38',
          'A12',
          'B58',
          'A39',
          'B31',
          'A45',
          'C18',
          'A29',
          'D44',
          'C5',
          'C34',
          'D2',
          'A15',
          'B35',
          'A21',
          'B36',
          'A13',
          'D12',
          'A24',
          'B33',
          'A31',
          'C11',
          'B12',
          'C6',
          'A18',
          'D3',
          'B4',
          'D6',
          'C38',
          'B46',
          'C32',
          'D31',
          'C53',
          'D32',
          'D17',
          'D45',
          'C39',
          'D48',
          'A41',
          'B21',
          'A56',
          'C19',
          'B22',
          'C36',
          'B23',
          'A51',
          'B44',
          'D27',
          'C48',
          'A4',
          'D6',
          'D56',
          'A57',
          'D29',
          'C27',
          'B54',
          'D16',
          'C51',
          'D34',
          'C11',
          'B60',
          'A59',
          'C17',
          'A14',
          'B39',
          'D33',
          'B43',
          'A35',
          'B26',
          'D24',
          'A7',
          'D49',
          'C5',
          'A11',
          'D11',
          'D47',
          'A9',
          'C39',
          'B54',
          'C26',
          'B10',
          'C32',
          'B47',
          'C60',
          'B48',
          'C20',
          'A4',
          'C29',
          'D19',
          'C57',
          'A38',
          'B36',
          'A12',
          'C3',
          'A40',
          'B27',
          'A46',
          'B30',
          'A37',
          'C21',
          'B51',
          'D40',
          'C13',
          'D3',
          'A5',
          'C11',
          'D41',
          'C49',
          'A48',
          'D44',
          'B40',
          'A19',
          'B33',
          'C54',
          'D54',
          'A42',
          'C2',
          'B7',
          'B52',
          'A33',
          'B28',
          'A22',
          'D32',
          'A27',
          'D31',
          'A58',
          'C44',
          'D22',
          'B41',
          'D36',
          'A43',
          'C1',
          'B3',
          'A45',
          'C37',
          'A23',
          'D57',
          'D5',
          'A26',
          'B32',
          'C55',
          'D15',
          'C24',
          'B47',
          'C23',
          'C50',
          'A24',
          'C34',
          'D19',
          'B19',
          'B2',
          'C42',
          'D25',
          'B39',
          'A13',
          'D48',
          'C59',
          'B58',
          'D31',
          'B1',
          'B24',
          'A47',
          'D6',
          'D51',
          'D7',
          'A50',
          'D49',
          'A4',
          'B55',
          'A36',
          'C38',
          'C5',
          'C29',
          'B49',
          'D52',
          'D5',
          'A25',
          'D37',
          'A44',
          'B17',
          'C58',
          'C7',
          'D34',
          'C39',
          'B58',
          'B13',
          'A50',
          'C45',
          'D56',
          'C25',
          'B10',
          'B51',
          'A46',
          'B60',
          'C10',
          'A7',
          'A30',
          'B43',
          'C37',
          'D5',
          'A6',
          'B54',
          'A57',
          'D54',
          'A43',
          'A2',
          'D24',
          'D45',
          'A10',
          'D6',
          'A18',
          'D14',
          'D41',
          'A36',
          'B39',
          'D46',
          'A50',
          'C17',
          'D38',
          'D23',
          'A5',
          'D40',
          'D16',
          'B12',
          'C25',
          'A29',
          'B31',
          'A37',
          'B53',
          'D35',
          'C36',
          'B46',
          'C41',
          'B57',
          'A8',
          'C19',
          'B48',
          'C18',
          'D10',
          'D49',
          'A12',
          'D7',
          'A6',
          'D60',
          'A53',
          'C56',
          'D38',
          'A31',
          'B38',
          'A16',
          'B33',
          'A22',
          'D11',
          'C46',
          'A35',
          'B49',
          'C22',
          'A49',
          'C30',
          'B4',
          'B59',
          'A8',
          'B37',
          'A7',
          'D1',
          'D54',
          'C40',
          'D8',
          'A6',
          'C6',
          'A24',
          'D4',
          'B22',
          'B7',
          'A52',
          'B25',
          'A42',
          'B27',
          'A37',
          'C38',
          'D57',
          'C19',
          'D11',
          'D26',
          'C60',
          'C30',
          'B42',
          'C29',
          'D25',
          'C26',
          'D27',
          'A19',
          'D8',
          'A60',
          'D58',
          'C14',
          'B18',
          'C13',
          'D4',
          'A43',
          'C27',
          'D46',
          'D15',
          'C52',
          'D55',
          'A17',
          'B6',
          'C33',
          'D21',
          'C53',
          'D33',
          'B5',
          'D2',
          'A45',
          'B32',
          'A23',
          'B39',
          'D38',
          'C16',
          'A59',
          'C45',
          'A4',
          'C41',
          'A22',
          'D53',
          'A40',
          'C34',
          'A41',
          'D46',
          'A32',
          'D44',
          'B41',
          'D10',
          'C48',
          'D55',
          'A21',
          'D3',
          'B23',
          'C20',
          'B51',
          'A44',
          'B19',
          'A53',
          'C21',
          'A19',
          'D15',
          'B14',
          'B55',
          'D38',
          'D18',
          'C37',
          'A39',
          'B50',
          'A34',
          'C39',
          'A5',
          'D45',
          'A50',
          'D5',
          'A14',
          'D53',
          'D8',
          'D52',
          'C49',
          'D33',
          'C30',
          'B8',
          'C43',
          'D7',
          'B16',
          'C32',
          'B17',
          'C16',
          'D6',
          'B21',
          'A13',
          'B33',
          'A18',
          'A2',
          'D3',
          'B42',
          'C27',
          'D54',
          'B48',
          'A58',
          'D30',
          'C8',
          'B56',
          'A3',
          'D24',
          'A1',
          'C23',
          'B47',
          'C36',
          'D57',
          'A54',
          'C29',
          'B11',
          'C26',
          'B46',
          'A42',
          'C46',
          'D12',
          'A28',
          'B45',
          'C57',
          'D32',
          'A21',
          'D14',
          'A27',
          'C16',
          'D7',
          'D56',
          'A60',
          'D3',
          'A43',
          'B41',
          'C31',
          'A16',
          'C53',
          'D43',
          'D28',
          'A26',
          'B27',
          'D26',
          'A8',
          'B7',
          'C54',
          'D15',
          'D46',
          'C24',
          'A47',
          'B14',
          'C11',
          'B18',
          'D10',
          'D49',
          'A40',
          'B28',
          'D22',
          'A55',
          'B15',
          'A24',
          'D17',
          'C32',
          'D21',
          'A32',
          'B51',
          'A3',
          'B25',
          'A50',
          'C16',
          'B30',
          'A20',
          'C9',
          'D27',
          'C25',
          'D59',
          'A49',
          'B26',
          'A44',
          'C44',
          'B3',
          'D34',
          'C60',
          'A31',
          'D13',
          'D47',
          'C41',
          'B49',
          'D51',
          'A11',
          'B36',
          'A10',
          'B54',
          'D10',
          'A25',
          'D30',
          'C54',
          'B30',
          'C15',
          'A37',
          'B57',
          'C40',
          'D56',
          'C59',
          'B60',
          'A8',
          'D4',
          'D53',
          'C32',
          'B18',
          'D57',
          'A56',
          'B24',
          'C20',
          'A23',
          'B27',
          'D24',
          'C37',
          'D48',
          'A31',
          'B29',
          'A38',
          'B42',
          'C3',
          'A41',
          'D44',
          'B9',
          'C44',
          'D40',
          'B37',
          'D39',
          'C47',
          'A33',
          'C55',
          'D4',
          'A54',
          'C14',
          'B13',
          'C7',
          'B56',
          'C45',
          'A52',
          'B34',
          'A9',
          'D11',
          'D47',
          'A13',
          'B59',
          'B8',
          'C33',
          'D1',
          'C11',
          'C57',
          'D20',
          'B44',
          'A36',
          'D33',
          'D18',
          'C58',
          'A31',
          'B49',
          'C60',
          'D37',
          'C34',
          'B55',
          'A6',
          'D9',
          'D50',
          'B21',
          'D5',
          'D57',
          'C26',
          'A43',
          'D60',
          'C19',
          'A58',
          'B13',
          'C29',
          'A17',
          'B36',
          'A47',
          'C17',
          'C1',
          'B22',
          'B57',
          'D54',
          'A21',
          'B28',
          'A13',
          'C34',
          'D20',
          'C42',
          'B52',
          'C23',
          'D57',
          'A25',
          'D14',
          'A20',
          'C50',
          'B49',
          'A46',
          'B41',
          'A31',
          'C9',
          'B7',
          'D32',
          'C52',
          'D45',
          'D17',
          'D44',
          'D21',
          'B31',
          'C47',
          'B2',
          'C5',
          'B10',
          'D11',
          'A29',
          'D39',
          'A26',
          'C49',
          'A37',
          'C32',
          'B20',
          'A53',
          'D48',
          'C43',
          'D43',
          'C29',
          'D45',
          'B14',
          'C52',
          'A58',
          'D29',
          'A4',
          'D23',
          'D38',
          'B40',
          'C25',
          'B46',
          'A28',
          'D13',
          'B49',
          'C19',
          'A21',
          'C8',
          'A24',
          'D27',
          'A3',
          'D2',
          'C15',
          'B32',
          'A34',
          'B47',
          'C22',
          'B52',
          'C6',
          'B56',
          'A36',
          'C20',
          'B33',
          'A12',
          'C36',
          'B51',
          'C53',
          'D44',
          'B57',
          'C30',
          'A51',
          'B9',
          'C28',
          'D13',
          'D47',
          'A38',
          'B21',
          'A50',
          'A10',
          'B59',
          'A44',
          'D36',
          'B5',
          'C44',
          'B6',
          'C31',
          'B44',
          'C3',
          'A53',
          'D58',
          'D3',
          'D54',
          'D5',
          'D52',
          'D8',
          'D51',
          'C28',
          'B45',
          'C27',
          'B9',
          'D46',
          'D15',
          'C35',
          'A39',
          'B52',
          'A8',
          'D48',
          'A45',
          'B38',
          'A15',
          'C33',
          'A32',
          'B26',
          'D57',
          'B27',
          'C53',
          'A35',
          'D49',
          'D10',
          'B15',
          'D19',
          'D45',
          'A47',
          'B25',
          'C2',
          'C60',
          'A26',
          'D4',
          'D58',
          'A43',
          'D53',
          'B52',
          'C5',
          'C59',
          'D40',
          'D16',
          'A27',
          'D6',
          'B29',
          'C37',
          'A6',
          'D28',
          'D43',
          'B5',
          'D31',
          'A23',
          'C17',
          'A51',
          'D59',
          'C22',
          'D2',
          'C14',
          'A38',
          'B43',
          'A29',
          'B32',
          'A20',
          'A2',
          'D57',
          'B2',
          'C45',
          'A54',
          'B31',
          'D30',
          'C33',
          'B44',
          'C30',
          'A58',
          'B30',
          'C48',
          'D14',
          'A17',
          'B60',
          'C58',
          'D25',
          'A18',
          'D51',
          'A6',
          'B59',
          'C40',
          'D41',
          'C32',
          'A32',
          'D38',
          'D23',
          'B39',
          'A12',
          'C35',
          'B22',
          'A60',
          'D9',
          'D50',
          'B42',
          'A45',
          'B56',
          'C31',
          'A34',
          'B53',
          'D53',
          'A57',
          'D32',
          'C48',
          'A29',
          'C36',
          'B43',
          'B4',
          'B49',
          'A40',
          'C1',
          'A49',
          'C42',
          'D35',
          'A38',
          'C24',
          'B11',
          'B26',
          'A41',
          'B60',
          'C56',
          'B34',
          'D29',
          'B5',
          'A1',
          'A28',
          'C41',
          'D53',
          'C31',
          'A56',
          'D20',
          'A30',
          'C10',
          'C25',
          'B50',
          'C60',
          'D6',
          'C15',
          'A52',
          'D21',
          'B42',
          'A40',
          'C4',
          'A17',
          'B32',
          'C13',
          'A16',
          'C27',
          'D55',
          'A25',
          'D35',
          'C58',
          'B44',
          'D17',
          'C51',
          'D13',
          'D47',
          'D14',
          'A34',
          'B37',
          'A47',
          'C54',
          'D4',
          'A3',
          'A24',
          'D5',
          'B49',
          'C18',
          'D16',
          'B13',
          'C6',
          'C22',
          'D3',
          'A17',
          'D52',
          'A57',
          'B55',
          'C42',
          'D22',
          'D44',
          'A50',
          'B23',
          'C35',
          'B53',
          'A19',
          'D28',
          'A58',
          'D35',
          'B24',
          'B6',
          'D1',
          'C10',
          'D26',
          'C49',
          'D54',
          'A52',
          'D8',
          'D51',
          'A40',
          'B30',
          'A35',
          'B44',
          'C29',
          'B16',
          'D46',
          'D15',
          'C27',
          'D58',
          'A21',
          'B41',
          'C33',
          'D52',
          'A37',
          'C48',
          'A46',
          'C3',
          'B45',
          'D13',
          'A24',
          'B31',
          'C45',
          'A40',
          'B33',
          'A11',
          'B58',
          'B11',
          'C44',
          'D11',
          'D48',
          'D12',
          'C21',
          'B47',
          'A4',
          'D1',
          'C56',
          'A32',
          'B37',
          'A6',
          'D24',
          'C46',
          'A48',
          'B23',
          'A44',
          'D42',
          'C30',
          'A60',
          'D5',
          'C39',
          'D7',
          'D56',
          'D2',
          'B2',
          'C24',
          'B46',
          'C57',
          'C16',
          'D16',
          'D46',
          'C42',
          'D40',
          'D17',
          'D32',
          'B3',
          'C18',
          'B22',
          'D50',
          'D9',
          'B19',
          'C46',
          'D28',
          'A11',
          'C8',
          'A31',
          'B42',
          'A24',
          'C14',
          'D58',
          'B20',
          'A52',
          'D59',
          'A55',
          'B16',
          'A20',
          'C21',
          'A3',
          'D49',
          'D10',
          'C50',
          'A35',
          'B31',
          'C44',
          'D36',
          'C53',
          'D4',
          'D53',
          'C28',
          'D45',
          'B39',
          'A41',
          'B27',
          'D56',
          'B15',
          'D12',
          'A12',
          'C51',
          'A16',
          'D21',
          'A4',
          'B28',
          'D8',
          'A23',
          'D16',
          'C35',
          'B29',
          'A47',
          'B55',
          'A38',
          'C13',
          'B19',
          'A18',
          'D1',
          'A41',
          'C4',
          'B41',
          'B56',
          'C28',
          'C8',
          'B10',
          'C12',
          'B59',
          'A42',
          'C37',
          'B21',
          'B58',
          'C58',
          'A31',
          'D47',
          'A49',
          'C24',
          'D55',
          'B50',
          'D54',
          'D7',
          'A21',
          'C49',
          'A57',
          'C31',
          'D41',
          'C44',
          'B15',
          'A50',
          'D10',
          'C42',
          'B45',
          'C2',
          'B46',
          'C30',
          'B9',
          'D39',
          'C45',
          'A56',
          'C54',
          'A33',
          'B33',
          'A7',
          'B34',
          'D26',
          'C29',
          'D50',
          'D9',
          'B5',
          'C21',
          'A22',
          'D33',
          'B1',
          'C49',
          'B49',
          'A6',
          'D40',
          'D20',
          'A2',
          'B48',
          'A30',
          'D12',
          'D48',
          'B23',
          'B56',
          'D55',
          'A56',
          'C9',
          'B33',
          'C19',
          'A33',
          'D30',
          'A28',
          'C39',
          'D50',
          'A48',
          'B34',
          'A20',
          'C33',
          'D40',
          'D20',
          'C56',
          'B44',
          'C25',
          'D5',
          'C17',
          'A29',
          'D31',
          'C55',
          'D22',
          'D37',
          'A16',
          'B40',
          'A47',
          'C50',
          'B58',
          'B6',
          'C10',
          'B18',
          'D7',
          'C15',
          'A44',
          'C36',
          'D4',
          'D60',
          'B22',
          'A52',
          'C23',
          'B11',
          'D17',
          'A13',
          'B30',
          'C46',
          'D1',
          'A11',
          'A40',
          'C42',
          'B3',
          'D30',
          'B7',
          'D28',
          'B43',
          'A1',
          'A43',
          'D35',
          'C55',
          'D53',
          'C52',
          'A60',
          'C15',
          'B33',
          'C38',
          'B26',
          'A47',
          'C27',
          'B49',
          'C40',
          'D47',
          'D14',
          'B14',
          'A22',
          'B40',
          'A41',
          'C2',
          'B47',
          'D48',
          'B24',
          'C3',
          'C39',
          'A10',
          'B38',
          'A42',
          'C1',
          'B6',
          'D43',
          'D15',
          'A11',
          'D35',
          'B25',
          'D25',
          'A1',
          'B53',
          'C60',
          'B54',
          'C35',
          'D17',
          'C27',
          'B14',
          'A58',
          'C29',
          'A28',
          'D40',
          'C41',
          'B45',
          'B60',
          'B16',
          'C44',
          'A47',
          'D11',
          'A26',
          'C59',
          'D6',
          'D57',
          'C18',
          'B55',
          'C25',
          'D29',
          'C43',
          'A57',
          'D34',
          'C57',
          'B57',
          'A44',
          'B30',
          'A18',
          'B35',
          'A7',
          'D2',
          'C10',
          'A10',
          'D27',
          'C50',
          'D13',
          'B1',
          'C46',
          'A26',
          'D6',
          'A46',
          'C55',
          'C18',
          'D50',
          'D9',
          'B15',
          'A22',
          'B29',
          'A49',
          'B17',
          'B54',
          'C29',
          'D14',
          'A33',
          'B44',
          'A3',
          'B45',
          'C38',
          'B58',
          'C49',
          'D45',
          'D19',
          'A14',
          'B18',
          'A8',
          'D44',
          'C30',
          'D48',
          'C23',
          'A57',
          'D23',
          'D38',
          'C46',
          'A35',
          'D39',
          'A31',
          'D21',
          'C22',
          'D4',
          'D58',
          'B22',
          'D49',
          'D10',
          'A18',
          'B37',
          'A11',
          'C3',
          'B2',
          'C19',
          'A48',
          'C2',
          'C18',
          'B58',
          'A38',
          'D33',
          'C59',
          'A56',
          'D51',
          'D8',
          'C47',
          'B34',
          'A33',
          'C51',
          'A19',
          'B54',
          'C43',
          'D45',
          'C25',
          'A42',
          'D17',
          'B12',
          'C12',
          'B60',
          'B3',
          'D58',
          'C23',
          'B35',
          'A48',
          'D60',
          'B39',
          'C29',
          'B17',
          'B48',
          'A1',
          'D27',
          'B38',
          'A9',
          'C17',
          'B58',
          'C30',
          'A53',
          'D47',
          'D14',
          'A16',
          'A42',
          'A15',
          'B40',
          'A27',
          'D59',
          'D2',
          'D55',
          'C20',
          'B47',
          'A32',
          'C28',
          'D54',
          'D7',
          'D53',
          'B13',
          'A57',
          'D31',
          'B32',
          'C53',
          'C31',
          'A35',
          'B27',
          'A19',
          'C36',
          'A45',
          'B25',
          'A50',
          'B56',
          'C42',
          'D52',
          'D5',
          'A43',
          'D57',
          'D6',
          'C58',
          'D19',
          'D45',
          'D25',
          'C44',
          'B21',
          'C19',
          'A28',
          'C12',
          'B17',
          'A55',
          'D58',
          'B25',
          'A36',
          'C15',
          'B34',
          'C38',
          'D15',
          'D46',
          'D16',
          'A21',
          'A2',
          'B52',
          'A7',
          'D10',
          'D49',
          'B13',
          'C53',
          'D12',
          'D48',
          'A14',
          'C3',
          'B43',
          'A41',
          'D2',
          'C8',
          'B30',
          'A12',
          'B57',
          'B26',
          'A27',
          'C33',
          'D36',
          'A42',
          'C17',
          'B23',
          'A59',
          'C26',
          'B42',
          'D54',
          'B45',
          'D50',
          'D9',
          'A20',
          'B52',
          'A46',
          'C53',
          'B28',
          'D56',
          'A49',
          'D2',
          'A25',
          'C7',
          'B33',
          'C52',
          'B50',
          'D53',
          'B57',
          'C35',
          'D35',
          'D20',
          'C31',
          'A50',
          'B9',
          'C33',
          'A24',
          'A2',
          'D22',
          'C54',
          'A57',
          'B43',
          'A26',
          'D41',
          'C51',
          'D3',
          'C56',
          'A38',
          'C2',
          'C48',
          'D15',
          'B13',
          'D48',
          'D12',
          'A23',
          'B10',
          'C46',
          'D13',
          'B14',
          'B51',
          'A16',
          'C8',
          'B15',
          'D1',
          'C16',
          'A51',
          'B24',
          'C37',
          'A31',
          'B50',
          'A17',
          'D24',
          'A53',
          'D21',
          'D43',
          'D28',
          'C41',
          'D55',
          'C26',
          'B4',
          'C45',
          'B10',
          'C28',
          'B41',
        ],
      },
    ],
  },
  {
    title: 'Mandala 2',
    id: 'mandala-2',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Mandala2.%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D22',
          'C59',
          'B35',
          'C12',
          'A50',
          'D27',
          'A4',
          'B40',
          'C17',
          'A53',
          'D30',
          'A7',
          'B49',
          'C27',
          'B50',
          'A12',
          'D35',
          'A58',
          'C22',
          'B45',
          'A9',
          'D32',
          'A55',
          'C13',
          'C55',
          'B37',
          'C15',
          'B38',
          'C60',
          'D23',
          'A46',
          'C10',
          'B33',
          'C57',
          'D20',
          'A42',
          'C5',
          'B28',
          'C4',
          'A40',
          'D16',
          'C53',
          'B29',
          'C6',
          'A44',
          'D21',
          'B41',
          'C18',
          'A56',
          'D33',
          'A10',
          'B46',
          'C22',
          'C59',
          'A38',
          'D15',
          'C52',
          'B27',
          'C51',
          'D14',
          'A36',
          'D59',
          'B22',
          'C46',
          'D9',
          'A32',
          'D54',
          'B17',
          'C41',
          'D4',
          'A28',
          'D52',
          'B15',
          'C39',
          'D2',
          'A24',
          'D47',
          'B10',
          'C34',
          'B57',
          'A21',
          'D44',
          'B7',
          'C25',
          'D49',
          'C31',
          'B13',
          'B55',
          'A13',
          'A54',
          'B33',
          'C56',
          'A31',
          'B6',
          'C29',
          'B52',
          'A16',
          'D40',
          'B3',
          'C27',
          'D47',
          'D9',
          'A33',
          'D56',
          'B60',
          'A23',
          'C46',
          'D10',
          'B22',
          'A45',
          'C9',
          'B32',
          'C54',
          'D17',
          'D57',
          'B20',
          'C42',
          'D5',
          'A29',
          'D6',
          'D49',
          'A31',
          'B13',
          'D51',
          'B14',
          'C36',
          'B59',
          'D39',
          'B2',
          'A23',
          'D47',
          'C39',
          'A16',
          'A52',
          'B40',
          'C16',
          'A4',
          'D28',
          'A51',
          'C15',
          'D35',
          'B57',
          'A20',
          'C41',
          'B17',
          'A40',
          'D17',
          'B39',
          'A3',
          'D26',
          'C4',
          'C52',
          'D16',
          'C5',
          'C45',
          'B21',
          'D58',
          'A34',
          'B11',
          'D48',
          'A26',
          'D3',
          'B23',
          'D60',
          'A38',
          'B19',
          'C37',
          'D55',
          'A37',
          'D19',
          'B37',
          'A55',
          'B30',
          'D26',
          'B43',
          'A1',
          'C19',
          'B1',
          'A6',
          'D29',
          'C9',
          'C46',
          'D58',
          'B22',
          'A34',
          'D11',
          'C48',
          'A27',
          'D50',
          'B54',
          'A17',
          'D41',
          'A18',
          'C33',
          'B56',
          'D42',
          'B5',
          'C29',
          'D51',
          'A28',
          'B16',
          'B53',
          'C30',
          'A9',
          'A53',
          'D29',
          'B51',
          'A15',
          'D38',
          'B42',
          'A5',
          'A44',
          'B29',
          'D9',
          'B17',
          'C40',
          'D4',
          'C58',
          'C10',
          'B34',
          'C28',
          'B52',
          'B4',
          'D41',
          'C21',
          'B44',
          'B5',
          'A21',
          'D45',
          'B8',
          'C30',
          'A25',
          'D7',
          'B31',
          'C49',
          'C7',
          'A49',
          'D31',
          'B48',
          'A11',
          'A50',
          'C55',
          'D13',
          'A31',
          'B26',
          'C3',
          'A39',
          'C2',
          'D6',
          'C40',
          'A28',
          'B35',
          'D27',
          'A51',
          'C14',
          'D18',
          'A41',
          'C57',
          'D21',
          'D59',
          'C39',
          'B15',
          'C38',
          'D42',
          'A22',
          'C34',
          'B58',
          'B10',
          'D46',
          'A52',
          'C16',
          'C53',
          'B30',
          'A31',
          'C49',
          'A44',
          'B47',
          'C24',
          'B2',
          'B37',
          'A1',
          'A36',
          'B15',
          'D51',
          'D11',
          'A35',
          'D12',
          'C8',
          'A45',
          'D21',
          'B29',
          'C5',
          'C57',
          'B4',
          'D58',
          'D9',
          'D47',
          'B39',
          'A2',
          'C6',
          'D20',
          'B44',
          'C21',
          'A57',
          'D34',
          'A10',
          'C22',
          'B28',
          'A51',
          'C41',
          'B18',
          'D14',
          'B31',
          'A49',
          'A14',
          'C35',
          'B11',
          'D3',
          'D41',
          'C33',
          'B9',
          'C32',
          'D36',
          'A59',
          'C23',
          'A60',
          'B56',
          'A18',
          'C43',
          'B25',
          'D1',
          'A19',
          'D37',
          'C13',
          'B36',
          'D32',
          'C18',
          'B41',
          'D33',
          'A9',
          'B46',
          'A58',
          'D52',
          'A29',
          'D53',
          'D15',
          'B23',
          'C47',
          'B24',
          'D58',
          'A5',
          'C28',
          'A16',
          'D39',
          'B47',
          'C24',
          'A19',
          'B14',
          'A23',
          'B59',
          'D51',
          'B16',
          'C10',
          'A47',
          'D24',
          'C20',
          'A57',
          'D33',
          'C11',
          'A22',
          'D46',
          'C34',
          'B40',
          'A29',
          'C22',
          'A33',
          'D57',
          'D5',
          'C41',
          'D34',
          'A40',
          'C52',
          'B58',
          'A51',
          'C40',
          'B46',
          'B9',
          'C59',
          'D23',
          'A47',
          'A3',
          'B5',
          'D41',
          'B33',
          'A22',
          'D28',
          'A34',
          'C46',
          'B52',
          'A15',
          'D39',
          'C17',
          'B41',
          'A5',
          'B15',
          'D22',
          'A16',
          'B4',
          'C28',
          'B53',
          'D45',
          'C30',
          'B39',
          'A47',
          'C44',
          'B21',
          'A11',
          'C4',
          'B10',
          'D44',
          'B48',
          'C22',
          'D10',
          'A4',
          'D53',
          'A30',
          'B9',
          'D16',
          'A10',
          'A58',
          'B35',
          'C12',
          'D8',
          'C45',
          'D9',
          'C40',
          'D52',
          'B12',
          'C35',
          'D57',
          'A34',
          'B45',
          'A53',
          'D4',
          'B16',
          'D50',
          'C53',
          'A32',
          'C37',
          'B19',
          'D1',
          'D44',
          'C20',
          'A23',
          'D33',
          'B53',
          'C30',
          'B26',
          'C48',
          'A43',
          'C1',
          'D18',
          'C52',
          'A58',
          'A22',
          'B10',
          'A3',
          'C24',
          'D20',
          'B24',
          'A21',
          'B49',
          'B7',
          'A12',
          'C37',
          'B25',
          'D7',
          'A7',
          'D25',
          'A25',
          'D43',
          'B1',
          'A26',
          'B58',
          'C10',
          'B36',
          'C59',
          'D28',
          'C16',
          'B22',
          'D33',
          'C22',
          'B28',
          'A40',
          'B51',
          'A14',
          'C19',
          'A47',
          'C11',
          'A3',
          'D27',
          'B47',
          'C23',
          'D15',
          'A39',
          'D16',
          'C50',
          'B27',
          'A16',
          'D36',
          'B50',
          'C27',
          'D35',
          'A59',
          'C32',
          'B58',
          'C9',
          'D17',
          'A41',
          'C34',
          'A45',
          'D22',
          'A28',
          'D5',
          'A58',
          'C51',
          'D59',
          'B51',
          'B11',
          'C40',
          'D29',
          'C21',
          'B45',
          'A8',
          'A43',
          'D25',
          'B49',
          'A16',
          'C5',
          'B57',
          'C33',
          'B23',
          'D60',
          'C56',
          'A41',
          'D51',
          'C58',
          'B34',
          'A46',
          'D40',
          'B6',
          'D2',
          'C39',
          'B7',
          'C7',
          'C43',
          'A38',
          'B41',
          'C51',
          'C12',
          'A52',
          'D29',
          'A5',
          'B16',
          'D27',
          'C35',
          'B44',
          'D48',
          'C44',
          'D10',
          'B3',
          'C26',
          'D30',
          'C59',
          'B10',
          'D41',
          'C52',
          'B28',
          'C2',
          'A29',
          'D39',
          'B14',
          'C55',
          'B55',
          'B19',
          'C31',
          'A13',
          'D31',
          'A31',
          'B59',
          'A9',
          'D58',
          'B46',
          'C17',
          'A28',
          'D34',
          'A5',
          'A56',
          'A1',
          'D19',
          'A19',
          'B18',
          'A15',
          'D5',
          'A37',
          'D37',
          'A55',
          'D55',
          'C31',
          'B31',
          'D13',
          'A13',
          'D45',
          'C48',
          'C3',
          'D23',
          'C15',
          'B38',
          'A43',
          'B25',
          'C43',
          'B43',
          'B1',
          'C1',
          'B19',
          'C19',
          'D36',
          'B28',
          'D16',
          'C28',
          'B34',
          'A3',
          'B30',
          'D50',
          'B21',
          'A10',
          'B41',
          'A34',
          'C27',
          'A54',
          'C17',
          'D9',
          'A58',
          'C47',
          'B56',
          'C24',
          'A43',
          'D43',
          'A16',
          'A53',
          'C3',
          'D11',
          'B33',
          'A18',
          'C15',
          'B5',
          'B57',
          'A46',
          'D22',
          'C33',
          'A10',
          'B43',
          'C55',
          'B23',
          'D59',
          'A35',
          'D45',
          'A22',
          'D51',
          'B38',
          'D42',
          'C58',
          'D47',
          'B58',
          'C5',
          'A41',
          'C13',
          'B13',
          'A38',
          'B37',
          'C49',
          'D7',
          'A34',
          'D14',
          'C42',
          'B51',
          'A59',
          'A15',
          'C23',
          'B32',
          'C9',
          'A16',
          'B22',
          'D29',
          'B40',
          'C34',
          'B5',
          'D16',
          'C27',
          'A12',
          'B52',
          'C26',
          'A25',
          'B20',
          'C11',
          'A48',
          'B27',
          'D58',
          'B51',
          'D40',
          'B29',
          'C39',
          'A42',
          'B58',
          'A21',
          'D11',
          'D51',
          'A19',
          'B42',
          'C30',
          'D2',
          'C6',
          'B15',
          'C35',
          'B59',
          'D39',
          'B3',
          'A24',
          'C43',
          'D1',
          'D37',
          'B37',
          'C37',
          'C1',
          'D25',
          'A57',
          'D28',
          'A48',
          'C49',
          'B49',
          'A1',
          'A60',
          'B39',
          'C59',
          'B52',
          'A45',
          'D35',
          'C46',
          'B23',
          'C52',
          'D44',
          'B27',
          'C30',
          'D5',
          'C18',
          'D42',
          'A39',
          'C60',
          'D12',
          'D55',
          'D19',
          'A51',
          'D41',
          'B12',
          'A9',
          'A54',
          'B49',
          'C13',
          'C48',
          'B2',
          'C43',
          'B31',
          'A36',
          'C9',
          'A59',
          'C31',
          'B13',
          'C25',
          'A7',
          'C12',
          'B44',
          'C32',
          'A35',
          'C51',
          'D15',
          'B35',
          'A8',
          'D34',
          'C22',
          'D20',
          'C5',
          'A20',
          'B5',
          'C28',
          'B54',
          'A51',
          'C36',
          'B32',
          'D60',
          'B9',
          'D53',
          'C45',
          'B16',
          'D18',
          'B42',
          'C16',
          'A47',
          'D57',
          'D5',
          'B2',
          'A7',
          'B55',
          'A27',
          'D3',
          'C54',
          'A33',
          'D23',
          'B11',
          'C21',
          'B56',
          'D58',
          'A29',
          'B44',
          'A49',
          'D49',
          'D13',
          'B37',
          'C10',
          'B39',
          'C46',
          'A52',
          'D26',
          'C39',
          'D14',
          'C18',
          'B27',
          'C47',
          'B2',
          'D53',
          'C33',
          'B10',
          'B47',
          'A19',
          'C37',
          'A36',
          'D10',
          'C43',
          'B6',
          'D46',
          'A23',
          'D9',
          'A12',
          'A51',
          'C5',
          'D2',
          'A5',
          'D15',
          'C32',
          'D27',
          'A17',
          'D40',
          'B33',
          'D22',
          'C58',
          'A46',
          'B23',
          'D48',
          'B46',
          'C53',
          'B4',
          'B41',
          'C6',
          'B18',
          'D52',
          'C49',
          'D25',
          'B25',
          'C25',
          'B7',
          'C19',
          'A38',
          'D1',
          'A1',
          'B5',
          'C58',
          'D24',
          'A59',
          'A22',
          'B29',
          'C4',
          'B52',
          'C46',
          'D39',
          'C28',
          'A39',
          'D29',
          'C30',
          'A45',
          'A6',
          'B21',
          'C41',
          'D12',
          'A40',
          'B47',
          'A16',
          'D60',
          'C39',
          'B10',
          'C17',
          'B14',
          'C59',
          'D8',
          'B12',
          'A27',
          'D17',
          'C53',
          'D24',
          'C7',
          'D31',
          'C14',
          'B34',
          'D22',
          'B11',
          'C43',
          'A25',
          'C50',
          'D21',
          'A11',
          'B22',
          'C16',
          'B31',
          'A3',
          'B53',
          'C9',
          'A12',
          'D40',
          'A34',
          'D54',
          'B17',
          'B57',
          'C28',
          'B21',
          'D49',
          'B25',
          'A50',
          'D53',
          'B45',
          'D23',
          'C36',
          'A55',
          'A13',
          'B8',
          'C53',
          'B33',
          'A41',
          'D27',
          'C31',
          'B49',
          'A7',
          'C3',
          'B26',
          'A11',
          'B47',
          'C57',
          'C18',
          'B30',
          'A27',
          'C35',
          'B32',
          'D45',
          'D5',
          'C16',
          'A49',
          'C54',
          'D6',
          'C2',
          'A25',
          'B44',
          'C9',
          'A40',
          'A4',
          'A52',
          'D8',
          'D54',
          'C50',
          'B17',
          'A33',
          'C18',
          'B50',
          'C53',
          'D9',
          'A44',
          'C20',
          'A19',
          'D7',
          'D43',
          'C26',
          'D50',
          'B2',
          'C22',
          'B15',
          'D19',
          'C52',
          'D45',
          'B9',
          'C58',
          'D4',
          'A24',
          'B40',
          'C14',
          'B54',
          'D37',
          'C37',
          'B55',
          'D31',
          'B7',
          'D49',
          'B49',
          'A52',
          'C45',
          'B35',
          'C11',
          'D22',
          'A51',
          'D58',
          'A38',
          'C42',
          'B6',
          'D54',
          'B26',
          'D39',
          'B33',
          'D36',
          'C53',
          'A33',
          'D10',
          'C12',
          'B24',
          'C57',
          'D32',
          'B3',
          'B48',
          'C36',
          'D8',
          'C56',
          'A55',
          'C7',
          'B25',
          'A30',
          'C55',
          'B31',
          'C49',
          'B17',
          'C27',
          'A58',
          'C5',
          'D27',
          'B14',
          'C2',
          'A34',
          'C23',
          'D16',
          'A22',
          'B53',
          'C4',
          'A20',
          'D40',
          'C28',
          'B43',
          'C25',
          'D1',
          'A33',
          'C41',
          'B51',
          'A39',
          'D53',
          'B17',
          'C14',
          'A38',
          'B23',
          'D2',
          'D48',
          'B30',
          'A7',
          'C32',
          'A31',
          'C3',
          'A15',
          'B42',
          'A43',
          'D11',
          'C48',
          'D20',
          'C45',
          'C6',
          'A46',
          'D52',
          'B40',
          'D28',
          'C35',
          'D50',
          'B54',
          'C59',
          'B56',
          'C44',
          'D27',
          'A49',
          'A14',
          'B13',
          'A9',
          'C17',
          'A57',
          'B42',
          'D59',
          'A45',
          'B57',
          'A11',
          'D43',
          'B19',
          'A44',
          'C48',
          'B7',
          'D4',
          'C38',
          'D18',
          'A53',
          'C22',
          'D45',
          'A48',
          'D7',
          'B3',
          'C6',
          'B48',
          'A2',
          'B1',
          'D34',
          'B46',
          'C40',
          'B33',
          'C24',
          'D11',
          'C36',
          'A15',
          'D29',
          'A26',
          'B26',
          'A7',
          'B35',
          'A4',
          'B41',
          'D21',
          'A35',
          'C50',
          'D10',
          'D58',
          'B4',
          'A28',
          'D8',
          'A30',
          'C27',
          'A39',
          'D7',
          'C16',
          'A23',
          'D55',
          'B13',
          'C37',
          'B25',
          'C57',
          'B28',
          'D5',
          'B20',
          'A41',
          'C4',
          'D2',
          'C20',
          'C55',
          'B37',
          'C1',
          'C43',
          'D39',
          'A53',
          'D6',
          'B5',
          'C2',
          'A48',
          'C25',
          'B1',
          'C13',
          'B31',
          'A27',
          'D41',
          'B24',
          'A1',
          'B43',
          'D43',
          'B10',
          'D26',
          'A29',
          'D15',
          'D53',
          'D16',
          'C18',
          'A14',
          'D34',
          'A54',
          'D51',
          'C55',
          'B59',
          'A55',
          'C60',
          'C25',
          'B52',
          'A36',
          'D50',
          'A22',
          'B55',
          'D55',
          'A28',
          'C12',
          'B8',
          'B53',
          'D59',
          'A10',
          'A46',
          'C40',
          'B12',
          'D60',
          'D14',
          'A17',
          'B9',
          'C29',
          'A21',
          'D56',
          'A59',
          'C52',
          'D12',
          'C8',
          'A8',
          'D28',
          'B26',
          'D25',
          'B43',
          'B7',
          'A25',
          'B48',
          'B13',
          'D41',
          'B56',
          'C33',
          'B36',
          'C24',
          'B21',
          'C11',
          'D3',
          'C53',
          'B5',
          'A28',
          'B39',
          'C42',
          'A56',
          'C32',
          'B18',
          'D35',
          'A11',
          'C7',
          'A35',
          'D32',
          'C35',
          'B51',
          'C27',
          'B57',
          'C26',
          'A58',
          'D14',
          'B19',
          'D52',
          'B45',
          'C48',
          'D25',
          'C25',
          'D43',
          'A31',
          'B54',
          'A54',
          'C54',
          'C9',
          'B39',
          'C8',
          'A40',
          'D20',
          'A23',
          'C19',
          'B1',
          'D10',
          'A17',
          'D3',
          'C20',
          'A5',
          'D37',
          'B13',
          'C50',
          'B36',
          'A33',
          'D36',
          'B34',
          'C57',
          'D46',
          'A26',
          'C10',
          'A7',
          'A49',
          'C1',
          'B16',
          'A9',
          'D23',
          'B19',
          'C60',
          'C15',
          'A19',
          'C44',
          'B30',
          'A30',
          'D44',
          'A56',
          'D59',
          'B54',
          'C42',
          'D41',
          'D3',
          'C39',
          'A46',
          'C30',
          'D31',
          'C59',
          'B45',
          'D51',
          'B59',
          'C24',
          'D1',
          'A25',
          'C28',
          'B34',
          'A26',
          'B39',
          'A59',
          'D11',
          'D47',
          'A57',
          'A18',
          'B58',
          'B21',
          'C45',
          'B48',
          'A49',
          'C7',
          'D25',
          'B16',
          'D56',
          'B8',
          'A12',
          'B31',
          'D55',
          'A14',
          'D17',
          'C42',
          'D57',
          'A4',
          'D24',
          'A21',
          'D35',
          'B57',
          'A22',
          'D2',
          'A46',
          'D19',
          'C28',
          'B48',
          'A24',
          'B20',
          'D52',
          'A8',
          'D11',
          'C12',
          'B9',
          'C23',
          'B52',
          'B15',
          'B60',
          'C40',
          'D24',
          'C11',
          'A23',
          'D32',
          'A58',
          'D38',
          'C51',
          'B28',
          'A36',
          'D16',
          'A60',
          'C14',
          'A46',
          'C35',
          'A55',
          'C27',
          'A4',
          'A52',
          'D59',
          'A19',
          'B38',
          'D40',
          'C17',
          'B3',
          'D31',
          'B10',
          'C4',
          'C41',
          'B6',
          'C34',
          'B27',
          'D12',
          'C46',
          'A53',
          'C50',
          'A39',
          'B16',
          'C39',
          'D28',
          'A1',
          'A43',
          'C44',
          'B39',
          'C29',
          'B26',
          'D51',
          'B40',
          'D50',
          'B55',
          'A51',
          'D56',
          'B22',
          'C45',
          'A29',
          'C1',
          'A34',
          'C18',
          'B36',
          'D54',
          'B3',
          'C10',
          'D3',
          'A10',
          'C13',
          'A49',
          'D17',
          'C9',
          'D29',
          'B24',
          'C38',
          'B50',
          'A55',
          'D51',
          'D12',
          'C32',
          'B52',
          'D40',
          'A15',
          'B22',
          'A6',
          'B44',
          'A4',
          'A41',
          'D6',
          'A3',
          'D38',
          'B4',
          'D20',
          'C21',
          'A48',
          'D36',
          'B54',
          'B9',
          'D46',
          'A40',
          'D60',
          'C60',
          'A60',
          'D57',
          'A1',
          'D16',
          'C56',
          'A45',
          'C54',
          'B51',
          'A5',
          'B58',
          'A50',
          'B35',
          'A23',
          'B15',
          'D32',
          'C55',
          'D35',
          'B59',
          'C12',
          'A31',
          'C8',
          'B11',
          'B50',
          'A10',
          'A58',
          'C21',
          'C58',
          'D13',
          'D49',
          'B4',
          'D1',
          'C23',
          'B53',
          'A25',
          'C16',
          'D9',
          'C59',
          'C14',
          'C50',
          'D55',
          'A43',
          'A8',
          'D3',
          'D48',
          'B49',
          'A13',
          'B41',
          'A56',
          'C41',
          'B57',
          'D46',
          'A2',
          'A37',
          'B19',
          'D18',
          'C35',
          'D4',
          'C20',
          'B32',
          'A36',
          'B55',
          'C51',
          'D50',
          'B45',
          'A33',
          'B37',
          'A14',
          'B60',
          'C34',
          'B7',
          'D35',
          'C15',
          'D9',
          'D54',
          'A51',
          'D42',
          'C37',
          'B41',
          'A37',
          'C1',
          'B39',
          'D56',
          'B27',
          'C3',
          'A10',
          'D30',
          'B17',
          'C46',
          'B2',
          'C38',
          'A13',
          'D1',
          'C21',
          'A6',
          'B30',
          'C27',
          'B31',
          'A56',
          'C40',
          'B13',
          'D58',
          'B32',
          'D55',
          'B57',
          'C14',
          'D18',
          'B33',
          'C36',
          'A36',
          'D33',
          'A47',
          'C43',
          'B47',
          'A30',
          'B21',
          'D30',
          'A39',
          'C48',
          'B60',
          'C57',
          'A60',
          'C5',
          'B2',
          'D2',
          'A43',
          'C15',
          'A27',
          'C2',
          'B22',
          'C29',
          'B15',
          'C18',
          'A18',
          'B42',
          'C56',
          'B19',
          'C31',
          'B7',
          'D30',
          'C35',
          'D58',
          'D20',
          'A11',
          'A50',
          'C10',
          'B16',
          'A8',
          'D44',
          'B49',
          'D25',
          'B27',
          'A37',
          'D49',
          'D13',
          'A25',
          'C6',
          'B24',
          'C52',
          'D36',
          'C12',
          'A5',
          'B30',
          'D59',
          'B8',
          'C11',
          'C51',
          'A28',
          'D1',
          'C46',
          'A54',
          'A19',
          'B55',
          'C28',
          'D21',
          'C38',
          'B5',
          'C37',
          'A33',
          'C8',
          'B20',
          'A20',
          'D6',
          'C26',
          'D31',
          'B33',
          'D10',
          'A16',
          'B56',
          'A56',
          'C60',
          'B57',
          'C47',
          'D16',
          'A43',
          'B18',
          'C46',
          'A57',
          'C11',
          'B27',
          'D39',
          'D3',
          'A6',
          'D15',
          'B14',
          'D37',
          'C19',
          'B34',
          'D9',
          'C14',
          'B26',
          'A47',
          'B59',
          'C37',
          'B19',
          'A33',
          'D47',
          'B40',
          'A43',
          'C7',
          'B44',
          'D57',
          'D21',
          'A5',
          'D35',
          'A32',
          'D52',
          'B36',
          'C16',
          'B9',
          'C54',
          'A50',
          'C31',
          'B31',
          'C43',
          'B3',
          'D53',
          'B16',
          'D32',
          'C34',
          'D1',
          'C1',
          'B28',
          'A17',
          'B6',
          'A7',
          'D11',
          'C47',
          'A26',
          'C26',
          'B38',
          'C15',
          'B39',
          'D24',
          'C20',
          'B23',
          'A19',
          'B1',
          'C57',
          'D44',
          'B4',
          'D35',
          'D51',
          'B20',
          'C23',
          'D17',
          'B40',
          'A7',
          'D34',
          'B27',
          'A15',
          'B43',
          'A39',
          'C24',
          'A20',
          'D56',
          'A42',
          'D39',
          'B47',
          'C56',
          'D22',
          'A2',
          'B53',
          'C49',
          'A53',
          'B32',
          'D50',
          'C25',
          'A58',
          'C18',
          'D33',
          'B8',
          'C30',
          'D12',
          'B9',
          'D59',
          'B31',
          'D29',
          'B42',
          'C39',
          'A24',
          'D21',
          'C10',
          'A21',
          'C42',
          'B55',
          'A13',
          'B12',
          'C26',
          'D8',
          'A11',
          'B23',
          'D43',
          'A3',
          'C29',
          'B45',
          'D22',
          'A42',
          'C19',
          'A55',
          'C13',
          'D13',
          'A45',
          'D48',
          'C5',
          'B21',
          'A29',
          'B54',
          'A46',
          'D26',
          'A17',
          'C31',
          'A35',
          'B14',
          'C50',
          'B27',
          'C41',
          'B36',
          'D31',
          'C7',
          'B11',
          'C34',
          'D8',
          'C21',
          'A28',
          'B25',
          'C52',
          'B45',
          'D49',
          'A25',
          'D57',
          'B32',
          'D43',
          'C42',
          'A58',
          'D34',
          'B59',
          'C36',
          'D13',
          'C12',
          'D18',
          'A6',
          'B46',
          'A35',
          'B60',
          'A9',
          'A54',
          'C31',
          'D36',
          'C46',
          'D6',
          'C23',
          'D19',
          'A31',
          'C55',
          'D60',
          'A57',
          'D41',
          'A38',
          'C58',
          'B14',
          'C34',
          'D23',
          'A32',
          'B7',
          'C3',
          'D27',
          'B47',
          'B2',
          'B38',
          'A14',
          'D58',
          'B42',
          'A42',
          'C18',
          'B53',
          'A49',
          'C21',
          'B25',
          'C43',
          'D22',
          'C10',
          'B30',
          'C4',
          'A44',
          'C44',
          'A29',
          'C26',
          'B46',
          'A13',
          'C9',
          'A60',
          'D40',
          'D56',
          'B60',
          'C27',
          'A56',
          'D47',
          'B3',
          'C8',
          'B48',
          'A23',
          'C46',
          'B19',
          'C15',
          'C52',
          'D7',
          'A3',
          'A48',
          'C48',
          'B28',
          'D30',
          'B36',
          'C5',
          'B15',
          'D44',
          'A35',
          'C23',
          'A2',
          'B34',
          'C8',
          'D13',
          'A37',
          'B12',
          'A12',
          'C12',
          'A36',
          'C28',
          'B8',
          'C52',
          'B54',
          'A22',
          'B30',
          'C39',
          'D9',
          'C13',
          'A46',
          'B35',
          'C31',
          'B44',
          'D29',
          'A58',
          'B55',
          'C40',
          'D7',
          'D50',
          'A15',
          'D6',
          'B21',
          'D26',
          'D11',
          'A40',
          'C24',
          'D39',
          'B9',
          'D38',
          'B33',
          'A21',
          'C32',
          'A32',
          'D18',
          'C58',
          'D49',
          'D13',
          'B16',
          'B51',
          'C56',
          'B20',
          'D33',
          'B13',
          'C25',
          'B57',
          'C38',
          'B58',
          'D46',
          'B12',
          'C45',
          'B2',
          'A10',
          'B26',
          'C17',
          'B27',
          'D32',
          'C26',
          'A34',
          'D5',
          'A2',
          'A51',
          'C39',
          'B43',
          'A7',
          'D48',
          'C4',
          'D16',
          'B41',
          'D54',
          'C54',
          'B10',
          'D55',
          'A31',
          'B50',
          'A50',
          'C59',
          'D15',
          'C46',
          'D30',
          'A27',
          'D51',
          'A3',
          'B7',
          'D40',
          'B24',
          'A49',
          'A14',
          'A59',
          'D45',
          'B20',
          'C7',
          'A40',
          'B56',
          'B17',
          'A5',
          'C2',
          'A53',
          'C16',
          'A56',
          'D42',
          'A19',
          'C29',
          'D12',
          'A26',
          'B1',
          'D57',
          'C46',
          'B26',
          'D49',
          'A17',
          'B53',
          'A41',
          'D38',
          'B42',
          'C47',
          'A39',
          'C14',
          'B58',
          'C8',
          'A54',
          'C30',
          'D17',
          'D32',
          'C45',
          'B49',
          'D47',
          'C27',
          'D2',
          'D44',
          'A47',
          'C35',
          'B28',
          'D25',
          'C20',
          'B6',
          'C15',
          'B45',
          'A25',
          'D37',
          'B15',
          'D59',
          'A54',
          'C22',
          'A38',
          'D3',
          'C47',
          'B33',
          'D55',
          'B35',
          'D48',
          'A28',
          'B44',
          'A48',
          'C24',
          'B4',
          'C56',
          'B28',
          'D60',
          'D18',
          'B10',
          'D50',
          'A45',
          'C53',
          'B48',
          'C5',
          'B59',
          'A22',
          'C48',
          'B12',
          'D7',
          'D43',
          'B45',
          'C36',
          'A52',
          'C20',
          'B15',
          'C38',
          'A46',
          'B39',
          'D30',
          'A4',
          'C7',
          'C42',
          'B56',
          'A27',
          'C31',
          'B58',
          'C52',
          'B29',
          'A40',
          'C43',
          'D48',
          'A22',
          'C15',
          'B50',
          'D25',
          'D40',
          'C51',
          'A36',
          'B36',
          'D27',
          'A11',
          'D59',
          'C34',
          'B49',
          'C26',
          'D16',
          'A55',
          'B52',
          'D29',
          'B12',
          'C21',
          'D41',
          'C16',
          'B43',
          'D52',
          'B7',
          'A8',
          'B54',
          'B19',
          'D57',
          'B21',
          'C44',
          'B35',
          'D36',
          'A45',
          'A5',
          'B60',
          'D16',
          'C32',
          'A14',
          'B3',
          'A23',
          'D39',
          'D4',
          'D53',
          'A6',
          'B25',
          'C49',
          'B8',
          'A8',
          'B31',
          'C4',
          'B24',
          'C60',
          'B40',
          'A33',
          'D17',
          'C18',
          'A37',
          'C9',
          'B13',
          'C54',
          'D22',
          'D57',
          'A48',
          'D4',
          'C37',
          'B25',
          'C2',
          'C47',
          'B44',
          'A20',
          'D23',
          'B38',
          'C41',
          'D56',
          'B30',
          'D46',
          'A17',
          'D33',
          'C9',
          'B33',
          'A53',
          'A16',
          'C19',
          'B23',
          'A37',
          'A1',
          'C4',
          'A58',
          'D7',
          'C31',
          'B31',
          'D40',
          'A11',
          'C34',
          'D25',
          'A12',
          'B3',
          'C52',
          'D55',
          'A59',
          'C22',
          'D12',
          'A47',
          'C3',
          'B50',
          'D54',
          'B53',
          'B4',
          'D15',
          'A18',
          'B34',
          'A1',
          'D5',
          'C35',
          'B30',
          'D24',
          'C50',
          'A50',
          'C47',
          'D25',
          'D40',
          'B8',
          'C44',
          'A54',
          'D42',
          'A6',
          'C5',
          'B6',
          'A21',
          'B25',
          'D58',
          'A35',
          'B24',
          'A25',
          'B58',
          'C7',
          'B22',
          'C42',
          'D3',
          'A52',
          'D32',
          'C33',
          'D8',
          'A34',
          'C58',
          'B35',
          'D46',
          'A39',
          'D44',
          'C45',
          'B13',
          'B59',
          'A15',
          'D19',
          'C56',
          'C17',
          'B12',
          'D38',
          'B51',
          'A26',
          'B11',
          'A7',
          'D39',
          'A30',
          'D10',
          'C55',
          'A37',
          'B57',
          'A17',
          'C5',
          'A25',
          'D52',
          'A1',
          'C11',
          'B6',
          'B45',
          'D20',
          'D35',
          'A51',
          'C31',
          'B46',
          'C20',
          'B38',
          'D37',
          'B17',
          'C13',
          'A3',
          'B32',
          'A27',
          'D18',
          'A23',
          'D54',
          'D10',
          'A54',
          'C29',
          'A22',
          'C6',
          'D4',
          'D20',
          'A8',
          'A43',
          'C10',
          'C47',
          'B48',
          'C17',
          'B33',
          'D50',
          'A13',
          'D36',
          'C30',
          'A59',
          'D19',
          'C14',
          'B9',
          'C57',
          'A42',
          'B21',
          'C35',
          'B45',
          'C7',
          'A16',
          'B24',
          'C33',
          'B37',
          'C49',
          'A45',
          'D16',
          'C25',
          'A1',
          'D41',
          'B36',
          'D12',
          'A21',
          'D5',
          'B1',
          'B56',
          'A39',
          'D23',
          'C3',
          'D2',
          'C50',
          'B41',
          'A29',
          'D45',
          'A52',
          'C19',
          'C55',
          'D46',
          'C21',
          'A10',
          'B47',
          'A58',
          'D31',
          'B27',
          'C32',
          'A15',
          'D22',
          'A49',
          'B58',
          'A4',
          'D48',
          'A46',
          'C9',
          'B56',
          'D50',
          'B59',
          'C29',
          'B48',
          'C4',
          'B6',
          'B41',
          'A52',
          'D25',
          'C11',
          'A31',
          'D7',
          'C45',
          'B52',
          'A19',
          'D31',
          'A43',
          'C39',
          'B33',
          'C23',
          'B16',
          'C43',
          'B19',
          'A22',
          'B38',
          'A59',
          'C16',
          'A5',
          'B20',
          'A38',
          'B46',
          'C12',
          'D20',
          'C52',
          'B32',
          'A16',
          'D18',
          'A54',
          'D52',
          'C29',
          'B53',
          'D9',
          'C26',
          'D10',
          'C19',
          'B35',
          'C56',
          'B58',
          'D46',
          'B13',
          'D17',
          'A13',
          'C37',
          'A46',
          'D7',
          'C35',
          'A50',
          'C34',
          'B54',
          'C13',
          'B28',
          'A31',
          'C27',
          'B21',
          'D51',
          'A35',
          'D28',
          'C3',
          'D11',
          'D50',
          'C50',
          'D30',
          'D46',
          'A19',
          'D57',
          'B11',
          'D47',
          'B41',
          'A24',
          'D38',
          'A50',
          'C58',
          'B6',
          'D1',
          'B26',
          'C57',
          'A43',
          'D19',
          'C18',
          'A52',
          'C31',
          'D8',
          'C45',
          'D39',
          'A46',
          'B55',
          'D37',
          'A10',
          'B42',
          'C43',
          'B43',
          'C55',
          'D27',
          'A30',
          'C41',
          'D51',
          'A42',
          'B18',
          'D34',
          'B25',
          'A5',
          'B31',
          'A55',
          'D14',
          'A2',
          'D25',
          'C7',
          'B39',
          'C53',
          'C8',
          'D21',
          'A56',
          'D9',
          'C31',
          'D33',
          'B35',
          'C28',
          'A60',
          'D48',
          'C4',
          'A36',
          'C23',
          'A43',
          'C1',
          'B5',
          'C36',
          'A16',
          'D14',
          'C27',
          'B31',
          'A34',
          'C37',
          'A41',
          'B42',
          'A3',
          'D10',
          'B21',
          'A20',
          'B45',
          'C14',
          'B15',
          'D4',
          'A33',
          'C32',
          'A57',
          'D1',
          'A19',
          'C33',
          'D3',
          'C9',
          'A21',
          'C20',
          'A45',
          'D52',
          'B50',
          'C13',
          'B40',
          'A32',
          'C40',
          'B14',
          'A59',
          'C15',
          'D2',
          'C1',
          'D25',
          'A37',
          'C51',
          'D5',
          'D45',
          'A49',
          'B35',
          'A60',
          'D37',
          'B42',
          'B57',
          'A27',
          'D34',
          'A11',
          'D6',
          'A58',
          'D51',
          'B28',
          'C22',
          'B37',
          'C19',
          'B7',
          'D27',
          'B48',
          'D58',
          'D42',
          'C59',
          'A14',
          'B39',
          'C38',
          'D22',
          'C24',
          'A4',
          'B36',
          'A35',
          'B27',
          'C30',
          'D40',
          'D25',
          'D9',
          'A31',
          'C41',
          'B21',
          'C26',
          'B3',
          'A7',
          'D58',
          'C14',
          'A27',
          'D11',
          'B13',
          'B49',
          'C36',
          'B16',
          'C9',
          'D8',
          'A44',
          'A8',
          'D51',
          'B5',
          'D30',
          'B45',
          'C50',
          'D56',
          'C57',
          'D1',
          'C48',
          'B4',
          'A12',
          'D47',
          'A27',
          'C53',
          'B13',
          'D22',
          'C21',
          'A14',
          'D26',
          'C11',
          'B54',
          'A60',
          'A25',
          'B29',
          'A17',
          'D12',
          'D50',
          'B34',
          'D43',
          'A47',
          'B27',
          'C26',
          'D21',
          'B8',
          'A29',
          'C43',
          'B4',
          'C32',
          'A40',
          'C3',
          'B52',
          'C37',
          'B60',
          'A59',
          'C8',
          'B28',
          'D44',
          'D5',
          'A14',
          'C9',
          'A48',
          'B53',
          'A16',
          'D55',
          'C59',
          'B11',
          'C4',
          'B33',
          'C2',
          'A41',
          'B54',
          'C23',
          'D32',
          'B38',
          'C58',
          'A35',
          'C6',
          'B29',
          'C46',
          'A58',
          'A21',
          'D25',
          'C45',
          'D15',
          'A50',
          'D36',
          'B58',
          'B9',
          'C8',
          'D52',
          'A3',
          'B47',
          'C40',
          'C3',
          'B17',
          'D42',
          'A11',
          'B31',
          'D51',
          'A20',
          'C27',
          'D7',
          'C2',
          'B52',
          'B12',
          'A23',
          'D3',
          'C32',
          'B18',
          'D60',
          'B37',
          'D59',
          'B55',
          'A35',
          'D15',
          'C37',
          'B10',
          'C30',
          'A47',
          'D40',
          'A33',
          'C21',
          'D23',
          'A54',
          'D28',
          'A5',
          'B45',
          'B5',
          'C14',
          'A22',
          'D49',
          'D13',
          'A9',
          'C35',
          'A31',
          'C10',
          'B25',
          'A42',
          'B35',
          'D15',
          'A20',
          'C44',
          'B9',
          'D14',
          'A40',
          'D33',
          'C20',
          'B21',
          'C56',
          'A56',
          'D4',
          'D52',
          'C54',
          'D29',
          'A45',
          'C48',
          'A3',
          'D17',
          'B30',
          'C29',
          'D42',
          'B41',
          'C18',
          'D34',
          'B2',
          'A2',
          'D38',
          'A33',
          'D2',
          'A4',
          'D31',
          'D16',
          'A14',
          'D9',
          'A49',
          'C6',
          'C59',
          'B24',
          'D3',
          'A51',
          'C21',
          'B4',
          'C31',
          'D10',
          'A37',
          'C4',
          'A13',
          'D40',
          'B46',
          'C48',
          'B14',
          'C16',
          'B23',
          'D53',
          'C28',
          'B11',
          'D12',
          'B37',
          'B52',
          'D27',
          'A18',
          'B1',
          'B51',
          'C50',
          'C11',
          'C26',
          'A39',
          'D8',
          'A51',
          'D20',
          'C44',
          'A45',
          'C33',
          'A50',
          'C1',
          'A53',
          'D49',
          'D33',
          'C45',
          'C10',
          'B12',
          'D54',
          'A10',
          'D17',
          'C55',
          'B47',
          'C12',
          'D37',
          'A41',
          'C50',
          'D31',
          'B22',
          'D55',
          'A37',
          'C17',
          'A42',
          'D47',
          'B16',
          'A7',
          'D20',
          'B25',
          'B60',
          'A52',
          'B26',
          'C54',
          'B34',
          'C41',
          'B42',
          'A17',
          'A33',
          'C59',
          'B19',
          'D56',
          'D2',
          'C44',
          'B30',
          'A29',
          'B9',
          'A8',
          'B32',
          'C20',
          'D27',
          'C30',
          'B43',
          'A24',
          'B58',
          'C51',
          'B3',
          'C38',
          'D28',
          'B12',
          'A4',
          'D55',
          'B46',
          'C7',
          'B47',
          'D22',
          'A47',
          'B21',
          'D38',
          'C40',
          'D3',
          'C1',
          'B14',
          'A18',
          'D53',
          'B24',
          'C23',
          'A48',
          'D25',
          'B1',
          'A15',
          'B44',
          'C16',
          'B36',
          'A28',
          'C48',
          'B17',
          'C34',
          'A14',
          'D37',
          'B23',
          'C32',
          'D32',
          'B49',
          'D5',
          'A59',
          'C29',
          'D44',
          'A43',
          'D30',
          'B29',
          'C38',
          'D51',
          'B50',
          'C41',
          'D19',
          'C47',
          'A48',
          'D53',
          'B48',
          'C3',
          'A32',
          'C39',
          'B46',
          'A15',
          'C58',
          'C9',
          'A52',
          'C15',
          'A45',
          'C32',
          'B33',
          'D20',
          'D4',
          'A31',
          'B7',
          'B22',
          'C51',
          'A38',
          'D24',
          'A29',
          'C25',
          'A34',
          'D36',
          'B23',
          'A6',
          'D41',
          'B19',
          'A15',
          'C2',
          'A3',
          'B55',
          'C19',
          'B32',
          'C14',
          'D34',
          'C9',
          'A2',
          'B10',
          'C3',
          'B36',
          'D15',
          'A22',
          'D29',
          'B25',
          'C25',
          'B29',
          'D21',
          'C52',
          'B35',
          'C43',
          'D40',
          'D56',
          'A55',
          'A20',
          'B9',
          'C42',
          'B20',
          'A37',
          'B60',
          'A34',
          'C53',
          'B18',
          'D58',
          'B37',
          'D46',
          'B1',
          'D39',
          'A1',
          'C6',
          'B4',
          'A49',
          'C2',
          'B36',
          'C35',
          'D31',
          'A35',
          'C18',
          'B7',
          'C47',
          'B24',
          'C29',
          'B60',
          'A21',
          'A6',
          'B6',
          'D43',
          'D7',
          'B17',
          'C1',
          'B52',
          'A43',
          'A58',
          'A9',
          'B2',
          'D15',
          'C22',
          'B42',
          'D36',
          'B46',
          'D28',
          'C37',
          'A56',
          'C13',
          'B45',
          'A55',
          'A13',
          'D26',
          'D11',
          'A20',
          'D32',
          'A18',
          'B37',
          'D57',
          'B56',
          'D12',
          'A10',
          'C8',
          'B31',
          'A22',
          'D7',
          'C54',
          'B11',
          'A25',
          'C4',
          'C39',
          'B22',
          'C50',
          'C15',
          'B7',
          'C46',
          'D37',
          'A49',
          'C53',
          'B20',
          'D21',
          'A26',
          'C33',
          'A37',
          'D50',
          'B24',
          'C38',
          'D9',
          'D56',
          'B40',
          'C48',
          'D6',
          'B31',
          'C58',
          'B56',
          'C39',
          'D20',
          'D35',
          'D51',
          'B53',
          'B7',
          'C31',
          'D11',
          'C23',
          'B3',
          'A2',
          'D33',
          'A14',
          'B22',
          'D54',
          'D12',
          'C28',
          'B48',
          'A45',
          'C42',
          'A25',
          'B1',
          'C52',
          'C3',
          'B6',
          'D23',
          'C15',
          'D17',
          'A60',
          'D59',
          'B18',
          'C27',
          'B34',
          'C7',
          'A59',
          'C55',
          'A10',
          'B53',
          'C46',
          'B20',
          'C40',
          'B23',
          'D35',
          'C60',
          'B16',
          'A23',
          'B49',
          'C22',
          'B29',
          'A36',
          'C11',
          'A46',
          'D17',
          'D53',
          'A33',
          'A16',
          'C56',
          'C13',
          'B51',
          'A50',
          'B33',
          'A8',
          'D31',
          'B55',
          'C17',
          'D42',
          'B11',
          'C42',
          'A29',
          'D9',
          'C37',
          'D39',
          'A43',
          'C22',
          'A55',
          'D28',
          'B39',
          'C23',
          'D45',
          'A36',
          'C35',
          'B32',
          'D34',
          'B3',
          'B18',
          'A18',
          'C36',
          'B25',
          'D47',
          'C9',
          'A56',
          'D51',
          'C57',
          'D4',
          'C49',
          'A24',
          'C11',
          'B4',
          'D13',
          'D48',
          'B6',
          'B59',
          'A42',
          'C34',
          'B40',
          'D49',
          'B54',
          'A7',
          'D45',
          'B25',
          'C33',
          'B47',
          'D7',
          'C44',
          'D15',
          'C21',
          'B5',
          'C60',
          'B34',
          'D18',
          'C55',
          'D58',
          'A31',
          'C7',
          'A45',
          'C5',
          'B14',
          'A9',
          'B21',
          'C12',
          'A29',
          'A44',
          'D30',
          'A28',
          'C47',
          'B40',
          'C57',
          'B44',
          'B9',
          'C11',
          'B42',
          'A34',
          'D32',
          'C22',
          'D13',
          'A50',
          'A10',
          'D44',
          'B50',
          'C32',
          'D5',
          'D57',
          'A41',
          'C24',
          'B36',
          'C50',
          'B3',
          'D25',
          'B5',
          'C48',
          'D35',
          'D20',
          'A6',
          'B2',
          'D3',
          'A2',
          'B57',
          'B22',
          'D38',
          'C30',
          'C15',
          'A54',
          'D31',
          'A27',
          'C26',
          'A51',
          'B44',
          'D46',
          'C43',
          'B58',
          'B18',
          'D47',
          'A44',
          'C57',
          'B14',
          'D8',
          'B8',
          'D55',
          'B11',
          'C20',
          'C4',
          'D20',
          'A46',
          'C32',
          'D56',
          'A47',
          'B60',
          'C5',
          'B34',
          'D60',
          'A17',
          'B30',
          'C18',
          'A11',
          'B1',
          'C33',
          'B40',
          'D15',
          'C19',
          'B42',
          'D57',
          'A36',
          'D41',
          'A19',
          'D23',
          'B32',
          'A15',
          'C14',
          'B16',
          'C55',
          'C40',
          'A14',
          'A49',
          'D26',
          'B24',
          'C5',
          'A30',
          'C22',
          'A2',
          'D45',
          'B44',
          'A27',
          'D4',
          'C1',
          'A5',
          'B33',
          'C38',
          'A38',
          'A58',
          'D60',
          'A34',
          'D27',
          'B2',
          'D44',
          'A46',
          'D31',
          'B11',
          'D24',
          'B16',
          'C42',
          'B31',
          'D26',
          'C51',
          'B19',
          'D40',
          'B56',
          'A32',
          'B21',
          'A19',
          'D34',
          'C31',
          'B54',
          'A4',
          'B37',
          'C14',
          'D35',
          'A13',
          'C16',
          'A37',
          'B34',
          'D16',
          'B7',
          'D11',
          'C57',
          'C23',
          'B18',
          'A39',
          'C9',
          'B23',
          'C39',
          'A25',
          'D47',
          'B42',
          'C51',
          'D13',
          'B57',
          'C33',
          'A38',
          'B51',
          'A11',
          'B12',
          'C59',
          'B60',
          'C17',
          'D19',
          'B33',
          'B48',
          'C46',
          'D38',
          'A56',
          'B16',
          'C54',
          'A17',
          'C13',
          'B1',
          'D29',
          'A48',
          'D46',
          'C2',
          'A57',
          'A23',
          'C6',
          'A40',
          'C51',
          'B35',
          'D50',
          'A41',
          'B15',
          'B30',
          'C53',
          'D1',
          'C10',
          'B17',
          'D53',
          'C9',
          'A7',
          'C21',
          'B44',
          'A10',
          'D36',
          'B51',
          'D15',
          'B53',
          'A9',
          'B27',
          'D20',
          'C33',
          'B16',
          'D55',
          'C45',
          'B14',
          'C36',
          'D51',
          'B23',
          'D30',
          'C47',
          'D6',
          'C32',
          'D22',
          'C31',
          'B19',
          'D39',
          'C44',
          'A52',
          'B29',
          'A43',
          'C17',
          'D32',
          'A44',
          'C51',
          'D21',
          'C12',
          'B49',
          'A6',
          'B32',
          'A13',
          'A49',
          'B46',
          'A14',
          'A33',
          'C39',
          'B53',
          'A36',
          'D13',
          'C46',
          'B8',
          'A26',
          'D17',
          'A55',
          'C33',
          'D54',
          'A52',
          'D50',
          'D12',
          'C27',
          'B41',
          'D60',
          'B52',
          'C50',
          'D34',
          'B59',
          'A8',
          'B47',
          'C53',
          'A54',
          'C43',
          'D15',
          'C13',
          'B5',
          'D58',
          'A32',
          'B57',
          'C19',
          'A9',
          'D14',
          'C56',
          'B6',
          'C32',
          'B34',
          'A25',
          'C35',
          'D42',
          'B50',
          'B8',
          'D50',
          'C42',
          'D18',
          'B28',
          'D39',
          'C26',
          'D47',
          'C59',
          'A39',
          'C58',
          'D5',
          'C49',
          'B38',
          'A33',
          'C20',
          'B40',
          'C38',
          'B56',
          'A4',
          'A21',
          'B7',
          'A10',
          'D4',
          'D58',
          'A43',
          'A60',
          'D42',
          'D22',
          'C4',
          'A56',
          'B57',
          'B14',
          'D41',
          'C30',
          'B28',
          'A20',
          'B11',
          'A18',
          'C10',
          'B54',
          'B35',
          'C41',
          'B18',
          'D11',
          'A42',
          'D40',
          'D25',
          'C58',
          'D50',
          'B13',
          'C52',
          'A27',
          'D32',
          'A4',
          'B15',
          'D27',
          'B26',
          'C8',
          'B43',
          'C26',
          'D51',
          'A18',
          'B21',
          'A8',
          'D7',
          'D28',
          'A52',
          'C17',
          'D57',
          'B4',
          'A47',
          'C29',
          'D31',
          'C40',
          'D19',
          'C55',
          'B28',
          'C45',
          'B29',
          'D8',
          'A19',
          'B15',
          'A3',
          'B33',
          'C36',
          'D4',
          'A43',
          'A27',
          'A44',
          'D35',
          'B30',
          'A16',
          'D42',
          'A40',
          'B48',
          'C15',
          'B22',
          'A29',
          'C24',
          'B47',
          'C48',
          'A41',
          'D60',
          'B29',
          'A35',
          'C5',
          'D7',
          'C29',
          'B38',
          'A11',
          'B24',
          'D48',
          'A57',
          'D26',
          'D11',
          'A4',
          'C47',
          'A51',
          'D30',
          'A18',
          'B23',
          'C31',
          'B35',
          'D14',
          'A1',
          'B49',
          'D46',
          'C28',
          'B1',
          'C2',
          'D23',
          'B25',
          'A2',
          'B39',
          'D53',
          'B12',
          'D12',
          'A25',
          'D40',
          'B58',
          'C38',
          'D33',
          'A37',
          'D52',
          'A59',
          'A13',
          'D54',
          'A18',
          'A58',
          'C35',
          'C50',
          'C7',
          'A27',
          'B4',
          'D31',
          'A16',
          'B37',
          'B52',
          'D42',
          'B3',
          'B56',
          'C28',
          'C9',
          'A53',
          'C41',
          'B38',
          'C14',
          'D20',
          'B5',
          'D18',
          'C34',
          'B41',
          'C17',
          'C57',
          'B2',
          'C33',
          'B17',
          'D23',
          'A1',
          'C2',
          'A10',
          'B45',
          'D9',
          'B53',
          'C43',
          'A42',
          'B37',
          'D43',
          'A5',
          'D48',
          'B9',
          'C35',
          'A60',
          'C21',
          'B35',
          'D10',
          'B13',
          'D38',
          'B28',
          'A45',
          'D2',
          'A28',
          'D26',
        ],
      },
    ],
  },
  {
    title: 'Mona Lisa',
    id: 'mona-lisa',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Mona%20Lisa.png',
    artData: [
      {
        size: {
          x: 301,
          y: 301,
        },
        pegs: [
          {
            x: 301,
            y: 150.5,
            angle: 0,
            name: 'D1',
          },
          {
            x: 300.9484274088214,
            y: 154.4396307203349,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 300.79374498056336,
            y: 158.376561414563,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 300.5360587268358,
            y: 162.3080939070407,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 300.17554525292513,
            y: 166.23153372178183,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 299.7124516367585,
            y: 170.14419192911777,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 299.14709525956823,
            y: 174.04338698855474,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 298.47986358837517,
            y: 177.92644658656818,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 297.7112139104377,
            y: 181.79070946807278,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 296.8416730198503,
            y: 185.63352726031374,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 295.8718368565048,
            y: 189.45226628792935,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 294.8023700976631,
            y: 193.24430937794037,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 293.6340057024206,
            y: 197.00705765342957,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 292.36754440937284,
            y: 200.7379323146825,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 291.00385418782884,
            y: 204.43437640656765,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 289.54386964294866,
            y: 208.09385657094597,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.98859137521146,
            y: 211.71386478290793,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 286.339085294654,
            y: 215.29192006964837,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 284.59648189034937,
            y: 218.82557021080177,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 282.7619754556258,
            y: 222.31239341907104,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 280.836823269558,
            y: 225.74999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 278.82234473529087,
            y: 229.13603398975027,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 276.7199204757863,
            y: 232.46817476976156,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 274.53099138761337,
            y: 235.74413865718734,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 272.25705765342957,
            y: 238.9616804700172,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.8996777138309,
            y: 242.11859506581243,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 267.46046719927415,
            y: 245.21271885300055,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.9410978228047,
            y: 248.24193127369267,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 262.3432962343478,
            y: 251.20415625700815,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 259.6688428373493,
            y: 254.09736364191,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.9195705685754,
            y: 256.9195705685754,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 254.09736364190996,
            y: 259.6688428373493,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 251.2041562570081,
            y: 262.34329623434786,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 248.2419312736926,
            y: 264.9410978228047,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 245.2127188530005,
            y: 267.46046719927415,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 242.1185950658124,
            y: 269.8996777138309,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.96168047001717,
            y: 272.2570576534296,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 235.74413865718728,
            y: 274.53099138761337,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 232.4681747697615,
            y: 276.71992047578635,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 229.13603398975022,
            y: 278.82234473529087,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 225.74999999999997,
            y: 280.836823269558,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 222.312393419071,
            y: 282.7619754556258,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.82557021080177,
            y: 284.59648189034937,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 215.29192006964837,
            y: 286.339085294654,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.71386478290793,
            y: 287.9885913752114,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 208.093856570946,
            y: 289.54386964294866,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 204.4343764065677,
            y: 291.00385418782884,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.73793231468255,
            y: 292.36754440937284,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 197.00705765342963,
            y: 293.6340057024206,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 193.2443093779404,
            y: 294.8023700976631,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 189.45226628792943,
            y: 295.8718368565048,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.63352726031383,
            y: 296.8416730198503,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.79070946807283,
            y: 297.7112139104377,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.9264465865683,
            y: 298.4798635883751,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 174.04338698855483,
            y: 299.14709525956823,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 170.1441919291179,
            y: 299.71245163675843,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 166.23153372178197,
            y: 300.17554525292513,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 162.3080939070408,
            y: 300.53605872683573,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 158.37656141456318,
            y: 300.79374498056336,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 154.43963072033506,
            y: 300.9484274088214,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.50000000000017,
            y: 301,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.56036927966528,
            y: 300.9484274088214,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.62343858543716,
            y: 300.79374498056336,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.69190609295953,
            y: 300.5360587268358,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.76846627821837,
            y: 300.1755452529252,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.85580807088246,
            y: 299.7124516367585,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.95661301144548,
            y: 299.1470952595683,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 123.07355341343207,
            y: 298.47986358837517,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 119.20929053192748,
            y: 297.7112139104378,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 115.36647273968649,
            y: 296.8416730198504,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.5477337120709,
            y: 295.87183685650484,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.75569062205994,
            y: 294.80237009766313,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.99294234657071,
            y: 293.63400570242067,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 100.26206768531779,
            y: 292.3675444093729,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.5656235934326,
            y: 291.00385418782895,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.90614342905431,
            y: 289.5438696429488,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 89.2861352170924,
            y: 287.98859137521157,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.70807993035191,
            y: 286.3390852946542,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 82.17442978919851,
            y: 284.59648189034954,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.68760658092921,
            y: 282.76197545562593,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.25000000000026,
            y: 280.8368232695582,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.86396601024994,
            y: 278.822344735291,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.53182523023864,
            y: 276.7199204757864,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.25586134281286,
            y: 274.5309913876135,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 62.03831952998296,
            y: 272.25705765342974,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.88140493418769,
            y: 269.89967771383095,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.78728114699959,
            y: 267.4604671992742,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.75806872630748,
            y: 264.94109782280475,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.795843742991934,
            y: 262.3432962343479,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.9026363580901,
            y: 259.66884283734936,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 44.080429431424655,
            y: 256.91957056857547,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.33115716265075,
            y: 254.09736364191002,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.656703765652196,
            y: 251.20415625700815,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 36.05890217719534,
            y: 248.24193127369267,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.53953280072588,
            y: 245.2127188530005,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 31.100322286169092,
            y: 242.1185950658124,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.742942346570374,
            y: 238.96168047001717,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.469008612386602,
            y: 235.74413865718728,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.28007952421363,
            y: 232.4681747697615,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.17765526470906,
            y: 229.13603398975022,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.163176730441904,
            y: 225.74999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.238024544374127,
            y: 222.31239341907093,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.403518109650545,
            y: 218.8255702108016,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.660914705345892,
            y: 215.2919200696482,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 13.01140862478847,
            y: 211.7138647829077,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.456130357051245,
            y: 208.09385657094577,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.996145812171036,
            y: 204.43437640656745,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.632455590627046,
            y: 200.73793231468224,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.365994297579293,
            y: 197.00705765342929,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.197629902336845,
            y: 193.24430937794,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.128163143495136,
            y: 189.452266287929,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.158326980149578,
            y: 185.63352726031337,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2887860895621603,
            y: 181.7907094680724,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.520136411624763,
            y: 177.92644658656778,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8529047404316987,
            y: 174.0433869885543,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2875483632414704,
            y: 170.14419192911728,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.824454747074803,
            y: 166.23153372178137,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.4639412731641913,
            y: 162.30809390704013,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.20625501943660468,
            y: 158.3765614145625,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.05157259117861568,
            y: 154.43963072033432,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 150.4999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.051572591178649096,
            y: 146.56036927966449,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20625501943667152,
            y: 142.62343858543633,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.46394127316429157,
            y: 138.69190609295867,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8244547470749367,
            y: 134.7684662782175,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.287548363241621,
            y: 130.85580807088155,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.8529047404318826,
            y: 126.95661301144453,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.520136411624964,
            y: 123.07355341343107,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2887860895624104,
            y: 119.20929053192646,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.1583269801498615,
            y: 115.36647273968548,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.128163143495436,
            y: 111.54773371206986,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.197629902337179,
            y: 107.75569062205885,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.365994297579644,
            y: 103.9929423465696,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.632455590627448,
            y: 100.26206768531664,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.996145812171454,
            y: 96.56562359343147,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.456130357051697,
            y: 92.90614342905313,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 13.011408624788954,
            y: 89.28613521709121,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.660914705346391,
            y: 85.70807993035072,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.40351810965108,
            y: 82.17442978919733,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.238024544374692,
            y: 78.68760658092803,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.16317673044249,
            y: 75.2499999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.17765526470966,
            y: 71.8639660102488,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.280079524214262,
            y: 68.53182523023752,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.469008612387253,
            y: 65.25586134281176,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.74294234657106,
            y: 62.03831952998189,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 31.100322286169792,
            y: 58.88140493418664,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.53953280072661,
            y: 55.787281146998566,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 36.05890217719611,
            y: 52.758068726306455,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.65670376565297,
            y: 49.795843742990954,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.33115716265154,
            y: 46.90263635808913,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 44.08042943142547,
            y: 44.08042943142372,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.90263635809094,
            y: 41.33115716264984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.7958437429928,
            y: 38.6567037656513,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.75806872630831,
            y: 36.058902177194526,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.78728114700044,
            y: 33.53953280072508,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.88140493418856,
            y: 31.10032228616832,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 62.03831952998386,
            y: 28.742942346569638,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.25586134281376,
            y: 26.469008612385885,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.53182523023956,
            y: 24.280079524212944,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.86396601025088,
            y: 22.17765526470839,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.25000000000121,
            y: 20.16317673044129,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.68760658093017,
            y: 18.238024544373523,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 82.17442978919948,
            y: 16.40351810964998,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.70807993035291,
            y: 14.66091470534534,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 89.28613521709343,
            y: 13.01140862478795,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.90614342905538,
            y: 11.456130357050762,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.56562359343374,
            y: 9.996145812170585,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 100.26206768531894,
            y: 8.632455590626627,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.99294234657191,
            y: 7.365994297578892,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.75569062206118,
            y: 6.1976299023364945,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.54773371207219,
            y: 5.128163143494801,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 115.36647273968782,
            y: 4.158326980149294,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 119.20929053192884,
            y: 3.2887860895619094,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 123.07355341343347,
            y: 2.5201364116245295,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.95661301144695,
            y: 1.8529047404314982,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.85580807088397,
            y: 1.2875483632413036,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.7684662782199,
            y: 0.824454747074686,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.6919060929611,
            y: 0.46394127316410777,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.62343858543875,
            y: 0.20625501943653785,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.5603692796669,
            y: 0.05157259117858226,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.50000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 154.43963072033677,
            y: 0.051572591178682514,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 158.37656141456495,
            y: 0.20625501943673835,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 162.30809390704258,
            y: 0.4639412731643918,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 166.23153372178376,
            y: 0.8244547470750704,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 170.1441919291197,
            y: 1.2875483632417881,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 174.04338698855668,
            y: 1.852904740432083,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.92644658657017,
            y: 2.520136411625198,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.79070946807477,
            y: 3.2887860895626777,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.63352726031576,
            y: 4.158326980150147,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 189.45226628793137,
            y: 5.128163143495754,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 193.2443093779424,
            y: 6.19762990233753,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 197.00705765343162,
            y: 7.365994297580045,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.7379323146845,
            y: 8.632455590627865,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 204.4343764065697,
            y: 9.996145812171905,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 208.09385657094805,
            y: 11.456130357052182,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.7138647829099,
            y: 13.011408624789455,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 215.29192006965044,
            y: 14.660914705346926,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.8255702108038,
            y: 16.40351810965165,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 222.31239341907306,
            y: 18.238024544375296,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.750000000002,
            y: 20.16317673044313,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 229.13603398975226,
            y: 22.177655264710328,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 232.46817476976352,
            y: 24.28007952421495,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 235.74413865718927,
            y: 26.469008612387974,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.96168047001913,
            y: 28.742942346571812,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 242.11859506581436,
            y: 31.100322286170563,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 245.2127188530024,
            y: 33.5395328007274,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 248.24193127369446,
            y: 36.058902177196934,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 251.20415625700994,
            y: 38.656703765653816,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 254.09736364191176,
            y: 41.33115716265242,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.91957056857717,
            y: 44.08042943142638,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 259.668842837351,
            y: 46.902636358091854,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 262.34329623434957,
            y: 49.79584374299374,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.94109782280634,
            y: 52.75806872630932,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 267.46046719927574,
            y: 55.78728114700147,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.89967771383243,
            y: 58.88140493418961,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 272.2570576534311,
            y: 62.038319529984925,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 274.53099138761485,
            y: 65.25586134281484,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 276.7199204757878,
            y: 68.53182523024067,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 278.82234473529223,
            y: 71.86396601025201,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 280.8368232695594,
            y: 75.25000000000237,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 282.76197545562707,
            y: 78.68760658093136,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 284.5964818903506,
            y: 82.17442978920069,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 286.33908529465526,
            y: 85.7080799303541,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.98859137521254,
            y: 89.28613521709465,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 289.54386964294974,
            y: 92.90614342905661,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 291.00385418782986,
            y: 96.56562359343498,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 292.3675444093738,
            y: 100.26206768532019,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 293.63400570242146,
            y: 103.99294234657317,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 294.80237009766387,
            y: 107.75569062206245,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 295.8718368565055,
            y: 111.54773371207347,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 296.841673019851,
            y: 115.36647273968913,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 297.71121391043835,
            y: 119.20929053193014,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 298.47986358837574,
            y: 123.07355341343477,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 299.1470952595687,
            y: 126.95661301144824,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 299.7124516367589,
            y: 130.85580807088527,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 300.1755452529254,
            y: 134.7684662782212,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 300.536058726836,
            y: 138.69190609296243,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 300.79374498056353,
            y: 142.62343858544008,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 300.94842740882143,
            y: 146.56036927966824,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'D28',
          'B14',
          'D26',
          'B16',
          'D33',
          'B20',
          'D37',
          'B13',
          'D30',
          'B11',
          'D38',
          'B12',
          'D27',
          'B15',
          'D35',
          'B19',
          'D25',
          'B17',
          'D24',
          'D39',
          'B10',
          'D31',
          'B8',
          'D28',
          'B21',
          'D32',
          'B7',
          'D29',
          'B13',
          'D36',
          'B14',
          'D42',
          'B18',
          'D23',
          'B20',
          'D22',
          'B15',
          'D34',
          'B19',
          'D24',
          'B9',
          'D27',
          'B16',
          'D25',
          'B8',
          'D37',
          'B12',
          'D40',
          'B17',
          'D30',
          'B6',
          'D33',
          'B13',
          'D43',
          'D23',
          'B22',
          'D22',
          'B23',
          'D32',
          'B15',
          'D28',
          'B19',
          'D38',
          'B10',
          'D41',
          'B11',
          'D29',
          'B18',
          'D26',
          'B21',
          'D24',
          'C38',
          'D25',
          'D40',
          'B14',
          'D35',
          'B12',
          'D16',
          'C32',
          'D18',
          'C30',
          'C15',
          'C31',
          'D12',
          'C28',
          'D13',
          'B4',
          'D15',
          'B1',
          'D14',
          'B5',
          'D31',
          'B16',
          'D41',
          'B13',
          'D24',
          'B22',
          'D21',
          'C35',
          'D22',
          'B24',
          'D37',
          'B10',
          'D42',
          'B12',
          'D25',
          'B21',
          'D40',
          'B18',
          'D39',
          'B9',
          'D32',
          'B25',
          'D35',
          'B20',
          'D27',
          'C43',
          'D30',
          'C42',
          'D28',
          'B11',
          'D36',
          'B17',
          'D19',
          'C30',
          'D9',
          'C26',
          'D5',
          'C24',
          'D7',
          'C23',
          'A41',
          'C25',
          'D3',
          'C27',
          'D4',
          'C22',
          'D8',
          'B3',
          'D16',
          'A60',
          'D1',
          'B4',
          'D26',
          'B15',
          'D42',
          'B9',
          'D15',
          'C33',
          'D17',
          'A59',
          'D2',
          'C28',
          'D19',
          'B18',
          'D36',
          'B6',
          'D10',
          'B13',
          'D27',
          'B17',
          'D43',
          'B11',
          'D39',
          'D24',
          'B23',
          'D23',
          'C39',
          'D28',
          'B12',
          'D31',
          'C42',
          'D25',
          'B20',
          'D34',
          'B22',
          'D33',
          'B5',
          'D12',
          'C26',
          'A42',
          'C25',
          'D4',
          'C21',
          'A39',
          'C20',
          'D5',
          'C23',
          'D9',
          'B14',
          'D39',
          'B16',
          'D37',
          'B21',
          'D23',
          'B25',
          'D31',
          'B27',
          'D28',
          'B6',
          'D38',
          'B7',
          'D33',
          'C48',
          'D34',
          'C46',
          'D29',
          'C44',
          'D32',
          'B11',
          'D11',
          'C29',
          'A43',
          'C27',
          'D2',
          'C26',
          'D13',
          'B2',
          'B58',
          'A56',
          'B56',
          'B3',
          'D27',
          'B7',
          'D9',
          'C22',
          'A40',
          'C24',
          'D14',
          'A58',
          'D12',
          'B17',
          'D26',
          'B19',
          'D36',
          'B8',
          'D43',
          'B9',
          'D40',
          'B15',
          'D8',
          'C31',
          'D17',
          'A60',
          'B58',
          'A54',
          'D1',
          'B2',
          'D11',
          'C33',
          'D22',
          'D42',
          'D27',
          'C40',
          'A47',
          'C39',
          'D21',
          'B23',
          'D19',
          'C26',
          'A40',
          'C21',
          'B53',
          'C20',
          'A5',
          'C16',
          'A3',
          'C14',
          'A6',
          'C11',
          'A4',
          'C13',
          'A32',
          'C15',
          'C60',
          'C17',
          'A2',
          'C19',
          'A38',
          'C18',
          'C58',
          'C12',
          'A7',
          'C10',
          'A8',
          'C9',
          'A5',
          'C16',
          'C56',
          'C14',
          'A33',
          'C20',
          'B54',
          'A57',
          'D15',
          'B13',
          'D42',
          'D14',
          'B19',
          'D41',
          'B8',
          'D33',
          'B24',
          'D32',
          'B26',
          'D30',
          'B14',
          'D43',
          'B12',
          'D44',
          'D19',
          'A59',
          'D11',
          'C22',
          'B52',
          'C18',
          'B54',
          'A55',
          'B60',
          'B5',
          'D39',
          'B22',
          'D25',
          'C36',
          'D20',
          'B16',
          'D7',
          'C27',
          'D8',
          'A60',
          'D10',
          'C20',
          'D12',
          'A56',
          'B59',
          'C24',
          'A42',
          'C30',
          'A44',
          'C29',
          'D6',
          'C25',
          'D13',
          'C22',
          'D15',
          'D42',
          'D27',
          'B14',
          'D38',
          'B18',
          'D35',
          'B21',
          'D34',
          'B4',
          'B56',
          'B1',
          'D5',
          'A55',
          'B57',
          'A57',
          'D11',
          'C32',
          'C17',
          'A4',
          'C12',
          'A5',
          'C15',
          'A34',
          'C14',
          'A1',
          'C41',
          'D31',
          'C49',
          'D32',
          'C42',
          'A48',
          'C39',
          'D24',
          'B14',
          'D41',
          'B15',
          'D38',
          'C52',
          'C18',
          'A3',
          'C21',
          'D9',
          'B2',
          'D29',
          'B20',
          'D17',
          'B18',
          'D10',
          'A58',
          'D18',
          'C37',
          'A46',
          'C36',
          'D27',
          'B27',
          'D24',
          'B16',
          'D36',
          'C51',
          'D37',
          'B15',
          'D45',
          'B11',
          'D44',
          'B13',
          'D40',
          'B6',
          'B56',
          'C23',
          'D3',
          'C28',
          'D6',
          'C20',
          'D13',
          'A55',
          'D2',
          'A53',
          'B59',
          'C26',
          'A43',
          'C25',
          'D19',
          'D41',
          'B5',
          'B55',
          'A59',
          'B57',
          'B3',
          'D29',
          'B14',
          'D11',
          'C27',
          'A39',
          'C19',
          'B50',
          'C17',
          'A36',
          'C16',
          'C54',
          'D40',
          'D25',
          'B25',
          'D22',
          'C30',
          'D5',
          'D43',
          'D12',
          'A59',
          'D18',
          'B17',
          'D7',
          'A54',
          'D3',
          'C26',
          'A39',
          'C23',
          'B59',
          'A58',
          'D20',
          'C25',
          'D8',
          'C24',
          'D21',
          'B15',
          'D9',
          'B19',
          'D33',
          'C47',
          'A2',
          'C45',
          'A49',
          'C44',
          'A3',
          'C13',
          'A6',
          'C7',
          'A8',
          'C5',
          'A5',
          'C11',
          'A30',
          'C16',
          'B50',
          'C21',
          'A1',
          'C20',
          'D14',
          'C34',
          'A45',
          'C33',
          'D25',
          'B6',
          'B54',
          'A60',
          'D15',
          'D41',
          'B12',
          'D39',
          'D24',
          'C43',
          'A47',
          'C36',
          'D18',
          'D33',
          'B10',
          'D30',
          'B16',
          'D28',
          'B22',
          'D26',
          'C42',
          'A49',
          'C41',
          'A3',
          'C15',
          'B45',
          'C17',
          'A33',
          'C13',
          'A11',
          'C9',
          'A6',
          'C4',
          'A9',
          'C8',
          'A13',
          'C12',
          'A32',
          'C16',
          'B54',
          'B3',
          'D4',
          'A53',
          'D7',
          'B19',
          'D40',
          'B10',
          'D44',
          'B7',
          'D42',
          'B11',
          'D26',
          'B24',
          'D36',
          'B9',
          'D8',
          'C23',
          'D12',
          'B13',
          'D31',
          'B2',
          'B57',
          'A51',
          'B60',
          'B1',
          'D17',
          'A56',
          'D1',
          'C29',
          'D18',
          'C27',
          'A41',
          'C22',
          'C60',
          'C16',
          'B44',
          'D43',
          'D13',
          'B16',
          'D44',
          'B8',
          'D42',
          'B17',
          'D11',
          'A55',
          'B59',
          'C18',
          'C55',
          'D39',
          'B23',
          'D38',
          'D15',
          'C20',
          'B49',
          'C19',
          'B53',
          'B4',
          'D29',
          'C43',
          'C58',
          'C17',
          'C53',
          'D37',
          'C49',
          'D33',
          'C42',
          'D34',
          'B5',
          'D11',
          'C21',
          'A35',
          'C15',
          'A6',
          'C41',
          'A46',
          'C33',
          'D28',
          'B28',
          'D26',
          'C38',
          'A3',
          'C12',
          'A29',
          'C18',
          'B53',
          'B1',
          'B58',
          'A59',
          'D9',
          'D43',
          'B6',
          'D34',
          'B23',
          'D17',
          'C37',
          'A4',
          'C16',
          'B55',
          'C24',
          'A37',
          'C27',
          'A44',
          'C35',
          'D13',
          'A57',
          'D21',
          'C22',
          'B52',
          'C21',
          'D5',
          'B17',
          'D41',
          'B9',
          'B57',
          'B11',
          'D17',
          'B8',
          'B59',
          'A52',
          'D3',
          'D44',
          'D4',
          'C26',
          'A41',
          'C24',
          'D2',
          'A58',
          'D16',
          'C20',
          'D21',
          'C34',
          'D10',
          'B14',
          'D47',
          'B15',
          'D29',
          'B26',
          'D34',
          'B12',
          'D32',
          'B1',
          'D13',
          'A54',
          'B49',
          'B2',
          'D35',
          'C52',
          'D39',
          'B17',
          'D23',
          'D38',
          'B20',
          'D36',
          'D15',
          'B16',
          'D8',
          'B18',
          'D6',
          'C18',
          'A35',
          'C14',
          'A8',
          'C2',
          'A6',
          'D60',
          'A9',
          'C11',
          'A31',
          'C16',
          'A2',
          'C22',
          'C58',
          'C42',
          'A50',
          'C46',
          'A51',
          'D1',
          'C28',
          'A38',
          'C27',
          'B58',
          'C25',
          'D4',
          'D40',
          'B4',
          'D42',
          'C57',
          'D41',
          'D20',
          'A57',
          'B55',
          'A52',
          'C45',
          'A48',
          'C43',
          'D25',
          'B28',
          'D33',
          'D18',
          'A60',
          'D6',
          'C17',
          'B53',
          'A58',
          'D19',
          'C37',
          'A6',
          'C8',
          'A15',
          'C15',
          'B46',
          'D42',
          'B3',
          'D35',
          'B9',
          'D13',
          'B12',
          'B56',
          'C16',
          'A33',
          'C12',
          'A8',
          'D59',
          'A10',
          'C3',
          'A5',
          'C14',
          'A2',
          'C18',
          'B44',
          'D34',
          'B27',
          'D30',
          'B19',
          'D39',
          'B6',
          'D31',
          'B9',
          'D45',
          'A1',
          'C39',
          'A49',
          'C48',
          'D37',
          'B7',
          'D18',
          'A55',
          'D15',
          'D35',
          'C46',
          'D28',
          'C32',
          'A45',
          'C29',
          'D2',
          'A50',
          'C41',
          'C59',
          'C17',
          'A29',
          'C10',
          'A5',
          'D58',
          'B24',
          'D24',
          'C35',
          'C19',
          'C51',
          'A51',
          'C50',
          'D10',
          'A56',
          'B48',
          'A47',
          'B49',
          'A59',
          'D1',
          'B15',
          'D43',
          'B16',
          'D35',
          'B10',
          'D29',
          'B21',
          'D27',
          'A1',
          'D28',
          'B13',
          'D48',
          'A3',
          'C17',
          'A37',
          'C22',
          'A41',
          'C31',
          'A45',
          'C38',
          'D16',
          'C34',
          'A44',
          'C26',
          'D18',
          'C20',
          'D22',
          'A56',
          'D14',
          'C23',
          'B51',
          'C19',
          'C57',
          'C24',
          'D4',
          'B18',
          'D51',
          'B19',
          'D11',
          'B1',
          'D36',
          'C54',
          'D16',
          'B15',
          'D25',
          'C25',
          'B54',
          'C17',
          'D7',
          'A58',
          'D13',
          'D43',
          'B3',
          'B48',
          'C21',
          'A4',
          'C5',
          'A14',
          'C10',
          'A30',
          'C18',
          'D3',
          'A57',
          'B58',
          'B10',
          'D12',
          'C51',
          'C24',
          'A43',
          'B16',
          'B60',
          'A50',
          'C39',
          'D30',
          'B12',
          'D45',
          'B6',
          'B59',
          'C16',
          'B44',
          'D29',
          'C50',
          'D32',
          'B4',
          'D37',
          'B18',
          'D15',
          'A54',
          'D6',
          'C53',
          'A52',
          'C44',
          'D23',
          'B26',
          'D58',
          'A11',
          'C11',
          'A26',
          'C8',
          'A27',
          'C13',
          'A5',
          'C1',
          'A13',
          'C6',
          'A24',
          'C9',
          'A12',
          'C2',
          'B30',
          'D43',
          'B45',
          'D27',
          'C35',
          'A4',
          'C15',
          'B47',
          'A49',
          'B53',
          'D42',
          'B5',
          'D40',
          'B11',
          'D35',
          'B24',
          'D21',
          'D4',
          'A51',
          'C49',
          'D8',
          'B20',
          'D30',
          'A3',
          'C39',
          'D34',
          'B50',
          'A53',
          'B54',
          'B7',
          'D43',
          'B2',
          'B52',
          'C35',
          'A47',
          'C38',
          'C18',
          'D9',
          'C17',
          'B43',
          'D44',
          'B14',
          'D23',
          'C58',
          'C23',
          'A42',
          'C27',
          'A40',
          'C20',
          'B48',
          'D41',
          'B3',
          'B60',
          'A52',
          'D8',
          'C56',
          'C25',
          'A39',
          'C30',
          'D10',
          'B15',
          'D31',
          'B7',
          'D26',
          'B13',
          'A45',
          'B14',
          'D2',
          'B1',
          'D44',
          'C59',
          'C22',
          'B46',
          'C31',
          'D3',
          'C52',
          'D4',
          'D39',
          'D6',
          'C24',
          'D19',
          'D34',
          'C41',
          'A51',
          'C45',
          'A53',
          'B58',
          'C19',
          'D5',
          'C47',
          'A49',
          'D1',
          'C27',
          'D12',
          'A55',
          'B48',
          'C33',
          'B49',
          'D39',
          'B13',
          'B55',
          'D41',
          'B18',
          'D31',
          'C60',
          'D43',
          'B10',
          'D46',
          'B5',
          'D37',
          'D6',
          'D29',
          'D8',
          'B7',
          'B58',
          'A50',
          'C38',
          'D17',
          'C54',
          'C19',
          'A1',
          'D30',
          'C46',
          'D13',
          'C18',
          'A4',
          'D47',
          'A2',
          'D26',
          'C24',
          'D28',
          'B23',
          'D56',
          'B24',
          'D19',
          'A57',
          'B47',
          'A60',
          'B57',
          'C16',
          'A37',
          'C29',
          'D5',
          'A50',
          'D1',
          'B6',
          'D32',
          'A4',
          'C10',
          'A10',
          'D60',
          'A13',
          'D59',
          'B23',
          'D37',
          'C46',
          'A47',
          'B50',
          'A6',
          'C12',
          'A25',
          'C9',
          'A15',
          'C13',
          'A8',
          'B52',
          'C20',
          'C50',
          'D36',
          'B5',
          'D44',
          'B2',
          'B55',
          'D40',
          'B8',
          'B53',
          'C15',
          'A1',
          'B46',
          'C17',
          'D10',
          'B20',
          'D41',
          'B40',
          'D39',
          'D13',
          'D28',
          'B29',
          'D58',
          'A9',
          'C1',
          'A8',
          'C6',
          'A25',
          'C7',
          'A7',
          'C36',
          'C20',
          'C59',
          'C40',
          'A46',
          'C31',
          'D4',
          'C51',
          'A52',
          'B33',
          'A50',
          'B38',
          'D44',
          'B52',
          'C19',
          'B42',
          'C18',
          'A1',
          'D16',
          'B8',
          'D32',
          'B13',
          'D11',
          'A53',
          'C44',
          'D3',
          'A55',
          'D20',
          'C56',
          'D43',
          'B4',
          'D41',
          'B7',
          'D36',
          'B49',
          'A46',
          'C29',
          'D12',
          'C59',
          'D24',
          'C27',
          'A45',
          'C42',
          'D21',
          'C37',
          'B54',
          'C15',
          'A7',
          'C11',
          'A32',
          'C21',
          'B46',
          'B1',
          'D42',
          'B16',
          'A41',
          'B18',
          'D27',
          'C47',
          'A52',
          'B56',
          'B10',
          'B52',
          'C17',
          'A38',
          'C31',
          'D6',
          'D34',
          'C53',
          'C38',
          'A51',
          'B59',
          'D41',
          'D3',
          'A49',
          'C43',
          'D33',
          'A60',
          'D14',
          'B8',
          'D30',
          'C32',
          'B47',
          'A1',
          'C33',
          'B50',
          'C20',
          'B55',
          'C15',
          'A33',
          'C19',
          'D22',
          'B21',
          'D55',
          'B24',
          'D53',
          'B17',
          'D52',
          'A8',
          'C3',
          'A14',
          'D59',
          'B28',
          'D24',
          'D8',
          'C55',
          'A54',
          'D16',
          'C49',
          'C25',
          'A40',
          'B19',
          'D23',
          'A57',
          'B60',
          'C16',
          'A6',
          'B47',
          'C21',
          'C60',
          'B45',
          'A4',
          'C14',
          'A16',
          'C7',
          'A26',
          'C6',
          'A9',
          'B53',
          'A54',
          'B32',
          'A53',
          'C40',
          'B57',
          'A58',
          'B46',
          'C30',
          'B60',
          'D41',
          'D5',
          'B19',
          'D50',
          'B17',
          'D38',
          'B3',
          'D44',
          'B29',
          'D32',
          'D6',
          'C47',
          'D23',
          'C29',
          'D7',
          'A52',
          'C41',
          'D4',
          'C50',
          'D19',
          'A3',
          'B44',
          'A45',
          'B50',
          'A56',
          'D15',
          'C60',
          'C36',
          'D21',
          'C52',
          'A50',
          'B43',
          'A59',
          'D37',
          'B25',
          'D35',
          'B5',
          'D28',
          'C23',
          'C53',
          'D41',
          'C58',
          'D13',
          'C17',
          'A5',
          'B49',
          'C18',
          'A39',
          'C28',
          'A36',
          'C15',
          'B56',
          'D12',
          'B18',
          'D25',
          'C56',
          'D33',
          'C54',
          'D3',
          'C16',
          'A14',
          'D58',
          'B30',
          'D30',
          'D15',
          'C53',
          'A54',
          'C48',
          'B57',
          'B8',
          'D13',
          'A52',
          'B37',
          'A40',
          'B24',
          'D41',
          'B2',
          'D46',
          'B13',
          'A42',
          'C22',
          'A31',
          'C13',
          'A34',
          'C19',
          'A3',
          'C31',
          'D16',
          'A53',
          'C38',
          'D33',
          'B3',
          'B45',
          'C23',
          'D20',
          'C40',
          'A48',
          'B48',
          'A4',
          'D29',
          'B12',
          'A47',
          'B53',
          'A8',
          'D51',
          'B20',
          'D7',
          'C28',
          'D17',
          'C49',
          'A52',
          'C39',
          'D35',
          'B26',
          'C1',
          'A18',
          'C22',
          'B49',
          'C32',
          'B50',
          'D32',
          'A3',
          'B45',
          'C16',
          'B52',
          'D43',
          'B58',
          'A55',
          'B51',
          'C21',
          'D12',
          'B12',
          'D49',
          'A5',
          'D56',
          'B30',
          'A37',
          'B33',
          'D41',
          'C52',
          'C24',
          'D30',
          'B9',
          'D23',
          'C20',
          'C53',
          'D3',
          'A53',
          'C37',
          'B55',
          'A10',
          'D57',
          'B22',
          'A41',
          'B36',
          'D35',
          'B59',
          'C50',
          'A50',
          'B46',
          'C60',
          'D17',
          'A57',
          'D9',
          'C49',
          'C32',
          'A44',
          'C25',
          'D27',
          'C34',
          'D24',
          'C26',
          'B50',
          'A5',
          'D22',
          'C40',
          'A49',
          'D4',
          'C48',
          'D26',
          'B5',
          'B47',
          'A58',
          'D42',
          'B6',
          'D19',
          'D3',
          'B13',
          'D8',
          'C19',
          'A28',
          'C9',
          'A30',
          'C17',
          'B39',
          'A40',
          'C23',
          'C56',
          'D7',
          'D32',
          'C48',
          'D38',
          'C46',
          'D5',
          'D40',
          'B16',
          'B59',
          'C49',
          'A55',
          'D1',
          'A48',
          'B38',
          'C18',
          'C59',
          'B44',
          'C30',
          'D4',
          'C16',
          'D6',
          'C41',
          'A45',
          'B21',
          'D15',
          'A59',
          'D44',
          'B15',
          'A42',
          'B35',
          'A53',
          'C56',
          'D19',
          'A54',
          'C38',
          'A52',
          'D12',
          'B9',
          'B52',
          'A11',
          'D60',
          'B27',
          'A38',
          'C29',
          'D30',
          'B1',
          'D45',
          'C60',
          'B47',
          'D42',
          'B54',
          'A9',
          'C10',
          'A23',
          'C5',
          'A10',
          'C2',
          'A16',
          'C13',
          'A2',
          'B43',
          'C17',
          'B55',
          'D36',
          'C49',
          'A48',
          'C46',
          'C24',
          'B50',
          'C18',
          'B51',
          'B11',
          'D46',
          'B4',
          'D38',
          'B22',
          'D60',
          'A12',
          'D57',
          'B29',
          'C3',
          'A21',
          'C13',
          'A7',
          'C8',
          'A24',
          'B13',
          'D45',
          'B41',
          'A60',
          'D43',
          'B39',
          'C15',
          'B57',
          'A13',
          'C4',
          'B28',
          'A35',
          'B16',
          'A33',
          'B24',
          'A39',
          'B25',
          'D21',
          'A3',
          'D33',
          'D18',
          'A56',
          'D24',
          'C54',
          'D8',
          'D29',
          'D9',
          'C16',
          'C59',
          'C23',
          'C49',
          'D25',
          'B7',
          'D17',
          'C48',
          'B59',
          'D34',
          'B17',
          'A42',
          'C33',
          'A47',
          'C31',
          'A4',
          'C7',
          'A12',
          'C15',
          'B50',
          'A10',
          'B51',
          'A7',
          'C19',
          'D24',
          'B18',
          'D43',
          'B53',
          'C38',
          'C59',
          'D15',
          'B7',
          'D34',
          'B60',
          'C32',
          'A2',
          'C20',
          'A37',
          'B34',
          'C2',
          'A21',
          'C11',
          'A8',
          'D37',
          'A60',
          'B56',
          'D36',
          'B23',
          'A41',
          'C21',
          'D2',
          'A54',
          'C37',
          'D26',
          'C44',
          'C25',
          'B48',
          'D40',
          'B2',
          'D42',
          'B58',
          'A58',
          'B49',
          'C30',
          'D11',
          'B21',
          'D43',
          'A57',
          'D12',
          'D27',
          'B6',
          'D37',
          'C42',
          'C57',
          'B42',
          'A1',
          'D14',
          'C17',
          'D15',
          'C58',
          'B45',
          'A59',
          'D39',
          'B3',
          'D47',
          'B9',
          'B60',
          'D33',
          'C36',
          'B55',
          'B14',
          'A44',
          'B46',
          'A5',
          'C18',
          'A19',
          'C1',
          'B37',
          'A39',
          'C24',
          'B54',
          'C21',
          'C58',
          'D22',
          'C51',
          'A54',
          'C44',
          'D9',
          'B1',
          'D4',
          'C54',
          'A53',
          'C52',
          'D13',
          'B10',
          'D8',
          'C53',
          'B41',
          'A2',
          'B48',
          'C34',
          'C60',
          'D21',
          'C21',
          'C49',
          'A56',
          'D44',
          'B43',
          'C29',
          'B44',
          'C56',
          'D35',
          'B49',
          'C25',
          'A36',
          'B27',
          'A33',
          'C11',
          'A27',
          'C6',
          'A17',
          'C12',
          'A31',
          'B17',
          'D29',
          'C33',
          'D9',
          'C24',
          'D31',
          'D7',
          'A50',
          'B11',
          'D37',
          'B48',
          'C41',
          'B58',
          'A14',
          'C14',
          'A26',
          'B12',
          'A46',
          'B43',
          'A4',
          'C19',
          'D2',
          'A47',
          'B40',
          'B1',
          'D43',
          'B45',
          'C29',
          'B46',
          'C27',
          'D17',
          'A52',
          'C46',
          'D31',
          'A5',
          'D48',
          'A1',
          'B48',
          'C23',
          'D10',
          'C16',
          'A34',
          'C26',
          'D1',
          'B18',
          'D52',
          'B25',
          'C2',
          'B36',
          'D59',
          'B27',
          'D29',
          'D9',
          'A53',
          'C50',
          'D30',
          'B60',
          'B7',
          'A18',
          'C20',
          'B56',
          'B13',
          'D25',
          'A55',
          'C37',
          'D23',
          'C51',
          'B58',
          'D36',
          'B12',
          'B57',
          'A56',
          'C43',
          'C26',
          'D7',
          'C55',
          'B44',
          'A2',
          'B46',
          'D43',
          'B31',
          'A35',
          'C13',
          'A28',
          'B17',
          'A26',
          'C5',
          'B33',
          'C3',
          'A22',
          'C7',
          'A9',
          'B55',
          'D39',
          'C46',
          'D6',
          'B16',
          'D54',
          'A10',
          'B54',
          'C47',
          'D34',
          'D4',
          'A52',
          'C55',
          'D11',
          'D26',
          'C20',
          'C60',
          'D14',
          'A51',
          'C40',
          'B47',
          'D38',
          'B9',
          'A21',
          'C25',
          'C48',
          'A56',
          'D40',
          'B20',
          'A30',
          'C8',
          'A3',
          'D46',
          'A60',
          'B42',
          'D45',
          'B4',
          'D39',
          'B46',
          'C58',
          'D17',
          'C47',
          'B55',
          'D38',
          'B5',
          'D32',
          'C23',
          'C57',
          'B43',
          'A43',
          'B21',
          'A42',
          'B41',
          'C52',
          'C21',
          'B49',
          'D24',
          'C50',
          'A54',
          'C36',
          'D35',
          'B58',
          'C15',
          'B59',
          'C41',
          'D26',
          'C60',
          'D4',
          'D33',
          'B11',
          'D7',
          'C35',
          'B55',
          'A47',
          'C29',
          'B60',
          'A56',
          'D21',
          'C57',
          'B46',
          'C24',
          'A44',
          'B15',
          'B54',
          'C18',
          'D5',
          'A49',
          'B36',
          'D57',
          'A13',
          'B58',
          'C31',
          'B30',
          'D55',
          'B31',
          'A55',
          'C44',
          'A46',
          'C42',
          'D19',
          'C22',
          'C37',
          'B51',
          'C36',
          'A56',
          'B43',
          'A3',
          'B47',
          'C39',
          'A53',
          'D21',
          'B5',
          'D2',
          'C32',
          'D34',
          'C28',
          'D23',
          'A5',
          'C2',
          'A18',
          'C14',
          'A7',
          'B52',
          'C45',
          'B55',
          'C33',
          'A43',
          'B33',
          'D40',
          'A58',
          'D45',
          'B14',
          'D17',
          'C43',
          'D31',
          'D7',
          'C25',
          'D29',
          'D14',
          'C52',
          'A50',
          'C48',
          'B37',
          'D39',
          'B21',
          'A30',
          'B24',
          'D60',
          'B25',
          'A38',
          'C16',
          'A1',
          'B41',
          'A41',
          'C20',
          'B57',
          'C33',
          'D27',
          'C57',
          'D10',
          'A54',
          'D42',
          'B31',
          'D58',
          'A12',
          'B55',
          'A53',
          'B36',
          'D40',
          'B3',
          'A15',
          'B4',
          'D3',
          'C51',
          'D28',
          'C20',
          'D30',
          'B25',
          'D59',
          'A11',
          'C4',
          'A4',
          'D20',
          'B5',
          'B42',
          'C52',
          'A51',
          'C48',
          'B38',
          'A39',
          'B26',
          'D21',
          'B55',
          'C44',
          'D39',
          'B8',
          'C18',
          'D8',
          'D24',
          'C47',
          'A54',
          'D41',
          'A55',
          'C42',
          'A60',
          'D45',
          'C58',
          'D5',
          'D33',
          'B14',
          'D7',
          'A57',
          'B49',
          'A2',
          'C15',
          'B60',
          'A16',
          'D60',
          'A7',
          'B48',
          'C40',
          'C25',
          'B51',
          'C23',
          'A30',
          'C14',
          'A37',
          'C19',
          'D12',
          'A60',
          'D41',
          'B54',
          'B9',
          'A20',
          'C4',
          'A23',
          'B13',
          'A36',
          'C21',
          'B55',
          'D37',
          'B39',
          'B2',
          'D48',
          'B8',
          'D9',
          'C31',
          'B43',
          'C59',
          'D4',
          'C47',
          'D28',
          'C30',
          'A5',
          'D50',
          'B20',
          'D56',
          'A13',
          'B53',
          'C36',
          'D26',
          'A8',
          'B54',
          'A58',
          'D23',
          'A3',
          'B40',
          'C55',
          'C24',
          'D34',
          'C23',
          'A1',
          'D13',
          'C45',
          'A54',
          'D14',
          'B7',
          'B45',
          'A45',
          'B51',
          'D45',
          'B28',
          'A33',
          'B6',
          'D44',
          'B44',
          'B8',
          'A27',
          'C5',
          'A3',
          'C11',
          'A60',
          'C40',
          'D32',
          'B17',
          'D3',
          'C32',
          'B31',
          'D53',
          'B16',
          'A32',
          'C22',
          'A34',
          'B15',
          'D7',
          'C43',
          'A57',
          'D46',
          'C57',
          'D6',
          'C30',
          'D16',
          'C29',
          'D31',
          'B55',
          'A45',
          'B57',
          'A17',
          'D1',
          'A12',
          'C17',
          'D18',
          'B6',
          'D35',
          'A60',
          'B48',
          'C39',
          'C56',
          'C33',
          'A55',
          'C38',
          'D20',
          'C52',
          'D22',
          'B3',
          'C13',
          'A37',
          'B35',
          'D41',
          'A57',
          'D27',
          'B46',
          'C41',
          'A47',
          'B42',
          'A4',
          'D31',
          'B24',
          'C3',
          'A9',
          'C18',
          'D12',
          'A2',
          'D44',
          'B17',
          'C21',
          'D7',
          'A15',
          'B60',
          'A21',
          'D1',
          'C25',
          'C51',
          'D11',
          'C50',
          'A51',
          'D18',
          'C55',
          'A53',
          'C54',
          'B40',
          'C27',
          'B47',
          'A43',
          'C36',
          'B54',
          'C34',
          'A54',
          'D38',
          'A58',
          'B42',
          'A3',
          'C22',
          'D34',
          'B9',
          'C18',
          'D24',
          'C37',
          'A60',
          'B53',
          'C26',
          'A35',
          'B32',
          'D42',
          'B22',
          'C1',
          'A10',
          'D53',
          'B10',
          'D11',
          'B55',
          'C17',
          'B56',
          'C53',
          'A49',
          'C27',
          'B49',
          'A9',
          'C9',
          'A29',
          'B21',
          'C25',
          'D18',
          'C43',
          'B4',
          'D36',
          'C47',
          'A56',
          'D47',
          'B12',
          'B50',
          'A3',
          'D25',
          'C26',
          'C46',
          'D12',
          'D27',
          'C31',
          'A48',
          'B39',
          'D34',
          'D1',
          'D30',
          'B41',
          'C60',
          'B48',
          'C32',
          'B57',
          'D37',
          'B3',
          'B37',
          'D38',
          'C53',
          'D10',
          'C56',
          'D6',
          'B20',
          'D44',
          'A27',
          'B11',
          'C21',
          'B18',
          'A26',
          'D40',
          'A25',
          'B12',
          'D11',
          'A50',
          'C51',
          'B41',
          'C20',
          'D7',
          'D31',
          'C28',
          'B27',
          'D58',
          'B28',
          'D56',
          'A12',
          'D2',
          'C55',
          'B36',
          'B4',
          'A14',
          'D4',
          'C45',
          'D14',
          'C16',
          'D15',
          'C39',
          'A51',
          'C57',
          'A52',
          'B58',
          'A18',
          'C10',
          'A60',
          'D22',
          'C42',
          'D39',
          'C36',
          'A57',
          'D16',
          'B6',
          'A19',
          'C7',
          'A24',
          'C3',
          'B42',
          'C51',
          'A55',
          'D45',
          'B2',
          'B45',
          'D41',
          'B1',
          'C40',
          'D23',
          'A59',
          'C44',
          'B52',
          'B5',
          'B35',
          'C58',
          'C19',
          'A40',
          'B23',
          'C1',
          'B43',
          'C37',
          'D32',
          'A2',
          'D3',
          'A1',
          'D33',
          'B4',
          'C13',
          'A22',
          'C25',
          'B53',
          'C22',
          'C57',
          'B60',
          'A49',
          'B37',
          'C56',
          'B27',
          'D26',
          'C35',
          'D15',
          'B33',
          'D58',
          'A15',
          'D1',
          'A46',
          'B50',
          'C58',
          'B26',
          'A37',
          'C18',
          'A10',
          'C7',
          'B31',
          'D45',
          'B5',
          'A22',
          'C2',
          'B21',
          'C24',
          'C56',
          'D27',
          'B48',
          'D37',
          'C27',
          'C42',
          'B4',
          'D51',
          'B30',
          'A36',
          'C30',
          'D29',
          'D9',
          'A25',
          'D41',
          'C55',
          'D15',
          'C49',
          'A53',
          'C46',
          'A56',
          'C38',
          'B50',
          'D7',
          'C57',
          'B26',
          'D60',
          'B29',
          'A38',
          'C26',
          'B13',
          'D35',
          'C22',
          'B54',
          'D40',
          'B7',
          'A17',
          'B59',
          'C31',
          'B16',
          'C30',
          'A54',
          'C43',
          'D20',
          'A20',
          'C14',
          'A9',
          'D28',
          'B17',
          'B58',
          'C17',
          'B9',
          'D14',
          'A18',
          'B2',
          'C11',
          'B3',
          'C44',
          'D16',
          'C16',
          'D18',
          'B4',
          'B55',
          'D35',
          'B59',
          'A57',
          'D39',
          'B33',
          'D22',
          'C41',
          'A59',
          'D47',
          'B30',
          'C60',
          'D5',
          'A14',
          'B54',
          'B14',
          'D32',
          'C25',
          'C54',
          'D7',
          'C52',
          'A53',
          'D43',
          'A56',
          'C45',
          'D10',
          'C29',
          'B28',
          'C28',
          'B45',
          'A5',
          'B47',
          'A48',
          'D6',
          'A14',
          'C1',
          'B21',
          'A28',
          'D47',
          'A33',
          'B29',
          'D56',
          'B37',
          'C47',
          'D38',
          'B38',
          'D11',
          'C24',
          'A54',
          'B54',
          'B39',
          'C48',
          'D8',
          'C52',
          'C34',
          'B19',
          'A31',
          'C10',
          'A6',
          'C20',
          'D32',
          'A60',
          'C36',
          'D23',
          'D7',
          'A28',
          'C6',
          'B32',
          'D55',
          'B37',
          'D28',
          'B4',
          'B44',
          'D50',
          'A34',
          'D46',
          'B3',
          'D30',
          'C30',
          'B29',
          'C28',
          'D10',
          'C43',
          'A59',
          'C38',
          'A3',
          'C20',
          'B18',
          'C33',
          'A60',
          'C29',
          'D33',
          'B23',
          'D25',
          'C39',
          'A44',
          'B53',
          'A1',
          'D47',
          'B36',
          'C58',
          'A51',
          'C56',
          'A52',
          'C53',
          'B40',
          'C21',
          'D4',
          'B12',
          'D9',
          'B5',
          'D51',
          'A4',
          'D18',
          'D33',
          'C25',
          'B23',
          'D60',
          'A19',
          'B59',
          'A16',
          'B2',
          'D38',
          'B47',
          'C35',
          'A53',
          'C48',
          'A55',
          'D49',
          'A6',
          'C17',
          'A17',
          'C23',
          'D29',
          'B5',
          'C16',
          'D19',
          'B3',
          'C32',
          'D5',
          'C59',
          'B33',
          'D17',
          'B9',
          'B58',
          'C56',
          'B38',
          'D9',
          'C52',
          'D27',
          'C26',
          'B20',
          'C36',
          'B1',
          'C9',
          'A31',
          'B6',
          'B41',
          'C27',
          'B52',
          'C37',
          'A56',
          'D42',
          'A59',
          'D36',
          'C22',
          'D1',
          'A11',
          'B51',
          'B10',
          'C47',
          'D29',
          'D10',
          'C15',
          'A13',
          'C2',
          'B42',
          'D58',
          'B34',
          'C42',
          'A57',
          'D24',
          'B7',
          'A29',
          'D43',
          'B53',
          'C35',
          'D8',
          'C17',
          'B14',
          'A23',
          'C12',
          'A37',
          'B10',
          'D36',
          'C20',
          'C56',
          'A55',
          'C53',
          'D2',
          'D34',
          'B2',
          'C33',
          'B19',
          'D57',
          'B27',
          'C5',
          'A12',
          'C3',
          'A25',
          'D38',
          'B8',
          'D18',
          'B24',
          'D23',
          'A21',
          'B1',
          'D39',
          'B46',
          'C40',
          'B49',
          'B13',
          'B60',
          'C27',
          'A60',
          'D5',
          'D33',
          'B12',
          'C20',
          'D4',
          'A13',
          'D55',
          'B42',
          'A41',
          'C30',
          'A59',
          'D49',
          'A35',
          'C31',
          'C56',
          'B45',
          'D40',
          'C59',
          'A52',
          'D60',
          'B30',
          'D28',
          'D13',
          'C21',
          'A54',
          'C46',
          'A10',
          'C6',
          'A22',
          'D1',
          'B8',
          'A18',
          'B9',
          'C22',
          'D3',
          'C50',
          'A55',
          'D44',
          'B44',
          'A4',
          'B54',
          'D39',
          'B24',
          'C24',
          'B11',
          'D57',
          'A49',
          'C49',
          'B56',
          'C35',
          'A8',
          'C19',
          'B7',
          'A28',
          'B22',
          'D40',
          'A57',
          'C45',
          'A58',
          'C34',
          'D26',
          'B48',
          'C55',
          'D19',
          'C41',
          'B5',
          'B54',
          'C54',
          'D5',
          'A20',
          'B1',
          'D49',
          'A32',
          'D48',
          'B21',
          'C22',
          'A44',
          'B40',
          'B8',
          'D15',
          'C45',
          'D38',
          'B56',
          'A58',
          'C8',
          'A8',
          'B46',
          'B2',
          'D30',
          'D3',
          'A46',
          'B54',
          'C51',
          'D13',
          'C18',
          'B42',
          'D51',
          'C46',
          'A55',
          'D48',
          'B27',
          'C31',
          'D28',
          'C26',
          'A19',
          'B1',
          'B52',
          'B32',
          'A1',
          'B37',
          'D46',
          'B12',
          'C49',
          'D22',
          'B4',
          'D12',
          'D27',
          'C48',
          'B9',
          'D54',
          'B11',
          'D48',
          'B34',
          'C41',
          'A55',
          'C2',
          'A23',
          'D7',
          'C32',
          'D26',
          'A7',
          'C4',
          'B23',
          'C56',
          'B46',
          'C28',
          'D35',
          'B56',
          'C34',
          'A40',
          'B43',
          'D45',
          'B37',
          'C58',
          'A52',
          'C55',
          'B53',
          'D42',
          'B44',
          'C59',
          'B34',
          'B6',
          'D41',
          'C42',
          'D24',
          'C16',
          'A19',
          'C5',
          'B35',
          'C4',
          'A21',
          'C10',
          'B3',
          'A13',
          'B4',
          'C32',
          'B19',
          'C20',
          'D37',
          'A27',
          'D6',
          'C58',
          'D18',
          'B49',
          'C39',
          'A55',
          'C29',
          'B16',
          'D46',
          'A53',
          'C52',
          'A54',
          'B60',
          'B15',
          'C17',
          'B59',
          'D35',
          'B37',
          'D54',
          'B4',
          'A34',
          'D51',
          'B6',
          'B51',
          'A6',
          'D21',
          'C53',
          'A10',
          'D56',
          'C58',
          'B39',
          'D46',
          'B42',
          'B27',
          'A32',
          'B18',
          'C35',
          'A56',
          'D25',
          'A22',
          'A60',
          'C26',
          'C47',
          'B59',
          'D15',
          'A18',
          'C8',
          'B30',
          'C30',
          'A45',
          'D2',
          'D34',
          'C50',
          'D21',
          'B7',
          'D49',
          'B20',
          'C25',
          'D10',
          'A25',
          'C2',
          'B26',
          'D59',
          'A52',
          'C53',
          'D5',
          'D32',
          'B47',
          'C56',
          'B60',
          'A14',
          'B2',
          'C14',
          'B5',
          'C13',
          'B55',
          'D17',
          'B37',
          'C19',
          'B41',
          'B1',
          'C35',
          'A57',
          'C27',
          'D23',
          'C49',
          'B16',
          'A27',
          'D8',
          'C29',
          'B50',
          'B11',
          'D14',
          'A21',
          'C8',
          'A59',
          'B53',
          'A11',
          'C18',
          'B54',
          'D38',
          'C25',
          'C50',
          'A52',
          'C38',
          'D22',
          'A22',
          'C40',
          'D6',
          'C36',
          'C51',
          'D18',
          'B5',
          'D53',
          'B32',
          'C60',
          'B58',
          'A20',
          'C12',
          'A38',
          'B44',
          'B10',
          'B53',
          'A56',
          'D41',
          'C34',
          'A9',
          'D29',
          'C22',
          'B13',
          'B52',
          'D44',
          'A33',
          'B30',
          'D59',
          'A16',
          'C18',
          'A28',
          'D46',
          'B27',
          'C45',
          'D30',
          'C19',
          'B53',
          'A41',
          'B55',
          'A4',
          'D4',
          'A2',
          'C23',
          'A31',
          'C8',
          'A60',
          'D19',
          'A23',
          'A59',
          'B46',
          'B6',
          'D50',
          'B32',
          'D18',
          'B19',
          'C2',
          'B20',
          'D43',
          'A55',
          'C21',
          'B21',
          'D36',
          'B57',
          'C28',
          'D11',
          'A26',
          'C36',
          'B5',
          'A12',
          'D54',
          'A39',
          'C16',
          'D21',
          'A20',
          'C23',
          'B17',
          'A25',
          'D4',
          'A56',
          'D29',
          'C51',
          'C22',
          'A5',
          'B51',
          'D45',
          'B32',
          'D43',
          'A30',
          'D50',
          'B11',
          'C46',
          'B54',
          'D37',
          'B9',
          'D9',
          'C58',
          'A54',
          'C57',
          'A58',
          'C27',
          'B12',
          'C24',
          'D12',
          'C53',
          'D16',
          'A31',
          'D41',
          'B53',
          'B6',
          'A12',
          'C13',
          'A18',
          'B56',
          'A44',
          'B51',
          'C59',
          'D14',
          'C21',
          'D19',
          'C45',
          'C28',
          'A43',
          'B48',
          'B33',
          'D36',
          'A56',
          'C33',
          'B59',
          'A15',
          'B10',
          'D58',
          'B35',
          'D48',
          'A27',
          'C7',
          'B29',
          'C49',
          'A29',
          'C6',
          'A1',
          'B31',
          'D56',
          'B3',
          'D55',
          'A47',
          'C47',
          'A53',
          'C31',
          'D7',
          'D31',
          'C26',
          'B47',
          'A56',
          'C44',
          'D30',
          'A55',
          'B49',
          'A48',
          'B54',
          'C38',
          'A59',
          'D20',
          'B17',
          'C34',
          'B32',
          'D41',
          'C32',
          'B5',
          'A20',
          'C1',
          'B24',
          'C40',
          'D34',
          'B11',
          'D53',
          'B19',
          'D12',
          'B37',
          'C55',
          'B60',
          'D35',
          'C21',
          'B13',
          'C18',
          'D14',
          'D29',
          'B6',
          'C42',
          'D5',
          'C50',
          'B15',
          'C32',
          'A40',
          'B56',
          'A16',
          'D9',
          'C29',
          'B26',
          'A34',
          'D47',
          'A35',
          'C17',
          'D2',
          'D34',
          'C37',
          'A59',
          'C12',
          'A26',
          'C9',
          'A57',
          'C4',
          'B40',
          'B7',
          'D19',
          'C15',
          'A17',
          'B1',
          'C27',
          'A18',
          'D17',
          'C18',
          'C57',
          'B56',
          'A10',
          'C44',
          'A24',
          'D39',
          'D24',
          'C40',
          'A54',
          'D46',
          'B14',
          'A36',
          'D53',
          'B37',
          'D32',
          'B16',
          'D45',
          'B42',
          'C30',
          'B59',
          'A2',
          'D57',
          'B30',
          'B53',
          'D40',
          'A60',
          'A22',
          'C1',
          'B28',
          'A32',
          'B4',
          'D27',
          'B23',
          'C41',
          'A9',
          'C19',
          'D38',
          'B1',
          'C8',
          'A11',
          'C16',
          'B14',
          'B51',
          'C22',
          'A53',
          'C59',
          'B35',
          'D36',
          'B58',
          'B5',
          'D54',
          'B30',
          'D46',
          'B40',
          'A5',
          'D28',
          'B44',
          'D41',
          'A49',
          'C54',
          'D9',
          'B21',
          'D60',
          'A14',
          'B10',
          'C40',
          'B34',
          'A2',
          'D7',
          'D31',
          'C38',
          'A48',
          'C44',
          'B59',
          'C25',
          'B26',
          'D50',
          'A7',
          'C20',
          'B51',
          'C58',
          'C24',
          'D13',
          'A27',
          'D36',
          'C36',
          'D40',
          'B45',
          'A1',
          'C46',
          'B39',
          'B9',
          'A17',
          'C11',
          'A38',
          'D52',
          'B3',
          'C33',
          'B20',
          'A31',
          'D50',
          'B15',
          'A36',
          'A21',
          'C7',
          'B30',
          'D44',
          'A51',
          'D60',
          'A53',
          'D59',
          'B8',
          'A22',
          'D27',
          'D12',
          'B7',
          'D10',
          'C52',
          'B23',
          'C29',
          'C56',
          'B28',
          'C31',
          'B48',
          'D25',
          'C16',
          'D36',
          'B3',
          'D15',
          'C48',
          'D22',
          'C45',
          'B2',
          'D23',
          'B49',
          'A7',
          'C9',
          'C60',
          'D50',
          'A58',
          'D24',
          'A23',
          'C1',
          'A11',
          'B58',
          'C55',
          'C35',
          'D40',
          'B32',
          'D38',
          'B46',
          'D36',
          'A16',
          'B9',
          'D4',
          'A1',
          'C28',
          'D16',
          'B24',
          'C23',
          'B60',
          'D35',
          'B4',
          'D21',
          'C51',
          'A53',
          'C45',
          'D52',
          'B14',
          'C26',
          'B16',
          'C48',
          'A57',
          'C58',
          'C14',
          'A10',
          'C33',
          'B1',
          'D47',
          'B38',
          'D6',
          'D34',
          'A27',
          'C4',
          'A22',
          'D7',
          'B28',
          'D48',
          'B29',
          'B48',
          'A39',
          'D51',
          'B25',
          'A35',
          'B33',
          'C43',
          'B3',
          'C31',
          'B19',
          'D52',
          'B37',
          'D15',
          'B6',
          'A19',
          'C13',
          'A49',
          'C53',
          'B18',
          'D58',
          'A53',
          'D42',
          'B43',
          'C30',
          'D41',
          'C27',
          'B28',
          'C50',
          'A55',
          'D37',
          'B2',
          'C44',
          'D40',
          'A24',
          'C5',
          'A29',
          'D9',
          'A19',
          'B42',
          'D43',
          'B5',
          'D17',
          'B57',
          'B10',
          'C25',
          'A45',
          'C24',
          'B47',
          'C42',
          'D29',
          'D10',
          'C22',
          'A22',
          'A37',
          'D53',
          'A11',
          'C6',
          'B26',
          'D49',
          'A33',
          'D19',
          'C52',
          'D12',
          'B11',
          'C40',
          'D14',
          'A28',
          'C11',
          'A59',
          'C34',
          'A4',
          'A56',
          'A3',
          'B33',
          'D38',
          'A26',
          'D45',
          'B19',
          'D3',
          'C55',
          'D4',
          'B40',
          'D31',
          'D8',
          'B14',
          'C12',
          'B5',
          'A38',
          'B32',
          'C6',
          'B34',
          'C16',
          'A7',
          'D20',
          'A56',
          'D28',
          'C45',
          'B8',
          'C44',
          'A58',
          'C56',
          'B52',
          'A12',
          'D55',
          'B10',
          'C22',
          'B17',
          'A20',
          'A37',
          'B11',
          'A14',
          'C25',
          'D2',
          'D33',
          'B36',
          'D58',
          'B43',
          'D30',
          'A42',
          'B58',
          'D36',
          'B48',
          'A9',
          'D39',
          'A53',
          'D47',
          'C36',
          'A48',
          'C14',
          'A15',
          'C3',
          'B25',
          'A30',
          'C49',
          'A55',
          'D33',
          'D6',
          'B50',
          'C23',
          'A4',
          'D57',
          'A54',
          'C19',
          'B19',
          'D31',
          'B26',
          'C44',
          'B16',
          'C24',
          'B49',
          'D22',
          'B14',
          'D51',
          'A29',
          'B4',
          'C31',
          'B31',
          'C7',
          'B1',
          'B47',
          'B12',
          'D26',
          'B6',
          'C11',
          'C59',
          'B45',
          'B30',
          'C49',
          'D35',
          'B7',
          'D5',
          'C41',
          'B9',
          'D59',
          'A17',
          'D38',
          'B34',
          'D24',
          'C45',
          'B37',
          'D48',
          'B41',
          'D46',
          'A59',
          'C28',
          'A24',
          'D6',
          'A29',
          'D49',
          'B15',
          'D4',
          'C19',
          'D7',
          'D23',
          'C35',
          'A26',
          'C22',
          'A47',
          'D38',
          'B56',
          'A5',
          'C21',
          'D29',
          'C40',
          'A46',
          'C39',
          'A58',
          'C47',
          'A50',
          'D57',
          'B23',
          'D43',
          'B47',
          'A45',
          'D4',
          'C44',
          'D53',
          'B13',
          'D9',
          'A14',
          'D56',
          'B1',
          'C26',
          'B22',
          'D49',
          'B33',
          'C17',
          'D17',
          'A19',
          'A37',
          'A18',
          'A38',
          'C9',
          'A20',
          'B41',
          'A17',
          'B43',
          'D45',
          'A35',
          'D54',
          'B6',
          'D13',
          'C47',
          'A55',
          'D57',
          'A9',
          'C49',
          'B58',
          'C59',
          'A53',
          'C25',
          'D5',
          'C38',
          'D39',
          'B53',
          'B12',
          'C23',
          'D33',
          'D18',
          'C57',
          'B57',
          'A43',
          'C31',
          'B5',
          'C10',
          'A11',
          'B54',
          'B11',
          'D52',
          'B21',
          'D50',
          'A39',
          'A18',
          'B39',
          'C28',
          'A32',
          'D37',
          'B56',
          'C33',
          'A14',
          'B12',
          'C19',
          'D39',
          'A60',
          'A21',
          'B3',
          'D28',
          'C44',
          'C29',
          'A48',
          'B41',
          'C58',
          'B27',
          'C2',
          'A9',
          'D25',
          'C47',
          'B7',
          'B32',
          'D59',
          'B17',
          'C12',
          'B1',
          'D53',
          'B6',
          'D20',
          'A40',
          'B58',
          'C54',
          'A51',
          'C1',
          'A24',
          'A58',
          'C42',
          'A2',
          'B40',
          'D52',
          'B52',
          'D44',
          'B31',
          'D51',
          'C49',
          'B36',
          'D57',
          'A60',
          'A8',
          'C15',
          'C39',
          'A25',
          'B18',
          'C34',
          'A37',
          'D23',
          'B13',
          'C42',
          'B45',
          'D45',
          'A28',
          'C4',
          'A12',
          'D59',
          'A49',
          'C51',
          'B60',
          'D35',
          'D1',
          'C45',
          'D2',
          'B7',
          'D46',
          'B15',
          'B52',
          'D43',
          'B43',
          'D37',
          'B57',
          'A6',
          'C19',
          'D16',
          'C39',
          'D36',
          'B58',
          'A19',
          'C10',
          'B6',
          'C59',
        ],
      },
    ],
  },
  {
    title: 'Love',
    id: 'love',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Love%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C37',
          'A50',
          'C36',
          'A48',
          'C35',
          'A46',
          'C34',
          'A47',
          'C38',
          'A52',
          'C40',
          'A55',
          'C41',
          'A56',
          'C43',
          'B48',
          'C42',
          'A54',
          'C39',
          'A49',
          'C44',
          'A58',
          'C45',
          'A59',
          'C46',
          'A60',
          'C49',
          'B3',
          'C50',
          'B49',
          'C52',
          'B58',
          'C60',
          'B60',
          'C56',
          'D2',
          'A1',
          'D7',
          'A2',
          'D18',
          'A10',
          'D19',
          'A11',
          'D14',
          'A9',
          'D11',
          'A4',
          'D16',
          'A3',
          'A18',
          'D21',
          'A16',
          'D20',
          'A13',
          'D15',
          'A8',
          'D10',
          'A1',
          'D8',
          'A2',
          'D17',
          'A10',
          'D13',
          'A6',
          'D6',
          'C60',
          'D1',
          'C55',
          'D16',
          'C53',
          'B59',
          'C52',
          'B6',
          'C51',
          'B18',
          'C33',
          'A44',
          'C36',
          'A45',
          'C35',
          'A48',
          'C38',
          'A49',
          'C37',
          'A51',
          'C41',
          'B43',
          'C40',
          'A53',
          'C39',
          'A54',
          'C42',
          'A57',
          'C43',
          'B48',
          'C44',
          'A59',
          'C45',
          'A60',
          'D53',
          'B39',
          'A8',
          'D18',
          'A1',
          'D5',
          'C60',
          'B59',
          'C57',
          'B60',
          'C54',
          'D16',
          'C52',
          'D11',
          'A10',
          'D15',
          'C48',
          'B2',
          'C47',
          'D9',
          'A2',
          'D1',
          'C45',
          'A60',
          'D52',
          'B38',
          'D51',
          'B36',
          'D49',
          'B34',
          'D47',
          'B32',
          'C6',
          'B46',
          'C41',
          'B43',
          'C39',
          'A46',
          'C34',
          'B19',
          'C33',
          'A45',
          'C38',
          'A53',
          'C43',
          'B48',
          'C42',
          'A50',
          'C36',
          'A42',
          'C32',
          'A43',
          'C37',
          'A51',
          'C40',
          'A55',
          'D50',
          'A56',
          'C41',
          'B43',
          'C39',
          'A47',
          'C34',
          'B20',
          'C35',
          'B21',
          'D38',
          'B22',
          'D39',
          'B23',
          'D41',
          'B26',
          'C55',
          'B27',
          'C56',
          'B28',
          'D43',
          'B29',
          'C3',
          'B45',
          'A19',
          'A3',
          'A20',
          'B44',
          'A8',
          'D19',
          'A12',
          'D20',
          'A14',
          'D18',
          'A2',
          'D15',
          'A1',
          'D11',
          'C51',
          'D16',
          'A9',
          'B39',
          'C20',
          'B51',
          'A22',
          'B56',
          'C44',
          'B57',
          'A24',
          'C22',
          'B5',
          'D57',
          'B42',
          'C31',
          'A40',
          'C30',
          'B16',
          'C33',
          'B17',
          'C32',
          'B19',
          'C6',
          'B32',
          'D45',
          'B30',
          'C4',
          'B46',
          'C5',
          'B31',
          'C57',
          'D1',
          'C60',
          'D2',
          'C55',
          'D10',
          'C53',
          'A8',
          'B40',
          'C23',
          'B6',
          'C52',
          'B19',
          'C32',
          'B42',
          'A18',
          'D22',
          'A2',
          'D24',
          'A31',
          'C26',
          'B10',
          'C25',
          'B8',
          'C24',
          'A28',
          'D60',
          'B43',
          'C38',
          'A53',
          'D49',
          'A54',
          'C46',
          'D8',
          'A9',
          'B52',
          'A8',
          'D12',
          'A1',
          'D4',
          'A3',
          'A21',
          'B56',
          'C37',
          'A52',
          'C44',
          'B58',
          'A25',
          'D34',
          'B11',
          'C26',
          'A32',
          'C2',
          'B42',
          'C31',
          'B18',
          'C33',
          'A39',
          'C32',
          'B15',
          'C29',
          'A37',
          'C6',
          'B20',
          'C34',
          'B17',
          'C30',
          'B41',
          'A15',
          'B44',
          'A9',
          'D9',
          'A1',
          'D13',
          'C56',
          'B29',
          'C37',
          'A47',
          'C36',
          'A49',
          'C35',
          'B42',
          'C33',
          'A41',
          'C31',
          'B43',
          'D59',
          'B6',
          'D56',
          'B41',
          'C27',
          'B13',
          'C48',
          'D15',
          'C50',
          'B4',
          'D32',
          'B3',
          'D54',
          'B37',
          'A3',
          'A22',
          'B52',
          'C22',
          'B40',
          'A12',
          'B53',
          'A13',
          'D16',
          'A1',
          'D6',
          'C49',
          'B14',
          'C28',
          'A35',
          'C4',
          'B18',
          'D37',
          'B19',
          'C7',
          'B33',
          'D50',
          'B37',
          'C15',
          'A52',
          'C42',
          'B48',
          'C41',
          'A48',
          'C12',
          'B47',
          'C17',
          'B38',
          'D54',
          'A58',
          'C43',
          'B57',
          'B42',
          'C30',
          'B17',
          'C3',
          'B29',
          'C56',
          'B9',
          'C55',
          'D13',
          'A7',
          'D14',
          'C47',
          'A57',
          'C42',
          'A55',
          'D51',
          'A54',
          'C44',
          'A59',
          'D54',
          'B40',
          'D58',
          'B43',
          'C38',
          'B48',
          'C41',
          'A50',
          'C13',
          'A13',
          'B44',
          'A19',
          'B55',
          'A18',
          'B45',
          'A17',
          'D21',
          'A15',
          'B54',
          'C36',
          'B19',
          'C5',
          'B31',
          'D44',
          'B25',
          'D42',
          'B28',
          'C2',
          'B43',
          'B58',
          'C47',
          'B12',
          'C31',
          'B15',
          'C49',
          'B49',
          'C54',
          'A9',
          'D12',
          'A10',
          'D20',
          'A15',
          'B45',
          'A8',
          'B34',
          'C9',
          'B47',
          'C8',
          'A40',
          'C7',
          'A36',
          'C30',
          'A39',
          'C10',
          'B46',
          'C4',
          'B30',
          'C56',
          'D9',
          'A44',
          'D10',
          'C49',
          'B1',
          'D53',
          'B2',
          'D31',
          'B3',
          'C9',
          'B20',
          'C34',
          'A38',
          'C6',
          'B21',
          'C35',
          'B18',
          'C51',
          'B44',
          'C1',
          'A30',
          'C27',
          'B9',
          'C25',
          'B53',
          'A23',
          'A3',
          'D1',
          'C52',
          'D14',
          'C58',
          'D17',
          'C60',
          'D3',
          'A4',
          'A27',
          'D37',
          'B16',
          'C50',
          'D16',
          'C49',
          'D7',
          'A5',
          'D2',
          'C51',
          'B5',
          'C53',
          'B7',
          'C26',
          'B41',
          'A16',
          'B54',
          'C30',
          'B42',
          'A18',
          'B44',
          'D60',
          'A29',
          'D38',
          'A3',
          'D3',
          'C57',
          'B35',
          'D48',
          'A51',
          'C14',
          'A14',
          'B45',
          'A13',
          'C12',
          'B21',
          'C34',
          'A43',
          'C32',
          'B16',
          'C29',
          'B54',
          'A23',
          'D56',
          'B3',
          'C48',
          'D7',
          'C43',
          'A49',
          'D14',
          'C51',
          'D5',
          'D51',
          'A57',
          'C46',
          'D9',
          'A8',
          'B35',
          'D52',
          'B39',
          'C19',
          'B51',
          'A4',
          'A28',
          'D6',
          'D58',
          'B4',
          'D55',
          'B36',
          'A1',
          'B60',
          'C60',
          'B14',
          'D35',
          'B10',
          'C14',
          'A52',
          'D48',
          'A55',
          'C40',
          'A45',
          'D10',
          'A5',
          'D12',
          'C53',
          'B49',
          'C48',
          'D16',
          'A2',
          'D25',
          'A59',
          'C19',
          'A60',
          'C7',
          'B47',
          'C16',
          'A51',
          'C36',
          'B25',
          'D40',
          'B20',
          'C8',
          'B22',
          'A7',
          'B38',
          'A6',
          'D17',
          'C57',
          'B11',
          'C46',
          'B1',
          'D28',
          'A10',
          'C9',
          'A42',
          'C35',
          'B19',
          'C4',
          'B42',
          'C1',
          'A4',
          'A29',
          'D23',
          'A2',
          'B37',
          'D53',
          'D5',
          'D50',
          'A52',
          'C16',
          'B47',
          'C15',
          'B58',
          'C54',
          'B8',
          'D33',
          'B4',
          'C10',
          'B21',
          'C9',
          'A43',
          'D9',
          'A7',
          'B23',
          'D38',
          'A30',
          'A4',
          'A32',
          'C27',
          'A33',
          'C3',
          'B46',
          'D47',
          'A55',
          'C16',
          'A17',
          'B42',
          'C34',
          'B16',
          'C31',
          'B18',
          'C7',
          'B33',
          'B48',
          'A21',
          'C20',
          'A54',
          'C19',
          'B39',
          'D59',
          'B7',
          'C23',
          'B40',
          'C22',
          'A25',
          'D58',
          'D6',
          'D57',
          'B2',
          'C51',
          'B49',
          'C12',
          'B26',
          'C57',
          'B27',
          'D44',
          'D4',
          'D43',
          'B24',
          'D45',
          'B46',
          'C9',
          'A39',
          'C31',
          'A42',
          'C10',
          'B23',
          'C8',
          'B47',
          'C6',
          'B32',
          'A8',
          'C52',
          'B20',
          'C33',
          'A38',
          'A5',
          'B38',
          'C17',
          'A50',
          'C38',
          'B43',
          'D42',
          'D4',
          'C54',
          'D9',
          'C50',
          'B16',
          'C29',
          'B41',
          'D55',
          'B5',
          'D32',
          'B6',
          'C24',
          'B28',
          'D46',
          'B33',
          'A9',
          'B48',
          'C40',
          'A46',
          'D11',
          'C49',
          'D14',
          'A19',
          'C18',
          'A53',
          'D48',
          'B31',
          'D51',
          'B32',
          'C38',
          'B57',
          'A23',
          'D32',
          'B9',
          'C13',
          'B36',
          'A8',
          'D43',
          'B30',
          'C3',
          'B18',
          'C51',
          'D6',
          'D56',
          'B4',
          'C54',
          'B8',
          'C43',
          'A58',
          'C18',
          'B59',
          'B44',
          'C50',
          'B56',
          'A20',
          'B47',
          'C33',
          'A45',
          'C11',
          'B20',
          'D39',
          'A3',
          'D37',
          'D3',
          'A35',
          'A4',
          'C3',
          'D7',
          'C5',
          'A34',
          'C27',
          'B13',
          'C48',
          'A56',
          'C40',
          'B30',
          'C25',
          'B41',
          'A8',
          'B46',
          'D46',
          'A55',
          'D52',
          'A53',
          'D47',
          'A50',
          'C36',
          'B55',
          'A24',
          'D57',
          'B40',
          'D38',
          'A28',
          'D7',
          'C60',
          'B36',
          'C59',
          'B50',
          'C23',
          'B51',
          'C19',
          'B47',
          'C7',
          'B1',
          'C8',
          'B2',
          'D30',
          'B32',
          'A3',
          'D6',
          'C50',
          'B4',
          'D54',
          'A21',
          'B49',
          'C47',
          'D15',
          'C49',
          'B44',
          'A16',
          'D19',
          'B14',
          'C33',
          'B20',
          'C7',
          'B22',
          'D44',
          'A8',
          'C51',
          'B19',
          'D41',
          'D4',
          'C52',
          'B1',
          'C20',
          'A22',
          'D31',
          'B7',
          'C12',
          'B57',
          'C53',
          'B8',
          'C28',
          'B12',
          'C58',
          'B10',
          'C45',
          'D2',
          'A33',
          'A3',
          'B17',
          'C29',
          'A36',
          'C5',
          'B20',
          'D32',
          'D17',
          'C59',
          'D11',
          'C27',
          'B35',
          'D56',
          'D6',
          'D55',
          'A58',
          'C48',
          'D8',
          'C10',
          'A45',
          'D27',
          'A47',
          'D12',
          'A17',
          'B55',
          'C6',
          'B47',
          'C15',
          'B12',
          'D34',
          'B5',
          'C53',
          'B44',
          'D4',
          'C33',
          'B42',
          'C32',
          'A35',
          'A3',
          'A38',
          'C29',
          'B7',
          'D58',
          'A26',
          'D36',
          'D3',
          'C56',
          'B10',
          'C30',
          'B14',
          'C28',
          'B54',
          'C4',
          'D7',
          'C2',
          'B28',
          'C57',
          'B50',
          'C20',
          'B60',
          'A30',
          'C25',
          'B31',
          'B48',
          'A8',
          'C7',
          'A39',
          'A3',
          'B51',
          'C18',
          'A56',
          'D49',
          'D5',
          'A7',
          'D42',
          'B44',
          'A11',
          'C11',
          'A41',
          'C37',
          'B29',
          'D46',
          'B34',
          'A9',
          'B35',
          'D32',
          'D17',
          'C55',
          'B25',
          'A8',
          'B37',
          'D58',
          'B10',
          'C45',
          'D3',
          'D35',
          'A28',
          'D23',
          'A57',
          'C17',
          'B59',
          'C50',
          'B17',
          'C5',
          'B31',
          'C43',
          'A51',
          'C39',
          'A44',
          'C37',
          'A52',
          'D45',
          'A53',
          'C47',
          'D15',
          'A12',
          'D47',
          'B26',
          'C11',
          'A46',
          'C38',
          'B58',
          'C14',
          'B28',
          'C55',
          'D3',
          'D34',
          'B37',
          'D55',
          'B2',
          'C51',
          'B57',
          'C46',
          'B60',
          'B45',
          'C2',
          'B16',
          'D36',
          'B6',
          'D60',
          'A4',
          'A31',
          'C3',
          'B41',
          'C27',
          'B14',
          'C49',
          'B15',
          'A1',
          'D1',
          'A32',
          'D39',
          'D23',
          'D38',
          'B17',
          'C36',
          'A51',
          'D49',
          'D5',
          'D48',
          'B33',
          'C7',
          'B44',
          'C48',
          'D12',
          'B43',
          'A20',
          'C21',
          'B52',
          'A7',
          'D19',
          'A54',
          'C16',
          'D9',
          'C15',
          'A50',
          'D15',
          'D35',
          'A2',
          'A39',
          'C6',
          'A59',
          'C18',
          'A18',
          'D52',
          'B49',
          'A8',
          'B21',
          'C11',
          'B35',
          'A14',
          'C13',
          'B25',
          'D60',
          'B42',
          'D56',
          'B7',
          'C52',
          'B44',
          'D9',
          'A42',
          'A5',
          'A25',
          'D57',
          'B3',
          'C21',
          'B40',
          'D37',
          'B23',
          'C35',
          'A49',
          'C42',
          'D7',
          'C2',
          'B17',
          'D36',
          'A27',
          'C1',
          'B27',
          'D41',
          'B43',
          'C31',
          'B11',
          'C29',
          'A31',
          'D23',
          'A26',
          'C23',
          'D1',
          'A4',
          'D5',
          'D47',
          'A54',
          'C41',
          'B6',
          'C11',
          'A38',
          'C32',
          'A44',
          'C12',
          'B25',
          'C10',
          'A11',
          'B40',
          'D53',
          'B33',
          'C26',
          'B52',
          'D59',
          'B12',
          'D18',
          'D41',
          'D20',
          'D35',
          'B7',
          'C24',
          'B29',
          'C37',
          'B30',
          'D49',
          'B47',
          'C11',
          'B22',
          'C53',
          'B9',
          'C44',
          'B59',
          'C28',
          'B15',
          'C6',
          'A60',
          'D27',
          'B28',
          'B49',
          'A17',
          'B39',
          'D58',
          'B52',
          'C21',
          'D10',
          'A3',
          'A42',
          'C8',
          'A35',
          'C31',
          'B19',
          'D30',
          'B9',
          'C24',
          'B40',
          'A13',
          'C15',
          'B11',
          'C14',
          'D9',
          'B45',
          'D3',
          'D33',
          'A24',
          'B44',
          'A5',
          'B51',
          'C27',
          'B54',
          'C3',
          'B42',
          'C30',
          'B18',
          'A3',
          'D13',
          'A48',
          'C40',
          'A56',
          'C47',
          'A58',
          'C20',
          'D10',
          'A46',
          'A6',
          'D41',
          'B24',
          'C22',
          'A55',
          'D29',
          'B31',
          'C4',
          'B15',
          'D39',
          'A29',
          'C28',
          'A36',
          'A4',
          'C2',
          'B46',
          'D44',
          'A53',
          'C17',
          'B59',
          'A2',
          'D36',
          'B13',
          'C32',
          'D3',
          'D32',
          'A18',
          'B48',
          'C33',
          'A46',
          'D27',
          'A8',
          'C50',
          'B5',
          'C25',
          'B56',
          'A24',
          'D59',
          'A27',
          'D6',
          'D55',
          'B6',
          'C55',
          'B7',
          'C42',
          'A58',
          'D51',
          'B38',
          'A16',
          'D50',
          'B46',
          'D27',
          'A19',
          'B43',
          'D58',
          'A3',
          'A43',
          'D26',
          'B17',
          'C34',
          'B21',
          'D42',
          'D16',
          'D31',
          'B8',
          'C13',
          'A51',
          'D46',
          'A11',
          'B45',
          'A9',
          'D4',
          'A37',
          'C35',
          'B19',
          'C9',
          'B23',
          'D24',
          'A34',
          'A6',
          'A48',
          'C16',
          'B13',
          'C59',
          'D14',
          'B42',
          'D40',
          'B43',
          'D11',
          'C26',
          'B41',
          'C5',
          'B32',
          'C57',
          'D8',
          'C8',
          'A9',
          'B26',
          'D60',
          'B11',
          'C46',
          'B58',
          'C58',
          'B13',
          'C31',
          'B53',
          'C1',
          'D7',
          'C50',
          'D13',
          'C52',
          'D5',
          'C36',
          'B18',
          'C6',
          'B21',
          'D35',
          'A25',
          'A41',
          'C10',
          'C25',
          'A27',
          'D56',
          'B36',
          'C39',
          'B48',
          'C37',
          'A46',
          'A3',
          'B44',
          'C5',
          'A58',
          'D24',
          'B24',
          'C22',
          'A60',
          'C45',
          'A53',
          'D43',
          'D14',
          'A17',
          'B50',
          'A8',
          'C49',
          'A57',
          'C21',
          'B4',
          'C10',
          'B35',
          'D47',
          'D5',
          'C59',
          'D32',
          'B46',
          'D56',
          'D40',
          'D20',
          'C55',
          'B3',
          'C51',
          'B7',
          'D34',
          'A3',
          'A24',
          'A42',
          'C38',
          'B22',
          'C14',
          'B24',
          'C9',
          'B56',
          'C24',
          'A23',
          'A43',
          'A2',
          'B16',
          'C28',
          'B37',
          'C39',
          'A54',
          'C15',
          'A49',
          'A6',
          'A50',
          'C35',
          'D5',
          'D46',
          'D15',
          'C47',
          'B12',
          'C3',
          'B29',
          'C2',
          'B41',
          'A14',
          'D21',
          'A56',
          'D52',
          'B37',
          'C14',
          'A12',
          'D49',
          'A52',
          'A6',
          'D4',
          'C60',
          'B28',
          'C13',
          'A43',
          'A21',
          'A45',
          'A20',
          'C18',
          'B17',
          'A2',
          'B44',
          'D60',
          'D7',
          'B45',
          'D2',
          'A34',
          'A5',
          'D39',
          'D24',
          'B46',
          'D55',
          'A28',
          'D37',
          'A30',
          'C4',
          'A4',
          'A37',
          'D8',
          'A42',
          'D26',
          'A44',
          'C10',
          'A14',
          'B54',
          'C26',
          'B8',
          'C12',
          'B36',
          'C58',
          'B26',
          'C55',
          'B49',
          'D51',
          'D19',
          'D46',
          'B31',
          'C42',
          'B47',
          'D48',
          'B25',
          'C22',
          'D1',
          'D38',
          'B15',
          'C30',
          'B43',
          'C40',
          'D2',
          'C26',
          'B11',
          'D30',
          'B20',
          'C52',
          'B9',
          'D57',
          'B35',
          'C27',
          'B6',
          'C57',
          'B8',
          'C1',
          'B41',
          'D16',
          'D42',
          'A48',
          'C37',
          'B30',
          'C18',
          'B31',
          'A4',
          'A38',
          'C8',
          'B2',
          'C22',
          'D10',
          'C19',
          'A52',
          'C41',
          'B7',
          'D33',
          'D11',
          'C25',
          'B15',
          'D20',
          'D35',
          'B38',
          'D60',
          'B5',
          'C39',
          'A55',
          'D45',
          'B33',
          'D30',
          'B43',
          'D57',
          'A31',
          'D41',
          'D21',
          'A13',
          'C7',
          'B16',
          'C6',
          'A7',
          'B24',
          'D59',
          'B42',
          'A8',
          'B22',
          'D42',
          'B29',
          'C24',
          'A29',
          'D10',
          'C54',
          'B4',
          'C49',
          'B15',
          'C3',
          'B18',
          'C34',
          'A37',
          'C5',
          'B14',
          'C32',
          'B10',
          'D17',
          'D55',
          'D6',
          'D54',
          'B32',
          'D50',
          'A51',
          'C45',
          'D4',
          'D40',
          'A33',
          'C6',
          'B33',
          'C20',
          'B11',
          'C60',
          'D53',
          'B46',
          'D2',
          'A4',
          'A41',
          'C31',
          'C15',
          'B27',
          'D47',
          'D12',
          'C33',
          'A42',
          'D20',
          'A55',
          'B59',
          'C55',
          'D27',
          'B43',
          'D10',
          'C50',
          'D14',
          'A21',
          'D34',
          'B38',
          'C46',
          'A8',
          'C9',
          'B34',
          'D53',
          'A19',
          'A45',
          'A18',
          'C17',
          'B10',
          'C52',
          'B1',
          'D52',
          'D17',
          'A52',
          'D46',
          'D20',
          'D35',
          'B5',
          'C10',
          'B22',
          'B48',
          'C36',
          'B20',
          'D43',
          'A54',
          'A6',
          'A26',
          'B45',
          'C7',
          'C27',
          'B10',
          'D31',
          'A25',
          'D56',
          'B51',
          'C29',
          'B8',
          'C24',
          'B15',
          'A4',
          'D25',
          'B43',
          'C37',
          'B3',
          'C48',
          'B13',
          'D18',
          'D55',
          'A22',
          'A37',
          'C21',
          'B23',
          'A8',
          'B52',
          'C20',
          'B39',
          'A16',
          'B42',
          'D13',
          'D47',
          'B47',
          'C5',
          'D49',
          'B37',
          'B22',
          'C36',
          'B25',
          'D59',
          'A3',
          'A47',
          'C11',
          'B57',
          'A22',
          'D5',
          'A38',
          'C4',
          'B17',
          'C50',
          'A59',
          'C43',
          'A53',
          'C15',
          'B12',
          'D29',
          'A54',
          'D53',
          'D6',
          'C55',
          'D8',
          'C9',
          'A44',
          'A2',
          'D3',
          'C31',
          'D12',
          'D44',
          'D19',
          'A27',
          'C23',
          'B27',
          'C12',
          'A14',
          'B36',
          'C45',
          'B43',
          'D5',
          'C58',
          'B44',
          'D59',
          'B8',
          'C43',
          'B33',
          'A3',
          'A48',
          'A18',
          'A50',
          'C44',
          'D8',
          'C7',
          'B22',
          'D35',
          'D51',
          'A17',
          'C19',
          'B19',
          'C32',
          'B36',
          'D54',
          'B50',
          'C56',
          'B11',
          'A56',
          'D48',
          'A11',
          'B53',
          'C29',
          'A39',
          'A1',
          'D3',
          'A60',
          'C53',
          'B60',
          'B45',
          'C14',
          'A50',
          'A3',
          'D51',
          'A59',
          'C5',
          'B55',
          'C33',
          'A34',
          'A5',
          'A26',
          'D53',
          'D14',
          'D47',
          'D17',
          'B40',
          'C4',
          'B30',
          'C3',
          'B54',
          'C23',
          'A56',
          'B16',
          'A4',
          'D57',
          'D16',
          'D31',
          'A15',
          'B49',
          'B20',
          'C13',
          'D9',
          'A14',
          'B42',
          'D43',
          'D19',
          'A43',
          'C38',
          'B33',
          'C57',
          'B35',
          'D50',
          'A60',
          'C21',
          'B24',
          'C54',
          'B6',
          'D35',
          'D20',
          'B16',
          'C35',
          'B52',
          'C47',
          'B13',
          'C23',
          'A59',
          'D56',
          'D16',
          'B7',
          'D32',
          'A2',
          'A50',
          'D48',
          'D11',
          'C50',
          'B2',
          'C20',
          'B60',
          'A29',
          'A44',
          'A23',
          'D58',
          'A30',
          'D24',
          'D39',
          'A48',
          'C34',
          'A40',
          'C12',
          'C32',
          'B11',
          'B28',
          'D26',
          'A60',
          'D48',
          'B26',
          'C16',
          'B38',
          'D50',
          'C58',
          'B5',
          'C28',
          'C13',
          'B21',
          'D57',
          'B44',
          'C31',
          'B19',
          'B50',
          'C15',
          'B29',
          'C60',
          'B12',
          'C26',
          'B14',
          'C48',
          'D14',
          'D58',
          'B11',
          'C4',
          'B43',
          'C8',
          'B34',
          'A3',
          'A36',
          'D8',
          'C51',
          'A58',
          'A6',
          'A55',
          'C44',
          'B31',
          'C17',
          'A47',
          'A20',
          'A49',
          'C18',
          'B39',
          'C48',
          'D16',
          'B44',
          'D25',
          'D40',
          'B24',
          'C13',
          'B3',
          'C11',
          'A12',
          'C16',
          'B59',
          'D45',
          'D17',
          'D42',
          'D27',
          'A48',
          'A17',
          'A50',
          'B56',
          'C8',
          'B19',
          'D28',
          'B30',
          'D52',
          'B46',
          'D23',
          'C60',
          'D54',
          'A31',
          'D1',
          'A58',
          'C46',
          'A52',
          'C36',
          'B3',
          'C9',
          'C33',
          'B48',
          'A34',
          'A5',
          'A45',
          'A4',
          'D38',
          'B12',
          'D49',
          'D10',
          'C18',
          'B9',
          'C28',
          'B53',
          'C27',
          'B58',
          'D48',
          'A14',
          'B50',
          'A13',
          'D3',
          'C53',
          'B6',
          'C40',
          'B23',
          'B46',
          'A18',
          'A33',
          'C26',
          'B55',
          'B19',
          'D36',
          'A29',
          'A6',
          'A56',
          'C22',
          'B13',
          'D60',
          'D7',
          'C4',
          'B44',
          'C47',
          'B39',
          'B24',
          'C8',
          'B3',
          'D15',
          'D58',
          'B41',
          'D37',
          'B46',
          'D49',
          'A3',
          'D40',
          'A47',
          'C42',
          'A58',
          'D53',
          'D6',
          'A26',
          'C27',
          'A42',
          'C25',
          'D2',
          'D45',
          'A8',
          'B29',
          'C58',
          'B30',
          'C41',
          'B44',
          'A23',
          'C23',
          'B40',
          'C3',
          'A34',
          'D25',
          'B25',
          'A2',
          'A51',
          'A17',
          'A52',
          'C17',
          'B15',
          'D35',
          'B46',
          'D22',
          'D46',
          'B47',
          'C21',
          'B33',
          'C19',
          'B60',
          'A56',
          'C16',
          'B10',
          'C1',
          'A3',
          'D2',
          'A10',
          'D29',
          'A21',
          'A37',
          'C20',
          'B20',
          'B40',
          'A9',
          'C44',
          'A60',
          'C32',
          'B42',
          'D28',
          'A52',
          'A16',
          'A53',
          'C38',
          'A54',
          'B18',
          'D42',
          'C55',
          'B3',
          'C27',
          'B7',
          'B29',
          'C39',
          'B23',
          'D58',
          'B52',
          'C30',
          'A35',
          'A5',
          'A40',
          'C5',
          'B20',
          'D22',
          'D44',
          'A52',
          'A2',
          'B18',
          'D29',
          'C57',
          'B36',
          'D59',
          'D5',
          'C45',
          'D6',
          'A8',
          'D11',
          'D48',
          'B12',
          'C33',
          'C6',
          'A13',
          'B32',
          'A56',
          'D13',
          'D60',
          'B7',
          'C23',
          'B14',
          'D53',
          'D16',
          'B46',
          'C16',
          'A49',
          'A3',
          'B15',
          'C8',
          'A12',
          'A33',
          'D55',
          'B33',
          'D54',
          'B44',
          'D24',
          'A27',
          'D52',
          'D18',
          'D47',
          'A51',
          'C20',
          'B40',
          'C2',
          'B54',
          'B20',
          'C10',
          'B26',
          'A2',
          'B51',
          'A41',
          'A4',
          'A42',
          'C24',
          'B30',
          'A60',
          'B15',
          'D33',
          'A19',
          'A37',
          'C30',
          'D12',
          'A53',
          'D18',
          'A3',
          'D53',
          'B36',
          'D48',
          'B60',
          'B20',
          'C32',
          'C10',
          'B35',
          'A54',
          'D52',
          'D6',
          'C52',
          'B11',
          'C14',
          'B58',
          'C56',
          'B2',
          'C35',
          'B23',
          'C16',
          'B31',
          'C4',
          'A13',
          'B47',
          'C2',
          'D12',
          'A2',
          'B28',
          'C59',
          'B9',
          'C44',
          'A52',
          'B57',
          'D49',
          'D16',
          'D48',
          'D32',
          'B36',
          'C9',
          'A49',
          'C40',
          'A50',
          'A3',
          'D60',
          'B14',
          'C22',
          'B32',
          'C38',
          'B4',
          'D7',
          'C3',
          'B43',
          'C1',
          'B27',
          'C31',
          'B9',
          'C13',
          'D9',
          'C12',
          'A10',
          'A33',
          'D24',
          'D39',
          'B39',
          'D18',
          'D47',
          'C57',
          'A45',
          'A4',
          'A46',
          'A19',
          'A47',
          'C41',
          'B45',
          'D57',
          'B52',
          'D14',
          'D60',
          'A25',
          'C28',
          'B32',
          'C26',
          'B16',
          'A1',
          'A52',
          'C40',
          'B30',
          'C56',
          'D5',
          'C53',
          'B8',
          'C23',
          'A55',
          'B33',
          'C18',
          'A51',
          'D16',
          'D56',
          'C60',
          'D34',
          'A27',
          'A44',
          'A23',
          'C21',
          'B34',
          'D51',
          'B44',
          'D11',
          'C24',
          'A43',
          'D23',
          'D44',
          'A56',
          'D29',
          'B41',
          'C22',
          'B54',
          'D13',
          'D59',
          'A28',
          'B59',
          'C27',
          'B11',
          'C1',
          'D12',
          'A30',
          'A6',
          'A59',
          'B31',
          'C56',
          'B5',
          'C15',
          'B37',
          'C9',
          'B46',
          'D9',
          'C51',
          'B19',
          'C38',
          'B33',
          'C26',
          'A41',
          'D21',
          'D41',
          'D22',
          'A56',
          'C50',
          'B6',
          'C25',
          'D53',
          'A34',
          'D3',
          'C3',
          'D11',
          'A47',
          'B54',
          'C1',
          'B12',
          'D37',
          'A46',
          'A2',
          'B10',
          'C13',
          'B46',
          'D5',
          'C34',
          'C4',
          'A57',
          'B31',
          'C41',
          'A6',
          'A21',
          'D17',
          'A15',
          'A53',
          'B38',
          'D59',
          'D14',
          'D57',
          'D15',
          'B41',
          'C6',
          'B22',
          'D34',
          'B25',
          'A7',
          'A44',
          'D55',
          'D21',
          'A2',
          'B29',
          'B3',
          'D53',
          'D16',
          'A22',
          'C25',
          'C8',
          'B21',
          'B53',
          'C35',
          'C1',
          'A40',
          'C28',
          'A31',
          'D56',
          'A20',
          'B45',
          'D51',
          'B13',
          'C31',
          'B36',
          'C12',
          'A43',
          'C23',
          'B1',
          'A6',
          'C39',
          'A52',
          'B39',
          'D52',
          'D21',
          'A44',
          'A22',
          'D28',
          'A2',
          'B38',
          'D19',
          'D43',
          'C55',
          'B50',
          'C14',
          'A42',
          'D27',
          'D42',
          'A53',
          'B36',
          'C27',
          'A36',
          'D4',
          'A18',
          'C44',
          'B10',
          'A1',
          'B44',
          'C51',
          'D12',
          'C32',
          'A34',
          'D41',
          'A11',
          'B28',
          'B9',
          'C17',
          'A56',
          'D54',
          'A25',
          'A41',
          'D14',
          'A60',
          'C42',
          'D6',
          'C6',
          'B42',
          'D58',
          'C36',
          'B48',
          'D49',
          'B54',
          'D46',
          'A12',
          'B36',
          'C8',
          'B17',
          'D35',
          'A23',
          'B55',
          'C32',
          'B12',
          'C53',
          'B58',
          'C13',
          'B29',
          'B1',
          'C11',
          'A50',
          'C59',
          'D54',
          'A60',
          'C40',
          'D6',
          'C39',
          'B37',
          'A17',
          'C46',
          'B22',
          'D57',
          'C37',
          'B18',
          'C19',
          'A55',
          'B34',
          'D33',
          'B3',
          'D52',
          'D25',
          'B17',
          'B58',
          'D46',
          'D16',
          'A40',
          'A5',
          'B30',
          'C20',
          'A38',
          'C18',
          'A24',
          'C21',
          'B14',
          'D40',
          'A30',
          'C30',
          'C5',
          'B32',
          'C44',
          'B60',
          'D44',
          'D2',
          'A9',
          'D5',
          'A27',
          'D49',
          'B38',
          'C58',
          'B7',
          'D10',
          'A47',
          'A1',
          'A53',
          'B58',
          'C49',
          'D13',
          'C38',
          'D56',
          'B43',
          'C6',
          'D8',
          'A35',
          'A5',
          'C2',
          'A39',
          'B50',
          'D53',
          'A43',
          'B52',
          'B22',
          'C15',
          'B23',
          'D42',
          'D27',
          'B8',
          'C42',
          'B2',
          'A6',
          'A32',
          'C7',
          'B14',
          'C58',
          'D2',
          'B46',
          'D51',
          'D21',
          'C57',
          'D30',
          'A54',
          'A15',
          'A55',
          'C48',
          'A57',
          'C19',
          'B40',
          'A51',
          'D45',
          'D17',
          'B12',
          'C25',
          'A36',
          'C22',
          'C6',
          'C29',
          'B13',
          'C16',
          'B32',
          'C42',
          'A18',
          'D54',
          'B45',
          'D48',
          'D1',
          'B16',
          'D39',
          'D24',
          'B25',
          'C11',
          'A43',
          'C8',
          'B56',
          'B18',
          'C18',
          'B40',
          'D60',
          'C36',
          'D13',
          'C2',
          'B17',
          'C50',
          'B49',
          'C53',
          'B10',
          'D45',
          'D18',
          'D42',
          'D19',
          'A6',
          'B3',
          'C26',
          'B34',
          'C38',
          'D6',
          'C5',
          'A4',
          'B37',
          'C1',
          'A53',
          'C37',
          'B4',
          'C9',
          'A37',
          'A10',
          'A25',
          'C16',
          'B14',
          'C4',
          'A60',
          'D47',
          'D14',
          'B46',
          'C41',
          'D4',
          'C57',
          'A47',
          'A19',
          'A49',
          'C12',
          'D9',
          'A6',
          'B4',
          'C54',
          'B7',
          'C50',
          'D5',
          'B2',
          'A15',
          'B35',
          'D55',
          'A1',
          'B37',
          'D53',
          'D16',
          'D46',
          'A3',
          'A51',
          'C57',
          'B29',
          'C2',
          'C34',
          'B1',
          'C46',
          'D14',
          'A50',
          'D58',
          'B44',
          'A12',
          'D3',
          'B15',
          'C1',
          'D13',
          'D54',
          'C39',
          'B56',
          'D50',
          'B46',
          'D48',
          'C57',
          'A48',
          'C6',
          'B33',
          'A56',
          'A15',
          'A59',
          'C30',
          'B9',
          'D26',
          'D41',
          'D22',
          'A25',
          'A42',
          'A3',
          'B13',
          'C53',
          'D24',
          'A46',
          'A23',
          'D5',
          'C7',
          'B23',
          'C38',
          'A40',
          'D43',
          'D2',
          'B18',
          'C33',
          'A48',
          'D38',
          'D23',
          'B15',
          'C27',
          'B31',
          'A2',
          'D30',
          'A14',
          'B52',
          'C29',
          'D12',
          'A46',
          'A22',
          'D53',
          'B44',
          'C46',
          'B51',
          'B25',
          'B4',
          'C21',
          'A53',
          'B45',
          'C11',
          'B19',
          'D1',
          'C30',
          'C12',
          'B2',
          'D58',
          'B8',
          'C2',
          'D7',
          'A7',
          'D43',
          'B9',
          'C54',
          'D39',
          'B24',
          'A8',
          'C38',
          'D48',
          'B48',
          'C35',
          'C3',
          'C31',
          'B51',
          'A9',
          'D44',
          'B19',
          'D55',
          'B41',
          'A50',
          'A18',
          'B1',
          'C19',
          'B11',
          'C17',
          'D8',
          'A1',
          'C42',
          'B3',
          'A15',
          'B5',
          'D36',
          'B14',
          'C48',
          'B37',
          'A54',
          'C43',
          'B43',
          'C37',
          'A47',
          'C13',
          'B47',
          'D22',
          'B21',
          'B58',
          'A31',
          'D38',
          'D23',
          'C26',
          'B57',
          'C60',
          'A46',
          'C58',
          'B39',
          'C4',
          'A6',
          'A37',
          'B49',
          'D5',
          'A3',
          'B25',
          'B45',
          'A25',
          'A42',
          'D60',
          'D15',
          'D57',
          'B34',
          'A2',
          'D19',
          'D42',
          'D2',
          'A59',
          'C47',
          'B59',
          'D49',
          'C36',
          'B43',
          'D55',
          'A20',
          'D29',
          'B20',
          'C12',
          'B28',
          'A10',
          'C14',
          'B37',
          'C60',
          'C44',
          'B38',
          'C34',
          'C5',
          'A44',
          'A21',
          'A46',
          'A4',
          'B19',
          'C37',
          'B30',
          'A58',
          'D49',
          'D10',
          'C17',
          'B16',
          'C25',
          'A40',
          'A5',
          'B14',
          'D52',
          'B43',
          'D26',
          'A33',
          'C34',
          'B13',
          'D31',
          'B15',
          'D27',
          'C26',
          'A34',
          'D9',
          'C11',
          'B57',
          'B22',
          'C45',
          'A51',
          'A17',
          'B1',
          'C33',
          'D50',
          'A53',
          'C20',
          'B52',
          'D47',
          'D1',
          'D46',
          'D16',
          'D44',
          'D17',
          'D53',
          'A32',
          'C28',
          'B10',
          'C3',
          'D11',
          'A50',
          'C58',
          'B40',
          'D59',
          'B5',
          'C55',
          'C40',
          'B6',
          'D37',
          'A2',
          'B35',
          'C11',
          'C26',
          'B43',
          'C24',
          'B53',
          'A45',
          'C41',
          'A19',
          'A49',
          'D46',
          'A54',
          'B34',
          'C23',
          'A44',
          'A5',
          'D13',
          'A30',
          'D60',
          'B37',
          'C59',
          'D22',
          'D41',
          'B44',
          'C54',
          'B5',
          'D54',
          'D15',
          'A41',
          'C1',
          'A12',
          'C13',
          'A26',
          'C2',
          'B51',
          'D55',
          'B21',
          'D43',
          'A56',
          'A3',
          'B27',
          'C30',
          'D3',
          'B17',
          'A7',
          'B18',
          'D56',
          'B40',
          'D30',
          'B46',
          'A16',
          'D4',
          'C53',
          'D35',
          'B24',
          'C35',
          'A51',
          'D59',
          'D7',
          'B5',
          'C42',
          'D55',
          'A3',
          'D14',
          'A1',
          'B27',
          'C14',
          'A46',
          'D60',
          'B26',
          'C22',
          'B6',
          'A15',
          'B8',
          'C18',
          'B54',
          'D51',
          'A52',
          'C57',
          'D46',
          'B11',
          'D39',
          'D24',
          'B52',
          'B24',
          'C40',
          'D47',
          'B57',
          'A32',
          'A6',
          'B51',
          'D54',
          'A57',
          'B32',
          'C15',
          'C30',
          'A28',
          'C1',
          'B39',
          'C34',
          'B48',
          'A52',
          'D12',
          'B54',
          'D45',
          'D20',
          'D35',
          'C26',
          'B4',
          'B28',
          'C11',
          'B49',
          'C24',
          'A36',
          'C10',
          'B2',
          'C56',
          'B3',
          'C40',
          'A1',
          'A44',
          'B35',
          'A13',
          'C3',
          'A38',
          'A6',
          'C36',
          'B26',
          'D33',
          'B9',
          'C29',
          'B55',
          'D51',
          'C26',
          'A35',
          'D52',
          'B44',
          'C31',
          'C4',
          'B1',
          'C45',
          'A17',
          'B2',
          'D56',
          'B8',
          'D34',
          'B14',
          'D4',
          'C51',
          'B45',
          'D25',
          'A30',
          'A14',
          'D10',
          'C48',
          'B53',
          'C6',
          'D12',
          'D48',
          'A28',
          'A10',
          'B32',
          'A4',
          'A49',
          'D60',
          'B41',
          'D54',
          'D15',
          'C56',
          'B35',
          'C10',
          'B38',
          'C43',
          'D8',
          'A41',
          'C20',
          'A56',
          'C44',
          'B22',
          'C17',
          'A57',
          'C41',
          'D46',
          'D16',
          'D31',
          'B34',
          'C7',
          'A47',
          'C60',
          'A49',
          'B55',
          'D11',
          'A45',
          'C23',
          'D55',
          'C39',
          'B5',
          'B20',
          'C52',
          'B21',
          'C20',
          'D57',
          'B37',
          'C47',
          'A15',
          'C9',
          'A59',
          'A19',
          'A60',
          'B29',
          'C59',
          'D33',
          'B44',
          'D50',
          'B56',
          'D49',
          'B2',
          'C7',
          'B24',
          'C16',
          'A45',
          'C8',
          'B46',
          'D20',
          'B52',
          'C19',
          'B60',
          'D57',
          'B39',
          'C40',
          'B22',
          'D27',
          'D42',
          'D19',
          'A15',
          'B9',
          'D59',
          'C37',
          'B31',
          'D52',
          'B53',
          'D60',
          'A57',
          'C16',
          'B6',
          'D34',
          'A18',
          'C22',
          'B43',
          'A54',
          'C49',
          'A55',
          'C21',
          'A36',
          'B49',
          'C52',
          'B12',
          'D32',
          'A25',
          'A42',
          'A24',
          'C17',
          'B30',
          'C3',
          'B39',
          'A2',
          'B19',
          'D31',
          'B27',
          'C10',
          'C47',
          'B21',
          'C39',
          'A55',
          'C18',
          'A39',
          'A6',
          'B5',
          'D58',
          'D13',
          'D48',
          'B56',
          'B23',
          'C14',
          'B44',
          'D49',
          'A15',
          'C19',
          'A37',
          'B35',
          'C30',
          'B47',
          'D14',
          'D47',
          'C39',
          'B59',
          'C58',
          'B41',
          'C25',
          'B10',
          'D44',
          'D17',
          'A39',
          'C53',
          'B14',
          'C20',
          'A23',
          'A58',
          'A20',
          'D13',
          'C35',
          'A8',
          'B47',
          'C4',
          'A47',
          'B34',
          'C54',
          'B11',
          'C59',
          'B50',
          'C22',
          'A19',
          'B42',
          'C34',
          'B28',
          'C10',
          'B45',
          'D24',
          'D44',
          'C56',
          'A52',
          'D43',
          'B39',
          'C46',
          'D12',
          'C3',
          'D4',
          'C37',
          'B22',
          'D55',
          'A30',
          'B58',
          'A24',
          'C14',
          'D8',
          'D50',
          'D9',
          'A29',
          'A9',
          'A33',
          'C28',
          'B38',
          'C15',
          'B6',
          'D57',
          'B42',
          'C32',
          'A40',
          'D22',
          'C52',
          'C37',
          'D6',
          'B13',
          'D37',
          'A48',
          'B33',
          'D52',
          'B50',
          'A16',
          'B1',
          'C39',
          'B60',
          'D54',
          'B43',
          'A18',
          'D43',
          'A33',
          'C33',
          'C7',
          'A37',
          'B37',
          'C45',
          'A54',
          'C60',
          'D57',
          'C14',
          'B6',
          'B31',
          'A58',
          'C17',
          'B54',
          'C43',
          'A59',
          'D49',
          'D10',
          'C4',
          'B19',
          'A7',
          'B26',
          'B11',
          'C46',
          'B12',
          'C24',
          'A22',
          'A48',
          'C55',
          'B3',
          'D59',
          'B41',
          'A47',
          'A4',
          'D26',
          'B38',
          'C9',
          'B16',
          'C5',
          'B53',
          'C4',
          'D2',
          'D38',
          'A45',
          'B34',
          'A38',
          'D55',
          'B42',
          'C25',
          'A41',
          'A5',
          'A44',
          'C1',
          'D12',
          'A7',
          'B28',
          'A1',
          'B32',
          'C27',
          'B2',
          'B27',
          'D29',
          'D2',
          'C57',
          'D14',
          'D47',
          'D15',
          'A49',
          'C41',
          'A20',
          'D33',
          'A2',
          'B40',
          'C40',
          'D53',
          'B22',
          'C36',
          'C2',
          'A27',
          'C12',
          'B24',
          'D58',
          'B59',
          'D45',
          'D1',
          'C24',
          'B5',
          'C51',
          'B58',
          'C26',
          'D36',
          'C53',
          'B34',
          'C43',
          'D44',
          'D17',
          'C54',
          'B47',
          'D57',
          'C38',
          'B1',
          'C3',
          'A47',
          'C56',
          'A53',
          'D31',
          'D16',
          'D46',
          'A50',
          'B32',
          'C25',
          'B25',
          'D10',
          'C23',
          'B15',
          'C42',
          'D45',
          'B45',
          'C32',
          'B52',
          'A6',
          'B6',
          'D9',
          'B47',
          'C45',
          'B31',
          'D28',
          'B7',
          'C11',
          'A14',
          'B9',
          'A3',
          'A51',
          'D44',
          'D20',
          'C27',
          'A40',
          'C2',
          'B38',
          'A36',
          'C58',
          'B44',
          'C40',
          'B55',
          'D51',
          'B60',
          'D49',
          'C34',
          'B2',
          'A16',
          'B4',
          'D35',
          'C60',
          'B56',
          'A33',
          'A8',
          'D8',
          'B52',
          'C15',
          'B13',
          'C6',
          'B45',
          'A47',
          'C23',
          'D11',
          'A28',
          'C29',
          'B47',
          'D55',
          'D12',
          'A54',
          'C41',
          'A59',
          'B29',
          'C55',
          'B10',
          'A14',
          'C49',
          'B49',
          'C21',
          'B12',
          'C18',
          'D7',
          'A32',
          'B35',
          'A42',
          'A24',
          'D23',
          'D38',
          'B11',
          'D19',
          'D42',
          'D27',
          'B16',
          'C1',
          'D13',
          'D48',
          'C37',
          'D35',
          'A45',
          'A1',
          'B40',
          'C17',
          'B33',
          'C16',
          'B59',
          'C26',
          'B9',
          'B24',
          'C49',
          'A56',
          'B32',
          'A49',
          'A19',
          'A60',
          'C53',
          'C28',
          'B4',
          'C44',
          'A21',
          'A46',
          'C10',
          'B57',
          'C29',
          'A34',
          'D40',
          'D19',
          'C54',
          'D25',
          'B18',
          'C5',
          'D8',
          'B33',
          'C42',
          'B43',
          'A14',
          'B37',
          'C12',
          'D14',
          'D58',
          'B28',
          'B8',
          'C31',
          'B45',
          'D33',
          'B11',
          'A2',
          'B13',
          'C28',
          'B47',
          'D54',
          'A44',
          'C24',
          'C9',
          'B42',
          'C5',
          'C49',
          'B16',
          'B59',
          'A54',
          'D42',
          'C44',
          'A48',
          'D29',
          'B14',
          'D51',
          'B50',
          'D22',
          'C27',
          'A5',
          'B20',
          'D56',
          'A1',
          'B19',
          'C19',
          'B29',
          'C52',
          'B44',
          'C36',
          'D6',
          'C15',
          'A45',
          'A7',
          'A60',
          'A18',
          'D11',
          'C2',
          'B10',
          'A13',
          'B52',
          'C14',
          'A25',
          'A44',
          'A10',
          'B39',
          'C21',
          'B50',
          'C58',
          'B60',
          'C5',
          'C26',
          'B5',
          'C38',
          'B34',
          'D56',
          'A29',
          'B46',
          'D60',
          'A50',
          'C46',
          'B21',
          'D40',
          'C54',
          'A48',
          'C13',
          'B22',
          'D12',
          'C59',
          'A47',
          'D39',
          'D24',
          'C19',
          'A23',
          'A59',
          'C8',
          'A49',
          'D47',
          'D15',
          'D57',
          'B10',
          'C20',
          'D6',
          'D51',
          'B37',
          'A56',
          'D31',
          'A14',
          'A31',
          'B36',
          'A15',
          'B51',
          'D48',
          'A3',
          'A42',
          'C13',
          'B44',
          'A10',
          'D3',
          'C8',
          'C48',
          'C32',
          'B1',
          'B28',
          'C23',
          'B9',
          'C43',
          'B58',
          'D46',
          'D16',
          'B50',
          'C28',
          'A37',
          'A9',
          'C34',
          'B22',
          'D25',
          'A45',
          'C22',
          'D1',
          'D44',
          'D17',
          'B33',
          'A39',
          'C51',
          'C29',
          'B26',
          'A4',
          'B38',
          'C49',
          'D5',
          'C3',
          'B13',
          'D30',
          'A21',
          'C26',
          'A43',
          'C6',
          'B55',
          'C4',
          'C50',
          'A40',
          'B32',
          'C20',
          'B34',
          'A33',
          'D52',
          'B54',
          'A31',
          'A11',
          'D43',
          'B10',
          'C30',
          'B11',
          'C54',
          'A37',
          'D44',
          'A7',
          'B1',
          'D47',
          'B28',
          'C33',
          'B38',
          'C59',
          'D51',
          'C32',
          'D4',
          'C8',
          'B35',
          'C7',
          'B6',
          'C56',
          'D7',
          'C45',
          'B55',
          'C28',
          'D11',
          'D49',
          'D12',
          'D60',
          'A52',
          'B31',
          'C44',
          'A51',
          'B45',
          'C5',
          'A46',
          'A2',
          'D31',
          'C52',
          'B2',
          'C47',
          'B49',
          'A28',
          'D57',
          'D5',
          'B15',
          'C41',
          'B48',
          'C59',
          'A35',
          'B60',
          'A52',
          'C35',
          'A60',
          'D28',
          'B14',
          'D48',
          'D13',
          'A49',
          'C54',
          'A59',
          'C5',
          'C21',
          'A21',
          'D49',
          'B39',
          'D36',
          'C23',
          'B5',
          'D56',
          'C22',
          'D23',
          'B45',
          'B29',
          'C51',
          'B46',
          'D19',
          'D40',
          'C45',
          'B21',
          'D28',
          'B44',
          'C34',
          'B54',
          'A13',
          'B37',
          'A12',
          'C7',
          'C49',
          'B7',
          'A19',
          'B8',
          'D11',
          'A11',
          'D38',
          'D23',
          'B16',
          'D4',
          'A4',
          'D12',
          'A40',
          'C18',
          'B23',
          'C13',
          'B52',
          'D56',
          'A32',
          'D42',
          'D27',
          'A7',
          'A33',
          'B48',
          'C9',
          'B34',
          'D50',
          'D9',
          'C39',
          'A60',
          'A3',
          'B1',
          'D4',
          'C1',
          'A24',
          'A57',
          'D52',
          'C41',
          'D5',
          'A21',
          'A59',
          'C36',
          'A39',
          'A6',
          'C47',
          'D11',
          'B23',
          'D55',
          'C32',
          'C2',
          'C51',
          'B19',
          'A53',
          'D53',
          'B13',
          'D59',
          'B46',
          'A9',
          'B36',
          'A38',
          'D38',
          'B7',
          'C7',
          'B41',
          'A1',
          'D34',
          'B43',
          'C21',
          'B5',
          'C12',
          'B40',
          'C48',
          'A57',
          'C46',
          'C11',
          'B24',
          'C35',
          'B3',
          'C25',
          'A35',
          'B39',
        ],
      },
    ],
  },
  {
    title: 'Princess Diana',
    id: 'princess-diana',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Princess%20Diana.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'B10',
          'B26',
          'B11',
          'B33',
          'B49',
          'D5',
          'B37',
          'D3',
          'B39',
          'D7',
          'B36',
          'D6',
          'B50',
          'B31',
          'B12',
          'B34',
          'D8',
          'B15',
          'D4',
          'B42',
          'D2',
          'B43',
          'B60',
          'B45',
          'B30',
          'D13',
          'B38',
          'D5',
          'B14',
          'B33',
          'B10',
          'B25',
          'B6',
          'B22',
          'D12',
          'B17',
          'D11',
          'B35',
          'D8',
          'B41',
          'D6',
          'B36',
          'D14',
          'B40',
          'D3',
          'B16',
          'D10',
          'B19',
          'D9',
          'B32',
          'D27',
          'B30',
          'D19',
          'B33',
          'D15',
          'B34',
          'D12',
          'B38',
          'D4',
          'B14',
          'B53',
          'B13',
          'B51',
          'D7',
          'B15',
          'B59',
          'B44',
          'D44',
          'B41',
          'D2',
          'B16',
          'D9',
          'B39',
          'D43',
          'B40',
          'D26',
          'B30',
          'D25',
          'B32',
          'D16',
          'B37',
          'D14',
          'B18',
          'D13',
          'B33',
          'D18',
          'B31',
          'D23',
          'B42',
          'B57',
          'B36',
          'D5',
          'B41',
          'D24',
          'B29',
          'B10',
          'B34',
          'D7',
          'B35',
          'D17',
          'B30',
          'D20',
          'B32',
          'B11',
          'B26',
          'D26',
          'B28',
          'D22',
          'B43',
          'D2',
          'B46',
          'D45',
          'B47',
          'D8',
          'B17',
          'D10',
          'B38',
          'D42',
          'B39',
          'D5',
          'B18',
          'D17',
          'B36',
          'D25',
          'B37',
          'D13',
          'B14',
          'D6',
          'B15',
          'D16',
          'B19',
          'A56',
          'A13',
          'B1',
          'A16',
          'B2',
          'A15',
          'A57',
          'A14',
          'A58',
          'A17',
          'A59',
          'B21',
          'D19',
          'B41',
          'D3',
          'B47',
          'D45',
          'B48',
          'B12',
          'B27',
          'D27',
          'B31',
          'D26',
          'B35',
          'B11',
          'D12',
          'B40',
          'D10',
          'B54',
          'D59',
          'B55',
          'D47',
          'B51',
          'B9',
          'B52',
          'D58',
          'B53',
          'B10',
          'B41',
          'D43',
          'B37',
          'D24',
          'A42',
          'A8',
          'A43',
          'A7',
          'A44',
          'A6',
          'A48',
          'A5',
          'A41',
          'C34',
          'A39',
          'C33',
          'A40',
          'C32',
          'A36',
          'C29',
          'A37',
          'A1',
          'A29',
          'C60',
          'A30',
          'C59',
          'A38',
          'C57',
          'A25',
          'C54',
          'A26',
          'C53',
          'A52',
          'C51',
          'A46',
          'C41',
          'A35',
          'C35',
          'A42',
          'D25',
          'A51',
          'D26',
          'B29',
          'D21',
          'B22',
          'B5',
          'B24',
          'D24',
          'B53',
          'D19',
          'B32',
          'D14',
          'A8',
          'B2',
          'A12',
          'B1',
          'A17',
          'B3',
          'A16',
          'B4',
          'D3',
          'C5',
          'B60',
          'C3',
          'D1',
          'C2',
          'B59',
          'C1',
          'B56',
          'C58',
          'B18',
          'D11',
          'B14',
          'B39',
          'D42',
          'B35',
          'D15',
          'B17',
          'D2',
          'A37',
          'C31',
          'A36',
          'C39',
          'A46',
          'C38',
          'A35',
          'C34',
          'A42',
          'D24',
          'A50',
          'D23',
          'B37',
          'B52',
          'D12',
          'A6',
          'D11',
          'A3',
          'A28',
          'B48',
          'A27',
          'C52',
          'A37',
          'C49',
          'A23',
          'D5',
          'A25',
          'D6',
          'B7',
          'D7',
          'B40',
          'D42',
          'B41',
          'D1',
          'C1',
          'B60',
          'C4',
          'D3',
          'A22',
          'C42',
          'A46',
          'C37',
          'A36',
          'C28',
          'A37',
          'C48',
          'A23',
          'C46',
          'A51',
          'C44',
          'A34',
          'C36',
          'A39',
          'C31',
          'A40',
          'D24',
          'B30',
          'D28',
          'B28',
          'D27',
          'B34',
          'D18',
          'A14',
          'B1',
          'A11',
          'B2',
          'A10',
          'D14',
          'A7',
          'D13',
          'B39',
          'B6',
          'B27',
          'B5',
          'B36',
          'D45',
          'B38',
          'A3',
          'D9',
          'B33',
          'B15',
          'D19',
          'A41',
          'D21',
          'B31',
          'D20',
          'A32',
          'B57',
          'B42',
          'A22',
          'D5',
          'C59',
          'D1',
          'A24',
          'D2',
          'C58',
          'B18',
          'C57',
          'B23',
          'C56',
          'B25',
          'A51',
          'D24',
          'A46',
          'D26',
          'B16',
          'D25',
          'A49',
          'D23',
          'A42',
          'B4',
          'A12',
          'B33',
          'D41',
          'B10',
          'B50',
          'B13',
          'B47',
          'D22',
          'A50',
          'D21',
          'B48',
          'A26',
          'B59',
          'B11',
          'D42',
          'B16',
          'D5',
          'B40',
          'A5',
          'A34',
          'C26',
          'A35',
          'C33',
          'A46',
          'C36',
          'A22',
          'C40',
          'A51',
          'C39',
          'A33',
          'B58',
          'D49',
          'B57',
          'C58',
          'A31',
          'B54',
          'D58',
          'B55',
          'B10',
          'B35',
          'D36',
          'B42',
          'A20',
          'B20',
          'A19',
          'B6',
          'A16',
          'B34',
          'A15',
          'D19',
          'B18',
          'A3',
          'D10',
          'A4',
          'A48',
          'D26',
          'A44',
          'D25',
          'A46',
          'C45',
          'A33',
          'C42',
          'A34',
          'C39',
          'A42',
          'C33',
          'A51',
          'B26',
          'A6',
          'B43',
          'B58',
          'C60',
          'D4',
          'A56',
          'B45',
          'D44',
          'B38',
          'D43',
          'B20',
          'D12',
          'B41',
          'A21',
          'D2',
          'C1',
          'C17',
          'A23',
          'C45',
          'A25',
          'C40',
          'A46',
          'C35',
          'A51',
          'D20',
          'A41',
          'B60',
          'C3',
          'D3',
          'C2',
          'D4',
          'A55',
          'B44',
          'B12',
          'D26',
          'A54',
          'D7',
          'B10',
          'B54',
          'D60',
          'B59',
          'A23',
          'B45',
          'D23',
          'A46',
          'C34',
          'A36',
          'D2',
          'A27',
          'B50',
          'A56',
          'B20',
          'D40',
          'B5',
          'D14',
          'A9',
          'B48',
          'B18',
          'C57',
          'D1',
          'C6',
          'B59',
          'A41',
          'D25',
          'B40',
          'A4',
          'D15',
          'B31',
          'A1',
          'D6',
          'A22',
          'C18',
          'A23',
          'B22',
          'C53',
          'A32',
          'C46',
          'A3',
          'A48',
          'B15',
          'B38',
          'A2',
          'D9',
          'A31',
          'B58',
          'C59',
          'C41',
          'A38',
          'C38',
          'A42',
          'B4',
          'D5',
          'A53',
          'B35',
          'A14',
          'D17',
          'A13',
          'D18',
          'A49',
          'D24',
          'B41',
          'B60',
          'D60',
          'C16',
          'A23',
          'B58',
          'B15',
          'A47',
          'D26',
          'A43',
          'D3',
          'A21',
          'B29',
          'D29',
          'B31',
          'D10',
          'A27',
          'C40',
          'A35',
          'D22',
          'A33',
          'C25',
          'A34',
          'B57',
          'C56',
          'B18',
          'D4',
          'C1',
          'D5',
          'C58',
          'B28',
          'D25',
          'B12',
          'B51',
          'D57',
          'B52',
          'A59',
          'A17',
          'D20',
          'B5',
          'B37',
          'A25',
          'C43',
          'A32',
          'C35',
          'A34',
          'B60',
          'C4',
          'D2',
          'A55',
          'D8',
          'B7',
          'D7',
          'A1',
          'B38',
          'D15',
          'A33',
          'A9',
          'B1',
          'A17',
          'B21',
          'C48',
          'B23',
          'D23',
          'A51',
          'C34',
          'A46',
          'B56',
          'D50',
          'B57',
          'D48',
          'B58',
          'B2',
          'A7',
          'C45',
          'B13',
          'B33',
          'A11',
          'B34',
          'D41',
          'B11',
          'D13',
          'A5',
          'D14',
          'A3',
          'B25',
          'C55',
          'A24',
          'C44',
          'A46',
          'C32',
          'A51',
          'D30',
          'B29',
          'C56',
          'B54',
          'B13',
          'A20',
          'D2',
          'C6',
          'B59',
          'C5',
          'D1',
          'B12',
          'B49',
          'A26',
          'D4',
          'A21',
          'D24',
          'A41',
          'D11',
          'A7',
          'B22',
          'C52',
          'B55',
          'C57',
          'A31',
          'C49',
          'B23',
          'C47',
          'A57',
          'D26',
          'B10',
          'B56',
          'C55',
          'B18',
          'A47',
          'D21',
          'A32',
          'B52',
          'A44',
          'B24',
          'C60',
          'D8',
          'C4',
          'D4',
          'A30',
          'C50',
          'C35',
          'A38',
          'D23',
          'B30',
          'B15',
          'D38',
          'B20',
          'A18',
          'A60',
          'D14',
          'C40',
          'A32',
          'C28',
          'A33',
          'B54',
          'A23',
          'C15',
          'D59',
          'B53',
          'B9',
          'D41',
          'B36',
          'D26',
          'A49',
          'D16',
          'C41',
          'A46',
          'C31',
          'A42',
          'C37',
          'D7',
          'B37',
          'A2',
          'A28',
          'D9',
          'A56',
          'B46',
          'D46',
          'B41',
          'B56',
          'C54',
          'D1',
          'A27',
          'C36',
          'C55',
          'B59',
          'A31',
          'C47',
          'B21',
          'D44',
          'B33',
          'D8',
          'B19',
          'A1',
          'B60',
          'A36',
          'C27',
          'A33',
          'D9',
          'B13',
          'B43',
          'A5',
          'A20',
          'C4',
          'C20',
          'B55',
          'C56',
          'C34',
          'A32',
          'B58',
          'A52',
          'D21',
          'A17',
          'B5',
          'D41',
          'B41',
          'A18',
          'D59',
          'B56',
          'D22',
          'A51',
          'C38',
          'A58',
          'B51',
          'B10',
          'B45',
          'C7',
          'D17',
          'C42',
          'A26',
          'B44',
          'A55',
          'B50',
          'B15',
          'D34',
          'B18',
          'A4',
          'B20',
          'A3',
          'B33',
          'B11',
          'D44',
          'B47',
          'C12',
          'D3',
          'C11',
          'D5',
          'A21',
          'B36',
          'B6',
          'D37',
          'B9',
          'A45',
          'D26',
          'A50',
          'D25',
          'A54',
          'D29',
          'A51',
          'C37',
          'A46',
          'D27',
          'A49',
          'D15',
          'C38',
          'D16',
          'A12',
          'B28',
          'C49',
          'B29',
          'C55',
          'D1',
          'A41',
          'A4',
          'A21',
          'C15',
          'D56',
          'B51',
          'A43',
          'B15',
          'D17',
          'B3',
          'D2',
          'A29',
          'D19',
          'A6',
          'B2',
          'A5',
          'D16',
          'C31',
          'A51',
          'D3',
          'A55',
          'D33',
          'B30',
          'D22',
          'A19',
          'B57',
          'C21',
          'A26',
          'C10',
          'A23',
          'C8',
          'D1',
          'A12',
          'C44',
          'B13',
          'D52',
          'B17',
          'B35',
          'D18',
          'A44',
          'D6',
          'A37',
          'C42',
          'B21',
          'A1',
          'D8',
          'C4',
          'D9',
          'C3',
          'D2',
          'C56',
          'B19',
          'C55',
          'D4',
          'A25',
          'B57',
          'A39',
          'C40',
          'D17',
          'B31',
          'A30',
          'D13',
          'A6',
          'D23',
          'A11',
          'B4',
          'A41',
          'D22',
          'A36',
          'C43',
          'A21',
          'B54',
          'C57',
          'C33',
          'A46',
          'C30',
          'A35',
          'D2',
          'C35',
          'D4',
          'D60',
          'B43',
          'A55',
          'B49',
          'A9',
          'B1',
          'A13',
          'D19',
          'B4',
          'A10',
          'C45',
          'A31',
          'C44',
          'B14',
          'A28',
          'B44',
          'C5',
          'D1',
          'D52',
          'B18',
          'B47',
          'C11',
          'A25',
          'B30',
          'D40',
          'A58',
          'C36',
          'D6',
          'A21',
          'C50',
          'A22',
          'C14',
          'D58',
          'C57',
          'B24',
          'C52',
          'B56',
          'A46',
          'B26',
          'A52',
          'B49',
          'A8',
          'D12',
          'C29',
          'A40',
          'B59',
          'C34',
          'B60',
          'A23',
          'C35',
          'A39',
          'D22',
          'A7',
          'D23',
          'A19',
          'C1',
          'D3',
          'C10',
          'B46',
          'B7',
          'B40',
          'B15',
          'B48',
          'A25',
          'B44',
          'C4',
          'D14',
          'C39',
          'D28',
          'B16',
          'A20',
          'B50',
          'C19',
          'B51',
          'B14',
          'D12',
          'A38',
          'D3',
          'C8',
          'D5',
          'A55',
          'B45',
          'A22',
          'C47',
          'B22',
          'A59',
          'A14',
          'D2',
          'C5',
          'D7',
          'C36',
          'A31',
          'D10',
          'A1',
          'B37',
          'B16',
          'C16',
          'D53',
          'B13',
          'D51',
          'B56',
          'C57',
          'B60',
          'B12',
          'A24',
          'C9',
          'A3',
          'B40',
          'A15',
          'B6',
          'D21',
          'A9',
          'B52',
          'D56',
          'C55',
          'C40',
          'A1',
          'C33',
          'A48',
          'D17',
          'C6',
          'A19',
          'B58',
          'C56',
          'B60',
          'B3',
          'D32',
          'A51',
          'C42',
          'A42',
          'D12',
          'A7',
          'B19',
          'A6',
          'C11',
          'D20',
          'A16',
          'A56',
          'C30',
          'D14',
          'A28',
          'D19',
          'A48',
          'A2',
          'B18',
          'B33',
          'B58',
          'C22',
          'A31',
          'B46',
          'C4',
          'B57',
          'A22',
          'D24',
          'A18',
          'D57',
          'C17',
          'B11',
          'C16',
          'B22',
          'D44',
          'B48',
          'B13',
          'B52',
          'A58',
          'C33',
          'A51',
          'D18',
          'B18',
          'C22',
          'B59',
          'D50',
          'B16',
          'D38',
          'A15',
          'C58',
          'B26',
          'D28',
          'C38',
          'D30',
          'B33',
          'A2',
          'D13',
          'B5',
          'C35',
          'A43',
          'C34',
          'A56',
          'D22',
          'A46',
          'C29',
          'D12',
          'B56',
          'B18',
          'D48',
          'B16',
          'B46',
          'C9',
          'A19',
          'B13',
          'C43',
          'D18',
          'C32',
          'A2',
          'B25',
          'C60',
          'B59',
          'D49',
          'D1',
          'A50',
          'D31',
          'C39',
          'A37',
          'D3',
          'C56',
          'C10',
          'B21',
          'C41',
          'D15',
          'A11',
          'B53',
          'A60',
          'A18',
          'B49',
          'B34',
          'A10',
          'D24',
          'A39',
          'B55',
          'C2',
          'A3',
          'B2',
          'D5',
          'D55',
          'B17',
          'C20',
          'B57',
          'A33',
          'D23',
          'C11',
          'A1',
          'C1',
          'A20',
          'B29',
          'D25',
          'A55',
          'D34',
          'A14',
          'B6',
          'A12',
          'B58',
          'C47',
          'B24',
          'A43',
          'D22',
          'B37',
          'C60',
          'C36',
          'A42',
          'D26',
          'B20',
          'D59',
          'D4',
          'A4',
          'B41',
          'D18',
          'C40',
          'A28',
          'D20',
          'B10',
          'B52',
          'A30',
          'C48',
          'A46',
          'B16',
          'C15',
          'B22',
          'C51',
          'D52',
          'A20',
          'D5',
          'C10',
          'A2',
          'B2',
          'D26',
          'B27',
          'C50',
          'B53',
          'A22',
          'D56',
          'C52',
          'D2',
          'A23',
          'B12',
          'D42',
          'B17',
          'D54',
          'D4',
          'C12',
          'C60',
          'B32',
          'B60',
          'C53',
          'B15',
          'C49',
          'B46',
          'A41',
          'A3',
          'D7',
          'C35',
          'A44',
          'B7',
          'D40',
          'A59',
          'D34',
          'B35',
          'A23',
          'C14',
          'A36',
          'C36',
          'B44',
          'A24',
          'B30',
          'D11',
          'C39',
          'D29',
          'B12',
          'B55',
          'C59',
          'B17',
          'A46',
          'D26',
          'A41',
          'D2',
          'A13',
          'C43',
          'B54',
          'A45',
          'A7',
          'C10',
          'D20',
          'C44',
          'A57',
          'A40',
          'B60',
          'C7',
          'D5',
          'C3',
          'A20',
          'B45',
          'B19',
          'A5',
          'A35',
          'C25',
          'B56',
          'C23',
          'B29',
          'D18',
          'C8',
          'A25',
          'D28',
          'C37',
          'A21',
          'D57',
          'C13',
          'A36',
          'D37',
          'A33',
          'D10',
          'C3',
          'D13',
          'B21',
          'D43',
          'B11',
          'C15',
          'A16',
          'D40',
          'A56',
          'B55',
          'C55',
          'D2',
          'C53',
          'D54',
          'B19',
          'D38',
          'B22',
          'C41',
          'B6',
          'B44',
          'A5',
          'B32',
          'C59',
          'C44',
          'B15',
          'C48',
          'D52',
          'C56',
          'B53',
          'B38',
          'A30',
          'B54',
          'A20',
          'D60',
          'D5',
          'A56',
          'C32',
          'B1',
          'D1',
          'C34',
          'B5',
          'C33',
          'A32',
          'D36',
          'B3',
          'B59',
          'C58',
          'D13',
          'C29',
          'A35',
          'A4',
          'A50',
          'B57',
          'C19',
          'C3',
          'B38',
          'C60',
          'A48',
          'B36',
          'A54',
          'B50',
          'C21',
          'B18',
          'B39',
          'C6',
          'B9',
          'C42',
          'A57',
          'C37',
          'D15',
          'A10',
          'A55',
          'A9',
          'D16',
          'B21',
          'A16',
          'D54',
          'C16',
          'B48',
          'A8',
          'A30',
          'D14',
          'A17',
          'B13',
          'A27',
          'D30',
          'C40',
          'D19',
          'C12',
          'C54',
          'B57',
          'B10',
          'C18',
          'B49',
          'A33',
          'C43',
          'D7',
          'C42',
          'D38',
          'A37',
          'D12',
          'A58',
          'C51',
          'A29',
          'D4',
          'C14',
          'B22',
          'A24',
          'B56',
          'C21',
          'B28',
          'C57',
          'A52',
          'B45',
          'C6',
          'D5',
          'D54',
          'B49',
          'A30',
          'D21',
          'A27',
          'B14',
          'C38',
          'D31',
          'B17',
          'B42',
          'C11',
          'D4',
          'C9',
          'D22',
          'A1',
          'B2',
          'B44',
          'C2',
          'A19',
          'D2',
          'A33',
          'A50',
          'D19',
          'A5',
          'C14',
          'D39',
          'A15',
          'C43',
          'B21',
          'C42',
          'A58',
          'D55',
          'A21',
          'B57',
          'C53',
          'B18',
          'B50',
          'B8',
          'B48',
          'C15',
          'B17',
          'D53',
          'B19',
          'B35',
          'D44',
          'A16',
          'D22',
          'D6',
          'A54',
          'D2',
          'A12',
          'B26',
          'A11',
          'C13',
          'B22',
          'C40',
          'D13',
          'A46',
          'D20',
          'C8',
          'B41',
          'A23',
          'D19',
          'A44',
          'B52',
          'C11',
          'A20',
          'C9',
          'A8',
          'B1',
          'C31',
          'D11',
          'C30',
          'D15',
          'A28',
          'B53',
          'A48',
          'C58',
          'D50',
          'B56',
          'C45',
          'B20',
          'D18',
          'C47',
          'B29',
          'C20',
          'B14',
          'A26',
          'C41',
          'D7',
          'D57',
          'C54',
          'D51',
          'B19',
          'D22',
          'C8',
          'A24',
          'D29',
          'A27',
          'D11',
          'B37',
          'A17',
          'D58',
          'A23',
          'D25',
          'B33',
          'D16',
          'C37',
          'C53',
          'D3',
          'C52',
          'B47',
          'A41',
          'B4',
          'C31',
          'A56',
          'C28',
          'D12',
          'A3',
          'D60',
          'B24',
          'D59',
          'D8',
          'A37',
          'D39',
          'C13',
          'C57',
          'D51',
          'B23',
          'A27',
          'B43',
          'B12',
          'A21',
          'D1',
          'A15',
          'D36',
          'B36',
          'D15',
          'A17',
          'D42',
          'C44',
          'B20',
          'A18',
          'D48',
          'B59',
          'A55',
          'B17',
          'C14',
          'A7',
          'A47',
          'D28',
          'B27',
          'A9',
          'D20',
          'C40',
          'B38',
          'C38',
          'B49',
          'A10',
          'B6',
          'D4',
          'A52',
          'C50',
          'B9',
          'C3',
          'D14',
          'A45',
          'D8',
          'A28',
          'A46',
          'C39',
          'A39',
          'D11',
          'B3',
          'D1',
          'B31',
          'D47',
          'B11',
          'C18',
          'B22',
          'C12',
          'A12',
          'D17',
          'C41',
          'A15',
          'D13',
          'A59',
          'B60',
          'A26',
          'C19',
          'B57',
          'B37',
          'C5',
          'D7',
          'A44',
          'C34',
          'C60',
          'B39',
          'A29',
          'B31',
          'D43',
          'A17',
          'C56',
          'D55',
          'B13',
          'C42',
          'B26',
          'C57',
          'D48',
          'B42',
          'C36',
          'A51',
          'C30',
          'A39',
          'B17',
          'C19',
          'B10',
          'D40',
          'B33',
          'B54',
          'C15',
          'D54',
          'A58',
          'C32',
          'A48',
          'C39',
          'D32',
          'B30',
          'C23',
          'B18',
          'D47',
          'B43',
          'D36',
          'A31',
          'C41',
          'D29',
          'B26',
          'A51',
          'C28',
          'A57',
          'D33',
          'C13',
          'D2',
          'A3',
          'D15',
          'C2',
          'B9',
          'C1',
          'B19',
          'C60',
          'D22',
          'A8',
          'A43',
          'D16',
          'A33',
          'C40',
          'A36',
          'D23',
          'A5',
          'C46',
          'A24',
          'C42',
          'B39',
          'A13',
          'D34',
          'B44',
          'B59',
          'C49',
          'A52',
          'B35',
          'C25',
          'B57',
          'B17',
          'C13',
          'D42',
          'B42',
          'A7',
          'C4',
          'A55',
          'C3',
          'B25',
          'D45',
          'C46',
          'B55',
          'C58',
          'B21',
          'D33',
          'C39',
          'B1',
          'A4',
          'C10',
          'A33',
          'A51',
          'B57',
          'C2',
          'B60',
          'A32',
          'D12',
          'C5',
          'B40',
          'A29',
          'D5',
          'C14',
          'B11',
          'C50',
          'D38',
          'A34',
          'B58',
          'C24',
          'B29',
          'A49',
          'B37',
          'A14',
          'D20',
          'A6',
          'C33',
          'A54',
          'B44',
          'C10',
          'B22',
          'D16',
          'A24',
          'C12',
          'D21',
          'A42',
          'B14',
          'C37',
          'D6',
          'C60',
          'D51',
          'A20',
          'B16',
          'A19',
          'D24',
          'C11',
          'D2',
          'A50',
          'A2',
          'B20',
          'D39',
          'A35',
          'B42',
          'C34',
          'B44',
          'A4',
          'D23',
          'B46',
          'C17',
          'B10',
          'D25',
          'A21',
          'D17',
          'C31',
          'A48',
          'D13',
          'A28',
          'B50',
          'D20',
          'A27',
          'C11',
          'C55',
          'A18',
          'C52',
          'B20',
          'D58',
          'B34',
          'A60',
          'A14',
          'D35',
          'B15',
          'A13',
          'D50',
          'B23',
          'C18',
          'A45',
          'B9',
          'C34',
          'D2',
          'C57',
          'D54',
          'C50',
          'B45',
          'C10',
          'D24',
          'C8',
          'D4',
          'A24',
          'B37',
          'D6',
          'C6',
          'A21',
          'D53',
          'C58',
          'B34',
          'D23',
          'A2',
          'C13',
          'A9',
          'B20',
          'C51',
          'B8',
          'C21',
          'B58',
          'C35',
          'B31',
          'C56',
          'A52',
          'C48',
          'B47',
          'A58',
          'C47',
          'A23',
          'D9',
          'C38',
          'B1',
          'B57',
          'A17',
          'C15',
          'B49',
          'A7',
          'A47',
          'A28',
          'D39',
          'C50',
          'D19',
          'C11',
          'B53',
          'A37',
          'D37',
          'B33',
          'A29',
          'D11',
          'A8',
          'D17',
          'A41',
          'D9',
          'D59',
          'C56',
          'D5',
          'A44',
          'B32',
          'D43',
          'B39',
          'C41',
          'B50',
          'C20',
          'B8',
          'C54',
          'B29',
          'D46',
          'B11',
          'C16',
          'B16',
          'C45',
          'D16',
          'A36',
          'C10',
          'A60',
          'D10',
          'A12',
          'A46',
          'B47',
          'A17',
          'D56',
          'B14',
          'D41',
          'B43',
          'B20',
          'C1',
          'D15',
          'B57',
          'D47',
          'B59',
          'C24',
          'A33',
          'C37',
          'D17',
          'A5',
          'D21',
          'C11',
          'B38',
          'A21',
          'A44',
          'B9',
          'C9',
          'B60',
          'B14',
          'C59',
          'B25',
          'C2',
          'B45',
          'A19',
          'C12',
          'C53',
          'B54',
          'C14',
          'B27',
          'C13',
          'D44',
          'C49',
          'B29',
          'C45',
          'A52',
          'D26',
          'B32',
          'A58',
          'C40',
          'B51',
          'A10',
          'D16',
          'C42',
          'A25',
          'D30',
          'A49',
          'D1',
          'A37',
          'B45',
          'C36',
          'D13',
          'B36',
          'A20',
          'D54',
          'B35',
          'A22',
          'C33',
          'D20',
          'A55',
          'B18',
          'C20',
          'D6',
          'D60',
          'B9',
          'C7',
          'C25',
          'A41',
          'C40',
          'B6',
          'C39',
          'B13',
          'D50',
          'C59',
          'B39',
          'D4',
          'A36',
          'D13',
          'A13',
          'B48',
          'C52',
          'A19',
          'C44',
          'B5',
          'C32',
          'A38',
          'B17',
          'C24',
          'B52',
          'C23',
          'B31',
          'A32',
          'D9',
          'D58',
          'B55',
          'D51',
          'D4',
          'A20',
          'B12',
          'C60',
          'B2',
          'C3',
          'A27',
          'B46',
          'D46',
          'B16',
          'C15',
          'A26',
          'D21',
          'C10',
          'D25',
          'A30',
          'C46',
          'D8',
          'C45',
          'B1',
          'C30',
          'A56',
          'A15',
          'D18',
          'C37',
          'B33',
          'C34',
          'A50',
          'A1',
          'A16',
          'C4',
          'C56',
          'B26',
          'D45',
          'A19',
          'C51',
          'D58',
          'B41',
          'B56',
          'A39',
          'B58',
          'C19',
          'B14',
          'B42',
          'A6',
          'D5',
          'A35',
          'C12',
          'D2',
          'C2',
          'D3',
          'C1',
          'B51',
          'B17',
          'D39',
          'C45',
          'B43',
          'C41',
          'B21',
          'A29',
          'C40',
          'D29',
          'C37',
          'A48',
          'C57',
          'B57',
          'A47',
          'A6',
          'B22',
          'D55',
          'D9',
          'A27',
          'C10',
          'B2',
          'D60',
          'D7',
          'A31',
          'D16',
          'A20',
          'B56',
          'B6',
          'B43',
          'C16',
          'A24',
          'C20',
          'B10',
          'B37',
          'A32',
          'D19',
          'C35',
          'B59',
          'C4',
          'A19',
          'C43',
          'A23',
          'D56',
          'A3',
          'B30',
          'D16',
          'C44',
          'B55',
          'A55',
          'D1',
          'A14',
          'B40',
          'C3',
          'B19',
          'A12',
          'C10',
          'B54',
          'B9',
          'C5',
          'B45',
          'C35',
          'D6',
          'C19',
          'B3',
          'C17',
          'B28',
          'A53',
          'B38',
          'C37',
          'B1',
          'C29',
          'B4',
          'D4',
          'C1',
          'B25',
          'A44',
          'A19',
          'D47',
          'A18',
          'B56',
          'C59',
          'D15',
          'A43',
          'C39',
          'B22',
          'D52',
          'B35',
          'C27',
          'B39',
          'A16',
          'C58',
          'C11',
          'D30',
          'A28',
          'B33',
          'D24',
          'C7',
          'B43',
          'C37',
          'A19',
          'B29',
          'D38',
          'A32',
          'A51',
          'B28',
          'C50',
          'D1',
          'A53',
          'C34',
          'D18',
          'A34',
          'C42',
          'B5',
          'D36',
          'A14',
          'C46',
          'A57',
          'A40',
          'C26',
          'A50',
          'C25',
          'C9',
          'B21',
          'C40',
          'A38',
          'D5',
          'B3',
          'C10',
          'D2',
          'A42',
          'B49',
          'C14',
          'B55',
          'C22',
          'A45',
          'B47',
          'D41',
          'B54',
          'C31',
          'D13',
          'A34',
          'D25',
          'D9',
          'D54',
          'B13',
          'A40',
          'D8',
          'D60',
          'B19',
          'B37',
          'D45',
          'A42',
          'C40',
          'B52',
          'A8',
          'C44',
          'A32',
          'C26',
          'B39',
          'C15',
          'D37',
          'A26',
          'D18',
          'C42',
          'D39',
          'A29',
          'D20',
          'A1',
          'B33',
          'D53',
          'A58',
          'C46',
          'A37',
          'D9',
          'C27',
          'D15',
          'A7',
          'D24',
          'B44',
          'D33',
          'B18',
          'C24',
          'B23',
          'A59',
          'C4',
          'B9',
          'C51',
          'B35',
          'C2',
          'A55',
          'A8',
          'C5',
          'B15',
          'A26',
          'C37',
          'B12',
          'C22',
          'A50',
          'C19',
          'D52',
          'B19',
          'B55',
          'C13',
          'A34',
          'B54',
          'A56',
          'C11',
          'D55',
          'A15',
          'C53',
          'D43',
          'B16',
          'C10',
          'D3',
          'A33',
          'C7',
          'B21',
          'C60',
          'D12',
          'D60',
          'D15',
          'A39',
          'B60',
          'C51',
          'A20',
          'B14',
          'C18',
          'D6',
          'A60',
          'B52',
          'C55',
          'B53',
          'A44',
          'C33',
          'B1',
          'C44',
          'D17',
          'B54',
          'D57',
          'B34',
          'D35',
          'B28',
          'C16',
          'B46',
          'C3',
          'A5',
          'D58',
          'B24',
          'A22',
          'C49',
          'D1',
          'A11',
          'A46',
          'C38',
          'B8',
          'A41',
          'C24',
          'B30',
          'B55',
          'A32',
          'C32',
          'B25',
          'D30',
          'C51',
          'C10',
          'D26',
          'D11',
          'A23',
          'D27',
          'B29',
          'C19',
          'B51',
          'B36',
          'A52',
          'C55',
          'D22',
          'C59',
          'B19',
          'A55',
          'A7',
          'B26',
          'D50',
          'D4',
          'C34',
          'B13',
          'D58',
          'A48',
          'D48',
          'C48',
          'A3',
          'D19',
          'C12',
          'D23',
          'A54',
          'B58',
          'B16',
          'C44',
          'A52',
          'B48',
          'A12',
          'B59',
          'A27',
          'D32',
          'B35',
          'C24',
          'A25',
          'D21',
          'B38',
          'D11',
          'A4',
          'B2',
          'D41',
          'A16',
          'D38',
          'A43',
          'C16',
          'D6',
          'A29',
          'C36',
          'B33',
          'A44',
          'D4',
          'C7',
          'A36',
          'D17',
          'C39',
          'D30',
          'C10',
          'C59',
          'D59',
          'A20',
          'B51',
          'A31',
          'C8',
          'B16',
          'C14',
          'A26',
          'D9',
          'A10',
          'D13',
          'C45',
          'A5',
          'C9',
          'B40',
          'A19',
          'C41',
          'A57',
          'C30',
          'B21',
          'D46',
          'C13',
          'D18',
          'B4',
          'C17',
          'B14',
          'C37',
          'B9',
          'B42',
          'A49',
          'D14',
          'B35',
          'C12',
          'B55',
          'A29',
          'C49',
          'D37',
          'A35',
          'D10',
          'D58',
          'B27',
          'A6',
          'C14',
          'B23',
          'D38',
          'C48',
          'B28',
          'D56',
          'A53',
          'A11',
          'B19',
          'C2',
          'B21',
          'D41',
          'B32',
          'C56',
          'A48',
          'C35',
          'D1',
          'A36',
          'D25',
          'A22',
          'B7',
          'C17',
          'A45',
          'C20',
          'A25',
          'C38',
          'B22',
          'B37',
          'C58',
          'D12',
          'B56',
          'C3',
          'D14',
          'A43',
          'B51',
          'A56',
          'C10',
          'B38',
          'D53',
          'A19',
          'D5',
          'C1',
          'D6',
          'A27',
          'A50',
          'C31',
          'B8',
          'C53',
          'A16',
          'B60',
          'A42',
          'C36',
          'B58',
          'C52',
          'A28',
          'B13',
          'D3',
          'D52',
          'A22',
          'B37',
          'C4',
          'D1',
          'B16',
          'D47',
          'B45',
          'C34',
          'D19',
          'C33',
          'B24',
          'C22',
          'B52',
          'A52',
          'D31',
          'A30',
          'D36',
          'B29',
          'D35',
          'B43',
          'A53',
          'D24',
          'B36',
          'A27',
          'C2',
          'B2',
          'A37',
          'C43',
          'A41',
          'D46',
          'A19',
          'C10',
          'A49',
          'C5',
          'A56',
          'D10',
          'A6',
          'D57',
          'A58',
          'D35',
          'B30',
          'A2',
          'B18',
          'B41',
          'D4',
          'C4',
          'B15',
          'A16',
          'C45',
          'B44',
          'C37',
          'A38',
          'D18',
          'A7',
          'C12',
          'D47',
          'C49',
          'B11',
          'D45',
          'C55',
          'C13',
          'C58',
          'D22',
          'A55',
          'A6',
          'D35',
          'A24',
          'C41',
          'B5',
          'B32',
          'A32',
          'C25',
          'B17',
          'C16',
          'A45',
          'D23',
          'B29',
          'D60',
          'B25',
          'C31',
          'B20',
          'A8',
          'D13',
          'D57',
          'B41',
          'A59',
          'B58',
          'A40',
          'A9',
          'D39',
          'C39',
          'B37',
          'D54',
          'A15',
          'D42',
          'D3',
          'C9',
          'B22',
          'C7',
          'A49',
          'D58',
          'A2',
          'B31',
          'D28',
          'A51',
          'B45',
          'D33',
          'B3',
          'B49',
          'D21',
          'A18',
          'D49',
          'B6',
          'A9',
          'A41',
          'D39',
          'C11',
          'A10',
          'C44',
          'D48',
          'B23',
          'A46',
          'C36',
          'B14',
          'C50',
          'D30',
          'B35',
          'C23',
          'B8',
          'C29',
          'B49',
          'D44',
          'B18',
          'D55',
          'B1',
          'C43',
          'B20',
          'D28',
          'A23',
          'B44',
          'C7',
          'A20',
          'B38',
          'D8',
          'A2',
          'C1',
          'B21',
          'A28',
          'D17',
          'B32',
          'C13',
          'B17',
          'C22',
          'B59',
          'A19',
          'C15',
          'D38',
          'A14',
          'D12',
          'B36',
          'A25',
          'C33',
          'B7',
          'C55',
          'C5',
          'A34',
          'C41',
          'B9',
          'D59',
          'B33',
          'D36',
          'A27',
          'D3',
          'A30',
          'C45',
          'A17',
          'A60',
          'D40',
          'C14',
          'B39',
          'B54',
          'A49',
          'D29',
          'C44',
          'D21',
          'C52',
          'B8',
          'C19',
          'B11',
          'D11',
          'B45',
          'D43',
          'B28',
          'D46',
          'A35',
          'C11',
          'B55',
          'C43',
          'D37',
          'A39',
          'C29',
          'B21',
          'C40',
          'D31',
          'A27',
          'D12',
          'C56',
          'A11',
          'D2',
          'C46',
          'B39',
          'D27',
          'A24',
          'C6',
          'A53',
          'C33',
          'B13',
          'A47',
          'D14',
          'C26',
          'A56',
          'C23',
          'A33',
          'D11',
          'D26',
          'A43',
          'B28',
          'D55',
          'B34',
          'D16',
          'A22',
          'C44',
          'A17',
          'D54',
          'B22',
          'D51',
          'B58',
          'A52',
          'D25',
          'A39',
          'C34',
          'A48',
          'C47',
          'B15',
          'A12',
          'C54',
          'B53',
          'C26',
          'D15',
          'C46',
          'B20',
          'D17',
          'C35',
          'A30',
          'C33',
          'B59',
          'C21',
          'B12',
          'D24',
          'C6',
          'A59',
          'C1',
          'A25',
          'D12',
          'A49',
          'B60',
          'A12',
          'D49',
          'B22',
          'C17',
          'D36',
          'C16',
          'B14',
          'D55',
          'A4',
          'D3',
          'A40',
          'C25',
          'B29',
          'D33',
          'A42',
          'D43',
          'B49',
          'A34',
          'D1',
          'C13',
          'B23',
          'A26',
          'D39',
          'B28',
          'D42',
          'A38',
          'C36',
          'B1',
          'A13',
          'D35',
          'B17',
          'C12',
          'B27',
          'D50',
          'A21',
          'C41',
          'A27',
          'B51',
          'C44',
          'A58',
          'D56',
          'D7',
          'C40',
          'D43',
          'A32',
          'B46',
          'D23',
          'B48',
          'A45',
          'B16',
          'A43',
          'D53',
          'B2',
          'C59',
          'C3',
          'A21',
          'B24',
          'C27',
          'D6',
          'D22',
          'A6',
          'C48',
          'A12',
          'C9',
          'A56',
          'D58',
          'C60',
          'D9',
          'A60',
          'B56',
          'B22',
          'C15',
          'A45',
          'C24',
          'A50',
          'C20',
          'D50',
          'D5',
          'A1',
          'A41',
          'D18',
          'A22',
          'B54',
          'A16',
          'C17',
          'B10',
          'D37',
          'B15',
          'A47',
          'D25',
          'B35',
          'D29',
          'C28',
          'B8',
          'D56',
          'B17',
          'D30',
          'B30',
          'B51',
          'D20',
          'C53',
          'A58',
          'C30',
          'A48',
          'C54',
          'B19',
          'C33',
          'A39',
          'C32',
          'D19',
          'A14',
          'B41',
          'A3',
          'D57',
          'B13',
          'C10',
          'D40',
          'C37',
          'A28',
          'D37',
          'A38',
          'B17',
          'B56',
          'C22',
          'B31',
          'D60',
          'A27',
          'B53',
          'C7',
          'B26',
          'C6',
          'B60',
          'A15',
          'C46',
          'B15',
          'D18',
          'C54',
          'D46',
          'B42',
          'C11',
          'D48',
          'B6',
          'C39',
          'A22',
          'B59',
          'B35',
          'D16',
          'A2',
          'C9',
          'A60',
          'A14',
          'B13',
          'C41',
          'A37',
          'D7',
          'D53',
          'D2',
          'A10',
          'D33',
          'B33',
          'D28',
          'A46',
          'B27',
          'A17',
          'C43',
          'D31',
          'B28',
          'C46',
          'B34',
          'C26',
          'A39',
          'C16',
          'B10',
          'C15',
          'B11',
          'D40',
          'B22',
          'D11',
          'B41',
          'C12',
          'A53',
          'C21',
          'A23',
          'B49',
          'A6',
          'B29',
          'A27',
          'B16',
          'D49',
          'C10',
          'C39',
          'D12',
          'B17',
          'D34',
          'C40',
          'A44',
          'A18',
          'C38',
          'D13',
          'A4',
          'D1',
          'C43',
          'A20',
          'C34',
          'B3',
          'C18',
          'D22',
          'C7',
          'D5',
          'C55',
          'B32',
          'D38',
          'A7',
          'B1',
          'C28',
          'B21',
          'A27',
          'C13',
          'B39',
          'D15',
          'A45',
          'D45',
          'B43',
          'C11',
          'D1',
          'B18',
          'C4',
          'D8',
          'A55',
          'C12',
          'B49',
          'A29',
          'D25',
          'D41',
          'C14',
          'B11',
          'B58',
          'A18',
          'A54',
          'C33',
          'B4',
          'C16',
          'B48',
          'C35',
          'A51',
          'C34',
          'D22',
          'C51',
          'D32',
          'A42',
          'C29',
          'B50',
          'D55',
          'B24',
          'D41',
          'A17',
          'C53',
          'B19',
          'C32',
          'B20',
          'C3',
          'B6',
          'C2',
          'C60',
          'D20',
          'B33',
          'C34',
          'A21',
          'D48',
          'B7',
          'D47',
          'C52',
          'A40',
          'C49',
          'A54',
          'C48',
          'B9',
          'A41',
          'C18',
          'A50',
          'B56',
          'A23',
          'C43',
          'D15',
          'B59',
          'B44',
          'A53',
          'D49',
          'A39',
          'D16',
          'C26',
          'B37',
          'C34',
          'B55',
          'B23',
          'C1',
          'B45',
          'D32',
          'B7',
          'D41',
          'D25',
          'B11',
          'C11',
          'D19',
          'A55',
          'B41',
          'B22',
          'A23',
          'C9',
          'B16',
          'D24',
          'B28',
          'A11',
          'D46',
          'A17',
          'D16',
          'A30',
          'C37',
          'A35',
          'C20',
          'B44',
          'D52',
          'A58',
          'C39',
          'B43',
          'C44',
          'D49',
          'A48',
          'B59',
          'A42',
          'D15',
          'B22',
          'D44',
          'B60',
          'C26',
          'D17',
          'B55',
          'D41',
          'A28',
          'A47',
          'A5',
          'D5',
          'A18',
          'C16',
          'B7',
          'C33',
          'B8',
          'C27',
          'A37',
          'C37',
          'A58',
          'C35',
          'B1',
          'B36',
          'C6',
          'A33',
          'D36',
          'B37',
          'A4',
          'D18',
          'C13',
          'B33',
          'A8',
          'C47',
          'A12',
          'D48',
          'B34',
          'C25',
          'B3',
          'C8',
          'D50',
          'A17',
          'C42',
          'A30',
          'D34',
          'B19',
          'B58',
          'C23',
          'B23',
          'C12',
          'D27',
          'C10',
          'D54',
          'B24',
          'A18',
          'D23',
          'C11',
          'A59',
          'D35',
          'B3',
          'C5',
          'D3',
          'D40',
          'A55',
          'A4',
          'C49',
          'D2',
          'A44',
          'A8',
          'B46',
          'A6',
          'D14',
          'C48',
          'A57',
          'C23',
          'B48',
          'C51',
          'A48',
          'C29',
          'D25',
          'B40',
          'C53',
          'B41',
          'D56',
          'A16',
          'A54',
          'D28',
          'C40',
          'A45',
          'D11',
          'A31',
          'D20',
          'C13',
          'A50',
          'A27',
          'C38',
          'D39',
          'B25',
          'D59',
          'A8',
          'B35',
          'D46',
          'B17',
          'A37',
          'D19',
          'B53',
          'A59',
          'C5',
          'D7',
          'C34',
          'B15',
          'C33',
          'D30',
          'C49',
          'D54',
          'B1',
          'C27',
          'A45',
          'A1',
          'C10',
          'D42',
          'B52',
          'C39',
          'A24',
          'B32',
          'C35',
          'A38',
          'D6',
          'C14',
          'B14',
          'A28',
          'D40',
          'D25',
          'B22',
          'D7',
          'A4',
          'B18',
          'B46',
          'B3',
          'D12',
          'C27',
          'B57',
          'C32',
          'A46',
          'B34',
          'A17',
          'B42',
          'C8',
          'B10',
          'D5',
          'C21',
          'B8',
          'C26',
          'B40',
          'C17',
          'A60',
          'D34',
          'B43',
          'C6',
          'B53',
          'A52',
          'D55',
          'B45',
          'D24',
          'A31',
          'C35',
          'A46',
          'D9',
          'C4',
          'A49',
          'D57',
          'B20',
          'C31',
          'A32',
          'C8',
          'B21',
          'A25',
          'B42',
          'A2',
          'D18',
          'C41',
          'B51',
          'A14',
          'C55',
          'B54',
          'C51',
          'B22',
          'A22',
          'D54',
          'B30',
          'D21',
          'A35',
          'C44',
          'A12',
          'B39',
          'A33',
          'D39',
          'A16',
          'D53',
          'D9',
          'A36',
          'C53',
          'B57',
          'C18',
          'D35',
          'D20',
          'C3',
          'B15',
          'C2',
          'C48',
          'B48',
          'C34',
          'B1',
          'D15',
          'A37',
          'C22',
          'B18',
          'D43',
          'A14',
          'C44',
          'B46',
          'D10',
          'C14',
          'B16',
          'A26',
          'C18',
          'B58',
          'C6',
          'D6',
          'B6',
          'A8',
          'B41',
          'C59',
          'C13',
          'B35',
          'B5',
          'C31',
          'B55',
          'C24',
          'B12',
          'D22',
          'C57',
          'B2',
          'D52',
          'A1',
          'B17',
          'D45',
          'B44',
          'C36',
          'A19',
          'B38',
          'A4',
          'D14',
          'A40',
          'B13',
          'D51',
          'C52',
          'D41',
          'C37',
          'A43',
          'C18',
          'B8',
          'C16',
          'D4',
          'A22',
          'C30',
          'B52',
          'B22',
          'D39',
          'B26',
          'D53',
          'B34',
          'A15',
          'A56',
          'C15',
          'D38',
          'B28',
          'C20',
          'B56',
          'C44',
          'B7',
          'A46',
          'A24',
          'D26',
          'B39',
          'C45',
          'A57',
          'C40',
          'A17',
          'B50',
          'D23',
          'A1',
          'B49',
          'B11',
          'C13',
          'B40',
          'D13',
          'C14',
          'D43',
          'C10',
          'C25',
          'A42',
          'C43',
          'B23',
          'D60',
          'D16',
          'A59',
          'C58',
          'D31',
          'A31',
          'D39',
          'A43',
          'B30',
          'C57',
          'B52',
          'A7',
          'B6',
          'C34',
          'B49',
          'D53',
          'A23',
          'B36',
          'C29',
          'C8',
          'B13',
          'A39',
          'B59',
          'D47',
          'C58',
          'B14',
          'A5',
          'D33',
          'B34',
          'C24',
          'A51',
          'D31',
          'B19',
          'C41',
          'D36',
          'A26',
          'B29',
          'D22',
          'A32',
          'D37',
          'C16',
          'A47',
          'C11',
          'D2',
          'D18',
          'A21',
          'D27',
          'B35',
          'C7',
          'B16',
          'A19',
          'C8',
          'D52',
          'B57',
          'D13',
          'A24',
          'C43',
          'B56',
          'C28',
          'B21',
          'C27',
          'D29',
          'A50',
          'B48',
          'A41',
          'D12',
          'C4',
          'A56',
          'C29',
          'A31',
          'C33',
          'B9',
          'D40',
          'A17',
          'C39',
          'D18',
          'C60',
          'C45',
          'B54',
          'A36',
          'C30',
          'D11',
          'B4',
          'B27',
          'D49',
          'B17',
          'B32',
          'C16',
          'B56',
          'C40',
          'D2',
          'C9',
          'B2',
          'C56',
          'A11',
          'B1',
          'A6',
          'D6',
          'C1',
          'A49',
          'D20',
          'B39',
          'A10',
          'A53',
          'C24',
          'B57',
          'D39',
          'B27',
          'A15',
          'D57',
          'B7',
          'D52',
          'D7',
          'C18',
          'B29',
          'D31',
          'C56',
          'C19',
          'B22',
          'C50',
          'D59',
          'B2',
          'A23',
          'D29',
          'B38',
          'B5',
          'C43',
          'B26',
          'C42',
          'A14',
          'B44',
          'C17',
          'A17',
          'C9',
          'D44',
          'A18',
          'A44',
          'B10',
          'D27',
          'B1',
          'C26',
          'B24',
          'C21',
          'A45',
          'B55',
          'A11',
          'C50',
          'B54',
          'D19',
          'A16',
          'C46',
          'B22',
          'C6',
          'A36',
          'C42',
          'D31',
          'B3',
          'C20',
          'B59',
          'A35',
          'C28',
          'A42',
          'B46',
          'C13',
          'B48',
          'B17',
          'C14',
          'A56',
          'D57',
          'B24',
          'D34',
          'B28',
          'C12',
          'D22',
          'A27',
          'D59',
          'D8',
          'C44',
          'B16',
          'A18',
          'C38',
          'A29',
          'D18',
          'B30',
          'A5',
          'B48',
          'C10',
          'D32',
          'A38',
          'C34',
          'A24',
          'D58',
          'B38',
          'A51',
          'C32',
          'A54',
          'B46',
          'C53',
          'B2',
          'D51',
          'B7',
          'D46',
          'B36',
          'D44',
          'A41',
          'C16',
          'A50',
          'A26',
          'C8',
          'B34',
          'C23',
          'B12',
          'C20',
          'D22',
          'B52',
          'B9',
          'D9',
          'D50',
          'D31',
          'B45',
          'A7',
          'D60',
          'B32',
          'C33',
          'D20',
          'C43',
          'A52',
          'D54',
          'B31',
          'C36',
          'A43',
          'D25',
          'B43',
          'D57',
          'B27',
          'A14',
          'C48',
          'B11',
          'C25',
          'B51',
          'D44',
          'A31',
          'B22',
          'C14',
          'C56',
          'B7',
          'C54',
          'A13',
          'C47',
          'A29',
          'D59',
          'B23',
          'A16',
          'C44',
          'B20',
          'C30',
          'A54',
          'A33',
          'D14',
          'A2',
          'D4',
          'B42',
          'D48',
          'D31',
          'C32',
          'B27',
          'D36',
          'A34',
          'C38',
          'A36',
          'C23',
          'B55',
          'D17',
          'C14',
          'B32',
          'D51',
          'B34',
          'D32',
          'A60',
          'C11',
          'B56',
          'D41',
          'A14',
          'C18',
          'A56',
          'C12',
          'B4',
          'A9',
          'A53',
          'C16',
          'D11',
          'A57',
          'A15',
          'B36',
          'D30',
          'A12',
          'D4',
          'A38',
          'D50',
          'C6',
          'A55',
          'D35',
          'D20',
          'B52',
          'A41',
          'C38',
          'A48',
          'C27',
          'B31',
          'C21',
          'B55',
          'C17',
          'A22',
          'D15',
          'C45',
          'D41',
          'A43',
          'B32',
          'C12',
          'D29',
          'A26',
          'C39',
          'B14',
          'C15',
          'A53',
          'B49',
          'C60',
          'A50',
          'C58',
          'B19',
          'A55',
          'C1',
          'B21',
          'C11',
          'B35',
          'D14',
          'B3',
          'C35',
          'A19',
          'A59',
          'C3',
          'D2',
          'B4',
          'D50',
          'B40',
          'C46',
          'B53',
          'C25',
          'A48',
          'B51',
          'A25',
          'D37',
          'B22',
          'A20',
          'C26',
          'B41',
          'D10',
          'B54',
          'C35',
          'A52',
          'D17',
          'D60',
          'A23',
          'B13',
          'D49',
          'C60',
          'D43',
          'B38',
          'A1',
          'B18',
          'D58',
          'B5',
          'C29',
          'A58',
          'C28',
          'A46',
          'A8',
          'D21',
          'A33',
          'D25',
          'D40',
          'B24',
          'C4',
          'D24',
          'B45',
          'B22',
          'C8',
          'D3',
          'A32',
          'C24',
          'B8',
          'C15',
          'D4',
        ],
      },
    ],
  },
  {
    title: 'Wolf',
    id: 'wolf',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Wolf%202.jpeg',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C10',
          'B17',
          'C11',
          'B11',
          'C2',
          'B38',
          'C23',
          'A40',
          'C22',
          'B34',
          'C1',
          'C51',
          'B5',
          'D59',
          'B26',
          'D60',
          'A24',
          'D39',
          'B22',
          'D42',
          'A49',
          'D57',
          'A46',
          'D58',
          'B25',
          'D43',
          'A36',
          'C3',
          'B16',
          'C4',
          'A37',
          'C20',
          'A40',
          'C21',
          'B29',
          'D45',
          'B30',
          'C1',
          'D10',
          'A56',
          'D60',
          'A25',
          'D25',
          'A46',
          'D59',
          'A53',
          'D55',
          'A45',
          'D51',
          'A60',
          'C41',
          'B11',
          'D56',
          'A47',
          'C29',
          'A48',
          'D58',
          'B25',
          'C19',
          'A34',
          'C1',
          'A29',
          'B31',
          'C21',
          'A40',
          'C24',
          'B44',
          'C52',
          'B6',
          'D60',
          'B27',
          'D44',
          'B28',
          'C2',
          'B38',
          'C23',
          'B39',
          'C3',
          'B8',
          'D59',
          'A23',
          'D39',
          'B22',
          'D38',
          'B21',
          'D37',
          'A41',
          'C26',
          'B50',
          'C33',
          'B49',
          'C55',
          'B48',
          'A5',
          'D27',
          'A46',
          'D60',
          'C49',
          'D8',
          'C1',
          'D10',
          'A55',
          'D59',
          'A33',
          'C2',
          'B7',
          'C53',
          'B46',
          'C25',
          'A41',
          'C26',
          'B21',
          'D37',
          'A12',
          'D29',
          'A11',
          'D5',
          'C48',
          'D60',
          'A54',
          'D24',
          'B4',
          'C50',
          'C1',
          'B1',
          'C42',
          'B12',
          'C2',
          'A35',
          'C19',
          'A40',
          'D43',
          'B26',
          'D59',
          'A46',
          'C5',
          'B16',
          'C3',
          'B9',
          'C6',
          'B17',
          'C10',
          'A53',
          'D23',
          'B3',
          'D22',
          'A52',
          'C8',
          'B10',
          'D54',
          'A45',
          'D52',
          'B1',
          'C1',
          'D10',
          'A18',
          'D38',
          'A43',
          'D48',
          'A57',
          'D11',
          'C50',
          'B4',
          'C13',
          'B18',
          'C12',
          'A47',
          'C16',
          'B20',
          'D34',
          'B16',
          'C36',
          'B36',
          'C2',
          'D13',
          'C54',
          'B47',
          'A23',
          'D60',
          'A24',
          'D39',
          'A8',
          'B51',
          'C33',
          'B50',
          'C25',
          'A41',
          'C26',
          'B32',
          'C1',
          'C52',
          'B6',
          'C15',
          'B12',
          'D42',
          'B22',
          'D41',
          'A31',
          'B33',
          'C22',
          'B34',
          'A32',
          'C18',
          'A58',
          'C39',
          'B9',
          'C3',
          'A36',
          'C2',
          'B38',
          'C23',
          'B54',
          'A11',
          'D43',
          'A49',
          'C30',
          'B6',
          'C52',
          'C1',
          'D11',
          'A57',
          'C38',
          'B16',
          'C35',
          'B57',
          'A14',
          'D7',
          'A13',
          'D38',
          'B22',
          'C19',
          'B51',
          'C57',
          'D14',
          'C2',
          'B38',
          'C39',
          'A58',
          'D12',
          'A44',
          'D50',
          'A45',
          'D51',
          'B16',
          'D57',
          'B24',
          'C35',
          'B36',
          'D59',
          'A47',
          'D35',
          'B21',
          'D36',
          'A12',
          'D29',
          'A28',
          'C1',
          'C51',
          'B5',
          'D24',
          'A46',
          'D26',
          'A26',
          'D60',
          'B27',
          'C29',
          'B4',
          'D23',
          'A24',
          'D7',
          'A13',
          'B56',
          'A10',
          'D42',
          'A39',
          'C6',
          'B17',
          'C8',
          'B10',
          'C40',
          'A59',
          'C5',
          'A38',
          'D44',
          'B28',
          'C1',
          'D11',
          'C53',
          'B32',
          'A30',
          'D9',
          'A16',
          'B41',
          'C44',
          'D16',
          'A45',
          'D17',
          'A20',
          'D32',
          'A56',
          'D10',
          'A33',
          'D41',
          'A48',
          'D56',
          'B23',
          'C20',
          'B13',
          'C5',
          'B17',
          'C46',
          'B4',
          'C50',
          'C1',
          'B33',
          'D55',
          'A45',
          'D53',
          'A52',
          'C32',
          'B9',
          'C39',
          'A59',
          'C40',
          'B26',
          'C28',
          'A46',
          'C15',
          'B6',
          'D25',
          'C57',
          'B52',
          'C34',
          'B53',
          'C22',
          'B34',
          'C1',
          'A29',
          'C7',
          'B17',
          'C45',
          'B42',
          'C58',
          'B25',
          'C39',
          'B16',
          'C2',
          'B39',
          'C23',
          'A40',
          'C18',
          'A49',
          'D19',
          'A45',
          'D52',
          'A52',
          'D3',
          'C47',
          'D60',
          'A34',
          'D42',
          'B23',
          'D46',
          'B15',
          'C27',
          'B30',
          'A28',
          'D40',
          'A27',
          'C1',
          'D11',
          'C50',
          'B18',
          'C12',
          'B3',
          'D22',
          'B2',
          'D21',
          'A51',
          'D49',
          'A44',
          'B57',
          'B17',
          'C45',
          'D17',
          'A45',
          'D15',
          'C59',
          'B53',
          'C34',
          'B35',
          'C22',
          'B16',
          'C35',
          'B45',
          'A21',
          'D18',
          'C46',
          'B60',
          'A49',
          'C13',
          'B18',
          'C12',
          'A43',
          'D57',
          'B34',
          'C8',
          'B17',
          'C45',
          'B42',
          'A18',
          'B43',
          'C31',
          'A51',
          'D2',
          'A8',
          'A45',
          'A6',
          'B49',
          'C25',
          'B21',
          'D37',
          'A17',
          'D27',
          'A4',
          'B58',
          'A3',
          'B14',
          'C21',
          'B52',
          'C20',
          'A36',
          'D22',
          'A23',
          'D6',
          'D60',
          'D7',
          'C38',
          'B16',
          'D47',
          'A50',
          'D20',
          'A45',
          'A5',
          'B18',
          'D40',
          'A9',
          'D28',
          'A8',
          'B53',
          'C22',
          'A40',
          'C17',
          'A30',
          'C1',
          'D11',
          'A56',
          'D60',
          'B21',
          'D36',
          'A41',
          'C25',
          'B50',
          'C33',
          'B11',
          'C60',
          'D33',
          'A58',
          'C4',
          'A27',
          'D53',
          'B9',
          'C6',
          'B31',
          'C28',
          'D1',
          'A50',
          'D46',
          'B16',
          'C37',
          'B37',
          'C2',
          'C54',
          'B48',
          'D52',
          'A45',
          'A4',
          'D36',
          'B21',
          'C25',
          'A41',
          'D45',
          'B5',
          'C51',
          'C1',
          'D11',
          'A19',
          'D31',
          'A54',
          'D9',
          'A55',
          'D60',
          'B16',
          'C35',
          'B58',
          'B17',
          'C11',
          'A46',
          'D40',
          'A28',
          'D54',
          'A45',
          'D13',
          'A59',
          'C40',
          'B10',
          'D30',
          'A14',
          'D8',
          'C49',
          'B3',
          'D43',
          'B13',
          'A2',
          'D16',
          'A58',
          'D49',
          'B18',
          'C50',
          'C1',
          'B32',
          'D46',
          'A15',
          'C11',
          'B17',
          'C45',
          'B43',
          'C31',
          'B7',
          'C52',
          'B29',
          'C3',
          'A26',
          'C15',
          'B12',
          'A1',
          'B27',
          'D60',
          'A37',
          'C4',
          'A44',
          'C58',
          'D26',
          'A25',
          'B49',
          'B16',
          'D40',
          'B19',
          'C5',
          'A50',
          'C20',
          'B13',
          'D49',
          'B14',
          'C6',
          'A47',
          'D34',
          'A31',
          'C1',
          'D9',
          'C51',
          'D12',
          'A58',
          'C38',
          'B8',
          'C2',
          'B39',
          'C56',
          'B40',
          'C14',
          'B5',
          'C47',
          'B17',
          'C9',
          'A53',
          'D30',
          'A3',
          'A45',
          'D51',
          'B20',
          'C6',
          'A28',
          'C1',
          'D11',
          'C48',
          'D20',
          'A13',
          'A46',
          'A12',
          'C10',
          'A32',
          'D57',
          'B4',
          'D44',
          'B29',
          'A27',
          'D28',
          'B8',
          'C53',
          'C2',
          'C55',
          'B49',
          'D52',
          'B9',
          'C59',
          'B48',
          'C41',
          'A60',
          'C1',
          'B33',
          'A31',
          'D56',
          'A45',
          'D18',
          'A48',
          'B59',
          'C32',
          'A53',
          'D45',
          'B23',
          'C11',
          'B6',
          'D47',
          'A56',
          'C15',
          'A47',
          'C38',
          'B38',
          'A14',
          'B39',
          'C23',
          'B54',
          'B17',
          'C44',
          'B2',
          'C1',
          'D12',
          'A37',
          'D23',
          'B14',
          'D33',
          'A40',
          'C16',
          'A28',
          'A46',
          'D26',
          'B7',
          'C52',
          'B45',
          'C24',
          'B19',
          'D53',
          'B51',
          'B16',
          'C27',
          'B1',
          'C1',
          'A35',
          'D1',
          'B17',
          'C44',
          'B56',
          'D55',
          'B11',
          'D20',
          'A7',
          'B55',
          'A12',
          'D44',
          'B14',
          'D48',
          'A22',
          'D6',
          'B18',
          'D35',
          'A11',
          'A46',
          'C3',
          'A57',
          'C17',
          'B8',
          'C49',
          'B57',
          'A45',
          'A2',
          'B13',
          'C43',
          'B17',
          'C10',
          'B36',
          'A34',
          'C19',
          'A59',
          'D50',
          'A51',
          'D29',
          'A11',
          'D36',
          'A30',
          'C1',
          'D12',
          'A18',
          'C12',
          'A48',
          'C6',
          'A4',
          'B59',
          'C36',
          'B37',
          'D49',
          'B38',
          'A13',
          'D37',
          'B21',
          'D35',
          'B1',
          'D20',
          'A31',
          'C9',
          'A10',
          'D5',
          'C37',
          'B25',
          'C2',
          'B40',
          'C49',
          'B18',
          'D58',
          'B35',
          'A33',
          'D38',
          'A17',
          'D10',
          'C52',
          'C1',
          'B33',
          'B16',
          'D59',
          'A36',
          'D34',
          'A1',
          'A45',
          'D14',
          'C42',
          'A50',
          'D60',
          'D7',
          'C53',
          'B31',
          'D53',
          'A42',
          'C8',
          'B17',
          'B56',
          'A43',
          'C9',
          'A60',
          'C7',
          'A40',
          'C14',
          'A24',
          'D24',
          'B15',
          'D39',
          'B16',
          'B46',
          'A21',
          'D5',
          'C31',
          'B8',
          'C2',
          'C56',
          'A44',
          'C55',
          'D9',
          'C1',
          'D12',
          'A53',
          'D59',
          'B26',
          'C39',
          'A48',
          'D36',
          'B3',
          'D56',
          'B34',
          'C22',
          'A46',
          'C14',
          'A25',
          'D60',
          'B29',
          'C3',
          'A54',
          'D13',
          'A5',
          'B48',
          'C17',
          'B21',
          'C25',
          'B31',
          'C5',
          'A28',
          'D1',
          'D58',
          'B5',
          'C51',
          'D14',
          'C2',
          'A41',
          'C24',
          'B49',
          'C43',
          'B17',
          'C7',
          'A6',
          'B52',
          'C28',
          'B16',
          'B44',
          'A20',
          'D4',
          'B18',
          'D28',
          'A18',
          'A46',
          'D23',
          'A36',
          'D2',
          'C29',
          'B28',
          'C20',
          'A51',
          'C43',
          'D15',
          'A12',
          'D6',
          'C52',
          'B30',
          'D45',
          'A50',
          'C30',
          'B42',
          'D54',
          'B53',
          'C34',
          'B16',
          'A4',
          'D26',
          'A56',
          'C37',
          'B7',
          'C16',
          'B47',
          'A22',
          'C13',
          'A44',
          'C54',
          'D13',
          'A59',
          'D17',
          'C2',
          'B13',
          'D42',
          'A43',
          'C33',
          'B60',
          'B17',
          'B55',
          'C24',
          'A46',
          'A10',
          'D41',
          'B20',
          'A7',
          'D38',
          'A19',
          'C9',
          'B10',
          'C32',
          'B23',
          'C1',
          'C53',
          'A44',
          'C27',
          'B31',
          'A29',
          'D55',
          'B12',
          'C14',
          'A56',
          'A41',
          'D56',
          'B57',
          'A2',
          'D29',
          'D14',
          'A42',
          'D46',
          'B24',
          'C57',
          'D31',
          'A55',
          'D25',
          'B5',
          'C47',
          'B50',
          'A7',
          'B21',
          'C6',
          'A60',
          'C41',
          'B11',
          'D41',
          'A49',
          'D60',
          'C46',
          'D1',
          'A13',
          'C10',
          'A41',
          'B55',
          'A6',
          'D27',
          'A26',
          'B28',
          'A1',
          'D2',
          'D58',
          'B35',
          'C22',
          'A52',
          'C8',
          'A30',
          'D41',
          'A9',
          'D3',
          'A53',
          'D8',
          'B18',
          'D48',
          'B36',
          'C2',
          'C58',
          'B53',
          'D54',
          'B54',
          'B39',
          'C40',
          'D12',
          'D34',
          'D19',
          'B10',
          'C4',
          'B17',
          'C42',
          'A51',
          'D60',
          'A38',
          'C15',
          'B41',
          'C57',
          'C3',
          'B50',
          'C33',
          'B16',
          'B48',
          'A23',
          'A46',
          'A17',
          'A47',
          'A16',
          'D47',
          'B20',
          'D32',
          'B13',
          'D22',
          'A52',
          'D30',
          'A15',
          'D8',
          'A3',
          'B15',
          'C46',
          'A55',
          'D45',
          'B31',
          'C1',
          'A29',
          'D33',
          'A46',
          'C2',
          'D15',
          'A57',
          'D52',
          'A40',
          'C21',
          'B53',
          'D9',
          'A31',
          'D37',
          'A41',
          'C17',
          'D47',
          'A21',
          'C11',
          'A14',
          'D35',
          'A2',
          'C5',
          'A56',
          'C35',
          'B45',
          'D51',
          'B46',
          'D58',
          'D3',
          'A37',
          'D4',
          'C30',
          'B16',
          'B37',
          'A12',
          'C57',
          'B42',
          'A17',
          'D13',
          'D33',
          'A9',
          'C9',
          'B35',
          'A10',
          'A46',
          'C37',
          'B19',
          'C20',
          'B29',
          'C41',
          'C26',
          'B51',
          'C4',
          'B27',
          'C60',
          'C2',
          'A25',
          'B50',
          'C56',
          'D14',
          'A60',
          'D34',
          'B21',
          'D54',
          'B43',
          'D50',
          'B8',
          'C49',
          'C1',
          'B33',
          'C29',
          'B4',
          'C50',
          'B25',
          'B10',
          'C59',
          'C4',
          'B30',
          'C41',
          'B11',
          'C12',
          'A54',
          'D57',
          'B16',
          'C27',
          'B60',
          'D59',
          'B36',
          'A33',
          'C18',
          'A39',
          'D30',
          'D15',
          'C52',
          'A44',
          'D39',
          'B24',
          'C8',
          'A8',
          'A23',
          'C13',
          'A46',
          'C7',
          'A51',
          'D48',
          'A17',
          'B60',
          'A6',
          'D18',
          'C2',
          'B41',
          'B16',
          'C22',
          'C45',
          'C25',
          'B31',
          'C42',
          'B49',
          'C18',
          'B50',
          'D11',
          'D37',
          'D17',
          'B8',
          'C38',
          'A58',
          'A40',
          'B55',
          'C11',
          'A47',
          'B59',
          'B17',
          'C9',
          'B20',
          'C5',
          'B14',
          'C26',
          'A59',
          'D38',
          'A16',
          'D26',
          'A44',
          'C51',
          'B6',
          'D27',
          'A38',
          'D54',
          'B32',
          'C7',
          'A17',
          'B45',
          'D57',
          'B60',
          'C32',
          'A54',
          'D8',
          'D60',
          'A52',
          'C44',
          'C29',
          'B22',
          'A7',
          'A25',
          'C54',
          'B59',
          'A33',
          'D37',
          'D22',
          'C50',
          'A44',
          'C34',
          'B13',
          'C19',
          'A35',
          'D33',
          'D18',
          'A60',
          'C1',
          'A32',
          'D10',
          'B18',
          'D5',
          'C3',
          'B26',
          'A24',
          'C57',
          'D11',
          'D36',
          'D52',
          'B40',
          'D49',
          'B16',
          'D55',
          'A30',
          'D20',
          'C2',
          'B14',
          'D29',
          'A46',
          'C17',
          'B9',
          'C54',
          'D9',
          'C53',
          'B32',
          'B48',
          'C27',
          'C49',
          'B45',
          'C14',
          'A41',
          'B3',
          'C45',
          'D58',
          'B7',
          'C48',
          'A44',
          'D5',
          'A20',
          'D46',
          'B20',
          'D30',
          'A2',
          'B37',
          'C37',
          'D6',
          'B50',
          'C2',
          'B39',
          'A37',
          'A19',
          'B44',
          'C50',
          'C21',
          'A60',
          'D54',
          'B10',
          'C7',
          'B53',
          'B17',
          'C42',
          'B12',
          'D41',
          'A32',
          'D35',
          'A46',
          'D52',
          'B49',
          'C41',
          'A49',
          'C34',
          'B54',
          'A42',
          'C3',
          'D6',
          'B38',
          'C55',
          'A13',
          'D19',
          'C47',
          'D59',
          'A11',
          'D42',
          'D15',
          'D41',
          'B22',
          'D55',
          'A53',
          'D4',
          'C43',
          'D60',
          'B6',
          'C36',
          'B46',
          'C24',
          'A47',
          'A15',
          'C54',
          'A27',
          'C8',
          'A59',
          'C5',
          'D17',
          'A19',
          'A39',
          'A18',
          'B58',
          'C26',
          'B30',
          'D52',
          'B17',
          'C7',
          'B19',
          'D50',
          'A31',
          'C53',
          'A17',
          'C12',
          'B8',
          'B29',
          'A3',
          'B46',
          'D51',
          'D14',
          'D45',
          'C19',
          'A36',
          'B4',
          'D43',
          'B11',
          'D31',
          'B54',
          'D54',
          'A41',
          'C9',
          'A54',
          'C36',
          'A55',
          'C10',
          'A28',
          'D8',
          'B36',
          'D55',
          'A8',
          'A46',
          'D32',
          'C58',
          'B9',
          'C50',
          'C25',
          'B45',
          'B14',
          'D56',
          'B58',
          'A15',
          'C5',
          'A47',
          'D16',
          'A45',
          'D21',
          'B12',
          'A1',
          'B34',
          'C1',
          'B2',
          'C28',
          'B26',
          'C19',
          'B16',
          'D25',
          'D41',
          'D17',
          'A28',
          'C54',
          'B3',
          'C49',
          'C34',
          'A56',
          'D14',
          'D44',
          'C21',
          'A50',
          'D58',
          'A32',
          'C18',
          'A42',
          'C16',
          'B42',
          'D53',
          'B10',
          'C32',
          'B16',
          'D11',
          'B35',
          'D47',
          'D12',
          'C1',
          'A28',
          'C53',
          'B46',
          'C15',
          'C46',
          'A44',
          'C33',
          'B34',
          'D52',
          'B18',
          'D7',
          'B37',
          'B57',
          'A25',
          'A4',
          'D17',
          'D32',
          'A57',
          'C16',
          'A27',
          'D40',
          'B14',
          'C44',
          'A53',
          'D23',
          'B4',
          'C13',
          'B9',
          'D18',
          'A22',
          'D59',
          'B19',
          'D48',
          'C15',
          'C32',
          'B46',
          'D7',
          'C37',
          'B16',
          'B31',
          'C41',
          'B54',
          'C9',
          'B3',
          'C28',
          'A46',
          'B58',
          'D38',
          'B60',
          'D35',
          'A21',
          'C59',
          'C2',
          'B17',
          'B43',
          'C51',
          'C20',
          'B42',
          'A40',
          'A18',
          'D41',
          'B2',
          'D53',
          'A45',
          'C60',
          'D58',
          'A52',
          'D44',
          'B20',
          'A6',
          'D3',
          'B18',
          'D13',
          'A22',
          'D39',
          'B55',
          'A30',
          'B32',
          'C6',
          'A51',
          'C19',
          'C52',
          'A32',
          'B4',
          'C46',
          'C21',
          'C37',
          'B38',
          'D48',
          'A60',
          'C20',
          'B51',
          'D53',
          'A7',
          'C8',
          'B10',
          'C2',
          'C57',
          'A26',
          'C56',
          'C3',
          'A57',
          'D11',
          'D49',
          'A51',
          'D21',
          'A14',
          'D3',
          'D35',
          'D20',
          'B12',
          'D57',
          'A1',
          'B44',
          'C34',
          'D2',
          'C7',
          'A41',
          'C27',
          'B49',
          'B26',
          'D43',
          'D14',
          'B16',
          'B50',
          'C25',
          'B4',
          'D21',
          'C49',
          'A28',
          'C46',
          'B35',
          'D9',
          'C1',
          'D13',
          'D47',
          'B22',
          'D40',
          'B54',
          'C30',
          'A49',
          'C23',
          'B17',
          'C41',
          'A50',
          'C14',
          'B5',
          'D44',
          'A41',
          'D14',
          'B34',
          'C54',
          'A48',
          'C33',
          'D55',
          'B1',
          'C10',
          'B55',
          'C2',
          'B18',
          'D9',
          'A17',
          'A40',
          'A14',
          'D45',
          'A19',
          'B47',
          'C32',
          'B48',
          'D60',
          'B59',
          'C26',
          'B15',
          'D33',
          'A34',
          'D58',
          'A10',
          'D28',
          'A49',
          'C53',
          'B8',
          'B34',
          'D12',
          'C51',
          'B11',
          'C3',
          'B12',
          'D31',
          'A47',
          'C55',
          'D24',
          'A37',
          'C14',
          'C47',
          'B37',
          'B22',
          'B42',
          'C2',
          'B29',
          'A26',
          'A3',
          'D26',
          'D41',
          'D18',
          'D40',
          'B25',
          'C37',
          'B20',
          'D59',
          'C44',
          'A44',
          'C43',
          'B13',
          'C3',
          'A49',
          'C17',
          'B26',
          'C39',
          'A59',
          'A33',
          'C52',
          'B7',
          'C36',
          'A46',
          'C16',
          'D9',
          'B43',
          'C2',
          'B57',
          'D39',
          'A45',
          'A4',
          'B30',
          'C49',
          'C13',
          'B39',
          'D5',
          'D59',
          'D1',
          'D57',
          'D41',
          'C22',
          'C42',
          'B40',
          'A15',
          'C4',
          'A55',
          'D14',
          'A9',
          'B54',
          'C3',
          'A37',
          'D46',
          'D13',
          'C41',
          'A60',
          'D9',
          'B16',
          'C36',
          'B37',
          'A35',
          'C52',
          'A49',
          'C7',
          'A5',
          'D49',
          'D12',
          'D52',
          'B21',
          'D48',
          'B1',
          'D19',
          'D34',
          'A59',
          'C12',
          'B31',
          'D17',
          'C45',
          'A29',
          'C6',
          'B52',
          'A27',
          'C56',
          'A46',
          'D25',
          'B9',
          'C39',
          'C23',
          'B39',
          'D3',
          'C47',
          'D10',
          'C14',
          'B19',
          'A8',
          'C26',
          'B32',
          'C30',
          'B6',
          'A32',
          'C15',
          'C43',
          'A29',
          'D40',
          'B2',
          'D35',
          'A30',
          'C54',
          'B10',
          'C50',
          'C12',
          'B56',
          'C35',
          'B46',
          'C51',
          'B58',
          'C2',
          'D21',
          'A33',
          'C11',
          'B11',
          'C33',
          'A55',
          'C13',
          'B44',
          'D55',
          'B23',
          'C31',
          'B16',
          'C26',
          'B40',
          'D1',
          'D38',
          'D15',
          'B33',
          'C7',
          'D4',
          'C6',
          'D5',
          'A38',
          'A58',
          'D49',
          'A18',
          'A1',
          'B49',
          'C24',
          'A48',
          'C60',
          'A25',
          'C59',
          'A45',
          'A14',
          'B42',
          'B57',
          'A24',
          'C13',
          'A30',
          'B33',
          'D46',
          'D13',
          'D52',
          'D23',
          'B7',
          'A31',
          'C43',
          'A52',
          'C15',
          'C40',
          'A60',
          'B30',
          'B6',
          'D59',
          'B13',
          'D41',
          'B53',
          'C48',
          'B17',
          'D39',
          'A34',
          'B60',
          'C9',
          'A11',
          'D6',
          'C32',
          'D43',
          'D14',
          'D36',
          'D15',
          'A25',
          'D51',
          'A55',
          'D46',
          'B22',
          'C49',
          'D9',
          'C6',
          'B9',
          'B27',
          'C28',
          'B33',
          'A3',
          'D56',
          'B15',
          'D50',
          'B28',
          'C24',
          'B56',
          'A42',
          'D57',
          'B34',
          'D10',
          'B14',
          'C58',
          'A45',
          'C43',
          'B36',
          'C19',
          'B51',
          'D42',
          'A20',
          'B45',
          'C12',
          'D49',
          'C53',
          'B2',
          'A33',
          'C42',
          'A58',
          'A37',
          'C10',
          'B5',
          'C34',
          'B30',
          'D19',
          'D34',
          'A12',
          'D1',
          'C60',
          'D28',
          'A57',
          'C11',
          'C51',
          'A49',
          'C16',
          'C39',
          'D14',
          'B1',
          'C7',
          'B41',
          'C19',
          'D8',
          'C52',
          'B29',
          'B2',
          'C23',
          'B18',
          'D27',
          'B8',
          'D58',
          'A22',
          'A5',
          'A23',
          'A1',
          'B35',
          'C22',
          'A44',
          'D50',
          'D9',
          'C15',
          'A27',
          'C56',
          'D30',
          'B7',
          'D16',
          'B32',
          'A59',
          'A37',
          'C52',
          'D53',
          'D11',
          'A51',
          'C2',
          'D22',
          'A9',
          'B56',
          'C3',
          'D7',
          'A52',
          'C31',
          'C16',
          'B8',
          'A30',
          'B60',
          'B45',
          'B16',
          'D54',
          'C40',
          'A49',
          'C50',
          'A27',
          'D1',
          'C8',
          'A18',
          'B20',
          'C58',
          'D19',
          'C5',
          'B52',
          'C20',
          'C47',
          'A43',
          'C45',
          'C28',
          'B28',
          'D44',
          'B23',
          'C49',
          'D42',
          'B1',
          'C30',
          'B17',
          'B57',
          'A14',
          'D38',
          'D2',
          'D37',
          'D22',
          'A37',
          'D34',
          'A46',
          'C31',
          'B58',
          'C45',
          'B34',
          'D56',
          'A54',
          'D50',
          'A59',
          'A10',
          'C25',
          'A58',
          'C11',
          'B37',
          'D10',
          'C6',
          'B15',
          'C46',
          'A55',
          'C32',
          'D57',
          'A33',
          'D28',
          'B13',
          'D11',
          'A35',
          'C13',
          'C48',
          'B44',
          'C21',
          'A52',
          'D53',
          'A36',
          'B38',
          'D9',
          'C19',
          'D46',
          'C54',
          'A48',
          'C53',
          'B3',
          'C1',
          'C51',
          'B6',
          'D48',
          'D11',
          'B18',
          'D17',
          'D36',
          'A3',
          'A45',
          'C35',
          'A50',
          'C13',
          'B32',
          'A7',
          'A40',
          'A5',
          'B60',
          'C20',
          'D7',
          'B16',
          'D31',
          'A39',
          'C1',
          'D10',
          'D54',
          'B11',
          'D40',
          'A8',
          'C5',
          'C60',
          'B49',
          'B22',
          'B54',
          'C22',
          'A49',
          'D59',
          'B27',
          'C51',
          'D41',
          'D26',
          'B16',
          'D48',
          'D12',
          'A58',
          'C17',
          'B43',
          'C58',
          'A22',
          'D20',
          'A50',
          'D57',
          'B46',
          'A17',
          'B53',
          'B24',
          'C23',
          'B40',
          'C41',
          'C14',
          'C44',
          'A28',
          'B30',
          'C21',
          'B31',
          'A60',
          'D38',
          'B14',
          'C43',
          'A32',
          'D36',
          'A22',
          'D44',
          'D16',
          'A1',
          'D59',
          'C57',
          'A45',
          'C42',
          'B55',
          'B21',
          'A6',
          'C27',
          'B51',
          'C56',
          'B41',
          'D52',
          'A18',
          'D14',
          'D29',
          'B7',
          'C30',
          'B19',
          'D36',
          'B43',
          'D5',
          'C45',
          'B35',
          'D54',
          'B36',
          'A3',
          'B60',
          'A49',
          'C15',
          'B13',
          'A2',
          'D57',
          'B6',
          'D45',
          'D13',
          'B33',
          'A58',
          'D53',
          'C41',
          'D42',
          'B52',
          'C27',
          'A53',
          'C37',
          'B48',
          'A12',
          'A40',
          'A4',
          'A59',
          'C11',
          'B2',
          'D49',
          'B41',
          'C6',
          'B17',
          'D47',
          'B10',
          'C40',
          'D60',
          'A21',
          'B23',
          'C31',
          'D17',
          'B19',
          'A41',
          'B27',
          'C10',
          'B59',
          'D39',
          'B13',
          'C59',
          'A26',
          'D43',
          'D16',
          'C41',
          'A33',
          'C16',
          'B58',
          'D56',
          'A42',
          'D4',
          'C51',
          'A50',
          'C50',
          'B33',
          'C27',
          'B5',
          'D37',
          'D22',
          'C47',
          'A27',
          'C55',
          'A47',
          'C4',
          'B28',
          'D21',
          'B13',
          'C34',
          'D42',
          'A49',
          'D58',
          'B4',
          'D51',
          'B47',
          'A44',
          'C10',
          'C49',
          'B3',
          'B26',
          'C25',
          'D43',
          'B49',
          'D5',
          'A2',
          'B60',
          'C56',
          'A46',
          'C9',
          'D3',
          'C8',
          'B2',
          'D33',
          'A30',
          'C17',
          'C38',
          'B11',
          'D30',
          'A13',
          'C2',
          'B44',
          'D32',
          'C45',
          'C13',
          'A56',
          'D46',
          'A38',
          'C4',
          'B40',
          'B23',
          'D38',
          'D5',
          'C48',
          'B7',
          'D49',
          'A30',
          'C44',
          'B33',
          'D11',
          'D53',
          'A9',
          'A39',
          'C24',
          'B29',
          'D20',
          'B18',
          'D2',
          'D57',
          'B52',
          'A18',
          'C25',
          'B27',
          'A37',
          'A22',
          'B41',
          'A3',
          'C28',
          'B22',
          'C10',
          'B37',
          'C43',
          'A55',
          'C1',
          'C54',
          'A54',
          'C35',
          'B57',
          'A32',
          'D13',
          'A44',
          'D3',
          'C38',
          'B23',
          'A10',
          'B53',
          'B38',
          'A6',
          'D51',
          'D24',
          'D49',
          'A23',
          'B25',
          'C36',
          'B12',
          'D44',
          'C20',
          'B1',
          'A37',
          'C12',
          'A30',
          'C53',
          'A31',
          'D26',
          'B8',
          'A28',
          'D30',
          'A16',
          'B47',
          'C52',
          'D40',
          'B56',
          'A7',
          'C7',
          'D8',
          'C17',
          'B35',
          'C33',
          'B12',
          'D12',
          'C50',
          'A51',
          'D5',
          'D35',
          'A10',
          'B2',
          'D21',
          'D54',
          'C34',
          'D30',
          'A59',
          'C42',
          'D41',
          'C35',
          'D3',
          'C29',
          'B16',
          'D18',
          'B30',
          'C3',
          'D8',
          'B39',
          'A13',
          'A58',
          'A11',
          'D15',
          'C1',
          'B32',
          'C21',
          'D6',
          'C5',
          'D20',
          'C53',
          'B14',
          'D22',
          'D54',
          'A8',
          'B2',
          'A4',
          'D55',
          'B13',
          'C14',
          'C48',
          'B29',
          'C35',
          'B6',
          'B57',
          'C49',
          'C12',
          'B56',
          'B17',
          'C29',
          'A42',
          'D40',
          'A57',
          'A15',
          'B18',
          'C59',
          'B26',
          'C15',
          'A53',
          'D44',
          'B48',
          'C26',
          'A9',
          'D4',
          'A54',
          'D59',
          'A33',
          'C39',
          'A48',
          'C19',
          'B42',
          'D52',
          'B6',
          'C11',
          'B45',
          'C2',
          'D23',
          'C48',
          'B20',
          'D29',
          'A50',
          'C4',
          'B10',
          'C50',
          'C9',
          'B39',
          'C40',
          'B47',
          'C36',
          'D1',
          'C10',
          'A20',
          'A45',
          'A2',
          'A47',
          'A14',
          'A57',
          'B37',
          'A34',
          'D22',
          'D37',
          'A5',
          'C28',
          'B29',
          'C11',
          'B58',
          'B34',
          'C50',
          'B4',
          'C46',
          'B5',
          'A35',
          'C8',
          'C32',
          'B15',
          'C45',
          'B17',
          'D41',
          'B53',
          'C40',
          'D13',
          'A39',
          'C52',
          'A48',
          'C18',
          'C34',
          'B31',
          'D14',
          'D29',
          'C35',
          'B9',
          'C55',
          'A47',
          'D60',
          'D8',
          'D38',
          'D9',
          'B14',
          'D47',
          'B36',
          'D11',
          'B2',
          'A3',
          'A40',
          'C20',
          'B37',
          'C29',
          'B18',
          'C4',
          'D4',
          'B16',
          'D45',
          'A13',
          'D21',
          'A35',
          'C2',
          'B47',
          'D59',
          'D43',
          'B50',
          'C33',
          'D42',
          'C26',
          'A17',
          'D28',
          'B7',
          'D48',
          'B12',
          'D27',
          'A50',
          'C16',
          'B42',
          'C8',
          'C51',
          'A59',
          'A9',
          'C4',
          'A27',
          'B9',
          'C46',
          'B46',
          'A57',
          'C54',
          'A58',
          'C6',
          'A3',
          'D31',
          'B19',
          'D16',
          'A20',
          'C13',
          'B44',
          'B21',
          'C42',
          'B27',
          'C41',
          'B1',
          'C1',
          'A46',
          'C21',
          'A11',
          'B48',
          'D55',
          'B4',
          'C12',
          'B38',
          'C17',
          'B49',
          'A24',
          'C57',
          'B24',
          'D45',
          'D13',
          'A27',
          'C7',
          'D3',
          'B43',
          'A41',
          'B54',
          'D41',
          'C50',
          'B36',
          'A58',
          'C4',
          'D6',
          'A2',
          'C29',
          'D17',
          'A47',
          'D33',
          'A25',
          'D7',
          'B41',
          'C27',
          'A7',
          'B35',
          'D57',
          'D20',
          'D56',
          'B2',
          'C52',
          'B30',
          'C24',
          'C9',
          'A55',
          'A16',
          'A39',
          'D51',
          'B6',
          'D38',
          'B42',
          'C7',
          'A30',
          'C1',
          'B7',
          'B55',
          'C25',
          'B21',
          'A2',
          'C35',
          'B24',
          'C2',
          'D6',
          'C40',
          'B17',
          'B52',
          'D42',
          'B16',
          'D24',
          'A25',
          'B27',
          'D22',
          'D37',
          'A16',
          'C11',
          'B60',
          'A41',
          'A56',
          'C43',
          'A57',
          'C37',
          'A33',
          'D32',
          'C44',
          'D5',
          'A22',
          'A37',
          'A21',
          'D38',
          'B16',
          'C18',
          'B10',
          'D60',
          'A17',
          'D50',
          'D10',
          'C1',
          'B33',
          'A8',
          'C22',
          'D43',
          'B12',
          'C52',
          'A40',
          'D48',
          'D12',
          'B31',
          'C37',
          'D40',
          'B58',
          'B2',
          'C30',
          'A1',
          'D26',
          'A51',
          'C14',
          'B57',
          'C59',
          'B28',
          'A36',
          'C53',
          'D39',
          'A14',
          'C20',
          'A44',
          'D49',
          'A60',
          'A9',
          'B23',
          'B54',
          'B8',
          'D24',
          'B26',
          'A42',
          'D25',
          'A52',
          'D2',
          'C10',
          'B17',
          'D18',
          'B10',
          'C7',
          'C40',
          'B48',
          'B27',
          'D55',
          'B50',
          'C47',
          'A49',
          'D47',
          'B19',
          'D13',
          'D38',
          'A37',
          'A19',
          'A45',
          'C57',
          'D52',
          'A52',
          'D12',
          'B32',
          'C29',
          'A48',
          'D60',
          'B11',
          'C60',
          'A40',
          'C13',
          'A58',
          'A11',
          'D43',
          'B27',
          'B42',
          'A3',
          'C4',
          'B21',
          'B50',
          'C8',
          'C39',
          'B39',
          'D51',
          'A33',
          'C15',
          'B3',
          'A8',
          'B4',
          'C9',
          'D5',
          'C6',
          'A12',
          'A57',
          'D35',
          'D15',
          'B30',
          'C48',
          'B38',
          'D39',
          'B59',
          'A16',
          'A56',
          'C24',
          'B1',
          'A9',
          'C21',
          'A13',
          'A47',
          'C54',
          'D10',
          'B54',
          'A29',
          'C56',
          'D16',
          'D31',
          'C46',
          'C12',
          'B57',
          'C25',
          'B43',
          'C49',
          'C32',
          'A42',
          'C11',
          'A54',
          'D44',
          'B29',
          'D19',
          'B11',
          'D48',
          'D25',
          'A14',
          'C3',
          'D9',
          'D55',
          'A20',
          'D43',
          'B51',
          'C33',
          'D7',
          'A40',
          'C2',
          'A56',
          'C47',
          'B44',
          'D50',
          'B4',
          'B20',
          'C52',
          'B13',
          'B58',
          'B5',
          'D21',
          'B16',
          'D34',
          'B1',
          'C13',
          'C34',
          'B14',
          'D55',
          'C51',
          'A48',
          'C17',
          'A29',
          'C55',
          'C3',
          'B39',
          'A6',
          'D36',
          'A35',
          'D58',
          'B12',
          'C42',
          'C6',
          'D18',
          'B18',
          'C38',
          'B49',
          'C2',
          'A32',
          'C53',
          'B3',
          'D54',
          'D10',
          'B36',
          'C49',
          'A51',
          'D59',
          'D6',
          'B22',
          'C7',
          'A50',
          'C26',
          'A43',
          'A17',
          'A48',
          'D37',
          'B11',
          'C10',
          'B2',
          'D32',
          'A47',
          'C53',
          'B9',
          'C38',
          'D39',
          'C43',
          'B48',
          'B23',
          'D47',
          'D12',
          'A16',
          'C60',
          'B44',
          'C18',
          'B59',
          'C46',
          'D10',
          'D50',
          'C52',
          'D16',
          'B29',
          'C5',
          'B32',
          'C28',
          'A49',
          'D4',
          'A28',
          'B56',
          'B20',
          'A8',
          'B6',
          'C47',
          'C11',
          'B24',
          'C50',
          'B25',
          'B52',
          'D53',
          'B16',
          'A4',
          'D3',
          'A33',
          'C35',
          'B58',
          'C59',
          'A40',
          'C51',
          'D23',
          'B3',
          'D42',
          'C29',
          'C14',
          'B22',
          'D1',
          'C41',
          'A57',
          'C15',
          'B7',
          'C23',
          'B40',
          'C9',
          'D7',
          'C18',
          'D46',
          'B8',
          'A8',
          'A39',
          'A15',
          'D22',
          'D37',
          'B42',
          'D33',
          'B21',
          'C37',
          'D20',
          'D40',
          'C36',
          'D28',
          'A50',
          'C24',
          'B3',
          'A3',
          'B57',
          'D60',
          'B37',
          'C35',
          'B12',
          'B59',
          'D56',
          'A9',
          'B2',
          'C48',
          'A48',
          'C33',
          'D1',
          'C22',
          'B35',
          'A4',
          'A23',
          'D14',
          'B5',
          'C44',
          'D38',
          'D13',
          'B11',
          'C4',
          'B31',
          'A28',
          'C9',
          'B25',
          'C27',
          'A24',
          'B58',
          'B43',
          'C48',
          'B39',
          'D55',
          'D9',
          'C42',
          'A46',
          'C56',
          'D22',
          'D53',
          'D11',
          'D50',
          'C35',
          'C12',
          'B8',
          'C58',
          'A26',
          'D19',
          'B16',
          'B53',
          'C29',
          'A60',
          'C26',
          'B18',
          'C8',
          'D7',
          'C2',
          'B50',
          'D58',
          'B15',
          'C31',
          'B30',
          'D13',
          'A40',
          'C57',
          'A45',
          'A14',
          'A57',
          'A12',
          'D29',
          'D48',
          'A37',
          'A18',
          'D44',
          'A47',
          'C52',
          'A54',
          'D24',
          'A39',
          'A6',
          'C2',
          'A52',
          'D8',
          'B15',
          'C21',
          'B3',
          'D20',
          'D55',
          'B33',
          'B48',
          'C58',
          'D11',
          'B42',
          'D7',
          'A58',
          'A10',
          'D52',
          'A26',
          'B56',
          'B6',
          'C44',
          'D19',
          'C25',
          'A12',
          'A47',
          'C38',
          'B13',
          'C42',
          'B32',
          'C3',
          'A43',
          'A15',
          'D16',
          'C32',
          'D45',
          'A44',
          'B25',
          'C23',
          'B55',
          'D41',
          'D26',
          'A38',
          'A21',
          'D6',
          'C48',
          'B57',
          'B3',
          'C52',
          'B35',
          'D53',
          'C35',
          'A6',
          'B22',
          'C42',
          'D27',
          'A57',
          'C49',
          'B8',
          'B52',
          'A39',
          'C17',
          'B21',
          'C12',
          'B59',
          'B1',
          'C54',
          'A29',
          'D54',
          'D10',
          'A4',
          'A60',
          'C31',
          'D58',
          'D18',
          'C45',
          'A54',
          'A17',
          'B58',
          'A11',
          'D34',
          'D19',
          'B6',
          'B35',
          'C50',
          'B41',
          'D39',
          'B1',
          'C22',
          'B43',
          'C20',
          'B19',
          'C51',
          'B5',
          'C13',
          'B46',
          'A29',
          'D27',
          'C37',
          'B10',
          'D40',
          'C43',
          'B20',
          'D57',
          'B24',
          'D17',
          'B27',
          'C19',
          'D19',
          'A49',
          'C46',
          'B47',
          'D58',
          'B51',
          'C47',
          'B8',
          'D32',
          'D48',
          'C42',
          'C16',
          'A54',
          'C51',
          'B6',
          'C24',
          'A51',
          'C33',
          'D56',
          'A53',
          'C55',
          'A46',
          'A19',
          'C60',
          'A23',
          'D39',
          'B60',
          'C11',
          'D1',
          'D51',
          'A56',
          'A16',
          'C6',
          'B46',
          'C50',
          'C7',
          'B23',
          'D20',
          'C44',
          'B15',
          'C30',
          'B20',
          'A34',
          'D14',
          'C57',
          'A26',
          'C25',
          'A47',
          'D52',
          'D23',
          'C1',
          'B34',
          'A32',
          'C42',
          'D15',
          'C40',
          'A60',
          'B45',
          'B27',
          'D54',
          'A43',
          'D11',
          'D50',
          'B39',
          'C59',
          'B29',
          'A35',
          'B19',
          'C18',
          'B50',
          'C43',
          'B41',
          'C23',
          'B28',
          'C49',
          'B9',
          'C5',
          'D21',
          'C19',
          'C3',
          'A26',
          'D28',
          'D52',
          'B35',
          'D12',
          'D47',
          'B12',
          'C33',
          'D32',
          'A8',
          'B34',
          'D55',
          'A42',
          'C28',
          'D17',
          'A27',
          'D8',
          'B37',
          'D4',
          'C10',
          'B15',
          'C9',
          'B26',
          'D41',
          'B18',
          'C23',
          'A51',
          'D58',
          'C58',
          'A44',
          'D7',
          'D51',
          'A60',
          'A11',
          'A37',
          'D25',
          'C56',
          'A40',
          'B27',
          'C51',
          'B11',
          'C37',
          'B33',
          'C26',
          'B51',
          'B20',
          'C8',
          'B21',
          'D32',
          'A24',
          'D60',
          'D8',
          'C7',
          'C25',
          'B14',
          'B57',
          'D57',
          'C41',
          'D2',
          'B43',
          'C39',
          'A35',
          'C35',
          'D20',
          'A14',
          'B29',
          'C40',
          'B10',
          'C46',
          'D6',
          'C20',
          'D17',
          'B28',
          'C8',
          'B51',
          'D46',
          'D24',
          'D44',
          'C41',
          'C26',
          'A25',
          'B60',
          'A60',
          'C55',
          'B4',
          'C54',
          'B2',
          'D45',
          'B22',
          'C29',
          'B42',
          'C50',
          'A54',
          'D5',
          'C22',
          'B20',
          'D54',
          'A5',
          'A58',
          'C46',
          'D37',
          'A52',
          'C56',
          'A56',
          'A15',
          'D10',
          'A50',
          'D1',
          'A6',
          'B40',
          'C31',
          'B16',
          'D56',
          'D8',
          'D51',
          'A51',
          'C16',
          'B47',
          'C24',
          'B9',
          'C13',
          'B54',
          'B3',
          'A2',
          'B38',
          'C52',
          'B7',
          'D59',
          'C41',
          'D23',
          'B26',
          'B51',
          'C2',
          'A4',
          'B17',
          'C44',
          'A31',
          'B34',
          'A18',
          'A37',
          'A10',
          'A47',
          'C32',
          'D3',
          'A23',
          'C59',
          'A41',
          'D21',
          'D50',
          'D22',
          'D37',
          'B12',
          'C51',
          'D5',
          'B23',
          'D18',
          'C20',
          'B40',
          'D40',
          'A9',
          'C2',
          'B19',
          'C36',
          'B13',
          'C33',
          'C4',
          'B41',
          'C15',
          'B47',
          'A13',
          'D2',
          'A45',
          'C27',
          'A49',
          'C11',
          'D6',
          'C10',
          'B38',
          'C30',
          'D4',
          'A6',
          'A39',
          'A1',
          'C3',
          'B15',
          'C53',
          'B4',
          'B59',
          'C45',
          'B60',
          'B45',
          'A30',
          'D26',
          'D41',
          'B57',
          'D55',
          'D20',
          'C59',
          'A43',
          'B18',
          'C31',
          'C5',
          'B26',
          'A51',
          'D43',
          'B14',
          'C20',
          'B23',
          'D42',
          'A33',
          'C12',
          'A40',
          'B56',
          'D58',
          'D19',
          'A41',
          'D59',
          'B44',
          'B29',
          'C47',
          'B9',
          'A8',
          'D21',
          'C18',
          'B25',
          'C16',
          'A60',
          'C6',
          'B49',
          'D52',
          'B50',
          'C28',
          'A22',
          'D31',
          'B2',
          'C22',
          'A36',
          'C34',
          'B36',
          'C46',
          'B16',
          'A52',
          'C7',
          'D9',
          'D55',
          'A11',
          'B1',
          'D33',
          'C44',
          'B39',
          'C55',
          'A56',
          'C23',
          'B48',
          'A55',
          'D59',
          'B55',
          'A27',
          'D27',
          'B17',
          'D26',
          'D41',
          'C34',
          'A42',
          'B45',
          'A16',
          'C2',
          'B52',
          'D42',
          'D12',
          'C44',
          'A2',
          'D56',
          'B24',
          'D4',
          'C36',
          'A29',
          'B48',
          'C42',
          'D55',
          'A39',
          'C15',
          'B14',
          'D19',
          'A45',
          'C57',
          'C4',
          'B42',
          'B4',
          'C50',
          'B58',
          'D30',
          'B12',
          'C39',
          'D38',
          'D13',
          'C1',
          'A59',
          'C56',
          'A46',
          'A18',
          'B36',
          'C18',
          'B40',
          'C44',
          'A43',
          'D53',
          'B27',
          'A23',
          'C12',
          'B46',
          'C4',
          'C41',
          'B17',
          'C9',
          'C30',
          'D16',
          'B30',
          'C46',
          'A28',
          'B53',
          'C21',
          'A53',
          'D7',
          'C2',
          'B21',
          'C53',
          'B32',
          'D14',
          'B18',
          'C58',
          'A23',
          'D59',
          'D17',
          'A6',
          'D38',
          'A34',
          'C17',
          'B43',
          'C8',
          'A46',
          'D28',
          'A9',
          'A37',
          'C20',
          'B2',
          'B44',
          'C39',
          'D6',
          'C19',
          'A14',
          'A59',
          'C25',
          'A48',
          'C52',
          'A49',
          'B16',
          'C12',
          'B11',
          'C46',
          'B8',
          'C18',
          'D23',
          'D50',
          'B10',
          'C54',
          'A46',
          'D53',
          'D20',
          'B4',
          'D42',
          'B53',
          'C2',
          'A54',
          'B49',
          'C4',
          'D5',
          'A48',
          'D17',
          'C27',
          'A8',
          'B59',
          'C53',
          'A34',
          'C41',
          'C23',
          'A60',
          'D46',
          'B25',
          'D25',
          'B20',
          'C33',
          'A56',
          'B47',
          'D57',
          'B3',
          'A9',
          'B5',
          'C50',
          'C11',
          'B31',
          'D4',
          'D58',
          'D42',
          'B10',
          'D26',
          'D41',
          'B27',
          'D20',
          'C20',
          'B24',
          'C34',
          'B45',
          'A2',
          'D43',
          'A43',
          'C32',
          'B48',
          'A10',
          'A48',
          'C8',
          'D1',
          'C40',
          'B18',
          'D6',
          'D52',
          'A57',
          'D48',
          'B50',
          'D47',
          'B21',
          'C48',
          'B7',
          'A9',
          'B52',
          'C52',
          'C10',
          'A45',
          'A30',
          'A15',
          'A32',
          'A13',
          'A35',
          'C1',
          'B2',
          'C14',
          'B25',
          'C6',
          'B30',
          'C35',
          'B10',
          'A8',
          'B23',
          'C38',
          'D10',
          'D54',
          'A19',
          'D13',
          'B34',
          'A6',
          'C30',
          'B58',
          'D40',
          'A58',
          'A10',
          'A60',
          'C51',
          'B32',
          'B59',
          'C2',
          'A2',
          'D22',
          'D37',
          'B4',
          'B24',
          'C27',
          'B60',
          'C50',
          'B18',
          'A36',
          'B40',
          'C26',
          'D18',
          'B26',
          'C3',
          'A8',
          'B54',
          'C8',
          'D7',
          'B21',
          'C41',
          'A46',
          'C53',
          'A53',
          'A18',
          'D60',
          'B30',
          'C30',
          'B34',
          'C37',
          'A1',
          'B56',
          'A25',
          'D10',
          'D39',
          'D1',
          'C59',
          'D33',
          'A20',
          'B57',
          'C20',
          'D55',
          'A3',
          'A41',
          'C12',
          'C40',
          'D16',
          'C1',
          'D24',
          'D44',
          'C33',
          'B21',
          'A32',
          'D25',
          'D50',
          'A24',
          'B60',
          'D56',
          'B47',
          'B27',
          'A9',
          'D52',
          'D20',
          'C25',
          'A49',
          'B32',
          'C21',
          'D5',
          'C43',
          'B17',
          'C60',
          'C4',
          'B14',
          'A2',
          'C13',
          'B26',
          'B1',
          'A5',
          'A39',
          'B54',
          'A7',
          'B37',
          'B55',
          'C45',
          'D9',
          'D51',
          'B8',
          'C38',
          'B19',
          'C24',
          'A53',
          'D54',
          'B48',
          'C54',
          'A30',
          'B47',
          'C39',
          'A59',
          'A12',
          'A57',
          'C14',
          'B40',
          'D55',
          'B5',
          'D26',
          'C43',
          'B4',
          'D56',
          'D8',
          'B11',
          'C11',
          'A43',
          'D20',
          'A35',
          'C37',
          'A47',
          'D41',
          'B37',
          'A4',
          'D11',
          'D49',
          'B19',
          'D58',
          'B33',
          'A15',
          'A56',
          'A13',
          'A45',
          'C57',
          'A26',
          'D40',
          'B24',
          'D26',
          'C39',
          'B51',
          'D43',
          'C49',
          'D2',
          'C47',
          'A57',
          'C7',
        ],
      },
    ],
  },
  {
    title: 'Leonardo da Vinci',
    id: 'leonardo-da-vinci',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Leonardo%20da%20Vinci.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'B4',
          'B20',
          'A32',
          'B21',
          'B3',
          'B28',
          'A51',
          'B32',
          'A48',
          'B38',
          'A58',
          'A16',
          'A38',
          'B17',
          'A34',
          'B19',
          'A52',
          'B26',
          'A60',
          'B30',
          'A45',
          'A2',
          'A46',
          'C59',
          'A32',
          'A17',
          'A57',
          'B31',
          'A49',
          'C58',
          'A50',
          'B41',
          'A42',
          'A15',
          'A44',
          'B38',
          'A43',
          'B40',
          'B10',
          'B25',
          'B3',
          'A14',
          'B5',
          'A26',
          'B7',
          'A22',
          'B8',
          'B44',
          'A56',
          'A2',
          'A60',
          'C60',
          'A41',
          'B42',
          'A57',
          'C58',
          'A33',
          'A17',
          'A40',
          'A16',
          'A59',
          'A15',
          'B9',
          'B41',
          'B3',
          'B29',
          'D39',
          'D23',
          'B19',
          'D29',
          'B24',
          'D28',
          'D45',
          'D20',
          'D41',
          'D21',
          'D38',
          'B30',
          'A50',
          'C57',
          'A52',
          'C54',
          'A60',
          'B43',
          'A33',
          'C56',
          'A42',
          'B33',
          'A36',
          'B37',
          'A51',
          'A17',
          'B13',
          'A14',
          'B17',
          'D25',
          'B21',
          'D30',
          'B23',
          'D33',
          'B27',
          'B7',
          'A16',
          'B8',
          'C57',
          'A41',
          'A58',
          'C58',
          'A39',
          'B41',
          'A29',
          'C59',
          'A48',
          'B42',
          'B2',
          'B29',
          'A52',
          'C56',
          'A53',
          'A17',
          'B23',
          'A14',
          'B25',
          'D28',
          'B20',
          'A24',
          'B32',
          'A44',
          'A3',
          'B14',
          'A2',
          'A47',
          'B45',
          'A37',
          'A22',
          'B21',
          'D27',
          'B26',
          'D37',
          'D21',
          'D44',
          'D16',
          'D43',
          'B31',
          'A45',
          'A15',
          'B12',
          'A54',
          'C53',
          'A57',
          'A17',
          'B29',
          'D35',
          'B25',
          'A53',
          'C55',
          'B1',
          'C54',
          'B2',
          'B46',
          'A39',
          'A23',
          'B6',
          'C56',
          'A46',
          'A14',
          'B26',
          'A13',
          'B13',
          'B40',
          'A51',
          'C58',
          'A59',
          'C57',
          'B4',
          'C53',
          'B5',
          'B39',
          'B14',
          'D29',
          'B16',
          'D26',
          'B20',
          'D23',
          'D38',
          'B31',
          'D33',
          'B32',
          'A20',
          'A39',
          'A3',
          'A40',
          'A1',
          'A48',
          'C59',
          'A49',
          'B44',
          'A32',
          'B23',
          'B8',
          'C56',
          'A60',
          'A15',
          'B21',
          'D23',
          'D38',
          'B28',
          'D40',
          'B27',
          'A52',
          'A20',
          'A56',
          'C54',
          'B3',
          'A2',
          'A47',
          'C60',
          'B11',
          'D30',
          'B13',
          'D24',
          'B18',
          'A10',
          'A45',
          'B44',
          'A48',
          'C55',
          'A50',
          'B43',
          'A40',
          'A16',
          'A41',
          'B45',
          'A34',
          'A18',
          'A36',
          'C59',
          'A56',
          'B39',
          'A54',
          'B15',
          'D27',
          'B28',
          'D36',
          'B31',
          'D45',
          'D13',
          'D48',
          'D18',
          'D46',
          'B26',
          'D43',
          'D19',
          'D52',
          'D11',
          'D51',
          'D14',
          'D47',
          'B30',
          'D34',
          'B22',
          'A21',
          'B12',
          'A19',
          'B32',
          'A43',
          'A11',
          'B23',
          'A54',
          'C55',
          'B8',
          'C54',
          'B4',
          'B40',
          'A42',
          'C60',
          'A45',
          'A9',
          'B20',
          'D44',
          'D28',
          'B13',
          'A4',
          'A38',
          'B47',
          'A36',
          'A18',
          'A37',
          'B19',
          'D45',
          'D14',
          'D42',
          'B34',
          'D33',
          'B36',
          'A52',
          'B42',
          'A28',
          'B31',
          'A21',
          'B24',
          'D47',
          'B33',
          'D43',
          'B55',
          'D48',
          'B28',
          'A14',
          'B9',
          'D20',
          'D40',
          'B29',
          'A51',
          'C57',
          'B1',
          'C52',
          'A57',
          'A2',
          'B15',
          'A39',
          'B44',
          'A42',
          'A16',
          'B16',
          'D21',
          'B8',
          'C54',
          'B6',
          'C53',
          'A58',
          'B30',
          'D46',
          'B18',
          'D41',
          'B36',
          'D40',
          'B20',
          'A8',
          'A37',
          'C59',
          'A49',
          'C58',
          'B3',
          'C51',
          'B4',
          'C57',
          'A51',
          'C55',
          'A55',
          'A3',
          'A44',
          'B39',
          'A26',
          'B11',
          'D25',
          'B23',
          'D44',
          'D1',
          'D43',
          'D28',
          'B9',
          'D40',
          'D16',
          'D42',
          'B35',
          'D46',
          'B57',
          'D45',
          'B58',
          'D37',
          'D22',
          'B7',
          'C56',
          'A60',
          'C49',
          'B1',
          'A15',
          'B24',
          'D44',
          'B21',
          'A8',
          'A46',
          'B29',
          'D48',
          'B51',
          'D47',
          'B56',
          'D39',
          'B30',
          'A30',
          'B45',
          'A28',
          'B4',
          'B27',
          'D43',
          'D3',
          'D41',
          'B13',
          'A11',
          'A44',
          'C57',
          'A45',
          'A3',
          'A59',
          'C55',
          'A56',
          'C54',
          'B9',
          'D26',
          'B31',
          'D34',
          'D18',
          'D33',
          'B38',
          'A33',
          'B19',
          'A10',
          'A31',
          'B44',
          'A35',
          'A2',
          'B16',
          'A53',
          'C56',
          'A47',
          'C60',
          'A27',
          'A43',
          'A4',
          'A32',
          'B47',
          'D45',
          'B59',
          'D33',
          'B15',
          'D22',
          'B21',
          'D39',
          'B37',
          'D38',
          'B8',
          'C53',
          'A60',
          'B51',
          'A49',
          'B41',
          'A36',
          'A58',
          'C52',
          'B2',
          'C49',
          'A40',
          'C58',
          'A50',
          'C50',
          'A52',
          'C57',
          'B5',
          'D35',
          'B26',
          'D49',
          'B19',
          'D48',
          'B23',
          'A8',
          'A38',
          'A18',
          'B12',
          'D23',
          'B31',
          'D31',
          'B38',
          'D47',
          'B53',
          'D44',
          'B25',
          'D41',
          'B27',
          'A13',
          'B17',
          'D45',
          'B20',
          'A23',
          'B11',
          'A22',
          'B36',
          'D27',
          'B39',
          'D25',
          'A58',
          'C50',
          'A59',
          'C48',
          'A60',
          'B18',
          'D21',
          'B13',
          'A3',
          'A46',
          'B43',
          'A43',
          'A16',
          'B20',
          'D48',
          'B32',
          'D43',
          'D6',
          'D40',
          'B53',
          'D50',
          'B50',
          'D49',
          'B22',
          'D45',
          'B60',
          'D39',
          'D16',
          'D36',
          'B9',
          'C56',
          'A54',
          'B40',
          'A40',
          'A6',
          'A26',
          'C46',
          'A52',
          'B52',
          'D46',
          'B16',
          'A15',
          'B25',
          'D51',
          'B29',
          'A14',
          'B30',
          'D37',
          'D22',
          'A60',
          'B37',
          'D36',
          'D7',
          'D34',
          'B33',
          'A19',
          'A56',
          'C53',
          'B9',
          'D35',
          'B32',
          'D30',
          'B5',
          'C55',
          'A55',
          'A27',
          'B43',
          'A36',
          'A1',
          'A46',
          'C57',
          'A52',
          'B31',
          'D49',
          'B54',
          'D46',
          'B56',
          'D38',
          'B20',
          'A7',
          'B21',
          'D37',
          'B27',
          'A11',
          'B17',
          'D42',
          'B11',
          'A56',
          'C51',
          'A57',
          'C47',
          'B1',
          'D28',
          'D3',
          'D26',
          'B37',
          'A45',
          'C36',
          'A47',
          'C60',
          'A48',
          'B52',
          'D51',
          'B49',
          'A35',
          'A9',
          'B18',
          'D49',
          'B44',
          'A29',
          'C57',
          'A30',
          'B19',
          'D46',
          'B33',
          'D28',
          'B38',
          'A41',
          'A4',
          'A43',
          'C58',
          'A50',
          'B16',
          'A47',
          'B42',
          'A31',
          'B47',
          'D52',
          'B28',
          'D43',
          'B58',
          'D36',
          'B4',
          'C50',
          'A56',
          'B13',
          'C59',
          'A38',
          'A7',
          'B23',
          'D32',
          'B37',
          'A49',
          'C56',
          'A54',
          'B27',
          'D49',
          'B29',
          'D47',
          'B15',
          'A17',
          'A58',
          'B51',
          'A41',
          'C49',
          'B4',
          'D31',
          'B60',
          'D45',
          'D2',
          'D44',
          'B30',
          'D35',
          'B13',
          'C58',
          'A51',
          'C47',
          'A46',
          'B18',
          'A38',
          'A18',
          'A39',
          'A7',
          'A42',
          'B49',
          'D33',
          'D18',
          'B9',
          'C55',
          'A57',
          'C46',
          'A30',
          'B47',
          'A43',
          'A4',
          'A26',
          'B19',
          'D41',
          'D4',
          'D24',
          'D7',
          'D23',
          'B39',
          'D52',
          'B48',
          'D39',
          'B7',
          'D26',
          'B33',
          'D43',
          'B34',
          'D32',
          'B2',
          'A15',
          'B22',
          'B43',
          'D51',
          'B26',
          'D44',
          'B54',
          'A47',
          'C35',
          'A48',
          'C59',
          'A49',
          'C51',
          'B5',
          'D29',
          'D1',
          'D42',
          'B35',
          'D41',
          'B45',
          'D54',
          'B46',
          'A33',
          'A10',
          'A25',
          'B19',
          'A17',
          'B32',
          'D47',
          'B17',
          'A1',
          'A44',
          'C45',
          'A46',
          'B30',
          'D50',
          'B55',
          'D40',
          'D6',
          'D39',
          'D8',
          'D38',
          'B5',
          'D21',
          'B40',
          'A37',
          'B31',
          'A25',
          'B42',
          'A51',
          'C36',
          'A39',
          'C50',
          'A60',
          'C55',
          'B10',
          'C54',
          'A53',
          'C57',
          'B6',
          'D27',
          'D10',
          'D53',
          'B46',
          'A44',
          'C60',
          'A28',
          'A12',
          'B25',
          'D47',
          'B55',
          'A45',
          'C34',
          'A40',
          'B52',
          'D48',
          'B50',
          'D52',
          'B24',
          'B5',
          'D19',
          'B8',
          'D37',
          'D2',
          'D33',
          'D8',
          'D32',
          'B3',
          'C52',
          'B6',
          'D24',
          'A59',
          'B29',
          'D45',
          'B33',
          'A50',
          'C38',
          'A54',
          'B41',
          'D55',
          'B34',
          'D23',
          'B14',
          'C58',
          'A40',
          'A6',
          'A42',
          'C48',
          'A60',
          'C46',
          'A32',
          'C45',
          'A47',
          'B28',
          'D51',
          'B21',
          'D46',
          'B57',
          'D44',
          'B31',
          'A14',
          'B12',
          'D43',
          'D3',
          'D19',
          'B34',
          'A19',
          'B35',
          'D40',
          'B36',
          'D30',
          'A60',
          'B27',
          'D53',
          'B30',
          'D48',
          'B52',
          'D41',
          'D4',
          'B32',
          'A23',
          'A8',
          'A35',
          'B18',
          'D50',
          'B24',
          'B1',
          'C55',
          'B2',
          'C48',
          'A43',
          'A3',
          'A26',
          'A2',
          'B9',
          'D17',
          'B40',
          'D25',
          'A59',
          'C57',
          'A55',
          'C56',
          'B11',
          'D24',
          'B33',
          'D44',
          'B16',
          'A39',
          'C35',
          'A51',
          'C58',
          'A58',
          'D27',
          'B2',
          'C47',
          'A29',
          'B30',
          'A53',
          'B47',
          'D51',
          'B49',
          'A34',
          'B50',
          'D42',
          'B51',
          'D52',
          'B20',
          'A6',
          'B21',
          'D50',
          'B28',
          'A32',
          'C60',
          'A45',
          'C33',
          'A47',
          'C43',
          'A41',
          'B46',
          'A37',
          'A1',
          'A40',
          'B54',
          'D39',
          'B57',
          'D38',
          'D23',
          'B3',
          'C55',
          'B11',
          'C54',
          'A55',
          'B14',
          'D34',
          'B58',
          'B37',
          'D35',
          'B28',
          'D49',
          'B51',
          'D16',
          'B1',
          'C46',
          'A34',
          'C37',
          'A50',
          'C59',
          'A38',
          'B48',
          'A36',
          'A10',
          'B26',
          'B9',
          'D25',
          'D1',
          'A51',
          'B43',
          'D55',
          'B44',
          'A26',
          'A2',
          'A35',
          'C35',
          'A43',
          'B18',
          'D47',
          'B56',
          'D27',
          'B30',
          'D45',
          'B52',
          'D41',
          'B33',
          'D56',
          'B35',
          'D54',
          'B47',
          'D49',
          'B49',
          'B1',
          'D34',
          'B5',
          'C50',
          'A28',
          'C49',
          'A58',
          'C45',
          'A49',
          'B46',
          'D53',
          'B23',
          'D52',
          'B27',
          'D28',
          'D6',
          'D22',
          'A56',
          'C53',
          'A38',
          'B30',
          'A26',
          'A1',
          'A46',
          'B36',
          'D18',
          'A52',
          'C35',
          'A42',
          'C42',
          'A37',
          'B26',
          'A20',
          'A5',
          'A23',
          'B40',
          'D56',
          'B42',
          'A44',
          'C34',
          'A30',
          'B38',
          'D26',
          'A57',
          'B34',
          'D57',
          'B37',
          'D12',
          'B54',
          'D41',
          'D5',
          'D37',
          'B36',
          'D48',
          'B53',
          'D30',
          'B58',
          'D46',
          'B23',
          'A22',
          'C50',
          'B6',
          'B28',
          'A54',
          'C44',
          'A34',
          'C32',
          'A47',
          'C60',
          'A48',
          'B12',
          'D42',
          'B31',
          'D50',
          'B48',
          'D34',
          'B2',
          'D20',
          'B16',
          'D48',
          'B55',
          'D40',
          'B14',
          'D28',
          'B5',
          'C55',
          'B7',
          'D38',
          'B35',
          'A18',
          'A56',
          'C59',
          'A49',
          'C37',
          'A29',
          'B46',
          'D52',
          'B25',
          'A11',
          'B28',
          'A20',
          'B30',
          'D43',
          'B47',
          'A41',
          'C36',
          'A19',
          'B36',
          'D59',
          'B45',
          'D55',
          'B29',
          'A31',
          'C47',
          'A24',
          'A5',
          'A42',
          'C32',
          'A43',
          'B44',
          'D13',
          'B57',
          'D38',
          'D23',
          'D7',
          'B40',
          'A58',
          'B26',
          'A48',
          'B53',
          'A41',
          'C33',
          'A32',
          'C40',
          'A38',
          'B55',
          'D39',
          'B6',
          'D18',
          'D33',
          'B59',
          'C8',
          'B48',
          'D54',
          'B37',
          'D53',
          'B49',
          'D43',
          'B10',
          'D17',
          'A54',
          'C43',
          'A57',
          'C51',
          'A26',
          'C58',
          'B3',
          'D30',
          'B38',
          'A24',
          'A5',
          'B21',
          'A55',
          'C38',
          'A38',
          'B25',
          'D50',
          'B49',
          'D32',
          'B12',
          'A12',
          'B19',
          'A9',
          'B22',
          'D54',
          'B45',
          'A42',
          'B50',
          'D49',
          'B51',
          'D42',
          'B13',
          'C57',
          'A52',
          'C45',
          'A33',
          'B27',
          'D51',
          'B46',
          'A28',
          'C48',
          'A45',
          'B39',
          'D18',
          'B49',
          'A32',
          'B34',
          'D41',
          'D14',
          'B52',
          'D32',
          'A60',
          'D29',
          'B23',
          'B7',
          'C51',
          'A27',
          'C30',
          'A35',
          'C44',
          'A50',
          'C34',
          'A52',
          'B40',
          'D44',
          'D2',
          'D25',
          'B1',
          'B16',
          'D27',
          'B58',
          'D36',
          'B21',
          'D48',
          'B31',
          'D58',
          'B32',
          'D57',
          'B38',
          'D24',
          'A55',
          'B35',
          'A50',
          'C59',
          'B2',
          'B33',
          'D33',
          'A59',
          'C57',
          'A53',
          'C45',
          'A28',
          'C39',
          'A37',
          'C36',
          'A38',
          'B52',
          'C5',
          'B53',
          'D47',
          'B56',
          'A47',
          'B29',
          'D51',
          'B39',
          'D46',
          'B59',
          'D26',
          'D11',
          'A48',
          'C60',
          'B12',
          'D45',
          'B13',
          'D37',
          'B57',
          'A40',
          'C46',
          'A31',
          'A12',
          'A34',
          'A4',
          'C34',
          'A38',
          'B42',
          'D44',
          'B44',
          'D60',
          'B47',
          'D35',
          'B58',
          'A45',
          'B41',
          'D15',
          'B3',
          'C48',
          'A30',
          'B43',
          'A24',
          'C53',
          'B9',
          'D24',
          'B16',
          'C60',
          'A35',
          'B48',
          'C7',
          'B54',
          'D41',
          'B8',
          'D29',
          'A57',
          'C41',
          'A34',
          'B22',
          'D52',
          'B37',
          'A46',
          'C37',
          'A53',
          'B17',
          'A49',
          'C35',
          'A29',
          'C50',
          'B1',
          'B25',
          'D56',
          'B36',
          'D55',
          'B26',
          'A9',
          'A36',
          'C43',
          'C28',
          'A37',
          'B32',
          'D49',
          'B51',
          'C10',
          'D54',
          'B29',
          'A43',
          'A2',
          'A27',
          'C58',
          'A51',
          'C32',
          'A45',
          'B45',
          'A39',
          'C33',
          'A48',
          'B27',
          'D39',
          'D16',
          'D36',
          'B3',
          'D19',
          'D3',
          'D42',
          'B53',
          'C11',
          'D1',
          'C8',
          'B46',
          'D50',
          'B37',
          'D48',
          'B55',
          'D32',
          'D9',
          'D30',
          'B60',
          'B9',
          'C52',
          'A55',
          'B24',
          'A8',
          'B19',
          'A7',
          'C33',
          'A52',
          'D20',
          'A54',
          'C42',
          'A58',
          'B33',
          'A16',
          'C38',
          'A28',
          'B47',
          'D40',
          'B56',
          'C7',
          'D7',
          'A50',
          'C48',
          'B4',
          'D35',
          'D4',
          'C10',
          'B45',
          'B5',
          'C54',
          'A27',
          'C51',
          'A38',
          'C35',
          'A43',
          'C31',
          'A44',
          'B43',
          'D56',
          'B28',
          'D46',
          'B32',
          'D42',
          'B23',
          'D51',
          'B50',
          'A32',
          'B41',
          'A53',
          'D21',
          'B1',
          'D31',
          'B35',
          'A54',
          'B45',
          'A27',
          'C41',
          'A35',
          'C29',
          'A36',
          'C37',
          'A42',
          'C36',
          'A50',
          'D14',
          'B9',
          'B55',
          'C5',
          'D2',
          'D30',
          'B15',
          'D45',
          'B39',
          'D22',
          'A51',
          'D13',
          'B57',
          'D37',
          'D6',
          'A53',
          'B51',
          'C9',
          'D49',
          'B54',
          'C13',
          'B49',
          'C6',
          'B50',
          'D50',
          'B17',
          'A13',
          'B30',
          'D51',
          'B19',
          'A3',
          'A45',
          'C29',
          'A29',
          'C49',
          'B5',
          'B20',
          'D53',
          'B38',
          'A46',
          'A1',
          'B45',
          'A36',
          'C46',
          'B2',
          'D31',
          'D16',
          'B55',
          'D39',
          'D4',
          'C9',
          'D30',
          'B4',
          'D20',
          'B17',
          'A10',
          'A25',
          'A5',
          'A31',
          'C34',
          'A50',
          'B36',
          'D58',
          'C15',
          'B57',
          'D32',
          'B59',
          'A44',
          'C33',
          'C48',
          'A33',
          'C45',
          'B2',
          'D24',
          'B24',
          'D57',
          'B27',
          'D50',
          'B39',
          'D40',
          'B40',
          'A35',
          'B52',
          'D49',
          'B30',
          'A17',
          'C38',
          'A40',
          'C44',
          'A53',
          'B25',
          'A21',
          'C52',
          'A25',
          'B44',
          'D43',
          'B19',
          'D47',
          'B56',
          'D46',
          'D1',
          'C14',
          'B48',
          'A43',
          'C34',
          'A23',
          'A8',
          'B53',
          'C4',
          'B57',
          'A46',
          'C29',
          'A47',
          'B36',
          'A37',
          'C26',
          'A38',
          'B18',
          'A13',
          'C58',
          'B14',
          'A54',
          'B24',
          'D51',
          'C8',
          'B37',
          'A34',
          'C45',
          'B3',
          'B23',
          'D36',
          'B8',
          'D26',
          'D5',
          'C5',
          'D8',
          'D24',
          'D39',
          'B56',
          'A38',
          'B57',
          'C9',
          'B45',
          'C7',
          'B46',
          'C12',
          'B54',
          'A53',
          'B28',
          'C8',
          'B38',
          'A21',
          'C37',
          'A30',
          'C47',
          'A33',
          'C33',
          'A50',
          'B25',
          'D54',
          'B36',
          'A18',
          'C49',
          'B1',
          'B35',
          'D60',
          'B48',
          'D52',
          'B41',
          'D22',
          'C5',
          'B56',
          'C12',
          'B50',
          'A41',
          'C40',
          'A35',
          'C39',
          'A15',
          'B33',
          'A47',
          'C28',
          'A7',
          'B24',
          'D55',
          'B28',
          'A48',
          'C31',
          'A34',
          'B42',
          'D40',
          'B53',
          'A37',
          'A5',
          'B50',
          'C6',
          'B51',
          'A39',
          'B29',
          'C8',
          'D30',
          'D4',
          'D29',
          'D9',
          'C4',
          'D24',
          'A57',
          'C50',
          'A27',
          'B18',
          'A2',
          'A45',
          'C30',
          'A43',
          'B39',
          'C7',
          'D1',
          'D38',
          'B12',
          'C55',
          'A13',
          'B22',
          'A59',
          'D31',
          'B14',
          'A40',
          'C34',
          'A53',
          'D26',
          'D11',
          'D46',
          'B38',
          'C13',
          'D3',
          'D42',
          'B33',
          'A27',
          'C46',
          'A31',
          'B49',
          'C14',
          'D2',
          'D44',
          'B11',
          'C54',
          'A17',
          'C37',
          'A37',
          'B57',
          'C3',
          'D15',
          'A49',
          'B48',
          'A33',
          'C42',
          'A55',
          'C56',
          'A59',
          'C52',
          'A25',
          'B37',
          'D47',
          'B51',
          'A6',
          'B22',
          'A56',
          'B34',
          'D49',
          'B54',
          'A9',
          'B55',
          'D48',
          'B14',
          'D19',
          'A54',
          'C36',
          'A55',
          'D15',
          'D45',
          'D1',
          'C6',
          'B52',
          'C16',
          'B59',
          'D46',
          'B43',
          'C59',
          'B44',
          'C12',
          'D3',
          'C11',
          'B35',
          'A53',
          'C40',
          'D16',
          'A48',
          'C60',
          'A54',
          'D26',
          'D5',
          'C10',
          'B36',
          'C9',
          'B30',
          'C8',
          'D52',
          'B38',
          'A20',
          'C54',
          'B12',
          'A57',
          'B27',
          'A21',
          'B34',
          'A26',
          'C31',
          'A40',
          'B28',
          'D33',
          'C7',
          'B47',
          'C15',
          'B52',
          'D51',
          'B48',
          'A3',
          'B1',
          'D35',
          'D6',
          'C6',
          'B54',
          'A39',
          'C25',
          'C44',
          'A46',
          'B42',
          'A42',
          'A5',
          'C30',
          'A28',
          'C23',
          'A30',
          'C27',
          'A36',
          'C39',
          'D16',
          'D31',
          'C8',
          'B38',
          'C2',
          'D15',
          'A59',
          'C41',
          'A14',
          'B16',
          'B1',
          'D33',
          'B51',
          'C17',
          'B58',
          'D34',
          'B8',
          'D39',
          'D4',
          'B9',
          'B43',
          'C11',
          'D27',
          'B37',
          'A55',
          'B50',
          'D59',
          'D9',
          'A52',
          'B55',
          'C13',
          'B59',
          'D32',
          'A58',
          'C58',
          'A51',
          'C32',
          'A19',
          'C28',
          'A41',
          'C38',
          'A33',
          'B45',
          'A50',
          'C59',
          'A57',
          'B43',
          'B28',
          'A27',
          'C49',
          'A29',
          'B42',
          'D50',
          'B29',
          'C7',
          'B48',
          'A40',
          'B59',
          'C12',
          'B60',
          'D57',
          'B34',
          'B2',
          'C56',
          'B4',
          'D29',
          'B12',
          'D46',
          'B46',
          'A4',
          'A24',
          'C34',
          'A46',
          'D17',
          'C41',
          'A3',
          'B49',
          'C7',
          'D41',
          'B54',
          'C22',
          'A37',
          'B34',
          'C12',
          'B47',
          'A45',
          'C27',
          'A22',
          'C35',
          'D12',
          'D27',
          'B7',
          'C54',
          'B13',
          'B43',
          'A53',
          'D15',
          'C6',
          'D7',
          'D34',
          'B26',
          'C7',
          'D3',
          'C4',
          'D10',
          'A49',
          'B25',
          'A30',
          'B49',
          'D43',
          'D2',
          'C29',
          'A6',
          'B52',
          'C14',
          'B53',
          'D42',
          'C5',
          'D8',
          'A44',
          'A4',
          'B21',
          'D53',
          'B26',
          'D52',
          'B29',
          'C47',
          'B5',
          'B20',
          'A12',
          'B27',
          'A10',
          'B56',
          'C22',
          'D13',
          'C20',
          'A30',
          'C29',
          'A48',
          'B35',
          'C6',
          'D35',
          'B38',
          'A47',
          'C37',
          'A18',
          'B14',
          'D47',
          'C3',
          'D8',
          'D37',
          'B3',
          'B32',
          'A16',
          'B15',
          'D28',
          'B29',
          'D46',
          'B51',
          'A43',
          'C28',
          'D19',
          'C32',
          'A2',
          'B46',
          'A32',
          'C49',
          'B6',
          'C55',
          'A23',
          'B42',
          'D14',
          'D40',
          'D20',
          'C11',
          'B44',
          'C8',
          'B31',
          'C47',
          'D21',
          'A50',
          'D17',
          'C29',
          'A18',
          'C35',
          'A39',
          'C32',
          'A35',
          'C23',
          'D10',
          'B9',
          'C51',
          'A59',
          'C40',
          'A11',
          'B57',
          'D47',
          'B27',
          'A42',
          'C31',
          'A44',
          'B45',
          'D53',
          'B47',
          'C15',
          'B58',
          'C25',
          'A19',
          'B11',
          'B26',
          'C6',
          'B39',
          'A33',
          'B52',
          'D43',
          'C5',
          'B41',
          'A58',
          'B23',
          'D56',
          'B44',
          'C60',
          'C36',
          'A34',
          'B32',
          'C7',
          'D4',
          'D38',
          'D23',
          'D7',
          'A45',
          'C26',
          'A24',
          'C24',
          'B58',
          'C3',
          'D24',
          'C53',
          'A26',
          'C52',
          'B8',
          'A20',
          'B34',
          'A16',
          'C59',
          'D27',
          'C10',
          'B46',
          'C16',
          'B50',
          'D50',
          'B19',
          'D40',
          'B54',
          'A12',
          'C39',
          'A55',
          'B26',
          'A49',
          'D2',
          'A45',
          'C46',
          'A32',
          'C27',
          'A43',
          'B49',
          'C18',
          'D1',
          'D45',
          'B32',
          'C47',
          'B6',
          'D15',
          'D60',
          'A16',
          'B11',
          'D47',
          'B31',
          'A38',
          'C21',
          'B54',
          'C6',
          'B55',
          'A41',
          'B27',
          'C42',
          'A34',
          'C24',
          'A36',
          'B38',
          'C7',
          'D5',
          'D41',
          'B12',
          'A5',
          'B52',
          'A42',
          'C34',
          'D12',
          'C4',
          'D13',
          'C19',
          'C40',
          'A27',
          'B46',
          'A1',
          'A26',
          'B40',
          'A46',
          'C27',
          'D21',
          'C32',
          'D5',
          'C29',
          'A21',
          'B29',
          'D17',
          'C1',
          'B32',
          'A14',
          'B56',
          'D39',
          'D24',
          'C38',
          'A55',
          'D25',
          'A52',
          'B24',
          'A51',
          'B44',
          'C58',
          'B37',
          'C9',
          'B28',
          'C48',
          'B30',
          'A48',
          'D12',
          'C23',
          'B55',
          'B38',
          'D42',
          'D3',
          'C11',
          'D13',
          'C3',
          'D47',
          'B20',
          'A60',
          'B39',
          'C13',
          'D2',
          'C12',
          'B35',
          'A31',
          'B50',
          'C15',
          'B59',
          'D46',
          'D18',
          'C43',
          'A32',
          'C25',
          'D19',
          'D60',
          'A23',
          'C54',
          'B32',
          'A28',
          'B26',
          'A44',
          'C30',
          'A10',
          'B52',
          'A16',
          'C56',
          'B4',
          'B21',
          'A59',
          'C49',
          'B27',
          'C6',
          'D7',
          'A60',
          'C43',
          'B28',
          'A15',
          'B51',
          'C8',
          'B60',
          'A14',
          'C33',
          'D18',
          'D33',
          'C4',
          'D36',
          'B36',
          'C12',
          'B40',
          'C7',
          'D21',
          'A59',
          'B25',
          'A45',
          'D12',
          'C55',
          'B14',
          'C57',
          'D27',
          'D60',
          'B41',
          'A26',
          'C50',
          'C34',
          'A54',
          'D4',
          'C6',
          'D31',
          'A6',
          'B47',
          'C9',
          'B50',
          'D48',
          'B55',
          'A8',
          'C44',
          'B26',
          'A34',
          'B44',
          'D55',
          'C37',
          'A41',
          'C21',
          'A36',
          'C45',
          'D19',
          'C12',
          'D26',
          'C7',
          'B24',
          'A39',
          'B30',
          'D14',
          'B58',
          'A15',
          'A42',
          'C33',
          'A18',
          'C14',
          'B37',
          'A44',
          'D13',
          'C36',
          'A28',
          'C21',
          'D12',
          'B3',
          'C3',
          'B6',
          'D30',
          'A57',
          'D22',
          'B16',
          'A4',
          'B44',
          'C57',
          'A53',
          'B22',
          'D57',
          'B35',
          'A26',
          'B45',
          'A48',
          'D39',
          'D6',
          'C29',
          'A31',
          'C50',
          'B3',
          'C58',
          'D28',
          'D43',
          'C3',
          'A19',
          'B37',
          'C9',
          'D14',
          'C19',
          'B56',
          'C5',
          'D9',
          'A44',
          'B47',
          'A37',
          'C41',
          'B26',
          'A43',
          'C26',
          'A46',
          'D5',
          'A42',
          'A5',
          'D32',
          'C54',
          'A24',
          'B41',
          'A1',
          'D17',
          'A58',
          'C44',
          'B29',
          'A12',
          'B60',
          'C15',
          'B51',
          'C7',
          'D27',
          'C36',
          'D57',
          'B40',
          'D16',
          'C2',
          'B4',
          'B24',
          'A50',
          'D41',
          'B46',
          'A46',
          'A1',
          'C34',
          'C52',
          'D24',
          'C35',
          'A41',
          'C49',
          'C19',
          'B50',
          'C18',
          'A38',
          'B33',
          'A33',
          'C22',
          'A24',
          'A8',
          'B56',
          'C4',
          'D14',
          'C58',
          'B42',
          'A54',
          'B15',
          'D48',
          'B41',
          'D57',
          'B25',
          'A29',
          'C48',
          'B5',
          'D1',
          'C30',
          'D3',
          'A14',
          'B49',
          'A4',
          'C31',
          'D25',
          'B32',
          'C11',
          'B33',
          'C10',
          'D29',
          'D9',
          'C50',
          'D56',
          'A20',
          'B31',
          'A46',
          'D38',
          'C6',
          'B51',
          'A36',
          'B54',
          'A18',
          'D54',
          'C39',
          'D23',
          'A53',
          'B23',
          'A6',
          'D19',
          'C44',
          'A29',
          'C28',
          'A35',
          'C20',
          'B55',
          'A12',
          'D1',
          'A50',
          'B29',
          'A27',
          'B38',
          'D24',
          'D39',
          'A5',
          'D36',
          'B57',
          'D47',
          'C3',
          'A25',
          'A8',
          'B43',
          'A38',
          'B59',
          'A16',
          'C20',
          'D6',
          'C10',
          'B34',
          'C51',
          'A28',
          'B27',
          'C41',
          'A40',
          'B29',
          'D44',
          'B17',
          'A48',
          'D18',
          'C19',
          'A37',
          'B49',
          'D53',
          'B36',
          'C57',
          'D14',
          'C38',
          'A57',
          'C59',
          'B11',
          'A56',
          'B23',
          'C37',
          'D56',
          'D15',
          'D45',
          'B41',
          'A22',
          'C24',
          'D32',
          'D3',
          'C27',
          'B14',
          'A51',
          'C46',
          'B31',
          'A17',
          'B35',
          'C10',
          'B40',
          'C6',
          'B55',
          'D11',
          'C54',
          'B33',
          'D58',
          'C19',
          'B57',
          'A9',
          'D47',
          'B58',
          'C17',
          'A29',
          'B16',
          'D53',
          'C41',
          'B1',
          'B36',
          'D51',
          'B53',
          'D50',
          'B26',
          'A18',
          'C56',
          'D35',
          'B6',
          'C2',
          'A7',
          'D30',
          'D46',
          'B60',
          'A52',
          'C33',
          'B19',
          'A46',
          'B24',
          'A31',
          'C26',
          'A47',
          'D18',
          'D42',
          'D3',
          'D40',
          'D20',
          'C8',
          'B47',
          'A1',
          'D28',
          'C38',
          'A13',
          'B49',
          'A8',
          'A39',
          'B40',
          'C9',
          'B43',
          'A26',
          'C33',
          'D60',
          'D33',
          'A9',
          'C41',
          'D41',
          'A46',
          'D19',
          'C43',
          'C59',
          'B12',
          'A41',
          'B39',
          'A27',
          'C31',
          'D28',
          'A57',
          'D18',
          'A4',
          'B50',
          'D43',
          'D2',
          'A26',
          'C52',
          'D10',
          'A58',
          'D26',
          'C38',
          'D54',
          'B28',
          'C47',
          'B24',
          'C6',
          'D21',
          'C36',
          'D46',
          'A53',
          'D42',
          'B41',
          'D51',
          'B49',
          'A29',
          'B26',
          'C49',
          'D22',
          'C25',
          'B14',
          'C54',
          'C15',
          'A22',
          'B28',
          'C46',
          'B6',
          'D17',
          'A51',
          'C35',
          'B20',
          'C33',
          'A53',
          'D8',
          'B29',
          'C43',
          'A48',
          'C60',
          'A49',
          'D44',
          'B48',
          'A32',
          'D32',
          'C6',
          'B52',
          'C21',
          'C36',
          'D9',
          'B21',
          'B2',
          'C1',
          'B4',
          'D37',
          'C59',
          'A50',
          'D23',
          'B40',
          'D53',
          'A15',
          'B59',
          'A13',
          'B31',
          'C12',
          'B37',
          'A48',
          'C37',
          'D43',
          'B11',
          'C53',
          'A24',
          'D58',
          'B26',
          'D56',
          'A16',
          'C40',
          'A54',
          'B53',
          'A17',
          'C18',
          'B14',
          'D25',
          'C54',
          'D12',
          'B46',
          'D55',
          'B35',
          'A37',
          'B20',
          'A33',
          'B22',
          'A8',
          'B57',
          'A21',
          'C1',
          'A3',
          'D32',
          'D4',
          'A14',
          'C58',
          'A52',
          'D47',
          'C29',
          'C13',
          'B33',
          'A23',
          'B43',
          'A7',
          'A39',
          'C19',
          'B30',
          'D59',
          'C37',
          'D14',
          'A59',
          'D58',
          'C46',
          'D6',
          'C9',
          'C36',
          'D23',
          'C32',
          'D24',
          'D39',
          'A51',
          'D11',
          'C48',
          'B27',
          'A24',
          'B5',
          'C4',
          'A17',
          'D53',
          'B47',
          'D17',
          'C40',
          'B25',
          'A25',
          'C1',
          'B34',
          'C7',
          'B49',
          'D21',
          'A10',
          'D60',
          'A27',
          'D4',
          'C12',
          'B39',
          'C59',
          'B45',
          'A38',
          'C20',
          'B52',
          'A4',
          'A44',
          'C28',
          'D28',
          'B36',
          'A28',
          'B17',
          'A39',
          'C50',
          'B34',
          'D47',
          'B58',
          'A59',
          'C37',
          'A33',
          'B40',
          'C1',
          'C31',
          'A29',
          'B31',
          'C19',
          'D33',
          'B11',
          'C24',
          'A44',
          'D37',
          'B7',
          'D14',
          'A43',
          'C47',
          'B32',
          'D13',
          'C10',
          'B27',
          'D56',
          'B45',
          'A25',
          'C34',
          'A12',
          'C28',
          'D1',
          'D45',
          'C5',
          'A16',
          'B57',
          'C26',
          'A48',
          'B3',
          'A40',
          'B12',
          'C24',
          'B13',
          'C17',
          'B59',
          'A23',
          'C2',
          'A60',
          'C56',
          'B10',
          'A50',
          'D18',
          'D42',
          'B53',
          'A4',
          'D20',
          'D40',
          'A7',
          'B44',
          'A3',
          'C32',
          'B20',
          'D37',
          'D9',
          'C24',
          'A27',
          'B40',
          'D49',
          'B54',
          'A35',
          'B18',
          'A34',
          'C21',
          'A10',
          'D32',
          'C23',
          'A32',
          'C39',
          'A1',
          'D20',
          'C45',
          'D50',
          'C20',
          'B15',
          'A36',
          'D38',
          'B56',
          'A51',
          'C43',
          'A53',
          'B36',
          'A17',
          'D9',
          'C46',
          'D11',
          'B16',
          'D51',
          'C44',
          'A31',
          'D31',
          'C31',
          'D26',
          'B28',
          'A18',
          'D1',
          'A11',
          'B18',
          'D54',
          'B32',
          'C19',
          'A41',
          'C32',
          'D2',
          'D43',
          'B37',
          'C8',
          'B24',
          'A30',
          'B48',
          'D52',
          'B51',
          'C29',
          'A42',
          'D42',
          'C60',
          'D15',
          'C53',
          'B12',
          'C20',
          'B53',
          'D50',
          'A15',
          'C25',
          'D30',
          'C7',
          'B25',
          'A8',
          'D54',
          'B45',
          'C17',
          'B14',
          'A40',
          'C50',
          'D16',
          'A54',
          'B9',
          'A43',
          'C22',
          'B17',
          'D48',
          'B55',
          'A19',
          'D52',
          'C43',
          'B2',
          'C12',
          'B52',
          'B37',
          'A28',
          'D5',
          'D26',
          'D11',
          'A18',
          'B30',
          'C44',
          'B22',
          'A31',
          'B23',
          'A47',
          'A1',
          'D36',
          'B24',
          'C39',
          'D20',
          'C33',
          'B21',
          'D18',
          'B28',
          'D32',
          'C21',
          'B10',
          'A42',
          'C49',
          'D8',
          'A45',
          'A2',
          'D27',
          'C35',
          'A55',
          'D43',
          'D59',
          'A29',
          'B35',
          'C14',
          'B38',
          'C60',
          'D21',
          'B19',
          'D54',
          'B46',
          'C16',
          'D55',
          'B27',
          'A23',
          'C27',
          'D28',
          'C11',
          'B39',
          'C3',
          'A57',
          'C31',
          'A9',
          'B20',
          'A5',
          'A25',
          'B26',
          'C40',
          'D11',
          'B38',
          'C55',
          'D34',
          'C18',
          'B30',
          'D7',
          'B48',
          'A7',
          'B54',
          'D49',
          'B13',
          'C53',
          'D59',
          'A58',
          'C58',
          'B45',
          'C57',
          'D15',
          'B29',
          'A22',
          'D55',
          'A12',
          'B56',
          'D48',
          'B26',
          'A42',
          'B37',
          'D17',
          'A52',
          'C32',
          'D8',
          'A59',
          'D23',
          'C51',
          'D49',
          'A9',
          'A37',
          'C22',
          'B35',
          'D45',
          'D1',
          'C15',
          'D40',
          'C6',
          'B25',
          'C47',
          'A30',
          'D30',
          'C45',
          'B28',
          'A13',
          'B50',
          'A2',
          'B19',
          'A16',
          'D5',
          'D41',
          'D7',
          'A15',
          'B11',
          'C21',
          'D37',
          'A45',
          'D19',
          'A5',
          'C9',
          'B54',
          'A34',
          'B10',
          'A24',
          'B39',
          'A20',
          'C30',
          'D27',
          'C46',
          'A6',
          'C26',
          'A13',
          'D23',
          'A49',
          'C29',
          'B16',
          'A35',
          'B21',
          'C27',
          'B49',
          'D50',
          'C50',
          'B35',
          'C4',
          'A56',
          'D29',
          'C60',
          'B50',
          'D6',
          'C8',
          'B39',
          'D58',
          'B1',
          'D5',
          'B40',
          'D33',
          'C56',
          'D18',
          'C42',
          'D22',
          'B3',
          'C12',
          'B32',
          'C23',
          'A36',
          'C34',
          'B18',
          'A10',
          'A33',
          'C30',
          'D45',
          'A50',
          'B15',
          'C18',
          'B55',
          'B40',
          'A21',
          'C25',
          'D50',
          'D15',
          'B41',
          'D57',
          'B43',
          'A5',
          'B22',
          'D16',
          'A58',
          'C57',
          'B7',
          'C2',
          'B39',
          'D9',
          'B4',
          'D18',
          'D33',
          'A34',
          'B29',
          'C45',
          'A45',
          'B25',
          'C46',
          'D46',
          'B60',
          'C17',
          'D36',
          'B2',
          'C41',
          'B28',
          'C6',
          'D7',
          'A30',
          'D58',
          'A2',
          'D16',
          'C57',
          'D29',
          'C26',
          'B59',
          'C36',
          'A3',
          'A44',
          'B6',
          'D40',
          'C38',
          'D22',
          'C48',
          'B1',
          'B22',
          'A26',
          'C3',
          'A59',
          'D46',
          'A49',
          'D19',
          'B17',
          'A47',
          'C24',
          'C8',
          'B33',
          'C23',
          'B36',
          'C22',
          'D59',
          'B35',
          'D53',
          'B47',
          'A11',
          'C30',
          'A46',
          'D1',
          'C14',
          'A23',
          'D7',
          'D40',
          'D21',
          'A9',
          'C39',
          'A44',
          'D11',
          'D37',
          'B15',
          'A15',
          'D34',
          'B27',
          'C49',
          'D60',
          'A28',
          'D30',
          'C23',
          'B34',
          'A55',
          'C60',
          'C42',
          'A58',
          'C38',
          'B19',
          'C24',
          'B59',
          'D47',
          'B22',
          'C27',
          'C54',
          'B7',
          'C46',
          'A43',
          'D2',
          'A19',
          'D12',
          'A41',
          'A18',
          'C3',
          'A35',
          'B56',
          'A7',
          'D34',
          'C49',
          'B43',
          'A46',
          'B54',
          'D49',
          'A53',
          'D44',
          'B36',
          'D25',
          'C58',
          'D13',
          'A20',
          'B10',
          'B53',
          'A10',
          'B28',
          'D47',
          'C17',
          'D52',
          'B52',
          'D51',
          'C38',
          'B22',
          'A24',
          'B26',
          'A15',
          'C7',
          'D6',
          'A14',
          'C9',
          'B27',
          'D21',
          'C26',
          'B47',
          'A27',
          'A1',
          'D50',
          'A20',
          'B56',
          'D16',
          'C49',
          'B7',
          'D40',
          'D25',
          'C42',
          'D3',
          'D42',
          'A56',
          'B13',
          'C19',
          'A14',
          'C16',
          'B42',
          'A2',
          'A45',
          'B48',
          'C8',
          'D2',
          'D43',
          'A41',
          'A8',
          'D29',
          'A31',
          'B43',
          'C58',
          'C33',
          'B55',
          'A60',
          'D59',
          'C35',
          'D28',
          'B6',
          'C18',
          'D35',
          'C23',
          'A13',
          'B32',
          'C53',
          'B14',
          'D38',
          'C56',
          'A56',
          'D14',
          'B31',
          'D55',
          'B17',
          'D27',
          'C29',
          'A52',
          'D59',
          'A18',
          'B12',
          'D22',
          'C37',
          'D26',
          'A3',
          'B53',
          'D50',
          'A47',
          'B39',
          'A22',
          'C4',
          'A33',
          'C26',
          'A35',
          'C16',
          'D60',
          'B42',
          'A9',
          'C36',
          'A48',
          'B8',
          'C2',
          'B9',
          'D34',
          'B44',
          'D55',
          'B50',
          'D53',
          'C23',
          'B59',
          'D44',
          'D1',
          'A25',
          'D57',
          'A49',
          'B36',
          'C10',
          'B46',
          'A36',
          'C11',
          'A4',
          'B58',
          'A8',
          'D22',
          'D6',
          'C41',
          'B29',
          'A15',
          'D41',
          'A54',
          'D28',
          'A60',
          'D13',
          'C52',
          'D17',
          'C34',
          'A51',
          'C51',
          'A28',
          'B10',
          'D26',
          'B13',
          'C28',
          'B16',
          'A14',
          'D57',
          'C42',
          'A6',
          'A40',
          'B42',
          'C12',
          'D40',
          'C30',
          'A12',
          'D44',
          'A44',
          'C22',
          'B39',
          'A5',
          'A42',
          'D11',
          'C39',
          'A45',
          'D39',
          'D7',
          'B12',
          'C56',
          'B49',
          'C34',
          'D35',
          'C32',
          'B25',
          'C44',
          'D47',
          'A55',
          'C33',
          'D22',
          'B17',
          'D3',
          'B41',
          'C21',
          'B8',
          'C18',
          'B31',
          'A24',
          'C7',
          'A38',
          'D41',
          'B55',
          'A39',
          'B27',
          'A19',
          'B56',
          'D48',
          'A10',
          'B48',
          'B2',
          'C15',
          'B34',
          'C6',
          'B37',
          'C4',
          'D22',
          'A54',
          'C57',
          'B46',
          'C1',
          'A26',
          'B24',
          'D58',
          'B25',
          'A44',
          'C47',
          'A56',
          'D27',
          'B60',
          'D46',
          'A5',
          'B23',
          'D15',
          'C38',
          'A45',
          'C21',
          'A13',
          'D2',
          'A7',
          'C40',
          'B2',
          'C3',
          'B40',
          'D43',
          'D28',
          'A29',
          'B6',
          'B44',
          'D42',
          'D4',
          'C43',
          'B30',
          'A12',
          'D34',
          'D7',
          'C41',
          'A31',
          'B51',
          'D53',
          'A3',
          'D17',
          'B14',
          'A17',
          'C15',
          'B36',
          'A35',
          'D12',
          'C53',
          'B15',
          'C17',
          'B16',
          'D56',
          'B43',
          'A2',
          'D35',
          'A36',
          'B17',
          'C5',
          'B19',
          'A10',
          'C35',
          'C11',
          'B9',
          'D44',
          'B32',
          'C46',
          'B1',
          'C44',
          'B54',
          'C20',
          'A24',
          'C4',
          'A58',
          'C14',
          'A38',
          'D5',
          'C12',
          'B8',
          'D27',
          'D12',
          'B33',
          'B60',
          'A15',
          'D24',
          'B19',
          'C56',
          'B50',
          'A9',
          'C16',
          'A37',
          'C8',
          'B23',
          'D10',
          'A25',
          'A4',
          'C49',
          'D39',
          'C40',
          'D19',
          'C9',
          'A23',
          'B35',
          'A16',
          'D44',
          'B22',
          'A55',
          'C29',
          'D52',
          'B20',
          'D21',
          'A47',
          'A1',
          'D54',
          'B48',
          'D58',
          'C36',
          'A59',
          'D45',
          'A60',
          'C51',
          'D15',
          'B26',
          'C44',
          'B27',
          'C8',
          'B44',
          'B59',
          'A24',
          'B42',
          'D57',
          'A51',
          'D18',
          'C29',
          'A34',
          'A10',
          'D34',
          'C37',
          'B5',
          'D31',
          'C52',
          'A48',
          'B14',
          'A37',
          'C39',
          'D25',
          'B15',
          'A40',
          'B53',
          'C30',
          'D46',
          'A52',
          'C46',
          'D13',
          'B57',
          'C18',
          'B12',
          'D40',
          'D3',
          'A20',
          'D15',
          'D45',
          'A7',
          'C25',
          'B38',
          'C13',
          'B1',
          'D12',
          'A24',
          'B44',
          'A37',
          'D34',
          'A59',
          'B32',
          'A39',
          'B42',
          'C10',
          'A19',
          'C31',
          'D39',
          'D24',
          'A16',
          'C16',
          'D37',
          'A47',
          'D54',
          'B34',
          'C27',
          'B16',
          'B39',
          'D35',
          'A8',
          'C24',
          'D29',
          'A27',
          'B21',
          'D17',
          'B38',
          'A52',
          'C41',
          'D16',
          'C35',
          'B47',
          'A35',
          'B57',
          'D48',
          'C4',
          'A34',
          'D14',
          'B43',
          'C48',
          'A29',
          'C30',
          'B15',
          'C34',
          'A17',
          'D6',
          'B31',
          'C24',
          'D23',
          'B9',
          'C10',
          'D40',
          'C6',
          'B30',
          'C58',
          'D37',
          'B22',
          'C29',
          'D20',
          'A48',
          'C25',
          'B57',
          'A7',
          'A22',
          'B42',
          'C5',
          'A55',
          'B52',
          'D51',
          'A52',
          'B7',
          'A21',
          'B55',
          'C32',
          'A59',
          'D32',
          'D7',
          'A16',
          'B27',
          'C7',
          'C30',
          'A48',
          'C48',
          'A4',
          'A42',
          'C27',
          'B35',
          'C56',
          'D43',
          'D59',
          'B37',
          'C15',
          'D13',
          'C44',
          'A33',
          'B18',
          'A31',
          'C17',
          'A32',
          'C1',
          'B5',
          'C14',
          'D2',
          'B2',
          'D28',
          'A24',
          'D1',
          'C53',
          'B36',
          'C51',
          'D24',
          'C13',
          'A19',
          'D51',
          'B48',
          'D34',
          'B58',
          'D47',
          'A43',
          'B13',
          'A41',
          'B28',
          'D9',
          'D29',
          'C9',
          'A28',
          'B19',
          'C31',
          'A4',
          'C27',
          'B5',
          'C19',
          'B33',
          'A30',
          'C28',
          'A21',
          'D14',
          'B27',
          'A15',
          'D37',
          'A7',
          'D18',
          'A2',
          'B35',
          'C24',
          'D50',
          'B53',
          'A14',
          'D24',
          'C1',
          'B40',
          'D58',
          'A32',
          'D10',
          'B2',
          'C39',
          'B3',
          'D33',
          'A13',
          'C18',
          'D25',
          'C32',
          'C53',
          'C17',
          'A2',
          'B48',
          'A27',
          'C52',
          'B58',
          'C16',
          'B43',
          'D54',
          'B46',
          'D9',
          'B11',
          'C13',
          'B35',
          'C23',
          'D55',
          'B45',
          'A29',
          'B18',
          'C32',
          'D36',
          'B25',
          'A26',
          'C42',
          'B30',
          'C5',
          'B20',
          'C28',
          'D48',
          'A54',
          'C30',
          'D16',
          'A52',
          'C39',
          'A56',
          'D41',
          'A13',
          'C45',
          'A57',
          'C54',
          'D56',
          'B18',
          'A18',
          'D5',
          'C44',
          'B39',
          'C19',
          'D22',
          'B26',
          'A8',
          'D49',
          'B54',
          'C43',
          'D8',
          'D32',
          'C50',
          'A28',
          'C33',
          'B50',
          'D54',
        ],
      },
    ],
  },
  {
    title: 'Martin Luther King Jr.',
    id: 'martin-luther-king-jr.',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Martin%20Luther%20King%20Jr..png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'A37',
          'A53',
          'A35',
          'A54',
          'A33',
          'A18',
          'A36',
          'A15',
          'A38',
          'A14',
          'A39',
          'A13',
          'A41',
          'B1',
          'A40',
          'A11',
          'A42',
          'A57',
          'A34',
          'A17',
          'C8',
          'D30',
          'C7',
          'D28',
          'D59',
          'D29',
          'C10',
          'D31',
          'C12',
          'D27',
          'C1',
          'A14',
          'A29',
          'A49',
          'A27',
          'A50',
          'A25',
          'A51',
          'A24',
          'A8',
          'A42',
          'A7',
          'A33',
          'A58',
          'B14',
          'A56',
          'B17',
          'A55',
          'A32',
          'C57',
          'A35',
          'A17',
          'C7',
          'D31',
          'C6',
          'D28',
          'C11',
          'D29',
          'C9',
          'D27',
          'D56',
          'C12',
          'A22',
          'C10',
          'A19',
          'C8',
          'D26',
          'D55',
          'A12',
          'C2',
          'A13',
          'A41',
          'A10',
          'A43',
          'A7',
          'A44',
          'B6',
          'B23',
          'A56',
          'B25',
          'B4',
          'A40',
          'A17',
          'C5',
          'D30',
          'C4',
          'A14',
          'D60',
          'D27',
          'C8',
          'D29',
          'C15',
          'D31',
          'C19',
          'D32',
          'C23',
          'D33',
          'C18',
          'D28',
          'D58',
          'A15',
          'C6',
          'A18',
          'A37',
          'C57',
          'A38',
          'B1',
          'B29',
          'B44',
          'B17',
          'A50',
          'B18',
          'B36',
          'A60',
          'B30',
          'A56',
          'A31',
          'C59',
          'A34',
          'C53',
          'A35',
          'C51',
          'D52',
          'C56',
          'A32',
          'C37',
          'A26',
          'C34',
          'A27',
          'A1',
          'A29',
          'C60',
          'A30',
          'C36',
          'A33',
          'C54',
          'D53',
          'C9',
          'A23',
          'C11',
          'A20',
          'C5',
          'D31',
          'C4',
          'A18',
          'C1',
          'A13',
          'C7',
          'D29',
          'C6',
          'A16',
          'C10',
          'D26',
          'C13',
          'D28',
          'D57',
          'A10',
          'D56',
          'A15',
          'D60',
          'A19',
          'C29',
          'A21',
          'C11',
          'A24',
          'A52',
          'B25',
          'B2',
          'B27',
          'B46',
          'B16',
          'B1',
          'A37',
          'C51',
          'D54',
          'A13',
          'C5',
          'A11',
          'C3',
          'D30',
          'C20',
          'D29',
          'D58',
          'A8',
          'A26',
          'A3',
          'A28',
          'C33',
          'A30',
          'A46',
          'B3',
          'B35',
          'B50',
          'B26',
          'B54',
          'B33',
          'A56',
          'A41',
          'A9',
          'D57',
          'A16',
          'D54',
          'C53',
          'D50',
          'C56',
          'A39',
          'B4',
          'B21',
          'A57',
          'B37',
          'A59',
          'B42',
          'B60',
          'B25',
          'B56',
          'B15',
          'B47',
          'B9',
          'B24',
          'D1',
          'B38',
          'B53',
          'B8',
          'B48',
          'B2',
          'A37',
          'C49',
          'D47',
          'A3',
          'D48',
          'C52',
          'D51',
          'A13',
          'D59',
          'D25',
          'C11',
          'D28',
          'C16',
          'D30',
          'C2',
          'A17',
          'C27',
          'D32',
          'C26',
          'A16',
          'A46',
          'A14',
          'C9',
          'A24',
          'A53',
          'A23',
          'C10',
          'D54',
          'A11',
          'D56',
          'C52',
          'A36',
          'C59',
          'A31',
          'C58',
          'D53',
          'A17',
          'D52',
          'A2',
          'C3',
          'D26',
          'C2',
          'A9',
          'D60',
          'A20',
          'C31',
          'D5',
          'B37',
          'B9',
          'B32',
          'A60',
          'B41',
          'B3',
          'B47',
          'B12',
          'B29',
          'B4',
          'B34',
          'D6',
          'C33',
          'A24',
          'C8',
          'A26',
          'C45',
          'A35',
          'B2',
          'B50',
          'B1',
          'B35',
          'D2',
          'B23',
          'D7',
          'C35',
          'A33',
          'A48',
          'B11',
          'B31',
          'A55',
          'A29',
          'C38',
          'A31',
          'A58',
          'A42',
          'A59',
          'B44',
          'B59',
          'B7',
          'B49',
          'B3',
          'B54',
          'B6',
          'B33',
          'D7',
          'C36',
          'A32',
          'C55',
          'C40',
          'D8',
          'C39',
          'A28',
          'C44',
          'A8',
          'D47',
          'C54',
          'D60',
          'D27',
          'D55',
          'A16',
          'C3',
          'A20',
          'D59',
          'A14',
          'D53',
          'C56',
          'A41',
          'A59',
          'A31',
          'C58',
          'C4',
          'D25',
          'C9',
          'A12',
          'D52',
          'A18',
          'D50',
          'A1',
          'C2',
          'A11',
          'D48',
          'C49',
          'A38',
          'C48',
          'D43',
          'A2',
          'A23',
          'C46',
          'D54',
          'C33',
          'D32',
          'C25',
          'D30',
          'C11',
          'A17',
          'C9',
          'A25',
          'C40',
          'A36',
          'C50',
          'D53',
          'A16',
          'D51',
          'A4',
          'A20',
          'D52',
          'C59',
          'D58',
          'D29',
          'C6',
          'A26',
          'A49',
          'B18',
          'D3',
          'B22',
          'D8',
          'B32',
          'B7',
          'B40',
          'B4',
          'B46',
          'B10',
          'B58',
          'B25',
          'B55',
          'B3',
          'A37',
          'C40',
          'D9',
          'B21',
          'D4',
          'C30',
          'D5',
          'B41',
          'A58',
          'B43',
          'B18',
          'D10',
          'B31',
          'B56',
          'B5',
          'B24',
          'D6',
          'C32',
          'D55',
          'D27',
          'C17',
          'D29',
          'C13',
          'A23',
          'C42',
          'A9',
          'D46',
          'A13',
          'A46',
          'A12',
          'C1',
          'A19',
          'D50',
          'A8',
          'D53',
          'C36',
          'A35',
          'C41',
          'A24',
          'C10',
          'A28',
          'C8',
          'D27',
          'C21',
          'D28',
          'D56',
          'C31',
          'D32',
          'C15',
          'B45',
          'B13',
          'D4',
          'C29',
          'D57',
          'A7',
          'C47',
          'D53',
          'A1',
          'C4',
          'A10',
          'C41',
          'D54',
          'C57',
          'C5',
          'A26',
          'C40',
          'A38',
          'C39',
          'A39',
          'A59',
          'B38',
          'A56',
          'A29',
          'C37',
          'D8',
          'C38',
          'D52',
          'A15',
          'D49',
          'A20',
          'C47',
          'D40',
          'C49',
          'A5',
          'A44',
          'B9',
          'D5',
          'B12',
          'B57',
          'B4',
          'A37',
          'A17',
          'D51',
          'A24',
          'C31',
          'D33',
          'C30',
          'D56',
          'A13',
          'D53',
          'C34',
          'D7',
          'B36',
          'B54',
          'B7',
          'D2',
          'B41',
          'B26',
          'B52',
          'B9',
          'D1',
          'B5',
          'B33',
          'D3',
          'B6',
          'B60',
          'B14',
          'B29',
          'D11',
          'B17',
          'B45',
          'A59',
          'A30',
          'C27',
          'D58',
          'C55',
          'A41',
          'C48',
          'D44',
          'A2',
          'D45',
          'C59',
          'C6',
          'A12',
          'D47',
          'A17',
          'A44',
          'A4',
          'C7',
          'A25',
          'D50',
          'A14',
          'D55',
          'C45',
          'A23',
          'D52',
          'A10',
          'D49',
          'A1',
          'C43',
          'D56',
          'A7',
          'D54',
          'C55',
          'D59',
          'C26',
          'D1',
          'B13',
          'B52',
          'B2',
          'B36',
          'A60',
          'B48',
          'B14',
          'B57',
          'B42',
          'B19',
          'D9',
          'B31',
          'B10',
          'D7',
          'C35',
          'A35',
          'A55',
          'B34',
          'D15',
          'B28',
          'D12',
          'B16',
          'B55',
          'B6',
          'A40',
          'C39',
          'A11',
          'D53',
          'A22',
          'C47',
          'A8',
          'C1',
          'D30',
          'C28',
          'D2',
          'C27',
          'A15',
          'D47',
          'A21',
          'D59',
          'D26',
          'C2',
          'A3',
          'A44',
          'A2',
          'D51',
          'C57',
          'D52',
          'A26',
          'C3',
          'C54',
          'A33',
          'C42',
          'D9',
          'C41',
          'D57',
          'C28',
          'D31',
          'C24',
          'D32',
          'C21',
          'A16',
          'C11',
          'A22',
          'A53',
          'A21',
          'D49',
          'A24',
          'D58',
          'A6',
          'A43',
          'B10',
          'B51',
          'B33',
          'B18',
          'B54',
          'B2',
          'A34',
          'C52',
          'D53',
          'A9',
          'D44',
          'A7',
          'D48',
          'A19',
          'D51',
          'C60',
          'C8',
          'A30',
          'D49',
          'A17',
          'C26',
          'D11',
          'B30',
          'B58',
          'B8',
          'D6',
          'B11',
          'D10',
          'B20',
          'D5',
          'C33',
          'A31',
          'C43',
          'A8',
          'A27',
          'A56',
          'B27',
          'D16',
          'B40',
          'D7',
          'C34',
          'D31',
          'C32',
          'D54',
          'A15',
          'C4',
          'D25',
          'C14',
          'B44',
          'D4',
          'B48',
          'B11',
          'B58',
          'B4',
          'A36',
          'C41',
          'D10',
          'C23',
          'D59',
          'C15',
          'D33',
          'C11',
          'A27',
          'D51',
          'A31',
          'A48',
          'B14',
          'D13',
          'B15',
          'B44',
          'B5',
          'D2',
          'C26',
          'D12',
          'B19',
          'B40',
          'A59',
          'A29',
          'C44',
          'D55',
          'A10',
          'C40',
          'D58',
          'A22',
          'D47',
          'A24',
          'D53',
          'A18',
          'C9',
          'D25',
          'D57',
          'C60',
          'C45',
          'A27',
          'D49',
          'A31',
          'C37',
          'A39',
          'A54',
          'B31',
          'D4',
          'C29',
          'A33',
          'D51',
          'A14',
          'D58',
          'C25',
          'D11',
          'B40',
          'C12',
          'D24',
          'C10',
          'A6',
          'C49',
          'D54',
          'A17',
          'C48',
          'A42',
          'C39',
          'D9',
          'B35',
          'B20',
          'D8',
          'C36',
          'D6',
          'C32',
          'D14',
          'B13',
          'B56',
          'B22',
          'A51',
          'A23',
          'D50',
          'A20',
          'C30',
          'D3',
          'B37',
          'A60',
          'B49',
          'C19',
          'D27',
          'D55',
          'A6',
          'C6',
          'A19',
          'C4',
          'A28',
          'D47',
          'C60',
          'B40',
          'B60',
          'B10',
          'B35',
          'B4',
          'B59',
          'B24',
          'D17',
          'C41',
          'B54',
          'C42',
          'A34',
          'D53',
          'A25',
          'C46',
          'D10',
          'C22',
          'D26',
          'C9',
          'A29',
          'C43',
          'B55',
          'B32',
          'D16',
          'C38',
          'A12',
          'D60',
          'C21',
          'A9',
          'D55',
          'C31',
          'A39',
          'B6',
          'D1',
          'B8',
          'B39',
          'C11',
          'B41',
          'A1',
          'D41',
          'C47',
          'A42',
          'B2',
          'A41',
          'C40',
          'D10',
          'B17',
          'D13',
          'C30',
          'A22',
          'C52',
          'B31',
          'D17',
          'B26',
          'B11',
          'D9',
          'C21',
          'D33',
          'C10',
          'A13',
          'D52',
          'A30',
          'A8',
          'C34',
          'A29',
          'C3',
          'D25',
          'D57',
          'A36',
          'D56',
          'C42',
          'B55',
          'B40',
          'A57',
          'A26',
          'C14',
          'B45',
          'B60',
          'C25',
          'A15',
          'D50',
          'A34',
          'D58',
          'C38',
          'A42',
          'C32',
          'D32',
          'C17',
          'B46',
          'A59',
          'A28',
          'A2',
          'C41',
          'A37',
          'B6',
          'D4',
          'C28',
          'D12',
          'B34',
          'D10',
          'C45',
          'A6',
          'D45',
          'A18',
          'D51',
          'A21',
          'A54',
          'A32',
          'C43',
          'D18',
          'B23',
          'B60',
          'B18',
          'A48',
          'B19',
          'D11',
          'C24',
          'B59',
          'B9',
          'D8',
          'B35',
          'D6',
          'B36',
          'B51',
          'B1',
          'B27',
          'D13',
          'C29',
          'A34',
          'D49',
          'C58',
          'C3',
          'C20',
          'A16',
          'D43',
          'A10',
          'C7',
          'C56',
          'D57',
          'D26',
          'C18',
          'D8',
          'B12',
          'B30',
          'C51',
          'D55',
          'D27',
          'C16',
          'B46',
          'C15',
          'B43',
          'B6',
          'B50',
          'C21',
          'A15',
          'A40',
          'C49',
          'B57',
          'C48',
          'A18',
          'C2',
          'A31',
          'D48',
          'A21',
          'D54',
          'A33',
          'C8',
          'A14',
          'D52',
          'C53',
          'B32',
          'A54',
          'A20',
          'D55',
          'D28',
          'C26',
          'A28',
          'D50',
          'A26',
          'D46',
          'A24',
          'C4',
          'A40',
          'C37',
          'B52',
          'B1',
          'B40',
          'C59',
          'D53',
          'A12',
          'C34',
          'D4',
          'B17',
          'D17',
          'C40',
          'A27',
          'C5',
          'D24',
          'D60',
          'C38',
          'B53',
          'B19',
          'D16',
          'B33',
          'D13',
          'B18',
          'D18',
          'C42',
          'A9',
          'D58',
          'C28',
          'D3',
          'C31',
          'A40',
          'C2',
          'A29',
          'D46',
          'A19',
          'C55',
          'C5',
          'A22',
          'C43',
          'D9',
          'C20',
          'A17',
          'D56',
          'A37',
          'A52',
          'B27',
          'B48',
          'B4',
          'A35',
          'D58',
          'A30',
          'C60',
          'B41',
          'C59',
          'D59',
          'A29',
          'C11',
          'B42',
          'D5',
          'C30',
          'D57',
          'D26',
          'C2',
          'C24',
          'A23',
          'D54',
          'C45',
          'B56',
          'C22',
          'A8',
          'C53',
          'A47',
          'B19',
          'D6',
          'C37',
          'A34',
          'B4',
          'B26',
          'D14',
          'B14',
          'D7',
          'C16',
          'B44',
          'D30',
          'C33',
          'A44',
          'A60',
          'B43',
          'A3',
          'D49',
          'A35',
          'D53',
          'A37',
          'C36',
          'D15',
          'C35',
          'A47',
          'A12',
          'D57',
          'C39',
          'A30',
          'D50',
          'A33',
          'D59',
          'C25',
          'A21',
          'A4',
          'C2',
          'A32',
          'C55',
          'B33',
          'B11',
          'A42',
          'C37',
          'B52',
          'C23',
          'B57',
          'C47',
          'A22',
          'C13',
          'B43',
          'C12',
          'D25',
          'C20',
          'D10',
          'C44',
          'A30',
          'C10',
          'B38',
          'B9',
          'D7',
          'B21',
          'D1',
          'B36',
          'B10',
          'D4',
          'C33',
          'A45',
          'C55',
          'A21',
          'C50',
          'B30',
          'D18',
          'B16',
          'A52',
          'C43',
          'D10',
          'C19',
          'C3',
          'A28',
          'C14',
          'D27',
          'C8',
          'D33',
          'C28',
          'D13',
          'A17',
          'D16',
          'B25',
          'D5',
          'B7',
          'A39',
          'C51',
          'B31',
          'C50',
          'B58',
          'C23',
          'B59',
          'B44',
          'A13',
          'B45',
          'B4',
          'B51',
          'C35',
          'D6',
          'C33',
          'A46',
          'B20',
          'D2',
          'B12',
          'D12',
          'A14',
          'D42',
          'A11',
          'C41',
          'A23',
          'C12',
          'D32',
          'C36',
          'B52',
          'B3',
          'A41',
          'C1',
          'A33',
          'D48',
          'A35',
          'C42',
          'A40',
          'D60',
          'A13',
          'C37',
          'D52',
          'A25',
          'D51',
          'A37',
          'C11',
          'B57',
          'B21',
          'B39',
          'C57',
          'A16',
          'B47',
          'D31',
          'C27',
          'B55',
          'B2',
          'B42',
          'B7',
          'D3',
          'B11',
          'A40',
          'C36',
          'C21',
          'A10',
          'D54',
          'D20',
          'C48',
          'D55',
          'D28',
          'B35',
          'A55',
          'A20',
          'D16',
          'A19',
          'D56',
          'C40',
          'B54',
          'C20',
          'D26',
          'C17',
          'B47',
          'A59',
          'A26',
          'D45',
          'A22',
          'C29',
          'D29',
          'D57',
          'A35',
          'C14',
          'D6',
          'B40',
          'C57',
          'D21',
          'C55',
          'B38',
          'C54',
          'A34',
          'D54',
          'A36',
          'D52',
          'C60',
          'D38',
          'C47',
          'A44',
          'C39',
          'D17',
          'B18',
          'D12',
          'B29',
          'A57',
          'B41',
          'A2',
          'B42',
          'C10',
          'A32',
          'D55',
          'C58',
          'C7',
          'D33',
          'C6',
          'B30',
          'D10',
          'C40',
          'D7',
          'C33',
          'A47',
          'C31',
          'D14',
          'B16',
          'A58',
          'B39',
          'B5',
          'B34',
          'C55',
          'C2',
          'C37',
          'A17',
          'B46',
          'A15',
          'D57',
          'C26',
          'D15',
          'B13',
          'B28',
          'D13',
          'C22',
          'B33',
          'C21',
          'B51',
          'C38',
          'A38',
          'D50',
          'A4',
          'C6',
          'A24',
          'C47',
          'B58',
          'C49',
          'A7',
          'C45',
          'A27',
          'C4',
          'A12',
          'B44',
          'A58',
          'A32',
          'C33',
          'D53',
          'B17',
          'A52',
          'C36',
          'D5',
          'B49',
          'D31',
          'C22',
          'B59',
          'C17',
          'D8',
          'A23',
          'C8',
          'B36',
          'C7',
          'D27',
          'C12',
          'B21',
          'A45',
          'C39',
          'A46',
          'A2',
          'B43',
          'D10',
          'B19',
          'D57',
          'A31',
          'D18',
          'D56',
          'B24',
          'D20',
          'C47',
          'C4',
          'D33',
          'C26',
          'B55',
          'C44',
          'A50',
          'C35',
          'D29',
          'B39',
          'C54',
          'A21',
          'C48',
          'D11',
          'A15',
          'B47',
          'D9',
          'C38',
          'A25',
          'A59',
          'A23',
          'D59',
          'A8',
          'D43',
          'A13',
          'D12',
          'C27',
          'D3',
          'B19',
          'A44',
          'B22',
          'C12',
          'B56',
          'C44',
          'A51',
          'A22',
          'C25',
          'D15',
          'C34',
          'A43',
          'C2',
          'A37',
          'D52',
          'A32',
          'C9',
          'B38',
          'B23',
          'D21',
          'D54',
          'D22',
          'C1',
          'C21',
          'B55',
          'C42',
          'A53',
          'B29',
          'C51',
          'B32',
          'C50',
          'A39',
          'C5',
          'A7',
          'C10',
          'B56',
          'B3',
          'B57',
          'C32',
          'C13',
          'D23',
          'D54',
          'A24',
          'C24',
          'B53',
          'B25',
          'D18',
          'A32',
          'D47',
          'A25',
          'C14',
          'A31',
          'C3',
          'A12',
          'C49',
          'D19',
          'D56',
          'A40',
          'B2',
          'B39',
          'D12',
          'B15',
          'D17',
          'A19',
          'A55',
          'A17',
          'C57',
          'C9',
          'B56',
          'C43',
          'A33',
          'C7',
          'A11',
          'D11',
          'C46',
          'A25',
          'C6',
          'A34',
          'D47',
          'A9',
          'D31',
          'C38',
          'B60',
          'B4',
          'B53',
          'C41',
          'B55',
          'B23',
          'A44',
          'C45',
          'A28',
          'D48',
          'A36',
          'D19',
          'A37',
          'B7',
          'B22',
          'D54',
          'D25',
          'C19',
          'D32',
          'C16',
          'C31',
          'D6',
          'B16',
          'D11',
          'A14',
          'B46',
          'A58',
          'A3',
          'D26',
          'C58',
          'B41',
          'C14',
          'A47',
          'B14',
          'D15',
          'C24',
          'A25',
          'D57',
          'B23',
          'A43',
          'C1',
          'B29',
          'C44',
          'A21',
          'D6',
          'C13',
          'B44',
          'A4',
          'B34',
          'B53',
          'C37',
          'D16',
          'A16',
          'C51',
          'D19',
          'D55',
          'A33',
          'B4',
          'B37',
          'A56',
          'A38',
          'D52',
          'C42',
          'A54',
          'C36',
          'C3',
          'A39',
          'C43',
          'B21',
          'C2',
          'A14',
          'C59',
          'D54',
          'D26',
          'C24',
          'B34',
          'D10',
          'B10',
          'B49',
          'C37',
          'B60',
          'C27',
          'D13',
          'C21',
          'B56',
          'C31',
          'A21',
          'D14',
          'A12',
          'D41',
          'A15',
          'D59',
          'D22',
          'D58',
          'B18',
          'D54',
          'A29',
          'D15',
          'B25',
          'A43',
          'C48',
          'A19',
          'C11',
          'D32',
          'C35',
          'C5',
          'B28',
          'B59',
          'C53',
          'B38',
          'D30',
          'A10',
          'C52',
          'B30',
          'C49',
          'B31',
          'C19',
          'B60',
          'B21',
          'D19',
          'A34',
          'C3',
          'A42',
          'B27',
          'C17',
          'A13',
          'B46',
          'C32',
          'D4',
          'C27',
          'B6',
          'B28',
          'C4',
          'A30',
          'D16',
          'A18',
          'C7',
          'A23',
          'B13',
          'A53',
          'C54',
          'D21',
          'C48',
          'C3',
          'D33',
          'A43',
          'D60',
          'B29',
          'D7',
          'B1',
          'B53',
          'D9',
          'A24',
          'C41',
          'A40',
          'D52',
          'B18',
          'A46',
          'C36',
          'B49',
          'A8',
          'C19',
          'D11',
          'C45',
          'A48',
          'C14',
          'C29',
          'D1',
          'C11',
          'B20',
          'C10',
          'D24',
          'C15',
          'A30',
          'D46',
          'A10',
          'B42',
          'D30',
          'B45',
          'A16',
          'C49',
          'B32',
          'A47',
          'B15',
          'D15',
          'A26',
          'C46',
          'B55',
          'C45',
          'D31',
          'C37',
          'C13',
          'A21',
          'B14',
          'C36',
          'B50',
          'B5',
          'C40',
          'A29',
          'C28',
          'B7',
          'D4',
          'C10',
          'D34',
          'A44',
          'C56',
          'B41',
          'A54',
          'D59',
          'D17',
          'A35',
          'B25',
          'D56',
          'A31',
          'C11',
          'A26',
          'A50',
          'B9',
          'A49',
          'C45',
          'D19',
          'C43',
          'D11',
          'B33',
          'C54',
          'A46',
          'C7',
          'A38',
          'D20',
          'B29',
          'B52',
          'B37',
          'C8',
          'A32',
          'D49',
          'A37',
          'B24',
          'D57',
          'C36',
          'B60',
          'C18',
          'B48',
          'A7',
          'B36',
          'A6',
          'C23',
          'D12',
          'B21',
          'C1',
          'A39',
          'B23',
          'C14',
          'D32',
          'C29',
          'D5',
          'B43',
          'A1',
          'B40',
          'D10',
          'A13',
          'C9',
          'A22',
          'D7',
          'A60',
          'A45',
          'B18',
          'A27',
          'D45',
          'A11',
          'C22',
          'B34',
          'A46',
          'C20',
          'D31',
          'A12',
          'C40',
          'D30',
          'C39',
          'B5',
          'B55',
          'C29',
          'A47',
          'B16',
          'D55',
          'D19',
          'B24',
          'A36',
          'C58',
          'D56',
          'A23',
          'D17',
          'D57',
          'A52',
          'C35',
          'B58',
          'C21',
          'D14',
          'B32',
          'C23',
          'C54',
          'B59',
          'C50',
          'B29',
          'A39',
          'D20',
          'D36',
          'C34',
          'B51',
          'C6',
          'D34',
          'A47',
          'C48',
          'A21',
          'C10',
          'D5',
          'A58',
          'C44',
          'A31',
          'B25',
          'A29',
          'D53',
          'B23',
          'A38',
          'C57',
          'D22',
          'C54',
          'A55',
          'C42',
          'B20',
          'D20',
          'B39',
          'C60',
          'D37',
          'C33',
          'B10',
          'C32',
          'B11',
          'B57',
          'C31',
          'B58',
          'B3',
          'D9',
          'B41',
          'C12',
          'B60',
          'C17',
          'A6',
          'B47',
          'A19',
          'B48',
          'A16',
          'D13',
          'C26',
          'A18',
          'D17',
          'A27',
          'C9',
          'D32',
          'C45',
          'B28',
          'C44',
          'B54',
          'B24',
          'A32',
          'D11',
          'A10',
          'C50',
          'D19',
          'C42',
          'D40',
          'C39',
          'D59',
          'A28',
          'D18',
          'A37',
          'C8',
          'B60',
          'A50',
          'B13',
          'A22',
          'D60',
          'C25',
          'B58',
          'C15',
          'B26',
          'D12',
          'C29',
          'A35',
          'D52',
          'B15',
          'A19',
          'C51',
          'B57',
          'C46',
          'A21',
          'C1',
          'A44',
          'B20',
          'A40',
          'D51',
          'A26',
          'C31',
          'B46',
          'B31',
          'C48',
          'D39',
          'C57',
          'A18',
          'C20',
          'B32',
          'D27',
          'D54',
          'A27',
          'D60',
          'D16',
          'A21',
          'D8',
          'C36',
          'A55',
          'D4',
          'C9',
          'D26',
          'C15',
          'D7',
          'B47',
          'D3',
          'B8',
          'C29',
          'B57',
          'B5',
          'A3',
          'B34',
          'C4',
          'A41',
          'D32',
          'A13',
          'D15',
          'B20',
          'D58',
          'D23',
          'C11',
          'A12',
          'C32',
          'A17',
          'B15',
          'C36',
          'C14',
          'A50',
          'C45',
          'A53',
          'C43',
          'B54',
          'B28',
          'D11',
          'C44',
          'B29',
          'D59',
          'D18',
          'A35',
          'B27',
          'D14',
          'C26',
          'D16',
          'B14',
          'A20',
          'C2',
          'D33',
          'A46',
          'B16',
          'D51',
          'B22',
          'A40',
          'C57',
          'B42',
          'C14',
          'A36',
          'C9',
          'B20',
          'D9',
          'B34',
          'D3',
          'C11',
          'A18',
          'D12',
          'C49',
          'B59',
          'C39',
          'B6',
          'C26',
          'D25',
          'C10',
          'A26',
          'B11',
          'A48',
          'B12',
          'A24',
          'D8',
          'B2',
          'B45',
          'A4',
          'D24',
          'C21',
          'B34',
          'A2',
          'D26',
          'D57',
          'A53',
          'D3',
          'A9',
          'C33',
          'B50',
          'C20',
          'B56',
          'B18',
          'A17',
          'D17',
          'A30',
          'C26',
          'D29',
          'C5',
          'A38',
          'D49',
          'A7',
          'C52',
          'A48',
          'B60',
          'C35',
          'B49',
          'A19',
          'B17',
          'D14',
          'C30',
          'A32',
          'D53',
          'B32',
          'C22',
          'A15',
          'C60',
          'B44',
          'A14',
          'C28',
          'D1',
          'C27',
          'A51',
          'B6',
          'B38',
          'C58',
          'B39',
          'A56',
          'C2',
          'B28',
          'A35',
          'D27',
          'C7',
          'B60',
          'C53',
          'D22',
          'C14',
          'A52',
          'C55',
          'B40',
          'D29',
          'D57',
          'A39',
          'D19',
          'A33',
          'C40',
          'B54',
          'A38',
          'D28',
          'C44',
          'A10',
          'D42',
          'A17',
          'C19',
          'D13',
          'A19',
          'B52',
          'B7',
          'C39',
          'D7',
          'B8',
          'A36',
          'C6',
          'A37',
          'B55',
          'C11',
          'B21',
          'A44',
          'C30',
          'A13',
          'C16',
          'B25',
          'A28',
          'C45',
          'A56',
          'C42',
          'B18',
          'A16',
          'D52',
          'C48',
          'B12',
          'A40',
          'B27',
          'C6',
          'D23',
          'D58',
          'D24',
          'A3',
          'B32',
          'D12',
          'C22',
          'A47',
          'B58',
          'B35',
          'C9',
          'B21',
          'D60',
          'A53',
          'B42',
          'C53',
          'B33',
          'D27',
          'D54',
          'B14',
          'C38',
          'B59',
          'C15',
          'C35',
          'D8',
          'A20',
          'B16',
          'D15',
          'C33',
          'A60',
          'D6',
          'B30',
          'C44',
          'D56',
          'A53',
          'D42',
          'A19',
          'C9',
          'A31',
          'C58',
          'D38',
          'C31',
          'B47',
          'A14',
          'D14',
          'A11',
          'B44',
          'D9',
          'C23',
          'D15',
          'C21',
          'D16',
          'D36',
          'D21',
          'C2',
          'A38',
          'C45',
          'A8',
          'D30',
          'A13',
          'B57',
          'C20',
          'C4',
          'B51',
          'B21',
          'A24',
          'C13',
          'D24',
          'A26',
          'D56',
          'B27',
          'C48',
          'B13',
          'A33',
          'B29',
          'D19',
          'A32',
          'D26',
          'C9',
          'A48',
          'D36',
          'A49',
          'B59',
          'B22',
          'D22',
          'C52',
          'A36',
          'D17',
          'C29',
          'B9',
          'A54',
          'D2',
          'C25',
          'B33',
          'C23',
          'A10',
          'C32',
          'A19',
          'C19',
          'B47',
          'B29',
          'D14',
          'C53',
          'D43',
          'A51',
          'C56',
          'D20',
          'C11',
          'B43',
          'A15',
          'B18',
          'D51',
          'A32',
          'C14',
          'A55',
          'B40',
          'C13',
          'A25',
          'B11',
          'C35',
          'A7',
          'D31',
          'C18',
          'D25',
          'D56',
          'A42',
          'D34',
          'A45',
          'C38',
          'B16',
          'A41',
          'C40',
          'A43',
          'B26',
          'D15',
          'B42',
          'B57',
          'C45',
          'B23',
          'D51',
          'A1',
          'A28',
          'D45',
          'A30',
          'D10',
          'A12',
          'D44',
          'C39',
          'B54',
          'C28',
          'B5',
          'B59',
          'C9',
          'D5',
          'A56',
          'C36',
          'B52',
          'C42',
          'A22',
          'B13',
          'D24',
          'C5',
          'B27',
          'D21',
          'D60',
          'B17',
          'A18',
          'D15',
          'A25',
          'D10',
          'B29',
          'A31',
          'D11',
          'C27',
          'B7',
          'A29',
          'D58',
          'B21',
          'C42',
          'D57',
          'A22',
          'C46',
          'A43',
          'C32',
          'D5',
          'A11',
          'C21',
          'D17',
          'A16',
          'C49',
          'A41',
          'D60',
          'A44',
          'C29',
          'B44',
          'A54',
          'B10',
          'C45',
          'D9',
          'A21',
          'B13',
          'D46',
          'A2',
          'B53',
          'B2',
          'C22',
          'A53',
          'C37',
          'D7',
          'A59',
          'B50',
          'C7',
          'D1',
          'C35',
          'D34',
          'C5',
          'A25',
          'C12',
          'D2',
          'A52',
          'D39',
          'C43',
          'B19',
          'C10',
          'A35',
          'C53',
          'A55',
          'C26',
          'D3',
          'C25',
          'D25',
          'A3',
          'B35',
          'A8',
          'B37',
          'A7',
          'B49',
          'C59',
          'D7',
          'B18',
          'A14',
          'C5',
          'B30',
          'D50',
          'A36',
          'B26',
          'C14',
          'B58',
          'C51',
          'D5',
          'B36',
          'B57',
          'C28',
          'A45',
          'B19',
          'D55',
          'D28',
          'C9',
          'D32',
          'A43',
          'B56',
          'C30',
          'A24',
          'B12',
          'A47',
          'C10',
          'D20',
          'B23',
          'D16',
          'C36',
          'A6',
          'B48',
          'C34',
          'B49',
          'B26',
          'D57',
          'A55',
          'D44',
          'A44',
          'B24',
          'B57',
          'B9',
          'C46',
          'A1',
          'D21',
          'C21',
          'A17',
          'D12',
          'C25',
          'D16',
          'C19',
          'B32',
          'D52',
          'B21',
          'D6',
          'A22',
          'A59',
          'C44',
          'D2',
          'C6',
          'A47',
          'A28',
          'B10',
          'A27',
          'C2',
          'D34',
          'C18',
          'B27',
          'C42',
          'B53',
          'A19',
          'B16',
          'C30',
          'B58',
          'C10',
          'D25',
          'C36',
          'B15',
          'A21',
          'B48',
          'A20',
          'C57',
          'C37',
          'B13',
          'C35',
          'A42',
          'B57',
          'C34',
          'D14',
          'A28',
          'C38',
          'B3',
          'B38',
          'C53',
          'B4',
          'A32',
          'C55',
          'D20',
          'C3',
          'B20',
          'C51',
          'D22',
          'A5',
          'B33',
          'D11',
          'C42',
          'A36',
          'C50',
          'A37',
          'D48',
          'B1',
          'B46',
          'D50',
          'A29',
          'B9',
          'A49',
          'C39',
          'A41',
          'D39',
          'A6',
          'B49',
          'B34',
          'D55',
          'C29',
          'B16',
          'D17',
          'C19',
          'D23',
          'C16',
          'A46',
          'C15',
          'A3',
          'C37',
          'A51',
          'D40',
          'A13',
          'B19',
          'C53',
          'D1',
          'C39',
          'B50',
          'C60',
          'A30',
          'B30',
          'D5',
          'A60',
          'D8',
          'B17',
          'D59',
          'C36',
          'D31',
          'A45',
          'C21',
          'B2',
          'B56',
          'C48',
          'D12',
          'A33',
          'D20',
          'A40',
          'B28',
          'A34',
          'C58',
          'B40',
          'C8',
          'D32',
          'A11',
          'B53',
          'C23',
          'D30',
          'C50',
          'D46',
          'B14',
          'B51',
          'B25',
          'C47',
          'B31',
          'C24',
          'B35',
          'D8',
          'C15',
          'B23',
          'D22',
          'A23',
          'B12',
          'D26',
          'A43',
          'C57',
          'B43',
          'A12',
          'D15',
          'C30',
          'D16',
          'B24',
          'D13',
          'C32',
          'A60',
          'C8',
          'A49',
          'D42',
          'A5',
          'A27',
          'D18',
          'C4',
          'A26',
          'D55',
          'A50',
          'C39',
          'D2',
          'C29',
          'A48',
          'B57',
          'A41',
          'D57',
          'D17',
          'C28',
          'A16',
          'B16',
          'C54',
          'D51',
          'C43',
          'A44',
          'C40',
          'B53',
          'A10',
          'D33',
          'C25',
          'B4',
          'D10',
          'A7',
          'D3',
          'C24',
          'D60',
          'A54',
          'D42',
          'A56',
          'C26',
          'B13',
          'B54',
          'C27',
          'A52',
          'B47',
          'A57',
          'C1',
          'D15',
          'A17',
          'D1',
          'A47',
          'C46',
          'A37',
          'C40',
          'A16',
          'D58',
          'D24',
          'B10',
          'D13',
          'B30',
          'D60',
          'D16',
          'A15',
          'C59',
          'D48',
          'A38',
          'D18',
          'C25',
          'B13',
          'D53',
          'D20',
          'D35',
          'A30',
          'C12',
          'D19',
          'B17',
          'A47',
          'C5',
          'B33',
          'C20',
          'B58',
          'C13',
          'B23',
          'D14',
          'A19',
          'D6',
          'B50',
          'C37',
          'A54',
          'C33',
          'B12',
          'B56',
          'C47',
          'D56',
          'D25',
          'C39',
          'B8',
          'D2',
          'C5',
          'A18',
          'B52',
          'B20',
          'C8',
          'B31',
          'A39',
          'C50',
          'A40',
          'D28',
          'D55',
          'B27',
          'D12',
          'C45',
          'B29',
          'B44',
          'C55',
          'D46',
          'A17',
          'B17',
          'C30',
          'A46',
          'C11',
          'A15',
          'B45',
          'A12',
          'C31',
          'B10',
          'D23',
          'B13',
          'A18',
          'C10',
          'A8',
          'D29',
          'A35',
          'C51',
          'B40',
          'A47',
          'B59',
          'C37',
          'D5',
          'C8',
          'B17',
          'C54',
          'A34',
          'D11',
          'A59',
          'C34',
          'C17',
          'A45',
          'C47',
          'D18',
          'D57',
          'A18',
          'D41',
          'A56',
          'C14',
          'A4',
          'D21',
          'D59',
          'C28',
          'B44',
          'C13',
          'C39',
          'B27',
          'C38',
          'A50',
          'D45',
          'A59',
          'B43',
          'C30',
          'B60',
          'C56',
          'B25',
          'C3',
          'A36',
          'B53',
          'A39',
          'D28',
          'C36',
          'C4',
          'D19',
          'D55',
          'A28',
          'D15',
          'B18',
          'B41',
          'A46',
          'C38',
          'D2',
          'C11',
          'D23',
          'D53',
          'A31',
          'C57',
          'C2',
          'C23',
          'D16',
          'D58',
          'B22',
          'D17',
          'C18',
          'A12',
          'B55',
          'C41',
          'A25',
          'B11',
          'C46',
          'B24',
          'D7',
          'B2',
          'C11',
          'D3',
          'A56',
          'D59',
          'B19',
          'C52',
          'A11',
          'C44',
          'A38',
          'D33',
          'A48',
          'C40',
          'B4',
          'A30',
          'C60',
          'B51',
          'B30',
          'C21',
          'D22',
          'B7',
          'A51',
          'D54',
          'B34',
          'C26',
          'D1',
          'C20',
          'B9',
          'A36',
          'D53',
          'B14',
          'A18',
          'B48',
          'D50',
          'B22',
          'A47',
          'D48',
          'A16',
          'B17',
          'C39',
          'D51',
          'A35',
          'B54',
          'A2',
          'D18',
          'C17',
          'A34',
          'C52',
          'D11',
          'C50',
          'B60',
          'B27',
          'C37',
          'D56',
          'A57',
          'D40',
          'A5',
          'B49',
          'C32',
          'D15',
          'C27',
          'A54',
          'C22',
          'D9',
          'A3',
          'A29',
          'D19',
          'A35',
          'C7',
          'D32',
          'A15',
          'B48',
          'B33',
          'D10',
          'A58',
          'B51',
          'C37',
          'B14',
          'A49',
          'C28',
          'A50',
          'B38',
          'A60',
          'B39',
          'C9',
          'B18',
          'A11',
          'C6',
          'D17',
          'C26',
          'B7',
          'D6',
          'A15',
          'B58',
          'D35',
          'D20',
          'C46',
          'B25',
          'D13',
          'C31',
          'A43',
          'C48',
          'B4',
          'C14',
          'D18',
          'D56',
          'D25',
          'D54',
          'D27',
          'A2',
          'B6',
          'B53',
          'C19',
          'D33',
          'A27',
          'B26',
          'C59',
          'D1',
          'B17',
          'D16',
          'A14',
          'D6',
          'C32',
          'A28',
          'C1',
          'B28',
          'C43',
          'A45',
          'B58',
          'A50',
          'C11',
          'D4',
          'B32',
          'D51',
          'C50',
          'B20',
          'C7',
          'B23',
          'A37',
          'D31',
          'C26',
          'A57',
          'D47',
          'A53',
          'C35',
          'D5',
          'C34',
          'B60',
          'B34',
          'C25',
          'A30',
          'C23',
          'B35',
          'A5',
          'A27',
          'B10',
          'A8',
          'D4',
          'C49',
          'A42',
          'C2',
          'B33',
          'C27',
          'D24',
          'C11',
          'A25',
          'D46',
          'A33',
          'C60',
          'D39',
          'C50',
          'B28',
          'D21',
          'A39',
          'D18',
          'B21',
          'D28',
          'B31',
          'C45',
          'D48',
          'B16',
          'D34',
          'C4',
          'C23',
          'B55',
          'B19',
          'A16',
          'B20',
          'D53',
          'C60',
          'D46',
          'A58',
          'C5',
          'D17',
          'A34',
          'B54',
          'A10',
          'D14',
          'C29',
          'D57',
          'A30',
          'B51',
          'B36',
          'A9',
          'C14',
          'B5',
          'D3',
          'C44',
          'A43',
          'D56',
          'B15',
          'A49',
          'C6',
          'C22',
          'C57',
          'D1',
          'A50',
          'B10',
          'A26',
          'D34',
          'C2',
          'A55',
          'C33',
          'A11',
          'D10',
          'A6',
          'D31',
          'A14',
          'C52',
          'A38',
          'C14',
          'B6',
          'C40',
          'A39',
          'B56',
          'B35',
          'C22',
          'D14',
          'B20',
          'A15',
          'B59',
          'C36',
          'B48',
          'D60',
          'D17',
          'C15',
          'A13',
          'C3',
          'B27',
          'C46',
          'A52',
          'D43',
          'C42',
          'D16',
          'C20',
          'A60',
          'B54',
          'A56',
          'C32',
          'B18',
          'C54',
          'D6',
          'C31',
          'B15',
          'A21',
          'B47',
          'C1',
          'A38',
          'D21',
          'D36',
          'A46',
          'B43',
          'A39',
          'D50',
          'B49',
          'A20',
          'D13',
          'B57',
          'B28',
          'B53',
          'C18',
          'A2',
          'C40',
          'B12',
          'A23',
          'C50',
          'D14',
          'C28',
          'B16',
          'A18',
          'B51',
          'C22',
          'D15',
          'C56',
          'B39',
          'A48',
          'D43',
          'A4',
          'B56',
          'C46',
          'D30',
          'C13',
          'D18',
          'C41',
          'A28',
          'C29',
          'A36',
          'C58',
          'A37',
          'D27',
          'A33',
          'D10',
          'B2',
          'D47',
          'B21',
          'C8',
          'D28',
          'B22',
          'C53',
          'D15',
          'B31',
          'A16',
          'B44',
          'C12',
          'D22',
          'B26',
          'C40',
          'A45',
          'D46',
          'C39',
          'D54',
          'A31',
          'D10',
          'A29',
          'B9',
          'A41',
          'B19',
          'C9',
          'A15',
          'D12',
          'C44',
          'A52',
          'C38',
          'B28',
          'D8',
          'A55',
          'D60',
          'B31',
          'D13',
          'A36',
          'C51',
          'B21',
          'C43',
          'A59',
          'D41',
          'A11',
          'D29',
          'C41',
          'A38',
          'C6',
          'A21',
          'C2',
          'B20',
          'D1',
          'A46',
          'D26',
          'B14',
          'A22',
          'B39',
          'C53',
          'D20',
          'D55',
          'C38',
          'C1',
          'A26',
          'D11',
          'C24',
          'B13',
          'D45',
          'C51',
          'A20',
          'C8',
          'A16',
          'D15',
          'C17',
          'D6',
          'A59',
          'C29',
          'C14',
          'B24',
          'B52',
          'A40',
          'D18',
          'D56',
          'B28',
          'C20',
          'B30',
          'B49',
          'B8',
          'B60',
          'C28',
          'A53',
          'C16',
          'D16',
          'C7',
          'D33',
          'C36',
          'A41',
          'C50',
          'B57',
          'B1',
          'D59',
          'A44',
          'B55',
          'A11',
          'C10',
          'D25',
          'B9',
          'B53',
          'B38',
          'D13',
          'C48',
          'B58',
          'B23',
          'C57',
          'D3',
          'A49',
          'C40',
          'D32',
          'C5',
          'C33',
          'B56',
          'A21',
          'D7',
          'C34',
          'B13',
          'D16',
          'C56',
          'A31',
          'D38',
          'A45',
          'C25',
          'A57',
          'B52',
          'A12',
          'B16',
          'A8',
          'D48',
          'A52',
          'B60',
          'D35',
          'C35',
          'B43',
          'A34',
          'C11',
          'D6',
          'C8',
          'A50',
          'D54',
          'D27',
          'C45',
          'D20',
          'A6',
          'D3',
          'C47',
          'A24',
          'B54',
          'C18',
          'A33',
          'C55',
          'A10',
          'B18',
          'C31',
          'B49',
          'B2',
          'B34',
          'D53',
          'C42',
          'D28',
          'A35',
          'B9',
          'C44',
          'D9',
          'B1',
          'D14',
          'C24',
          'B4',
          'D1',
          'C19',
          'A60',
          'D60',
          'A28',
          'A1',
          'D8',
          'A19',
          'C3',
          'D20',
          'C43',
          'A34',
          'D52',
          'B17',
          'A46',
          'C47',
          'A48',
          'C46',
          'A27',
          'B29',
          'D51',
          'B47',
          'A22',
          'D43',
          'A55',
          'C27',
          'B12',
          'B46',
          'A53',
          'D38',
          'B18',
          'D14',
          'A24',
          'C2',
          'B44',
          'A1',
          'D23',
          'C53',
          'B23',
          'D58',
          'D24',
          'A25',
          'B11',
          'C22',
          'B57',
          'A38',
          'D51',
          'B40',
          'A3',
          'B42',
          'B21',
          'D59',
          'B2',
          'A39',
          'D49',
          'C44',
          'C13',
          'B59',
          'C48',
          'A44',
          'C34',
          'C19',
          'D7',
          'A53',
          'C38',
          'D30',
          'A46',
          'B46',
          'C57',
          'B47',
          'C33',
          'D41',
          'A42',
          'C49',
          'B28',
          'A32',
          'D60',
          'C35',
          'B36',
          'A5',
          'D19',
          'C17',
          'D21',
          'D36',
          'C25',
          'B52',
          'A37',
          'D58',
          'B25',
          'D23',
          'C14',
          'B43',
          'A55',
          'C22',
          'D16',
          'B15',
          'A20',
          'A2',
          'B55',
          'A10',
          'C35',
          'B14',
          'D45',
          'A54',
          'D4',
          'C47',
          'B12',
          'D54',
          'B31',
          'A15',
          'C52',
          'B41',
          'C32',
          'B1',
          'B42',
          'A56',
          'D39',
          'A8',
          'D2',
          'C23',
          'A49',
          'C36',
          'B21',
          'D14',
          'A18',
          'C12',
          'B19',
          'C11',
          'D19',
          'C48',
          'B49',
          'C52',
          'D1',
          'B22',
          'C43',
          'D26',
          'A47',
          'C15',
          'D21',
          'D53',
          'A33',
          'C53',
          'A35',
          'D12',
          'B32',
          'C33',
          'D7',
          'D59',
          'A34',
          'B9',
          'A28',
          'D5',
          'C28',
          'A20',
          'C26',
          'B14',
          'A22',
          'B40',
          'B23',
          'C6',
          'D10',
          'C25',
          'B54',
          'C24',
          'B16',
          'C1',
          'D34',
          'A31',
          'C31',
          'B59',
          'B34',
          'D7',
          'C11',
          'D31',
          'C10',
          'B2',
          'D16',
          'A13',
          'B20',
          'A8',
          'B48',
          'A14',
          'D15',
          'C20',
          'D17',
          'C44',
          'B1',
          'D6',
          'B41',
          'C31',
          'B6',
          'A51',
          'C39',
          'B37',
          'A5',
          'D31',
          'C42',
          'B29',
          'A26',
          'B10',
          'A6',
          'C11',
          'B22',
          'D52',
          'A48',
          'C43',
          'D3',
          'C38',
          'A59',
          'C33',
          'A16',
          'B60',
          'C55',
          'D12',
          'C51',
          'A38',
          'B32',
          'B6',
          'A30',
          'B52',
          'C35',
          'A54',
          'B45',
          'A3',
          'B53',
          'D56',
          'B33',
          'C26',
          'B42',
          'A47',
          'D21',
          'D36',
          'C18',
          'D17',
          'D60',
          'B16',
          'D5',
          'A27',
          'C6',
          'A50',
          'D34',
          'C40',
          'B13',
          'A23',
          'C49',
          'A39',
          'B46',
          'A20',
          'B19',
          'D20',
          'D55',
          'C11',
          'D24',
          'B12',
          'C46',
          'B58',
          'A40',
          'C41',
          'B29',
          'A17',
          'B30',
          'D17',
          'C42',
          'C14',
          'A57',
          'C45',
          'A29',
          'C16',
          'D19',
          'D59',
          'D22',
          'A4',
          'A27',
          'D10',
          'C5',
          'A59',
          'C4',
          'D2',
          'C22',
          'B48',
          'C38',
          'B17',
          'B57',
          'C24',
          'B60',
          'C12',
          'A49',
          'C29',
          'B56',
          'A3',
          'D44',
          'A60',
          'B50',
          'C32',
          'D21',
          'B6',
          'C30',
          'B31',
          'D50',
          'A37',
          'C9',
          'D26',
          'D56',
          'A32',
          'C44',
          'B20',
          'C1',
          'A31',
          'A54',
          'B52',
          'A8',
          'C53',
          'A18',
          'B19',
          'A9',
          'D11',
          'C23',
          'A52',
          'C11',
          'A13',
          'D6',
          'A57',
          'C6',
          'B36',
          'B55',
          'B23',
          'D44',
          'A47',
          'C30',
          'B45',
          'C28',
          'B8',
          'A30',
          'C60',
          'C15',
          'C35',
          'B21',
          'D55',
          'D29',
          'A39',
          'C38',
          'B37',
          'C54',
          'B60',
          'C6',
          'D3',
          'C14',
          'B9',
          'B41',
          'C51',
          'D23',
          'A41',
          'B26',
          'C57',
          'B19',
          'C8',
          'A31',
          'B43',
          'D32',
          'A38',
          'B3',
          'D59',
          'D8',
          'C27',
          'B56',
          'D56',
          'B6',
          'B58',
          'A14',
          'C10',
          'A1',
          'D27',
          'D54',
          'D28',
          'B23',
          'D11',
          'C18',
          'B8',
          'C58',
          'B10',
          'D9',
          'A56',
          'D48',
          'B20',
          'C37',
          'A45',
          'C31',
          'D13',
          'C1',
          'D15',
          'A20',
          'C7',
          'B29',
          'C40',
          'B43',
          'A14',
          'C17',
          'B22',
          'C8',
          'A43',
          'C41',
          'B52',
          'C22',
          'A59',
          'D4',
          'A50',
          'D29',
          'C44',
          'A23',
          'D7',
          'C1',
          'A22',
          'B54',
          'C12',
          'D22',
          'D59',
          'B4',
          'B41',
          'B22',
          'D5',
          'A2',
          'D19',
          'A41',
          'C33',
          'D10',
          'C4',
          'B2',
          'C2',
          'A15',
          'B32',
          'C3',
          'D11',
          'C59',
          'A30',
          'C45',
          'A39',
          'C14',
          'C53',
          'A37',
          'C42',
          'D20',
          'C22',
          'D32',
          'A46',
          'C34',
          'D1',
          'A55',
          'C46',
          'B14',
          'C25',
          'D13',
          'A33',
          'D18',
          'D60',
          'B44',
          'C36',
          'B12',
          'D49',
          'A40',
          'B42',
          'A32',
          'B53',
          'C39',
          'B29',
          'B52',
          'A35',
          'D30',
          'A15',
          'C51',
          'A20',
          'B54',
          'B25',
          'B40',
          'A41',
          'B21',
          'C58',
          'D13',
          'C23',
          'D14',
          'C2',
          'A25',
          'B11',
          'A24',
          'A2',
          'A29',
          'B52',
          'B10',
          'C48',
          'B28',
          'D58',
          'D24',
          'B14',
          'D17',
          'C14',
          'A49',
          'B38',
          'C27',
          'B59',
          'C21',
          'A55',
          'C52',
          'C24',
          'A48',
          'B15',
          'D7',
          'C38',
          'B53',
          'C26',
          'D8',
          'A8',
          'D9',
          'C37',
          'A59',
          'C3',
          'A21',
          'B18',
          'C40',
          'B45',
          'D5',
          'A18',
          'B28',
          'C43',
          'A35',
          'B41',
          'D51',
          'A36',
          'B27',
          'C16',
          'D20',
          'D60',
          'D34',
          'D59',
          'B48',
          'C33',
          'B2',
        ],
      },
    ],
  },
  {
    title: 'Elvis Presley',
    id: 'elvis-presley',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Elvis%20Presley.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C13',
          'A14',
          'C47',
          'C2',
          'A2',
          'C18',
          'C40',
          'C3',
          'A1',
          'C19',
          'C35',
          'C60',
          'C22',
          'A17',
          'C17',
          'D54',
          'C11',
          'A7',
          'A24',
          'C45',
          'C25',
          'C59',
          'C4',
          'A8',
          'C10',
          'A14',
          'C24',
          'C47',
          'C13',
          'A4',
          'C16',
          'A6',
          'C12',
          'C49',
          'D60',
          'C41',
          'C58',
          'C27',
          'C43',
          'A24',
          'B5',
          'A48',
          'B13',
          'A56',
          'B20',
          'A46',
          'B14',
          'A44',
          'A14',
          'C9',
          'A9',
          'C6',
          'A11',
          'C50',
          'A23',
          'C47',
          'C17',
          'A3',
          'C14',
          'D52',
          'C8',
          'A10',
          'A38',
          'A15',
          'C23',
          'C59',
          'C20',
          'A18',
          'A33',
          'A17',
          'A34',
          'B14',
          'A27',
          'B15',
          'A43',
          'B23',
          'A55',
          'B42',
          'B57',
          'B31',
          'B46',
          'B29',
          'B58',
          'B38',
          'B53',
          'B32',
          'D1',
          'B36',
          'B59',
          'B30',
          'D2',
          'B34',
          'B51',
          'B14',
          'A24',
          'C41',
          'C14',
          'A13',
          'C25',
          'C40',
          'C59',
          'C1',
          'C48',
          'A12',
          'C26',
          'C58',
          'C5',
          'A10',
          'A44',
          'B6',
          'A18',
          'B8',
          'A49',
          'B11',
          'A29',
          'B13',
          'A47',
          'B18',
          'A27',
          'B21',
          'A45',
          'A9',
          'C53',
          'C28',
          'C57',
          'C6',
          'C47',
          'C31',
          'D58',
          'C22',
          'A19',
          'B1',
          'B41',
          'A51',
          'B6',
          'A16',
          'C23',
          'D55',
          'C11',
          'A13',
          'C7',
          'A7',
          'C15',
          'A12',
          'C33',
          'A6',
          'C27',
          'A11',
          'C16',
          'C60',
          'C20',
          'A19',
          'B5',
          'A30',
          'B8',
          'A17',
          'A42',
          'A15',
          'C12',
          'A5',
          'C3',
          'C46',
          'C18',
          'A1',
          'C1',
          'C54',
          'C36',
          'C7',
          'C45',
          'C19',
          'A16',
          'A34',
          'B23',
          'B60',
          'B34',
          'B56',
          'B37',
          'A48',
          'B41',
          'A56',
          'A20',
          'A59',
          'B44',
          'B59',
          'B24',
          'A30',
          'B9',
          'A17',
          'C15',
          'A2',
          'C34',
          'A10',
          'C28',
          'C55',
          'C7',
          'A8',
          'A45',
          'B22',
          'A37',
          'A12',
          'C6',
          'A4',
          'C2',
          'C53',
          'C8',
          'A14',
          'A58',
          'B40',
          'B55',
          'B30',
          'D1',
          'B23',
          'A27',
          'B24',
          'A42',
          'A11',
          'C5',
          'C38',
          'A24',
          'B16',
          'A23',
          'C46',
          'C14',
          'A8',
          'C11',
          'C52',
          'C30',
          'C8',
          'A3',
          'A38',
          'B37',
          'A44',
          'B22',
          'B5',
          'B46',
          'A47',
          'B19',
          'A60',
          'A20',
          'C25',
          'A7',
          'A45',
          'B34',
          'A49',
          'B12',
          'A28',
          'B13',
          'A25',
          'A43',
          'B39',
          'B60',
          'A56',
          'B51',
          'A54',
          'B42',
          'A53',
          'B52',
          'A59',
          'B37',
          'A57',
          'A15',
          'C10',
          'D50',
          'C6',
          'C37',
          'C59',
          'C2',
          'A3',
          'C28',
          'C12',
          'A12',
          'C24',
          'C58',
          'C3',
          'C52',
          'C9',
          'A1',
          'C16',
          'C51',
          'D60',
          'C55',
          'A22',
          'B16',
          'A21',
          'C32',
          'D57',
          'C27',
          'A18',
          'B1',
          'A14',
          'B2',
          'B40',
          'A57',
          'B59',
          'A53',
          'A24',
          'A43',
          'A11',
          'C7',
          'A10',
          'A44',
          'B38',
          'A37',
          'A5',
          'C35',
          'C8',
          'A6',
          'C3',
          'C43',
          'C20',
          'A7',
          'C12',
          'C51',
          'C36',
          'A1',
          'C13',
          'D52',
          'C18',
          'A16',
          'A36',
          'B22',
          'D1',
          'B29',
          'A33',
          'B27',
          'A30',
          'B10',
          'B48',
          'A45',
          'A6',
          'C26',
          'A19',
          'C33',
          'A15',
          'C9',
          'C24',
          'A20',
          'B16',
          'B51',
          'B36',
          'A49',
          'B56',
          'B32',
          'B60',
          'A55',
          'A22',
          'C31',
          'C50',
          'C17',
          'C59',
          'C10',
          'A9',
          'C4',
          'C39',
          'A14',
          'B3',
          'B18',
          'A23',
          'A43',
          'B40',
          'B59',
          'A50',
          'B50',
          'A57',
          'B54',
          'B1',
          'B22',
          'A59',
          'B32',
          'A46',
          'B47',
          'B11',
          'A17',
          'C14',
          'A5',
          'C28',
          'A16',
          'C16',
          'C54',
          'C29',
          'A2',
          'C3',
          'A7',
          'C5',
          'C51',
          'A6',
          'A47',
          'B56',
          'A52',
          'A9',
          'A53',
          'A23',
          'B19',
          'B57',
          'A51',
          'B9',
          'A15',
          'A43',
          'A21',
          'A55',
          'B58',
          'B30',
          'A60',
          'B43',
          'A57',
          'A11',
          'C25',
          'D57',
          'C16',
          'A7',
          'C8',
          'D52',
          'C12',
          'A16',
          'A56',
          'B53',
          'B15',
          'A25',
          'C43',
          'D59',
          'C42',
          'C57',
          'C30',
          'A15',
          'C21',
          'C58',
          'C1',
          'C60',
          'C19',
          'A19',
          'B7',
          'B45',
          'A48',
          'B57',
          'A46',
          'B58',
          'A54',
          'B24',
          'A26',
          'A41',
          'B40',
          'B55',
          'A59',
          'B49',
          'A53',
          'B60',
          'B29',
          'D3',
          'B25',
          'A34',
          'B26',
          'B1',
          'B36',
          'A45',
          'A5',
          'C9',
          'A2',
          'C12',
          'A17',
          'C11',
          'C55',
          'C35',
          'A9',
          'A44',
          'B49',
          'B30',
          'A33',
          'C60',
          'C4',
          'A4',
          'C17',
          'C36',
          'C21',
          'C50',
          'C13',
          'A11',
          'A43',
          'A20',
          'C23',
          'A13',
          'C10',
          'C56',
          'C5',
          'A8',
          'A53',
          'B55',
          'A56',
          'B44',
          'A49',
          'B60',
          'A52',
          'B28',
          'A29',
          'B22',
          'A35',
          'B38',
          'A59',
          'A11',
          'C34',
          'A14',
          'B6',
          'B47',
          'A47',
          'A5',
          'C2',
          'C41',
          'C17',
          'C53',
          'C38',
          'C19',
          'A13',
          'C8',
          'A15',
          'A43',
          'B50',
          'A55',
          'A17',
          'B12',
          'B46',
          'A54',
          'A23',
          'B15',
          'B49',
          'A58',
          'B53',
          'B5',
          'B25',
          'B3',
          'B35',
          'B55',
          'A48',
          'B60',
          'A59',
          'A15',
          'C13',
          'C55',
          'C1',
          'A2',
          'A38',
          'C60',
          'C14',
          'D50',
          'C6',
          'C54',
          'C21',
          'A17',
          'C16',
          'C48',
          'C23',
          'A6',
          'C11',
          'A12',
          'B3',
          'B20',
          'A46',
          'B60',
          'A54',
          'B54',
          'B31',
          'A32',
          'B32',
          'B48',
          'A60',
          'B36',
          'A39',
          'B47',
          'A45',
          'B58',
          'A51',
          'A25',
          'C41',
          'A4',
          'C36',
          'A8',
          'C6',
          'A13',
          'B2',
          'B35',
          'A52',
          'B57',
          'B30',
          'A50',
          'A10',
          'C14',
          'C54',
          'C2',
          'C57',
          'C27',
          'A21',
          'A58',
          'B58',
          'A44',
          'B59',
          'A49',
          'B51',
          'A60',
          'A15',
          'C29',
          'C45',
          'D59',
          'C38',
          'C15',
          'A4',
          'C12',
          'D49',
          'C9',
          'A12',
          'C7',
          'A14',
          'B9',
          'B24',
          'A25',
          'A41',
          'A19',
          'A43',
          'A2',
          'C17',
          'A10',
          'C4',
          'C57',
          'C25',
          'A21',
          'A55',
          'B56',
          'B3',
          'B46',
          'B8',
          'A16',
          'A56',
          'B57',
          'A47',
          'B60',
          'B24',
          'A35',
          'B39',
          'A59',
          'B53',
          'A52',
          'A25',
          'C40',
          'A5',
          'C13',
          'C43',
          'A2',
          'C7',
          'D51',
          'C16',
          'C31',
          'A14',
          'B10',
          'B49',
          'A51',
          'B42',
          'B1',
          'B27',
          'A53',
          'B56',
          'A48',
          'A26',
          'B25',
          'D2',
          'B28',
          'A34',
          'B38',
          'A56',
          'A8',
          'C3',
          'C50',
          'C10',
          'A16',
          'A58',
          'B54',
          'A49',
          'A17',
          'A55',
          'B45',
          'B1',
          'A15',
          'C24',
          'C60',
          'C2',
          'C51',
          'C29',
          'A9',
          'C5',
          'C48',
          'C13',
          'A12',
          'B4',
          'B34',
          'B9',
          'A13',
          'C32',
          'C7',
          'A6',
          'C9',
          'A16',
          'A36',
          'B37',
          'A60',
          'A20',
          'B17',
          'B2',
          'B52',
          'B32',
          'B5',
          'B57',
          'A45',
          'B60',
          'A44',
          'A19',
          'A46',
          'B56',
          'A50',
          'B58',
          'A42',
          'B59',
          'A48',
          'A5',
          'C34',
          'C19',
          'A9',
          'C15',
          'C40',
          'A8',
          'A57',
          'B55',
          'B8',
          'B23',
          'A31',
          'C55',
          'C20',
          'A15',
          'C11',
          'A10',
          'C26',
          'C54',
          'D60',
          'C56',
          'C7',
          'D45',
          'C1',
          'C53',
          'C15',
          'A18',
          'A46',
          'A7',
          'C6',
          'C41',
          'C8',
          'D52',
          'C12',
          'A13',
          'B2',
          'B25',
          'A25',
          'A41',
          'A22',
          'A53',
          'B47',
          'B1',
          'B35',
          'B50',
          'A45',
          'B59',
          'A56',
          'B40',
          'A55',
          'B53',
          'A50',
          'B43',
          'A37',
          'B48',
          'A59',
          'A10',
          'A51',
          'B55',
          'B18',
          'D36',
          'B16',
          'D33',
          'B17',
          'A19',
          'A53',
          'A18',
          'C11',
          'A5',
          'C27',
          'A16',
          'B12',
          'B50',
          'A58',
          'B52',
          'B13',
          'A17',
          'C10',
          'A11',
          'C14',
          'C42',
          'A3',
          'C16',
          'C37',
          'C53',
          'B44',
          'B9',
          'D29',
          'B10',
          'A18',
          'B7',
          'B56',
          'A43',
          'B60',
          'A50',
          'B57',
          'B31',
          'A30',
          'C57',
          'C12',
          'C54',
          'C8',
          'A12',
          'C5',
          'D45',
          'C1',
          'A3',
          'C21',
          'A6',
          'C17',
          'C46',
          'A1',
          'C22',
          'C49',
          'C4',
          'A9',
          'A55',
          'A17',
          'C13',
          'A8',
          'A44',
          'B57',
          'A49',
          'A5',
          'C31',
          'C53',
          'B45',
          'B16',
          'B34',
          'B3',
          'A11',
          'B4',
          'A20',
          'C22',
          'C40',
          'A12',
          'A41',
          'A22',
          'C30',
          'D56',
          'C28',
          'A17',
          'B14',
          'D34',
          'B15',
          'B52',
          'A51',
          'D6',
          'A49',
          'B58',
          'A47',
          'A18',
          'A54',
          'B55',
          'A60',
          'D17',
          'A59',
          'B42',
          'A38',
          'B21',
          'D42',
          'C6',
          'A6',
          'C2',
          'A1',
          'C10',
          'A5',
          'A53',
          'B51',
          'B18',
          'D32',
          'B12',
          'B40',
          'A52',
          'B48',
          'B33',
          'B5',
          'A19',
          'C18',
          'C58',
          'C11',
          'C49',
          'C33',
          'C56',
          'C29',
          'A22',
          'A37',
          'A21',
          'A41',
          'B59',
          'A47',
          'B55',
          'A50',
          'A28',
          'B28',
          'A27',
          'B29',
          'D2',
          'B22',
          'D41',
          'B19',
          'B54',
          'A51',
          'A2',
          'C5',
          'A11',
          'A43',
          'B58',
          'A39',
          'B52',
          'A57',
          'D15',
          'B1',
          'B50',
          'B3',
          'B39',
          'A58',
          'A16',
          'C17',
          'C60',
          'A44',
          'D1',
          'B31',
          'B8',
          'D26',
          'B6',
          'A12',
          'C21',
          'C52',
          'B43',
          'A54',
          'D12',
          'A55',
          'B41',
          'C51',
          'B42',
          'A34',
          'C55',
          'C5',
          'D43',
          'C3',
          'A3',
          'C7',
          'A13',
          'B10',
          'D28',
          'B14',
          'A16',
          'C15',
          'C49',
          'C18',
          'C45',
          'C22',
          'A2',
          'C13',
          'A18',
          'C12',
          'A9',
          'C8',
          'C50',
          'B33',
          'B52',
          'A60',
          'D16',
          'A56',
          'D11',
          'A58',
          'A8',
          'C20',
          'D51',
          'C15',
          'A1',
          'C30',
          'A6',
          'C4',
          'D38',
          'B17',
          'D43',
          'B20',
          'A23',
          'A54',
          'D13',
          'A53',
          'B57',
          'A59',
          'A16',
          'B8',
          'B54',
          'B31',
          'B6',
          'B45',
          'A41',
          'A21',
          'A45',
          'B56',
          'A57',
          'A17',
          'A50',
          'A5',
          'C8',
          'A15',
          'C19',
          'C57',
          'D60',
          'D44',
          'B19',
          'D36',
          'B20',
          'B44',
          'C52',
          'C1',
          'C42',
          'C24',
          'C39',
          'D58',
          'C44',
          'C12',
          'A19',
          'A48',
          'A18',
          'A54',
          'D7',
          'A49',
          'D5',
          'A52',
          'B51',
          'B1',
          'A15',
          'B8',
          'D30',
          'B9',
          'B27',
          'A29',
          'A46',
          'C59',
          'C18',
          'A9',
          'A59',
          'A8',
          'C30',
          'C48',
          'B33',
          'B4',
          'B38',
          'C50',
          'B39',
          'B2',
          'B46',
          'C54',
          'A43',
          'B57',
          'A41',
          'B60',
          'A40',
          'B51',
          'B36',
          'A58',
          'B55',
          'A46',
          'C58',
          'D60',
          'C53',
          'C22',
          'A14',
          'C14',
          'A16',
          'B15',
          'D37',
          'B17',
          'D31',
          'B7',
          'B24',
          'D46',
          'C2',
          'C50',
          'B41',
          'B2',
          'D1',
          'A60',
          'B54',
          'A48',
          'C58',
          'B50',
          'A54',
          'A5',
          'C15',
          'A10',
          'C6',
          'A14',
          'C11',
          'D47',
          'B22',
          'B56',
          'A51',
          'A24',
          'A39',
          'B59',
          'A38',
          'C59',
          'C23',
          'C43',
          'C5',
          'C46',
          'B26',
          'D46',
          'B18',
          'B33',
          'C44',
          'C19',
          'A3',
          'A45',
          'A21',
          'A58',
          'D18',
          'B2',
          'B48',
          'D54',
          'C10',
          'A18',
          'B7',
          'B29',
          'B59',
          'A55',
          'A17',
          'B5',
          'B47',
          'D53',
          'B49',
          'C57',
          'C1',
          'A2',
          'A45',
          'C60',
          'C32',
          'C47',
          'B28',
          'C46',
          'A27',
          'A50',
          'D5',
          'A47',
          'D7',
          'A56',
          'B56',
          'B17',
          'D30',
          'B12',
          'D29',
          'B13',
          'D38',
          'C3',
          'A7',
          'C14',
          'C50',
          'B34',
          'A31',
          'B26',
          'D45',
          'B18',
          'D2',
          'B23',
          'A36',
          'B48',
          'D53',
          'C19',
          'A18',
          'C17',
          'A13',
          'A58',
          'D14',
          'A55',
          'A5',
          'C24',
          'A8',
          'A60',
          'B47',
          'D55',
          'C33',
          'A20',
          'C13',
          'A16',
          'B16',
          'D39',
          'C2',
          'C52',
          'B45',
          'D54',
          'B46',
          'C53',
          'B42',
          'C50',
          'C4',
          'A10',
          'B4',
          'D23',
          'B8',
          'A14',
          'B11',
          'D33',
          'C1',
          'C56',
          'B40',
          'C55',
          'B38',
          'B15',
          'D28',
          'B19',
          'A22',
          'A54',
          'A18',
          'A56',
          'B49',
          'D52',
          'B47',
          'A38',
          'A23',
          'B25',
          'D1',
          'B12',
          'A14',
          'C16',
          'C47',
          'C4',
          'C56',
          'C20',
          'C40',
          'C25',
          'C55',
          'A46',
          'A21',
          'B3',
          'B36',
          'A30',
          'C22',
          'A8',
          'C9',
          'A11',
          'B6',
          'D25',
          'B7',
          'A17',
          'A57',
          'D12',
          'B1',
          'B53',
          'B31',
          'C49',
          'B32',
          'C44',
          'B34',
          'C48',
          'C12',
          'A3',
          'C18',
          'A5',
          'C5',
          'C42',
          'C21',
          'D50',
          'B22',
          'D40',
          'B15',
          'D44',
          'B24',
          'D42',
          'B43',
          'C51',
          'C3',
          'D35',
          'B21',
          'A22',
          'A42',
          'B57',
          'A40',
          'B58',
          'A48',
          'C57',
          'B48',
          'A58',
          'D9',
          'A51',
          'A24',
          'A52',
          'B54',
          'B29',
          'B44',
          'B8',
          'A12',
          'C6',
          'C50',
          'C30',
          'C9',
          'A17',
          'A60',
          'A16',
          'C11',
          'A4',
          'A37',
          'B59',
          'B25',
          'D49',
          'B22',
          'D46',
          'C2',
          'A7',
          'C10',
          'C51',
          'B32',
          'C43',
          'B33',
          'B57',
          'A55',
          'D15',
          'A56',
          'A5',
          'C4',
          'C52',
          'B40',
          'B9',
          'D22',
          'B2',
          'A15',
          'B5',
          'D27',
          'B10',
          'D35',
          'C1',
          'A1',
          'A51',
          'A19',
          'A41',
          'B46',
          'D56',
          'C46',
          'C21',
          'C36',
          'C6',
          'C39',
          'A26',
          'B29',
          'C47',
          'C14',
          'A21',
          'A52',
          'D8',
          'A59',
          'B54',
          'B4',
          'B45',
          'D43',
          'B22',
          'A28',
          'B14',
          'B39',
          'C55',
          'B47',
          'D54',
          'B48',
          'C54',
          'A46',
          'D4',
          'A51',
          'C60',
          'C3',
          'A9',
          'C16',
          'A15',
          'C7',
          'C51',
          'C23',
          'A33',
          'A49',
          'A31',
          'B35',
          'C47',
          'B25',
          'C45',
          'B26',
          'D49',
          'C17',
          'A8',
          'B1',
          'B46',
          'A48',
          'D6',
          'A45',
          'C60',
          'C31',
          'D51',
          'B21',
          'D47',
          'B17',
          'A16',
          'C8',
          'A11',
          'A43',
          'A1',
          'C20',
          'A12',
          'B9',
          'D36',
          'C3',
          'C57',
          'B50',
          'D53',
          'B48',
          'A35',
          'C25',
          'C58',
          'B51',
          'A37',
          'B60',
          'A39',
          'A24',
          'B15',
          'D32',
          'B16',
          'B37',
          'C46',
          'B38',
          'C54',
          'D59',
          'C52',
          'C18',
          'A25',
          'B29',
          'B1',
          'A7',
          'A44',
          'B39',
          'B54',
          'A55',
          'D11',
          'B2',
          'B44',
          'D55',
          'B45',
          'D43',
          'B26',
          'C44',
          'C4',
          'A8',
          'C27',
          'A37',
          'B21',
          'D44',
          'C9',
          'A10',
          'A43',
          'B59',
          'A51',
          'C59',
          'D60',
          'D39',
          'B11',
          'D35',
          'B13',
          'B45',
          'A53',
          'B58',
          'B30',
          'C47',
          'C19',
          'A2',
          'A41',
          'A12',
          'C3',
          'C48',
          'B27',
          'C41',
          'C26',
          'C46',
          'D57',
          'B44',
          'C51',
          'B33',
          'C49',
          'B40',
          'B3',
          'A13',
          'B11',
          'B26',
          'D42',
          'B18',
          'D29',
          'B20',
          'A39',
          'A24',
          'C17',
          'A5',
          'A57',
          'A17',
          'B1',
          'D21',
          'B6',
          'A10',
          'C12',
          'C41',
          'B28',
          'D1',
          'A58',
          'D19',
          'A59',
          'D13',
          'A52',
          'A19',
          'C11',
          'C50',
          'B43',
          'D41',
          'B24',
          'D40',
          'D59',
          'D43',
          'B15',
          'C14',
          'C39',
          'B21',
          'D2',
          'B26',
          'A23',
          'A39',
          'D1',
          'B37',
          'A45',
          'C59',
          'C21',
          'A31',
          'C51',
          'C1',
          'A4',
          'C29',
          'C10',
          'B12',
          'A13',
          'C5',
          'C40',
          'B28',
          'B59',
          'A46',
          'C53',
          'A47',
          'D3',
          'B24',
          'D50',
          'B48',
          'D52',
          'B50',
          'C55',
          'B41',
          'C52',
          'D60',
          'C44',
          'C8',
          'C32',
          'B19',
          'D32',
          'B6',
          'A20',
          'B22',
          'C42',
          'B26',
          'C38',
          'C23',
          'A11',
          'C12',
          'B51',
          'C59',
          'A52',
          'D10',
          'A49',
          'A26',
          'A50',
          'B45',
          'D57',
          'C45',
          'B35',
          'D55',
          'B46',
          'D54',
          'B49',
          'A34',
          'A6',
          'A44',
          'B55',
          'C29',
          'C14',
          'B16',
          'D34',
          'B10',
          'A15',
          'C6',
          'C33',
          'A23',
          'A57',
          'A22',
          'C15',
          'B13',
          'C10',
          'C60',
          'C23',
          'D49',
          'B17',
          'D46',
          'B47',
          'A59',
          'A5',
          'B1',
          'B59',
          'A40',
          'B46',
          'C52',
          'A47',
          'D8',
          'A53',
          'D4',
          'A45',
          'C56',
          'B45',
          'B3',
          'B21',
          'C43',
          'B34',
          'D56',
          'B43',
          'B10',
          'A12',
          'C18',
          'A15',
          'B7',
          'B44',
          'A42',
          'A22',
          'C28',
          'B53',
          'C27',
          'A34',
          'C26',
          'A37',
          'C25',
          'B47',
          'C58',
          'C36',
          'B23',
          'A28',
          'C52',
          'A48',
          'B52',
          'C12',
          'B16',
          'D47',
          'B25',
          'C46',
          'B30',
          'B45',
          'D56',
          'C48',
          'B36',
          'C53',
          'D58',
          'C43',
          'C4',
          'A17',
          'A57',
          'D14',
          'A53',
          'D12',
          'B3',
          'B57',
          'C31',
          'B17',
          'D34',
          'B18',
          'A16',
          'A60',
          'B56',
          'B29',
          'D53',
          'B46',
          'D45',
          'B14',
          'C15',
          'A6',
          'C13',
          'A9',
          'A43',
          'A1',
          'A44',
          'B43',
          'C59',
          'B54',
          'C55',
          'D59',
          'D30',
          'B24',
          'A24',
          'A52',
          'C58',
          'B48',
          'C26',
          'C50',
          'B31',
          'C45',
          'C3',
          'A13',
          'C9',
          'B12',
          'C16',
          'A19',
          'A53',
          'D7',
          'A57',
          'B58',
          'A41',
          'A18',
          'A54',
          'D16',
          'B2',
          'A7',
          'C4',
          'A11',
          'C22',
          'D49',
          'B23',
          'D43',
          'D59',
          'B44',
          'D42',
          'B15',
          'A17',
          'B16',
          'A48',
          'C56',
          'C32',
          'A4',
          'C19',
          'A27',
          'B37',
          'B5',
          'A13',
          'D56',
          'B24',
          'D39',
          'B39',
          'A57',
          'B48',
          'C25',
          'D48',
          'B20',
          'D52',
          'B50',
          'D51',
          'A10',
          'C24',
          'C58',
          'B52',
          'C11',
          'B11',
          'A30',
          'B42',
          'C23',
          'A34',
          'B51',
          'C14',
          'B17',
          'C30',
          'B57',
          'C29',
          'A7',
          'B3',
          'B38',
          'C45',
          'B23',
          'D31',
          'C1',
          'D36',
          'B8',
          'B35',
          'C43',
          'C19',
          'B20',
          'D28',
          'D60',
          'C50',
          'C33',
          'D50',
          'B21',
          'C34',
          'A18',
          'A56',
          'A9',
          'C27',
          'B46',
          'D44',
          'B22',
          'C39',
          'B27',
          'A22',
          'B37',
          'C48',
          'B28',
          'D40',
          'B41',
          'C24',
          'A32',
          'B33',
          'C47',
          'D55',
          'C11',
          'B53',
          'C19',
          'D51',
          'C51',
          'A48',
          'D9',
          'A46',
          'C57',
          'A52',
          'B42',
          'C58',
          'B43',
          'C54',
          'B49',
          'D53',
          'B19',
          'D31',
          'B8',
          'A15',
          'B2',
          'A5',
          'B57',
          'A54',
          'B41',
          'A35',
          'B13',
          'C9',
          'B39',
          'C56',
          'B48',
          'C53',
          'D52',
          'B51',
          'C13',
          'B54',
          'C30',
          'C45',
          'B33',
          'B10',
          'C12',
          'A14',
          'C7',
          'A16',
          'B1',
          'D23',
          'B11',
          'C10',
          'A10',
          'B7',
          'D37',
          'B18',
          'C15',
          'A13',
          'B13',
          'C16',
          'A5',
          'B3',
          'D10',
          'A50',
          'A26',
          'B14',
          'D35',
          'C50',
          'B44',
          'A33',
          'C28',
          'A36',
          'B60',
          'B45',
          'D55',
          'B47',
          'C27',
          'B54',
          'C12',
          'B17',
          'A15',
          'D60',
          'D36',
          'B10',
          'D40',
          'C54',
          'D51',
          'B20',
          'C41',
          'C4',
          'A18',
          'A59',
          'A21',
          'B38',
          'D38',
          'B24',
          'D31',
          'B11',
          'C17',
          'A25',
          'B37',
          'A19',
          'A44',
          'B56',
          'C56',
          'A52',
          'D15',
          'B2',
          'D13',
          'A50',
          'A26',
          'C38',
          'C7',
          'A9',
          'D50',
          'C55',
          'B45',
          'C54',
          'C34',
          'B22',
          'B43',
          'C23',
          'A35',
          'B25',
          'D46',
          'B42',
          'C57',
          'C28',
          'A4',
          'C18',
          'D53',
          'A11',
          'D54',
          'B47',
          'D51',
          'B23',
          'D47',
          'B13',
          'C13',
          'B8',
          'A14',
          'B1',
          'D17',
          'C30',
          'C54',
          'B52',
          'C14',
          'B40',
          'C47',
          'B24',
          'D2',
          'B17',
          'C16',
          'A8',
          'C2',
          'C49',
          'D55',
          'B22',
          'C38',
          'B27',
          'A28',
          'A46',
          'B35',
          'A23',
          'B36',
          'D58',
          'B44',
          'D56',
          'C44',
          'D39',
          'C53',
          'B43',
          'A34',
          'B55',
          'C20',
          'A2',
          'A57',
          'A22',
          'C27',
          'A39',
          'D2',
          'C33',
          'A3',
          'A42',
          'B60',
          'A8',
          'A60',
          'B38',
          'A19',
          'B6',
          'B36',
          'B18',
          'D33',
          'C30',
          'B58',
          'C29',
          'B15',
          'C28',
          'B50',
          'D50',
          'C21',
          'A14',
          'C8',
          'B39',
          'C54',
          'A44',
          'B41',
          'C22',
          'B23',
          'D42',
          'B30',
          'A25',
          'B42',
          'C24',
          'D48',
          'B48',
          'C49',
          'C34',
          'B27',
          'C32',
          'A7',
          'C28',
          'D14',
          'A56',
          'B39',
          'D38',
          'C45',
          'B24',
          'C36',
          'B20',
          'C40',
          'B33',
          'A50',
          'A3',
          'B55',
          'C12',
          'D56',
          'A9',
          'A43',
          'B41',
          'D47',
          'C26',
          'B49',
          'C25',
          'D26',
          'B19',
          'A16',
          'C3',
          'A17',
          'A58',
          'A12',
          'B12',
          'A30',
          'A49',
          'B40',
          'C11',
          'B54',
          'C54',
          'B47',
          'A3',
          'C5',
          'A6',
          'B56',
          'C20',
          'A28',
          'B36',
          'A20',
          'B4',
          'A7',
          'D51',
          'C15',
          'B50',
          'C13',
          'B9',
          'C8',
          'B13',
          'D46',
          'B20',
          'C18',
          'A35',
          'C30',
          'A41',
          'B41',
          'D58',
          'B42',
          'C55',
          'B51',
          'A33',
          'C57',
          'B59',
          'A52',
          'A24',
          'A53',
          'C56',
          'B38',
          'C10',
          'A12',
          'D52',
          'B17',
          'D45',
          'B21',
          'A21',
          'C31',
          'B18',
          'C41',
          'B19',
          'C15',
          'D53',
          'C14',
          'B53',
          'C13',
          'B15',
          'A22',
          'B44',
          'C60',
          'C21',
          'B52',
          'D54',
          'C50',
          'D36',
          'B24',
          'D29',
          'D60',
          'B37',
          'B4',
          'D9',
          'A56',
          'A24',
          'B17',
          'A48',
          'A29',
          'B34',
          'D53',
          'C54',
          'B55',
          'C11',
          'A9',
          'C25',
          'B10',
          'A18',
          'B39',
          'C46',
          'D41',
          'B26',
          'D34',
          'C51',
          'C32',
          'B56',
          'C31',
          'B19',
          'D52',
          'B22',
          'C33',
          'D21',
          'A60',
          'A16',
          'C1',
          'C44',
          'D42',
          'B45',
          'C24',
          'B10',
          'A51',
          'C60',
          'B50',
          'A41',
          'C55',
          'A53',
          'A19',
          'C14',
          'B12',
          'C17',
          'B46',
          'D56',
          'B29',
          'D5',
          'A43',
          'C49',
          'B34',
          'A24',
          'B43',
          'C16',
          'A23',
          'A39',
          'C26',
          'B44',
          'C59',
          'C34',
          'D1',
          'A7',
          'C18',
          'B21',
          'A46',
          'B49',
          'D51',
          'A11',
          'B8',
          'A10',
          'D49',
          'B15',
          'C27',
          'D12',
          'A50',
          'A26',
          'B41',
          'D44',
          'B25',
          'C43',
          'C14',
          'A9',
          'B58',
          'A5',
          'D40',
          'B42',
          'D57',
          'A8',
          'B1',
          'A4',
          'C2',
          'D32',
          'B21',
          'C46',
          'B22',
          'D45',
          'C50',
          'B32',
          'C42',
          'B35',
          'B20',
          'A30',
          'B26',
          'D31',
          'C29',
          'B59',
          'C22',
          'B42',
          'B57',
          'A38',
          'C57',
          'D43',
          'B29',
          'A22',
          'D5',
          'C34',
          'D49',
          'B19',
          'C30',
          'A3',
          'C37',
          'B32',
          'A53',
          'D9',
          'A50',
          'C1',
          'A51',
          'D8',
          'C25',
          'B40',
          'C10',
          'D54',
          'A12',
          'B2',
          'A8',
          'C15',
          'B43',
          'A23',
          'C32',
          'A48',
          'A28',
          'C19',
          'B16',
          'D53',
          'A16',
          'B20',
          'C16',
          'A35',
          'B60',
          'C57',
          'C38',
          'B33',
          'A46',
          'D10',
          'C37',
          'C5',
          'A15',
          'D51',
          'C29',
          'D16',
          'A55',
          'D6',
          'A59',
          'A21',
          'B44',
          'C15',
          'B20',
          'C35',
          'A13',
          'B14',
          'A25',
          'A50',
          'C3',
          'A11',
          'B53',
          'C58',
          'B54',
          'A36',
          'C27',
          'D28',
          'B4',
          'B55',
          'C57',
          'D54',
          'B47',
          'D45',
          'A8',
          'D34',
          'C48',
          'B38',
          'A45',
          'C59',
          'A47',
          'B31',
          'A60',
          'D18',
          'C30',
          'A43',
          'B43',
          'D60',
          'D32',
          'D59',
          'B35',
          'A21',
          'B45',
          'A1',
          'C8',
          'B11',
          'A18',
          'A54',
          'B25',
          'C41',
          'B29',
          'D52',
          'A10',
          'B53',
          'C12',
          'B56',
          'C55',
          'D41',
          'B30',
          'A22',
          'A57',
          'B18',
          'C40',
          'D34',
          'B20',
          'C47',
          'D56',
          'C26',
          'B46',
          'D55',
          'B48',
          'D47',
          'B15',
          'C20',
          'B19',
          'C38',
          'A58',
          'C60',
          'B52',
          'C31',
          'B27',
          'C29',
          'A32',
          'B7',
          'A31',
          'C14',
          'D58',
          'C46',
          'A43',
          'B47',
          'C24',
          'D23',
          'C23',
          'B10',
          'C22',
          'B60',
          'A5',
          'B49',
          'C59',
          'A54',
          'C8',
          'B40',
          'A18',
          'B13',
          'D27',
          'B20',
          'C13',
          'B36',
          'A46',
          'D40',
          'B43',
          'C10',
          'B14',
          'A12',
          'D45',
          'B23',
          'D4',
          'A21',
          'C24',
          'B48',
          'D54',
          'C53',
          'C3',
          'C44',
          'B24',
          'C35',
          'A1',
          'A30',
          'B33',
          'C36',
          'D24',
          'B3',
          'A4',
          'C25',
          'B41',
          'A39',
          'C21',
          'B54',
          'C10',
          'A56',
          'B22',
          'D57',
          'B45',
          'A20',
          'C32',
          'D2',
          'A12',
          'D5',
          'C33',
          'B26',
          'D44',
          'C51',
          'A50',
          'B35',
          'A29',
          'B43',
          'D58',
          'A10',
          'D39',
          'B15',
          'D35',
          'B7',
          'A17',
          'A59',
          'A9',
          'D53',
          'C58',
          'B59',
          'A36',
          'C16',
          'B8',
          'C42',
          'B9',
          'C11',
          'B12',
          'D48',
          'B21',
          'C40',
          'C55',
          'B37',
          'D59',
          'B40',
          'A45',
          'C49',
          'D33',
          'D58',
          'D40',
          'D56',
          'B45',
          'C47',
          'D49',
          'A14',
          'B18',
          'D30',
          'B19',
          'C37',
          'D11',
          'C18',
          'B51',
          'D51',
          'A13',
          'A42',
          'A22',
          'D14',
          'A52',
          'A23',
          'D17',
          'C21',
          'A27',
          'B44',
          'C57',
          'A55',
          'C56',
          'B40',
          'C46',
          'C6',
          'B2',
          'B17',
          'A17',
          'C35',
          'B33',
          'D60',
          'A48',
          'D59',
          'A9',
          'C2',
          'D27',
          'B21',
          'C37',
          'A58',
          'C12',
          'B39',
          'A19',
          'B45',
          'C28',
          'B27',
          'D2',
          'C31',
          'A2',
          'A42',
          'B56',
          'C54',
          'D38',
          'B6',
          'C7',
          'C25',
          'A14',
          'D7',
          'C35',
          'B5',
          'D33',
          'B29',
          'A51',
          'D16',
          'A24',
          'B18',
          'C39',
          'B2',
          'C14',
          'B8',
          'D37',
          'A11',
          'D5',
          'B26',
          'C30',
          'A44',
          'A1',
          'B27',
          'D57',
          'B43',
          'D46',
          'C27',
          'D13',
          'C19',
          'A8',
          'C28',
          'B28',
          'B60',
          'A33',
          'C17',
          'B16',
          'D50',
          'C20',
          'B57',
          'C14',
          'A36',
          'B6',
          'A19',
          'C34',
          'D22',
          'D58',
          'B21',
          'A16',
          'B7',
          'D32',
          'A9',
          'D36',
          'C33',
          'A50',
          'A25',
          'B31',
          'A17',
          'C2',
          'B37',
          'A20',
          'B52',
          'D53',
          'B49',
          'C49',
          'A7',
          'C42',
          'B14',
          'C9',
          'A52',
          'A21',
          'B5',
          'C6',
          'A5',
          'D41',
          'C48',
          'B39',
          'C15',
          'A15',
          'B11',
          'D22',
          'A24',
          'C31',
          'A34',
          'C17',
          'B47',
          'D3',
          'A12',
          'C36',
          'B25',
          'D35',
          'B17',
          'C11',
          'B56',
          'B30',
          'A5',
          'C22',
          'A32',
          'A47',
          'D42',
          'B45',
          'C51',
          'B19',
          'D47',
          'A8',
          'B57',
          'A14',
          'D44',
          'B14',
          'C39',
          'D14',
          'C19',
          'A39',
          'B53',
          'C31',
          'B24',
          'D55',
          'B51',
          'C15',
          'B52',
          'A56',
          'A18',
          'B41',
          'C23',
          'D24',
          'D58',
          'D36',
          'B37',
          'C51',
          'B22',
          'C37',
          'A7',
          'C21',
          'B38',
          'A27',
          'B13',
          'A15',
          'B15',
          'D54',
          'C48',
          'B23',
          'A26',
          'B28',
          'C30',
          'B56',
          'C10',
          'A59',
          'C5',
          'A10',
          'D5',
          'C24',
          'A29',
          'B12',
          'A36',
          'D53',
          'A12',
          'C17',
          'B48',
          'D21',
          'B51',
          'A1',
          'B46',
          'C26',
          'B50',
          'C25',
          'D9',
          'A16',
          'D50',
          'B49',
          'C56',
          'B59',
          'B29',
          'A7',
          'D28',
          'C4',
          'A60',
          'C38',
          'C60',
          'C44',
          'B20',
          'C31',
          'B25',
          'A2',
          'A59',
          'D20',
          'C32',
          'B16',
          'D38',
          'B3',
          'C14',
          'B58',
          'A38',
          'D2',
          'A8',
          'C26',
          'B26',
          'D30',
          'C4',
          'D34',
          'A42',
          'B49',
          'A4',
          'D37',
          'C52',
          'D55',
          'B27',
          'B42',
          'C8',
          'A20',
          'B38',
          'C9',
          'A56',
          'A24',
          'D5',
          'A60',
          'D13',
          'C40',
          'B35',
          'C54',
          'A46',
          'D39',
          'B22',
          'A15',
          'D57',
          'D41',
          'B5',
          'A39',
          'B10',
          'A32',
          'D51',
          'C55',
          'A55',
          'C11',
          'C45',
          'B43',
          'A18',
          'C23',
          'D20',
          'C21',
          'A41',
          'D40',
          'A7',
          'C31',
          'A40',
          'C27',
          'B45',
          'C46',
          'D59',
          'B41',
          'C59',
          'D45',
          'C48',
          'A45',
          'D43',
          'B27',
          'A8',
          'D33',
          'B26',
          'A54',
          'A10',
          'B9',
          'D37',
          'B13',
          'C7',
          'A56',
          'D13',
          'A49',
          'C34',
          'B23',
          'A15',
          'D48',
          'C23',
          'B37',
          'C22',
          'D49',
          'C16',
          'B10',
          'C42',
          'B15',
          'A12',
          'B35',
          'A22',
          'D18',
          'B33',
          'A25',
          'D19',
          'C41',
          'B26',
          'D1',
          'A34',
          'C50',
          'B52',
          'C13',
          'B37',
          'C1',
          'B27',
          'D31',
          'B57',
          'D30',
          'B58',
          'C59',
          'D52',
          'B46',
          'C57',
          'C32',
          'A33',
          'B6',
          'D40',
          'A48',
          'A28',
          'B16',
          'A12',
          'D44',
          'B44',
          'D58',
          'B2',
          'C38',
          'D9',
          'A1',
          'B28',
          'C29',
          'A35',
          'C15',
          'B21',
          'D53',
          'B38',
          'C7',
          'B54',
          'A10',
          'C18',
          'A32',
          'B22',
          'D32',
          'B10',
          'C13',
          'A37',
          'B47',
          'C26',
          'D10',
          'C36',
          'B26',
          'A2',
          'D29',
          'C27',
          'B28',
          'C60',
          'D44',
          'C49',
          'B35',
          'C14',
          'D58',
          'A43',
          'D56',
          'B1',
          'A16',
          'B7',
          'C43',
          'B13',
          'C38',
          'A27',
          'B22',
          'D3',
          'A22',
          'A58',
          'C7',
          'B39',
          'D60',
          'A51',
          'B11',
          'D49',
          'B49',
          'D20',
          'A4',
          'B23',
          'C37',
          'B52',
          'D52',
          'A7',
          'D34',
          'B21',
          'D46',
          'B4',
          'D43',
          'B41',
          'D39',
          'C52',
          'A30',
          'C20',
          'B7',
          'A13',
          'D4',
          'C35',
          'B34',
          'A36',
          'B17',
          'D24',
          'C33',
          'B15',
          'D25',
          'C26',
          'A48',
          'C6',
          'B31',
          'C41',
          'A10',
          'B55',
          'B30',
          'C50',
          'A31',
          'C19',
          'D51',
          'B47',
          'C3',
          'B8',
          'C12',
          'B13',
          'A14',
          'C30',
          'B25',
          'A24',
          'B41',
          'C21',
          'A20',
          'A60',
          'D53',
          'B42',
          'A48',
          'A28',
          'C11',
          'B36',
          'A21',
          'A59',
          'D54',
          'B18',
          'C38',
          'B14',
          'D56',
          'C33',
          'B31',
          'D43',
          'A46',
          'C48',
          'B6',
          'C47',
          'D57',
          'B44',
          'D53',
          'B45',
          'A18',
          'B46',
          'C51',
          'C25',
          'D41',
          'A47',
          'B19',
          'C19',
          'B33',
          'A8',
          'C3',
          'B13',
          'C11',
          'A53',
          'A19',
          'B9',
          'D35',
          'A10',
          'D6',
          'C24',
          'C59',
          'B55',
          'D32',
          'B29',
          'C8',
          'B12',
          'A49',
          'C4',
          'B54',
          'B34',
          'D28',
          'B16',
          'C42',
          'B24',
          'A3',
          'C44',
          'B6',
          'C35',
          'A4',
          'C7',
          'B40',
          'D60',
          'C45',
          'D41',
          'B15',
          'D51',
          'A33',
          'C13',
          'A10',
          'B10',
          'D25',
          'A5',
          'B22',
          'D34',
          'D50',
          'B50',
          'D20',
          'B52',
          'C36',
          'D11',
          'A45',
          'C30',
          'B59',
          'D30',
          'A37',
          'C12',
          'A20',
          'B53',
          'C22',
          'A26',
          'A51',
          'B32',
          'C39',
          'A57',
          'A1',
          'C23',
          'D57',
          'A10',
          'D40',
          'A59',
          'A17',
          'D55',
          'B14',
          'C37',
          'B24',
          'D32',
          'B56',
          'C28',
          'D15',
          'A21',
          'A52',
          'D46',
          'A9',
          'B59',
          'A15',
          'D43',
          'A43',
          'C5',
          'C21',
          'D18',
          'A26',
          'B12',
          'C59',
          'D5',
          'A56',
          'A23',
          'B44',
          'D41',
          'B21',
          'C35',
          'D23',
          'A5',
          'C1',
          'B13',
          'C56',
          'D49',
          'B16',
          'D46',
          'B28',
          'D4',
          'B24',
          'C34',
          'D7',
          'A51',
          'C10',
          'B36',
          'C47',
          'D31',
          'C7',
          'B29',
          'B58',
          'A37',
          'A4',
          'C14',
          'A15',
          'D10',
          'A48',
          'A28',
          'C4',
          'C42',
          'B17',
          'A12',
          'B18',
          'C22',
          'B24',
          'D45',
          'B40',
          'D55',
          'A11',
          'B36',
          'C52',
          'C37',
          'B35',
          'A49',
          'C2',
          'B10',
          'A55',
          'C54',
          'D5',
          'A21',
          'D19',
          'B32',
          'C1',
          'B38',
          'D37',
          'A48',
          'C5',
          'D26',
          'C24',
          'D42',
          'A5',
          'B4',
          'C8',
          'A47',
          'A32',
          'B11',
          'C25',
          'B26',
          'C30',
          'B60',
          'C52',
          'B23',
          'C46',
          'D32',
          'B25',
          'C29',
          'A15',
          'C9',
          'B36',
          'A10',
          'B57',
          'A23',
          'B28',
          'D54',
          'A35',
          'C17',
          'A14',
          'B1',
          'D34',
          'C3',
          'B9',
          'A16',
          'C39',
          'D15',
          'A54',
          'C58',
          'B12',
          'C44',
          'B14',
          'D52',
          'A31',
          'B8',
          'C43',
          'D18',
          'B34',
          'B52',
          'C35',
          'D38',
          'A47',
          'B54',
          'C31',
          'D19',
          'C30',
          'A5',
          'D28',
          'B7',
          'C47',
          'A46',
          'C40',
          'A13',
          'B13',
          'D57',
          'D20',
          'A23',
          'B53',
          'C60',
          'B30',
          'D40',
          'C26',
          'B9',
          'C41',
          'B60',
          'D30',
          'C27',
          'B42',
          'A24',
          'A40',
          'C16',
          'A27',
          'B40',
          'D54',
          'A2',
          'B47',
          'D22',
          'B16',
          'D35',
          'A41',
          'C50',
          'A42',
          'D44',
          'C24',
          'D46',
          'B48',
          'D51',
          'A50',
          'A25',
          'C4',
          'A54',
          'D6',
          'C36',
          'B32',
          'C33',
          'B18',
          'D49',
          'A17',
          'C25',
          'A49',
          'D8',
          'C17',
          'B7',
          'D38',
          'A12',
          'D4',
          'A1',
          'D12',
          'B4',
          'A20',
          'C28',
          'B57',
          'B2',
          'C5',
          'B38',
          'C44',
          'B23',
          'C43',
          'A4',
          'D48',
          'B24',
          'D34',
          'A48',
          'D16',
          'B31',
          'A22',
          'A50',
          'D4',
          'C25',
          'D22',
          'B17',
          'C10',
          'D30',
          'A1',
          'B11',
          'C13',
          'A36',
          'D35',
          'B19',
          'C50',
          'A44',
          'B40',
          'A11',
          'C21',
          'D59',
          'B20',
          'A29',
          'C23',
          'A7',
          'B5',
          'A20',
          'B54',
          'C40',
          'B1',
          'D33',
          'B30',
          'C7',
          'A47',
          'D48',
          'D32',
          'A48',
          'B13',
          'C55',
          'D36',
          'A8',
          'D3',
          'B26',
          'C29',
          'B49',
          'C48',
          'D55',
          'A40',
          'C15',
          'C41',
          'B17',
          'C8',
          'A55',
          'C36',
          'C59',
          'D7',
          'C24',
          'C9',
          'A53',
          'B8',
          'C34',
          'D35',
          'A38',
          'C51',
          'D53',
          'B6',
          'A19',
          'B40',
          'B55',
          'B32',
          'B59',
          'D46',
          'B60',
          'B27',
          'A27',
          'C37',
          'B50',
          'A2',
          'B1',
          'C7',
          'B56',
          'A41',
          'C20',
          'D12',
          'B7',
          'C35',
          'B35',
          'C12',
          'B29',
          'D42',
          'C58',
          'B17',
          'D44',
          'B28',
          'C26',
          'D11',
          'A14',
          'D3',
          'A7',
          'D41',
          'C7',
          'B42',
          'D59',
          'B43',
          'D39',
          'C27',
          'B25',
          'A32',
          'C14',
          'A22',
          'A58',
          'C36',
          'B2',
          'D54',
          'B20',
          'C38',
          'B49',
          'A29',
          'C21',
          'B60',
          'D45',
          'A53',
          'B39',
          'C26',
          'C57',
          'B12',
          'C2',
          'B26',
          'A15',
          'B56',
          'D31',
          'C4',
          'B7',
          'C46',
          'A47',
          'A32',
          'D52',
          'B46',
          'A16',
          'D56',
          'A54',
          'B31',
          'C52',
          'A10',
          'C23',
          'B58',
          'D29',
          'B60',
          'C35',
          'B55',
          'C31',
          'A13',
          'B34',
          'C19',
          'D15',
          'A51',
          'C59',
          'C5',
          'B53',
          'A53',
          'D18',
          'C42',
          'B30',
          'C40',
          'B38',
          'D28',
          'C11',
          'A58',
          'D45',
          'B13',
          'D36',
          'A35',
          'A6',
          'B48',
          'A21',
          'B3',
          'D47',
          'B20',
          'A28',
          'A48',
          'C50',
          'B57',
          'A3',
          'A40',
          'C3',
          'B54',
          'D33',
          'B14',
          'C43',
          'A47',
          'C1',
          'B39',
          'C24',
          'B11',
          'C60',
          'D25',
          'B16',
          'C13',
          'B55',
          'C56',
          'D43',
          'B42',
          'A11',
          'C41',
          'B34',
          'A22',
          'C10',
          'D54',
          'B43',
          'C44',
          'B1',
          'C15',
          'A20',
          'D16',
          'A3',
          'D49',
          'B50',
          'C36',
          'B4',
          'C14',
          'A35',
          'B18',
          'C32',
          'B24',
          'C31',
          'B58',
          'A16',
          'B10',
          'D50',
          'A12',
          'B27',
          'D60',
          'B41',
          'C56',
          'C37',
          'A28',
          'D49',
          'A55',
          'A26',
          'D24',
          'C6',
          'B13',
          'D1',
          'C30',
          'B24',
          'A36',
          'D8',
          'B9',
          'C34',
          'D23',
          'B15',
          'A13',
          'C18',
          'D12',
          'C36',
          'B14',
          'A18',
          'C16',
          'C59',
          'A58',
          'C45',
          'A59',
          'D39',
          'B2',
          'A22',
          'B43',
          'B58',
          'D47',
          'C24',
          'B40',
          'D48',
          'A48',
          'D58',
          'A14',
          'C4',
          'B38',
          'C39',
          'A18',
          'B6',
          'D33',
          'B20',
          'C5',
          'B25',
          'D45',
          'D1',
          'B18',
          'A8',
          'C42',
          'B23',
          'C40',
          'B17',
          'C1',
          'A52',
          'C12',
          'D26',
          'B33',
          'C32',
          'D35',
          'C3',
          'A23',
          'A56',
          'A18',
          'D54',
          'C34',
          'B52',
          'A11',
          'C19',
          'A34',
          'D51',
          'B29',
          'D41',
          'A58',
          'C6',
          'A42',
          'C15',
          'A30',
          'D22',
          'B48',
          'C39',
          'B23',
          'C33',
          'D25',
          'C25',
          'A28',
          'A49',
          'D47',
          'B12',
          'C49',
          'B5',
          'D39',
          'B30',
          'D7',
          'D40',
          'B44',
          'C55',
          'D53',
          'B9',
          'A14',
          'B11',
          'C12',
          'A36',
          'B40',
          'D41',
          'A40',
          'C51',
          'B56',
          'D38',
          'B41',
          'C18',
          'A17',
          'B6',
          'C6',
          'B17',
          'C56',
          'B50',
          'D19',
          'B21',
          'D29',
          'B26',
          'A24',
          'A53',
          'C37',
          'B51',
          'A21',
          'D6',
          'C54',
          'D7',
          'C33',
          'A5',
          'B33',
          'D16',
          'C39',
          'A25',
          'A51',
          'D50',
          'B14',
          'C34',
          'B55',
          'C21',
          'A32',
          'A47',
          'B37',
          'C47',
          'A2',
          'B27',
          'D49',
          'B20',
          'A6',
          'D24',
          'B36',
          'C8',
          'A57',
          'D4',
          'A23',
          'C44',
        ],
      },
    ],
  },
  {
    title: 'Winston Churchill',
    id: 'winston-churchill',
    image:
      'https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/stringPortraits/Winston%20Churchill.png',
    artData: [
      {
        size: {
          x: 300,
          y: 300,
        },
        pegs: [
          {
            x: 300,
            y: 150,
            angle: 0,
            name: 'D1',
          },
          {
            x: 299.9485987463336,
            y: 153.92654224618096,
            angle: 0.02617993877991494,
            name: 'D2',
          },
          {
            x: 299.79443021318605,
            y: 157.85039343644155,
            angle: 0.05235987755982988,
            name: 'D3',
          },
          {
            x: 299.5376000599692,
            y: 161.76886435917677,
            angle: 0.07853981633974483,
            name: 'D4',
          },
          {
            x: 299.178284305241,
            y: 165.67926949014802,
            angle: 0.10471975511965978,
            name: 'D5',
          },
          {
            x: 298.71672920607153,
            y: 169.57892883300772,
            angle: 0.13089969389957473,
            name: 'D6',
          },
          {
            x: 298.15325108927067,
            y: 173.4651697560346,
            angle: 0.15707963267948968,
            name: 'D7',
          },
          {
            x: 297.4882361345932,
            y: 177.3353288238221,
            angle: 0.18325957145940464,
            name: 'D8',
          },
          {
            x: 296.7221401100708,
            y: 181.1867536226639,
            angle: 0.20943951023931956,
            name: 'D9',
          },
          {
            x: 295.85548805965146,
            y: 185.01680457838577,
            angle: 0.23561944901923448,
            name: 'D10',
          },
          {
            x: 294.88887394336024,
            y: 188.8228567653781,
            angle: 0.2617993877991494,
            name: 'D11',
          },
          {
            x: 293.82296023022894,
            y: 192.6023017055884,
            angle: 0.28797932657906433,
            name: 'D12',
          },
          {
            x: 292.65847744427305,
            y: 196.3525491562421,
            angle: 0.31415926535897926,
            name: 'D13',
          },
          {
            x: 291.3962236638268,
            y: 200.07102888506563,
            angle: 0.3403392041388942,
            name: 'D14',
          },
          {
            x: 290.03706397458024,
            y: 203.755192431795,
            angle: 0.3665191429188091,
            name: 'D15',
          },
          {
            x: 288.58192987669304,
            y: 207.40251485476344,
            angle: 0.39269908169872403,
            name: 'D16',
          },
          {
            x: 287.03181864639015,
            y: 211.01049646137002,
            angle: 0.41887902047863895,
            name: 'D17',
          },
          {
            x: 285.3877926524791,
            y: 214.57666452124423,
            angle: 0.4450589592585539,
            name: 'D18',
          },
          {
            x: 283.6509786282552,
            y: 218.098574960932,
            angle: 0.4712388980384688,
            name: 'D19',
          },
          {
            x: 281.8225668992948,
            y: 221.57381403894124,
            angle: 0.4974188368183837,
            name: 'D20',
          },
          {
            x: 279.9038105676658,
            y: 224.99999999999997,
            angle: 0.5235987755982987,
            name: 'D21',
          },
          {
            x: 277.8960246531138,
            y: 228.3747847073923,
            angle: 0.5497787143782137,
            name: 'D22',
          },
          {
            x: 275.8005851918136,
            y: 231.69585525225403,
            angle: 0.5759586531581287,
            name: 'D23',
          },
          {
            x: 273.61892829330236,
            y: 234.96093553872493,
            angle: 0.6021385919380436,
            name: 'D24',
          },
          {
            x: 271.3525491562421,
            y: 238.16778784387097,
            angle: 0.6283185307179586,
            name: 'D25',
          },
          {
            x: 269.00300104368523,
            y: 241.31421435130807,
            angle: 0.6544984694978736,
            name: 'D26',
          },
          {
            x: 266.57189421854565,
            y: 244.39805865747562,
            angle: 0.6806784082777886,
            name: 'D27',
          },
          {
            x: 264.06089484000466,
            y: 247.41720724952756,
            angle: 0.7068583470577036,
            name: 'D28',
          },
          {
            x: 261.4717238216091,
            y: 250.36959095382872,
            angle: 0.7330382858376185,
            name: 'D29',
          },
          {
            x: 258.80615565184314,
            y: 253.25318635406313,
            angle: 0.7592182246175335,
            name: 'D30',
          },
          {
            x: 256.06601717798213,
            y: 256.06601717798213,
            angle: 0.7853981633974485,
            name: 'D31',
          },
          {
            x: 253.25318635406308,
            y: 258.80615565184314,
            angle: 0.8115781021773635,
            name: 'D32',
          },
          {
            x: 250.36959095382866,
            y: 261.47172382160915,
            angle: 0.8377580409572785,
            name: 'D33',
          },
          {
            x: 247.4172072495275,
            y: 264.06089484000466,
            angle: 0.8639379797371934,
            name: 'D34',
          },
          {
            x: 244.3980586574756,
            y: 266.57189421854565,
            angle: 0.8901179185171084,
            name: 'D35',
          },
          {
            x: 241.31421435130804,
            y: 269.0030010436853,
            angle: 0.9162978572970234,
            name: 'D36',
          },
          {
            x: 238.16778784387094,
            y: 271.35254915624216,
            angle: 0.9424777960769384,
            name: 'D37',
          },
          {
            x: 234.96093553872487,
            y: 273.61892829330236,
            angle: 0.9686577348568534,
            name: 'D38',
          },
          {
            x: 231.69585525225398,
            y: 275.80058519181364,
            angle: 0.9948376736367683,
            name: 'D39',
          },
          {
            x: 228.37478470739225,
            y: 277.89602465311384,
            angle: 1.0210176124166832,
            name: 'D40',
          },
          {
            x: 224.99999999999997,
            y: 279.9038105676658,
            angle: 1.047197551196598,
            name: 'D41',
          },
          {
            x: 221.5738140389412,
            y: 281.8225668992948,
            angle: 1.073377489976513,
            name: 'D42',
          },
          {
            x: 218.098574960932,
            y: 283.6509786282552,
            angle: 1.0995574287564278,
            name: 'D43',
          },
          {
            x: 214.57666452124423,
            y: 285.3877926524791,
            angle: 1.1257373675363427,
            name: 'D44',
          },
          {
            x: 211.01049646137002,
            y: 287.0318186463901,
            angle: 1.1519173063162575,
            name: 'D45',
          },
          {
            x: 207.40251485476347,
            y: 288.581929876693,
            angle: 1.1780972450961724,
            name: 'D46',
          },
          {
            x: 203.75519243179505,
            y: 290.03706397458024,
            angle: 1.2042771838760873,
            name: 'D47',
          },
          {
            x: 200.07102888506566,
            y: 291.39622366382673,
            angle: 1.2304571226560022,
            name: 'D48',
          },
          {
            x: 196.35254915624213,
            y: 292.65847744427305,
            angle: 1.256637061435917,
            name: 'D49',
          },
          {
            x: 192.60230170558842,
            y: 293.82296023022894,
            angle: 1.282817000215832,
            name: 'D50',
          },
          {
            x: 188.82285676537816,
            y: 294.88887394336024,
            angle: 1.3089969389957468,
            name: 'D51',
          },
          {
            x: 185.0168045783859,
            y: 295.85548805965146,
            angle: 1.3351768777756616,
            name: 'D52',
          },
          {
            x: 181.18675362266396,
            y: 296.7221401100708,
            angle: 1.3613568165555765,
            name: 'D53',
          },
          {
            x: 177.3353288238222,
            y: 297.48823613459314,
            angle: 1.3875367553354914,
            name: 'D54',
          },
          {
            x: 173.46516975603473,
            y: 298.15325108927067,
            angle: 1.4137166941154062,
            name: 'D55',
          },
          {
            x: 169.57892883300786,
            y: 298.71672920607153,
            angle: 1.439896632895321,
            name: 'D56',
          },
          {
            x: 165.67926949014816,
            y: 299.178284305241,
            angle: 1.466076571675236,
            name: 'D57',
          },
          {
            x: 161.7688643591769,
            y: 299.5376000599692,
            angle: 1.4922565104551508,
            name: 'D58',
          },
          {
            x: 157.85039343644172,
            y: 299.79443021318605,
            angle: 1.5184364492350657,
            name: 'D59',
          },
          {
            x: 153.92654224618113,
            y: 299.9485987463336,
            angle: 1.5446163880149806,
            name: 'D60',
          },
          {
            x: 150.00000000000017,
            y: 300,
            angle: 1.5707963267948954,
            name: 'C1',
          },
          {
            x: 146.0734577538192,
            y: 299.9485987463336,
            angle: 1.5969762655748103,
            name: 'C2',
          },
          {
            x: 142.14960656355862,
            y: 299.7944302131861,
            angle: 1.6231562043547252,
            name: 'C3',
          },
          {
            x: 138.23113564082345,
            y: 299.5376000599692,
            angle: 1.64933614313464,
            name: 'C4',
          },
          {
            x: 134.32073050985218,
            y: 299.17828430524105,
            angle: 1.675516081914555,
            name: 'C5',
          },
          {
            x: 130.4210711669925,
            y: 298.71672920607153,
            angle: 1.7016960206944698,
            name: 'C6',
          },
          {
            x: 126.5348302439656,
            y: 298.15325108927067,
            angle: 1.7278759594743847,
            name: 'C7',
          },
          {
            x: 122.66467117617813,
            y: 297.4882361345932,
            angle: 1.7540558982542995,
            name: 'C8',
          },
          {
            x: 118.81324637733636,
            y: 296.7221401100709,
            angle: 1.7802358370342144,
            name: 'C9',
          },
          {
            x: 114.98319542161444,
            y: 295.8554880596516,
            angle: 1.8064157758141293,
            name: 'C10',
          },
          {
            x: 111.17714323462215,
            y: 294.8888739433603,
            angle: 1.8325957145940441,
            name: 'C11',
          },
          {
            x: 107.39769829441191,
            y: 293.822960230229,
            angle: 1.858775653373959,
            name: 'C12',
          },
          {
            x: 103.6474508437582,
            y: 292.6584774442731,
            angle: 1.8849555921538739,
            name: 'C13',
          },
          {
            x: 99.92897111493467,
            y: 291.39622366382685,
            angle: 1.9111355309337887,
            name: 'C14',
          },
          {
            x: 96.24480756820525,
            y: 290.03706397458035,
            angle: 1.9373154697137036,
            name: 'C15',
          },
          {
            x: 92.59748514523686,
            y: 288.58192987669315,
            angle: 1.9634954084936185,
            name: 'C16',
          },
          {
            x: 88.98950353863029,
            y: 287.03181864639026,
            angle: 1.9896753472735333,
            name: 'C17',
          },
          {
            x: 85.42333547875606,
            y: 285.3877926524792,
            angle: 2.015855286053448,
            name: 'C18',
          },
          {
            x: 81.90142503906829,
            y: 283.65097862825536,
            angle: 2.0420352248333633,
            name: 'C19',
          },
          {
            x: 78.42618596105902,
            y: 281.82256689929494,
            angle: 2.0682151636132784,
            name: 'C20',
          },
          {
            x: 75.00000000000026,
            y: 279.90381056766597,
            angle: 2.0943951023931935,
            name: 'C21',
          },
          {
            x: 71.62521529260792,
            y: 277.89602465311395,
            angle: 2.1205750411731086,
            name: 'C22',
          },
          {
            x: 68.30414474774615,
            y: 275.8005851918137,
            angle: 2.1467549799530237,
            name: 'C23',
          },
          {
            x: 65.03906446127527,
            y: 273.6189282933025,
            angle: 2.1729349187329388,
            name: 'C24',
          },
          {
            x: 61.832212156129195,
            y: 271.3525491562422,
            angle: 2.199114857512854,
            name: 'C25',
          },
          {
            x: 58.685785648692054,
            y: 269.00300104368534,
            angle: 2.225294796292769,
            name: 'C26',
          },
          {
            x: 55.60194134252451,
            y: 266.5718942185457,
            angle: 2.251474735072684,
            name: 'C27',
          },
          {
            x: 52.58279275047257,
            y: 264.0608948400047,
            angle: 2.277654673852599,
            name: 'C28',
          },
          {
            x: 49.630409046171366,
            y: 261.47172382160926,
            angle: 2.303834612632514,
            name: 'C29',
          },
          {
            x: 46.746813645936975,
            y: 258.8061556518432,
            angle: 2.3300145514124293,
            name: 'C30',
          },
          {
            x: 43.933982822017924,
            y: 256.0660171779822,
            angle: 2.3561944901923444,
            name: 'C31',
          },
          {
            x: 41.193844348156894,
            y: 253.25318635406316,
            angle: 2.3823744289722595,
            name: 'C32',
          },
          {
            x: 38.5282761783909,
            y: 250.36959095382872,
            angle: 2.4085543677521746,
            name: 'C33',
          },
          {
            x: 35.93910515999536,
            y: 247.41720724952756,
            angle: 2.4347343065320897,
            name: 'C34',
          },
          {
            x: 33.42810578145436,
            y: 244.3980586574756,
            angle: 2.4609142453120048,
            name: 'C35',
          },
          {
            x: 30.99699895631471,
            y: 241.31421435130804,
            angle: 2.48709418409192,
            name: 'C36',
          },
          {
            x: 28.64745084375785,
            y: 238.16778784387094,
            angle: 2.513274122871835,
            name: 'C37',
          },
          {
            x: 26.38107170669761,
            y: 234.96093553872487,
            angle: 2.53945406165175,
            name: 'C38',
          },
          {
            x: 24.199414808186344,
            y: 231.69585525225398,
            angle: 2.565634000431665,
            name: 'C39',
          },
          {
            x: 22.103975346886106,
            y: 228.37478470739225,
            angle: 2.59181393921158,
            name: 'C40',
          },
          {
            x: 20.096189432334125,
            y: 224.99999999999986,
            angle: 2.6179938779914953,
            name: 'C41',
          },
          {
            x: 18.17743310070511,
            y: 221.57381403894112,
            angle: 2.6441738167714104,
            name: 'C42',
          },
          {
            x: 16.34902137174473,
            y: 218.09857496093184,
            angle: 2.6703537555513255,
            name: 'C43',
          },
          {
            x: 14.612207347520823,
            y: 214.57666452124406,
            angle: 2.6965336943312406,
            name: 'C44',
          },
          {
            x: 12.96818135360977,
            y: 211.01049646136983,
            angle: 2.7227136331111557,
            name: 'C45',
          },
          {
            x: 11.418070123306888,
            y: 207.40251485476324,
            angle: 2.7488935718910708,
            name: 'C46',
          },
          {
            x: 9.962936025419639,
            y: 203.7551924317948,
            angle: 2.775073510670986,
            name: 'C47',
          },
          {
            x: 8.603776336173135,
            y: 200.07102888506535,
            angle: 2.801253449450901,
            name: 'C48',
          },
          {
            x: 7.34152255572687,
            y: 196.3525491562418,
            angle: 2.827433388230816,
            name: 'C49',
          },
          {
            x: 6.177039769770942,
            y: 192.60230170558805,
            angle: 2.853613327010731,
            name: 'C50',
          },
          {
            x: 5.11112605663967,
            y: 188.82285676537776,
            angle: 2.879793265790646,
            name: 'C51',
          },
          {
            x: 4.1445119403484165,
            y: 185.0168045783854,
            angle: 2.9059732045705613,
            name: 'C52',
          },
          {
            x: 3.2778598899290636,
            y: 181.18675362266353,
            angle: 2.9321531433504764,
            name: 'C53',
          },
          {
            x: 2.5117638654067407,
            y: 177.3353288238217,
            angle: 2.9583330821303915,
            name: 'C54',
          },
          {
            x: 1.8467489107292678,
            y: 173.4651697560342,
            angle: 2.9845130209103066,
            name: 'C55',
          },
          {
            x: 1.2832707939283758,
            y: 169.57892883300727,
            angle: 3.0106929596902217,
            name: 'C56',
          },
          {
            x: 0.8217156947589399,
            y: 165.67926949014753,
            angle: 3.0368728984701368,
            name: 'C57',
          },
          {
            x: 0.46239994003075546,
            y: 161.7688643591762,
            angle: 3.063052837250052,
            name: 'C58',
          },
          {
            x: 0.2055697868138917,
            y: 157.85039343644104,
            angle: 3.089232776029967,
            name: 'C59',
          },
          {
            x: 0.051401253666394364,
            y: 153.9265422461804,
            angle: 3.115412714809882,
            name: 'C60',
          },
          {
            x: 0,
            y: 149.9999999999994,
            angle: 3.141592653589797,
            name: 'A1',
          },
          {
            x: 0.05140125366642767,
            y: 146.07345775381842,
            angle: 3.167772592369712,
            name: 'A2',
          },
          {
            x: 0.20556978681395832,
            y: 142.1496065635578,
            angle: 3.1939525311496273,
            name: 'A3',
          },
          {
            x: 0.4623999400308554,
            y: 138.2311356408226,
            angle: 3.2201324699295424,
            name: 'A4',
          },
          {
            x: 0.8217156947590731,
            y: 134.3207305098513,
            angle: 3.2463124087094575,
            name: 'A5',
          },
          {
            x: 1.283270793928526,
            y: 130.42107116699157,
            angle: 3.2724923474893726,
            name: 'A6',
          },
          {
            x: 1.846748910729451,
            y: 126.53483024396465,
            angle: 3.2986722862692877,
            name: 'A7',
          },
          {
            x: 2.5117638654069405,
            y: 122.66467117617715,
            angle: 3.3248522250492027,
            name: 'A8',
          },
          {
            x: 3.2778598899293128,
            y: 118.81324637733535,
            angle: 3.351032163829118,
            name: 'A9',
          },
          {
            x: 4.144511940348699,
            y: 114.98319542161344,
            angle: 3.377212102609033,
            name: 'A10',
          },
          {
            x: 5.11112605663997,
            y: 111.17714323462113,
            angle: 3.403392041388948,
            name: 'A11',
          },
          {
            x: 6.177039769771276,
            y: 107.39769829441082,
            angle: 3.429571980168863,
            name: 'A12',
          },
          {
            x: 7.34152255572722,
            y: 103.64745084375708,
            angle: 3.455751918948778,
            name: 'A13',
          },
          {
            x: 8.603776336173535,
            y: 99.92897111493353,
            angle: 3.4819318577286933,
            name: 'A14',
          },
          {
            x: 9.962936025420055,
            y: 96.24480756820412,
            angle: 3.5081117965086084,
            name: 'A15',
          },
          {
            x: 11.418070123307338,
            y: 92.59748514523568,
            angle: 3.5342917352885235,
            name: 'A16',
          },
          {
            x: 12.968181353610253,
            y: 88.98950353862911,
            angle: 3.5604716740684386,
            name: 'A17',
          },
          {
            x: 14.61220734752132,
            y: 85.42333547875486,
            angle: 3.5866516128483537,
            name: 'A18',
          },
          {
            x: 16.349021371745263,
            y: 81.90142503906712,
            angle: 3.6128315516282687,
            name: 'A19',
          },
          {
            x: 18.177433100705674,
            y: 78.42618596105784,
            angle: 3.639011490408184,
            name: 'A20',
          },
          {
            x: 20.096189432334707,
            y: 74.9999999999991,
            angle: 3.665191429188099,
            name: 'A21',
          },
          {
            x: 22.103975346886706,
            y: 71.62521529260678,
            angle: 3.691371367968014,
            name: 'A22',
          },
          {
            x: 24.199414808186972,
            y: 68.30414474774504,
            angle: 3.717551306747929,
            name: 'A23',
          },
          {
            x: 26.38107170669826,
            y: 65.03906446127418,
            angle: 3.743731245527844,
            name: 'A24',
          },
          {
            x: 28.64745084375853,
            y: 61.83221215612813,
            angle: 3.7699111843077593,
            name: 'A25',
          },
          {
            x: 30.99699895631541,
            y: 58.685785648691,
            angle: 3.7960911230876744,
            name: 'A26',
          },
          {
            x: 33.428105781455095,
            y: 55.60194134252349,
            angle: 3.8222710618675895,
            name: 'A27',
          },
          {
            x: 35.939105159996124,
            y: 52.582792750471555,
            angle: 3.8484510006475046,
            name: 'A28',
          },
          {
            x: 38.528276178391664,
            y: 49.630409046170385,
            angle: 3.8746309394274197,
            name: 'A29',
          },
          {
            x: 41.19384434815768,
            y: 46.746813645936015,
            angle: 3.9008108782073347,
            name: 'A30',
          },
          {
            x: 43.93398282201874,
            y: 43.93398282201699,
            angle: 3.92699081698725,
            name: 'A31',
          },
          {
            x: 46.74681364593781,
            y: 41.193844348155984,
            angle: 3.953170755767165,
            name: 'A32',
          },
          {
            x: 49.630409046172225,
            y: 38.52827617839,
            angle: 3.97935069454708,
            name: 'A33',
          },
          {
            x: 52.582792750473395,
            y: 35.93910515999454,
            angle: 4.005530633326995,
            name: 'A34',
          },
          {
            x: 55.60194134252536,
            y: 33.42810578145356,
            angle: 4.03171057210691,
            name: 'A35',
          },
          {
            x: 58.685785648692914,
            y: 30.99699895631394,
            angle: 4.057890510886825,
            name: 'A36',
          },
          {
            x: 61.83221215613009,
            y: 28.647450843757117,
            angle: 4.08407044966674,
            name: 'A37',
          },
          {
            x: 65.03906446127617,
            y: 26.381071706696897,
            angle: 4.110250388446655,
            name: 'A38',
          },
          {
            x: 68.30414474774706,
            y: 24.19941480818566,
            angle: 4.13643032722657,
            name: 'A39',
          },
          {
            x: 71.62521529260884,
            y: 22.103975346885438,
            angle: 4.162610266006485,
            name: 'A40',
          },
          {
            x: 75.0000000000012,
            y: 20.09618943233351,
            angle: 4.1887902047864,
            name: 'A41',
          },
          {
            x: 78.42618596105997,
            y: 18.17743310070451,
            angle: 4.214970143566315,
            name: 'A42',
          },
          {
            x: 81.90142503906925,
            y: 16.349021371744165,
            angle: 4.2411500823462305,
            name: 'A43',
          },
          {
            x: 85.42333547875705,
            y: 14.612207347520272,
            angle: 4.267330021126146,
            name: 'A44',
          },
          {
            x: 88.98950353863133,
            y: 12.968181353609253,
            angle: 4.293509959906061,
            name: 'A45',
          },
          {
            x: 92.59748514523791,
            y: 11.418070123306407,
            angle: 4.319689898685976,
            name: 'A46',
          },
          {
            x: 96.2448075682064,
            y: 9.962936025419188,
            angle: 4.345869837465891,
            name: 'A47',
          },
          {
            x: 99.92897111493582,
            y: 8.603776336172718,
            angle: 4.372049776245806,
            name: 'A48',
          },
          {
            x: 103.64745084375939,
            y: 7.3415225557264705,
            angle: 4.398229715025721,
            name: 'A49',
          },
          {
            x: 107.39769829441313,
            y: 6.177039769770592,
            angle: 4.424409653805636,
            name: 'A50',
          },
          {
            x: 111.17714323462344,
            y: 5.111126056639336,
            angle: 4.450589592585551,
            name: 'A51',
          },
          {
            x: 114.98319542161578,
            y: 4.144511940348133,
            angle: 4.476769531365466,
            name: 'A52',
          },
          {
            x: 118.81324637733772,
            y: 3.2778598899288136,
            angle: 4.502949470145381,
            name: 'A53',
          },
          {
            x: 122.66467117617954,
            y: 2.511763865406508,
            angle: 4.5291294089252965,
            name: 'A54',
          },
          {
            x: 126.53483024396705,
            y: 1.846748910729068,
            angle: 4.555309347705212,
            name: 'A55',
          },
          {
            x: 130.42107116699398,
            y: 1.2832707939282095,
            angle: 4.581489286485127,
            name: 'A56',
          },
          {
            x: 134.32073050985372,
            y: 0.8217156947588233,
            angle: 4.607669225265042,
            name: 'A57',
          },
          {
            x: 138.23113564082502,
            y: 0.4623999400306722,
            angle: 4.633849164044957,
            name: 'A58',
          },
          {
            x: 142.1496065635602,
            y: 0.2055697868138251,
            angle: 4.660029102824872,
            name: 'A59',
          },
          {
            x: 146.07345775382083,
            y: 0.05140125366636106,
            angle: 4.686209041604787,
            name: 'A60',
          },
          {
            x: 150.00000000000182,
            y: 0,
            angle: 4.712388980384702,
            name: 'B1',
          },
          {
            x: 153.92654224618283,
            y: 0.05140125366646098,
            angle: 4.738568919164617,
            name: 'B2',
          },
          {
            x: 157.85039343644345,
            y: 0.20556978681402494,
            angle: 4.764748857944532,
            name: 'B3',
          },
          {
            x: 161.76886435917865,
            y: 0.4623999400309553,
            angle: 4.790928796724447,
            name: 'B4',
          },
          {
            x: 165.67926949014992,
            y: 0.8217156947592064,
            angle: 4.8171087355043625,
            name: 'B5',
          },
          {
            x: 169.57892883300968,
            y: 1.2832707939286925,
            angle: 4.843288674284278,
            name: 'B6',
          },
          {
            x: 173.46516975603657,
            y: 1.8467489107296509,
            angle: 4.869468613064193,
            name: 'B7',
          },
          {
            x: 177.33532882382409,
            y: 2.511763865407174,
            angle: 4.895648551844108,
            name: 'B8',
          },
          {
            x: 181.18675362266586,
            y: 3.277859889929579,
            angle: 4.921828490624023,
            name: 'B9',
          },
          {
            x: 185.0168045783878,
            y: 4.144511940348983,
            angle: 4.948008429403938,
            name: 'B10',
          },
          {
            x: 188.82285676538012,
            y: 5.111126056640286,
            angle: 4.974188368183853,
            name: 'B11',
          },
          {
            x: 192.6023017055904,
            y: 6.177039769771625,
            angle: 5.000368306963768,
            name: 'B12',
          },
          {
            x: 196.35254915624412,
            y: 7.34152255572762,
            angle: 5.026548245743683,
            name: 'B13',
          },
          {
            x: 200.07102888506765,
            y: 8.603776336173953,
            angle: 5.052728184523598,
            name: 'B14',
          },
          {
            x: 203.75519243179704,
            y: 9.962936025420504,
            angle: 5.078908123303513,
            name: 'B15',
          },
          {
            x: 207.40251485476549,
            y: 11.418070123307823,
            angle: 5.1050880620834285,
            name: 'B16',
          },
          {
            x: 211.010496461372,
            y: 12.968181353610753,
            angle: 5.131268000863344,
            name: 'B17',
          },
          {
            x: 214.57666452124627,
            y: 14.612207347521853,
            angle: 5.157447939643259,
            name: 'B18',
          },
          {
            x: 218.09857496093403,
            y: 16.34902137174583,
            angle: 5.183627878423174,
            name: 'B19',
          },
          {
            x: 221.57381403894325,
            y: 18.177433100706274,
            angle: 5.209807817203089,
            name: 'B20',
          },
          {
            x: 225.00000000000196,
            y: 20.096189432335343,
            angle: 5.235987755983004,
            name: 'B21',
          },
          {
            x: 228.3747847073943,
            y: 22.10397534688737,
            angle: 5.262167694762919,
            name: 'B22',
          },
          {
            x: 231.695855252256,
            y: 24.199414808187658,
            angle: 5.288347633542834,
            name: 'B23',
          },
          {
            x: 234.96093553872683,
            y: 26.381071706698975,
            angle: 5.314527572322749,
            name: 'B24',
          },
          {
            x: 238.16778784387287,
            y: 28.64745084375928,
            angle: 5.340707511102664,
            name: 'B25',
          },
          {
            x: 241.31421435131,
            y: 30.996998956316176,
            angle: 5.366887449882579,
            name: 'B26',
          },
          {
            x: 244.39805865747746,
            y: 33.428105781455876,
            angle: 5.3930673886624945,
            name: 'B27',
          },
          {
            x: 247.41720724952938,
            y: 35.93910515999694,
            angle: 5.41924732744241,
            name: 'B28',
          },
          {
            x: 250.3695909538305,
            y: 38.52827617839251,
            angle: 5.445427266222325,
            name: 'B29',
          },
          {
            x: 253.25318635406487,
            y: 41.19384434815856,
            angle: 5.47160720500224,
            name: 'B30',
          },
          {
            x: 256.0660171779839,
            y: 43.93398282201965,
            angle: 5.497787143782155,
            name: 'B31',
          },
          {
            x: 258.80615565184485,
            y: 46.74681364593872,
            angle: 5.52396708256207,
            name: 'B32',
          },
          {
            x: 261.47172382161085,
            y: 49.63040904617316,
            angle: 5.550147021341985,
            name: 'B33',
          },
          {
            x: 264.0608948400063,
            y: 52.582792750474404,
            angle: 5.5763269601219,
            name: 'B34',
          },
          {
            x: 266.57189421854724,
            y: 55.60194134252639,
            angle: 5.602506898901815,
            name: 'B35',
          },
          {
            x: 269.0030010436868,
            y: 58.685785648693965,
            angle: 5.62868683768173,
            name: 'B36',
          },
          {
            x: 271.35254915624364,
            y: 61.832212156131156,
            angle: 5.654866776461645,
            name: 'B37',
          },
          {
            x: 273.61892829330384,
            y: 65.03906446127725,
            angle: 5.6810467152415605,
            name: 'B38',
          },
          {
            x: 275.80058519181506,
            y: 68.30414474774818,
            angle: 5.7072266540214756,
            name: 'B39',
          },
          {
            x: 277.8960246531152,
            y: 71.62521529260998,
            angle: 5.733406592801391,
            name: 'B40',
          },
          {
            x: 279.90381056766716,
            y: 75.00000000000236,
            angle: 5.759586531581306,
            name: 'B41',
          },
          {
            x: 281.8225668992961,
            y: 78.42618596106115,
            angle: 5.785766470361221,
            name: 'B42',
          },
          {
            x: 283.65097862825644,
            y: 81.90142503907046,
            angle: 5.811946409141136,
            name: 'B43',
          },
          {
            x: 285.3877926524803,
            y: 85.42333547875825,
            angle: 5.838126347921051,
            name: 'B44',
          },
          {
            x: 287.03181864639123,
            y: 88.98950353863255,
            angle: 5.864306286700966,
            name: 'B45',
          },
          {
            x: 288.58192987669406,
            y: 92.59748514523915,
            angle: 5.890486225480881,
            name: 'B46',
          },
          {
            x: 290.03706397458126,
            y: 96.24480756820762,
            angle: 5.916666164260796,
            name: 'B47',
          },
          {
            x: 291.3962236638277,
            y: 99.92897111493706,
            angle: 5.942846103040711,
            name: 'B48',
          },
          {
            x: 292.6584774442739,
            y: 103.64745084376064,
            angle: 5.9690260418206265,
            name: 'B49',
          },
          {
            x: 293.8229602302298,
            y: 107.3976982944144,
            angle: 5.9952059806005416,
            name: 'B50',
          },
          {
            x: 294.888873943361,
            y: 111.17714323462472,
            angle: 6.021385919380457,
            name: 'B51',
          },
          {
            x: 295.85548805965215,
            y: 114.98319542161707,
            angle: 6.047565858160372,
            name: 'B52',
          },
          {
            x: 296.72214011007145,
            y: 118.81324637733901,
            angle: 6.073745796940287,
            name: 'B53',
          },
          {
            x: 297.48823613459376,
            y: 122.66467117618083,
            angle: 6.099925735720202,
            name: 'B54',
          },
          {
            x: 298.1532510892711,
            y: 126.53483024396834,
            angle: 6.126105674500117,
            name: 'B55',
          },
          {
            x: 298.716729206072,
            y: 130.4210711669953,
            angle: 6.152285613280032,
            name: 'B56',
          },
          {
            x: 299.1782843052413,
            y: 134.32073050985503,
            angle: 6.178465552059947,
            name: 'B57',
          },
          {
            x: 299.53760005996946,
            y: 138.23113564082632,
            angle: 6.204645490839862,
            name: 'B58',
          },
          {
            x: 299.7944302131863,
            y: 142.14960656356152,
            angle: 6.230825429619777,
            name: 'B59',
          },
          {
            x: 299.9485987463337,
            y: 146.07345775382217,
            angle: 6.2570053683996925,
            name: 'B60',
          },
        ],
        steps: [
          'C10',
          'C29',
          'C14',
          'C35',
          'C9',
          'C32',
          'D58',
          'C36',
          'C3',
          'C34',
          'C18',
          'D52',
          'C8',
          'C33',
          'D56',
          'C37',
          'C2',
          'C30',
          'C11',
          'C26',
          'C5',
          'D48',
          'C13',
          'D51',
          'C35',
          'D60',
          'C31',
          'C9',
          'C24',
          'D49',
          'C32',
          'D59',
          'C36',
          'C7',
          'C34',
          'D52',
          'C29',
          'C3',
          'C27',
          'D56',
          'D17',
          'D42',
          'D18',
          'D37',
          'D20',
          'D35',
          'D13',
          'D43',
          'D14',
          'D40',
          'D15',
          'D39',
          'D16',
          'D33',
          'D12',
          'D44',
          'D26',
          'D41',
          'D11',
          'D45',
          'D22',
          'D38',
          'D23',
          'D42',
          'D19',
          'D34',
          'D18',
          'D56',
          'C35',
          'D49',
          'C30',
          'C15',
          'A33',
          'C14',
          'D57',
          'C33',
          'D48',
          'C36',
          'D50',
          'C20',
          'C4',
          'C32',
          'D53',
          'C28',
          'D60',
          'C24',
          'C5',
          'C31',
          'D51',
          'C34',
          'D54',
          'D13',
          'D44',
          'D15',
          'D43',
          'D16',
          'D39',
          'D17',
          'D32',
          'D14',
          'D29',
          'B22',
          'D28',
          'B23',
          'D27',
          'D42',
          'D12',
          'D45',
          'D9',
          'D41',
          'D26',
          'C14',
          'A32',
          'C15',
          'D49',
          'C37',
          'D54',
          'C36',
          'D55',
          'C28',
          'C10',
          'D51',
          'C38',
          'D53',
          'C9',
          'C31',
          'C1',
          'C35',
          'D50',
          'C39',
          'D52',
          'C33',
          'D58',
          'C26',
          'C11',
          'D22',
          'D37',
          'D13',
          'D41',
          'D12',
          'D44',
          'D9',
          'D43',
          'D15',
          'D30',
          'B21',
          'D26',
          'C13',
          'A31',
          'C12',
          'C27',
          'D57',
          'C37',
          'D53',
          'D11',
          'D45',
          'D7',
          'D42',
          'D20',
          'D40',
          'D13',
          'D44',
          'D5',
          'D43',
          'D16',
          'D38',
          'D23',
          'C11',
          'C35',
          'C4',
          'C28',
          'D49',
          'C36',
          'D57',
          'C34',
          'D55',
          'D14',
          'D54',
          'C30',
          'D59',
          'C32',
          'C17',
          'A37',
          'C16',
          'A35',
          'C15',
          'A34',
          'C14',
          'D58',
          'C21',
          'C1',
          'C33',
          'D48',
          'C38',
          'D52',
          'C36',
          'D47',
          'C34',
          'D56',
          'D19',
          'D34',
          'D13',
          'D44',
          'D2',
          'D43',
          'D28',
          'B20',
          'D27',
          'B22',
          'B7',
          'B24',
          'D31',
          'D16',
          'D38',
          'D17',
          'D32',
          'B25',
          'B6',
          'A46',
          'B1',
          'A41',
          'A56',
          'A38',
          'A58',
          'A37',
          'C17',
          'D53',
          'C35',
          'C16',
          'A34',
          'C13',
          'A32',
          'C12',
          'D24',
          'B20',
          'D26',
          'B22',
          'D31',
          'B23',
          'D25',
          'C14',
          'A35',
          'A59',
          'A38',
          'C20',
          'C4',
          'C27',
          'C7',
          'C32',
          'D57',
          'D13',
          'D41',
          'D14',
          'D54',
          'D15',
          'D40',
          'D20',
          'C9',
          'D53',
          'D11',
          'D45',
          'D4',
          'D44',
          'D6',
          'D43',
          'D10',
          'D55',
          'C38',
          'D50',
          'C40',
          'D51',
          'C37',
          'D47',
          'C21',
          'D49',
          'C11',
          'A30',
          'C13',
          'A35',
          'A60',
          'A37',
          'C15',
          'A36',
          'C18',
          'C33',
          'C3',
          'C32',
          'D48',
          'C39',
          'D54',
          'D10',
          'D43',
          'D28',
          'D13',
          'D55',
          'C35',
          'C2',
          'C30',
          'D51',
          'C42',
          'D53',
          'D14',
          'D44',
          'B57',
          'D42',
          'D27',
          'B19',
          'B4',
          'B25',
          'D31',
          'B21',
          'D29',
          'B23',
          'D26',
          'C12',
          'C31',
          'D58',
          'C35',
          'D48',
          'C37',
          'D55',
          'D13',
          'D28',
          'D43',
          'B54',
          'B15',
          'B47',
          'B11',
          'B42',
          'B13',
          'B51',
          'D41',
          'D14',
          'D40',
          'D20',
          'B21',
          'D25',
          'B22',
          'D22',
          'D37',
          'D16',
          'D54',
          'C29',
          'D59',
          'C26',
          'C8',
          'D15',
          'C7',
          'C23',
          'D56',
          'C33',
          'D58',
          'C46',
          'D55',
          'C42',
          'D52',
          'C36',
          'D60',
          'C25',
          'D48',
          'C31',
          'C16',
          'A36',
          'B1',
          'A35',
          'C18',
          'A40',
          'A56',
          'A39',
          'C17',
          'A38',
          'C19',
          'A37',
          'A58',
          'A43',
          'B4',
          'B19',
          'D30',
          'B20',
          'D23',
          'B23',
          'D22',
          'D37',
          'B42',
          'B8',
          'B45',
          'B11',
          'B40',
          'B9',
          'B24',
          'D29',
          'C16',
          'A33',
          'C15',
          'A38',
          'B3',
          'A37',
          'C18',
          'D28',
          'B21',
          'D18',
          'C8',
          'D13',
          'D55',
          'C36',
          'D46',
          'C38',
          'D47',
          'C40',
          'D53',
          'D8',
          'D44',
          'B59',
          'D43',
          'D11',
          'D42',
          'D2',
          'D41',
          'B52',
          'B10',
          'B48',
          'D40',
          'D14',
          'C8',
          'A29',
          'C12',
          'A33',
          'C14',
          'A31',
          'C11',
          'D55',
          'C28',
          'C2',
          'D12',
          'D56',
          'C38',
          'D54',
          'D10',
          'B15',
          'D1',
          'D45',
          'D3',
          'B16',
          'B59',
          'D42',
          'D7',
          'D44',
          'B55',
          'D43',
          'B56',
          'B17',
          'D24',
          'B22',
          'D30',
          'B18',
          'D29',
          'B19',
          'D25',
          'D40',
          'D15',
          'D36',
          'D16',
          'C6',
          'C35',
          'C20',
          'A39',
          'A56',
          'A19',
          'A58',
          'A17',
          'A53',
          'A10',
          'A54',
          'A7',
          'A55',
          'A6',
          'A52',
          'A12',
          'A57',
          'A40',
          'C17',
          'D28',
          'B24',
          'B2',
          'A35',
          'B3',
          'B22',
          'D20',
          'C8',
          'C32',
          'D50',
          'C43',
          'A45',
          'C42',
          'A46',
          'C46',
          'A47',
          'C49',
          'A48',
          'C51',
          'A49',
          'C54',
          'A50',
          'C53',
          'D60',
          'D24',
          'B23',
          'D21',
          'D58',
          'C37',
          'D48',
          'C41',
          'D52',
          'C30',
          'C9',
          'C24',
          'C3',
          'A27',
          'C6',
          'D12',
          'D42',
          'D8',
          'B14',
          'B50',
          'B7',
          'B46',
          'D39',
          'B48',
          'B5',
          'B43',
          'B10',
          'B38',
          'D36',
          'D21',
          'B20',
          'D29',
          'C36',
          'D56',
          'C45',
          'D59',
          'C34',
          'C2',
          'D13',
          'D53',
          'C46',
          'A48',
          'A3',
          'A52',
          'A1',
          'A53',
          'A15',
          'A58',
          'A12',
          'A59',
          'B24',
          'D27',
          'B21',
          'D23',
          'C13',
          'A33',
          'C11',
          'D24',
          'B19',
          'D28',
          'C35',
          'D57',
          'C48',
          'D55',
          'C41',
          'A46',
          'C55',
          'A47',
          'C45',
          'D54',
          'D10',
          'D53',
          'D14',
          'D39',
          'B52',
          'B12',
          'D6',
          'B15',
          'D4',
          'D44',
          'B58',
          'B17',
          'D30',
          'C16',
          'A40',
          'C25',
          'A41',
          'C18',
          'A38',
          'B6',
          'B47',
          'B3',
          'A34',
          'C17',
          'C1',
          'C36',
          'D48',
          'C29',
          'C14',
          'A36',
          'C13',
          'A31',
          'C10',
          'D19',
          'B22',
          'D18',
          'D39',
          'B59',
          'B13',
          'B44',
          'B9',
          'B53',
          'B6',
          'A37',
          'B8',
          'B46',
          'B16',
          'D25',
          'D58',
          'C30',
          'C6',
          'A28',
          'C7',
          'D13',
          'D28',
          'B18',
          'D26',
          'B19',
          'D22',
          'D37',
          'B43',
          'D36',
          'C22',
          'A40',
          'B1',
          'B25',
          'D27',
          'C34',
          'D49',
          'C41',
          'D46',
          'C39',
          'A43',
          'C37',
          'D30',
          'C3',
          'D11',
          'B14',
          'B41',
          'B9',
          'A37',
          'C14',
          'A32',
          'C15',
          'D29',
          'B15',
          'B57',
          'D41',
          'D12',
          'D56',
          'C41',
          'A47',
          'C52',
          'A52',
          'A8',
          'A53',
          'C59',
          'A55',
          'A21',
          'A57',
          'A18',
          'A59',
          'A15',
          'A60',
          'A12',
          'A28',
          'C3',
          'D31',
          'C2',
          'C33',
          'C5',
          'D49',
          'C39',
          'D47',
          'C42',
          'A48',
          'C45',
          'A44',
          'C44',
          'D54',
          'D7',
          'B10',
          'D5',
          'B14',
          'B58',
          'D44',
          'D1',
          'B12',
          'B37',
          'D35',
          'B23',
          'D20',
          'B20',
          'D19',
          'D39',
          'D2',
          'B11',
          'A37',
          'C21',
          'A39',
          'C19',
          'A44',
          'C43',
          'D51',
          'C36',
          'D22',
          'C10',
          'A30',
          'C12',
          'A34',
          'C8',
          'A32',
          'C7',
          'D11',
          'C3',
          'D12',
          'C1',
          'C52',
          'B48',
          'B4',
          'B44',
          'D36',
          'B22',
          'D17',
          'C6',
          'C28',
          'C13',
          'C34',
          'D57',
          'C50',
          'A52',
          'C60',
          'A54',
          'C58',
          'A55',
          'C57',
          'A56',
          'A6',
          'A29',
          'A2',
          'A51',
          'A13',
          'A53',
          'A8',
          'A27',
          'C2',
          'D32',
          'B32',
          'B47',
          'B7',
          'D3',
          'B19',
          'D33',
          'B20',
          'D25',
          'C33',
          'C6',
          'D30',
          'C36',
          'C5',
          'D9',
          'B12',
          'B39',
          'D35',
          'B36',
          'B2',
          'A40',
          'C15',
          'D31',
          'D16',
          'D53',
          'C39',
          'A45',
          'C47',
          'D57',
          'C38',
          'D24',
          'B24',
          'A47',
          'A1',
          'A30',
          'C6',
          'C26',
          'A39',
          'C16',
          'A41',
          'C17',
          'D28',
          'B16',
          'B60',
          'D43',
          'D6',
          'B8',
          'B58',
          'C57',
          'A57',
          'A6',
          'A58',
          'A5',
          'A53',
          'A3',
          'C4',
          'D10',
          'D42',
          'B51',
          'B17',
          'D29',
          'C35',
          'D21',
          'B21',
          'D16',
          'B22',
          'A37',
          'B12',
          'B60',
          'B6',
          'D4',
          'D44',
          'B55',
          'B8',
          'B43',
          'B1',
          'B41',
          'D34',
          'B33',
          'D33',
          'C39',
          'A46',
          'C53',
          'A55',
          'C52',
          'B49',
          'B3',
          'B19',
          'D23',
          'C31',
          'D53',
          'A23',
          'A4',
          'A27',
          'D59',
          'A25',
          'D56',
          'C49',
          'C1',
          'D32',
          'C20',
          'A40',
          'B4',
          'B53',
          'B16',
          'D27',
          'B17',
          'D22',
          'D37',
          'B26',
          'D31',
          'C21',
          'A18',
          'A52',
          'C10',
          'C25',
          'D55',
          'A24',
          'C5',
          'A1',
          'A31',
          'C59',
          'A53',
          'C54',
          'D59',
          'C37',
          'D50',
          'B31',
          'B51',
          'D40',
          'B27',
          'D39',
          'D14',
          'A14',
          'D58',
          'A29',
          'C9',
          'A51',
          'A9',
          'C17',
          'A42',
          'C16',
          'A35',
          'C12',
          'D21',
          'C30',
          'A41',
          'C24',
          'A39',
          'C23',
          'D60',
          'C34',
          'D19',
          'B21',
          'D34',
          'B44',
          'A60',
          'A44',
          'C40',
          'D45',
          'C37',
          'A45',
          'C19',
          'A8',
          'A54',
          'C50',
          'A55',
          'C49',
          'D54',
          'D7',
          'B7',
          'B59',
          'C57',
          'A31',
          'C56',
          'B57',
          'B16',
          'D23',
          'C10',
          'A53',
          'C51',
          'B48',
          'C53',
          'B49',
          'D50',
          'C34',
          'D3',
          'D51',
          'D4',
          'B9',
          'A35',
          'C2',
          'C45',
          'D48',
          'A16',
          'A52',
          'A11',
          'A28',
          'A4',
          'A29',
          'D56',
          'B53',
          'D55',
          'D13',
          'D28',
          'B15',
          'D26',
          'C33',
          'C15',
          'A10',
          'A25',
          'D57',
          'B55',
          'B17',
          'B52',
          'B30',
          'B45',
          'A60',
          'B26',
          'D41',
          'D12',
          'D56',
          'C39',
          'A47',
          'C60',
          'D1',
          'B4',
          'B56',
          'D43',
          'D6',
          'C35',
          'D7',
          'B13',
          'B55',
          'D44',
          'D5',
          'B16',
          'D32',
          'D60',
          'C29',
          'C3',
          'A33',
          'C56',
          'A45',
          'B20',
          'D22',
          'C30',
          'B40',
          'B2',
          'B54',
          'D55',
          'A27',
          'C11',
          'A53',
          'C58',
          'B60',
          'C33',
          'C18',
          'A39',
          'B23',
          'D19',
          'D39',
          'D20',
          'C35',
          'D47',
          'C43',
          'D13',
          'A4',
          'D48',
          'A15',
          'D14',
          'D53',
          'D15',
          'A14',
          'A50',
          'C9',
          'A52',
          'C60',
          'C6',
          'D8',
          'B9',
          'B39',
          'B4',
          'A22',
          'C5',
          'D9',
          'D57',
          'A12',
          'C18',
          'A20',
          'C4',
          'D10',
          'B8',
          'A40',
          'C14',
          'D29',
          'B14',
          'D2',
          'A1',
          'A51',
          'C53',
          'B50',
          'B32',
          'D49',
          'C44',
          'A48',
          'C41',
          'D35',
          'B40',
          'C29',
          'B54',
          'B29',
          'D50',
          'C31',
          'D57',
          'A30',
          'A4',
          'D14',
          'A13',
          'C17',
          'D29',
          'C4',
          'A29',
          'C2',
          'C51',
          'A50',
          'C8',
          'C58',
          'A56',
          'C49',
          'B44',
          'B7',
          'B56',
          'B16',
          'D26',
          'B30',
          'D49',
          'A17',
          'C22',
          'C6',
          'C59',
          'A54',
          'C54',
          'A31',
          'C1',
          'D13',
          'C35',
          'D4',
          'B11',
          'A35',
          'D60',
          'D1',
          'B19',
          'B55',
          'B1',
          'B57',
          'D40',
          'D15',
          'B22',
          'A43',
          'C18',
          'D27',
          'B18',
          'D25',
          'C32',
          'B60',
          'C57',
          'A58',
          'C56',
          'C1',
          'A26',
          'A7',
          'C20',
          'A19',
          'C3',
          'C44',
          'D14',
          'C35',
          'D42',
          'D8',
          'B11',
          'B57',
          'D58',
          'A31',
          'C52',
          'B49',
          'B34',
          'D32',
          'D59',
          'A28',
          'C5',
          'A44',
          'B21',
          'A35',
          'B12',
          'D28',
          'D13',
          'D54',
          'A6',
          'C21',
          'A26',
          'C11',
          'A54',
          'C51',
          'B47',
          'C50',
          'C6',
          'D8',
          'C35',
          'C8',
          'A48',
          'B4',
          'A21',
          'C17',
          'A43',
          'B24',
          'D23',
          'C37',
          'D31',
          'C36',
          'D41',
          'D12',
          'C3',
          'A34',
          'C16',
          'D28',
          'B14',
          'B38',
          'C30',
          'B39',
          'C24',
          'A16',
          'A57',
          'C49',
          'A31',
          'C47',
          'A55',
          'C11',
          'A35',
          'B24',
          'D36',
          'D21',
          'B18',
          'D24',
          'B13',
          'A48',
          'C39',
          'D32',
          'B17',
          'D4',
          'B4',
          'A18',
          'C58',
          'A29',
          'C57',
          'B54',
          'D50',
          'B50',
          'C52',
          'D57',
          'B58',
          'C56',
          'B59',
          'B31',
          'D49',
          'B33',
          'B57',
          'C30',
          'C10',
          'A24',
          'D52',
          'B30',
          'D51',
          'B48',
          'B2',
          'D2',
          'B5',
          'A40',
          'C3',
          'A21',
          'A6',
          'D14',
          'C43',
          'D15',
          'C27',
          'A38',
          'C2',
          'C48',
          'A44',
          'C17',
          'A22',
          'D55',
          'A9',
          'A52',
          'C60',
          'A15',
          'D16',
          'D53',
          'D17',
          'B20',
          'A48',
          'C7',
          'C56',
          'A56',
          'C54',
          'B55',
          'B28',
          'D39',
          'D24',
          'B21',
          'D10',
          'D42',
          'B60',
          'C1',
          'A33',
          'C9',
          'A25',
          'C59',
          'A47',
          'C48',
          'B44',
          'B3',
          'D1',
          'D44',
          'D5',
          'C35',
          'D54',
          'A27',
          'A7',
          'D48',
          'A17',
          'C18',
          'A8',
          'D16',
          'D43',
          'D6',
          'B5',
          'B38',
          'B54',
          'D58',
          'B60',
          'B14',
          'A37',
          'C8',
          'A51',
          'A4',
          'C5',
          'D9',
          'D56',
          'D12',
          'C24',
          'D35',
          'C23',
          'A5',
          'D14',
          'A16',
          'D60',
          'A26',
          'B12',
          'B56',
          'C58',
          'C19',
          'A36',
          'B22',
          'D23',
          'B33',
          'B48',
          'A59',
          'B50',
          'B18',
          'B54',
          'C28',
          'B40',
          'C27',
          'B51',
          'C54',
          'A45',
          'B2',
          'D5',
          'B6',
          'B37',
          'C20',
          'A20',
          'D54',
          'D13',
          'D41',
          'D17',
          'C32',
          'A41',
          'C21',
          'A52',
          'C45',
          'A55',
          'C44',
          'A31',
          'C4',
          'D11',
          'D57',
          'B60',
          'B7',
          'A38',
          'B26',
          'A35',
          'D59',
          'B56',
          'C55',
          'A29',
          'C11',
          'A56',
          'C52',
          'C5',
          'A2',
          'D3',
          'D45',
          'B58',
          'C34',
          'D20',
          'D39',
          'D24',
          'B14',
          'B55',
          'A59',
          'A5',
          'D16',
          'C43',
          'A31',
          'D57',
          'D1',
          'C33',
          'D47',
          'B31',
          'B54',
          'B5',
          'D26',
          'B6',
          'B51',
          'C53',
          'A29',
          'C50',
          'B48',
          'C25',
          'D34',
          'B17',
          'D28',
          'D43',
          'D7',
          'A7',
          'A54',
          'C10',
          'D24',
          'C32',
          'C5',
          'A45',
          'C36',
          'D22',
          'B34',
          'C22',
          'C56',
          'A53',
          'C21',
          'C57',
          'D60',
          'A34',
          'C7',
          'C48',
          'A56',
          'B38',
          'C29',
          'B39',
          'C23',
          'A16',
          'D15',
          'A13',
          'A50',
          'A10',
          'D56',
          'B56',
          'D50',
          'B30',
          'D1',
          'B6',
          'D8',
          'C6',
          'A39',
          'B10',
          'D3',
          'A1',
          'A23',
          'C17',
          'D48',
          'A3',
          'D14',
          'C42',
          'D36',
          'C20',
          'A42',
          'C40',
          'A31',
          'C9',
          'A49',
          'C7',
          'D7',
          'B14',
          'D22',
          'D37',
          'A21',
          'C18',
          'B33',
          'B53',
          'C52',
          'D60',
          'B55',
          'C29',
          'D20',
          'B19',
          'A37',
          'C4',
          'D48',
          'B30',
          'D19',
          'D53',
          'B29',
          'D52',
          'B32',
          'D47',
          'A5',
          'C5',
          'D15',
          'A21',
          'B5',
          'A18',
          'C22',
          'A38',
          'B10',
          'B58',
          'A58',
          'B51',
          'B34',
          'C18',
          'A42',
          'C4',
          'C34',
          'B59',
          'D44',
          'D9',
          'C35',
          'D16',
          'C27',
          'C8',
          'C47',
          'A47',
          'B25',
          'A43',
          'C16',
          'A23',
          'B3',
          'D3',
          'C33',
          'D18',
          'B19',
          'B60',
          'B9',
          'D10',
          'D42',
          'C34',
          'D14',
          'A10',
          'C19',
          'A20',
          'C57',
          'A25',
          'D53',
          'B28',
          'D51',
          'A21',
          'C6',
          'D31',
          'D16',
          'C46',
          'A29',
          'C45',
          'A53',
          'C53',
          'B55',
          'C59',
          'A27',
          'B11',
          'D28',
          'B13',
          'B36',
          'C31',
          'B37',
          'C30',
          'B41',
          'C29',
          'A41',
          'C3',
          'D15',
          'A19',
          'C21',
          'A50',
          'C7',
          'A37',
          'B27',
          'B56',
          'D44',
          'D5',
          'B9',
          'A46',
          'C50',
          'B43',
          'B12',
          'D25',
          'C15',
          'A34',
          'D59',
          'B59',
          'C59',
          'D1',
          'C58',
          'A27',
          'C12',
          'A55',
          'C10',
          'A28',
          'D54',
          'A23',
          'C60',
          'A16',
          'D49',
          'B50',
          'B35',
          'C18',
          'A1',
          'A51',
          'B57',
          'B3',
          'D25',
          'B17',
          'D2',
          'C35',
          'D11',
          'B7',
          'B48',
          'B15',
          'A27',
          'C56',
          'D34',
          'C27',
          'A42',
          'C38',
          'A48',
          'B55',
          'B30',
          'D33',
          'D59',
          'C53',
          'A33',
          'C43',
          'A47',
          'B53',
          'B19',
          'B57',
          'D60',
          'C50',
          'B45',
          'C49',
          'C22',
          'D37',
          'B27',
          'D54',
          'A12',
          'C25',
          'A15',
          'D15',
          'D40',
          'A2',
          'A46',
          'C20',
          'B32',
          'D50',
          'D1',
          'A60',
          'B49',
          'D51',
          'D4',
          'C4',
          'A32',
          'D56',
          'C17',
          'A45',
          'B53',
          'C58',
          'A54',
          'C50',
          'D37',
          'D22',
          'B37',
          'A58',
          'C13',
          'A59',
          'C56',
          'B52',
          'C27',
          'D17',
          'D41',
          'D13',
          'C41',
          'A53',
          'C38',
          'D30',
          'C14',
          'A30',
          'C45',
          'D16',
          'A14',
          'D48',
          'A18',
          'D14',
          'C30',
          'B45',
          'A43',
          'B26',
          'A34',
          'B13',
          'D23',
          'B18',
          'D2',
          'D59',
          'D8',
          'B1',
          'D25',
          'C39',
          'A31',
          'C5',
          'A39',
          'C2',
          'C55',
          'B58',
          'C31',
          'A40',
          'B20',
          'D12',
          'D56',
          'D21',
          'C29',
          'C7',
          'C59',
          'A52',
          'C20',
          'B33',
          'D31',
          'C14',
          'D49',
          'B34',
          'D20',
          'B17',
          'A44',
          'B9',
          'D27',
          'C35',
          'D6',
          'A6',
          'A21',
          'D38',
          'B23',
          'A36',
          'D57',
          'A13',
          'C15',
          'D28',
          'C34',
          'D2',
          'A59',
          'B14',
          'D33',
          'B31',
          'B52',
          'C49',
          'A58',
          'C53',
          'A34',
          'C52',
          'C21',
          'A38',
          'B37',
          'D49',
          'B43',
          'C23',
          'B44',
          'C47',
          'A56',
          'C44',
          'D12',
          'C42',
          'D16',
          'A23',
          'C2',
          'A32',
          'C54',
          'A27',
          'B20',
          'A37',
          'C1',
          'C41',
          'D44',
          'D9',
          'B8',
          'A49',
          'B36',
          'C46',
          'B48',
          'A42',
          'B52',
          'D57',
          'D11',
          'D42',
          'A5',
          'D15',
          'A4',
          'D5',
          'C4',
          'A18',
          'B7',
          'D27',
          'C18',
          'A26',
          'B20',
          'D6',
          'B3',
          'B54',
          'C56',
          'D48',
          'C40',
          'D13',
          'C24',
          'A50',
          'B19',
          'A29',
          'C7',
          'C49',
          'B43',
          'C48',
          'B53',
          'B11',
          'D26',
          'B33',
          'D52',
          'D2',
          'A48',
          'C55',
          'D33',
          'C26',
          'C11',
          'A57',
          'B51',
          'B12',
          'D3',
          'D45',
          'D4',
          'C34',
          'D6',
          'C4',
          'A43',
          'C35',
          'D38',
          'A22',
          'A6',
          'A26',
          'C8',
          'C33',
          'D49',
          'B45',
          'A46',
          'B58',
          'D60',
          'A31',
          'C38',
          'A54',
          'C21',
          'B31',
          'D53',
          'A13',
          'C24',
          'B40',
          'B3',
          'D22',
          'D37',
          'A20',
          'D36',
          'B18',
          'D34',
          'C42',
          'B47',
          'A43',
          'B36',
          'B7',
          'A17',
          'D14',
          'A12',
          'C8',
          'C55',
          'A30',
          'C5',
          'A46',
          'B60',
          'A60',
          'C13',
          'A14',
          'D56',
          'A35',
          'C52',
          'B54',
          'D49',
          'A9',
          'D17',
          'D41',
          'D18',
          'C47',
          'B38',
          'C31',
          'B39',
          'A55',
          'B57',
          'B26',
          'D50',
          'A6',
          'C5',
          'D3',
          'A58',
          'D24',
          'B1',
          'B47',
          'B8',
          'B35',
          'C17',
          'B31',
          'C19',
          'A40',
          'D59',
          'D9',
          'B10',
          'A36',
          'B25',
          'A35',
          'C9',
          'C46',
          'A49',
          'C41',
          'A29',
          'D48',
          'C35',
          'D12',
          'B13',
          'D21',
          'B40',
          'C31',
          'B59',
          'C55',
          'D58',
          'D8',
          'A59',
          'C53',
          'C20',
          'D37',
          'D22',
          'B5',
          'B52',
          'A56',
          'C43',
          'B31',
          'C29',
          'D14',
          'D52',
          'B44',
          'C28',
          'D18',
          'C27',
          'B53',
          'A41',
          'C14',
          'A34',
          'C50',
          'A55',
          'D6',
          'D60',
          'D7',
          'D43',
          'D28',
          'C5',
          'D49',
          'A22',
          'C22',
          'A25',
          'C56',
          'A28',
          'C51',
          'A45',
          'B6',
          'B57',
          'A48',
          'D4',
          'A2',
          'A21',
          'C57',
          'B53',
          'C55',
          'A52',
          'C8',
          'A49',
          'B39',
          'C47',
          'C23',
          'A17',
          'D15',
          'D55',
          'B29',
          'D54',
          'A29',
          'C6',
          'A3',
          'C24',
          'A51',
          'C22',
          'D30',
          'B16',
          'B43',
          'C31',
          'B60',
          'B2',
          'D23',
          'C50',
          'B42',
          'B4',
          'B58',
          'B18',
          'A44',
          'B23',
          'D18',
          'A11',
          'C26',
          'B49',
          'C45',
          'B34',
          'D55',
          'B55',
          'D49',
          'B46',
          'C49',
          'D37',
          'C11',
          'A32',
          'D58',
          'A26',
          'C17',
          'D27',
          'B29',
          'D3',
          'A46',
          'C50',
          'A56',
          'C12',
          'A59',
          'D24',
          'C39',
          'A49',
          'A11',
          'C19',
          'B32',
          'D46',
          'A13',
          'D16',
          'D43',
          'A25',
          'D18',
          'C46',
          'D20',
          'D39',
          'C60',
          'A24',
          'D17',
          'A15',
          'D47',
          'C15',
          'A39',
          'B36',
          'B51',
          'B7',
          'D5',
          'D44',
          'D14',
          'A20',
          'D42',
          'C30',
          'B30',
          'C28',
          'A41',
          'B48',
          'C44',
          'C24',
          'C9',
          'C54',
          'B56',
          'C1',
          'A42',
          'B59',
          'B3',
          'D10',
          'A55',
          'C38',
          'A32',
          'B26',
          'A31',
          'D49',
          'B57',
          'A43',
          'C6',
          'A1',
          'D36',
          'C41',
          'A30',
          'C2',
          'B55',
          'C28',
          'D14',
          'A7',
          'D54',
          'C47',
          'A28',
          'B19',
          'A60',
          'D11',
          'C25',
          'A49',
          'B54',
          'C55',
          'A53',
          'C20',
          'B38',
          'A37',
          'D57',
          'B33',
          'C19',
          'A25',
          'B20',
          'D45',
          'B60',
          'B30',
          'C18',
          'A22',
          'C3',
          'D6',
          'A5',
          'C22',
          'C51',
          'D22',
          'C12',
          'A60',
          'B53',
          'A56',
          'C10',
          'A34',
          'B14',
          'B52',
          'B33',
          'C17',
          'A27',
          'D46',
          'B34',
          'C16',
          'D30',
          'C4',
          'A44',
          'C52',
          'A53',
          'C51',
          'C6',
          'A35',
          'D58',
          'D9',
          'A56',
          'D23',
          'B4',
          'D7',
          'B11',
          'B46',
          'B9',
          'A40',
          'C30',
          'B32',
          'C29',
          'B56',
          'D43',
          'D28',
          'B10',
          'B34',
          'D54',
          'D15',
          'A3',
          'A50',
          'C25',
          'D14',
          'C7',
          'A42',
          'D1',
          'C35',
          'D17',
          'D53',
          'D19',
          'B35',
          'D50',
          'B36',
          'C45',
          'A54',
          'C20',
          'A9',
          'D8',
          'A8',
          'D49',
          'B48',
          'B1',
          'D4',
          'C5',
          'A36',
          'C52',
          'A59',
          'C49',
          'B54',
          'C33',
          'D12',
          'C41',
          'A56',
          'B56',
          'A49',
          'A7',
          'C21',
          'A51',
          'A1',
          'A26',
          'C7',
          'D18',
          'D41',
          'C55',
          'A25',
          'B21',
          'A48',
          'B59',
          'C2',
          'A40',
          'B34',
          'D57',
          'B28',
          'D56',
          'D12',
          'A59',
          'C14',
          'B2',
          'B46',
          'C52',
          'D32',
          'D17',
          'A6',
          'A49',
          'D8',
          'A60',
          'D25',
          'D40',
          'C37',
          'A33',
          'C54',
          'B57',
          'B13',
          'A26',
          'C48',
          'B45',
          'D52',
          'B47',
          'C45',
          'C23',
          'A50',
          'D48',
          'C27',
          'B44',
          'B14',
          'B35',
          'C16',
          'A12',
          'D58',
          'C14',
          'D32',
          'B36',
          'C32',
          'A39',
          'C4',
          'A46',
          'B46',
          'B4',
          'D5',
          'A47',
          'D21',
          'B22',
          'D34',
          'A18',
          'D16',
          'D43',
          'A24',
          'A9',
          'D14',
          'A19',
          'C19',
          'D26',
          'C40',
          'A56',
          'C38',
          'D24',
          'C31',
          'B45',
          'B12',
          'B58',
          'A43',
          'C29',
          'B41',
          'C45',
          'D18',
          'D41',
          'B49',
          'C25',
          'A1',
          'D51',
          'B31',
          'C42',
          'B48',
          'A51',
          'C57',
          'A23',
          'C15',
          'B1',
          'D7',
          'C34',
          'D55',
          'D12',
          'C9',
          'A24',
          'D15',
          'A7',
          'D16',
          'A16',
          'C59',
          'D2',
          'A56',
          'C37',
          'D46',
          'B35',
          'B5',
          'B56',
          'C32',
          'B31',
          'D56',
          'B33',
          'A35',
          'C47',
          'A59',
          'D9',
          'D44',
          'D14',
          'B22',
          'A40',
          'B59',
          'D59',
          'C30',
          'B58',
          'A60',
          'D21',
          'A49',
          'A5',
          'D54',
          'C43',
          'A44',
          'C38',
          'D33',
          'B15',
          'A26',
          'D53',
          'D20',
          'D39',
          'A11',
          'C9',
          'A53',
          'D10',
          'A12',
          'D48',
          'A19',
          'D36',
          'C30',
          'C5',
          'D5',
          'A56',
          'C21',
          'B32',
          'C43',
          'A35',
          'C8',
          'A28',
          'C12',
          'A54',
          'D23',
          'C49',
          'A34',
          'C17',
          'B21',
          'A23',
          'D18',
          'A6',
          'C22',
          'A45',
          'D6',
          'C2',
          'A41',
          'B54',
          'B8',
          'B23',
          'B42',
          'B17',
          'A56',
          'D20',
          'D39',
          'A3',
          'D16',
          'C41',
          'B38',
          'D50',
          'B52',
          'B1',
          'D1',
          'D45',
          'D4',
          'B28',
          'D54',
          'B16',
          'D21',
          'D36',
          'A21',
          'C19',
          'C54',
          'A28',
          'B10',
          'B59',
          'D58',
          'D10',
          'A56',
          'B26',
          'C28',
          'B38',
          'B53',
          'A17',
          'C59',
          'A52',
          'C57',
          'D34',
          'B35',
          'A50',
          'A2',
          'B56',
          'B36',
          'C19',
          'D48',
          'A49',
          'A4',
          'C24',
          'C50',
          'D55',
          'B15',
          'A21',
          'D18',
          'A15',
          'D52',
          'B34',
          'C44',
          'B30',
          'D54',
          'C27',
          'B45',
          'B3',
          'C14',
          'A42',
          'C26',
          'B50',
          'C55',
          'A55',
          'D11',
          'D57',
          'C17',
          'B32',
          'C28',
          'D19',
          'A12',
          'B4',
          'B43',
          'C47',
          'A60',
          'D2',
          'C33',
          'D27',
          'A31',
          'D17',
          'A29',
          'C44',
          'D46',
          'B22',
          'C38',
          'B23',
          'B3',
          'B52',
          'A18',
          'D50',
          'B53',
          'C50',
          'D20',
          'C34',
          'D26',
          'B4',
          'C14',
          'B32',
          'B58',
          'C3',
          'A36',
          'B39',
          'D42',
          'B41',
          'C28',
          'B27',
          'D58',
          'A38',
          'C7',
          'A51',
          'C46',
          'D13',
          'C44',
          'A60',
          'D7',
          'B2',
          'B55',
          'A46',
          'D5',
          'A7',
          'D50',
          'C59',
          'A24',
          'C15',
          'A32',
          'C57',
          'B8',
          'D39',
          'C52',
          'A37',
          'B43',
          'C46',
          'A52',
          'D48',
          'A28',
          'B26',
          'C40',
          'B48',
          'C27',
          'D40',
          'D25',
          'A57',
          'C54',
          'D58',
          'A39',
          'B24',
          'A6',
          'A27',
          'C58',
          'A47',
          'D2',
          'D57',
          'C13',
          'D20',
          'C45',
          'A59',
          'D22',
          'D37',
          'A22',
          'D15',
          'C42',
          'B49',
          'A50',
          'C10',
          'A51',
          'D49',
          'B58',
          'C54',
          'C4',
          'B40',
          'B5',
          'D1',
          'A49',
          'D50',
          'C19',
          'A46',
          'C37',
          'A48',
          'C48',
          'A27',
          'D15',
          'D54',
          'B38',
          'B12',
          'C56',
          'B55',
          'C33',
          'D7',
          'D43',
          'D16',
          'B6',
          'B39',
          'A35',
          'D30',
          'C12',
          'A36',
          'D60',
          'B58',
          'A53',
          'B44',
          'D50',
          'A2',
          'D2',
          'D46',
          'A15',
          'C24',
          'D34',
          'B45',
          'A58',
          'C48',
          'B54',
          'A57',
          'D9',
          'D43',
          'D16',
          'A22',
          'C16',
          'B21',
          'C14',
          'A35',
          'C42',
          'C25',
          'D15',
          'A26',
          'D45',
          'D4',
          'C22',
          'A55',
          'D23',
          'A58',
          'D8',
          'C26',
          'B51',
          'A7',
          'B23',
          'D46',
          'C40',
          'D9',
          'A51',
          'D22',
          'D37',
          'B3',
          'C16',
          'A27',
          'C53',
          'A30',
          'D48',
          'A42',
          'D5',
          'D44',
          'A8',
          'A48',
          'C19',
          'A16',
          'B53',
          'A50',
          'C48',
          'A34',
          'C44',
          'B1',
          'B50',
          'A39',
          'D2',
          'C58',
          'A18',
          'C20',
          'B30',
          'C43',
          'B47',
          'C41',
          'C8',
          'A36',
          'D56',
          'C32',
          'B57',
          'C4',
          'A4',
          'B24',
          'C59',
          'D35',
          'C42',
          'B32',
          'D53',
          'D21',
          'B1',
          'B56',
          'C1',
          'A43',
          'B60',
          'C60',
          'B18',
          'B51',
          'B10',
          'B39',
          'C41',
          'A35',
          'D31',
          'A32',
          'D29',
          'C17',
          'B33',
          'D51',
          'C33',
          'D13',
          'C52',
          'D14',
          'B19',
          'A49',
          'B58',
          'A5',
          'D50',
          'A57',
          'C22',
          'D9',
          'C34',
          'B53',
          'A59',
          'D3',
          'C35',
          'D18',
          'D41',
          'A17',
          'D32',
          'C28',
          'A37',
          'C3',
          'B40',
          'C49',
          'B42',
          'B5',
          'B49',
          'A40',
          'D1',
          'B9',
          'B52',
          'A13',
          'D34',
          'C12',
          'B21',
          'D59',
          'B26',
          'B41',
          'A45',
          'D20',
          'D39',
          'C22',
          'A29',
          'D26',
          'A23',
          'B22',
          'A48',
          'C36',
          'D7',
          'D60',
          'A17',
          'D17',
          'D43',
          'C34',
          'D24',
          'A53',
          'C37',
          'B23',
          'D37',
          'C58',
          'A57',
          'C10',
          'C32',
          'B34',
          'D51',
          'C19',
          'A50',
          'D50',
          'B47',
          'B2',
          'D12',
          'A58',
          'D49',
          'B49',
          'C41',
          'B24',
          'C57',
          'B11',
          'D53',
          'C15',
          'B16',
          'B1',
          'C43',
          'B40',
          'A47',
          'C42',
          'A44',
          'C23',
          'A4',
          'B19',
          'A8',
          'A24',
          'C49',
          'C26',
          'A38',
          'C52',
          'A45',
          'B58',
          'D45',
          'A14',
          'D18',
          'D41',
          'B14',
          'A28',
          'D57',
          'B27',
          'C27',
          'A53',
          'C19',
          'D24',
          'B11',
          'D52',
          'A54',
          'B56',
          'D49',
          'B50',
          'C41',
          'B25',
          'C14',
          'B5',
          'D54',
          'A10',
          'D19',
          'A40',
          'C5',
          'A47',
          'C40',
          'D16',
          'A32',
          'C45',
          'B43',
          'B15',
          'B37',
          'C48',
          'D53',
          'B29',
          'C31',
          'D13',
          'C55',
          'A26',
          'D18',
          'D41',
          'B50',
          'A35',
          'B57',
          'C1',
          'A15',
          'B47',
          'A45',
          'C21',
          'A8',
          'B20',
          'C18',
          'A25',
          'B54',
          'C34',
          'B56',
          'C33',
          'D19',
          'D34',
          'B4',
          'D55',
          'C15',
          'B33',
          'C14',
          'B16',
          'A26',
          'C2',
          'B40',
          'D57',
          'D11',
          'D42',
          'B42',
          'B2',
          'C43',
          'B3',
          'D8',
          'D60',
          'A27',
          'C46',
          'A43',
          'C44',
          'A32',
          'B50',
          'A8',
          'B22',
          'D50',
          'A11',
          'D10',
          'C26',
          'A40',
          'D58',
          'B60',
          'A41',
          'D6',
          'B6',
          'D54',
          'A56',
          'C20',
          'A15',
          'C46',
          'A28',
          'D31',
          'A60',
          'B51',
          'A43',
          'B42',
          'B9',
          'C25',
          'A2',
          'A22',
          'C56',
          'A23',
          'D38',
          'B21',
          'C39',
          'A52',
          'C23',
          'D3',
          'C7',
          'D7',
          'A45',
          'C6',
          'C57',
          'D39',
          'D20',
          'B41',
          'C10',
          'D17',
          'A16',
          'B46',
          'C51',
          'D14',
          'D44',
          'D5',
          'B27',
          'C41',
          'A36',
          'D4',
          'D45',
          'A6',
          'C4',
          'C43',
          'D18',
          'D41',
          'C35',
          'D21',
          'D36',
          'C23',
          'A40',
          'C33',
          'D28',
          'A33',
          'C53',
          'B56',
          'C28',
          'D33',
          'B35',
          'B16',
          'A51',
          'B33',
          'A46',
          'C16',
          'B4',
          'D2',
          'B29',
          'C19',
          'B9',
          'D54',
          'B10',
          'B45',
          'A37',
          'D59',
          'A30',
          'D16',
          'A20',
          'D35',
          'A15',
          'B53',
          'C47',
          'D15',
          'C44',
          'B46',
          'D53',
          'B16',
          'D7',
          'A54',
          'D48',
          'B31',
          'C31',
          'D58',
          'C14',
          'B26',
          'A23',
          'C50',
          'A57',
          'C37',
          'A35',
          'D16',
          'A2',
          'B24',
          'C15',
          'B2',
          'B44',
          'A47',
          'D4',
          'A5',
          'D28',
          'A31',
          'C55',
          'B51',
          'D49',
          'B58',
          'C29',
          'B43',
          'C16',
          'B23',
          'B38',
          'B11',
          'D50',
          'C27',
          'C60',
          'D3',
          'D52',
          'A23',
          'B54',
          'C2',
          'C59',
          'B25',
          'D55',
          'C40',
          'D32',
          'A29',
          'B9',
          'A17',
          'B45',
          'D54',
          'D13',
          'B1',
          'C24',
          'D51',
          'B29',
          'C45',
          'A60',
          'C31',
          'D16',
          'D43',
          'D9',
          'A54',
          'C51',
          'A55',
          'B53',
          'A42',
          'C25',
          'B10',
          'D58',
          'B21',
          'D56',
          'A37',
          'D3',
          'C18',
          'B7',
          'B38',
          'D47',
          'B33',
          'C57',
          'C28',
          'A52',
          'D19',
          'D34',
          'A41',
          'D59',
          'A33',
          'C51',
          'B56',
          'A40',
          'C29',
          'A24',
          'B20',
          'D60',
          'A32',
          'C55',
          'B52',
          'A58',
          'C22',
          'D5',
          'D44',
          'B58',
          'A4',
          'A19',
          'C4',
          'C20',
          'D6',
          'C8',
          'B42',
          'A38',
          'D2',
          'D46',
          'C34',
          'B59',
          'D49',
          'B22',
          'C24',
          'A21',
          'B26',
          'D55',
          'B8',
          'D27',
          'C50',
          'D21',
          'A48',
          'B23',
          'C60',
          'A26',
          'B18',
          'A9',
          'D1',
          'C32',
          'B37',
          'B13',
          'C13',
          'A29',
          'C3',
          'A24',
          'C23',
          'A54',
          'C9',
          'B41',
          'B6',
          'D11',
          'D42',
          'B16',
          'D38',
          'D23',
          'C48',
          'A59',
          'D56',
          'B3',
          'C42',
          'D29',
          'C25',
          'D33',
          'B45',
          'A6',
          'B51',
          'B4',
          'C13',
          'B30',
          'D53',
          'A4',
          'B31',
          'C30',
          'B40',
          'A8',
          'A48',
          'C26',
          'A14',
          'B48',
          'A43',
          'D48',
          'B37',
          'C46',
          'A34',
          'C43',
          'B46',
          'D52',
          'D15',
          'A25',
          'C49',
          'A46',
          'D18',
          'D41',
          'C54',
          'B52',
          'C44',
          'D60',
          'B58',
          'A56',
          'C36',
          'D8',
          'C32',
          'D2',
          'C8',
          'A15',
          'B54',
          'D48',
          'A45',
          'C51',
          'D15',
          'B11',
          'D49',
          'A13',
          'D17',
          'D43',
          'A26',
          'B53',
          'D50',
          'C41',
          'C5',
          'A48',
          'B39',
          'C46',
          'A59',
          'B44',
          'A44',
          'B47',
          'C38',
          'B24',
          'C56',
          'B33',
          'D53',
          'A29',
          'D25',
          'D40',
          'B58',
          'A34',
          'D18',
          'C42',
          'B22',
          'A5',
          'B10',
          'A16',
          'D19',
          'A6',
          'D51',
          'B49',
          'A43',
          'D20',
          'C28',
          'B47',
          'A60',
          'C11',
          'B24',
          'D58',
          'D10',
          'C22',
          'A34',
          'C39',
          'B46',
          'A50',
          'B38',
          'D53',
          'B41',
          'B12',
          'D48',
          'A47',
          'C16',
          'A38',
          'B18',
          'B34',
          'C37',
          'A44',
          'C3',
          'A2',
          'A22',
          'C6',
          'C49',
          'B47',
          'D52',
          'B48',
          'A28',
          'B50',
          'D47',
          'A50',
          'B17',
          'A10',
          'D7',
          'D43',
          'D17',
          'A39',
          'C52',
          'B24',
          'D35',
          'C48',
          'C8',
          'C27',
          'D57',
          'A60',
          'C30',
          'D22',
          'D37',
          'C41',
          'B28',
          'D55',
          'C19',
          'A54',
          'D11',
          'B3',
          'B43',
          'A18',
          'D15',
          'C58',
          'A30',
          'C9',
          'A55',
          'D48',
          'A31',
          'B42',
          'A29',
          'C1',
          'B4',
          'D56',
          'B7',
          'A52',
          'C42',
          'B23',
          'A5',
          'D5',
          'D44',
          'D6',
          'B26',
          'C59',
          'D3',
          'D46',
          'B36',
          'C17',
          'A18',
          'B44',
          'C23',
          'A28',
          'C60',
          'B19',
          'A44',
          'C22',
          'A41',
          'B58',
          'A31',
          'D34',
          'D19',
          'A50',
          'A2',
          'C7',
          'B42',
          'D38',
          'B2',
          'D59',
          'C51',
          'D54',
          'B13',
          'D9',
          'D43',
          'C28',
          'C59',
          'B57',
          'A47',
          'C20',
          'B56',
          'A1',
          'A51',
          'C60',
          'C26',
          'A53',
          'B14',
          'C25',
          'B15',
          'C16',
          'B45',
          'B5',
          'D37',
          'D22',
          'C50',
          'A58',
          'C12',
          'B25',
          'A33',
          'D30',
          'A9',
          'D48',
          'B24',
          'D56',
          'A8',
          'D1',
          'D58',
          'B32',
          'A53',
          'C39',
          'B22',
          'A22',
          'B54',
          'D50',
          'B57',
          'C52',
          'C4',
          'A23',
          'A2',
          'D52',
          'B51',
          'A5',
          'A20',
          'C25',
          'C40',
          'A36',
          'C17',
          'B37',
          'D55',
          'C33',
          'B59',
          'D34',
          'A39',
          'C31',
          'B49',
          'C14',
          'B18',
          'C8',
          'B19',
          'C58',
          'B31',
          'C18',
          'B9',
          'D60',
          'A24',
          'D19',
          'A38',
          'D57',
          'C55',
          'D25',
          'C49',
          'D9',
          'B21',
          'D54',
          'B40',
          'A46',
          'C6',
          'D18',
          'D41',
          'A15',
          'B11',
          'B44',
          'C51',
          'C5',
          'B32',
          'C58',
          'B20',
          'C43',
          'A45',
          'D22',
          'B38',
          'D32',
          'B45',
          'A29',
          'D50',
          'D2',
          'A40',
          'B35',
          'C56',
          'B49',
          'C13',
          'A57',
          'C20',
          'A24',
          'A2',
          'D36',
          'C36',
          'A53',
          'D53',
          'B10',
          'D51',
          'B51',
          'D47',
          'B54',
          'C47',
          'D17',
          'A7',
          'B20',
          'A6',
          'B46',
          'C37',
          'B24',
          'B41',
          'A39',
          'D5',
          'A57',
          'D24',
          'C18',
          'D37',
          'C36',
          'D10',
          'C8',
          'A1',
          'B17',
          'C15',
          'A48',
          'D18',
          'D41',
          'B22',
          'B43',
          'D53',
          'A12',
          'B11',
          'D20',
          'D39',
          'C45',
          'C10',
          'A58',
          'B49',
          'C1',
          'A34',
          'C53',
          'D13',
          'C57',
          'A52',
          'C54',
          'A32',
          'D27',
          'C41',
          'B3',
          'D59',
          'B9',
          'D56',
          'B35',
          'C38',
          'B42',
          'A19',
          'A43',
          'A9',
          'C27',
          'A37',
          'D45',
          'D4',
          'B8',
          'A18',
          'D33',
          'C54',
          'C29',
          'D17',
          'A34',
          'D57',
          'A24',
          'D51',
          'B21',
          'D6',
          'D44',
          'D14',
          'A60',
          'C25',
          'A53',
          'B56',
          'C31',
          'A47',
          'D8',
          'A20',
          'A4',
          'D7',
          'D40',
          'D25',
          'A30',
          'B18',
          'D15',
          'B5',
          'C13',
          'D57',
          'A58',
          'B55',
          'C2',
          'C54',
          'B42',
          'D48',
          'B13',
          'D52',
          'A5',
          'B30',
          'C59',
          'A28',
          'C56',
          'B37',
          'C14',
          'B47',
          'A12',
          'D20',
          'A57',
          'B52',
          'C53',
          'C3',
          'A51',
          'B60',
          'D46',
          'D3',
          'A48',
          'A7',
          'D31',
          'A29',
          'C10',
          'C41',
          'D54',
          'B31',
          'C44',
          'D17',
          'A41',
          'D1',
          'C57',
          'D59',
          'B20',
          'C40',
          'A45',
          'C34',
          'B60',
          'C9',
          'C24',
          'A53',
          'D50',
          'B33',
          'B13',
          'B50',
          'A27',
          'C4',
          'C33',
          'A31',
          'C46',
          'A35',
          'B41',
          'B5',
          'D55',
          'D12',
          'D42',
          'A50',
          'A2',
          'D46',
          'D3',
          'A13',
          'C21',
          'A55',
          'C19',
          'B10',
          'D26',
          'A4',
          'A49',
          'A3',
          'B46',
          'B1',
          'C23',
          'B45',
          'D53',
          'C36',
          'A59',
          'D48',
          'B56',
          'B41',
          'B26',
          'D40',
          'D25',
          'A21',
          'B33',
          'C50',
          'C5',
          'B39',
          'C32',
          'B42',
          'C53',
          'A52',
          'C52',
          'D21',
          'A55',
          'B55',
          'A14',
          'D47',
          'B34',
          'C30',
          'B59',
          'A30',
          'D49',
          'B23',
          'D59',
          'A36',
          'C39',
          'B26',
          'D36',
          'A37',
          'D30',
          'B1',
          'D57',
          'C25',
          'A38',
          'C9',
          'C47',
          'D35',
          'A53',
          'B18',
          'C13',
          'B54',
          'A40',
          'D18',
          'D41',
          'A3',
          'D49',
          'B59',
          'A15',
          'B12',
          'A11',
          'B14',
          'A45',
          'A19',
          'A47',
          'D56',
          'B48',
          'D50',
          'C58',
          'B23',
          'C24',
          'B43',
          'C15',
          'B3',
          'B28',
          'A48',
          'D51',
          'A17',
          'D16',
          'B17',
          'A9',
          'B19',
          'C3',
          'A45',
        ],
      },
    ],
  },
];
