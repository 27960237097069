import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppUrlBuilderContext, useConsumerTokenStorage } from 'shared/tools';
import { useVerifyConsumerApi } from '../api';
import { useConsumerCodeStorage } from '../context';
import { setIsVerifyLoading, setVerify, setVerifyError } from '../slices';
import { VerifyFormData } from '../types';

export const useVerifyService = (imageId?: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appUrlBuilder = useAppUrlBuilderContext();
  const verifyConsumerApi = useVerifyConsumerApi();
  const consumerTokenStorage = useConsumerTokenStorage();
  const consumerCodeStorage = useConsumerCodeStorage();
  const { loading, isVerify, error } = useAppSelector(state => state.verify);

  const initFormData: VerifyFormData = useMemo(() => ({ code: '' }), []);

  const verify = useCallback(
    async (data: VerifyFormData) => {
      dispatch(setIsVerifyLoading({ verify: true }));

      try {
        const response = await verifyConsumerApi.verify(data);

        consumerTokenStorage.set(JSON.stringify(response.sessionToken));
        consumerCodeStorage.set(data.code);

        dispatch(setVerify({ isVerify: true }));

        history.push(
          imageId
            ? appUrlBuilder.consumerCreateReadyArt(imageId.slice(4))
            : appUrlBuilder.consumerCreateArt(),
        );
      } catch (e) {
        dispatch(setVerifyError(e as Error));
        throw e;
      } finally {
        dispatch(setIsVerifyLoading({ verify: false }));
      }
    },
    [dispatch, verifyConsumerApi, consumerTokenStorage, consumerCodeStorage],
  );

  const initVerify = useCallback(async () => {
    const code = consumerCodeStorage.get();

    if (code) {
      try {
        const response = await verifyConsumerApi.verify({ code });
        consumerTokenStorage.set(JSON.stringify(response.sessionToken));
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setIsVerifyLoading({ initVerify: false }));
      }
    } else {
      dispatch(setIsVerifyLoading({ initVerify: false }));
    }
  }, [dispatch, verifyConsumerApi, consumerTokenStorage, consumerCodeStorage]);

  const logout = useCallback(() => {
    consumerTokenStorage.remove();
    consumerCodeStorage.remove();

    dispatch(setVerify({ isVerify: false }));
  }, [dispatch, consumerTokenStorage, consumerCodeStorage]);

  return {
    initFormData,
    verify,
    initVerify,
    logout,
    loading,
    isVerify,
    error,
  };
};
