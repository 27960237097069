import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Fade, Menu, MenuItem, Typography } from '@mui/material';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { LinkItem } from '../../types';


type Props = {
  title: string;
  links: LinkItem[];
  themeMode?: 'light' | 'dark';
};

export const LinkMenu: FC<Props> = props => {
  const { title, links, themeMode } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <Button
        endIcon={<KeyboardArrowDownIcon sx={{ color: themeMode === 'dark' ? 'white' : 'black' }} />}
        onClick={handleClick}
      >
        <Typography variant="button" color={themeMode === 'dark' ? 'white' : 'black'}>
          {title}
        </Typography>
      </Button>

      <Menu TransitionComponent={Fade} anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {links.map((l, i) => (
          <MenuItem onClick={handleClose} key={i}>
            {l.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
