import {
  TableBody as TableBodySource,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { PropsWithChildren, ReactElement, ReactNode } from "react";
import { TableObjectField } from "./types";

interface Props<T extends object> {
  columns: TableObjectField<T>[];
  records: T[];
}

export const TableBody = <T extends object>(
  props: PropsWithChildren<Props<T>>
): ReactElement | null => {
  const { columns, records } = props;

  return (
    <TableBodySource>
      {records.map((record, rowIndex) => (
        <TableRow hover key={rowIndex}>
          {columns.map((column, index) => {
            let cellContent: ReactNode = null;

            if (typeof column.cell === "string") {
              cellContent = record[column.cell] as unknown as ReactNode;
            }

            if (typeof column.cell === "function") {
              cellContent = column.cell(record, rowIndex);
            }

            return (
              <TableCell key={index} align={column.cellAlign}>
                {cellContent}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBodySource>
  );
};
