import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import qrcode from 'qrcode';
import { useCallback, useState } from 'react';
import { chunkArray } from './helpers';
import headerLogo from './images/header_logo.jpg';
import waves from './images/waves.png';

const styles = StyleSheet.create({
  firstPage: {
    backgroundColor: '#272727',
  },

  firstPageContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },

  firstPageWaves: {
    position: 'absolute',
    width: '100%',
  },

  firstPageImage: {
    position: 'absolute',
    top: 230,
    right: 60,
    width: '80%',
    borderRadius: '50%',
  },

  firstPageQrCode: {
    position: 'absolute',
    top: 30,
    right: 30,
    width: '80px',
  },

  page: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },

  headerLogo: {
    width: '109px',
  },

  headerSemicircle: {
    position: 'relative',
    width: '84px',
    height: '42px',
  },

  headerSemicircleLeftWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '42px',
    height: '42px',
  },

  headerSemicircleRightWrapper: {
    overflow: 'hidden',
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '42px',
    height: '42px',
  },

  headerSemicircleLeftBlack1: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '84px',
    height: '84px',
    backgroundColor: '#272727',
    borderRadius: '50%',
  },

  headerSemicircleLeftBlackText1: {
    position: 'absolute',
    top: '14px',
    left: '18px',
    color: 'white',
  },

  headerSemicircleLeftWhite1: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '84px',
    height: '84px',
    backgroundColor: '#2727270F',
    borderRadius: '50%',
  },

  headerSemicircleLeftWhiteText1: {
    position: 'absolute',
    top: '14px',
    left: '12px',
    color: 'black',
  },

  headerSemicircleLeftBlack2: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    width: '84px',
    height: '84px',
    backgroundColor: '#272727',
    borderRadius: '50%',
  },

  headerSemicircleLeftBlackText2: {
    position: 'absolute',
    top: '11px',
    left: '12px',
    color: 'white',
  },

  headerSemicircleLeftWhite2: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '84px',
    height: '84px',
    backgroundColor: '#2727270F',
    borderRadius: '50%',
  },

  headerSemicircleLeftWhiteText2: {
    position: 'absolute',
    top: '11px',
    left: '18px',
    color: 'black',
  },

  lightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '4px 8px',
    borderRadius: '12px',
  },

  darkRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '4px 8px',
    borderRadius: '12px',
    backgroundColor: '#2727270F',
  },

  section: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '4px',
    padding: '2px',
  },

  sectionCircle: {
    position: 'relative',
    width: '32px',
    height: '32px',
    border: '1px solid #D7D7D7',
    borderRadius: '50%',
  },

  sectionSectorA: {
    top: '2px',
    left: '2px',
    position: 'absolute',
    width: '16px',
    height: '16px',
    overflow: 'hidden',
  },

  sectionSectorB: {
    top: '2px',
    left: '17px',
    position: 'absolute',
    width: '16px',
    height: '16px',
    overflow: 'hidden',
    transform: 'rotate(90deg)',
  },

  sectionSectorC: {
    top: '18px',
    left: '2px',
    position: 'absolute',
    width: '16px',
    height: '16px',
    overflow: 'hidden',
    transform: 'rotate(270deg)',
  },

  sectionSectorD: {
    top: '18px',
    left: '17px',
    position: 'absolute',
    width: '16px',
    height: '16px',
    overflow: 'hidden',
    transform: 'rotate(180deg)',
  },

  sectionSector: {
    position: 'absolute',
    width: '32px',
    height: '32px',
    backgroundColor: '#D7D7D7',
    borderRadius: '50%',
  },

  sectionNumber: {
    position: 'absolute',
    top: '11px',
    fontSize: '13px',
    fontWeight: 500,
  },

  sectionCounter: {
    marginTop: '4px',
    fontSize: '10px',
    fontWeight: 400,
    color: '#8b8989',
  },

  footer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 30,
    left: 0,
    right: 0,
  },

  footerPageNumberWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    width: '24px',
    border: '1px solid black',
    borderRadius: '12px',
    textAlign: 'center',
    paddingTop: '5px',
  },

  footerPageNumber: {
    fontSize: 12,
    fontWeight: 400,
  },
});

export const usePDFGeneratorService = () => {
  const [state, setState] = useState<{
    isLoading: boolean;
    blob?: Blob;
    url?: string;
    error?: Error;
  }>();

  const getDocument = useCallback(
    async (data: { steps: string[]; link: string; image: string }) => {
      const stepChunks = chunkArray(data.steps, 12);

      const qrCode = await qrcode.toDataURL(data.link, {
        type: 'image/png',
        margin: 0,
        color: {
          dark: '#FFFFFF',
          light: '#272727',
        },
      });

      return (
        <Document title="Your instruction" subject="Your instruction">
          <Page size="A4" style={styles.firstPage}>
            <View style={styles.firstPageContainer}>
              <Image src={waves} style={styles.firstPageWaves} />
              <Image src={data.image} style={styles.firstPageImage} />
              <Image src={qrCode} style={styles.firstPageQrCode} />
            </View>
          </Page>

          <Page size="A4" style={styles.page}>
            <View style={styles.header} fixed>
              <div style={styles.headerSemicircle}>
                <div style={styles.headerSemicircleLeftWrapper}>
                  <div style={styles.headerSemicircleLeftBlack1} />
                  <Text style={styles.headerSemicircleLeftBlackText1}>A</Text>
                </div>

                <div style={styles.headerSemicircleRightWrapper}>
                  <div style={styles.headerSemicircleLeftWhite1} />
                  <Text style={styles.headerSemicircleLeftWhiteText1}>B</Text>
                </div>
              </div>

              <Image src={headerLogo} style={styles.headerLogo} />

              <div style={styles.headerSemicircle}>
                <div style={styles.headerSemicircleLeftWrapper}>
                  <div style={styles.headerSemicircleLeftWhite2} />
                  <Text style={styles.headerSemicircleLeftWhiteText2}>C</Text>
                </div>

                <div style={styles.headerSemicircleRightWrapper}>
                  <div style={styles.headerSemicircleLeftBlack2} />
                  <Text style={styles.headerSemicircleLeftBlackText2}>D</Text>
                </div>
              </div>
            </View>

            {stepChunks.map((row, ri) => (
              <View wrap={false} style={ri % 2 === 0 ? styles.lightRow : styles.darkRow} key={ri}>
                {row.map((section, si) => {
                  const currentSection = section.slice(0, 1);

                  return (
                    <div style={styles.section} key={si}>
                      <div style={styles.sectionCircle} />

                      <div style={styles[`sectionSector${currentSection}`]}>
                        <div style={styles.sectionSector} />
                      </div>

                      <Text style={styles.sectionNumber}>{section}</Text>
                      <Text style={styles.sectionCounter}>{ri * 12 + si + 1}</Text>
                    </div>
                  );
                })}
              </View>
            ))}

            <View style={styles.footer} fixed>
              <Text
                style={styles.footerPageNumberWrapper}
                render={({ pageNumber }) => (
                  <Text style={styles.footerPageNumber}>{pageNumber - 1}</Text>
                )}
              />
            </View>
          </Page>
        </Document>
      );
    },
    [],
  );

  const generatePdf = useCallback(
    async (data: { steps: string[]; link: string; image: string }) => {
      try {
        setState({ isLoading: true });

        const document = await getDocument(data);
        const instruction = await pdf(document).toBlob();
        const url = URL.createObjectURL(instruction);
        setState(s => ({ ...s, instruction, url }));

        return { instruction, url };
      } catch (error) {
        setState(s => ({ ...s, error }));
        console.log(error);
      } finally {
        setState(s => ({ ...s, isLoading: false }));
      }
    },
    [],
  );

  return { ...state, getDocument, generatePdf };
};
