import { Box, Typography } from '@mui/material';
import { PortraitDataType } from 'modules/art/types';
import { FC } from 'react';
import { CustomIcon } from 'shared/assets/icons';
import { ButtonWithIcon } from 'shared/components';
import { useLangContext } from 'shared/features/Language';

type Props = {
  item: PortraitDataType;
  chooseArt: (id: string) => Promise<void>;
};

export const ReadyDesignCard: FC<Props> = props => {
  const { item, chooseArt } = props;
  const { textData } = useLangContext();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        component="img"
        src={item.image}
        alt={item.id}
        sx={{ width: '100%', objectFit: 'cover', margin: '30px 0' }}
      />

      <Typography
        variant="overline"
        sx={{
          fontSize: '18px',
          lineHeight: '27px',
          margin: '30px 0 30px 0',
          fontWeight: 300,
          marginTop: '30px',
        }}
      >
        {item.title}
      </Typography>

      <Box sx={{ margin: '30px 0' }}>
        <ButtonWithIcon
          onClick={() => chooseArt(item.id)}
          type="button"
          icon={<CustomIcon name="arrowTopBlack" />}
          position="end"
          color="light"
        >
          <Typography
            variant="overline"
            sx={{
              fontSize: '30px',
              lineHeight: '30px',
            }}
          >
            {textData.chooseButton}
          </Typography>
        </ButtonWithIcon>
      </Box>
    </Box>
  );
};
