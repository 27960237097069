export const getSettingsJsonUrl = () => {
  const timestamp = new Date().getTime();
  return `https://letstrings.fra1.digitaloceanspaces.com/settings/tools.json?${timestamp}`;
};

export const getVoiceUrl = (fileName: string, language: string) => {
  return `https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/${language}/${fileName}.mp3`;
};

export const getBlankVoiceUrl = () => {
    return `https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/silence.mp3`;
};

export const getMelodyUrl = (fileName: string) => {
  return `https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/melodies/${fileName}.mp3`;
};

export const getIntroUrl = (language: string) => {
  return `https://letstrings.fra1.cdn.digitaloceanspaces.com/assets/app/intro/${language}.mp3`;
};
