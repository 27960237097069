import { Box, Grid, Typography } from '@mui/material';
import { VerifyFormData } from 'modules/auth/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { CustomIcon } from 'shared/assets/icons';
import { FormComponentProps, FormTextBox } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { COUPON_PATTERN, useAppUrlBuilderContext } from 'shared/tools';

export const VerifyForm: FC<FormComponentProps<VerifyFormData, { isLoading: boolean }>> = ({
  form,
  isLoading,
}) => {
  const appUrlBuilder = useAppUrlBuilderContext();
  const { textData } = useLangContext();

  const couponAutoFormat = (coupon: string): string => {
    const code = coupon.trim().replace(/^\S{4}-\S{5}$/, '');
    if (code.length < 5) return code.toLocaleUpperCase();
    return code.replace(/(\w{4})(\w{1})/, '$1-$2').toLocaleUpperCase();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextBox
            actionOnChange={e => (e.target.value = couponAutoFormat(e.target.value))}
            form={form}
            placeholder="XXXX-XXXXX"
            fieldName="code"
            validation={{
              required: textData.requiredCode,

              pattern: {
                value: COUPON_PATTERN,
                message: textData.invalidCode,
              },
            }}
            isDisabled={isLoading}
          />
        </Grid>

        <Grid item xs={6}>
          <Link to={appUrlBuilder.home()}>
            <ButtonWithIcon
              type="button"
              disabled={isLoading}
              icon={<CustomIcon name="arrowBottomLeftBlack" />}
              position="start"
              color="light"
            >
              <Typography
                variant="overline"
                sx={{
                  fontSize: { md: '24px', xs: '22px' },
                }}
              >
                {textData.buttonBack}
              </Typography>
            </ButtonWithIcon>
          </Link>
        </Grid>

        <Grid item xs={6}>
          <ButtonWithIcon
            type="submit"
            loading={isLoading}
            icon={<CustomIcon name="arrowBottomRightBlack" />}
            position="end"
            color="light"
          >
            <Typography
              variant="overline"
              sx={{
                fontSize: { md: '24px', xs: '22px' },
              }}
            >
              {textData.buttonFurther}
            </Typography>
          </ButtonWithIcon>
        </Grid>
      </Grid>
    </Box>
  );
};
