import { Box, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import {
  arrowBottomLeftBlack,
  arrowBottomRightBlack,
  arrowTopBlack,
  arrowTopWhite,
  drawBlack,
  minusBlack,
  music,
  musicBlack,
  play,
  playBlack,
  plusBlack,
  rotateBlack,
  volume,
  volumeBlack,
  stop,
  stopBlack,
  store,
  storeBlack,
  upload,
  uploadBlack,
  noVolume,
  noVolumeWhite,
} from './icons';

export type IconName =
  | 'arrowTopWhite'
  | 'upload'
  | 'arrowTopBlack'
  | 'arrowBottomLeftBlack'
  | 'arrowBottomRightBlack'
  | 'uploadBlack'
  | 'store'
  | 'storeBlack'
  | 'plusBlack'
  | 'minusBlack'
  | 'drawBlack'
  | 'rotateBlack'
  | 'music'
  | 'play'
  | 'volume'
  | 'stop'
  | 'musicBlack'
  | 'playBlack'
  | 'volumeBlack'
  | 'stopBlack'
  | 'noVolume'
  | 'noVolumeWhite';

const icons: Record<IconName, ReactNode> = {
  arrowTopWhite,
  upload,
  arrowTopBlack,
  arrowBottomLeftBlack,
  arrowBottomRightBlack,
  uploadBlack,
  store,
  storeBlack,
  drawBlack,
  minusBlack,
  plusBlack,
  rotateBlack,
  music,
  play,
  volume,
  stop,
  musicBlack,
  playBlack,
  volumeBlack,
  stopBlack,
  noVolume,
  noVolumeWhite,
};

type CustomIconProps = {
  name: IconName;
  styles?: SxProps<Theme>;
};

export const CustomIcon: FC<CustomIconProps> = ({ name, styles }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        ...styles,
      }}
    >
      {icons[name]}
    </Box>
  );
};
