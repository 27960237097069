import { Route } from '../../../shared/router';
import { HomePage } from '../pages';

export const routes: Route[] = [
  {
    name: 'home',
    title: 'Home',
    path: '/',
    component: HomePage,
    layoutType: 'publicMain',
    isPublic: true,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
];
