import { AppBar, Grid, Hidden, Toolbar } from '@mui/material';
import React, { FC } from 'react';
import { AuthButton } from './AdminAuthButton';
import { NavigationLogo } from './AdminNavigationLogo';
import { usePrivateNavigation } from './AdminPrivateNavigation';
import { useLayout } from 'shared/layout/contexts';

export const PrivateHeader: FC = () => {
  const { isAuth, layoutLinks } = useLayout();
  const { drawer: NavigationDrawer, menu: NavigationMenu } = usePrivateNavigation(layoutLinks);

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          spacing={1}
        >
          <Hidden mdUp>{NavigationDrawer}</Hidden>

          <NavigationLogo textColor="black" />

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
            spacing={1}
          >
            <Hidden mdDown>{NavigationMenu}</Hidden>

            <AuthButton isAuth={isAuth} />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
