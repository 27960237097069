import { Grid, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link as LinkSource } from 'react-router-dom';

type Props = {
  textColor: 'white' | 'black';
};

export const NavigationLogo: FC<Props> = props => {
  const { textColor } = props;

  return (
    <Grid item>
      <Link to="/">
        <Typography
          variant="body1"
          component="h1"
          color={textColor}
          sx={{ whiteSpace: 'nowrap', letterSpacing: '2px', lineHeight: '2px', fontSize: '25px' }}
        >
          Let's String
        </Typography>
      </Link>
    </Grid>
  );
};

const Link = styled(LinkSource)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
}));
