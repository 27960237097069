import { FC, PropsWithChildren, ReactNode } from 'react';
import { MainHeader } from './PublicMainHeader';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useLangContext } from 'shared/features/Language';

type Props = {
  title: ReactNode;
};

export const PublicPageLayout: FC<PropsWithChildren<Props>> = ({ children, title }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { textData } = useLangContext();

  return (
    <Grid
      container
      direction={{ md: 'row', xs: 'column' }}
      sx={{ minHeight: '100%', backgroundColor: theme => theme.palette.secondary.main }}
    >
      <Grid item sx={{ width: '100%', flex: { md: '1 1', xs: 'inherit' } }}>
        <MainHeader title={title} />
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          flex: { xs: '1 1' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Grid>

      {matches && (
        <Grid
          item
          sx={{
            height: '80px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme => theme.palette.primary.main,
          }}
        >
          <Typography
            variant="overline"
            sx={{
              fontSize: { md: '18px', xs: '16px' },
              lineHeight: '27px',
              textAlign: 'center',
              color: theme => theme.palette.secondary.main,
            }}
          >
            {textData.contactUs}
            <Box sx={{ marginLeft: '10px' }}>
              <a
                href="mailto:support@letsstring.store"
                style={{ textDecoration: 'none', color: 'rgba(255, 255, 255, 1)' }}
              >
                {textData.contactEmail}
              </a>
            </Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
