import React, { FC } from 'react';
import { NotificationContext } from '../context';
import { useNotificationsControllerService } from '../services';
import { Notifications } from './Notifications';

export const NotificationController: FC = ({children}) => {
  const state = useNotificationsControllerService();

  return (
    <NotificationContext.Provider value={state}>
      {children}

      <Notifications />
    </NotificationContext.Provider>
  );
};
