import { FC, PropsWithChildren, useMemo } from 'react';
import { LayoutContext } from './LayoutContext';

type Props = {
  isAuth: boolean | null;
  isVerify: boolean | null;
};

export const LayoutContextProvider: FC<PropsWithChildren<Props>> = ({
  isAuth,
  isVerify,
  children,
}) => {
  const ctx = useMemo(
    () => ({
      layoutLinks: {
        adminPublic: [],
        adminPrivate: [],
        adminAuth: [],
        publicMain: [],
        publicWhite: [],
      },
      isAuth,
      isVerify,
    }),
    [isAuth],
  );

  return <LayoutContext.Provider value={ctx}>{children}</LayoutContext.Provider>;
};
