import { FC } from 'react';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { useAssemblyViewController } from 'modules/art/controllers';
import { theme } from 'themes';
import { BuilderArtMelodyDrawer, BuilderArtStepDrawer } from './BuilderArtDrawer';
import { BuilderArtHeader } from './BuilderArtHeader';
import WrapperBuilderSlider from './BuilderSlider';
import { InfoBlock } from './InfoBlock';
import { LineWithLetterView } from './LineWithLetterView';
import { SlidesSpeedsView } from './SlidesSpeedsView';
import { SoundsButtonsView } from './SoundsButtonsView';

type Props = {
  sessionId: string;
};

export const BuilderArtView: FC<Props> = ({ sessionId }) => {
  const {
    assemblyData,
    currentStep,
    isStepDrawerOpen,
    isMelodyDrawerOpen,
    autoPlay,
    slider,
    soundPlayerController,
    langContext,
    setIsStepDrawerOpen,
    setIsMelodyDrawerOpen,
    changeAutoPlay,
    changeStep,
  } = useAssemblyViewController(sessionId);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!assemblyData) {
    return null;
  }

  return (
    <>
      <BuilderArtStepDrawer
        isOpen={isStepDrawerOpen}
        close={() => setIsStepDrawerOpen(false)}
        changeStep={(s, f) => {
          changeAutoPlay(false);
          return changeStep(s, f);
        }}
        currentStep={currentStep.number}
      />

      <BuilderArtMelodyDrawer
        isOpen={isMelodyDrawerOpen}
        close={() => setIsMelodyDrawerOpen(false)}
        soundPlayerController={soundPlayerController}
      />

      <Box sx={{ height: '100%', backgroundColor: theme => theme.palette.primary.main }}>
        <Container
          maxWidth="xl"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: { sm: '30px 0', xs: '10px 0 0 0' },
          }}
        >
          <BuilderArtHeader
            lang={langContext.lang}
            changeLang={langContext.changeLang}
            sx={{ padding: { sm: '0 30px', xs: '0 10px' } }}
          />

          <Grid
            container
            sx={{
              display: 'flex',
              flex: '1 1',
              flexDirection: { sm: 'column', xs: 'row' },
              flexWrap: 'nowrap',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <SlidesSpeedsView soundPlayerController={soundPlayerController} />

            <Grid
              container
              item
              sx={{
                height: '100%',
                flexDirection: { sm: 'column', xs: 'row' },
                flexWrap: 'nowrap',
                justifyContent: 'center',
                padding: { sm: '70px', xs: '10px' },
              }}
            >
              <LineWithLetterView
                letters={matches ? ['A', 'C'] : ['A', 'B']}
                orientation={matches ? 'vertical' : 'horizontal'}
              />

              <WrapperBuilderSlider
                slider={slider}
                assemblyData={assemblyData}
                currentStepNumber={currentStep.number}
                changeStep={changeStep}
              />

              <LineWithLetterView
                letters={matches ? ['B', 'D'] : ['C', 'D']}
                orientation={matches ? 'vertical' : 'horizontal'}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item sx={{ padding: { sm: '0 16px', xs: '20px 16px' } }}>
              <InfoBlock color="white">
                {currentStep.number}/{assemblyData.stepsData.steps.length}
              </InfoBlock>
            </Grid>

            <Grid
              item
              sx={{
                width: { sm: 'initial', xs: '100%' },
                padding: { sm: 'inherit', xs: '18px' },
                borderRadius: { sm: 'inherit', xs: '20px 20px 0 0' },
                backgroundColor: { sm: 'inherit', xs: 'secondary.main' },
                order: { sm: 'inherit', xs: '1' },
              }}
            >
              <SoundsButtonsView
                autoPlay={autoPlay}
                mode={matches ? 'dark' : 'white'}
                openDrawer={() => setIsMelodyDrawerOpen(true)}
                changeAutoPlay={changeAutoPlay}
              />
            </Grid>

            <Grid item sx={{ padding: { sm: '0 16px', xs: '20px 16px' } }}>
              <InfoBlock color="black" hover onClick={() => setIsStepDrawerOpen(true)}>
                {langContext.textData.lostButton}
              </InfoBlock>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
