import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppUrlBuilderContext } from 'shared/tools';
import { useVerifyService } from '../../services';
import { VerifyFormData } from '../../types';

export const useVerifyCouponController = (imageId?: string) => {
  const history = useHistory();
  const appUrlBuilder = useAppUrlBuilderContext();
  const { initFormData, verify, logout, loading, isVerify, error } = useVerifyService(imageId);

  const enter = useCallback(
    async (data: VerifyFormData) => {
      try {
        await verify(data);
      } catch (e) {
        // notification.error(e);
      }
    },
    [appUrlBuilder, history, verify],
  );

  const exit = useCallback(() => {
    logout();
    history.push(appUrlBuilder.home());
  }, [appUrlBuilder, history, logout]);

  return {
    initFormData,
    enter,
    exit,
    loading,
    isVerify,
    error,
  };
};
