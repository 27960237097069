import { AuthorizeController } from 'modules/auth';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import {
  Route as RouteSource,
  Router as RouterSource,
  Switch as SwitchSource,
} from 'react-router-dom';
import { Loading, LoadingContainer } from '../../components';
import { NotificationController } from '../../features';
import { getLayoutComponent, LayoutContextProvider, Page404 } from '../../layout';
import { useAppSelector } from '../../tools';
import { browserHistory } from '../history';
import { Route } from '../types';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicRoute } from './PublicRoute';

type Props = {
  routes: Route[];
  show404?: boolean;
};

export const Router: FC<Props> = ({ routes, show404 }) => {
  const { isAuth } = useAppSelector(state => state.auth);
  const { isVerify } = useAppSelector(state => state.verify);

  return (
    <RouterSource history={browserHistory}>
      <>
        <AuthorizeController />

        {typeof isAuth !== 'boolean' || typeof isVerify !== 'boolean' ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <NotificationController>
            <LayoutContextProvider isAuth={isAuth} isVerify={isVerify}>
              <SwitchSource>
                {routes.map(route => {
                  const {
                    path,
                    component: Component,
                    layoutType,
                    title,
                    availableFor,
                    isPublic,
                    onlyNonAuth,
                    name,
                  } = route;
                  const Layout = layoutType ? getLayoutComponent(layoutType) : undefined;
                  const RouteWrapper = isPublic ? PublicRoute : ProtectedRoute;

                  return (
                    <RouteWrapper
                      key={name}
                      path={path}
                      availableFor={availableFor}
                      isAuth={isAuth}
                      isVerify={isVerify}
                      onlyNonAuth={onlyNonAuth}
                      exact
                    >
                      <>
                        <Helmet title={title} />

                        {!!Layout ? (
                          <Layout>
                            <Component />
                          </Layout>
                        ) : (
                          <Component />
                        )}
                      </>
                    </RouteWrapper>
                  );
                })}

                {show404 && <RouteSource component={Page404} />}
              </SwitchSource>
            </LayoutContextProvider>
          </NotificationController>
        )}
      </>
    </RouterSource>
  );
};
