import React, { FC, useEffect } from 'react';
import { HomeView } from '../components';
import { useEffectOnce } from 'react-use';
import { useHistory, useLocation } from 'react-router';
import { LangState, useLangContext } from 'shared/features/Language';
import { parseRawQueryString } from 'shared/tools';

export const HomePage: FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const langContext = useLangContext();
  const { query } = parseRawQueryString(search);

  useEffectOnce(() => {
    if (query.lang) {
      langContext.changeLang(query.lang as LangState);
    }
  });

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?lang=${langContext.lang}`,
    });
  }, [langContext.lang]);

  return <HomeView />;
};
