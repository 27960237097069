import { useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { useCouponsService } from '../services';
import { CouponCount, CouponStatus } from '../types';

export const useCouponsController = () => {
  const { loading, initFormData, couponList, getCoupons, generateCoupons, changeFilter, filter } =
    useCouponsService();

  const load = useCallback(async () => {
    try {
      await getCoupons();
    } catch (e) {}
  }, [getCoupons]);

  const generate = useCallback(
    async (formData: CouponCount) => {
      try {
        if (formData.count) {
          await generateCoupons(formData.count);
          await getCoupons();
        }
      } catch (e) {}
    },
    [getCoupons],
  );

  const search = useCallback(
    async (search: string) => {
      changeFilter({ search });
      await load();
    },
    [changeFilter, load],
  );

  const changePage = useCallback(
    async (pageNumber: number) => {
      changeFilter({ pageNumber });
      await load();
    },
    [changeFilter, load],
  );

  const changePageSize = useCallback(
    async (pageSize: number) => {
      changeFilter({ pageSize });
      await load();
    },
    [changeFilter, load],
  );

  const changeStatus = useCallback(
    async (status: CouponStatus[]) => {
      changeFilter({ status });
      await load();
    },
    [changeFilter, load],
  );

  const changeSort = useCallback(
    async (orderedby: string) => {
      changeFilter({ orderedby });
      await load();
    },
    [changeFilter, load],
  );

  useEffectOnce(() => {
    load();
  });

  return {
    loading,
    initFormData,
    couponList,
    filter,
    generate,
    search,
    changeSort,
    changePage,
    changePageSize,
    changeStatus,
  };
};
