import { Configs } from './types';

export const appConfigs: Configs = {
  DEFAULT: {
    apiBaseUrl: 'https://www.letsstring.art/v1/',
    appBaseUrl: ' https://www.letsstring.art/',
  },
  DEV: {
    apiBaseUrl: 'https://www.letsstring.art/v1/',
    appBaseUrl: ' https://www.letsstring.art/',
  },
  PROD: {
    apiBaseUrl: 'https://www.letsstring.art/v1/',
    appBaseUrl: ' https://www.letsstring.art/',
  },
};
