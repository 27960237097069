import { useCallback } from 'react';
import { useAdminTokenStorage, useAppDispatch, useConsumerTokenStorage } from 'shared/tools';
import { useConsumerCodeStorage } from '../context';
import { setAuth, setVerify } from '../slices';

export function useGeneralAuthService() {
  const dispatch = useAppDispatch();
  const adminTokenStorage = useAdminTokenStorage();
  const consumerTokenStorage = useConsumerTokenStorage();
  const consumerCodeStorage = useConsumerCodeStorage();

  const authorize = useCallback(async () => {
    try {
      const tokenData = adminTokenStorage.get();
      const consumerTokenData = consumerTokenStorage.get();
      const consumerCode = consumerCodeStorage.get();

      if (tokenData) {
        dispatch(setAuth({ isAuth: true }));
      } else {
        dispatch(setAuth({ isAuth: false }));
      }

      if (consumerTokenData && consumerCode) {
        dispatch(setVerify({ isVerify: true }));
      } else {
        dispatch(setVerify({ isVerify: false }));
      }
    } catch (e) {
      throw e;
    }
  }, [dispatch, adminTokenStorage, consumerTokenStorage]);

  return {
    authorize,
  };
}
