import { Query } from '../../types/Query';
import { BaseUrlBuilder } from './BaseUrlBuilder';

export class AppUrlBuilder extends BaseUrlBuilder {
  home(query?: { lang: 'en' | 'it' | 'es' | 'pl' }) {
    return this.buildUrl('/', { query });
  }

  adminLogin() {
    return this.buildUrl('/admin/login');
  }

  adminCoupons() {
    return this.buildUrl('/admin/coupons');
  }

  consumerVerify(query?: { id: string }) {
    return this.buildUrl('/verify', { query });
  }

  consumerCreateArt() {
    return this.buildUrl(`/create-art`);
  }

  consumerCreateReadyArt(imageId: string) {
    return this.buildUrl(`/create-ready-art/${imageId}`);
  }

  consumerDesigns() {
    return this.buildUrl('/designs');
  }

  consumerAssembly(sessionId: string, query?: { lang: 'en' | 'it' | 'es' | 'pl' }) {
    return this.buildUrl(`/assembly/${sessionId}`, { query });
  }
}
