import { Box, Grid, Typography } from '@mui/material';
import { getSettingsJsonUrl } from 'app/constants';
import axios from 'axios';
import { applyFiltersToImage } from 'modules/art/helpers';
import { UploadPhotoFormData } from 'modules/art/types';
import { ReactElement, useEffect, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useForm } from 'react-hook-form';
import { CustomIcon } from 'shared/assets/icons';
import { Loading, LoadingContainer } from 'shared/components';
import { ButtonWithIcon } from 'shared/components/Buttons/ButtonWithIcon';
import { useLangContext } from 'shared/features/Language';
import { PublicPageLayout } from 'shared/layout';
import { getCroppedImg } from './canvasUtils';
import { CropPhotoButtons } from './CropPhotoButtons';

type Props = {
  image: {
    file: File | null;
    url: string | undefined;
  };
  goBack?(): void;
  uploadImage: (formData: UploadPhotoFormData) => void;
  saveCrop: (crop: string[]) => void;
};

export const CropPhotoView = (props: Props): ReactElement | null => {
  const { image, saveCrop, goBack, uploadImage } = props;
  const { textData } = useLangContext();

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [tools, setTools] = useState({
    first: 'contrast(50%)',
    second: 'contrast(70%)',
    third: 'grayscale(1) brightness(1.3) contrast(70%)',
    fourth: 'saturate(50%) contrast(70%) brightness(0.9)',
  });

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>(null);

  const form = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: { newImage: null },
  });

  const formData = form.watch();

  const onSave = async () => {
    try {
      const croppedImage = await getCroppedImg(image.url, croppedAreaPixels, rotate);

      applyFiltersToImage(
        croppedImage,
        [tools['first'], tools['second'], tools['third'], tools['fourth']],
        data => saveCrop(data),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    if (formData.newImage) {
      form.handleSubmit(uploadImage)();
    }
  }, [formData.newImage]);

  useEffect(() => {
    // axios.get(getSettingsJsonUrl()).then(response => setTools(response.data));
  }, []);

  if (!image) {
    return (
      <LoadingContainer flex="1 1">
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <>
      <PublicPageLayout
        title={
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: { md: '50px', xs: '30px' },
              lineHeight: { md: '60px', xs: '36px' },
              maxWidth: '394px',
              paddingRight: { xs: '20px' },
              paddingTop: { md: '40px', xs: 0 },
            }}
          >
            {textData.cropPageTitle}
          </Typography>
        }
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '18px',
            marginTop: { sm: 0, xs: '30px' },
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{ flexDirection: 'column', maxWidth: '490px' }}
          >
            <Grid
              item
              container
              sx={{ maxWidth: '320px', width: '100%', justifyContent: 'space-between' }}
            >
              <CropPhotoButtons
                fieldName="newImage"
                form={form}
                rotate={rotate}
                scale={scale}
                setRotate={setRotate}
                setScale={setScale}
              />
            </Grid>

            <Grid item>
              <Box
                sx={{
                  width: { md: '372px', xs: '320px' },
                  height: { md: '372px', xs: '318px' },
                  position: 'relative',
                  background: 'transparent',
                }}
              >
                <Cropper
                  image={image.url}
                  crop={crop}
                  rotation={rotate}
                  zoom={scale}
                  aspect={1}
                  cropShape="round"
                  onCropChange={setCrop}
                  onRotationChange={setRotate}
                  onCropComplete={onCropComplete}
                  onZoomChange={setScale}
                />
              </Box>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <ButtonWithIcon
                  type="button"
                  icon={<CustomIcon name="arrowBottomLeftBlack" />}
                  position="start"
                  color="light"
                  onClick={goBack}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: { md: '19px', sm: '22px', xs: '22px' },
                      lineHeight: { md: '20px', xs: '18px' },
                    }}
                  >
                    {textData.buttonBack}
                  </Typography>
                </ButtonWithIcon>
              </Grid>

              <Grid item xs={6}>
                <ButtonWithIcon
                  icon={<CustomIcon name="arrowBottomRightBlack" />}
                  color="light"
                  position="end"
                  onClick={onSave}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: { md: '19px', sm: '22px', xs: '22px' },
                      lineHeight: { md: '20px', xs: '18px' },
                    }}
                  >
                    {textData.buttonFurther}
                  </Typography>
                </ButtonWithIcon>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PublicPageLayout>
    </>
  );
};
