import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router';
import { BuilderArtView } from '../components';
import { parseRawQueryString } from 'shared/tools';
import { LangState, useLangContext } from 'shared/features/Language';
import { useEffectOnce } from 'react-use';

export const AssemblyPage: FC = () => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const { search } = useLocation();
  const langContext = useLangContext();
  const { query } = parseRawQueryString(search);

  useEffectOnce(() => {
    if (query.lang) {
      langContext.changeLang(query.lang as LangState);
    }
  });

  return <BuilderArtView sessionId={sessionId} />;
};
