import { useArtGeneratorViewController } from 'modules/art/controllers';
import { ConfirmEmailView } from './ConfirmEmailView';
import { CropPhotoView } from './CropPhotoView';
import { EnteringEmailView } from './EnterEmailView/EnterEmailView';
import { FinishSelectView } from './FinishSelectView';
import { SelectVariantView } from './SelectVariantView';
import { UploadPhotoView } from './UploadPhotoView';

export const CreateArtView = () => {
  const {
    step,
    image,
    emailData,
    isStartLoading,
    setStep,
    uploadImage,
    saveCrop,
    enterEmail,
    selectVariant,
    start,
    toBuilder,
    download,
  } = useArtGeneratorViewController('upload');

  return (
    <>
      {step === 'upload' && (
        <UploadPhotoView uploadImage={data => uploadImage(data, () => setStep('crop'))} />
      )}

      {step === 'crop' && (
        <CropPhotoView
          image={image}
          goBack={() => setStep('upload')}
          uploadImage={uploadImage}
          saveCrop={saveCrop}
        />
      )}

      {step === 'choice-variant' && (
        <SelectVariantView image={image} goBack={() => setStep('crop')} select={selectVariant} />
      )}

      {step === 'enter-email' && (
        <EnteringEmailView goBack={() => setStep('choice-variant')} enterEmail={enterEmail} />
      )}

      {step === 'confirm-email' && (
        <ConfirmEmailView
          isStartLoading={isStartLoading}
          emailData={emailData}
          goBack={() => setStep('enter-email')}
          start={start}
        />
      )}

      {step === 'finish-select' && (
        <FinishSelectView
          download={download}
          toBuilder={toBuilder}
          isStartLoading={isStartLoading}
        />
      )}
    </>
  );
};
