import { Grid } from '@mui/material';
import { FC } from 'react';
import { CustomIcon, IconName } from 'shared/assets/icons';

type Props = {
  autoPlay: boolean;
  mode: 'dark' | 'white';
  openDrawer: () => void;
  changeAutoPlay: (autoPlay: boolean) => void;
};

const icons: { dark: Record<string, IconName>; white: Record<string, IconName> } = {
  dark: {
    music: 'musicBlack',
    play: 'playBlack',
    stop: 'stopBlack',
  },
  white: {
    music: 'music',
    play: 'play',
    stop: 'stop',
  },
};

export const SoundsButtonsView: FC<Props> = props => {
  const { autoPlay, mode, openDrawer, changeAutoPlay } = props;

  const buttons: {
    name: IconName;
    handleClick: () => void;
  }[] = [
    {
      name: icons[mode].music,
      handleClick: openDrawer,
    },
    {
      name: !autoPlay ? icons[mode].play : icons[mode].stop,
      handleClick: () => changeAutoPlay(autoPlay),
    },
  ];

  return (
    <Grid container sx={{ gap: '16px', justifyContent: 'center' }}>
      {buttons.map((button, index) => (
        <Grid
          item
          key={index}
          sx={{ width: '50px', height: '50px', cursor: 'pointer' }}
          onClick={button.handleClick}
        >
          <CustomIcon name={button.name} styles={{ '& > svg': { shapeRendering: 'auto' } }} />
        </Grid>
      ))}
    </Grid>
  );
};
