import { ConsumerCodeStorageContext } from 'modules/auth';
import { Config, ConfigContext } from '../../../app/config';
import { ApiAdminClientContext, ApiConsumerClientContext } from '../api';
import { AdminTokenStorageContext, ConsumerTokenStorageContext } from '../token';
import { ApiUrlBuilderContext, AppUrlBuilderContext } from '../url';
import { contextItem, MultiContextProviderItem } from './MultiContextProvider';
import { Services } from './types';

export function createContextItems(
  services: Services,
  appConfig: Config,
): MultiContextProviderItem[] {
  const contextItems: MultiContextProviderItem[] = [
    contextItem(ConfigContext, appConfig),
    contextItem(ApiUrlBuilderContext, services.apiUrlBuilder),
    contextItem(AppUrlBuilderContext, services.appUrlBuilder),
    contextItem(AdminTokenStorageContext, services.adminTokenStorage),
    contextItem(ConsumerTokenStorageContext, services.consumerTokenStorage),
    contextItem(ConsumerCodeStorageContext, services.consumerCodeStorage),
    contextItem(ApiAdminClientContext, services.apiAdminClient),
    contextItem(ApiConsumerClientContext, services.apiConsumerClient),
  ];

  return contextItems;
}
