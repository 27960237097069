import { Route } from '../../../shared/router';
import { LoginPage, VerifyPage } from '../pages';

export const routes: Route[] = [
  {
    name: 'login',
    title: 'Login',
    path: '/admin/login',
    component: LoginPage,
    layoutType: 'adminAuth',
    isPublic: true,
    onlyNonAuth: true,
    availableFor: 'admin',
  },
  {
    name: 'verify',
    title: 'Verify',
    path: '/verify',
    component: VerifyPage,
    isPublic: true,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
];
