import { useCallback, useMemo } from 'react';
import { useStore } from 'react-redux';
import { RootState } from 'shared/store';
import { useAppDispatch, useAppSelector } from 'shared/tools';
import { useAdminCouponsApi } from '../api';
import { setCouponsList, setCouponsListError, setFilter, setIsLoading } from '../slices';
import { CouponCount, CouponFilter } from '../types';

export const useCouponsService = () => {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();
  const couponsApi = useAdminCouponsApi();
  const { loading, couponList, error, filter } = useAppSelector(state => state.coupons);

  const initFormData: CouponCount = useMemo(() => ({ count: 0 }), []);

  const getCoupons = useCallback(async () => {
    const filter = getState().coupons.filter;
    dispatch(setIsLoading({ getCoupons: true }));

    const filterArr: string[] = [];

    if (filter.search) {
      filterArr.push(`contains(code,'${filter.search}')`);
    }

    if (filter.status?.length) {
      filterArr.push(`(${filter.status.map(s => `statusId eq '${s}'`).join(' or ')})`);
    }

    try {
      const coupons = await couponsApi.getCoupons({
        pageNumber: filter.pageNumber,
        pageSize: filter.pageSize,
        filter: filterArr.join(' and ') || undefined,
        orderby: filter.orderedby ? filter.orderedby.replace(',', ' ') : undefined,
      });
      dispatch(setCouponsList(coupons));
    } catch (e) {
      dispatch(setCouponsListError(e as Error));
    } finally {
      dispatch(setIsLoading({ getCoupons: false }));
    }
  }, [couponsApi, dispatch]);

  const generateCoupons = useCallback(
    async (count: number) => {
      dispatch(setIsLoading({ generateCoupons: true }));

      try {
        await couponsApi.generateCoupons(count);
      } catch (e) {
        dispatch(setCouponsListError(e as Error));
      } finally {
        dispatch(setIsLoading({ generateCoupons: false }));
      }
    },
    [couponsApi, dispatch],
  );

  const changeFilter = useCallback(
    (filter: Partial<CouponFilter>) => {
      dispatch(setFilter(filter));
    },
    [dispatch],
  );

  return {
    loading,
    initFormData,
    couponList,
    error,
    getCoupons,
    generateCoupons,
    changeFilter,
    filter,
  };
};
