import { useCallback, useState } from 'react';
import { LangState } from '../types';
import { commonTextData } from 'shared/data';
import { useEffectOnce } from 'react-use';

export function useLangService() {
  const [lang, setLang] = useState<LangState>();

  const changeLang = useCallback((lang: LangState) => {
    setLang(lang);
    localStorage.setItem('lang', lang);
  }, []);

  useEffectOnce(() => {
    changeLang((localStorage.getItem('lang') || 'pl') as LangState);
  });

  const textData = Object.keys(commonTextData).reduce<Record<keyof typeof commonTextData, string>>(
    (acc, key, i) => {
      acc[key] = commonTextData[key][lang];
      return acc;
    },
    {} as Record<keyof typeof commonTextData, string>,
  );

  return { lang, changeLang, textData };
}
