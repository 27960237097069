import { Box, Grid } from '@mui/material';
import { FC, useState } from 'react';
import { PublicHeader } from './PublicHeader';
import { PublicLayoutDrawer } from './PublicLayoutDrawer';

export const PublicMainPageLayout: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        minHeight: '100%',
        backgroundColor: theme => theme.palette.primary.main,
      }}
    >
      <Grid
        container
        direction="column"
        width="100%"
        sx={{ zIndex: 1, position: 'relative', minHeight: '100%' }}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <PublicHeader mode="dark" />

        <Grid item container direction="column" flex="1 0 auto">
          {children}
        </Grid>
      </Grid>

      <PublicLayoutDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </Box>
  );
};
