import { Theme, useMediaQuery } from '@mui/material';
import FileSaver from 'file-saver';
import { useConsumerCodeStorage } from 'modules/auth';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLangContext } from 'shared/features/Language';
import usePagination from 'shared/features/Pagination/Pagination';
import { useAppUrlBuilderContext, useConsumerTokenStorage } from 'shared/tools';
import { portraits } from '../data';
import { useCreateArtService } from '../services';
import {
  CreateArtRequest,
  CreateArtResponse,
  EmailFormData,
  PortraitDataType,
  ReadyArtDataType,
  StepMode,
  UploadPhotoFormData,
} from '../types';
import { createImage } from '../components/CreateArtView/CropPhotoView/canvasUtils';
import { disposeCanvas } from '../helpers';

const IMG_DIMENSION = 1500;

export const useArtGeneratorViewController = (initStep: StepMode, imageId?: string) => {
  const history = useHistory();
  const appBuilder = useAppUrlBuilderContext();
  const { lang } = useLangContext();
  const consumerCodeStorage = useConsumerCodeStorage();
  const consumerTokenStorage = useConsumerTokenStorage();
  const { loading, startArt, sendPDF, chooseReadyArt, generatePdf, downloadPDF, createArt } =
    useCreateArtService();

  const [step, setStep] = useState<StepMode>(initStep);
  const [image, setImage] = useState<{
    file: File | null;
    url: string | undefined;
    crop: string[] | undefined;
  }>({
    file: null,
    url: undefined,
    crop: undefined,
  });
  const [emailData, setEmailData] = useState<EmailFormData>(null);
  const [artData, setArtData] = useState<ReadyArtDataType>(null);
  const [sessionId, setSessionId] = useState<string>(null);
  const [isStartLoading, setIsStartLoading] = useState(false);
  const [readyArt, setReadyArt] = useState<PortraitDataType>(null);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const PER_PAGE = matches ? 4 : 100;

  const data = usePagination(portraits, PER_PAGE);

  const [generationParams, setGenerationParams] = useState<Omit<CreateArtRequest, 'image'>>({
    query: 'v1,steps,points,coords,imgUrl',
    points: 240,
    steps: 3700,
    seed: 30,
    index: 1,
    width: 2,
    weight: 10,
  });
  const [beGeneration, setBeGeneration] = useState<CreateArtResponse>(null);

  const { currentReadyArt } = useMemo(() => {
    if (!imageId) {
      return {};
    }

    const currentReadyArt: PortraitDataType = portraits.find(item => item.id === imageId);
    setReadyArt(currentReadyArt);

    return { currentReadyArt };
  }, [imageId]);

  const chooseArt = useCallback(
    async (id: string) => {
      try {
        await chooseReadyArt();
        history.push(appBuilder.consumerVerify({ id }));
      } catch {}
    },
    [appBuilder, history],
  );

  const changePage = useCallback(
    (e: any, p: number) => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      data.jump(p);
    },
    [data],
  );

  const uploadImage = useCallback((form: UploadPhotoFormData, next?: () => void) => {
    if (form.newImage) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        createImage(reader.result).then(image => {
          const cropped2 = document.createElement('canvas');
          const croppedCtx2 = cropped2.getContext('2d');
          const hor = image.width > image.height;
          cropped2.width = Math.trunc(
            hor ? IMG_DIMENSION : (IMG_DIMENSION * image.width) / image.height,
          );
          cropped2.height = Math.trunc(
            hor ? (IMG_DIMENSION * image.height) / image.width : IMG_DIMENSION,
          );

          croppedCtx2.drawImage(image, 0, 0, cropped2.width, cropped2.height);
          const url = cropped2.toDataURL();
          disposeCanvas(cropped2);
          setImage({ file: form.newImage, url, crop: undefined });
          next?.();
        });
      });

      reader.readAsDataURL(form.newImage);
    }
  }, []);

  const uploadImage2 = useCallback(
    (form: UploadPhotoFormData & Omit<CreateArtRequest, 'image'>, next?: () => void) => {
      const { newImage, ...rest } = form;
      setGenerationParams({
        query: rest.query,
        points: Number(rest.points),
        steps: Number(rest.steps),
        seed: Number(rest.seed),
        index: Number(rest.index),
        width: Number(rest.width),
        weight: Number(rest.weight),
      });

      if (newImage) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          setImage({ file: newImage, url: reader.result as string, crop: undefined });
          next?.();
        });

        reader.readAsDataURL(newImage);
      }
    },
    [],
  );

  const saveCrop = useCallback((crop: string[]) => {
    setImage(image => ({
      ...image,
      crop: crop,
    }));

    setStep('choice-variant');
  }, []);

  const saveCrop2 = useCallback(
    async (crop: string[]) => {
      setImage(image => ({
        ...image,
        crop: crop,
      }));

      setStep('choice-variant');

      try {
        const regex = /data:.*base64,/;

        const response = await createArt({
          image: crop[1].replace(regex, ''),
          ...generationParams,
        });

        setBeGeneration(response);
      } catch (e) {
        console.log(e);
      }
    },
    [generationParams],
  );

  const selectVariant = useCallback((data: ReadyArtDataType) => {
    setArtData(data);
    setStep('enter-email');
  }, []);

  const enterEmail = useCallback((data: EmailFormData) => {
    setEmailData(data);
    setStep('confirm-email');
  }, []);

  const start = useCallback(async () => {
    setIsStartLoading(true);
    const { image, ...restArtData } = artData;

    try {
      const startArtResponse = await startArt({
        code: consumerCodeStorage.get(),
        stepsData: JSON.stringify(restArtData),
        totalStepCount: restArtData.steps.length,
      });

      setSessionId(startArtResponse.sessionId);

      await generatePdf({
        email: emailData.email,
        name: 'FRIEND',
        languageId: lang.toLocaleUpperCase(),
        artImage: image,
        debug: true,
      });

      setStep('finish-select');
    } catch (e) {
      if (e.data.title === 'CODE_INVALID_STATUS') {
        consumerTokenStorage.remove();
        consumerCodeStorage.remove();
        history.push(appBuilder.consumerVerify());
      }
    } finally {
      setIsStartLoading(false);
    }
  }, [artData, emailData, startArt, sendPDF]);

  const download = useCallback(async () => {
    setIsStartLoading(true);
    try {
      const result = await downloadPDF();
      const csvFile = new Blob([result], { type: 'application/pdf' });
      FileSaver.saveAs(csvFile, 'YourInstruction.pdf');
    } catch (e) {
      console.log(e);
    } finally {
      setIsStartLoading(false);
    }
  }, [downloadPDF]);

  const toBuilder = useCallback(() => {
    history.push(appBuilder.consumerAssembly(sessionId));
  }, [sessionId]);

  return {
    step,
    image,
    emailData,
    readyArt,
    data,
    currentReadyArt,
    isStartLoading,
    isGenerateLoading: loading.createArt,
    setStep,
    uploadImage,
    uploadImage2,
    saveCrop,
    saveCrop2,
    enterEmail,
    selectVariant,
    toBuilder,
    chooseArt,
    changePage,
    download,
    start,
    generationParams,
    beGeneration,
  };
};
