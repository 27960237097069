import { useMemo } from 'react';
import { useApiAdminClient, useApiUrlBuilder } from '../../../shared/tools';
import { LoginDataRequest, Token } from '../types';

export function useAuthAdminApi() {
  const api = useApiAdminClient();
  const apiUrlBuilder = useApiUrlBuilder();

  return useMemo(
    () => ({
      login: (data: LoginDataRequest) =>
        api.post<LoginDataRequest, Token>({
          path: apiUrlBuilder.adminLogin(),
          data,
        }),
    }),
    [api, apiUrlBuilder],
  );
}
