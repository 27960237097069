import { Box, Grid, SxProps, Theme } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import logoBlack from '../../../assets/img/logoBlack.png';
import logoWhite from '../../../assets/img/logoWhite.png';

type LogoProps = {
  color: string;
  sx?: SxProps<Theme> | undefined;
};

export const Logo: FC<LogoProps> = props => {
  const { color, sx } = props;

  return (
    <Grid item>
      <Link to="/">
        <Box
          component="img"
          src={color === 'white' ? logoWhite : logoBlack}
          alt="Let's String"
          sx={sx}
        />
      </Link>
    </Grid>
  );
};
