import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { PrivateFooter as Footer } from './AdminPrivateFooter';
import { PrivateHeader as Header } from './AdminPrivateHeader';

export const AdminPrivatePageLayout: FC = ({ children }) => {
  return (
    <Grid container direction="column" minHeight="100%">
      <Header />

      <Grid item container direction="column" flex="1 0 auto">
        {children}
      </Grid>

      <Footer />
    </Grid>
  );
};
