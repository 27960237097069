import { useArtGeneratorViewController } from 'modules/art/controllers';
import { EnteringEmailView } from '../CreateArtView/EnterEmailView';
import { ConfirmEmailView } from '../CreateArtView/ConfirmEmailView';
import { FinishSelectView } from '../CreateArtView/FinishSelectView';
import { ShowReadyArtView } from './ShowReadyArtView';

export const CreateReadyArtView = ({ imageId }) => {
  const {
    step,
    emailData,
    isStartLoading,
    setStep,
    enterEmail,
    selectVariant,
    toBuilder,
    currentReadyArt,
    download,
    start,
  } = useArtGeneratorViewController('show-ready-art', imageId);

  return (
    <>
      {step === 'show-ready-art' && (
        <ShowReadyArtView currentReadyArt={currentReadyArt} select={selectVariant} />
      )}

      {step === 'enter-email' && (
        <EnteringEmailView goBack={() => setStep('show-ready-art')} enterEmail={enterEmail} />
      )}

      {step === 'confirm-email' && (
        <ConfirmEmailView
          isStartLoading={isStartLoading}
          emailData={emailData}
          goBack={() => setStep('enter-email')}
          start={start}
        />
      )}

      {step === 'finish-select' && (
        <FinishSelectView
          download={download}
          toBuilder={toBuilder}
          isStartLoading={isStartLoading}
        />
      )}
    </>
  );
};
