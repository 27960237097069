import { FC } from 'react';
import {
  AdminAuthPageLayout,
  AdminPrivatePageLayout,
  AdminPublicPageLayout,
} from '../components/admin';
import { PublicMainPageLayout, PublicWhitePageLayout } from '../components/public';
import { LayoutType } from '../types';

export const getLayoutComponent = (layoutType: LayoutType) => {
  const map: Record<LayoutType, FC> = {
    adminPrivate: AdminPrivatePageLayout,
    adminPublic: AdminPublicPageLayout,
    adminAuth: AdminAuthPageLayout,
    publicMain: PublicMainPageLayout,
    publicWhite: PublicWhitePageLayout,
  };

  return map[layoutType];
};
