import { Typography } from '@mui/material';
import { UploadPhotoFormData } from 'modules/art/types';
import { FC } from 'react';
import { PublicPageLayout } from 'shared/layout';
import { UploadPhotoForm } from './UploadPhotoForm';
import { useLangContext } from 'shared/features/Language';

type Props = {
  uploadImage: (form: UploadPhotoFormData) => void;
};

export const UploadPhotoView: FC<Props> = ({ uploadImage }) => {
  const { textData } = useLangContext();

  return (
    <PublicPageLayout
      title={
        <Typography
          variant="subtitle1"
          sx={{
            maxWidth: '394px',
            paddingRight: {
              sm: '20px ',
              xs: '20px ',
            },
            fontSize: {
              md: '50px',
              xs: '30px',
            },
            lineHeight: {
              md: '60px',
              xs: '36px',
            },
          }}
        >
          {textData.uploadPageTitle}
        </Typography>
      }
    >
      <UploadPhotoForm submit={uploadImage} />
    </PublicPageLayout>
  );
};
