import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { AssemblyDataState } from 'modules/art/controllers';
import React, { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import Slider from 'react-slick';
import { theme } from 'themes';
import { CentralCircleView } from './CentralCircleView';

type Props = {
  slider: MutableRefObject<Slider>;
  assemblyData: AssemblyDataState;
  currentStepNumber: number;
  changeStep: (stepIndex: number) => void;
};

const staticSlideSettings = {
  infinite: false,
  centerMode: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 5,
        centerMode: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
        centerMode: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 7,
        centerMode: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 7,
        centerMode: true,
      },
    },
  ],
};

export const BuilderSlider: FC<Props> = props => {
  const { slider, assemblyData, currentStepNumber, changeStep } = props;
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);

  const { prev, current } = useMemo(() => {
    const { prev, current } = assemblyData.stepsData.pegs.reduce<{
      prev: {
        x: number;
        y: number;
        angle: number;
        name: string;
      } | null;
      current: {
        x: number;
        y: number;
        angle: number;
        name: string;
      } | null;
    }>(
      (acc, peg) => {
        if (peg.name === assemblyData.stepsData.steps?.[currentStepNumber - 2]) {
          acc.prev = peg;
        }

        if (peg.name === assemblyData.stepsData.steps?.[currentStepNumber - 1]) {
          acc.current = peg;
        }

        return acc;
      },
      {
        current: null,
        prev: null,
      },
    );

    if (!prev || !current) {
      return {};
    }

    const size = matches ? 170 : 315;
    const getCoordinate = (value: number) => (value * size) / assemblyData.stepsData.size.x;

    return {
      current: {
        ...current,
        x: getCoordinate(current.x),
        y: getCoordinate(current.y),
      },
      prev: {
        ...prev,
        x: getCoordinate(prev.x),
        y: getCoordinate(prev.y),
      },
    };
  }, [assemblyData, currentStepNumber, matches]);

  useEffect(() => {
    if (ref.current) {
      const touched = (e: PointerEvent) => {
        e.preventDefault();
      };

      ref?.current?.addEventListener('pointerdown', touched);
      ref?.current?.addEventListener('touchmove', touched);

      return () => {
        ref?.current?.removeEventListener('pointerdown', touched);
        ref?.current?.removeEventListener('touchmove', touched);
      };
    }
  }, [ref.current]);

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1 1',
      }}
    >
      <CentralCircleView
        step={assemblyData.stepsData.steps[currentStepNumber - 1]}
        prev={prev}
        current={current}
      />

      <Grid
        container
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: matches ? 'column' : 'row',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '5',
          maxWidth: { sm: '100%', xs: 'inherit' },
          maxHeight: { sm: 'inherit', xs: '100%' },
          overflow: 'hidden',
        }}
      >
        <Slider
          ref={slider}
          initialSlide={currentStepNumber - 1}
          {...staticSlideSettings}
          afterChange={current => changeStep(current + 1)}
        >
          {[...assemblyData.stepsData.steps, ...new Array(6)].map((slide, index) => (
            <Grid
              item
              onClick={!!slide ? () => slider.current.slickGoTo(index) : undefined}
              sx={{ cursor: !!slide ? 'pointer' : undefined }}
              key={index}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: { sm: '35px', xs: '22px' },
                  lineHeight: '42px',
                }}
              >
                {slide}
              </Typography>
            </Grid>
          ))}
        </Slider>
      </Grid>
    </Box>
  );
};

const WrapperBuilderSlider = React.memo(BuilderSlider);
export default WrapperBuilderSlider;
