import qs from 'query-string';
import { BuildUrlOptions } from './types';

export class BaseUrlBuilder {
  baseUrl: string;

  constructor(baseUrl: string = '') {
    this.baseUrl = baseUrl;
  }

  public buildUrl(path: string, options?: BuildUrlOptions): string {
    let url = this.baseUrl + path;

    if (options?.query && Object.keys(options.query).length !== 0) {
      url = url + '?' + qs.stringify(options.query);
    }

    return url;
  }
}
