import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { FC } from 'react';
import { CouponFilter, CouponStatus } from '../types';

type Props = {
  filter: CouponFilter;
  changeStatus: (filter: CouponStatus[]) => void;
};

const options: CouponStatus[] = ['New', 'Initialized', 'Started', 'Finished'];

export const CouponFilterByStatus: FC<Props> = ({ filter, changeStatus }) => {
  return (
    <>
      <FormControl sx={{ width: 200, marginTop: '20px' }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={filter.status}
          onChange={e => changeStatus(e.target.value as CouponStatus[])}
          input={<OutlinedInput label="Filter" />}
          renderValue={selected => selected.join(', ')}
          sx={{
            height: 60,
            maxWidth: '200px',
            '& > div': { maxWidth: '200px !important', maxHeight: '60px !important' },
          }}
        >
          {options.map(status => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={filter.status.includes(status)} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
