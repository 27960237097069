import { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { useApiAdminClient, useApiConsumerClient } from 'shared/tools';
import { useGeneralAuthService, useLoginService, useVerifyService } from '../../services';

export const AuthorizeController: FC = () => {
  const apiAdminClient = useApiAdminClient();
  const apiConsumerClient = useApiConsumerClient();
  const { authorize } = useGeneralAuthService();
  const { logout: adminLogout } = useLoginService();
  const { logout: consumerLogout } = useVerifyService();

  useEffectOnce(() => {
    authorize();

    apiAdminClient.setErrorHandler(error => {
      if (error.httpCode === 401 || error.message === 'Network Error') {
        adminLogout();
      }
    });

    apiConsumerClient.setErrorHandler(error => {
      if (error.httpCode === 401 || error.message === 'Network Error') {
        consumerLogout();
      }
    });
  });

  return null;
};
