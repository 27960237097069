import { BaseUrlBuilder } from './BaseUrlBuilder';

export class ApiUrlBuilder extends BaseUrlBuilder {
  public adminLogin() {
    return this.buildUrl('admin/auth');
  }

  public adminCoupons(query: { top: number; skip: number; orderby?: string; filter?: string }) {
    return this.buildUrl('admin/coupons', { query });
  }

  public adminGenerateCoupons(count: number) {
    return this.buildUrl(`admin/coupons/generate/${count}`);
  }

  public consumerVerify() {
    return this.buildUrl('art/verify');
  }

  public consumerStartArt() {
    return this.buildUrl('art/start');
  }

  public consumerSendPDF() {
    return this.buildUrl('art/send');
  }

  public consumerSaveArtStep() {
    return this.buildUrl('art/save');
  }

  public consumerGenerateArt() {
    return this.buildUrl('art/generate');
  }

  public consumerResumeArt(sessionId: string) {
    return this.buildUrl(`art/resume/${sessionId}`);
  }

  public consumerCreateArt() {
    return this.buildUrl('art/create');
  }

  public consumerDownloadPDF() {
    return this.buildUrl('art/pdf/download');
  }
}
