import { Route } from '../../../shared/router';
import {
  AssemblyPage,
  CreateArtPage,
  CreateArtPage2,
  CreateReadyArtPage,
  DesignsPage,
} from '../pages';

export const routes: Route[] = [
  {
    name: 'createArt',
    title: 'Create Art',
    path: '/create-art',
    component: CreateArtPage,
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
  {
    name: 'createArt',
    title: 'Create Art',
    path: '/create-art2',
    component: CreateArtPage2,
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
  {
    name: 'createReadyArt',
    title: 'Create Ready Art',
    path: '/create-ready-art/:imageId',
    component: CreateReadyArtPage,
    isPublic: false,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
  {
    name: 'designs',
    title: 'Designs',
    path: '/designs',
    layoutType: 'publicWhite',
    component: DesignsPage,
    isPublic: true,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
  {
    name: 'assembly',
    title: 'Assembly',
    path: '/assembly/:sessionId',
    component: AssemblyPage,
    isPublic: true,
    onlyNonAuth: false,
    availableFor: 'consumer',
  },
];
