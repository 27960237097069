import { getIntroUrl, getMelodyUrl, getVoiceUrl } from 'app/constants';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { delay } from 'shared/tools';

export type MelodyOption = {
  title: string;
  value: string | null;
};

const melodies: MelodyOption[] = [
  { title: 'Ocean', value: 'Ocean' },
  { title: 'Rain', value: 'Rain' },
  { title: 'Nature', value: 'Nature' },
  { title: 'Winter Fire', value: 'WinterFire' },
  { title: 'Meditation 1', value: 'Meditation1' },
  { title: 'Meditation 2', value: 'Meditation2' },
  { title: 'Meditation 3', value: 'Meditation3' },
  { title: 'No music', value: null },
];

export type SpeedOption = {
  title: string;
  value: number;
};

const speeds: SpeedOption[] = [
  { title: '0.5X', value: 5500 },
  { title: '0.75X', value: 3500 },
  { title: '1X', value: 1000 },
  { title: '1.25X', value: 800 },
  { title: '1.5X', value: 700 },
  { title: '2X', value: 600 },
  { title: '2.5X', value: 300 },
  { title: '3X', value: 100 },
  { title: '4X', value: 20 },
  { title: '5X', value: 0 },
];

type SoundPlayerState = {
  melodyName: string | null;
  volume: number;
  speed: number;
};

export type SoundPlayerController = {
  voiceRef: MutableRefObject<HTMLAudioElement>;
  state: SoundPlayerState;
  melodies: MelodyOption[];
  speeds: SpeedOption[];
  setIntro: (lang: string, nextAction: () => void) => void;
  setStep: (currentStep: string | null, lang: string, nextAction: () => void) => void;
  setMelody: (melodyName: string | null) => void;
  setVolume: (volume: number) => void;
  setSpeed: (speed: number) => void;
};

export const useSoundPlayerController = (): SoundPlayerController => {
  const voiceRef = useRef(new Audio());
  const melodyRef = useRef(new Audio());

  const [state, setState] = useState<SoundPlayerState>({
    melodyName: null,
    volume: 0.3,
    speed: 1000,
  });

  const setIntro = useCallback(
    (lang: string, nextAction: () => void) => {
      voiceRef.current.src = getIntroUrl(lang);
      voiceRef.current.onended = async () => {
        await delay(1000);
        nextAction();
      };
      voiceRef.current.onerror = async () => {
        await delay(1000);
        nextAction();
      };
    },
    [voiceRef.current],
  );

  const setStep = useCallback(
    (currentStep: string | null, lang: string, nextAction: () => void) => {
      voiceRef.current.src = getVoiceUrl(currentStep, lang);
      voiceRef.current.onended = async () => {
        await delay(state.speed);
        nextAction();
      };
      voiceRef.current.onerror = async () => {
        await delay(state.speed);
        nextAction();
      };
    },
    [voiceRef.current, state.speed],
  );

  const setMelody = useCallback((melodyName: string | null) => {
    setState(s => {
      if (!!melodyName) {
        melodyRef.current.src = getMelodyUrl(melodyName);
      } else {
        melodyRef.current.pause();
      }

      return {
        ...s,
        melodyName,
      };
    });
  }, []);

  const setVolume = useCallback(
    (volume: number) => {
      melodyRef.current.volume = volume;

      setState(s => ({
        ...s,
        volume,
      }));
    },
    [melodyRef.current],
  );

  const setSpeed = useCallback((speed: number) => {
    setState(s => ({
      ...s,
      speed,
    }));
  }, []);

  useEffectOnce(() => {
    melodyRef.current.volume = state.volume;
    melodyRef.current.loop = true;
    melodyRef.current.autoplay = true;

    voiceRef.current.autoplay = true;

    return () => {
      voiceRef.current.pause();
      melodyRef.current.pause();
    };
  });

  return {
    voiceRef,
    state,
    melodies,
    speeds,
    setIntro,
    setStep,
    setMelody,
    setVolume,
    setSpeed,
  };
};
