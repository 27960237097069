import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArtState } from './types';

const initialState: ArtState = {
  loading: {
    sendPDF: false,
    startArt: false,
    resumeArt: false,
    saveStep: false,
    createArt: false,
  },
  isReadyArt: false,
};

export const ArtSlice = createSlice({
  name: 'art',
  initialState,
  reducers: {
    setIsLoading(
      state,
      action: PayloadAction<Partial<Record<keyof ArtState['loading'], boolean>>>,
    ) {
      state.loading = { ...state.loading, ...action.payload };
    },
    setReadyArt(state) {
      state.isReadyArt = true;
    },
  },
});

const { actions, reducer } = ArtSlice;
export default reducer;
export const { setIsLoading, setReadyArt } = actions;
