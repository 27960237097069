import { Box, Button } from '@mui/material';
import { ChangeEvent, ReactElement, useEffect } from 'react';
import { Path, PathValue, UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import { CustomIcon } from 'shared/assets/icons';

interface Props<T extends object> {
  form: UseFormReturn<T>;
  fieldName: Path<T>;
  rotate: number;
  setRotate: (rotate: number) => void;
  scale: number;
  setScale: (scale: number) => void;
}

export const CropPhotoButtons = <T extends object>(props: Props<T>): ReactElement | null => {
  const { form, fieldName, rotate, setRotate, scale, setScale } = props;

  const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!!event.target.files?.length) {
      form.setValue(fieldName, event.target.files[0] as UnpackNestedValue<PathValue<T, Path<T>>>, {
        shouldDirty: true,
      });

      form.trigger(fieldName);
    }
  };

  useEffect(() => {
    form.register(fieldName);
  }, [fieldName, form]);

  return (
    <>
      <Button
        component="label"
        variant="outlined"
        sx={{
          border: `1px solid`,
          borderColor: 'green',
          borderRadius: '100%',
          padding: '12px',
          width: '54px',
          maxWidth: '54px',
          minWidth: 0,
          height: '54px',
          '&:hover': { cursor: 'pointer', backgroundColor: 'gainsboro' },
        }}
      >
        <CustomIcon
          name="upload"
          styles={{ '& > svg': { width: '24px', height: '24px', fill: 'green' } }}
        />
        <input accept="image/*" hidden type="file" onChange={handleSelectFile} />
      </Button>

      <Box
        onClick={() => setRotate(rotate - 90)}
        sx={{
          border: `1px solid`,
          borderColor: theme => theme.palette.primary.main,
          borderRadius: '100%',
          padding: '12px',
          width: '54px',
          height: '54px',
          '&:hover': { cursor: 'pointer', backgroundColor: 'gainsboro' },
        }}
      >
        <CustomIcon name="rotateBlack" styles={{ '& > svg': { width: '25px', height: '25px' } }} />
      </Box>

      <Box
        onClick={() => setScale(scale + 0.1)}
        sx={{
          border: `1px solid`,
          borderColor: theme => theme.palette.primary.main,
          borderRadius: '100%',
          padding: '12px',
          width: '54px',
          height: '54px',
          '&:hover': { cursor: 'pointer', backgroundColor: 'gainsboro' },
        }}
      >
        <CustomIcon name="plusBlack" styles={{ '& > svg': { width: '25px', height: '25px' } }} />
      </Box>

      <Box
        onClick={() => setScale(scale - 0.1)}
        sx={{
          border: `1px solid`,
          borderColor: theme => theme.palette.primary.main,
          borderRadius: '100%',
          padding: '12px',
          width: '54px',
          height: '54px',
          '&:hover': { cursor: 'pointer', backgroundColor: 'gainsboro' },
        }}
      >
        <CustomIcon name="minusBlack" styles={{ '& > svg': { width: '25px', height: '25px' } }} />
      </Box>
    </>
  );
};
