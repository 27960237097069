import React, { ComponentType, PropsWithChildren, ReactElement, useEffect, useMemo } from 'react';
import { DefaultValues, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { FormComponentProps, FormErrorRecord } from './types';

interface Props<T extends object, P extends object = {}> {
  formId?: string;
  formData: DefaultValues<T>;
  component: ComponentType<FormComponentProps<T, P>>;
  componentProps?: P;
  errorList?: FormErrorRecord<T>[];
  onSubmit(full: Partial<T>, dirty: Partial<T>): void;
}

export const FormView = <T extends object, P extends object = {}>(
  props: PropsWithChildren<Props<T, P>>,
): ReactElement | null => {
  const { formId, formData, component, componentProps = {} as P, errorList, onSubmit } = props;
  const FormComponent = component;
  const form = useForm<T>({
    mode: 'onBlur',
    shouldFocusError: false,
    defaultValues: formData || ({} as DefaultValues<T>),
  });

  const onSubmitHandler: SubmitHandler<T> = useMemo(
    () => full => onSubmit(full, getDirtyFormData(form)),
    [form, onSubmit],
  );

  useEffect(() => {
    errorList?.forEach(error => {
      form.setError(error.field, { type: 'custom', message: error.message });
    });
  }, [errorList, form]);

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmitHandler)} noValidate>
      <FormComponent form={form} formData={formData} {...componentProps} />
    </form>
  );
};

function getDirtyFormData<T extends Record<string, any>>(form: UseFormReturn<T>): Partial<T> {
  const data = form.getValues() as Partial<T>;
  const dirtyFields = form.formState.dirtyFields as unknown as Partial<Record<keyof T, boolean>>;

  return Object.keys(dirtyFields).reduce((acc, key: keyof T) => {
    if (!!dirtyFields[key]) {
      acc[key] = data[key];
    }

    return acc;
  }, {} as Partial<T>);
}
