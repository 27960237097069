import { Box, Container, Grid } from '@mui/material';
import { HomeButtonBlock } from './HomeButtonBlock';
import { HomeCompareImage } from './HomeCompareImage';
import { HomeFooter } from './HomeFooter';
import './home-page.css';

export const HomeView = () => {
  return (
    <Box
      sx={{
        width: {
          md: '85%',
          xs: '100%',
        },
        height: {
          md: '100%',
          xs: '88%',
        },
        display: 'flex',
        flexDirection: 'column',
        paddingTop: {
          md: '5%',
          xs: 0,
        },
        backgroundColor: theme => theme.palette.primary.main,
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%', flex: '4 1' }}>
        <Grid
          container
          spacing={2}
          sx={{
            height: '100%',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: {
              md: 'flex-start',
              xs: 'center',
            },
            margin: 0,
          }}
        >
          <Grid
            item
            container
            sx={{
              flex: '1 1',
              paddingLeft: '0px !important',
              paddingTop: { md: '16px', xs: '0px !important' },
              marginTop: {
                md: 0,
                xs: '30px',
              },
            }}
          >
            <Grid
              item
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                gap: {
                  lg: '160px',
                  md: '22px',
                  xs: '7px',
                },
              }}
            >
              <Grid
                item
                container
                sm={12}
                md={6}
                lg={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: {
                    xs: 'center',
                  },
                }}
              >
                <HomeButtonBlock />
              </Grid>

              <Grid item lg={5} md={5} sm={5} xs={8}>
                <HomeCompareImage />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <HomeFooter />
    </Box>
  );
};
