import {
  TableCell,
  TableHead as TableHeadSource,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';

import { TableObjectField } from './types';
import { SortDirection } from '../../types';

interface Props<T extends object> {
  columns: TableObjectField<T>[];
  sort?: string;
  onChangeSort?: (value: string) => void;
}

export const TableHead = <T extends object>(
  props: PropsWithChildren<Props<T>>,
): ReactElement | null => {
  const { columns, sort, onChangeSort } = props;

  const sortOptions = useMemo<{
    field: string;
    direction: SortDirection;
  } | null>(() => {
    const params = sort?.split(',');

    if (!params?.[0] || !params?.[1]) {
      return null;
    }

    return { field: params[0], direction: params[1] } as {
      field: string;
      direction: SortDirection;
    };
  }, [sort]);

  const handleChangeSort = useCallback(
    (field: string | undefined) => {
      if (onChangeSort) {
        if (field === sortOptions?.field) {
          onChangeSort(`${field},${sortOptions?.direction === 'asc' ? 'desc' : 'asc'}`);
        } else {
          onChangeSort(`${field},asc`);
        }
      }
    },
    [sortOptions, onChangeSort],
  );

  const isActiveSortOption = useCallback(
    (column: TableObjectField<T>) => {
      if (column.sort) {
        return column.sort === sortOptions?.field;
      }

      return false;
    },
    [sortOptions],
  );

  return (
    <TableHeadSource>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={index}
            align={column.titleAlign}
            sortDirection={isActiveSortOption(column) ? sortOptions?.direction : false}
          >
            {column.titleElement || (
              <TableSortLabel
                active={isActiveSortOption(column)}
                disabled={!column.sort}
                direction={isActiveSortOption(column) ? sortOptions?.direction : 'asc'}
                onClick={() => handleChangeSort(column.sort)}
              >
                {column.title}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHeadSource>
  );
};
